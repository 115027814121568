import every from "lodash/every";
import Model from "../model";

class Condition extends Model {
  static modelType = "Condition";

  static EQUAL = "=";
  static NOT_EQUAL = "!=";
  static GREATER_THAN = ">";
  static LESS_THAN = "<";
  static GREATER_THAN_OR_EQUAL = ">=";
  static LESS_THAN_OR_EQUAL = "<=";
  static BETWEEN = "between";
  static START_WITH = "^=";
  static CONTAIN = "contain";
  static NOT_CONTAIN = "!contain";
  static BLANK = "is_blank";
  static NOT_BLANK = "is_not_blank";

  operator(...args) {
    return this.accessor("operator", args, "string");
  }

  values(...args) {
    return this.accessor("values", args, ["*"]);
  }

  isValid() {
    const values = this.values();
    if (!values) {
      return false;
    }

    switch (this.operator()) {
      case Condition.BLANK:
      case Condition.NOT_BLANK:
        return values.length === 0;

      case Condition.GREATER_THAN:
      case Condition.LESS_THAN:
      case Condition.GREATER_THAN_OR_EQUAL:
      case Condition.LESS_THAN_OR_EQUAL:
      case Condition.NOT_EQUAL:
        return values.length === 1 && typeof values[0] === "number";

      case Condition.START_WITH:
      case Condition.CONTAIN:
      case Condition.NOT_CONTAIN:
        return values.length === 1 && typeof values[0] === "string";

      case Condition.BETWEEN:
        return (
          values.length === 2 &&
          every(values, (d) => typeof d === "number")
        );

      case Condition.EQUAL:
        return (
          values.length > 0 &&
          every(values, (d) => typeof d === "number" || typeof d === "string")
        );

      default:
        return false;
    }
  }
}
export default Condition;
