angular
  .module("acl.visualizer.charts.controllers")
  .controller("PieChartController", function($scope, ChartService, InheritController, PieChart) {
    var base = InheritController("ChartBaseController", $scope)
      .override("chartType", "PieChart")
      .override("defaultDisplayConfig", PieChart.defaultDisplayConfig)
      .override("getChartDirectiveConfig", PieChart.getChartDirectiveConfig)
      .override("populateChartConfigColumnDefs", PieChart.populateChartConfigColumnDefs)
      .override("chartConfigColumnDefs", PieChart.chartConfigColumnDefs)
      .override("isValidDataConfig", PieChart.isValidDataConfig)
      .override("chartZoomIn", function(keyArray) {
        // Needed to remove $scope before being able to move it to service.
        var categoryFormatter = ChartService.getFormatterForType($scope.chartConfig.dataConfig.chartCategory.type);
        var filters = [{ field: $scope.chartConfig.dataConfig.chartCategory, value: categoryFormatter(keyArray[0]) }];
        $scope.tab.callbackInterface.onZoomIn(filters);
      })
      .override("getKeyColor", config => config && ChartService.getKeyColor(config.colorByField, config.colorMapping));

    base.initChart();
  });
