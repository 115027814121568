export default class BoardDataLoader {
  constructor(InterpretationDataLoader, InterpretationTableDataLoader, MetricDataLoader, VisualizationDataLoader) {
    "ngInject";

    this._InterpretationDataLoader = InterpretationDataLoader;
    this._InterpretationTableDataLoader = InterpretationTableDataLoader;
    this._MetricDataLoader = MetricDataLoader;
    this._VisualizationDataLoader = VisualizationDataLoader;
  }

  async loadBoard(board) {
    switch (board.type) {
      case "metric":
      case "trigger":
        return this._MetricDataLoader.load(board);
      case "visualization":
        return this._VisualizationDataLoader.load(board);
      case "table":
        return this._InterpretationTableDataLoader.load(board);
    }
  }

  async loadMoreBoardData(board, boardModel) {
    return this._InterpretationTableDataLoader.loadMoreTableData(board, boardModel);
  }

  async loadInterpretationsForBoards(boards) {
    const interpretationBoards = boards.filter(board => ["visualization", "table"].includes(board.type));
    return this._InterpretationDataLoader.loadInterpretations(interpretationBoards);
  }
}
