angular
  .module("acl.visualizer.charts.backend")
  .service("BubbleChartBackendService", function(ChartBackendService, Sorter) {
    return {
      fetchJson: ChartBackendService.fetchJson,

      getRepresentation: function(resultData, config) {
        var data = [];

        const seriesType = resultData.config.series
          ? resultData.config.series.data_type
          : resultData.config.key.data_type;
        const xAxisType = resultData.config.key.data_type;
        const yAxisType = resultData.config.y_axis.data_type;
        const valueType = resultData.config.values.data_type;

        var seriesParser =
          config.chartSeries && config.chartSeries.fieldName ? ChartBackendService.getParserForType(seriesType) : null;
        var xAxisParser = ChartBackendService.getParserForType(xAxisType);
        var yAxisParser = ChartBackendService.getParserForType(yAxisType);
        var valueParser = ChartBackendService.getParserForType(valueType);

        data = ChartBackendService.nest()
          .key(function(d) {
            return config.chartSeries && config.chartSeries.fieldName ? seriesParser(d.series) : "";
          })
          .rollup(function(array) {
            var dataPoints = [];
            for (var i = 0; i < array.length; i++) {
              var value = {
                x: xAxisParser(array[i].key),
                y: yAxisParser(array[i].yaxis),
                size: valueParser(array[i].values[0]),
              };
              if (value.x !== null && value.y !== null && value.size !== null) {
                dataPoints.push(value);
              }
            }
            return dataPoints.length ? dataPoints : null;
          })
          .excludeNullKeys(true)
          .excludeNullValues(true)
          .entries(resultData.values);

        // Sort the series
        data = Sorter.sort(data, {
          dataType: seriesType,
          valueParser: item => item.key,
        });

        return data;
      },
    };
  });
