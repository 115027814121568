import ProcessedDataHandler from "./processedDataHandler";

angular
  .module("acl.visualizer.dataTable", [
    "acl.common.asyncCallManager",
    "acl.common.config",
    "acl.common.filters",
    "acl.common.localize",
    "acl.common.queuedHttp",
    "acl.visualizer.charts",
    "acl.visualizer.conditionalOperators",
    "acl.visualizer.dataGrid",
    "acl.visualizer.dataModel",
    "acl.visualizer.dataTableConfig",
    "acl.visualizer.dataVisualizer",
    "acl.visualizer.formatters",
    "acl.visualizer.model.table",
    "acl.visualizer.quickMenu",
    "acl.visualizer.tabState",
  ])
  .service("ProcessedDataHandler", ProcessedDataHandler);
