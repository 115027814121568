import ChartData from "@viz-ui/models/chart/chartData";
import SummaryTable from "@viz-ui/models/summaryTable/summaryTable";
import Board from "./board";

export default class VisualizationBoard extends Board {
  static modelType = "VisualizationBoard";

  controlTestId(...args) {
    return this.accessor("controlTestId", args, "number");
  }

  canDrilldown(...args) {
    return this.accessor("canDrilldown", args, "boolean");
  }

  visualizationId(...args) {
    return this.accessor("visualizationId", args, "string");
  }

  interpretation(...args) {
    return this.accessor("interpretation", args, Object);
  }

  chartData(...args) {
    return this.accessor("chartData", args, ChartData);
  }

  summaryTable(...args) {
    return this.accessor("summaryTable", args, SummaryTable);
  }

  visualizationData(...args) {
    return this.accessor("visualizationData", args, Object);
  }

  visualization() {
    const interpretation = this.interpretation();
    return interpretation && interpretation.visualizations && interpretation.visualizations.length > 0
      ? interpretation.visualizations.find(visualization => visualization.id === this.visualizationId())
      : undefined;
  }

  visualizationConfig() {
    const visualization = this.visualization();
    return visualization ? visualization.config : {};
  }

  isValid() {
    return (
      this.interpretation() &&
      this.visualization() &&
      this.visualizationConfig() &&
      (this.chartData() || this.summaryTable() || this.visualizationData())
    );
  }
}
