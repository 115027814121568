angular.module("acl.visualizer.chartConfigPanel").service("SectionValidator", DataModel => {
  const isValidFieldConfig = fieldConfig => fieldConfig && DataModel.table.fieldExists(fieldConfig);

  const isValidStatsChartCategory = categoryConfig =>
    categoryConfig &&
    Array.isArray(categoryConfig) &&
    categoryConfig.length > 0 &&
    DataModel.table.fieldsExist(categoryConfig);

  return {
    isSectionValid: (chartType, viewModelDataConfig) => {
      const hasChartValue = isValidFieldConfig(viewModelDataConfig.chartValue);
      const hasChartXAxis = isValidFieldConfig(viewModelDataConfig.chartXAxis);
      const hasChartYAxis = isValidFieldConfig(viewModelDataConfig.chartYAxis);
      const hasChartCategory = isValidFieldConfig(viewModelDataConfig.chartCategory);
      const hasChartValues = isValidFieldConfig(viewModelDataConfig.chartValues);
      const hasChartLocation = isValidFieldConfig(viewModelDataConfig.chartLocation);

      switch (chartType) {
        case "BarChart":
        case "LineChart":
        case "StackedAreaChart":
          return !!(hasChartXAxis && (viewModelDataConfig.aggregationType === "count" || hasChartValue));

        case "PieChart":
          return !!(hasChartCategory && (viewModelDataConfig.aggregationType === "count" || hasChartValue));

        case "BubbleChart":
          return !!(
            hasChartXAxis &&
            hasChartYAxis &&
            ((hasChartValue && viewModelDataConfig.aggregationType !== "") ||
              viewModelDataConfig.aggregationType === "count")
          );

        case "Heatmap":
          return !!(
            hasChartXAxis &&
            hasChartYAxis &&
            ((hasChartValue && viewModelDataConfig.aggregationType !== "") ||
              viewModelDataConfig.aggregationType === "count")
          );

        case "StatisticsViz":
          return isValidStatsChartCategory(viewModelDataConfig.chartCategory);

        case "CombinationChart":
          return isValidStatsChartCategory(viewModelDataConfig.chartCategory);

        case "MapChart":
          return !!(
            (hasChartValues && hasChartLocation && viewModelDataConfig.aggregationType !== "") ||
            viewModelDataConfig.aggregationType === "count"
          );

        default:
          throw new Error("Unexpected chart type: " + chartType);
      }
    },
  };
});
