import moment from "moment";
import { isArray, map, some } from "lodash";

class DataFilter {
  constructor(AppConfig, MultiSelectFilterStore, DataModel) {
    this.AppConfig = AppConfig;
    this.MultiSelectFilterStore = MultiSelectFilterStore;
    this.DataModel = DataModel;
  }

  hasInvalidFilters = filterConfig => {
    const filterNames = map(filterConfig.filterList, "name");
    return some(
      filterNames,
      filterName =>
        this.isFilterActive(filterConfig, filterName) &&
        !this.areChangedFieldCriteriaFiltersValid(filterConfig, filterName)
    );
  };

  areChangedFieldCriteriaFiltersValid = (filterConfig, fieldName) => {
    const fieldFilters = this.filtersForField(filterConfig, fieldName);
    return !some(fieldFilters, filter => filter.changed && !this.isCriteriaFilterValid(filter));
  };

  isCriteriaFilterValid = criteriaFilter => {
    switch (criteriaFilter.operator) {
      case "is_blank":
      case "is_not_blank":
        return true;
      case "between":
        return !!(criteriaFilter.values[0] && criteriaFilter.values[1]);
      default:
        return !!criteriaFilter.values[0];
    }
  };

  clearNewCriteriaFilter = field => {
    /* eslint-disable no-param-reassign */
    field.criteriaFilter.newFilter = {};
  };

  filtersForField = (filterConfig, fieldName) => {
    const filters = [];
    const fieldFilterIndex = this.findFilterIndexByName(filterConfig, fieldName);

    // FIXME: Make this easier to read by using functional array methods.
    if (fieldFilterIndex >= 0 && filterConfig.filterList[fieldFilterIndex].filters) {
      for (let iFilter = 0; iFilter < filterConfig.filterList[fieldFilterIndex].filters.length; iFilter++) {
        const filter = filterConfig.filterList[fieldFilterIndex].filters[iFilter];
        filters.push(filter);
      }
    }
    return filters;
  };

  setOrToggleSort = (newSortField, newSortOrder) => {
    let resetSort = false;
    const oldSortField = this.DataModel.filterConfig.sortFieldId();
    const oldSortOrder = this.DataModel.filterConfig.sortOrder();
    if (oldSortField && oldSortOrder) {
      const isExistingSort = oldSortField === newSortField && oldSortOrder === newSortOrder;
      if (isExistingSort) {
        this.DataModel.filterConfig.sortFieldId(null).sortOrder(null);
        resetSort = true;
      }
    }
    if (!resetSort) this.DataModel.filterConfig.sortFieldId(newSortField).sortOrder(newSortOrder);
  };

  addFilter = (filterConfig, colName, type, operator, values, connector) => {
    /* eslint-disable no-param-reassign */
    if (!filterConfig.filterList) filterConfig.filterList = [];
    const { filterList } = filterConfig;
    const filter = {};
    filter.operator = operator;

    switch (type) {
      case "datetime":
        filter.values = getMomentParsedValues(values, "YYYY-MM-DD HH:mm:ss");
        break;
      case "date":
        filter.values = getMomentParsedValues(values, "YYYY-MM-DD");
        break;
      default:
        filter.values = values.slice(0);
    }

    if (connector) filter.connector = connector;

    const filterObj = this.findFilterByName(filterConfig, colName);
    if (!filterObj) {
      filterList.push({
        name: colName,
        type: type,
        filters: [filter],
        active: true,
      });
    } else {
      if (!filterObj.filters) {
        filterObj.filters = [];
      }
      filterObj.filters.push(filter);
    }

    return true;
  };

  updateFilter = (filterConfig, colName) => {
    if (!filterConfig.filterList) return;
    const filterObj = this.findFilterByName(filterConfig, colName);
    if (filterObj && filterObj.filters) {
      filterObj.filters.forEach(filter => {
        if (filter.operator !== "between") filter.values.splice(1);
      });
    }
  };

  removeFilter = (filterConfig, colName, filter) => {
    let updated = false;
    if (filterConfig.filterList) {
      const fieldFilterIndex = this.findFilterIndexByName(filterConfig, colName);
      if (fieldFilterIndex >= 0 && filterConfig.filterList[fieldFilterIndex].filters) {
        const filterIndex = filterConfig.filterList[fieldFilterIndex].filters.indexOf(filter);
        if (filterIndex >= 0) {
          filterConfig.filterList[fieldFilterIndex].filters.splice(filterIndex, 1);
          updated = true;
          if (!filterConfig.filterList[fieldFilterIndex].filters.length) {
            /* eslint-disable no-param-reassign */
            delete filterConfig.filterList[fieldFilterIndex].filters;
          }
          this.cleanFilterListByIndex(filterConfig, fieldFilterIndex);
        }
      }
    }
    return updated;
  };

  removeAllCriteriaFilter = (filterConfig, colName) => {
    let updated = false;
    if (filterConfig.filterList) {
      const fieldFilterIndex = this.findFilterIndexByName(filterConfig, colName);
      if (fieldFilterIndex >= 0 && filterConfig.filterList[fieldFilterIndex].filters) {
        delete filterConfig.filterList[fieldFilterIndex].filters;
        updated = true;
        this.cleanFilterListByIndex(filterConfig, fieldFilterIndex);
      }
    }
    return updated;
  };

  isNewCriteriaFilterValid = filter => {
    let valid = false;

    // FIXME: Should be valid for falsy values.
    if (filter.operator === "between" && filter.value && filter.value2) valid = true;

    // FIXME: Should be valid for falsy values.
    if (filter.operator !== "between" && filter.operator && filter.value) valid = true;

    if (filter.operator === "is_blank" || filter.operator === "is_not_blank") valid = true;

    return valid;
  };

  bindQuickFilterCheckedValues = filterConfig => {
    if (filterConfig && filterConfig.filterList) {
      for (let iFilter = 0; iFilter < filterConfig.filterList.length; iFilter++) {
        const fieldName = filterConfig.filterList[iFilter].name;
        if (filterConfig.filterList[iFilter].quickFilter) {
          this.MultiSelectFilterStore.bindCheckedValuesOnLoad(
            fieldName,
            filterConfig.filterList[iFilter].quickFilter.values
          );
        }
      }
    }
  };

  resetFilterConfigQuickFilterValues = (filterConfig, colName, type, values) => {
    let updated = false;

    //if values is empty, remove the quickFilter from the filterList
    if (!values.length) {
      const filterIndex = this.findFilterIndexByName(filterConfig, colName);
      if (filterIndex >= 0) {
        if (filterConfig.filterList[filterIndex].quickFilter) {
          /* eslint-disable no-param-reassign */
          delete filterConfig.filterList[filterIndex].quickFilter;
          updated = true;
          this.cleanFilterListByIndex(filterConfig, filterIndex);
        }
      }
    } else {
      if (!filterConfig.filterList) filterConfig.filterList = [];

      const quickFilter = {
        operator: "=",
        values: values.slice(0),
      };

      const filterObj = this.findFilterByName(filterConfig, colName);
      if (filterObj) {
        if (filterObj.quickFilter) {
          quickFilter.storyboardIndicatorValues = filterObj.quickFilter.storyboardIndicatorValues;
          quickFilter.indeterminateValues = filterObj.quickFilter.indeterminateValues;
        }
        filterObj.quickFilter = quickFilter;
        updated = true;
      } else {
        filterConfig.filterList.push({
          name: colName,
          type: type,
          quickFilter: quickFilter,
          active: true,
        });
        updated = true;
      }
    }
    return updated;
  };

  cleanFilterListByIndex = (filterConfig, fieldIndex) => {
    if (!filterConfig.filterList[fieldIndex].quickFilter && !filterConfig.filterList[fieldIndex].filters)
      filterConfig.filterList.splice(fieldIndex, 1);

    if (!filterConfig.filterList.length) delete filterConfig.filterList;
  };

  findFilterIndexByName = (filterConfig, colName) => {
    if (filterConfig.filterList) {
      for (let i = 0; i < filterConfig.filterList.length; i++) {
        if (filterConfig.filterList[i].name === colName) return i;
      }
    }
    return -1;
  };

  findFilterByName = (filterConfig, colName) => {
    if (filterConfig.filterList) return filterConfig.filterList[this.findFilterIndexByName(filterConfig, colName)];
  };

  getFilterFieldNames = filterConfig => {
    if (filterConfig.filterList) return filterConfig.filterList.map(d => d.name);
    return [];
  };

  isFilterActive = (filterConfig, fieldName) => {
    let filterActive = true;
    const filterIndex = this.findFilterIndexByName(filterConfig, fieldName);
    if (filterIndex >= 0) filterActive = filterConfig.filterList[filterIndex].active;
    return filterActive;
  };

  getFilterRequestBody = filterConfig => {
    const requestBody = {};
    if (filterConfig.jobId) {
      requestBody.jobId = filterConfig.jobId;
    }
    if (filterConfig.params) {
      requestBody.params = filterConfig.params;
    }
    if (filterConfig.sortField) {
      requestBody.sortField = filterConfig.sortField;
    }
    if (this.AppConfig.features.remediationToggles) {
      requestBody.myRecords = filterConfig.myRecords;
      requestBody.openStatuses = filterConfig.openStatuses;
    }

    if (filterConfig.filterList) {
      requestBody.filterList = [];

      for (let iField = 0; iField < filterConfig.filterList.length; iField++) {
        if (filterConfig.filterList[iField].active) {
          const filter = {
            name: filterConfig.filterList[iField].name,
            type: filterConfig.filterList[iField].type,
          };
          // initialize filters if either quickFilter or filters exist
          if (filterConfig.filterList[iField].quickFilter || filterConfig.filterList[iField].filters) {
            filter.filters = [];
          }
          if (filterConfig.filterList[iField].filters) {
            for (let iFilter = 0; iFilter < filterConfig.filterList[iField].filters.length; iFilter++) {
              let values = [];
              const currFilter = filterConfig.filterList[iField].filters[iFilter];
              currFilter.values = currFilter.values || [];
              // make sure we are taking 2 values for between and 1 value for other operators
              switch (filterConfig.filterList[iField].type) {
                case "date": {
                  values = getMomentParsedValues(currFilter.values, "YYYY-MM-DD");
                  break;
                }
                case "datetime": {
                  const datetimeFormat = this.AppConfig.systemDatetimeFormat || "YYYY-MM-DD HH:mm:ss";
                  values = getMomentParsedValues(currFilter.values, datetimeFormat);
                  break;
                }
                case "numeric": {
                  values.push(currFilter.values[0]);
                  if (currFilter.operator === "between") {
                    values.push(currFilter.values[1]);
                  }
                  break;
                }
                default:
                  if (currFilter.operator === "between") {
                    values = currFilter.values.slice(0, 2);
                  } else if (currFilter.operator === "is_blank" || currFilter.operator === "is_not_blank") {
                    // FIXME: What happens if date, datetime, or numeric types above are using blank operators.
                    values = [];
                  } else {
                    values = currFilter.values.slice(0, 1);
                  }
              }
              filter.filters.push({
                operator: currFilter.operator,
                values: values,
                connector: currFilter.connector,
              });
            }
          }

          const quickFilter = filterConfig.filterList[iField].quickFilter;
          if (quickFilter) {
            if (quickFilter.unCheckedValues && quickFilter.unCheckedValues.length > 0) {
              quickFilter.unCheckedValues.forEach(unCheckedValue => {
                const sanitizeQuickFilter = {
                  ...quickFilter,
                  operator: unCheckedValue === "" ? "is_not_blank" : "!=",
                  values: unCheckedValue === "" ? [] : [unCheckedValue],
                  connector: "and",
                };
                delete sanitizeQuickFilter.selectAllState;
                delete sanitizeQuickFilter.unCheckedValues;
                filter.filters.push(sanitizeQuickFilter);
              });
            } else if (quickFilter.values && quickFilter.values.length !== 0) {
              const sanitizeQuickFilter = { ...quickFilter };
              delete sanitizeQuickFilter.selectAllState;
              delete sanitizeQuickFilter.unCheckedValues;
              filter.filters.push(quickFilter);
            }
          }
          if (filter.filters.length > 0) requestBody.filterList.push(filter);
        }
      }
      if (!requestBody.filterList.length) {
        delete requestBody.filterList;
      }
    }
    return requestBody;
  };

  valueExistInQuickFilter = (filterConfig, colName, value) => {
    const filter = this.findFilterByName(filterConfig, colName);
    return filter && filter.quickFilter && filter.quickFilter.values.indexOf(value) !== -1;
  };

  isAlphaSortType = fieldType => {
    switch (fieldType) {
      case "character":
      case "logical":
        return true;
      default:
        return false;
    }
  };

  isFormattableType = fieldType => fieldType !== "logical";

  isDigitalSortType = fieldType => {
    switch (fieldType) {
      case "date":
      case "datetime":
      case "time":
      case "numeric":
        return true;
      default:
        return false;
    }
  };
}

const getMomentParsedValues = (values, format) => {
  if (!isArray(values)) return [];

  const formattedValues = [];
  values.forEach(elem => {
    formattedValues.push(moment(elem).format(format));
  });
  return formattedValues;
};
export default DataFilter;
