import { JwtHighbondClient } from "@acl-services/jwt-highbond-client";

const URL_PIECES = window.location.host.split(".").reverse();
const LOCAL = URL_PIECES.length === 1 && URL_PIECES[0].includes("localhost");

let region = ``;
if (!LOCAL) {
  const subdomainPieces = URL_PIECES[2].split("-");
  const regionCode = subdomainPieces.pop() || "";
  if (["ca", "eu", "ap", "au", "af", "sa", "jp", "uk"].includes(regionCode)) {
    region = `-${regionCode}`;
  }
}

const PDF_EXPORTER_API = LOCAL
  ? `http://localhost:8000/export`
  : `https://pdf-exporter-api${region}.${URL_PIECES[1]}.${URL_PIECES[0]}/export`;

export default class ApiService {
  async exportAsPdf(exportOptions = {}) {
    const options = {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(exportOptions),
    };

    const client = new JwtHighbondClient({ autoRedirect: true });
    return await client.fetchWithTokenRefresh(PDF_EXPORTER_API, options);
  }
}
