import React from "react";
import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import merge from "lodash/merge";
import pubsub from "pubsub-js";
import ColorPalette from "@acl-services/sriracha-color-palette/dist/ColorPalette";
import { TREE_CHART } from "@visualizer/modules/visualization/highCharts/boost/boostThresholds";
import TreemapChartService from "@viz-ui/services/charts/treemapChart/treemapChartService";
import ReactHighChart from "../highChart/reactHighChart";
import {
  canAnimate,
  updateLabelFontSize,
  getBoostConfig,
  clickWrapper,
  baseConfig,
} from "../services/highChartService";

const baseFontSizeConfigPath = [
  "plotOptions.series.dataLabels.style.fontSize",
  "tooltip.style.fontSize",
  "xAxis.labels.style.fontSize",
  "xAxis.title.style.fontSize",
  "yAxis.labels.style.fontSize",
  "yAxis.title.style.fontSize",
];

const colorByFontSizeConfigPath = ["colorAxis.labels.style.fontSize", "legend.itemStyle.fontSize"];

const configPath = {
  labelFontSize: baseFontSizeConfigPath,
  labelFontSizeWithColorBy: [...colorByFontSizeConfigPath, ...baseFontSizeConfigPath],
};

class TreemapChart extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    config: PropTypes.objectOf(PropTypes.any),
    rawData: PropTypes.objectOf(PropTypes.any),
    redrawIndex: PropTypes.number,
    zoomInHandler: PropTypes.func,
    getFieldLabel: PropTypes.func,
    dataModel: PropTypes.objectOf(PropTypes.any).isRequired,
    appConfig: PropTypes.objectOf(PropTypes.any).isRequired,
    eventService: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    index: 0,
    config: {},
    rawData: {},
    redrawIndex: 0,
    zoomInHandler: () => {},
    getFieldLabel: () => {},
  };

  constructor(props) {
    super(props);
    this.state = { hcConfig: {}, chartReDraw: false };
    this.treemapChartServices = new TreemapChartService(
      ColorPalette,
      this.props.dataModel,
      this.props.appConfig,
      this.props.eventService
    );
  }

  componentDidMount() {
    pubsub.subscribe("chartRedraw", () => {
      this.setState({ chartReDraw: !this.state.chartReDraw });
    });

    this.setState({ hcConfig: this.getConfig(this.props.config, this.props.rawData) });
  }

  getConfig = (config, data) => {
    const that = this;
    let hcConfig = {
      legend: {
        enabled: config.showLegend,
        align: "right",
        verticalAlign: "top",
      },
      boost: getBoostConfig(config),
      colorAxis: that.treemapChartServices.getHighChartsColorAxis(config, data),
      plotOptions: {
        series: {
          point: {
            events: {
              click: clickWrapper(null, point => {
                that.zoomIn(
                  that.treemapChartServices.getNodeAndParentsDescending(point.node, point.series).map(node => node.name)
                );
              }),
            },
          },
          tooltip: {
            headerFormat: "",
            pointFormatter: function() {
              return that.treemapChartServices.tooltipPointFormatter(
                config,
                this.series,
                this.node,
                this.value,
                this.colorValue
              );
            },
          },
          turboThreshold: 0,
          animation: canAnimate(),
        },
      },
      series: [
        {
          type: "treemap",
          layoutAlgorithm: "squarified",
          allowDrillToNode: true,
          alternateStartingDirection: true,
          animationLimit: 1000,
          boostThreshold: TREE_CHART,
          colorIndex: 0,
          data: that.treemapChartServices.convertToHighChartData(data, config),
          levelIsConstant: true,
          levels: [
            {
              level: 1,
              dataLabels: {
                enabled: config.showFirstGroupLabels,
                formatter: function() {
                  return config.chartRows[0].valueFormatter(this.key);
                },
                align: "left",
                verticalAlign: "top",
                style: {
                  fontSize: "20px",
                  fontWeight: "bold",
                },
              },
              borderWidth: 3,
            },
            {
              level: 2,
              dataLabels: {
                enabled: config.showSecondGroupLabels,
                formatter: function() {
                  return config.chartRows[1].valueFormatter(this.key);
                },
              },
              borderWidth: 2,
            },
          ],
        },
      ],
    };
    // pattern-fill does not support gradient axis
    if (!getBoostConfig(config).enabled && this.isPatternFill) {
      if (!hcConfig.colorAxis)
        for (const [index, v] of hcConfig.series[0].data.entries()) {
          if (v.color) {
            v.color = this.patternFillPalettes(index);
          } else break;
        }
      else if (hcConfig.colorAxis.dataClasses) {
        Object.entries(hcConfig.colorAxis.dataClasses).forEach((v, index) => {
          v[1].color = this.patternFillPalettes(index); // eslint-disable-line no-param-reassign
        });
      }
    }

    const dataConfig = this.getDataConfig(data);
    const labelFontSizeConfigPaths = this.getLabelFontSizeConfigPaths(dataConfig);
    hcConfig = updateLabelFontSize(hcConfig, labelFontSizeConfigPaths);
    return merge({}, baseConfig(), hcConfig);
  };

  hasColorBy = config => config.values.length > 1;

  getLabelFontSizeConfigPaths = config => {
    const { labelFontSizeWithColorBy, labelFontSize } = configPath;
    return this.hasColorBy(config) ? labelFontSizeWithColorBy : labelFontSize;
  };

  zoomIn = values => {
    if (isFunction(this.props.zoomInHandler)) {
      this.props.zoomInHandler(values);
    }
  };

  getDataConfig = data => {
    if (data.modelType === "ChartData") {
      const dataConfig = data.dataConfig();
      return { rows: dataConfig.chartRows, values: dataConfig.chartValues };
    }
    return data.config;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.config !== nextProps.config)
      this.setState({ hcConfig: this.getConfig(nextProps.config, nextProps.rawData) });
  }

  render() {
    return (
      <ReactHighChart
        className={`redraw-index-${this.props.redrawIndex}`}
        config={this.state.hcConfig}
        redrawIndex={this.props.redrawIndex}
        chartReDraw={this.state.chartReDraw}
      />
    );
  }
}

export default TreemapChart;
