import i18n from "@viz-ui/i18n/i18n";
import { TableDataFormatter } from "@acl-services/sriracha-formatters/dist/Formatters";

let functions;

// Initialize the function names
updateDataTypeFunctionsNames();
// Update the function name if the locale changes
i18n.addObserver(updateDataTypeFunctionsNames);

function updateDataTypeFunctionsNames() {
  functions = {
    character: [
      {
        displayName: i18n.t("_Chart.Metrics.UniqueValues.Label_"),
        fieldName: "uniques",
      },
      {
        displayName: i18n.t("_Chart.Metrics.Blanks.Label_"),
        fieldName: "blanks",
      },
      {
        displayName: i18n.t("_Chart.Metrics.PercentOf.Label_"),
        fieldName: "percent-of",
      },
    ],
    numeric: [
      {
        displayName: i18n.t("_Chart.Metrics.UniqueValues.Label_"),
        fieldName: "count",
      },
      {
        displayName: i18n.t("_Chart.Metrics.Positives.Label_"),
        fieldName: "positives",
      },
      {
        displayName: i18n.t("_Chart.Metrics.Negatives.Label_"),
        fieldName: "negatives",
      },
      {
        displayName: i18n.t("_Chart.Metrics.Zeros.Label_"),
        fieldName: "zeros",
      },
      {
        displayName: i18n.t("_Chart.Metrics.Total.Label_"),
        fieldName: "total",
      },
      {
        displayName: i18n.t("_Chart.Metrics.Average.Label_"),
        fieldName: "average",
      },
      {
        displayName: i18n.t("_Chart.Metrics.Lowest.Label_"),
        fieldName: "lowest",
      },
      {
        displayName: i18n.t("_Chart.Metrics.Highest.Label_"),
        fieldName: "highest",
      },
      {
        displayName: i18n.t("_Chart.Metrics.StdDeviation.Label_"),
        fieldName: "standardDeviation",
      },
      {
        displayName: i18n.t("_Chart.Metrics.PercentOf.Label_"),
        fieldName: "percent-of",
      },
    ],
    datetime: [
      {
        displayName: i18n.t("_Chart.Metrics.Count.Label_"),
        fieldName: "count",
      },
      {
        displayName: i18n.t("_Chart.Metrics.Blanks.Label_"),
        fieldName: "blanks",
      },
      {
        displayName: i18n.t("_Chart.Metrics.Lowest.Label_"),
        fieldName: "lowest",
      },
      {
        displayName: i18n.t("_Chart.Metrics.Highest.Label_"),
        fieldName: "highest",
      },
      {
        displayName: i18n.t("_Chart.Metrics.PercentOf.Label_"),
        fieldName: "percent-of",
      },
    ],
    date: [
      {
        displayName: i18n.t("_Chart.Metrics.Count.Label_"),
        fieldName: "count",
      },
      {
        displayName: i18n.t("_Chart.Metrics.Blanks.Label_"),
        fieldName: "blanks",
      },
      {
        displayName: i18n.t("_Chart.Metrics.Lowest.Label_"),
        fieldName: "lowest",
      },
      {
        displayName: i18n.t("_Chart.Metrics.Highest.Label_"),
        fieldName: "highest",
      },
      {
        displayName: i18n.t("_Chart.Metrics.PercentOf.Label_"),
        fieldName: "percent-of",
      },
    ],
    time: [
      {
        displayName: i18n.t("_Chart.Metrics.Count.Label_"),
        fieldName: "count",
      },
      {
        displayName: i18n.t("_Chart.Metrics.Blanks.Label_"),
        fieldName: "blanks",
      },
      {
        displayName: i18n.t("_Chart.Metrics.Lowest.Label_"),
        fieldName: "lowest",
      },
      {
        displayName: i18n.t("_Chart.Metrics.Highest.Label_"),
        fieldName: "highest",
      },
      {
        displayName: i18n.t("_Chart.Metrics.PercentOf.Label_"),
        fieldName: "percent-of",
      },
    ],
  };
}

function _formatNumericCountValue(rawValue) {
  return TableDataFormatter.formatValue(rawValue, "numeric", { precision: 0, thousandsDelimiter: true });
}

function _formatStandardDeviationValue(rawValue) {
  return TableDataFormatter.formatValue(rawValue, "numeric", { precision: 2, thousandsDelimiter: true });
}

function _formatPercentValue(rawValue) {
  return TableDataFormatter.formatValue(rawValue, "percentage");
}

function _formatValue(rawValue, valueType, fieldFormatJson, utcOffsetMinutes) {
  const formatBlanks = false;

  // Simplify output's format when not explicitly set by user
  let simplifiedFieldFormatJson;
  if (fieldFormatJson) {
    simplifiedFieldFormatJson = { ...fieldFormatJson };
    if (typeof simplifiedFieldFormatJson.precision === "undefined") {
      simplifiedFieldFormatJson.precision = 2;
    }
  } else {
    simplifiedFieldFormatJson = { precision: 2, thousandsDelimiter: true };
  }

  return TableDataFormatter.formatValue(rawValue, valueType, simplifiedFieldFormatJson, formatBlanks, utcOffsetMinutes);
}

const DataTypeFunctions = {
  getFunctionsByDataType(dataType) {
    return functions[dataType] && functions[dataType].length ? functions[dataType] : [];
  },

  getFunctionDataType(functionName, fieldType) {
    const isTemporalFieldTypes = ["datetime", "date", "time"].includes(fieldType);
    const isMinOrMaxOrAvg = ["average", "lowest", "highest"].includes(functionName);
    if (isTemporalFieldTypes && isMinOrMaxOrAvg) {
      return fieldType;
    }
    return "numeric";
  },

  canFunctionAcceptDataType(functionName, dataType) {
    let i;
    const funcs = this.getFunctionsByDataType(dataType);

    for (i = 0; i < funcs.length; i++) {
      if (funcs[i].fieldName === functionName) {
        return true;
      }
    }
    return false;
  },

  formatTypedMetricValueByFunctionWithFormatting(
    metricFunction,
    rawValue,
    valueType,
    fieldFormatJson,
    utcOffsetMinutes
  ) {
    const isTemporalFieldType = ["time", "datetime", "date"].includes(valueType);
    const isTemporalValueEmpty = isTemporalFieldType && rawValue === 0;

    if (isTemporalValueEmpty) {
      return "0";
    }

    switch (metricFunction) {
      case "count":
      case "blanks":
      case "negatives":
      case "positives":
      case "uniques":
      case "zeros":
        return _formatNumericCountValue(rawValue);
      case "standardDeviation":
        return _formatStandardDeviationValue(rawValue);
      case "percent-of":
        return _formatPercentValue(rawValue);
      case "average":
      case "highest":
      case "lowest":
      case "total":
      default:
        return _formatValue(rawValue, valueType, fieldFormatJson, utcOffsetMinutes);
    }
  },
};

export default DataTypeFunctions;
