import React from "react";
import PropTypes from "prop-types";
import checkboxState from "@viz-ui/models/checkboxState";
import objectValues from "lodash/values";
import AsyncCallManager from "@viz-ui/services/asyncCallManager/asyncCallManager";
import FieldValueManager from "@viz-ui/services/fieldValue/fieldValueManager";
import FieldValueStore from "@viz-ui/services/fieldValue/fieldValueStore";
import FilterValueAdapter from "@viz-ui/models/storyboardFilter/filterValueAdapter";
import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";
import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";
import i18n from "@viz-ui/i18n/i18n";
import MultiSelectFilter from "./multiSelectFilter";

export default class ValueFilter extends React.Component {
  static propTypes = {
    field: PropTypes.oneOfType(PropTypes.object).isRequired,
    tableId: PropTypes.string.isRequired,
    fieldFormatIdentifier: PropTypes.string.isRequired,
    selectAllState: PropTypes.oneOf([checkboxState.CHECKED, checkboxState.INDETERMINATE, checkboxState.UNCHECKED])
      .isRequired,
    filterDisabled: PropTypes.bool,
    prevSelectedValues: PropTypes.arrayOf(PropTypes.string),
    selectedValues: PropTypes.arrayOf(PropTypes.string),
    indeterminateValues: PropTypes.arrayOf(PropTypes.string),
    storyboardIndicatorValues: PropTypes.arrayOf(PropTypes.string),
    onSelectAllClick: PropTypes.func,
    onToggle: PropTypes.func,
    onIsLoadingChanged: PropTypes.func,
  };

  static defaultProps = {
    filterDisabled: false,
    prevSelectedValues: [],
    selectedValues: [],
    indeterminateValues: [],
    storyboardIndicatorValues: [],
    onSelectAllClick: () => {},
    onToggle: () => {},
    onIsLoadingChanged: () => {},
  };

  constructor(props) {
    super(props);

    this.searchValue = null;
    this.dataSource = null;
    this.doLoad = AsyncCallManager.resolveOnlyLastCallTo(() => this.dataSource.load());

    this.state = {
      values: [],
      totalValuesCount: 0,
      isDataLoading: true,
      showLoadMoreButton: false,
      errorMessage: null,
    };
  }

  componentDidMount() {
    if (!this.dataSource) {
      this.updateDataSource();
    }
  }

  componentDidUpdate(prevProps) {
    //load data for newly selected field
    if (this.props.field !== prevProps.field) {
      this.updateDataSource();
    }
  }

  onIsLoadingChanged(newValue) {
    if (this.props.onIsLoadingChanged) {
      this.props.onIsLoadingChanged(newValue);
    }
  }

  getRequestData(field) {
    const timezoneOffset = GlobalValueFormatter.getTimezoneOffset();

    return {
      fieldName: field.fieldName,
      fieldType: field.type,
      tableId: this.props.tableId,
      search: this.searchValue,
      timezoneOffset: timezoneOffset ? timezoneOffset.utcOffsetString() : undefined,
    };
  }

  handleLoadMoreClick = () => {
    this.setState({
      isDataLoading: true,
    });

    this.load();
  };

  handleToggle = (field, value, totalLoadedValuesLength, filterApplied) => {
    if (this.props.onToggle) {
      this.props.onToggle(field, value, totalLoadedValuesLength, filterApplied);
    }
  };

  handleSelectAllClick = (
    field,
    action,
    visibleValues,
    totalLoadedValuesLength,
    filterApplied,
    selectAllStatePriorSearch
  ) => {
    if (this.props.onSelectAllClick) {
      this.props.onSelectAllClick(
        field,
        action,
        visibleValues,
        totalLoadedValuesLength,
        filterApplied,
        selectAllStatePriorSearch
      );
    }
  };

  handleSearchChange = value => {
    this.searchValue = value;

    this.setState({
      isDataLoading: true,
    });

    this.dataSource = null;
    this.updateDataSource();
  };

  showLoadMoreButton = () => this.dataSource && this.dataSource.hasMoreValues();

  updateDataSource() {
    const { field } = this.props;

    if (field) {
      const requestData = this.getRequestData(field);

      if (this.isRequestDataValid(requestData)) {
        this.dataSource = FieldValueStore.getDataSource(FieldValueManager, requestData);
        this.dataSourceChanged();
      }
    }
  }

  isRequestDataValid(requestData) {
    return !objectValues(requestData).includes(undefined);
  }

  dataSourceChanged() {
    if (!this.props.field || !this.props.field.isQuickFilterable) {
      this.updateValuesState([], 0, false, null);
      return;
    }

    if (this.dataSource.isLoaded()) {
      this.updateValuesState(this.dataSource.valuesRef(), this.dataSource.countRef(), false, null);
    } else {
      this.load();
    }
  }

  load() {
    this.onIsLoadingChanged(true);

    this.doLoad().then(
      data => {
        this.updateValuesState(data.values, data.count, false, null);
        UsageTracker.mark("this.props.loaded");
        this.onIsLoadingChanged(false);
      },
      error => {
        let errorMessage = i18n.t("_Filter.Values.Load.Error." + error + "_");
        if (!errorMessage) {
          errorMessage = i18n.t("_Filter.Values.Load.Error_");
        }

        this.updateValuesState(null, 0, false, error !== AsyncCallManager.CALL_DROPPED ? errorMessage : "");
        this.onIsLoadingChanged(false);

        if (error !== AsyncCallManager.CALL_DROPPED) {
          throw error;
        }
      }
    );
  }

  updateValuesState(valueModels, count, isDataLoading, errorMessage) {
    this.setState({
      values: FilterValueAdapter.serializeFilterValues(valueModels),
      totalValuesCount: count,
      isDataLoading: isDataLoading,
      errorMessage: errorMessage,
      showLoadMoreButton: this.showLoadMoreButton(),
    });
  }

  render() {
    return (
      <MultiSelectFilter
        field={this.props.field}
        filterDisabled={this.props.filterDisabled}
        fieldFormatIdentifier={this.props.fieldFormatIdentifier}
        prevSelectedValues={this.props.prevSelectedValues}
        selectedValues={this.props.selectedValues}
        indeterminateValues={this.props.indeterminateValues}
        storyboardIndicatorValues={this.props.storyboardIndicatorValues}
        selectAllState={this.props.selectAllState}
        values={this.state.values}
        totalValuesCount={this.state.totalValuesCount}
        isDataLoading={this.state.isDataLoading}
        showLoadMoreButton={this.state.showLoadMoreButton}
        errorMessage={this.state.errorMessage}
        onLoadMoreClick={this.handleLoadMoreClick}
        onSearchChange={this.handleSearchChange}
        onToggle={this.handleToggle}
        onSelectAllClick={this.handleSelectAllClick}
      />
    );
  }
}
