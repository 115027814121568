import PropTypes from "prop-types";
import Modal from "@paprika/modal";
import Button from "@paprika/button";
import Spinner from "@paprika/spinner";

import React from "react";
import i18n from "@viz-ui/i18n/i18n";

function DownloadModal(props) {
  function showSpinner() {
    if (!props.showDownload && !props.downloadError) return <Spinner size="small" className={"download-spinner"} />;
  }
  function handleDownloadClick() {
    if (props.showDownload) {
      props.onModalCancel();
    }
  }

  return (
    <Modal isOpen={props.isOpen} size="small">
      <Modal.FocusLock autoFocus={false} />
      <Modal.Header hasCloseButton={false}>
        {props.downloadError ? i18n.t("_DownloadModal.Header.Error.Label_") : i18n.t("_DownloadModal.Header.Label_")}
      </Modal.Header>
      <Modal.Content>
        <div class="content-flex">
          {showSpinner()}
          <div>
            {props.downloadError
              ? i18n.t("_DownloadModal.Content.Error.message_")
              : i18n.t("_DownloadModal.Content.message_")}
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer>
        {props.downloadError ? (
          ""
        ) : (
          <Button.Link
            kind="primary"
            href={props.downloadLink}
            download={props.fileName}
            isDisabled={!props.showDownload}
            onClick={handleDownloadClick}
          >
            {i18n.t("_DownloadModal.Footer.Button.Download_")}
          </Button.Link>
        )}

        <Button kind="default" className={"download-cancel__margin"} onClick={props.onModalCancel}>
          {props.downloadError ? i18n.t("_General.Remove.Label_") : i18n.t("_General.Cancel.Label_")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

DownloadModal.propTypes = {
  isOpen: PropTypes.bool,
  showDownload: PropTypes.bool,
  onClose: PropTypes.func,
  onModalCancel: PropTypes.func,
  downloadLink: PropTypes.string,
  downloadError: PropTypes.bool,
  fileName: PropTypes.string,
};

DownloadModal.defaultProps = {
  isOpen: false,
  showDownload: false,
  onClose: () => {},
  onModalCancel: () => {},
  downloadLink: "",
  downloadError: false,
  fileName: "storyboard",
};

export default DownloadModal;
