import React from "react";
import PropTypes from "prop-types";
import ListBox from "@paprika/list-box";
import L10n from "@paprika/l10n";
import i18n from "@viz-ui/i18n/i18n";

const propTypes = {
  dataConfig: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      option: PropTypes.arrayOf(
        PropTypes.shape({
          field: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ),
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  selectedField: PropTypes.string,
  onSelectChange: PropTypes.func,
};

const defaultProps = {
  dataConfig: [],
  hasError: false,
  isDisabled: false,
  className: "",
  selectedField: null,
  onSelectChange: () => {},
};

const getListBoxProps = props => ({
  zIndex: 9999,
  size: ListBox.types.size.MEDIUM,
  isDisabled: props.isDisabled,
  hasError: props.hasError,
});

const ListBoxComponent = props => {
  const { ...listBoxProps } = getListBoxProps(props);
  const [selectedField, setSelectedField] = React.useState();

  React.useEffect(() => {
    setSelectedField(() => props.selectedField);
    props.onSelectChange({ selectedField: props.selectedField });
  }, [props.selectedField, setSelectedField]);

  const handleClear = () => {
    setSelectedField(() => null);
  };

  const handleChange = (index, options) => {
    const selectedValue = options[index].value;
    setSelectedField(() => selectedValue);
    props.onSelectChange({ selectedField: selectedValue });
  };

  return (
    <div className={props.className}>
      <L10n locale={i18n.locale}>
        <ListBox onChange={handleChange} {...listBoxProps}>
          <ListBox.Filter />
          <ListBox.Popover zIndex={9999} />
          <ListBox.Trigger onClickClear={handleClear} />
          {props.dataConfig.map(options => (
            <React.Fragment key={options.name}>
              {options.name ? <ListBox.Divider key={options.name}>{options.name}</ListBox.Divider> : null}
              {options.option.map(option => (
                <ListBox.Option
                  key={option.field}
                  value={option.field}
                  className="list-box__option"
                  defaultIsSelected={selectedField === option.field}
                >
                  {option.value}
                </ListBox.Option>
              ))}
            </React.Fragment>
          ))}
        </ListBox>
      </L10n>
    </div>
  );
};

ListBoxComponent.displayName = "listBoxComponent";
ListBoxComponent.propTypes = propTypes;
ListBoxComponent.defaultProps = defaultProps;

export default ListBoxComponent;
