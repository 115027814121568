import PropTypes from "prop-types";
import React from "react";
import services from "../../../services/glue/appGlue";

class RoleSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  getDefaultState() {
    return {
      roles: [
        {
          value: "viewer",
          option: services.Localize.getLocalizedString("_Storyboards.SharePanel.MembersTable.Permissions.Viewer_"),
        },
        {
          value: "editor",
          option: services.Localize.getLocalizedString("_Storyboards.SharePanel.MembersTable.Permissions.Editor_"),
        },
      ],
    };
  }

  render() {
    let customCssClass = "roles";
    return (
      <SelectOptions
        containerCssClass={customCssClass}
        dropdownCssClass={customCssClass}
        hideSearchBox={true}
        options={this.state.roles}
        selectedValue={this.props.selectedRole}
        onOpenDropdown={this.props.onOpenDropdown}
        onCloseDropdown={this.props.onCloseDropdown}
      />
    );
  }
}

RoleSelect.propTypes = {
  selectedRole: PropTypes.string,
  onChange: PropTypes.func,
};

window.RoleSelect = RoleSelect;
