import React from "react";
import PropTypes from "prop-types";
import NumberSign from "@acl-services/wasabicons/lib/Number";
import Clock from "@acl-services/wasabicons/lib/Clock";
import TimeAndDate from "@acl-services/wasabicons/lib/TimeAndDate";
import Calendar from "@acl-services/wasabicons/lib/Calendar";
import Character from "@acl-services/wasabicons/lib/Character";
import FunctionSymbol from "@acl-services/wasabicons/lib/FunctionSymbol";
import Boolean from "@acl-services/wasabicons/lib/Boolean";
import FileText from "@acl-services/wasabicons/lib/FileText";
import DigitalSignature from "@acl-services/wasabicons/lib/DigitalSignature";
import CaretRight from "@acl-services/wasabicons/lib/CaretRight";
import "./dataTypeIcon.scss";

const { string } = PropTypes;
const DataTypeIcon = props => {
  switch (props.type) {
    case "numeric":
      return <NumberSign aria-label="numbers" className="panel-column-type__icon" />;
    case "time":
      return <Clock aria-label="clock" className="panel-column-type__icon" />;
    case "datetime":
      return <TimeAndDate aria-label="date and time" className="panel-column-type__icon" />;
    case "date":
      return <Calendar aria-label="calender" className="panel-column-type__icon" />;
    case "character":
      return <Character aria-label="character" className="panel-column-type__icon" />;
    case "count":
      return <FunctionSymbol aria-label="function" className="panel-column-type__icon" />;
    case "logical":
      return <Boolean aria-label="logical" className="panel-column-type__icon" />;
    case "file":
      return <FileText aria-label="text file" className="panel-column-type__icon" />;
    case "digital_signature":
      return <DigitalSignature aria-label="digital signature" className="panel-column-type__icon" />;
    default:
      return <CaretRight aria-label="Caret Label" className="panel-column-type__icon" />;
  }
};
DataTypeIcon.propTypes = {
  type: string,
};
DataTypeIcon.defaultProps = {
  type: "",
};
export default DataTypeIcon;
