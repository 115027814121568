import Model from "../model";
import FieldFormat from "../field/fieldFormat";
import FilterField from "./filterField";
import FilterValue from "./filterValue";

class StoryboardFilter extends Model {
  static modelType = "StoryboardFilter";

  id(...args) {
    return this.accessor("id", args, "string");
  }

  displayName(...args) {
    return this.accessor("displayName", args, "string");
  }

  isNew() {
    return !this.id();
  }

  isActive(...args) {
    return this.accessor("isActive", args, "boolean");
  }

  type(...args) {
    return this.accessor("type", args, "string");
  }

  operator(...args) {
    return this.accessor("operator", args, "string");
  }

  value(...args) {
    return this.accessor("value", args, FilterValue);
  }

  isValueDefined() {
    return !!this._data.value;
  }

  values(...args) {
    return this.accessor("values", args, [FilterValue]);
  }

  isValuesDefined() {
    return !!this._data.values;
  }

  filterFields(...args) {
    return this.accessor("fields", args, [FilterField]);
  }

  fieldFormat(...args) {
    return this.accessor("fieldFormat", args, FieldFormat);
  }

  fieldType(...args) {
    return this.accessor("fieldType", args, "string");
  }

  excludeToday(...args) {
    return this.accessor("excludeToday", args, "boolean");
  }

  selectAllState(...args) {
    return this.accessor("selectAllState", args, "string");
  }

  unSelectedValues(...args) {
    return this.accessor("unSelectedValues", args, [FilterValue]);
  }

  isUnSelectedValuesDefined() {
    return !!this._data.unSelectedValues;
  }

  clone() {
    return new StoryboardFilter(this._data);
  }

  isSelectedDynamicFilter(...args) {
    return this.accessor("isSelectedDynamicFilter", args, "boolean");
  }

  isCurrentDynamicFilter(...args) {
    return this.accessor("isCurrentDynamicFilter", args, "boolean");
  }

  isSelectedFrozenFilter(...args) {
    return this.accessor("isSelectedFrozenFilter", args, "boolean");
  }

  isFrozenFilter(...args) {
    return this.accessor("isFrozenFilter", args, "boolean");
  }

  isCurrentEditedDynamicFilter(...args) {
    return this.accessor("isCurrentEditedDynamicFilter", args, "boolean");
  }

  isCurrentSessionFilter(...args) {
    return this.accessor("isCurrentSessionFilter", args, "boolean");
  }
}

export default StoryboardFilter;
