class AssignmentActions {
  static addUserAssignment(userAssignment) {
    return {
      type: "ADD_USER_ASSIGNMENT",
      userAssignment,
    };
  }

  static addGroupAssignment(groupAssignment) {
    return {
      type: "ADD_GROUP_ASSIGNMENT",
      groupAssignment,
    };
  }

  static removeAssignment(assignmentId) {
    return {
      type: "REMOVE_ASSIGNMENT",
      assignmentId,
    };
  }

  static updateAssignmentRole(assignmentId, role) {
    return {
      type: "UPDATE_ASSIGNMENT_ROLE",
      assignmentId,
      role,
    };
  }
}

window.AssignmentActions = AssignmentActions;
