import React, { Component } from "react";
import ListBox from "@paprika/list-box";
import Input from "@paprika/input";
import Popover from "@paprika/popover";
import i18n from "@viz-ui/i18n/i18n";
import PropTypes from "prop-types";
import Switch from "@paprika/switch";
import ConditionalFilter from "../../models/storyboardFilter/conditionalFilter";
import "./relativeDateFilter.scss";

const RELATIVE_TYPES = ["current", "last", "next"];
const SINGULAR_UNITS = ["day", "week", "month", "quarter", "year"];
const PLURAL_UNITS = ["days", "weeks", "months", "quarters", "years"];
const RELATIVE_UNIT_TYPES = {
  0: SINGULAR_UNITS,
  1: PLURAL_UNITS,
  2: PLURAL_UNITS,
};

export default class RelativeDateFilter extends Component {
  static propTypes = {
    filter: PropTypes.instanceOf(ConditionalFilter).isRequired,
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    isSelectionValid: PropTypes.bool,
  };

  static defaultProps = {
    onChange: () => {},
    isDisabled: false,
  };

  constructor(props) {
    super(props);
    const filter = this.props.filter;
    const excludeToday = filter.excludeToday();
    const relativeType = filter.value1();
    const units = filter.value2();
    const unitType = filter.value3();
    const relativeTypeIdx = RELATIVE_TYPES.indexOf(relativeType);
    const unitTypeIdx =
      filter.value2() === ""
        ? RELATIVE_UNIT_TYPES[0].indexOf(unitType)
        : RELATIVE_UNIT_TYPES[relativeTypeIdx].indexOf(unitType);

    this.state = {
      relativeTypeIdx,
      units: units || "",
      unitTypeIdx,
      excludeToday: excludeToday,
    };
    this.handleRelativeTypeChange = this.handleRelativeTypeChange.bind(this);
    this.handleUnitTypeChange = this.handleUnitTypeChange.bind(this);
    this.handleUnitChange = this.handleUnitChange.bind(this);

    this.textInput = "";
    this.focusTextInput = this.focusTextInput.bind(this);
  }

  focusTextInput() {
    this.focus && this.textInput && this.textInput.focus();
    this.focus = false;
  }

  handleItemClick = () => {
    this.focus = true;
  };

  //to handle case when user enters unit greater than one
  //so in those situation, conversion to plural for unit type is needed for automatic change
  //eg "day" to "days"
  static convertToPlural = unitType => {
    const unitTypeIndex = SINGULAR_UNITS.indexOf(unitType);
    if (unitTypeIndex !== -1) {
      return PLURAL_UNITS[unitTypeIndex];
    }
    return unitType;
  };

  // To handle GRCPRD-7424, to reset the relative date filter values
  static getDerivedStateFromProps(nextProps, prevState) {
    const { filter } = nextProps;
    let newState = {};

    const excludeToday = filter.excludeToday();
    if (excludeToday !== prevState.excludeToday) {
      newState = { ...newState, excludeToday: excludeToday };
    }

    const units = filter.value2();
    if (units !== prevState.units) {
      newState = { ...newState, units: units };
    }

    const relativeType = filter.value1();
    const relativeTypeIdx = RELATIVE_TYPES.indexOf(relativeType);
    if (relativeTypeIdx !== prevState.relativeTypeIdx) {
      newState = { ...newState, relativeTypeIdx };
    }

    const unitType = units !== "" ? RelativeDateFilter.convertToPlural(filter.value3()) : filter.value3();
    const unitTypeIdx =
      filter.value2() === ""
        ? RELATIVE_UNIT_TYPES[0].indexOf(unitType)
        : RELATIVE_UNIT_TYPES[relativeTypeIdx].indexOf(unitType);
    if (unitTypeIdx !== prevState.unitTypeIdx) {
      newState = { ...newState, unitTypeIdx };
    }

    return { ...prevState, ...newState };
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter.value1() !== prevProps.filter.value1()) this.focusTextInput();
  }

  handleRelativeTypeChange = (selectedIdx, values) => {
    this.setState({
      relativeTypeIdx: selectedIdx,
    });
    if (selectedIdx === 0) this.setState({ units: "" });
    this.props.onChange("value1", values[selectedIdx].value);
  };

  handleUnitTypeChange = (selectedIdx, values) => {
    this.setState({
      unitTypeIdx: selectedIdx,
    });
    this.props.onChange("value3", values[selectedIdx].value);
  };

  handleUnitChange = evt => {
    let value = evt.target.value;
    value = value.replace(/\D/g, "").replace(/^0/g, "");
    if (value.length > 3) {
      value = value.substring(0, 3);
    }
    this.setState({
      units: value,
    });
    this.props.onChange("value2", value);
  };

  renderRelativeTypes() {
    return (
      <ListBox
        isDisabled={this.props.isDisabled}
        key="dropdown"
        className="relative-type"
        onChange={this.handleRelativeTypeChange}
      >
        <ListBox.Trigger hasClearButton={false} className="relative-type-trigger" />
        {RELATIVE_TYPES.map((type, index) => (
          <ListBox.Option
            value={type}
            onClick={this.handleItemClick}
            defaultIsSelected={index === this.state.relativeTypeIdx}
          >
            {i18n.t(`_RelativeDate.RelativeType.${type}_`)}
          </ListBox.Option>
        ))}
      </ListBox>
    );
  }

  renderUnits() {
    if (this.state.relativeTypeIdx === 0) {
      this.props.onChange("value2", "");
      return "";
    }
    return (
      <Popover className="relative_date_popover" maxWidth={255} isEager offset={8} zIndex={1000}>
        <Popover.Trigger>
          {handler => (
            <Input
              inputRef={input => {
                this.textInput = input;
              }}
              className="relative-input"
              placeholder="--"
              type="text"
              pattern="[0-9]"
              inputMode="numeric"
              value={this.state.units}
              isDisabled={this.props.isDisabled || this.props.filter.value1() === ""}
              onChange={this.handleUnitChange.bind(this)}
              onMouseOver={event => {
                if (!this.props.isDisabled) handler(event);
              }}
              onMouseOut={event => {
                if (!this.props.isDisabled) handler(event);
              }}
              onBlur={() => {
                this.focus = false;
                this.renderUnitTypes();
              }}
            />
          )}
        </Popover.Trigger>
        <Popover.Content>
          <Popover.Card>
            <div>
              {i18n.t("_RelativeDate.UnitValue.Tooltip_")}
              <b>{i18n.t("_RelativeDate.UnitValue.Tooltip.Eg_")}</b>
              {i18n.t("_RelativeDate.UnitValue.Tooltip.Eg.text_")}
              <a
                href="https://help.highbond.com/helpdocs/highbond/en-us/Content/results/storyboards/filtering_storyboards.htm#Relative"
                target="_blank"
              >
                <b>{i18n.t("_RelativeDate.UnitValue.Tooltip.Link_")}</b>
              </a>
            </div>
          </Popover.Card>
        </Popover.Content>
        <Popover.Tip />
      </Popover>
    );
  }

  renderUnitTypes = () => {
    let values;
    let key;
    if (this.state.units === "") {
      values = RELATIVE_UNIT_TYPES[0];
      key = "singular-list";
    } else {
      values = RELATIVE_UNIT_TYPES[this.state.relativeTypeIdx];
      key = "plural-list";
    }
    this.props.onChange("value3", values[this.state.unitTypeIdx]);
    return (
      <ListBox
        isDisabled={this.props.isDisabled}
        key={key}
        className={this.props.isSelectionValid ? "relative-type" : "relative-type invalid-type"}
        onChange={this.handleUnitTypeChange}
      >
        <ListBox.Trigger hasClearButton={false} className="relative-type-trigger" />
        {values.map((type, index) => (
          <ListBox.Option value={type} defaultIsSelected={index === this.state.unitTypeIdx}>
            {i18n.t(`_RelativeDate.UnitType.${type}_`)}
          </ListBox.Option>
        ))}
      </ListBox>
    );
  };

  renderToggleSwitch = () => {
    if (this.state.unitTypeIdx === 0 && parseInt(this.state.units) > 1) {
      return (
        <div className="exclude-today__switch">
          <Switch
            id="switch-item__toggle"
            isChecked={this.state.excludeToday}
            isDisabled={this.props.isDisabled}
            size="small"
            onChange={this.handleToggleChange}
          />
          <label
            htmlFor="switch-item__toggle"
            className={`switch-item__label ${this.props.isDisabled ? "disable-text" : ""}`}
            onClick={this.handleToggleChange}
          >
            <b>{i18n.t("_RelativeDate.Switch.Label_")}</b>
          </label>
        </div>
      );
    }
  };

  handleToggleChange = () => {
    this.setState(prev => ({ excludeToday: !prev.excludeToday }));
    this.props.onChange("excludeToday", !this.state.excludeToday);
  };

  render() {
    return (
      <div className="relative-date-input-border">
        <div className={this.props.isSelectionValid ? "relative-date-input" : "relative-date-input invalid-type"}>
          {this.renderRelativeTypes()}
          <div className="unit-types">
            {this.renderUnits()}
            {this.renderUnitTypes()}
          </div>
        </div>
        {this.renderToggleSwitch()}
      </div>
    );
  }
}
