import logger from "@viz-ui/services/logger/logger";

angular.module("acl.visualizer.charts.backend", [
  "acl.visualizer.charts",
  "acl.visualizer.dataFilter",
  "acl.visualizer.saveViz",
  "acl.visualizer.temporalDiff",
  "acl.visualizer.model.chart",
  "acl.common.event",
  "acl.common.queuedHttp",
]);

require("./chartDataQueryBackend.service");
require("./chartsBackendService");
require("./barChartBackendService");
require("./bubbleChartBackendService");
require("./lineChartBackendService");
require("./statisticsVizBackendService");
require("./summaryTableBackendService");
require("./pieChartBackendService");
require("./stackedAreaChartBackendService");
require("./treemapBackendService");
require("./combinationChartBackendService");
require("./mapChartBackendService");

angular
  .module("acl.visualizer.charts.backend")
  .run(function(
    BarChartBackendService,
    BubbleChartBackendService,
    ChartDataQueryBackendService,
    EventService,
    LineChartBackendService,
    PieChartBackendService,
    StackedAreaChartBackendService,
    StatisticsVizBackendService,
    SummaryTableBackendService,
    TreemapBackendService,
    CombinationChartBackendService,
    MapChartBackendService
  ) {
    var eventService = EventService.register("aclax.shared.charts.BarChart");
    var backendService = {
      BarChart: BarChartBackendService,
      BubbleChart: BubbleChartBackendService,
      PieChart: PieChartBackendService,
      StackedAreaChart: StackedAreaChartBackendService,
      LineChart: LineChartBackendService,
      StatisticsViz: StatisticsVizBackendService,
      SummaryTable: SummaryTableBackendService,
      Heatmap: BubbleChartBackendService,
      Treemap: TreemapBackendService,
      CombinationChart: CombinationChartBackendService,
      MapChart: MapChartBackendService,
    };

    const delegate = delegateDataRequest("chartData", ChartDataQueryBackendService.chartDataRequest);
    eventService.subscribe("request.chartData", delegate);

    eventService.subscribe("chartData.configChanged", function(
      e,
      tabIndex,
      type,
      tableIdentifier,
      filterConfig,
      dataConfig
    ) {
      backendService[type].fetchJson(tableIdentifier, filterConfig, dataConfig).then(
        function({ data: response }) {
          let representation = backendService[type].getRepresentation(response.result.data, dataConfig);
          eventService.publish("chartData.loaded", tabIndex, type, dataConfig, representation, null);
        },
        function({ data: response }) {
          eventService.publish("chartData.loaded", tabIndex, type, dataConfig, null, response);
        }
      );
    });

    eventService.subscribe("biView.configChanged", function(e, type, tableIdentifier, filterConfig, dataConfig) {
      backendService[type].fetchJson(tableIdentifier, filterConfig, dataConfig, true).then(
        function({ data: response }) {
          var representation = backendService[type].getRepresentation(response.result.data, dataConfig);
          eventService.publish("biView.loaded", type, dataConfig, representation, null);
        },
        function({ data: response }) {
          eventService.publish("biView.loaded", type, dataConfig, null, response);
        }
      );
    });

    function delegateDataRequest(eventName, requestFunction) {
      return function(_, token) {
        var args = Array.prototype.slice.call(arguments, 2);
        requestFunction(...args)
          .then(function(model) {
            if (
              model.type &&
              model.type() !== "SummaryTable" &&
              model.type() !== "Treemap" &&
              model.type() !== "MapChart"
            ) {
              logger.log(`chart data representation - Start`);
              let data = model.data();
              let dataConfig = model.dataConfig();
              let representation = backendService[model.type()].getRepresentation(data, dataConfig);
              model.representation(representation);
              logger.log(`chart data representation - End`);
            }
            eventService.publish("resolve." + eventName, token, model);
          })
          .catch(function(error) {
            eventService.publish("reject." + eventName, token, error);
          });
      };
    }
  });
