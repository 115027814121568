import DataRequest from "./dataRequest";

class DataRequester {
  constructor() {
    this.dataRequests = {};
  }

  getInstance(eventName) {
    let dataRequest = this.dataRequests[eventName];
    if (dataRequest) {
      return dataRequest;
    }

    dataRequest = new DataRequest(eventName, () => {
      this.dataRequests[eventName] = null;
    });
    this.dataRequests[eventName] = dataRequest;

    return dataRequest;
  }
}

export default new DataRequester();
