import { Environments as env } from "./environments";

export default {
  airbrake: {
    [env.STAGING]: {
      credentials: {
        projectId: 165274,
        projectKey: "3d766ae567c0f2a5bd8d820f04d2d09b",
      },
    },
    [env.PRODUCTION]: {
      credentials: {
        projectId: 169990,
        projectKey: "c2218dfa99e86b7e23908020bf7a2237",
      },
    },
  },
};
