import $ from "jquery";

function isRowSelected(props, rowData, rowSelection) {
  const selectedRowId = rowSelection.getSelectedRowId();
  if (selectedRowId === undefined) return false;

  const rowDataId = getRowDataId(rowData, props.rowIdKey);
  return selectedRowId === rowDataId;
}

function isRowChecked(props, checkedRowIds, rowData) {
  const rowDataId = getRowDataId(rowData, props.rowIdKey);
  const idFound = !!(typeof rowDataId !== "undefined" && checkedRowIds.indexOf(rowDataId) >= 0);
  return props.rowSelectInverse ? !idFound : idFound;
}

function getRowDataId(rowData, rowIdKey) {
  return rowData && rowData[rowIdKey] && rowData[rowIdKey].value;
}

function isCheckAllClicked(props, row, col, targetElement) {
  return (
    row === -1 &&
    col === checkAllColumnId(props) &&
    targetElement.nodeName === "INPUT" &&
    targetElement.className === "data-grid__select-all"
  );
}

function checkAllColumnId(props) {
  if (props.isRowSelectionEnabled) {
    return props.isPositionEnabled ? 1 : 0;
  }
}

function highlightSelectedRow(props, rowSelection, rowData) {
  const rowDataId = getRowDataId(rowData, props.rowIdKey);
  $(".handsontable td.selected-row").removeClass("selected-row");
  const tableClasses = [".ht_master", ".ht_clone_left"];
  tableClasses.forEach(tableClass => {
    $(`${tableClass} table td[data-record-id="${rowDataId}"]`).addClass("selected-row");
  });
}

export { isRowChecked, isRowSelected, getRowDataId, isCheckAllClicked, checkAllColumnId, highlightSelectedRow };
