import ReactDirective from "acl-ui/angular/reactDirective.umd";
import saveVisualizationModal from "@viz-ui/components/saveVisualizationModal";

angular
  .module("acl.visualizer.saveViz", [
    "acl.common.config",
    "acl.common.event",
    "acl.common.localize",
    "acl.visualizer.charts",
    "acl.visualizer.dataModel",
    "ui.router",
    "ui.bootstrap",
  ])
  .directive("vizSaveVisualizationModal", ReactDirective.getFactory(saveVisualizationModal));
