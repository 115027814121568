function reorderFieldModelsByColumnConfigs(fieldModels, columnConfigs) {
  return getFieldMapping(fieldModels, columnConfigs).map(function(d) {
    return fieldModels[d];
  });
}

// Returns an array where the element index is the index of the output column
// and the element value is the index of the input field or data column.
function getFieldMapping(fieldModels, columnConfigs) {
  var fieldModelIndex;
  var hiddenFieldNames = [];
  var result = [];

  columnConfigs.forEach(function(columnConfig) {
    if (columnConfig.visible()) {
      fieldModelIndex = getFieldModelIndexByFieldName(fieldModels, columnConfig.name());
      if (fieldModelIndex >= 0) {
        result.push(fieldModelIndex);
      }
    } else {
      hiddenFieldNames.push(columnConfig.name());
    }
  });

  fieldModels.forEach(function(fieldModel, i) {
    if (result.indexOf(i) < 0 && hiddenFieldNames.indexOf(fieldModel.name()) < 0) {
      result.push(i);
    }
  });

  return result;
}

function getFieldModelIndexByFieldName(fieldModels, fieldName) {
  for (let i = 0; i < fieldModels.length; i++) {
    if (fieldModels[i].name() === fieldName) {
      return i;
    }
  }
  return -1;
}

export default reorderFieldModelsByColumnConfigs;
