import AggregatorArgs from "@viz-ui/models/metric/aggregatorArgs";
import Metric from "@viz-ui/models/metric/metric";
import MetricConfig from "@viz-ui/models/metric/metricConfig";
import MetricSparkline from "@viz-ui/models/metric/metricSparkline";
import MetricSummary from "@viz-ui/models/metric/metricSummary";

angular
  .module("acl.visualizer.model.metric", [
    "acl.common.config",
    "acl.common.event",

    // FIXME: Move ConditionalOperators to a different module and include that here.
    "acl.core.conditionalCriteriaSelector",

    "acl.visualizer.model.field",
    "acl.visualizer.model.filter",
    "acl.visualizer.model.model",
    "acl.visualizer.notification",
  ])
  .factory("AggregatorArgs", () => AggregatorArgs)
  .factory("Metric", () => Metric)
  .factory("MetricConfig", () => MetricConfig)
  .factory("MetricSparkline", () => MetricSparkline)
  .factory("MetricSummary", () => MetricSummary);
