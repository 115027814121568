import React from "react";
import PropTypes from "prop-types";

import Button from "@paprika/button";
import Popover from "@paprika/popover";
import RawButton from "@paprika/raw-button";
import InfoCircleIcon from "@paprika/icon/lib/InfoCircle";
import Textarea from "@paprika/textarea";
import tokens from "@paprika/tokens";
import i18n from "@viz-ui/i18n/i18n";

import "./InterpretationSummaryPopover.scss";

class InterpretationSummaryPopover extends React.Component {
  static propTypes = {
    summary: PropTypes.string.isRequired,
    canUpdateSummary: PropTypes.bool.isRequired,
    onSaveSummary: PropTypes.func,
  };

  static defaultProps = {
    onSaveSummary: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      isOpen: false,
      summaryValue: props.summary,
    };
    this.popoverContentRef = React.createRef();
  }

  componentDidMount() {
    document.body.addEventListener("click", this.popOverCloseEventListener);
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.popOverCloseEventListener);
  }

  handleEditButtonOnClick = e => {
    e.preventDefault();
    this.setState({ editMode: true });
    this.setState({ summaryValue: this.props.summary });
  };

  handlePopoverOnClick = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  handleTextareaOnChange = e => {
    this.setState({ summaryValue: e.target.value });
  };

  handleSaveOnClick = e => {
    e.preventDefault();
    this.props.onSaveSummary(this.state.summaryValue);
    this.setState({ editMode: false });
  };

  popOverCloseEventListener = e => {
    if (this.popoverContentRef && !this.popoverContentRef.current.contains(e.target)) {
      this.setState({ isOpen: false, editMode: false });
    }
  };

  summaryViewMode = () => {
    if (this.props.canUpdateSummary) {
      return this.editableSummaryText();
    }
    return this.readOnlySummaryText();
  };

  cancelButtonOnClick = e => {
    e.preventDefault();
    this.setState({ editMode: false });
  };

  editableSummaryText() {
    return (
      <RawButton className="interpretation-summary__edit-button" onClick={this.handleEditButtonOnClick}>
        {this.readOnlySummaryText()}
      </RawButton>
    );
  }

  readOnlySummaryText() {
    let displayedValue;

    if (this.props.summary) {
      displayedValue = this.props.summary;
    } else if (this.props.canUpdateSummary) {
      displayedValue = i18n.t("_Summary.Placeholder.Editable_");
    } else {
      displayedValue = i18n.t("_Summary.Placeholder.ReadOnly_");
    }
    return <div className="interpretation-summary__text">{displayedValue}</div>;
  }

  summaryEditModeButtons() {
    const isSaveButtonDisabled = this.props.summary === this.state.summaryValue;
    return (
      <div className="interpretation-summary__edit-footer">
        <Button
          className="interpretation-summary__save-btn"
          kind="primary"
          isDisabled={isSaveButtonDisabled}
          isSemantic={false}
          onClick={this.handleSaveOnClick}
        >
          {i18n.t("_SaveButton.Save.Label_")}
        </Button>
        <Button
          kind="minor"
          className="interpretation-summary__cancel-btn"
          isSemantic={false}
          onClick={this.cancelButtonOnClick}
        >
          {i18n.t("_General.Cancel.Label_")}
        </Button>
      </div>
    );
  }

  summaryEditMode() {
    return (
      <React.Fragment>
        <Textarea
          className="interpretation-summary__textarea"
          maxHeight="345px"
          onChange={this.handleTextareaOnChange}
          value={this.state.summaryValue}
        />
        {this.summaryEditModeButtons()}
      </React.Fragment>
    );
  }

  renderSummary = () => {
    if (this.state.editMode) return this.summaryEditMode();
    return this.summaryViewMode();
  };

  render() {
    return (
      <div className="interpretation-summary-container">
        <Popover className="interpretation-summary__popover" isOpen={this.state.isOpen} maxWidth={270} zIndex={400}>
          <RawButton onClick={this.handlePopoverOnClick} className="interpretation-summary__trigger">
            <InfoCircleIcon color={tokens.textColor.icon} />
          </RawButton>
          <Popover.Content ref={this.popoverContentRef}>
            <Popover.Card>
              <div className="interpretation-summary__popover-content">{this.renderSummary()}</div>
            </Popover.Card>
          </Popover.Content>
          <Popover.Tip />
        </Popover>
      </div>
    );
  }
}

export default InterpretationSummaryPopover;
