import React, { Component } from "react";
import PropTypes from "prop-types";
import ConditionalFilter from "@viz-ui/models/storyboardFilter/conditionalFilter";
import FilterValue from "@viz-ui/models/storyboardFilter/filterValue";
import StoryboardFilter from "@viz-ui/models/storyboardFilter/storyboardFilter";
import VizUiConditional from "@viz-ui/components/stbGlobalFilter/conditional/conditional";
import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";

export default class Conditional extends Component {
  static propTypes = {
    canValidate: PropTypes.bool,
    fieldFormatIdentifier: PropTypes.string,
    flipRelativeFilters: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    filter: PropTypes.instanceOf(StoryboardFilter),
    isDisabled: PropTypes.bool,
    onFilterChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    canValidate: undefined,
    fieldFormatIdentifier: undefined,
    flipRelativeFilters: false,
    filter: undefined,
    isDisabled: false,
  };

  getFilterValue = n => {
    const { filter } = this.props;
    if (!filter.values()) return "";
    if (filter.values().length < n) return "";
    return filter.values()[n - 1].value();
  };

  handleFilterChange = (prop, value) => {
    const { onFilterChange, filter } = this.props;
    if (prop === "value1")
      onFilterChange("values", [
        new FilterValue().value(value),
        new FilterValue().value(this.getFilterValue(2)),
        new FilterValue().value(this.getFilterValue(3)),
      ]);
    else if (prop === "value2")
      onFilterChange("values", [
        new FilterValue().value(this.getFilterValue(1)),
        new FilterValue().value(value),
        new FilterValue().value(this.getFilterValue(3)),
      ]);
    else if (prop === "value3")
      onFilterChange("values", [
        new FilterValue().value(this.getFilterValue(1)),
        new FilterValue().value(this.getFilterValue(2)),
        new FilterValue().value(value),
      ]);
    else if (prop === "excludeToday") onFilterChange("excludeToday", value);
    else onFilterChange(prop, value);
    UsageTracker.createEvent(`.filterpanel.${filter.type()}.${prop}changed`);
  };

  makeConditionalFilter = () => {
    const { filter } = this.props;
    return new ConditionalFilter({
      operator: filter.operator(),
      value1: this.getFilterValue(1),
      value2: this.getFilterValue(2),
      value3: this.getFilterValue(3),
      excludeToday: filter.excludeToday(),
    });
  };

  render() {
    const { canValidate, fieldFormatIdentifier, fieldName, isDisabled, flipRelativeFilters } = this.props;
    return (
      <VizUiConditional
        canValidate={canValidate}
        flipRelativeFilters={flipRelativeFilters}
        fieldFormatIdentifier={fieldFormatIdentifier}
        fieldName={fieldName}
        filter={this.makeConditionalFilter()}
        isDisabled={isDisabled}
        onFilterChange={this.handleFilterChange}
      />
    );
  }
}
