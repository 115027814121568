angular
  .module("acl.visualizer.heatmap")
  .controller("HeatmapController", function(
    $controller,
    $filter,
    $scope,
    $timeout,
    HeatmapService,
    ChartService,
    DataModel,
    DataDownsamplerService,
    InheritController
  ) {
    var base = InheritController("ChartBaseController", $scope)
      .override("chartType", "Heatmap")
      .override("defaultDisplayConfig", HeatmapService.defaultDisplayConfig)
      .override("getChartDirectiveConfig", HeatmapService.getChartDirectiveConfig)
      .override("populateChartConfigColumnDefs", HeatmapService.populateChartConfigColumnDefs)
      .override("chartConfigColumnDefs", HeatmapService.chartConfigColumnDefs)
      .override("isValidDataConfig", HeatmapService.isValidDataConfig)
      .override("chartZoomIn", function(data) {
        var chartXAxisFormatter = ChartService.getFormatterForType($scope.chartConfig.dataConfig.chartXAxis.type);
        var chartYAxisFormatter = ChartService.getFormatterForType($scope.chartConfig.dataConfig.chartYAxis.type);
        var filters = [
          {
            field: $scope.chartConfig.dataConfig.chartXAxis,
            value: chartXAxisFormatter(data.xAxisName),
          },
          {
            field: $scope.chartConfig.dataConfig.chartYAxis,
            value: chartYAxisFormatter(data.yAxisName),
          },
        ];

        $scope.tab.callbackInterface.onZoomIn(filters);
      });

    base.initChart();
  });
