import React from "react";
import PropTypes from "prop-types";
import sortBy from "lodash/sortBy";
import services from "../../../services/glue/appGlue";
import Button from "acl-ui/components/Button";
import Pagination from "@paprika/pagination";
import Spinner from "@paprika/spinner";
let timeout = null;
class UsersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldToggleEditMode: false,
      keyWord: "",
    };
  }

  handleRemoveUser(assignmentId) {
    return () => {
      if (assignmentId) {
        this.props.onRemoveUser(assignmentId);
      }
    };
  }

  handlePermissionChange(assignmentId) {
    return value => {
      this.props.onPermissionChange(assignmentId, value);
    };
  }

  toggleRoleDropdownOpened(value) {
    return () => {
      this.setState({ shouldToggleEditMode: value });
    };
  }

  getRows() {
    let users = sortBy(this.props.users, "name");
    let toggleRoleDropdownOpenedFn = this.toggleRoleDropdownOpened.bind(this);
    return users.map(user => {
      return (
        <UsersListRow
          key={user.assignmentId ? "assignment" + user.assignmentId : "user" + user.userId}
          user={user}
          shouldToggleEditMode={!this.state.shouldToggleEditMode}
          onOpenDropdown={toggleRoleDropdownOpenedFn(true)}
          onCloseDropdown={toggleRoleDropdownOpenedFn(false)}
          onPermissionChange={this.props.onPermissionChange}
          onRemoveUser={this.props.onRemoveUser}
        />
      );
    });
  }

  showSpinner() {
    return (
      <div className="selection__spinner">
        <Spinner
          caption={services.Localize.getLocalizedString("_Storyboards.SharePanel.UserSelector.spinnerMessage")}
        />
      </div>
    );
  }
  showTableContent() {
    return (
      <table className="share-storyboard-table" id="shareStoryboardTable">
        <thead className="share-storyboard-table__header" id="shareStoryboardHeader">
          <tr>
            <th>{services.Localize.getLocalizedString("_Storyboards.SharePanel.MembersTable.ColumnHeaders.Name_")}</th>
            <th>{services.Localize.getLocalizedString("_Storyboards.SharePanel.MembersTable.ColumnHeaders.Email_")}</th>
            <th width="150px">
              {services.Localize.getLocalizedString("_Storyboards.SharePanel.MembersTable.ColumnHeaders.Permissions_")}
            </th>
            <th width="20px" />
          </tr>
        </thead>
        <tbody className="share-storyboard-table__body" id="shareStoryboardUserList">
          {this.getRows()}
        </tbody>
      </table>
    );
  }
  render() {
    return (
      <div className="acl-tbl-wrapper open-tbl current-user__listContainer">
        <div className="current-users__header">
          <div>
            <i className="acl-i-user-group" />{" "}
            {services.Localize.getLocalizedString("_Storyboards.SharePanel.MembersTable.Header_")}
          </div>
          <Button type="primary" onClick={this.props.toggleUserPanel} icon="add" isDisabled={this.props.isLoading}>
            {services.Localize.getLocalizedString("_Storyboards.SharePanel.ManageUsersBtn_")}
          </Button>
        </div>
        {services.AppConfig.features.enablePaginationOnCurrentUsers && (
          <div className="user-selector__count">
            {!this.props.isLoading && this.props.paginationData && (
              <p>
                <>
                  {services.Localize.getLocalizedString("_Storyboards.SharePanel.UserSelector.countMessage")}&nbsp;
                  {this.props.paginationData.from}-{this.props.paginationData.to}/{this.props.paginationData.count}
                </>
              </p>
            )}
          </div>
        )}

        <div className="user-selector-wrapper">
          {services.AppConfig.features.enablePaginationOnCurrentUsers && (
            <div className="search-panel">
              <i className="acl-i-search" />
              <input
                type="text"
                value={this.state.keyWord}
                onChange={e => {
                  this.setState({ keyWord: e.target.value });

                  if (timeout) {
                    clearTimeout(timeout);
                  }
                  // Set a new timeout to log the input after a pause of 500 milliseconds
                  timeout = setTimeout(() => {
                    if (this.state.keyWord.length >= 2 || this.state.keyWord.length === 0) {
                      this.props.handleCurrentUsersPageChange(1, this.state.keyWord);
                    }
                  }, 1000);
                }}
                placeholder={services.Localize.getLocalizedString(
                  "_Storyboards.SharePanel.UserSelector.FilterUserPlaceholder_"
                )}
              />
            </div>
          )}

          <div
            className={
              services.AppConfig.features.enablePaginationOnCurrentUsers
                ? "share-storyboard-table__container"
                : "share-storyboard-table__container_flippedOff"
            }
          >
            {this.props.isLoading ? this.showSpinner() : this.showTableContent()}
          </div>
        </div>
        {/* use this only if paginationData is available */}
        {services.AppConfig.features.enablePaginationOnCurrentUsers && this.props.paginationData && (
          <div className="share-storyboard-table__pagination">
            {!this.props.isLoading && (
              <Pagination
                totalPages={this.props.paginationData.pages}
                currentPage={this.props.paginationData.page}
                onChange={e => {
                  this.props.handleCurrentUsersPageChange(e, this.state.keyWord);
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

UsersList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      assignmentId: PropTypes.number,
      userId: PropTypes.number,
      groupId: PropTypes.number,
      name: PropTypes.string.isRequired,
      email: PropTypes.string,
      role: PropTypes.string,
    })
  ).isRequired,
  onPermissionChange: PropTypes.func.isRequired,
  onRemoveUser: PropTypes.func,
  paginationData: PropTypes.object,
  isLoading: PropTypes.bool,
  handleCurrentUsersPageChange: PropTypes.func,
};

window.UsersList = UsersList;
