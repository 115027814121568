import find from "lodash/find";
import map from "lodash/map";
import each from "lodash/each";
import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";

(() => {
  const fieldPrefix = "col_";
  const blankKey = "(blank)";

  class SummaryTableDataGenerator {
    constructor(Localize, StringFormatter, SummaryTable, TableDataFormatter, Sorter) {
      this._Localize = Localize;
      this._StringFormatter = StringFormatter;
      this._SummaryTable = SummaryTable;
      this._TableDataFormatter = TableDataFormatter;
      this._Sorter = Sorter;
    }

    createSummaryTable(fields, fieldFormatMap, rawData, displayTotals) {
      this._setLocalizeLabels();

      var summaryTable = new this._SummaryTable()
        .fields(fields)
        .fieldFormatMap(fieldFormatMap)
        .rawData(rawData);

      summaryTable
        .columnDefs(this._generateColumnDefs(summaryTable))
        .tableData(this._generateTableData(summaryTable, displayTotals))
        .fieldFormatMap(this._generateColumnDefsFieldFormatMap(summaryTable));

      return summaryTable;
    }

    _generateColumnDefs(summaryTable) {
      var columnDefs = [];
      var rowColumnDefs;
      var aggregatedValueColumnDefs;

      if (!summaryTable.rawData()) return [];

      rowColumnDefs = this._generateRowColumnDefs(summaryTable);
      if (rowColumnDefs.length) columnDefs = columnDefs.concat(rowColumnDefs);

      aggregatedValueColumnDefs = this._generateValuesColumnDefs(summaryTable);
      if (aggregatedValueColumnDefs.length) columnDefs = columnDefs.concat(aggregatedValueColumnDefs);

      return map(columnDefs, (column, index) => ({ ...column, ...{ field: fieldPrefix + index } }));
    }

    _generateRowColumnDefs(summaryTable) {
      let field;
      let fieldType;
      let columnName;
      let cellClass;
      let rawData = summaryTable.rawData();
      return _.map(rawData.config.rows, row => {
        field = this._fieldByName(summaryTable.fields(), row.field_name);
        columnName = field ? this._fieldDisplayName(field) : row.field_name;
        fieldType = field ? field.type() : row.data_type;
        cellClass = row.data_type;
        return this._columnDef(columnName, row.field_name, fieldType, cellClass, this._columnWidth(columnName, 200));
      });
    }

    _generateValuesColumnDefs(summaryTable) {
      var columnDefs = [];
      var rawData = summaryTable.rawData();

      var aggregatedColumnDefs = this._generateAggregatedColumnDefs(summaryTable);

      var selectedValues = rawData.config.values;
      if (!selectedValues.length) {
        columnDefs = columnDefs.concat(aggregatedColumnDefs);
      } else if (aggregatedColumnDefs.length) {
        aggregatedColumnDefs.forEach(aggregatedColumnDef => {
          columnDefs = columnDefs.concat(
            this._generateAggregatedValuesColumnDefs(summaryTable, selectedValues, aggregatedColumnDef)
          );
        });
      } else {
        columnDefs = columnDefs.concat(this._generateAggregatedValuesColumnDefs(summaryTable, selectedValues));
      }
      return columnDefs;
    }

    _generateColumnDefsFieldFormatMap(summaryTable) {
      const columnDefsFieldFormatMap = new Map();
      const columnDefs = summaryTable.columnDefs() || [];
      const fieldFormatMap = summaryTable.fieldFormatMap();

      fieldFormatMap.forEach((fieldFormat, fieldName) => {
        const foundColumnDefs = columnDefs.filter(columnDef => columnDef.fieldName === fieldName);
        foundColumnDefs.forEach(columnDef => {
          columnDefsFieldFormatMap.set(columnDef.field, fieldFormat);
        });
      });

      return columnDefsFieldFormatMap;
    }

    _generateAggregatedColumnDefs(summaryTable) {
      var rawData = summaryTable.rawData();
      var selectedColumn = rawData.config.column;
      var columnValues = selectedColumn && rawData.values[0].column[selectedColumn.field_name];
      columnValues = this._sortColumnValues(columnValues, selectedColumn.data_type);
      return _.map(columnValues, columnValue => {
        var columnKey = columnValue === null ? "" : columnValue;
        var displayValue =
          columnKey === ""
            ? this._localizedLabels["_Filter.BlankValue.Label_"]
            : this._formatValue(
                summaryTable.fields(),
                summaryTable.fieldFormatMap(),
                columnKey,
                selectedColumn.field_name
              );
        var field = this._fieldByName(summaryTable.fields(), selectedColumn.field_name);
        var displayName = field ? this._fieldDisplayName(field) : selectedColumn.field_name;
        var columnObject = {
          dataKey: columnKey.toString(),
          displayName: displayName,
          displayValue: displayValue,
          type: field.type(),
        };
        var cellClass = "aggregation " + selectedColumn.data_type;
        return this._columnDef(
          displayValue,
          selectedColumn.field_name,
          field.type(),
          cellClass,
          this._columnWidth(displayName, 200),
          columnObject
        );
      });
    }

    _generateAggregatedValuesColumnDefs(summaryTable, selectedValues, aggregatedColumnDef) {
      return _.map(selectedValues, valueField => {
        var field = this._fieldByName(summaryTable.fields(), valueField.field_name);
        var valueFieldName = field ? this._fieldDisplayName(field) : valueField.field_name;
        var valueFieldType = field ? field.type() : valueField.data_type;
        var valueDisplayName = this._aggregatedValueDisplayName(valueFieldName);
        var cellClass = "aggregation " + valueField.data_type;
        var aggregation = valueFieldName === "*" ? "count" : "sum";
        var columnObject = aggregatedColumnDef ? aggregatedColumnDef.column : undefined;
        var columnWidth = this._aggregatedValuesColumnWidth(selectedValues, aggregatedColumnDef, valueDisplayName);
        return this._columnDef(
          valueDisplayName,
          valueField.field_name,
          valueFieldType,
          cellClass,
          columnWidth,
          columnObject,
          aggregation
        );
      });
    }

    _aggregatedValuesColumnWidth(selectedValues, aggregatedColumnDef, valueDisplayName) {
      let valueColumnWidth = this._columnWidth(valueDisplayName, 200);
      let columnValueHeaderWidth;
      if (selectedValues.length === 1) {
        columnValueHeaderWidth = aggregatedColumnDef && aggregatedColumnDef.width ? aggregatedColumnDef.width : "0px";
        if (this._widthComparator(columnValueHeaderWidth, valueColumnWidth)) {
          valueColumnWidth = columnValueHeaderWidth;
        }
      }
      return valueColumnWidth;
    }

    _columnWidth(text, defaultWidth) {
      const showMaximumNumberOfCharacters = 25;
      const extraPadding = 35;
      const textWidth = this._columnTextWidth(text);
      if (textWidth && text.length < showMaximumNumberOfCharacters) {
        return textWidth + extraPadding + "px";
      }
      return defaultWidth + "px";
    }

    _columnTextWidth(text) {
      const styleClass = "grid-columns-width";
      const element = $("<div></div>")
        .addClass(styleClass)
        .text(text)
        .appendTo($("body"));
      const width = element.width();
      element.remove();
      return width;
    }

    _aggregatedValueDisplayName(valueFieldName) {
      var displayName;

      if (valueFieldName !== "*") {
        displayName = this._Localize.getLocalizedStringWithTokenReplacement("_SummaryTable.Header.Sum_", {
          fieldName: valueFieldName,
        });
      } else {
        displayName = this._localizedLabels["_SummaryTable.Header.Count_"];
      }

      return displayName;
    }

    _generateTableData(summaryTable, displayTotals) {
      var tableData = [];
      var entry;
      var rowValue;
      var rawData = summaryTable.rawData();
      var columnDefs = summaryTable.columnDefs();
      var totalRow = this._totalRowFieldDef(columnDefs);
      var value;
      if (!rawData || (rawData.config.rows.length === 0 && rawData.config.values.length === 0)) {
        return [];
      }

      rawData.values.forEach(valueObj => {
        entry = {};
        columnDefs.forEach(column => {
          if (this._isRowColumn(column)) {
            rowValue = find(valueObj.rows, row => row[column.fieldName] !== undefined)[column.fieldName];
            entry[column.field] = rowValue || this._localizedLabels["_Filter.BlankValue.Label_"];
          } else if (Object.keys(valueObj.values).length > 0) {
            value =
              column.column && column.column.dataKey !== undefined
                ? valueObj.values[column.fieldName][column.column.dataKey]
                : valueObj.values[column.fieldName];
            totalRow = this._updateTotalRow(totalRow, column.field, value);
            if (column.column && column.column.dataKey !== undefined) {
              entry[column.field] = valueObj.values[column.fieldName][column.column.dataKey];
            } else {
              entry[column.field] = valueObj.values[column.fieldName];
            }
          }
        });
        tableData.push(entry);
      });

      this._sortTableData(
        tableData,
        rawData.config.rows.map(d => d.data_type)
      );

      this._formatTableData(summaryTable.fields(), summaryTable.fieldFormatMap(), tableData, columnDefs);

      if (displayTotals && tableData.length > 1 && rawData.config.values.length > 0) {
        tableData.push(
          this._formatTotalRow(summaryTable.fields(), summaryTable.fieldFormatMap(), columnDefs, totalRow)
        );
      }

      return tableData;
    }

    _getFieldType(fields, fieldName) {
      var field = this._fieldByName(fields, fieldName);

      if (this._isCountColumn(fieldName)) {
        return "numeric";
      }
      return field ? field.type() : "character";
    }

    _sortColumnValues(columnValues, type) {
      if (columnValues) {
        columnValues = this._Sorter.sort(columnValues, { dataType: type, order: "asc" });
      }
      return columnValues;
    }

    _sortTableData(tableData, rowTypes) {
      tableData.sort((a, b) => {
        var i;
        var result;
        for (i = 0; i < rowTypes.length; i++) {
          let comparator = this._Sorter.getTypedComparator({ dataType: rowTypes[i] });
          result = comparator(a["col_" + i], b["col_" + i]);
          if (result !== 0) {
            return result;
          }
        }
        return 0;
      });
    }

    _formatTableData(fields, fieldFormatMap, tableData, columnDefs) {
      tableData.forEach(entry => {
        columnDefs.forEach(column => {
          const { field, fieldName } = column;
          if (entry[field] !== undefined) {
            const rawValue = column.aggregation === "count" && !entry[field] ? 0 : entry[field];
            const formattedValue = this._formatValue(fields, fieldFormatMap, rawValue, fieldName);
            entry[field] = { value: rawValue, formattedValue };
          }
        });
      });
    }

    _updateTotalRow(totalRow, field, value) {
      var total = angular.copy(totalRow);
      if (value === "" || value === null || value === undefined) return total;

      if (total[field] === "") {
        total[field] = numeral(value).value();
      } else {
        total[field] += numeral(value).value();
      }

      return total;
    }

    _totalRowFieldDef(columnDefs) {
      var totalRow = {};
      var i;
      for (i = 0; i < columnDefs.length; i++) {
        if (this._isAggregatedColumn(columnDefs[i])) {
          if (i > 0 && Object.keys(totalRow).length === 0) {
            totalRow[columnDefs[0].field] = this._localizedLabels["_SummaryTable.Total.Label_"];
          }
          totalRow[columnDefs[i].field] = "";
        }
      }
      return totalRow;
    }

    _formatValue(fields, fieldFormatMap, value, fieldName) {
      var fieldType = this._getFieldType(fields, fieldName);
      const fieldFormat = fieldFormatMap.get(fieldName);
      var fieldFormatJson = fieldFormat && fieldFormat.toJson();
      var blankValue = this._localizedLabels["_Filter.BlankValue.Label_"];
      let utcOffsetMinutes = 0;
      if (fieldType == "dateTime" || fieldType == "datetime" || fieldType == "DT") {
        utcOffsetMinutes = GlobalValueFormatter.getTimezoneOffset().utcOffsetMinutes();
      }
      var formattedValue =
        value === blankKey
          ? blankValue
          : this._TableDataFormatter.formatValue(value, fieldType, fieldFormatJson, false, utcOffsetMinutes);
      formattedValue = this._sanitizeValue(formattedValue, fieldType, fieldFormat);
      return formattedValue;
    }

    _sanitizeValue(value, fieldType, fieldFormat) {
      var sanitizedValue;
      if (fieldType === "character" && fieldFormat) {
        if (fieldFormat.isHtml()) {
          sanitizedValue = this._StringFormatter.sanitizeHtml(value);
        } else {
          sanitizedValue = this._StringFormatter.encode(value);
        }
        return sanitizedValue;
      }
      return value;
    }

    _formatTotalRow(fields, fieldFormatMap, columnDefs, totalRow) {
      var formattedTotalRow = {};
      var column;
      each(totalRow, (totalDataOrTotalsLabel, field) => {
        let formattedValue;
        const isTotalsLabel = typeof totalDataOrTotalsLabel === "string";
        const isTotalValues = !Number.isNaN(totalDataOrTotalsLabel) && !isTotalsLabel;
        if (isTotalValues) {
          if (totalDataOrTotalsLabel !== "") {
            column = this._getColumnDefByField(columnDefs, field);
            formattedValue = this._formatValue(
              fields,
              fieldFormatMap,
              totalDataOrTotalsLabel.toFixed(2),
              column.fieldName
            );
          } else {
            formattedValue = "";
          }
        } else {
          formattedValue = totalDataOrTotalsLabel;
        }
        formattedTotalRow[field] = { value: totalDataOrTotalsLabel, formattedValue };
        if (formattedValue === this._localizedLabels["_SummaryTable.Total.Label_"]) {
          formattedTotalRow[field].fontWeight = "bold";
        }
      });
      return formattedTotalRow;
    }

    _fieldDisplayName(field) {
      return field.displayName() || field.name();
    }

    _fieldByName(fields, fieldName) {
      return find(fields, field => field.name() === fieldName);
    }

    _getColumnDefByField(columnDefs, field) {
      return find(columnDefs, column => column.field === field);
    }

    _columnDef(columnName, fieldName, type, cellClass, width, columnObject, aggregation) {
      var column = {
        displayName: columnName,
        fieldName: fieldName,
        type: type,
        cellClass: cellClass,
        width: width,
      };
      if (columnObject !== undefined) column = { ...column, ...{ column: columnObject } };
      if (aggregation) column = { ...column, ...{ aggregation: aggregation } };

      return column;
    }

    _isCountColumn(fieldName) {
      return fieldName === "*";
    }

    _widthComparator(a, b) {
      var aValue = parseInt(a.split("px")[0], 10);
      var bValue = parseInt(b.split("px")[0], 10);
      return aValue > bValue;
    }

    _isAggregatedColumn(column) {
      return column.cellClass.indexOf("aggregation") > -1;
    }

    _isRowColumn(column) {
      return column.cellClass.indexOf("aggregation") === -1;
    }

    _setLocalizeLabels() {
      this._localizedLabels = {
        "_Filter.BlankValue.Label_": this._Localize.getLocalizedString("_Filter.BlankValue.Label_"),
        "_SummaryTable.Header.Count_": this._Localize.getLocalizedString("_SummaryTable.Header.Count_"),
        "_SummaryTable.Total.Label_": this._Localize.getLocalizedString("_SummaryTable.Total.Label_"),
      };
    }
  }

  angular.module("acl.visualizer.model.summaryTable").service("SummaryTableDataGenerator", SummaryTableDataGenerator);
})();
