export default class SessionStorageHelper {
  static get = key => {
    try {
      return sessionStorage.getItem(key);
    } catch (e) {
      return null;
    }
  };

  static set = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  };

  static remove = key => {
    sessionStorage.removeItem(key);
  };
}
