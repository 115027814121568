import min from "lodash/min";
import max from "lodash/max";
import uniq from "lodash/uniq";
import { HighChartDirective, HighChartController } from "../highChartDirective";
import ChartColorConfigHelper from "../chartColorConfigHelper";
import { HEAT_MAP_CHART } from "../boost/boostThresholds";

class HeatMapDirective extends HighChartDirective {
  controller = Controller;
  static factory = () => new HeatMapDirective();
}

export class Controller extends HighChartController {
  static configPath = {
    labelFontSize: [
      "colorAxis.labels.style.fontSize",
      "plotOptions.series.dataLabels.style.fontSize",
      "tooltip.style.fontSize",
      "xAxis.labels.style.fontSize",
      "xAxis.title.style.fontSize",
      "yAxis.labels.style.fontSize",
      "yAxis.title.style.fontSize",
    ],
  };

  constructor($scope, $timeout, HighChartLabelSizer, Sorter) {
    "ngInject";
    super($scope, $timeout, HighChartLabelSizer);
    this.Sorter = Sorter;
  }

  getConfig = (config, data) => {
    let allDataPoints = data.reduce((all, series) => all.concat(series.values), []);
    let xAxis = {
      categories: this.Sorter.sort(uniq(allDataPoints.map(d => d.x)), {
        dataType: config.xAxis.axisType === "character" ? "character" : "numeric",
      }),
    };
    let yAxis = {
      categories: this.Sorter.sort(uniq(allDataPoints.map(d => d.y)), {
        dataType: config.yAxis.axisType === "character" ? "character" : "numeric",
      }),
    };
    let colorAxis = {
      colorStops: ChartColorConfigHelper.getColorStops(
        config.colorAxis.colorStops,
        min(allDataPoints.map(p => p.size)),
        max(allDataPoints.map(p => p.size))
      ),
    };
    colorAxis.min = colorAxis.colorStops[0].value;
    colorAxis.max = colorAxis.colorStops[2].value;

    let hcColorAxis = config.binMode
      ? {
          dataClasses: ChartColorConfigHelper.getColorBins(colorAxis.colorStops, config.colorAxis.numBins),
        }
      : {
          stops: colorAxis.colorStops.map((stop, i) => [
            (colorAxis.colorStops[i].value - colorAxis.min) / (colorAxis.max - colorAxis.min),
            stop.color,
          ]),
          min: colorAxis.min,
          max: colorAxis.max,
          endOnTick: !isFinite(config.colorAxis.colorStops[2].value),
          startOnTick: !isFinite(config.colorAxis.colorStops[0].value),
        };
    hcColorAxis.labels = {
      formatter: function() {
        return config.colorAxis.valueFormatter(this.value);
      },
    };

    // pattern-fill does not support gradient axis
    if (this.$scope.isPatternFill && hcColorAxis.dataClasses) {
      Object.entries(hcColorAxis.dataClasses).forEach((v, index) => {
        v[1].color = this.patternFillPalettes(index);
      });
    }

    let hcConfig = {
      legend: {
        align: "right",
        enabled: config.showLegend,
        verticalAlign: "top",
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: config.displayDataLabels,
            formatter: function() {
              return config.colorAxis.valueFormatter(this.point.value);
            },
          },
          point: {
            events: {
              click: this.clickWrapper(null, point => {
                this.zoomIn([point.xValue, point.yValue]);
              }),
            },
          },
          tooltip: {
            headerFormat: "",
            pointFormatter: function() {
              return `
                <b>${config.xAxis.displayName}: </b>
                <span>${config.xAxis.valueFormatter(this.xValue)}</span>
                <br/>
                <b>${config.yAxis.displayName}: </b>
                <span>${config.yAxis.valueFormatter(this.yValue)}</span>
                <br/>
                <b>${config.valueLabel}: </b>
                <span>${config.colorAxis.valueFormatter(this.value)}</span>
              `;
            },
          },
          turboThreshold: 0,
          animation: this.canAnimate(),
        },
      },
      xAxis: {
        categories: xAxis.categories,
        labels: {
          formatter: function() {
            return config.xAxis.valueFormatter(this.value);
          },
        },
        tickmarkPlacement: "between",
        title: {
          text: config.xAxis.label,
        },
      },
      yAxis: {
        categories: yAxis.categories,
        tickmarkPlacement: "between",
        title: {
          text: config.yAxis.label,
        },
        labels: {
          formatter: function() {
            return config.yAxis.valueFormatter(this.value);
          },
        },
      },
      colorAxis: hcColorAxis,
      series: data.map((series, i) => ({
        type: "heatmap",
        name: series.key,
        color: series.color,
        colorIndex: i,
        boostThreshold: HEAT_MAP_CHART,
        visible: series.visible !== false,
        data: series.values.map(value => ({
          x: xAxis.categories.indexOf(value.x),
          y: yAxis.categories.indexOf(value.y),
          xValue: value.x,
          yValue: value.y,
          value: value.size,
        })),
      })),
    };
    hcConfig = this.updateLabelFontSize(hcConfig, Controller.configPath.labelFontSize);
    return hcConfig;
  };

  zoomIn = keyArray => {
    if (angular.isFunction(this.$scope.zoomInHandler)) {
      this.$scope.zoomInHandler({
        xAxisName: keyArray[keyArray.length - 2],
        yAxisName: keyArray[keyArray.length - 1],
      });
    }
  };
}

export default HeatMapDirective;
