import HistogramChartService from "@viz-ui/services/charts/histogramChart/histogramChartService";

angular
  .module("acl.visualizer.charts")
  .factory("HistogramService", function($filter, $sanitize, ChartService, DataModel, EventService, AppConfig) {
    var eventService = EventService.register("acl.biView");

    if (AppConfig.features.migrateVizServices) {
      return HistogramChartService;
    }

    return {
      loadData: loadData,
      createHistogramViewConfig: createHistogramViewConfig,
    };

    function createHistogramViewConfig(fieldData) {
      return {
        chartXAxis: {
          displayName: fieldData.displayName,
          fieldName: fieldData.fieldName,
          type: fieldData.type,
        },
        chartValue: {
          fieldName: "",
          type: "numeric",
          aggregationType: "count",
        },
      };
    }

    function loadData(config) {
      const filterConfig = DataModel.getFilterConfigDeprecated();
      eventService.publish("biView.configChanged", "BarChart", DataModel.table.id(), filterConfig, config);
    }
  });
