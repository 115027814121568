import React, { Component } from "react";
import PropTypes from "prop-types";
import AcluiLazyMultiselect from "acl-ui/components/Multiselect/LazyMultiselectWithSections";
import i18n from "@viz-ui/i18n/i18n";
import FilterValue from "@viz-ui/models/storyboardFilter/filterValue";
import FilterValueService from "@viz-ui/services/storyboardFilter/filterValueService";
import GlobalFieldFormatMap from "../../../services/formatters/globalFieldFormatMap";
import checkboxState from "@viz-ui/models/checkboxState";
import "./multiselect.scss";

export default class Multiselect extends Component {
  static propTypes = {
    allItemsLoaded: PropTypes.bool,
    fieldFormatIdentifier: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    filterString: PropTypes.string,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.instanceOf(FilterValue)).isRequired,
    totalItemsCount: PropTypes.number.isRequired,
    onChangeFilterString: PropTypes.func.isRequired,
    onProcessFilterChange: PropTypes.func,
    onClickItem: PropTypes.func.isRequired,
    onLoadMore: PropTypes.func.isRequired,
    selectedItems: PropTypes.arrayOf(PropTypes.instanceOf(FilterValue)),
    selectAllState: PropTypes.oneOf(["checked", "indeterminate", "unchecked"]),
    unSelectedItems: PropTypes.arrayOf(PropTypes.instanceOf(FilterValue)),
    filterId: PropTypes.string.isRequired,

    isDynamicFilterEnabled: PropTypes.bool,
  };

  static defaultProps = {
    allItemsLoaded: false,
    fieldFormatIdentifier: "",
    isDisabled: false,
    isLoading: false,
    selectedItems: [],
    unSelectedItems: [],
    selectAllState: checkboxState.UNCHECKED,
    isDynamicFilterEnabled: false,
  };

  constructor(props) {
    super(props);
    this.itemLookup = new Map();
  }

  componentWillMount() {
    this.initialize(this.props);
    this.buildLookup();
    if (this.filterString) this.buildVariablesPostFilter();
    this.setTransformItemObjectsInState();
  }

  componentWillReceiveProps(nextProps) {
    this.initialize(nextProps);
    this.buildLookup();
    if (this.filterString) this.buildVariablesPostFilter();
    this.setTransformItemObjectsInState();
  }

  componentDidUpdate() {
    this.makeSelectAllStickiness();
    const that = this;
    setTimeout(() => {
      const selectAllStateProp =
        ((that.selectAllState == checkboxState.UNCHECKED || !that.selectAllState) &&
          that.selectedItems &&
          that.selectedItems.length > 0) ||
        that.filterString
          ? that.getSelectAllStateProp(that.visibleSelectedItemsLength, that.itemLookup.size)
          : that.selectAllState;

      that.setSelectAllStateProp(selectAllStateProp);
    }, 5);
  }

  initialize(props) {
    this.filterString = props.filterString;
    this.allItemsLoaded = props.allItemsLoaded;
    this.selectAllCheckboxId = FilterValueService.hashCode(`${props.filterId}-select-all`);
    this.items = props.items;
    this.selectAllState = props.selectAllState;
    this.unSelectedItems = props.unSelectedItems;

    if (!this.filterString) {
      this.selectAllStatePriorSearch = this.selectAllState;
      this.totalVisibleItemsPriorSearch = this.items; // It holds all visible items
      this.totalItemsCount = props.totalItemsCount; // It holds the total items length (both visible & non-visible values count)
    }

    this.totalLoadedItems = this.getTotalLoadedItems(this.items, this.totalVisibleItemsPriorSearch);
    this.selectedItems = this.getSelectedItems(
      props.selectedItems,
      this.selectAllState,
      this.totalLoadedItems,
      this.unSelectedItems
    );
    this.visibleSelectedItemsLength = this.getVisibleSelectedItemsLength(
      this.filterString,
      this.items,
      this.selectedItems
    );
  }

  getTotalLoadedItems(items, totalVisibleItemsPriorSearch) {
    return [...new Set([...items.map(x => x.value()), ...totalVisibleItemsPriorSearch.map(x => x.value())])].map(x =>
      new FilterValue().value(x)
    );
  }

  getSelectedItems(selectedItems, selectAllState, totalLoadedItems, unSelectedItems) {
    if (selectAllState == checkboxState.CHECKED || (unSelectedItems && unSelectedItems.length > 0)) {
      return totalLoadedItems.filter(x => unSelectedItems.findIndex(y => y.value() == x.value()) == -1);
    } else {
      return selectedItems;
    }
  }

  getVisibleSelectedItemsLength(filterString, items, selectedItems) {
    if (!filterString) {
      return selectedItems.length;
    } else {
      return items.filter(item => selectedItems.findIndex(x => x.value() == item.value()) > -1).length;
    }
  }

  //to display appropriate message based on number of selection
  getSelectedMessageFormat() {
    const selectedValuesCount = this.getSelectedValuesCount();
    if (selectedValuesCount > 1) {
      return { key: "_Multiselect.statusTitleDynamicFilterPlural.Label_", value: selectedValuesCount };
    } else {
      return { key: "_Multiselect.statusTitleDynamicFilter.Label_", value: selectedValuesCount };
    }
  }

  getSelectedValuesCount() {
    if (this.selectAllState === checkboxState.CHECKED || this.unSelectedItems.length > 0) {
      return this.totalItemsCount - this.unSelectedItems.length;
    } else {
      return this.selectedItems.length;
    }
  }

  /* if dynamic filter is enabled then we need to show only selected values count
   if there is no dynamic filter, show total values along with selected values*/
  getStatusTitle() {
    if (this.selectedItems.length === 0) return i18n.t("_Multiselect.Placeholder_");
    let status;

    if (this.props.isDynamicFilterEnabled) {
      status = i18n.t(this.getSelectedMessageFormat().key, {
        selectedValuesCount: this.getSelectedMessageFormat().value,
      });
    } else {
      status = i18n.t("_Multiselect.statusTitle.Label_", {
        selectedValuesCount: this.getSelectedValuesCount(),
        totalValuesCount: this.totalItemsCount,
      });
    }

    return <b>{status}</b>;
  }

  getFilterPlaceholder() {
    const { fieldFormatIdentifier, fieldName } = this.props;
    const fieldType = GlobalFieldFormatMap.getFieldType(fieldFormatIdentifier, fieldName);
    if (fieldType === "date" || fieldType === "datetime") {
      return i18n.t("_General.FilterField.DatePlaceholder_");
    }
    return i18n.t("_General.FilterField.Placeholder_");
  }

  buildLookup() {
    this.itemLookup = new Map();
    this.items.forEach(filterValue => {
      const id = FilterValueService.hashCode(filterValue.value());
      this.itemLookup.set(id, { filterValue });
    });

    this.selectedItems.forEach(filterValue => {
      const id = FilterValueService.hashCode(filterValue.value());
      if (this.itemLookup.has(id)) this.itemLookup.set(id, { filterValue, isSelected: true });
    });
  }

  buildVariablesPostFilter() {
    this.unSelectedItemsPostFilter = Array.from(this.itemLookup.values())
      .filter(x => !x.isSelected)
      .map(x => x.filterValue);
    this.selectedItemsPostFilter = Array.from(this.itemLookup.values())
      .filter(x => !!x.isSelected)
      .map(x => x.filterValue);

    if (this.selectedItems && this.selectedItemsPostFilter) {
      //Previous selected items which are not available post search.
      this.additionalSelectedItemsPostFilter = this.selectedItems.filter(
        x => this.selectedItemsPostFilter.findIndex(y => y.value() == x.value()) == -1
      );
    }
  }

  setTransformItemObjectsInState() {
    this.setState({
      sections: this.transformSections(),
    });
  }

  transformSections() {
    const transformedItems = [];
    transformedItems.push(this.createSelectAllItemObject());
    this.itemLookup.forEach((item, key) => {
      transformedItems.push(this.createItemObject(item.filterValue, !!item.isSelected));
    });
    return [{ heading: "", items: transformedItems }];
  }

  createItemObject = (filterValue, isSelected) => ({
    id: FilterValueService.hashCode(filterValue.value()),
    title: FilterValueService.getItemDisplayValue(filterValue, this.props.fieldFormatIdentifier, this.props.fieldName),
    isSelected,
  });

  createSelectAllItemObject = () => {
    return {
      id: this.selectAllCheckboxId,
      title: "(Select All)",
      content: (
        <span title="" className="checkable-item__select-all">
          <b>( {i18n.t("_Multiselect.SelectAll.Label_")})</b>
          {this.disableSelectAllOnFilter() && !this.itemLookup.size == 0 && (
            <span
              title=""
              className="select-all-disable-info"
              onMouseEnter={event => this.setSelectAllDisableToolTip(event, true)}
              onMouseLeave={event => this.setSelectAllDisableToolTip(event, false)}
            >
              {" "}
              <i class="acl-i-info-circle"></i>
            </span>
          )}
          <div id={`tooltip-${this.selectAllCheckboxId}`} className="select-all-disable-tooltip">
            {i18n.t(
              this.props.isDynamicFilterEnabled
                ? "_Multiselect.selectAllFrozenFilter.InfoIcon.ToolTip_"
                : "_Multiselect.selectAllSearch.InfoIcon.ToolTip_"
            )}
          </div>
        </span>
      ),
      isSelected: this.selectAllState == checkboxState.CHECKED ? true : false,
      isDisabled: this.disableSelectAllOnFilter() || this.itemLookup.size == 0,
    };
  };

  handleLoadMore = () => {
    this.loadMoreClicked = true;
    this.props.onLoadMore();
  };

  handleClickItem = clickedItem => {
    if (clickedItem.itemId == this.selectAllCheckboxId) {
      this.handleSelectAllClick(clickedItem);
    } else {
      this.handleValueClick(clickedItem);
    }
  };

  handleSelectAllClick = clickedItem => {
    let newSelectedItems = [];
    let newUnSelectedItems = [];

    if (clickedItem.action === "add") {
      newSelectedItems = !this.filterString
        ? Array.from(this.itemLookup.values())
        : [
            ...this.selectedItemsPostFilter,
            ...this.additionalSelectedItemsPostFilter,
            ...this.unSelectedItemsPostFilter,
          ];

      if (this.filterString && this.unSelectedItems && this.unSelectedItems.length > 0) {
        newUnSelectedItems = this.unSelectedItems.filter(
          x => this.unSelectedItemsPostFilter.findIndex(y => y.value() === x.value()) == -1
        );
      }
    }
    if (clickedItem.action === "remove") {
      newSelectedItems = !this.filterString ? [] : [...this.additionalSelectedItemsPostFilter];

      if (
        this.filterString &&
        (this.selectAllStatePriorSearch == checkboxState.CHECKED ||
          (this.unSelectedItems && this.unSelectedItems.length > 0))
      ) {
        newUnSelectedItems = [
          ...new Set([
            ...this.selectedItemsPostFilter.map(x => x.value()),
            ...this.unSelectedItems.map(x => x.value()),
          ]),
        ].map(x => new FilterValue().value(x));
      }
    }

    const newSelectAllState = this.getSelectAllStateProp(newSelectedItems.length, this.totalLoadedItems.length);

    this.props.onClickItem(
      newSelectAllState == checkboxState.CHECKED || newUnSelectedItems.length > 0 ? [] : newSelectedItems,
      newUnSelectedItems,
      newSelectAllState
    );
  };

  handleValueClick = clickedItem => {
    let newSelectedItems = [];
    let newUnSelectedItems = []; // It requires to hold only in scenario when select all is clicked + unselecting records.

    if (clickedItem.action === "add") {
      const clickedObject = this.itemLookup.get(+clickedItem.itemId).filterValue;
      newSelectedItems = this.selectedItems.concat(clickedObject);

      if (this.unSelectedItems && this.unSelectedItems.length > 0) {
        newUnSelectedItems = this.unSelectedItems
          .slice()
          .filter(filterValue => FilterValueService.hashCode(filterValue.value()) !== +clickedItem.itemId);
      }
    }
    if (clickedItem.action === "remove") {
      newSelectedItems = this.selectedItems
        .slice()
        .filter(filterValue => FilterValueService.hashCode(filterValue.value()) !== +clickedItem.itemId);

      if (this.selectAllState == checkboxState.CHECKED || (this.unSelectedItems && this.unSelectedItems.length > 0)) {
        const clickedObject = this.itemLookup.get(+clickedItem.itemId).filterValue;
        newUnSelectedItems = this.unSelectedItems.concat(clickedObject);
      }
    }

    const newSelectAllState = this.getSelectAllStateProp(newSelectedItems.length, this.totalLoadedItems.length);

    if (newSelectAllState === checkboxState.UNCHECKED) {
      newUnSelectedItems = [];
    }

    this.props.onClickItem(
      newSelectAllState == checkboxState.CHECKED || newUnSelectedItems.length > 0 ? [] : newSelectedItems,
      newUnSelectedItems,
      newSelectAllState,
      clickedItem.action
    );
  };

  getSelectAllStateProp(selectedItemsLength, totalItemsLength) {
    let selectAllStateProp = checkboxState.UNCHECKED;
    if (selectedItemsLength == 0) {
      selectAllStateProp = checkboxState.UNCHECKED;
    } else if (selectedItemsLength == totalItemsLength && !this.props.isDynamicFilterEnabled) {
      selectAllStateProp = checkboxState.CHECKED;
    } else {
      selectAllStateProp = checkboxState.INDETERMINATE;
    }

    return selectAllStateProp;
  }

  disableSelectAllOnFilter = () => {
    if ((this.filterString != "" && !this.allItemsLoaded) || this.props.isDynamicFilterEnabled) {
      return true;
    }
    return false;
  };

  setSelectAllDisableToolTip = (event, isVisible) => {
    if (isVisible) {
      $("#tooltip-" + this.selectAllCheckboxId).css({
        visibility: "visible",
      });
    } else {
      $("#tooltip-" + this.selectAllCheckboxId).css({
        visibility: "hidden",
      });
    }
  };

  makeSelectAllStickiness() {
    $(".checkable-item__select-all")
      .parents(".checkable-item")
      .addClass("checkable-item-select-all");

    $(".checkable-item__select-all")
      .parents(".aclui-multiselect__checkable-list")
      .addClass("aclui-multiselect__checkable-list-select-all");
  }

  setSelectAllStateProp(checkboxStateProp) {
    switch (checkboxStateProp) {
      case checkboxState.INDETERMINATE:
        $(`input[data-id=${this.selectAllCheckboxId}]`).prop("indeterminate", true);
        $(`input[data-id=${this.selectAllCheckboxId}]`).attr("state", () => "indeterminate");
        break;
      case checkboxState.CHECKED:
        $(`input[data-id=${this.selectAllCheckboxId}]`).prop("indeterminate", false);
        $(`input[data-id=${this.selectAllCheckboxId}]`).prop("checked", true);
        $(`input[data-id=${this.selectAllCheckboxId}]`).attr("state", () => "checked");
        break;
      case checkboxState.UNCHECKED:
      default:
        $(`input[data-id=${this.selectAllCheckboxId}]`).prop("indeterminate", false);
        $(`input[data-id=${this.selectAllCheckboxId}]`).prop("checked", false);
        $(`input[data-id=${this.selectAllCheckboxId}]`).attr("state", () => "unchecked");
        break;
    }
  }

  render() {
    const lazyProps = {
      allItemsLoaded: this.allItemsLoaded,
      isLoading: this.props.isLoading,
      loadMoreText: i18n.t("_Select.LoadMore.Label_"),
      onProcessFilterChange: this.props.onProcessFilterChange,
      onChangeFilterString: this.props.onChangeFilterString,
      onLoadMore: this.handleLoadMore,
      debouncedDelay: 800,
    };

    return (
      <AcluiLazyMultiselect
        className="stb-multiselect"
        filterPlaceholder={this.getFilterPlaceholder()}
        filterString={this.props.filterString}
        isCollapsible={false}
        isDisabled={this.props.isDisabled}
        noResultsText={i18n.t("_General.NoMatches.Label_")}
        onClickItem={this.handleClickItem}
        sections={this.state.sections}
        statusTitle={this.getStatusTitle()}
        {...lazyProps}
      />
    );
  }
}
