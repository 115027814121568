import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";

export default class ProcessPanelService {
  constructor(DataModel) {
    "ngInject";
    this.DataModel = DataModel;
  }

  formatRowData(fieldFormatIdentifier, rowData, fieldModels, tableConfigColumns) {
    if (!rowData) return [];

    const rowDataArr = [];
    for (const { fieldName: name, visible } of tableConfigColumns) {
      const fieldModel = fieldModels.find(field => field.name() === name);

      if (visible && fieldModel) {
        const displayName = fieldModel.displayName();
        const type = fieldModel.type();

        const fieldValue = typeof rowData[name] !== "undefined" ? rowData[name] : "";

        let formattedValue = GlobalValueFormatter.formatValue(fieldValue, fieldFormatIdentifier, name);
        if (this.isFieldTypeDate(type) && this.isDateValueInvalid(fieldValue)) {
          formattedValue = "Invalid date";
        }
        const isHtml = GlobalValueFormatter.isHtmlFormatted(fieldFormatIdentifier, name);

        rowDataArr.push({
          displayName,
          name,
          type,
          formattedValue,
          isHtml,
        });
      }
    }

    return rowDataArr;
  }

  fixMetadataNames(metadataFields) {
    const updateMetadataFields = {};

    for (let fieldName in metadataFields) {
      updateMetadataFields[`metadata.${fieldName}`] = metadataFields[fieldName];
    }

    return updateMetadataFields;
  }

  isDateValueInvalid(value) {
    let valueArr = value && value.split("-");
    if (valueArr && valueArr.length == 1) valueArr = value.split("/");
    return value && (valueArr.length <= 2 || valueArr.filter(val => isNaN(parseInt(val))).length > 1) ? true : false;
  }

  isFieldTypeDate(type) {
    return type === "date" || type === "D" || type === "dateTime" || type === "datetime" || type === "DT";
  }

  onlyChangedFields(newFields, oldFields) {
    const changedFields = {};

    for (let fieldName in newFields) {
      if (newFields[fieldName] !== oldFields[fieldName]) {
        changedFields[fieldName] = newFields[fieldName];
      }
    }

    return changedFields;
  }
}
