import Sorter from "../../sorters/sorter";
import globalValueFormatter from "../../formatters/globalValueFormatter";

export default class MultiTableValuesIterator {
  constructor(lastIteratedIndexMap, tableDataSources, cacheKeys, dataType, pageSize) {
    this._lastIteratedIndexMap = lastIteratedIndexMap;
    this._tableDataSources = tableDataSources;
    this._cacheKeys = cacheKeys;
    this._dataType = dataType;
    this._pageSize = pageSize;
  }

  hasValues() {
    return !this._cacheKeys
      .map(key => {
        let lastIteratedIndex = this._lastIteratedIndexMap.getIndex(key);
        return this._tableDataSources.get(key).hasValues(lastIteratedIndex, this._pageSize);
      })
      .includes(false);
  }

  getTableFieldsToLoad(forceRerender) {
    const timezoneOffset = this._getTimezoneOffset();
    return this._cacheKeys
      .filter(key => {
        let lastIteratedIndex = this._lastIteratedIndexMap.getIndex(key);
        return !this._tableDataSources.get(key).hasValues(lastIteratedIndex, this._pageSize);
      })
      .map(key => {
        return {
          cacheKey: key,
          lastRecordIndex: forceRerender ? 0 : this._tableDataSources.get(key).getLastRecordIndex(),
          timezoneOffset,
        };
      });
  }

  nextPageValues() {
    let values = [];
    let iterateIndex;
    for (iterateIndex = 0; iterateIndex < this._pageSize; iterateIndex++) {
      let value = this._getNextValue();
      if (value !== undefined) {
        values.push({ value });
        this._incrementIndex(value);
      } else {
        break;
      }
    }
    return values;
  }

  hasMoreValues() {
    let hasMoreValues = false;
    this._cacheKeys.forEach(key => {
      let totalRowCount = this._tableDataSources.get(key).getTotalRecordCount();
      let lastIteratedIndex = this._lastIteratedIndexMap.getIndex(key);
      if (lastIteratedIndex < totalRowCount) hasMoreValues = true;
    });
    return hasMoreValues;
  }

  getTotalRowCount() {
    let totalRowCount = 0;
    this._cacheKeys.forEach(key => {
      const count = this._tableDataSources.get(key).getTotalRecordCount();
      if (count && count > 0) totalRowCount = totalRowCount + count;
    });

    return totalRowCount;
  }

  _getTimezoneOffset() {
    if (this._dataType === "datetime") {
      const timezoneOffset = globalValueFormatter.getTimezoneOffset();
      return timezoneOffset.utcOffsetString();
    }
  }

  _getNextValue() {
    let value;
    let nextValues = this._cacheKeys.map(key => {
      let lastIteratedIndex = this._lastIteratedIndexMap.getIndex(key);
      return this._tableDataSources.get(key).getValue(lastIteratedIndex);
    });
    return getSmallestValue(
      nextValues.filter(value => value !== undefined),
      this._dataType
    );
  }

  _incrementIndex(selectedValue) {
    this._cacheKeys.forEach(key => {
      let lastIteratedIndex = this._lastIteratedIndexMap.getIndex(key);
      if (selectedValue === this._tableDataSources.get(key).getValue(lastIteratedIndex)) {
        this._lastIteratedIndexMap.incrementIndex(key);
      }
    });
  }
}

function getSmallestValue(values, dataType) {
  let sorted = Sorter.sort(values, { dataType });
  return sorted[0];
}
