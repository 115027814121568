import ConditionalFormatting from "@viz-ui/services/quickMenu/conditionalFormat/conditionalFormattingService";
import ConditionalFormat from "@viz-ui/models/conditionalFormatting/conditionalFormat";
import ConditionalOperators from "@viz-ui/services/quickMenu/conditionalFormat/conditionalOperators";
import DataFilter from "@viz-ui/services/charts/dataFilter/dataFilterService";
import DataModel from "@viz-ui/services/charts/DataModel/dataModelService";
import EventService from "@viz-ui/services/eventService/eventService";
import FieldFormatAdapter from "@viz-ui/models/field/fieldFormatAdapter";
import FilterConfig from "@viz-ui/models/filter/filterConfig";
import Sorter from "@viz-ui/services/sorters/sorter";
import i18n from "@viz-ui/i18n/i18n";
import MultiSelectFilterStore from "@viz-ui/services/quickMenu/MultiSelectFilterStoreService";

class FilterHacksService {
  constructor() {
    const AppConfig = { features: { remediationToggles: true } }; // replace app config once migrated properly
    this.eventService = EventService.register("acl.visualizer.metricApp.FilterHacksService");
    this.DataFilter = new DataFilter(AppConfig, MultiSelectFilterStore, DataModel);
  }

  validateFilters = filterConfigObj => {
    var valid;
    if (!filterConfigObj) {
      // eslint-disable-next-line no-param-reassign
      filterConfigObj = DataModel.getFilterConfigDeprecated();
    }
    valid = DataModel.filtersValid() && !this.DataFilter.hasInvalidFilters(filterConfigObj);
    if (!valid) {
      this.eventService.publish("notify", "warning", i18n.t("_FilterConfig.Invalid.Error_"), true);
    }
    return valid;
  };

  applySort = (sortField, sortOrder) => {
    if (sortField && sortOrder) {
      this.DataFilter.setOrToggleSort(sortField, sortOrder);
      const dataModelSortFieldId = DataModel.filterConfig.sortFieldId();
      const dataModelSortOrder = DataModel.filterConfig.sortOrder();
      this.eventService.publish("dataTable.sortChange", dataModelSortFieldId, dataModelSortOrder);
      this.eventService.publish("dataTable.dataChange", DataModel.table.data());

      this.closeQuickMenu();
    }
  };

  closeQuickMenu = () => {
    this.eventService.publish("quickMenu.close");
  };

  getFilterConfig = () => {
    var filterConfigObj = DataModel.getFilterConfigDeprecated();
    return FilterConfig.fromJson(filterConfigObj);
  };

  getTableObj = () => DataModel.getTableObj();

  filtersOpenChanged = isFilterPanelOpen => {
    DataModel.filtersOpen(isFilterPanelOpen);
  };

  onFiltersOpenChange = callback =>
    DataModel.subscribe("filtersOpen", () => {
      const newValue = DataModel.filtersOpen();
      if (!newValue) {
        // Force to keep the filter panel open.
        DataModel.filtersOpen(true);
      }
      callback(newValue);
    });

  isFilterPanelOpen = () => DataModel.filtersOpen();

  getFieldFormatModelsByName = () => DataModel.tableConfig.getFieldFormatModelsByName();

  filterConfigChanged = filterConfig => {
    var serialized = filterConfig.toJson();
    var filterConfigObj = DataModel.getFilterConfigDeprecated();
    filterConfigObj.filtersOpen = serialized.filtersOpen;
    filterConfigObj.sortField = serialized.sortField;
    filterConfigObj.params = serialized.params;
    filterConfigObj.filterList = serialized.filterList;
    if (filterConfigObj.filterList && filterConfigObj.filterList.length) {
      filterConfigObj.filterList[0].forceWatchToFire = Math.random();
    }
  };

  conditionalFormatsMapChanged = conditionalFormatsMap => {
    var serialized = ConditionalFormat.conditionalFormatMapToJson(conditionalFormatsMap);
    var tableConfigObj = DataModel.getTableConfigObj();
    mergeTableConfigFieldsTo(serialized, tableConfigObj.formatting);

    ConditionalFormatting.updateFormattingConditions(
      DataModel.table.fields(),
      DataModel.tableConfig.formattingOptions()
    );
  };

  fieldFormatMapChanged = fieldFormatMap => {
    var serialized = FieldFormatAdapter.serializeFieldFormatMap(fieldFormatMap);
    var tableConfigObj = DataModel.getTableConfigObj();
    mergeTableConfigFieldsTo(serialized, tableConfigObj.formatting);

    ConditionalFormatting.updateFormattingConditions(
      DataModel.table.fields(),
      DataModel.tableConfig.formattingOptions()
    );
  };

  tableChanged = (table, appController) => {
    var tableObj = DataModel.getTableObj();
    if (tableObj.metaData === undefined) tableObj.metaData = {};
    if (tableObj.metaData.name === undefined) tableObj.metaData.name = table.name();
    if (tableObj.metaData.recordCount === undefined) tableObj.metaData.recordCount = table.recordCount();

    this.updateHasStaticField();
    this.updateTableFields(table);
    this.updateSortFields(table, appController);

    this.updateFieldCheckedValues();
  };

  updateHasStaticField = () => {
    DataModel.table.hasStaticField(false);
  };

  updateTableFields = table => {
    var tableFields = {};
    table.fields().forEach(function(field, i) {
      var fieldName = field.name();
      var fieldObj = DataModel.table.field(fieldName) || {};
      if (fieldObj.fieldName === undefined) fieldObj.fieldName = fieldName;
      if (fieldObj.displayName === undefined) fieldObj.displayName = field.displayName();
      if (fieldObj.type === undefined) fieldObj.type = field.type();
      if (fieldObj.colId === undefined) fieldObj.colId = "col" + (i + 1);
      if (fieldObj.isSortable === undefined) fieldObj.isSortable = true;
      if (fieldObj.isQuickFilterable === undefined) fieldObj.isQuickFilterable = true;
      if (fieldObj.isPrimaryKey === undefined) fieldObj.isPrimaryKey = field.isPrimaryKey();
      if (fieldObj.criteriaFilter === undefined) {
        fieldObj.criteriaFilter = {
          newFilter: {},
          filters: [],
          operators: ConditionalOperators.getOperatorsByType(field.type()),
        };
      }

      tableFields[fieldName] = fieldObj;
    });
    DataModel.table.fields(tableFields);
    ConditionalFormatting.updateFormattingConditions(
      DataModel.table.fields(),
      DataModel.tableConfig.formattingOptions()
    );
  };

  updateSortFields = (table, appController) => {
    var sortFields = [];
    table.fields().forEach(function(field) {
      sortFields.push({
        fieldName: field.name(),
        displayName: field.displayName(),
        dataType: field.type(),
      });
    });
    sortFields = Sorter.sort(sortFields, {
      valueParser: item => item.displayName,
    });
    DataModel.table.sortFields(sortFields);
    // eslint-disable-next-line no-param-reassign
    appController.table.sortFields = sortFields;
  };

  updateFieldCheckedValues = () => {
    this.DataFilter.bindQuickFilterCheckedValues(DataModel.getFilterConfigDeprecated());
  };

  toggleFilterPanel = () => {
    this.eventService.publish("toggleFilterPanel");
  };

  getFilterFieldNames = function() {
    var filterList = DataModel.getFilterConfigDeprecated().filterList;
    if (filterList) {
      return filterList.map(d => d.name);
    }
    return [];
  };

  getSortFieldName = () =>
    DataModel.getFilterConfigDeprecated().sortField ? DataModel.getFilterConfigDeprecated().sortField.field : null;

  getSortOrder = () =>
    DataModel.getFilterConfigDeprecated().sortField ? DataModel.getFilterConfigDeprecated().sortField.order : null;

  getConditionalFormatsMap = () =>
    ConditionalFormat.jsonToConditionalFormatMap(DataModel.tableConfig.formattingOptions());
}
export default new FilterHacksService();

const mergeTableConfigFieldsTo = (newFieldFormattingMap, existingFieldFormattingMap) => {
  var existingFieldFormatting;
  var fieldName;
  var formattingKey;
  var newFieldFormatting;

  for (fieldName in newFieldFormattingMap) {
    if (Object.prototype.hasOwnProperty.call(newFieldFormattingMap, fieldName)) {
      newFieldFormatting = newFieldFormattingMap[fieldName];

      existingFieldFormatting = existingFieldFormattingMap[fieldName];
      if (!existingFieldFormatting) {
        existingFieldFormatting = {};
        // eslint-disable-next-line no-param-reassign
        existingFieldFormattingMap[fieldName] = existingFieldFormatting;
      }

      for (formattingKey in newFieldFormatting) {
        existingFieldFormatting[formattingKey] = newFieldFormatting[formattingKey];
      }
    }
  }
};
