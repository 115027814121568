import controller from "./processPanel.controller";

const bindings = {
  checkedRowCount: "<",
  checkedRowIds: "<",
  fieldFormatIdentifier: "<",
  fieldModels: "<",
  isFirstRecord: "<",
  isLastRecord: "<",
  isOpen: "<",
  onAddComment: "&",
  onClosePanel: "&",
  onCheck: "&",
  onDeleteBulk: "&",
  onDeleteRecord: "&",
  onDeleteInProgress: "&",
  onEnterBulkReviewMode: "&",
  onExitBulkReviewMode: "&",
  onNextRowClick: "&",
  onPrevRowClick: "&",
  onProcessBulk: "&",
  onProcessRecord: "&",
  rowCheckedInverse: "<",
  selectedRowData: "<",
  selectedRowId: "<",
};

const templateUrl = "visualizer/js/modules/core/processPanel/processPanel.tpl.html";
const controllerAs = "processPanel";

const ProcessPanelComponent = {
  bindings,
  controller,
  controllerAs,
  templateUrl,
};

export default ProcessPanelComponent;
