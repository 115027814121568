import ReactDirective from "acl-ui/angular/reactDirective.umd";
import sortableSwitch from "@viz-ui/components/SortableSwitch";
import Button from "@paprika/button";
import Switch from "@paprika/switch";
import searchBox from "@viz-ui/components/searchBox";

angular
  .module("acl.visualizer.dataTableConfig", [
    "acl.common.filters",
    "acl.visualizer.model.tableConfig",
    "acl.visualizer.style",
    "templates-visualizer",
  ])
  .directive("vizSortableSwitch", ReactDirective.getFactory(sortableSwitch))
  .directive("pkaButton", ReactDirective.getFactory(Button))
  .directive("pkaSwitch", ReactDirective.getFactory(Switch))
  .directive("vizSearchBox", ReactDirective.getFactory(searchBox));
