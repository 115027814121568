import BiViewService from "@viz-ui/services/charts/biView/biViewService";

angular
  .module("acl.visualizer.biView")
  .factory("BiViewService", function(BarChart, ChartDataSorter, DataModelFormat, Localize, AppConfig) {
    if (AppConfig.features.migrateVizServices) {
      return BiViewService;
    }
    return {
      isSupportableType: isSupportableType,
      prepareVizConfig: prepareVizConfig,
      sortChartData: sortChartData,
    };

    function isSupportableType(fieldType) {
      var unSupportableTypes = ["logical", "file", "digital_signature"];
      return unSupportableTypes.indexOf(fieldType) === -1;
    }

    function prepareVizConfig(chartType, dataConfig) {
      let config = {
        dataConfig: dataConfig,
      };
      let title;

      switch (chartType) {
        case "BarChart":
          title =
            Localize.getLocalizedString("_Chart.DataDistribution.Title_") + " - " + dataConfig.chartXAxis.displayName;
          config.displayConfig = BarChart.defaultDisplayConfig();

          // Strings should default to descending order in the bar chart.
          if (dataConfig.chartXAxis.type === "character") {
            config.displayConfig.order = "desc";
          }
          break;

        case "StatisticsViz":
          title =
            Localize.getLocalizedString("_Chart.StatisticsViz.Title_") +
            " - " +
            dataConfig.chartCategory[0].displayName;
          break;

        default:
          throw new Error("Unexpected chart type: " + chartType);
      }

      return {
        type: chartType,
        vizType: chartType,
        title: title,
        id: DataModelFormat.generateVisualizationId(),
        config: config,
      };
    }

    function sortChartData(data, type) {
      // If character it should sort by descending count by default.
      if (type === "character") {
        data = ChartDataSorter.sortChartDataByTotals(data, "desc");
      } else {
        data.forEach(function(row) {
          row.values = ChartDataSorter.sortChartDataByPropertyName(row.values, "key", type);
        });
      }
      return data;
    }
  });
