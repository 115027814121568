/**
 * @name color-picker
 *
 * @description
 * Wraps the jQuery spectrum plugin to provide a fully customizable color picker.
 * The model can be a color defined in any color format.
 * The resulting model value will always be an rgb or rgba color string.
 * Options can be provided using a json object.  All options are passed simply passed to the spectrum plugin.
 * An enhancement is if the spectrum option, 'showPaletteOnly', is set to true then the color picker will automatically close on selection.
 *
 */

angular.module("acl.visualizer.aclColorPicker").directive("aclColorPicker", function(ColorPalette) {
  const _updateOptions = function(scope, element, options) {
    const modelColor = tinycolor(scope.ngModel);
    if (modelColor.isValid()) {
      options.color = modelColor;
    }
    if (convertedColorsHasChanged(scope.ngModel, options.color)) {
      scope.ngModel = tinycolor(options.color).toRgbString();
    }
  };

  const convertedColorsHasChanged = function(color, newColor) {
    return convertToRgb(color) !== convertToRgb(newColor);
  };

  const convertToRgb = function(color) {
    return tinycolor(color).toRgbString();
  };

  return {
    restrict: "A",
    require: "ngModel",
    scope: {
      ngModel: "=",
      aclColorPicker: "=",
      ngChange: "&",
    },
    link: function(scope, element) {
      var colorPalette = new ColorPalette();
      var options = {
        color: "#3F3D3C",
        change: function(color) {
          scope.$apply(function() {
            scope.ngModel = color.toRgbString();
          });
        },
        palette: [
          colorPalette.greyColors(),
          colorPalette.darkColors(),
          colorPalette.baseColors(),
          colorPalette.lightColors(),
          colorPalette.lighterColors(),
        ],
        showPaletteOnly: true,
        showPalette: true,
        containerClassName: "acl-color-picker",
      };

      if (angular.isObject(scope.aclColorPicker)) {
        angular.extend(options, scope.aclColorPicker);
      }

      if (options.showPaletteOnly && angular.isUndefined(options.hideAfterPaletteSelect)) {
        options.hideAfterPaletteSelect = true;
      }

      _updateOptions(scope, element, options);
      $(element).spectrum(options);

      scope.$watch("ngModel", () => {
        if (convertedColorsHasChanged(scope.ngModel, options.color)) {
          _updateOptions(scope, element, options);
          $(element).spectrum("set", options.color);
          if (angular.isFunction(scope.ngChange)) scope.ngChange({ color: scope.ngModel });
        }
      });
    },
  };
});
