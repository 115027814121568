import logger from "@viz-ui/services/logger/logger";
import QueryClient from "@viz-ui/services/query/queryClient/queryClient";
import QueryGenerator from "@viz-ui/services/query/queryGenerator/queryGenerator";
import QueryErrorInterpreter from "@viz-ui/services/query/queryErrorInterpreter/queryErrorInterpreter.service";

angular
  .module("acl.visualizer.charts.backend")
  .service("ChartDataQueryBackendService", function(
    ChartDataAdapter,
    FieldFormatAdapter,
    DataFilter,
    Sorter,
    SummaryTableAdapter,
    AppConfig
  ) {
    return {
      chartDataRequest: (
        table,
        chartType,
        { filterConfig, formatting },
        dataConfig,
        globalFilters,
        scope,
        displayConfig
      ) => {
        let { filterList } = DataFilter.getFilterRequestBody(filterConfig);

        let aggColumnQuery = QueryGenerator.chartData({
          tableId: table.id(),
          chartType,
          filterList,
          dataConfig,
          globalFilters,
          scope,
        });

        return new Promise((resolve, reject) => {
          QueryClient.query(aggColumnQuery)
            .then(response => {
              logger.log(`chart query response`);
              const chartData = deserializeChartDataModel(
                chartType,
                dataConfig,
                table,
                formatting,
                response,
                displayConfig
              );
              logger.log(`chart data deserialized`);
              resolve(chartData);
            })
            .catch(response => {
              const errorCode = QueryErrorInterpreter.errorCode(response);
              reject(errorCode);
            });
        });
      },
    };

    function deserializeChartDataModel(chartType, dataConfig, table, formatting, response, displayConfig) {
      if (chartType === "SummaryTable") {
        const fieldFormatMap = FieldFormatAdapter.deserializeFieldFormatMap(formatting);
        const json = sortSummaryTableData(dataConfig, response.data.table);
        const showTotals = displayConfig.showTotals === false ? false : true;

        return SummaryTableAdapter.deserializeSummaryTable(dataConfig, fieldFormatMap, json, showTotals);
      }
      const aggregationData = response.data.table;
      return ChartDataAdapter.deserializeChartData(chartType, table, dataConfig, aggregationData);
    }

    function sortSummaryTableData(dataConfig, json) {
      let chartColumn = dataConfig.chartColumns.length ? dataConfig.chartColumns[0] : undefined;
      let subtotalData = chartColumn
        ? Sorter.sort(json.subtotalData, {
            dataType: chartColumn.type,
            valueParser: item => item[chartColumn.fieldName],
          })
        : json.subtotalData;

      let { chartRows } = dataConfig;
      let rowTypes = chartRows.map(row => row.type);
      let summarizedData = json.summarizedData.sort((a, b) => {
        let i;
        let result;
        for (i = 0; i < rowTypes.length; i++) {
          let comparator = Sorter.getTypedComparator({ dataType: rowTypes[i] });
          result = comparator(a[chartRows[i].fieldName], b[chartRows[i].fieldName]);
          if (result !== 0) {
            return result;
          }
        }
        return 0;
      });

      return {
        subtotalData: subtotalData,
        summarizedData: summarizedData,
      };
    }
  });
