import { JwtHighbondClient } from "@acl-services/jwt-highbond-client";
import backendApi from "../apiCall/backendApi";
import ApiPath from "@viz-ui/services/apiPath/apiPathService";

angular
  .module("acl.visualizer.downloadReports.backend", ["acl.common.event"])
  .service("downloadReportBackend", function(EventService) {
    const eventService = EventService.register("acl.visualizer.downloadReportBackend");
    const service = {
      getReportTypes: async orgId => {
        const url = ApiPath.reports.getReportTypes(orgId);
        const response = await backendApi.publicApiGet(url);
        if (response.error) {
          return [];
        }
        const { data } = response.data;
        return transformData(data);
      },
      emailReport: (orgId, report, filterExpression) => {
        const { reportId, reportName } = report;
        const url = ApiPath.reports.generateReportUrl(orgId, reportId);
        const body = {
          dataArgs: {
            ...filterExpression,
          },
          reportMetadata: {
            reportName: reportName,
            retryUrl: window.location.href,
            resourceName: reportName,
            resourceType: "Results",
          },
        };
        const options = {};
        return generateReport(url, { options, body });
      },
      downloadReport: async (orgId, report, filterExpression) => {
        const { reportId, reportName } = report;
        const reportDispatcherUrl = backendApi.getReportDispatcherUrl();
        await new JwtHighbondClient({
          autoRedirect: true,
        })
          .webSocketHandshakeWithTokenRefresh(reportDispatcherUrl)
          .then(socket => {
            socket.send(JSON.stringify({ action: "CONNECTION_ID" })); //Getting socket connectionId

            // Handling situation when user clicks on cancel in report generation pop up, after initiating the report generation
            eventService.subscribe("reportGenerationCancelled", () => {
              //Checking if the websocket is open, before closing the connection
              if (socket.readyState === WebSocket.OPEN) {
                socket.close();
              }
            });

            socket.onmessage = async response => {
              const message = JSON.parse(response.data);
              switch (message.action) {
                case "CONNECTION_ID": {
                  const url = ApiPath.reports.generateReportUrl(orgId, reportId, message.data.connectionId);
                  const body = {
                    dataArgs: { ...filterExpression },
                    reportMetadata: {
                      reportName: reportName,
                      retryUrl: window.location.href,
                      resourceName: reportName,
                      resourceType: "Results",
                    },
                  };
                  const options = {};
                  await generateReport(url, { options, body });
                  break;
                }
                case "REPORT": {
                  if (message.data.reportStatus) {
                    eventService.publish("reportGenerated", {
                      reportDownloadURL: message.data.reportDownloadURL,
                      reportStatus: message.data.reportStatus,
                    });
                  } else {
                    eventService.publish("reportGenerationFailure", {
                      reportStatus: message.data.reportStatus,
                    });
                  }
                  socket.close();
                  break;
                }
                default:
                  break;
              }
            };

            socket.onclose = () => {
              // Do nothing, as this is a default method which would be called on close or error
            };

            socket.onerror = e => {
              eventService.publish("webSocketServerError", e);
            };
          })
          .catch(error => {
            eventService.publish("webSocketConnectionError", error);
          });
      },
      getOpenStatuses: async orgId => {
        const url = ApiPath.reports.getRecordStatuses(orgId);
        const response = await backendApi.publicApiGet(url);
        if (response.error) {
          return [];
        }
        const { data } = response.data;
        const activeStatuses = data.filter(element => {
          return element.attributes.active;
        });
        return activeStatuses.map(status => {
          return status.attributes.name;
        });
      },
    };
    return service;
  });

const transformData = data => {
  const reportGroups = getUniqueReportName(data);
  let records = [];
  reportGroups.forEach(rg => {
    const filteredRecords = data.filter(record => record.attributes.report_group_by === rg);
    const formatTypes = filteredRecords.map(record => ({
      report_id: record.attributes.report_id,
      report_generate: record.attributes.report_generate,
    }));
    records.push({
      report_group_by: filteredRecords[0].attributes.report_group_by,
      formatTypes: formatTypes,
    });
  });

  //Sorting records based on alphabetic order.
  records = records.sort(function(a, b) {
    const nameA = a.report_group_by ? a.report_group_by.toLowerCase() : a.report_group_by;
    const nameB = b.report_group_by ? b.report_group_by.toLowerCase() : b.report_group_by;
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
  return records;
};

const getUniqueReportName = data => {
  let uniqueReportNames = new Set();
  data.forEach(item => {
    // preventing empty / undefined report names getting added to the list
    if (item.attributes.report_group_by) uniqueReportNames.add(item.attributes.report_group_by);
  });
  return Array.from(uniqueReportNames);
};

const generateReport = (url, options) => backendApi.publicApiPost(url, options);
