import HighChart from "@acl-services/sriracha-high-chart";
import ReactDirective from "acl-ui/angular/reactDirective.umd";

import LineChart from "@viz-ui/components/charts/lineChart/lineChart";
import BarChart from "@viz-ui/components/charts/barChart/barChart";
import PieChart from "@viz-ui/components/charts/pieChart/pieChart";
import AreaChart from "@viz-ui/components/charts/areaChart/areaChart";
import AreaChartDirective from "./areaChart/areaChart.directive";
import BarChartDirective from "./barChart/barChart.directive";
import BubbleChartDirective from "./bubbleChart/bubbleChart.directive";
import HeatMapDirective from "./heatMap/heatMap.directive";
import HistogramDirective from "./histogram/histogram.directive";
import LineChartDirective from "./lineChart/lineChart.directive";
import PieChartDirective from "./pieChart/pieChart.directive";
import TreemapDirective from "./treemap/treemap.directive";
import CombinationChartDirective from "./combinationChart/combinationChart.directive";
import MapDirective from "./mapChart/mapChart.directive";
import HighChartLabelSizer from "./highChartLabelSizer";

angular
  .module("acl.visualizer.highCharts", ["acl.visualizer.charts"])
  .directive("highChart", ReactDirective.getFactory(HighChart))
  .directive("lineChart", ReactDirective.getFactory(LineChart))
  .directive("barChart", ReactDirective.getFactory(BarChart))
  .directive("pieChart", ReactDirective.getFactory(PieChart))
  .directive("areaChart", ReactDirective.getFactory(AreaChart))
  .directive("hcAreaChart", AreaChartDirective.factory)
  .directive("hcBarChart", BarChartDirective.factory)
  .directive("hcBubbleChart", BubbleChartDirective.factory)
  .directive("hcHeatMap", HeatMapDirective.factory)
  .directive("hcHistogram", HistogramDirective.factory)
  .directive("hcLineChart", LineChartDirective.factory)
  .directive("hcPieChart", PieChartDirective.factory)
  .directive("hcTreemap", TreemapDirective.factory)
  .directive("hcCombinationChart", CombinationChartDirective.factory)
  .directive("hcMapChart", MapDirective.factory)
  .service("HighChartLabelSizer", HighChartLabelSizer);
