// Data Downsampler Service
// ------------------------
// [chartData] should be multiple groups all formated just the way you would pass
// to D3. Purpose is to reduce the amount of points the DOM will try to render
// without sacrificing visualization accuracy
//
// [dataItemLimit] is the total number of points believed to be efficiently
// drawable by the browser. We want our output to be below that number while
// retaining as much information as possible.
//
// [aggregationType] should be 'max', 'min', 'sum', or 'average' depending on the
// chart config the user has chosen.
import reduce from "lodash/reduce";
import minBy from "lodash/minBy";
import maxBy from "lodash/maxBy";

angular.module("acl.visualizer.charts").factory("DataDownsamplerService", function() {
  "use strict";
  function getWeightedSumOfBubbles(bubbles) {
    var totalX = 0,
      totalY = 0,
      totalSize = 0,
      result = angular.copy(bubbles[0]);
    bubbles.forEach(function(bubble) {
      totalX += bubble.x * (bubble.size / 1000);
      totalY += bubble.y * (bubble.size / 1000);
      totalSize += bubble.size;
    });
    result.x = totalX / (totalSize / 1000);
    result.y = totalY / (totalSize / 1000);
    result.size = totalSize;
    return result;
  }

  function processBubbleBin(bubbles, aggregationType) {
    var bubble;
    switch (aggregationType) {
      case "min":
        bubble = angular.copy(
          minBy(bubbles, function(bubble) {
            return bubble.size;
          })
        );
        break;
      case "max":
        bubble = angular.copy(
          maxBy(bubbles, function(bubble) {
            return bubble.size;
          })
        );
        break;
      case "sum":
      case "count":
        bubble = getWeightedSumOfBubbles(bubbles);
        break;
      case "average":
        bubble = getWeightedSumOfBubbles(bubbles);
        bubble.size /= bubbles.length;
        break;
      default:
        throw "Unknown aggregation type.";
    }

    bubble.rangeX = {
      max: maxBy(bubbles, function(bubble) {
        return bubble.x;
      }).x,
      min: minBy(bubbles, function(bubble) {
        return bubble.x;
      }).x,
    };
    bubble.rangeY = {
      max: maxBy(bubbles, function(bubble) {
        return bubble.y;
      }).y,
      min: minBy(bubbles, function(bubble) {
        return bubble.y;
      }).y,
    };

    return bubble;
  }

  function processStackedAreaBin(points, aggregationType) {
    var point, sum;
    switch (aggregationType) {
      case "min":
        point = angular.copy(
          minBy(points, function(point) {
            return point.y;
          })
        );
        point.x = points[0].x;
        return point;
      case "max":
        point = angular.copy(
          maxBy(points, function(point) {
            return point.y;
          })
        );
        point.x = points[0].x;
        return point;
      case "sum":
      case "count":
        sum = reduce(
          points,
          function(memo, point) {
            return memo + point.y;
          },
          0
        );
        point = angular.copy(points[0]);
        point.y = sum;
        return point;
      case "average":
        sum = reduce(
          points,
          function(memo, point) {
            return memo + point.y;
          },
          0
        );
        point = angular.copy(points[0]);
        point.y = sum / points.length;
        return point;
      default:
        throw "Unknown aggregation type.";
    }
  }

  var service = {
    //FIXME: this mutates the inputs while returning another value
    reduceStackedAreaData: function(chartData, dataLimit, aggregationType) {
      if (!chartData || !chartData.length) {
        return 1;
      }

      var inputCount = chartData[0].values.length * chartData.length;

      if (inputCount <= dataLimit) {
        return 1;
      }

      var reductionFactor = dataLimit / inputCount;
      var numBins = Math.max(Math.floor(reductionFactor * chartData[0].values.length), 1);
      var averageBinSize = chartData[0].values.length / numBins;

      chartData.forEach(function(series) {
        var samples = series.values.sort(function(a, b) {
          return a.x - b.x;
        });
        var reducedSamples = [];
        var bin = 1;
        while (samples.length) {
          var binSize = Math.round(bin * averageBinSize) - Math.round((bin - 1) * averageBinSize);
          reducedSamples.push(processStackedAreaBin(samples.splice(0, binSize), aggregationType));
          bin++;
        }
        series.values = reducedSamples;
      });

      return averageBinSize.toFixed(2);
    },

    reduceBubbleData: function(chartData, dataLimit, aggregationType) {
      if (!chartData || !chartData.length) {
        return 1;
      }

      var inputCount = 0,
        outputCount = 0,
        remainingCount;
      chartData.forEach(function(series) {
        inputCount += series.values.length;
      });

      if (inputCount <= dataLimit) {
        return 1;
      }

      remainingCount = inputCount;

      chartData = chartData.sort(function(a, b) {
        return a.values.length - b.values.length;
      });

      chartData.forEach(function(series) {
        var reductionFactor = (dataLimit - outputCount) / remainingCount;
        var numBins = Math.max(Math.floor(reductionFactor * series.values.length), 1);
        var numColumns = (numBins = Math.max(Math.floor(Math.sqrt(numBins)), 1));

        remainingCount -= series.values.length;

        var samples = series.values.sort(function(a, b) {
          return a.x - b.x;
        });
        var reducedSamples = [];

        var averageColumnSize = samples.length / numColumns;
        var columns = [],
          col = 1;
        while (samples.length) {
          var columnSize = Math.round(col * averageColumnSize) - Math.round((col - 1) * averageColumnSize);
          columns.push(samples.splice(0, columnSize));
          col++;
        }

        columns.forEach(function(column) {
          var samples = column.sort(function(a, b) {
            return a.y - b.y;
          });
          var averageBinSize = samples.length / numBins;
          var bin = 1;
          while (samples.length) {
            var binSize = Math.round(bin * averageBinSize) - Math.round((bin - 1) * averageBinSize);
            reducedSamples.push(processBubbleBin(samples.splice(0, binSize), aggregationType));
            bin++;
          }
        });

        outputCount += reducedSamples.length;
        series.values = reducedSamples;
      });

      return (inputCount / outputCount).toFixed(2);
    },
  };

  return service;
});
