angular.module("acl.visualizer.chartColorPicker").directive("chartColorPicker", function() {
  return {
    restrict: "E",
    replace: true,
    scope: {
      chartData: "<",
      chartDataConfig: "<",
      visible: "=isVisible",
      onChange: "&",
      fieldFormatIdentifier: "<",
    },
    templateUrl: "visualizer/js/modules/core/chartColorPicker/chartColorPicker.tpl.html",
    controller: function($scope, $timeout, AppConfig, ChartService, DataModel, EventService, Localize) {
      var eventService = EventService.register("chartColorPicker");
      var promise;

      $scope.colorList = null;
      $scope.axisType = null;

      function generateColorList() {
        const globalColorsTable = ChartService.getGlobalChartElementsColorsTable();
        const colorsObj = {};
        $scope.colorObjSize = 0;

        if ($scope.chartData) {
          // FIXME: Color picker should not be interacting directly with DataModel.
          $scope.colorMapping = angular.copy(DataModel.visualizationConfig.colorMapping()) || {};
          $scope.axisType = "";
          // FIXME: chartColorPicker shouldn't decide which user-selected field is used to determine colour.
          if ($scope.chartDataConfig.chartRows) {
            $scope.axisType = $scope.chartDataConfig.chartRows[0].fieldName || $scope.chartDataConfig.chartRows[0];
          } else {
            $scope.axisType = $scope.chartDataConfig.chartSeries || $scope.chartDataConfig.chartCategory;
          }

          $scope.chartData
            .filter(item => item)
            .forEach(item => {
              const key = $scope.axisType + item.key;
              if (globalColorsTable[key]) {
                // To handle (blank) value and to localize the string (issue GRCPRD-4135)
                let title = item.legendDisplayName || item.key || "";
                title = title === "(blank)" ? Localize.getLocalizedString("_Filter.BlankValue.Label_") : title;
                if (!(item.type && item.type === "line")) {
                  const getChartValueFormatter = ChartService.getValueFormatter(
                    $scope.fieldFormatIdentifier,
                    $scope.axisType,
                    $scope.chartDataConfig.fieldType
                  );
                  title = getChartValueFormatter(item.key);
                }
                colorsObj[key] = {
                  type: $scope.axisType,
                  color: globalColorsTable[key] || "#fff",
                  title: title,
                  key: item.key || "", //In case of combinationChart title cannot be used for setKey, as it is linked to locale, hence adding additional property called key
                };
                $scope.colorObjSize++;
              }
            });
        }
        return colorsObj;
      }

      function initColorGeneration() {
        if (promise) {
          $timeout.cancel(promise);
        }
        promise = $timeout(function() {
          $scope.colorList = $scope.visible ? generateColorList() : null;
        }, 200);
      }

      eventService.subscribe("charts.drawComplete", function() {
        initColorGeneration();
      });

      $scope.$watch("chartData", function() {
        initColorGeneration();
      });

      $scope.$watch("visible", function() {
        initColorGeneration();
      });

      $scope.setKey = function(key) {
        $scope.colorKeyForChange = key;
      };

      $scope.colorChanged = function(newColor) {
        const key = $scope.axisType + $scope.colorKeyForChange;
        if ($scope.colorMapping[key] !== newColor) {
          $scope.colorMapping[key] = newColor;
          $scope.onChange({
            value: angular.copy($scope.colorMapping),
          });
        }
      };

      $scope.$on("$destroy", function() {
        eventService.unregister();
      });
    },
  };
});
