/* eslint-disable */
const userAgentString = (() => {
  const userAgent = window.navigator.userAgent;
  let tem;
  let M = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*([\d\.]+)/i) || [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+(\.\d+)?)/g.exec(userAgent) || [];
    return "IE " + (tem[1] || "");
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = userAgent.match(/version\/([\.\d]+)/i)) !== null) {
    M[2] = tem[1];
  }

  return M.join(" ");
})();

const userAgentParts = userAgentString.split(" ");

export const userAgent = {
  browser: userAgentParts[0],
  version: userAgentParts[1],
  isIE: () => userAgentParts[0] === "IE",
};
/* eslint-enable */
