import isEqual from "lodash/isEqual";
import Model from "../model";

class FieldFormat extends Model {
  static modelType = "FieldFormat";

  isURL(...args) {
    return this.accessor("isURL", args, "boolean");
  }

  isHtml(...args) {
    return this.accessor("isHtml", args, "boolean");
  }

  isRaw(...args) {
    return this.accessor("isRaw", args, "boolean");
  }

  hasOnlyRadixSeparator(...args) {
    return this.accessor("hasOnlyRadixSeparator", args, "boolean");
  }

  dateFormat(...args) {
    return this.accessor("dateFormat", args, "string");
  }

  datetimeFormat(...args) {
    return this.accessor("datetimeFormat", args, "string");
  }

  prefix(...args) {
    return this.accessor("prefix", args, "string");
  }

  precision(...args) {
    return this.accessor("precision", args, "number");
  }

  postfix(...args) {
    return this.accessor("postfix", args, "string");
  }

  sign(...args) {
    return this.accessor("sign", args, "string");
  }

  thousandsDelimiter(...args) {
    return this.accessor("thousandsDelimiter", args, "boolean");
  }

  abbreviate(...args) {
    return this.accessor("abbreviate", args, "boolean");
  }

  keepTrailingZeros(...args) {
    return this.accessor("keepTrailingZeros", args, "boolean");
  }

  displayTime(...args) {
    return this.accessor("displayTime", args, "boolean");
  }

  equals(other) {
    return other && isEqual(this._data, other._data);
  }

  clone() {
    return new FieldFormat(this._data);
  }

  static fromJson(json) {
    return new FieldFormat()
      .isURL(json.isURL)
      .isHtml(json.isHtml)
      .isRaw(json.isRaw)
      .hasOnlyRadixSeparator(json.hasOnlyRadixSeparator)
      .dateFormat(json.dateFormat)
      .datetimeFormat(json.datetimeFormat)
      .prefix(json.prefix)
      .precision(json.precision)
      .postfix(json.postfix)
      .sign(json.sign)
      .thousandsDelimiter(json.thousandsDelimiter)
      .abbreviate(json.abbreviate)
      .keepTrailingZeros(json.keepTrailingZeros)
      .displayTime(json.displayTime);
  }

  toJson() {
    return this._deleteUndefinedProperties({
      isURL: this.isURL(),
      isHtml: this.isHtml(),
      isRaw: this.isRaw(),
      hasOnlyRadixSeparator: this.hasOnlyRadixSeparator(),
      dateFormat: this.dateFormat(),
      datetimeFormat: this.datetimeFormat(),
      prefix: this.prefix(),
      precision: this.precision(),
      postfix: this.postfix(),
      sign: this.sign(),
      thousandsDelimiter: this.thousandsDelimiter(),
      abbreviate: this.abbreviate(),
      keepTrailingZeros: this.keepTrailingZeros(),
      displayTime: this.displayTime(),
    });
  }
}
export default FieldFormat;
