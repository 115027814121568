import tableCapability from "@visualizer/modules/core/tableCapability/tableCapability.service";

export default function getDataWithRowIndex(data, startIndex) {
  const canRemediateRecords = tableCapability.canRemediateRecords();

  if (canRemediateRecords) return data;

  return data.map((row, rowIndex) => ({
    ...row,
    "metadata.exception_id": startIndex + rowIndex,
    "metadata.position": startIndex + rowIndex + 1,
  }));
}
