angular.module("acl.common.autoHeight").directive("aclAutoHeight", function($timeout, $window) {
  var timeoutDelay = 200;

  return {
    restrict: "A",
    scope: {
      autoHeightProperty: "@",
      autoHeightMatchElement: "@",
      autoHeightSubtractElements: "<",
      autoHeightCallback: "&",
      autoHeightCallbackForce: "@",
    },
    link: function(scope, element) {
      var timeout;
      var prevHeight;
      scope.autoHeightProperty = scope.autoHeightProperty || "height";

      $timeout(setHeight, timeoutDelay);

      function setHeight() {
        var matchElement = scope.autoHeightMatchElement;
        var parentElement = null;
        var height;

        // Get height of the Match Element.
        if ($.isNumeric(matchElement)) {
          height = matchElement;
        } else {
          matchElement = trySelectForAncestor(matchElement, $(element));
          height = getHeight(matchElement);
          parentElement = matchElement;
        }

        // Factor in height of the Subtract Elements.
        height -= getElementsHeight(scope.autoHeightSubtractElements, parentElement);

        if (height !== prevHeight || scope.autoHeightCallbackForce) {
          if (height !== prevHeight) {
            element.css(scope.autoHeightProperty, height + "px");
          }

          if (scope.autoHeightCallback) {
            scope.autoHeightCallback();
          }
        }

        prevHeight = height;
      }

      angular.element($window).resize(function() {
        if (timeout) {
          $timeout.cancel(timeout);
        }

        timeout = $timeout(function() {
          setHeight();
        }, timeoutDelay);
      });
    },
  };

  function getHeight(element) {
    if ($.isWindow(element)) {
      // jQuery says to use height in case of window.
      return element.height();
    }
    // Only place height is called so we can control technique used.
    // outerHeight includes padding + border NOT margin.
    return element.outerHeight();
  }

  function getElementsHeight(targets, parent) {
    return targets.reduce(function(a, b) {
      var amount = b;
      if (!$.isNumeric(amount)) {
        amount = getHeight(trySelectForDescendant(b, parent));
      }
      if (!$.isNumeric(amount)) {
        amount = 0;
      }
      return a + amount;
    }, 0);
  }

  // Will attempt to find element as child. Expect parent to be jQuery
  // object.
  function trySelectForDescendant(selector, parent) {
    var child;

    if (selector === "window") return $($window);

    // Try to find via parent
    if (parent && parent.length) {
      child = $(parent).find(selector);

      if (child.length) return child;
    }

    return $(selector);
  }

  // Will attempt to find element as an ancestor. Expect child param to be jQuery
  // object.
  function trySelectForAncestor(selector, child) {
    var parent;

    if (selector === "window") return $($window);

    // Try to find via child
    if (child && child.length) {
      parent = $(child).closest(selector);

      if (parent.length) return parent;
    }

    return $(selector);
  }
});
