angular
  .module("acl.visualizer.modules.backend", [
    "acl.common.config",
    "acl.common.event",
    "acl.common.postAndDownload",
    "acl.visualizer.dataModel",
  ])
  .run(function(AppConfig, DataModel, EventService) {
    var eventService = EventService.register("acl.visualizer.ModulesBackend");

    eventService.subscribe("exportToCsv", function(_, deferred, filename) {
      var data = {
        file_name: filename,
        export_data: JSON.stringify(getExportData()),
      };

      return eventService.request("postAndDownload", null, AppConfig.exportToCsvUrl, data).then(
        function(response) {
          deferred.resolve(response);
        },
        function(errorCode) {
          deferred.reject(errorCode);
        }
      );
    });

    function getExportData() {
      return DataModel.toSaveViz();
    }

    return {};
  });
