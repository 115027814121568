angular.module("acl.visualizer.charts").factory("PieChart", function(ChartService, DataModel, AppConfig, Sorter) {
  var defaultMargin = { top: 0, right: 90, bottom: 110, left: 0 };

  return {
    defaultDisplayConfig: function() {
      return {
        showLegend: true,
        donut: false,
        donutRatio: 0.35,
        showPercentageTicks: true,
        showCountTicks: true,
        enableBoost: true,
      };
    },

    getChartDirectiveConfig: function(interpretationId, chartConfig) {
      var getColorByField = function() {
        return (
          (chartConfig &&
            chartConfig.dataConfig &&
            chartConfig.dataConfig.chartCategory &&
            chartConfig.dataConfig.chartCategory.fieldName) ||
          ""
        );
      };

      function valueFormatter(interpretationId, chartConfig) {
        const { vizId, dataConfig, displayConfig } = chartConfig;
        if (displayConfig.valueFormattingOptions && dataConfig.chartValue.aggregationType === "average") {
          const chartValueFieldName = `${vizId}-chart-value`;
          const chartValueFieldType = "numeric";
          return ChartService.getValueFormatter(interpretationId, chartValueFieldName, chartValueFieldType);
        }
        return ChartService.getValueFormatter(
          interpretationId,
          dataConfig.chartValue.fieldName,
          dataConfig.chartValue.type
        );
      }

      return {
        showLegend: chartConfig.displayConfig.showLegend,
        donut: chartConfig.displayConfig.donut,
        donutRatio: chartConfig.displayConfig.donutRatio,
        showPercentageTicks: chartConfig.displayConfig.showPercentageTicks,
        displayDataLabels: chartConfig.displayConfig.showCountTicks,
        enableBoost: chartConfig.displayConfig.enableBoost,
        showTotals: chartConfig.displayConfig.showTotals,
        DATA_ITEM_LIMIT: ChartService.getDataItemLimit(),
        colorByField: getColorByField(),
        labelFormatter: ChartService.getValueFormatter(
          interpretationId,
          chartConfig.dataConfig.chartCategory.fieldName,
          chartConfig.dataConfig.chartCategory.type
        ),
        valueFormatter: valueFormatter(interpretationId, chartConfig),
      };
    },

    populateChartConfigColumnDefs: function(chartConfigColumnDefs) {
      var tempColumnDef;
      var fields = DataModel.table.fields();
      if (fields) {
        for (var fieldName in fields) {
          tempColumnDef = {};
          tempColumnDef.displayName = fields[fieldName].displayName;
          tempColumnDef.fieldName = fieldName;
          tempColumnDef.type = fields[fieldName].type;

          switch (tempColumnDef.type) {
            case "character":
            case "logical":
              if (AppConfig.features.logicalFieldsInCharts || tempColumnDef.type === "character") {
                chartConfigColumnDefs.chartCategory.push(tempColumnDef);
              }
              break;
            case "numeric":
              chartConfigColumnDefs.chartValue.push(tempColumnDef);
              chartConfigColumnDefs.chartCategory.push(tempColumnDef);
              break;
            case "date":
            case "datetime":
            case "time":
              chartConfigColumnDefs.chartCategory.push(tempColumnDef);
              break;
          }
        }
        chartConfigColumnDefs.chartCategory = Sorter.sort(chartConfigColumnDefs.chartCategory, {
          valueParser: item => item.displayName,
        });
        chartConfigColumnDefs.chartValue = Sorter.sort(chartConfigColumnDefs.chartValue, {
          valueParser: item => item.displayName,
        });
      }

      return chartConfigColumnDefs;
    },

    chartConfigColumnDefs: function() {
      return {
        chartCategory: [],
        chartValue: [],
      };
    },

    isValidDataConfig: function(dataConfig) {
      return !!(
        dataConfig &&
        dataConfig.chartCategory &&
        dataConfig.chartValue &&
        ((dataConfig.chartValue.aggregationType && dataConfig.chartValue.fieldName) ||
          dataConfig.chartValue.aggregationType === "count")
      );
    },
  };
});
