import moment from "moment";

angular
  .module("acl.visualizer.fieldFormatOptions")
  .service("FieldFormatter", function(
    AppConfig,
    DateFormatter,
    DatetimeFormatter,
    Localize,
    NumberFormatter,
    TimeFormatter
  ) {
    var service = {
      isDateFormattableType: function(fieldType) {
        return fieldType === "date";
      },
      getDateFormattingOptions: getDateFormattingOptions,
      isDatetimeFormattableType: function(fieldType) {
        return fieldType === "datetime";
      },
      getDatetimeHeading: getDatetimeHeading,
      getDatetimeFormattingOptions: getDatetimeFormattingOptions,
      getTimeDisplayHeading: getTimeDisplayHeading,
      getTimeDisplayOptions: getTimeDisplayOptions,
      isNumericFormattableType: function(fieldType) {
        return fieldType === "numeric";
      },
      getNumericFormattingHeading: getNumericFormattingHeading,
      getNumericFormattingOptions: getNumericFormattingOptions,
      getNumericRoundingHeading: getNumericRoundingHeading,
      getNumericRoundingOptions: getNumericRoundingOptions,
      getTimeFormattingOptions: getTimeFormattingOptions,
      compareFormattingOptions: compareFormattingOptions,
      compareRoundingOptions: compareRoundingOptions,
    };

    var empty = {};

    return service;

    function getDateFormattingOptions(selectedFormatObj) {
      var exampleDate = moment(new Date(new Date().getFullYear(), 0, 31)).format("MM/DD/YYYY");
      var formats = [];

      if (AppConfig.features.unformattedValues) {
        formats.push({ isRaw: true, dateFormat: undefined });
      }
      formats = formats.concat([
        { isRaw: false, dateFormat: undefined }, // Default format.
        { isRaw: false, dateFormat: "YYYY-MM-DD" },
        { isRaw: false, dateFormat: "MM-DD-YYYY" },
        { isRaw: false, dateFormat: "DD-MMM-YYYY" },
        { isRaw: false, dateFormat: "YYYY/MM/DD" },
        { isRaw: false, dateFormat: "MM/DD/YYYY" },
        { isRaw: false, dateFormat: "DD/MMM/YYYY" },
      ]);

      return formats.map(function(fieldFormatJson) {
        var example = fieldFormatJson.isRaw
          ? Localize.getLocalizedString("_Format.Date.Raw.Example_")
          : DateFormatter.format(exampleDate, fieldFormatJson);

        return {
          example: example,
          label: Localize.getLocalizedString(getDateFormatLabelKey(fieldFormatJson)),
          selected: compareFormattingOptions(fieldFormatJson, selectedFormatObj),
          format: fieldFormatJson,
        };
      });
    }

    function getDateFormatLabelKey(fieldFormatJson) {
      var template = key => `_Format.Date.${key}.Label_`;
      if (fieldFormatJson) {
        if (fieldFormatJson.isRaw) {
          return template("Raw");
        }

        if (fieldFormatJson.dateFormat) {
          return template(fieldFormatJson.dateFormat);
        }
      }
      return template("Default");
    }

    function getDatetimeHeading() {
      return {
        isHeading: true,
        label: Localize.getLocalizedString("_Format.Section.Label_"),
      };
    }

    function getDatetimeFormattingOptions(selectedFormatObj) {
      var selectedDisplayTime = selectedFormatObj.displayTime === undefined ? true : selectedFormatObj.displayTime;
      var timeFormat = getLocalizedTimeFormat();
      var formats = [];
      if (AppConfig.features.unformattedValues) {
        formats.push({ isRaw: true, datetimeFormat: undefined });
      }
      formats = formats.concat([
        { isRaw: false, datetimeFormat: undefined }, // Default format.
        { isRaw: false, datetimeFormat: "YYYY-MM-DD " + timeFormat },
        { isRaw: false, datetimeFormat: "MM-DD-YYYY " + timeFormat },
        { isRaw: false, datetimeFormat: "DD-MMM-YYYY " + timeFormat },
        { isRaw: false, datetimeFormat: "YYYY/MM/DD " + timeFormat },
        { isRaw: false, datetimeFormat: "MM/DD/YYYY " + timeFormat },
        { isRaw: false, datetimeFormat: "DD/MMM/YYYY " + timeFormat },
      ]);

      return formats.map(function(fieldFormatJson) {
        var mergedFieldFormatJson = Object.assign({}, fieldFormatJson, { displayTime: selectedDisplayTime });
        var exampleDate = moment(new Date(new Date().getFullYear(), 0, 31, 22, 14, 42)).format("MM/DD/YYYY HH:mm:ss");
        var example = mergedFieldFormatJson.isRaw
          ? Localize.getLocalizedString("_Format.Datetime.Raw.Example_")
          : DatetimeFormatter.format(exampleDate, mergedFieldFormatJson);

        return {
          example: example,
          format: mergedFieldFormatJson,
          isHeading: false,
          label: Localize.getLocalizedString(getDatetimeFormatLabelKey(mergedFieldFormatJson)),
          selected: compareFormattingOptions(mergedFieldFormatJson, selectedFormatObj),
        };
      });
    }

    function getDatetimeFormatLabelKey(fieldFormatJson) {
      var template = key => `_Format.Datetime.${key}.Label_`;
      if (fieldFormatJson) {
        if (fieldFormatJson.isRaw) {
          return template("Raw");
        }

        if (fieldFormatJson.datetimeFormat) {
          return template(extractDateFormat(fieldFormatJson.datetimeFormat));
        }
      }
      return template("Default");
    }

    function extractDateFormat(formatString) {
      if (typeof formatString === "undefined") {
        return undefined;
      }
      return formatString.split(" ")[0];
    }

    function getTimeDisplayHeading() {
      return {
        isHeading: true,
        label: Localize.getLocalizedString("_Format.Datetime.TimeSection.Label_"),
      };
    }

    function getTimeDisplayOptions(selectedFormatObj) {
      var selectedDisplayTime =
        selectedFormatObj.isRaw || (selectedFormatObj.displayTime === undefined ? true : selectedFormatObj.displayTime);
      var selectedDatetimeFormat = selectedFormatObj.datetimeFormat || "";
      var exampleDate = moment(new Date(new Date().getFullYear(), 0, 31, 22, 14, 42)).format("MM/DD/YYYY HH:mm:ss");

      return [
        {
          example: DatetimeFormatter.format(exampleDate, { datetimeFormat: selectedDatetimeFormat, displayTime: true }),
          format: {
            isRaw: selectedFormatObj.isRaw,
            datetimeFormat: selectedDatetimeFormat,
            displayTime: true,
          },
          isHeading: false,
          label: Localize.getLocalizedString("_Format.Datetime.Visible.Label_"),
          selected: selectedDisplayTime,
        },
        {
          example: DatetimeFormatter.format(exampleDate, {
            datetimeFormat: selectedDatetimeFormat,
            displayTime: false,
          }),
          format: {
            isRaw: selectedFormatObj.isRaw,
            datetimeFormat: selectedDatetimeFormat,
            displayTime: false,
          },
          isHeading: false,
          label: Localize.getLocalizedString("_Format.Datetime.Hidden.Label_"),
          selected: !selectedDisplayTime,
        },
      ];
    }

    function getNumericFormattingHeading() {
      return {
        isHeading: true,
        label: Localize.getLocalizedString("_Format.Section.Label_"),
      };
    }

    function getNumericFormattingOptions(selectedFormattingObj, selectedRoundingObj) {
      var exampleNumber = -1234;
      var optionDefs = [];
      optionDefs = optionDefs.concat([
        {
          labelKey: "_Format.Numeric.None.Label_",
          format: {
            hasOnlyRadixSeparator: true,
            thousandsDelimiter: false,
            prefix: undefined,
            sign: undefined,
            postfix: undefined,
          },
        },
        {
          labelKey: "_Format.Numeric.Number.Label_",
          format: {
            hasOnlyRadixSeparator: false,
            thousandsDelimiter: true,
            prefix: undefined,
            sign: undefined,
            postfix: undefined,
          },
        }, // Default format.
        {
          labelKey: "_Format.Numeric.Currency.Label_",
          format: {
            hasOnlyRadixSeparator: false,
            thousandsDelimiter: true,
            prefix: "$",
            sign: undefined,
            postfix: undefined,
          },
        },
        {
          labelKey: "_Format.Numeric.Financial.Label_",
          format: {
            hasOnlyRadixSeparator: false,
            thousandsDelimiter: true,
            prefix: undefined,
            sign: "(",
            postfix: undefined,
          },
        },
        {
          labelKey: "_Format.Numeric.Percent.Label_",
          format: {
            hasOnlyRadixSeparator: false,
            thousandsDelimiter: true,
            prefix: undefined,
            sign: undefined,
            postfix: "%",
          },
        },
      ]);

      return optionDefs.map(function(optionDef) {
        // The displayed formatting options should incorporate THIS formatting option, plus the selected ROUNDING option.
        var fieldFormatJson = Object.assign({}, selectedRoundingObj, optionDef.format);
        var example = NumberFormatter.format(exampleNumber, fieldFormatJson);

        return {
          example: example,
          format: fieldFormatJson,
          isHeading: false,
          label: Localize.getLocalizedString(optionDef.labelKey),
          selected: compareFormattingOptions(optionDef.format, selectedFormattingObj),
        };
      });
    }

    function getTimeFormattingOptions(selectedFormattingObj) {
      if (!AppConfig.features.unformattedValues) {
        return [];
      }

      var exampleTime = moment(new Date(new Date().getFullYear(), 0, 31, 17, 30, 0)).format("H:mm:ss");
      var formats = [
        { isRaw: true, timeFormat: undefined },
        { isRaw: false, timeFormat: getLocalizedTimeFormat() }, // Default format.
      ];

      return formats.map(function(fieldFormatJson) {
        var example = fieldFormatJson.isRaw
          ? Localize.getLocalizedString("_Format.Time.Raw.Example_")
          : TimeFormatter.format(exampleTime, fieldFormatJson);

        return {
          example: example,
          label: Localize.getLocalizedString(getTimeFormatLabelKey(fieldFormatJson)),
          selected: compareFormattingOptions(fieldFormatJson, selectedFormattingObj),
          format: fieldFormatJson,
        };
      });
    }

    function getLocalizedTimeFormat() {
      return Localize.getLocalizedString("_Format.Time.Default.Config_");
    }

    function getTimeFormatLabelKey(fieldFormatJson) {
      var template = key => `_Format.Time.${key}.Label_`;
      if (fieldFormatJson) {
        if (fieldFormatJson.isRaw) {
          return template("Raw");
        }
      }
      return template("Default");
    }

    function getNumericRoundingHeading() {
      return {
        isHeading: true,
        label: Localize.getLocalizedString("_Format.Numeric.RoundingSection.Label_"),
      };
    }

    function getNumericRoundingOptions(selectedRoundingObj, selectedFormattingObj) {
      var exampleNumber = 1234.567;
      var optionDefs = [
        {
          labelKey: "_Format.Numeric.Exact.Label_",
          format: { precision: 5, keepTrailingZeros: false, abbreviate: false },
        },
        {
          labelKey: "_Format.Numeric.2Decimals.Label_",
          format: { precision: 2, keepTrailingZeros: true, abbreviate: false },
        },
        {
          labelKey: "_Format.Numeric.NoDecimals.Label_",
          format: { precision: 0, keepTrailingZeros: false, abbreviate: false },
        },
        {
          labelKey: "_Format.Numeric.Rounded.Label_",
          format: { precision: 2, keepTrailingZeros: false, abbreviate: true },
        },
      ];

      return optionDefs.map(function(optionDef) {
        // The displayed rounding options should incorporate THIS rounding option, plus the selected FORMAT option.
        var mergedFieldFormatJson = Object.assign({}, selectedFormattingObj, optionDef.format);

        return {
          example: NumberFormatter.format(exampleNumber, mergedFieldFormatJson),
          format: mergedFieldFormatJson,
          isHeading: false,
          label: Localize.getLocalizedString(optionDef.labelKey),
          selected: compareRoundingOptions(optionDef.format, selectedRoundingObj),
        };
      });
    }

    function compareFormattingOptions(a, b) {
      if (a && b) {
        if (a.isRaw === true && b.isRaw === true) {
          return true;
        }

        return (
          (a.hasOnlyRadixSeparator === true) === (b.hasOnlyRadixSeparator === true) &&
          ((!a.dateFormat && !b.dateFormat) || a.dateFormat === b.dateFormat) &&
          ((!a.datetimeFormat && !b.datetimeFormat) ||
            extractDateFormat(a.datetimeFormat) === extractDateFormat(b.datetimeFormat)) &&
          ((!a.postfix && !b.postfix) || a.postfix === b.postfix) &&
          ((!a.prefix && !b.prefix) || a.prefix === b.prefix) &&
          ((a.sign === "(" && b.sign === "(") || (a.sign !== "(" && b.sign !== "("))
        );
      }

      return isFormatUndefined(a) && isFormatUndefined(b);
    }

    function isFormatUndefined(v) {
      return v === undefined || v === null || angular.equals(v, empty);
    }

    function compareRoundingOptions(a, b) {
      return (
        (a &&
          b &&
          a.precision === b.precision &&
          ((!a.keepTrailingZeros && !b.keepTrailingZeros) || a.keepTrailingZeros === b.keepTrailingZeros) &&
          ((!a.abbreviate && !b.abbreviate) || a.abbreviate === b.abbreviate)) ||
        ((a === undefined || a === null || angular.equals(a, empty)) &&
          (b === undefined || b === null || angular.equals(b, empty)))
      );
    }
  });
