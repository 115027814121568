import DataTypeFunctions from "../../metrics/dataTypeFunctions";

const aggregationKeyMapper = {
  average: "avg",
  blanks: "countBlanks",
  highest: "max",
  lowest: "min",
  standard_deviation: "stddev",
  total: "sum",
};

export default class MetricDataAdapter {
  static formatTimelineData({ fieldType, period, aggFn, data }) {
    const timelineValuesFn = aggFn === "percent-of" ? getPercentOfTimelineValues : getTimelineValues;
    return {
      values: timelineValuesFn({ period, aggFn, data, fieldType }),
      range: getTimelineRange(data),
      valueType: DataTypeFunctions.getFunctionDataType(aggFn, fieldType),
    };
  }
}

function getTimelineRange(data) {
  if (!data.range.length) return {};
  return {
    start: data.range[0].timeField.min,
    end: data.range[0].timeField.max,
  };
}

function getTimelineValues({ period, aggFn, data, fieldType }) {
  const aggKey = aggregationKeyMapper[aggFn] || aggFn;
  return data.timeline.map(valueEntry => {
    const measureValue = isTemporalTypeValue(fieldType, aggFn)
      ? valueEntry.measureField[aggKey]
      : parseFloat(valueEntry.measureField[aggKey] || 0);
    return {
      value: measureValue,
      date: valueEntry[period],
    };
  });
}

function isTemporalTypeValue(fieldType, aggFn) {
  const temporalFieldTypes = ["date", "time", "datetime"];
  const temporalFn = ["highest", "lowest", "average"];
  return temporalFieldTypes.includes(fieldType) && temporalFn.includes(aggFn);
}

function getPercentOfTimelineValues({ period, data }) {
  return data.totalCount.map(valueEntry => {
    const percentOfValueEntry = data.percentOfCount.find(
      percentOfCount => percentOfCount[period] === valueEntry[period]
    );
    const percentOfValue =
      percentOfValueEntry && percentOfValueEntry.measureField.count
        ? percentOfValueEntry.measureField.count / valueEntry.measureField.count
        : 0;
    return { value: percentOfValue, date: valueEntry[period] };
  });
}
