angular.module("acl.visualizer.summaryTable").factory("SummaryTableService", function(AppConfig, Localize, Sorter) {
  function sortFields(chartConfigColumnDefs) {
    return {
      chartRows: Sorter.sort(chartConfigColumnDefs.chartRows, { valueParser: item => item.displayName }),
      chartColumns: Sorter.sort(chartConfigColumnDefs.chartColumns, { valueParser: item => item.displayName }),
      chartValues: Sorter.sort(chartConfigColumnDefs.chartValues, { valueParser: item => item.displayName }),
    };
  }

  return {
    defaultDisplayConfig: function() {
      return {
        showTotals: true,
      };
    },

    getChartDirectiveConfig: function(interpretationId, chartConfig) {
      return chartConfig;
    },

    populateChartConfigColumnDefs: function(fields) {
      var chartConfigColumnDefs = this.chartConfigColumnDefs();

      if (fields) {
        for (var fieldName in fields) {
          var tempColumnDef = {};
          tempColumnDef.displayName = fields[fieldName].displayName;
          tempColumnDef.fieldId = fields[fieldName].colId;
          tempColumnDef.fieldName = fieldName;
          tempColumnDef.type = fields[fieldName].type;
          tempColumnDef.class = "";

          switch (tempColumnDef.type) {
            case "character":
              tempColumnDef.class = "acl-i-character";
              break;
            case "logical":
              if (AppConfig.features.logicalFieldsInCharts || tempColumnDef.type !== "logical") {
                tempColumnDef.class = "acl-i-boolean";
              }
              break;
            case "date":
              tempColumnDef.class = "acl-i-calendar";
              break;
            case "datetime":
              tempColumnDef.class = "acl-i-time-and-date";
              break;
            case "time":
              tempColumnDef.class = "acl-i-clock";
              break;
            case "numeric":
              tempColumnDef.class = "acl-i-number-sign";
              break;
          }

          switch (tempColumnDef.type) {
            case "character":
            case "logical":
            case "date":
            case "datetime":
            case "time":
              if (AppConfig.features.logicalFieldsInCharts || tempColumnDef.type !== "logical") {
                chartConfigColumnDefs.chartColumns.push(tempColumnDef);
                chartConfigColumnDefs.chartRows.push(tempColumnDef);
              }
              break;
            case "numeric":
              chartConfigColumnDefs.chartColumns.push(tempColumnDef);
              chartConfigColumnDefs.chartRows.push(tempColumnDef);
              chartConfigColumnDefs.chartValues.push(tempColumnDef);
              break;
          }
        }
      }

      // Add count option to the values list
      if (angular.isArray(chartConfigColumnDefs.chartValues)) {
        chartConfigColumnDefs.chartValues.unshift({
          displayName: Localize.getLocalizedString("_SummaryTable.Count.Label_"),
          fieldName: "Count",
          type: "count",
        });
      }

      return sortFields(chartConfigColumnDefs);
    },

    chartConfigColumnDefs: function() {
      return {
        chartRows: [],
        chartColumns: [],
        chartValues: [],
      };
    },

    isValidDataConfig: function(dataConfig) {
      var rowContainsField =
        dataConfig.chartRows &&
        dataConfig.chartRows.length &&
        dataConfig.chartRows[0] &&
        dataConfig.chartRows[0].fieldName;
      var columnContainsField =
        dataConfig.chartColumns &&
        dataConfig.chartColumns.length &&
        dataConfig.chartColumns[0] &&
        dataConfig.chartColumns[0].fieldName;
      return dataConfig && (rowContainsField || columnContainsField);
    },
  };
});
