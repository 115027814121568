import uniq from "lodash/uniq";
import isNumber from "lodash/isNumber";

import { HighChartDirective, HighChartController } from "../highChartDirective";
import { COMBINATION_CHART } from "../boost/boostThresholds";

class CombinationChartDirective extends HighChartDirective {
  controller = Controller;

  static factory = () => new CombinationChartDirective();
}

export class Controller extends HighChartController {
  static configPath = {
    labelFontSize: [
      "legend.itemStyle.fontSize",
      "plotOptions.series.dataLabels.style.fontSize",
      "tooltip.style.fontSize",
      "xAxis.labels.style.fontSize",
      "xAxis.title.style.fontSize",
      "yAxis.labels.style.fontSize",
      "yAxis.title.style.fontSize",
    ],
  };

  static STACK_OPTIONS = {
    stack: "normal",
    expand: "percent",
    group: undefined,
  };

  onLegendItemClick = item => {
    const showAll = !this.hcConfig.series.some(entry => entry.visible && entry.name !== item.name);
    const series = this.hcConfig.series.map(entry => ({
      ...entry,
      visible: showAll || (entry.name === item.name ? !item.visible : entry.visible),
    }));
    this.updateHcConfig({ series });
  };

  onLegendItemDoubleClick = item => {
    const series = this.hcConfig.series.map(entry => ({ ...entry, visible: entry.name === item.name }));
    this.updateHcConfig({ series });
  };

  zoomIn = keyArray => {
    if (angular.isFunction(this.$scope.zoomInHandler)) {
      this.$scope.zoomInHandler(keyArray);
    }
  };

  getChartStyle = (chartStyle, type) => {
    if (chartStyle === "expand") {
      return type === "column" ? Controller.STACK_OPTIONS[chartStyle] : "undefined";
    }
    return Controller.STACK_OPTIONS[chartStyle];
  };

  constructor($scope, $timeout, HighChartLabelSizer, CombinationChart, Localize, AppConfig) {
    "ngInject";
    super($scope, $timeout, HighChartLabelSizer);

    this.CombinationChart = CombinationChart;
    this.Localize = Localize;
    this.AppConfig = AppConfig;
  }

  getConfig = (config, rawData) => {
    const chartData = rawData;
    let data = this.CombinationChart.reorderValues(chartData, config);
    if (Controller.STACK_OPTIONS[config.chartStyle]) {
      data = data.reverse();
    }
    let categories = uniq(data[0].values.map(d => d.key));
    let hcConfig = {
      legend: {
        enabled: config.showLegend && config.hasMultipleSeries,
        align: "right",
        verticalAlign: "top",
        reversed: !!Controller.STACK_OPTIONS[config.chartStyle],
        labelFormatter: function() {
          return this.options.legendDisplayName;
        },
      },
      boost: this.getBoostConfig(config),
      plotOptions: {
        series: {
          dataLabels: {
            enabled: config.displayDataLabels,
            formatter: function() {
              return this.series.type === "column"
                ? config.yAxis[0].tickFormatter(this.y)
                : config.yAxis[1].tickFormatter(this.y);
            },
          },
          marker: {
            enabled: config.displayDataLabels,
          },
          tooltip: {
            headerFormat: "",
            pointFormatter: function() {
              let header = config.hasMultipleSeries
                ? `
                  <span style="text-decoration: underline grey;">${this.series.options.legendDisplayName}</span>
                  <br/>
                `
                : "";
              let body = `
                <span>${config.xAxis.tickFormatter(this.xValue)}</span>
                <br/>
                <b>${
                  this.series.type === "column"
                    ? config.yAxis[0].tickFormatter(this.y)
                    : config.yAxis[1].tickFormatter(this.y)
                }</b>
              `;
              return header + body;
            },
          },
          point: {
            events: {
              click: this.clickWrapper(null, point => {
                this.zoomIn(config.hasMultipleSeries ? [point.series.name, point.xValue] : [point.xValue]);
              }),
            },
          },
          events: {
            legendItemClick: this.clickWrapper(this.onLegendItemClick, this.onLegendItemDoubleClick),
          },
          turboThreshold: 0,
          animation: this.canAnimate(),
        },
      },
      xAxis: {
        categories: categories,
        labels: {
          formatter: function() {
            return config.xAxis.tickFormatter(this.value);
          },
        },
        title: {
          text: config.xAxis.label,
        },
      },
      yAxis: [
        {
          title: {
            text: config.yAxis[0].label,
          },
          labels: {
            formatter: function() {
              return config.chartStyle === "expand" ? `${this.value}%` : config.yAxis[0].tickFormatter(this.value);
            },
          },
          min: isNumber(config.yAxis[0].minimum) ? config.yAxis[0].minimum : undefined,
          max: isNumber(config.yAxis[0].maximum) ? config.yAxis[0].maximum : undefined,
          startOnTick: !isNumber(config.yAxis[0].minimum),
          endOnTick: !isNumber(config.yAxis[0].maximum),
        },
        {
          title: {
            text: config.yAxis[1].label,
          },
          labels: {
            formatter: function() {
              return config.yAxis[1].tickFormatter(this.value);
            },
          },
          min: isNumber(config.yAxis[1].minimum) ? config.yAxis[1].minimum : undefined,
          max: isNumber(config.yAxis[1].maximum) ? config.yAxis[1].maximum : undefined,
          startOnTick: !isNumber(config.yAxis[1].minimum),
          endOnTick: !isNumber(config.yAxis[1].maximum),
          gridLineDashStyle: "DashDot",
          opposite: true,
          visible: config.showDualYAxis,
        },
      ],
      series: data.map((series, index) => {
        let { type } = series;
        let yAxis = 0;
        if (type === "line") {
          type = config.smoothEdged ? "spline" : "line";
        }
        if (config.showDualYAxis) {
          if (series.type === "column") {
            yAxis = 0;
          } else {
            yAxis = 1;
          }
        }
        // Adding legendDisplayName property to handle (blank) value  & to localize the string (issue GRCPRD-4135)
        let legendDisplayName =
          series.legendDisplayName === "(blank)"
            ? this.Localize.getLocalizedString("_Filter.BlankValue.Label_")
            : series.legendDisplayName;
        if (type === "column") {
          legendDisplayName =
            config.labelFormatter && series.key !== "(blank)"
              ? config.labelFormatter(legendDisplayName)
              : legendDisplayName;
        }
        return {
          type: type,
          name: series.key,
          legendDisplayName: legendDisplayName,
          yAxis: yAxis,
          color:
            !this.getBoostConfig(config, categories.length >= COMBINATION_CHART).enabled &&
            this.$scope.isPatternFill &&
            type !== "line"
              ? this.patternFillPalettes(index)
              : series.color,
          boostThreshold: COMBINATION_CHART,
          stacking: this.getChartStyle(config.chartStyle, type),
          zIndex: type === "column" ? 0 : 1, // adding zIndex to make line chart appear always on the top
          visible: series.visible !== false,
          data: series.values.map(value => ({
            x: categories.indexOf(value.key),
            y: value.values,
            xValue: value.key,
          })),
        };
      }),
    };
    hcConfig = this.updateLabelFontSize(hcConfig, Controller.configPath.labelFontSize);
    return hcConfig;
  };
}

export default CombinationChartDirective;
