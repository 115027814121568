import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import filterTypes from "@viz-ui/models/storyboardFilter/storyboardFilterTypes";
import i18n from "@viz-ui/i18n/i18n";
import UsageTracker from "@viz-ui/services/tracking/usageTracker";

import "./filterTypeSection.scss";

FilterTypeSection.propTypes = {
  className: PropTypes.string,
  flipConditional: PropTypes.bool.isRequired,
  flipMultiselect: PropTypes.bool.isRequired,
  isConditionalDisabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.string,
};

FilterTypeSection.defaultProps = {
  className: null,
  name: null,
  isConditionalDisabled: false,
  onChange: () => {},
  title: null,
  value: null,
};

function FilterTypeSection({
  className,
  flipConditional,
  flipMultiselect,
  isConditionalDisabled,
  name,
  onChange,
  title,
  value,
}) {
  const classes = classNames("filter-section__type", className);

  const options = [
    { id: filterTypes.MULTISELECT },
    { id: filterTypes.CONDITIONAL },
    { id: filterTypes.OPTIONAL_SINGLESELECT },
    { id: filterTypes.MANDATORY_SINGLESELECT },
  ];

  const optionIndex = options.findIndex(option => option.id === value);

  if (optionIndex === -1 || (isConditionalDisabled && value === filterTypes.CONDITIONAL)) {
    onChange(flipMultiselect ? filterTypes.MULTISELECT : filterTypes.OPTIONAL_SINGLESELECT);
  }

  const onChangeHandler = e => {
    onChange(e.target.value);
    UsageTracker.createEvent(".filterpanel.configpanel.typechanged", { type: e.target.value });
  };

  const selectOptions = options.map(option => {
    if (!flipConditional && option.id === filterTypes.CONDITIONAL) return null;
    if (!flipMultiselect && option.id === filterTypes.MULTISELECT) return null;
    const isDisabled = isConditionalDisabled && option.id === filterTypes.CONDITIONAL;

    return (
      <li key={"select-option-" + option.id} className={isDisabled ? "filter-type__item--is-disabled" : ""}>
        <input
          checked={option.id === value}
          disabled={isDisabled}
          id={option.id}
          name={name}
          onChange={onChangeHandler}
          type="radio"
          value={option.id}
        />
        <label htmlFor={option.id}>
          <div className="filter-type__icon" />
          <span className="filter-type__item-label">
            {i18n.t(`_GlobalFilter.${option.id.charAt(0).toUpperCase() + option.id.slice(1)}.Label_`)}
          </span>
        </label>
      </li>
    );
  });

  return (
    <div className={classes}>
      <label htmlFor="filter-type__options">{title}</label>
      <ul className="filter-type__options" id="filter-type__options">
        {selectOptions}
      </ul>
    </div>
  );
}

export default FilterTypeSection;
