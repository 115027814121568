import ApiPath from "@viz-ui/services/apiPath/apiPathService";

angular.module("acl.visualizer.shareToGRC").factory("shareToGRC", function($http, AppConfig, DataModel) {
  "use strict";
  //TODO move to AppConfig
  var accountsUrl = (AppConfig.share_grc && AppConfig.share_grc.accountsUrl) || global_grcUrl;
  var accessToken = (AppConfig.share_grc && AppConfig.share_grc.accessToken) || global_grcAccessToken;
  var apisUrl;
  var regionCode;

  var shareToGRC = {
    getOrgs: function() {
      var url = accountsUrl + "/api/user/orgs";
      return $http({
        method: "GET",
        url: url,
        headers: { Authorization: "Bearer " + accessToken, Accept: "application/vnd.acl.v1+json " },
      });
    },
    getAccounts: function(region) {
      apisUrl = region.resultsUrl;
      regionCode = region.code;
      return $http({
        method: "GET",
        url: apisUrl + "/accounts",
        headers: { Authorization: "Bearer " + accessToken, Accept: "application/vnd.acl.v1+json " },
      });
    },

    getProjects: function(accountId) {
      var GRCApiUrl = encodeURI(
        apisUrl + "/accounts/" + accountId + "/projects?role[]=auditor&role[]=audit_manager&role[]=admin"
      );
      return $http({
        method: "GET",
        url: GRCApiUrl,
        headers: { Authorization: "Bearer " + accessToken, Accept: "application/vnd.acl.v1+json " },
      });
    },

    getControls: function(projectId) {
      var GRCApiUrl = apisUrl + "/projects/" + projectId + "/controls";
      return $http({
        method: "GET",
        url: GRCApiUrl,
        headers: { Authorization: "Bearer " + accessToken, Accept: "application/vnd.acl.v1+json " },
      });
    },

    getControlTests: function(controlId) {
      var GRCApiUrl = apisUrl + "/controls/" + controlId + "/control_tests";
      return $http({
        method: "GET",
        url: GRCApiUrl,
        headers: { Authorization: "Bearer " + accessToken, Accept: "application/vnd.acl.v1+json " },
      });
    },

    getInterpretation: function(controlTestId, interpretationId) {
      var GRCApiUrl = apisUrl + "/control_tests/" + controlTestId + "/interpretations/" + interpretationId;
      return $http({
        method: "GET",
        url: GRCApiUrl,
        headers: {
          Authorization: "Bearer " + accessToken,
          Accept: "application/vnd.acl.v1+json",
          "Content-Type": "application/json",
        },
      });
    },

    shareInterpretation: function(controlTest, savedViz) {
      var GRCApiUrl = apisUrl + "/control_tests/" + controlTest.id + "/interpretations.json";
      return $http({
        method: "POST",
        url: GRCApiUrl,
        headers: {
          Authorization: "Bearer " + accessToken,
          Accept: "application/vnd.acl.v1+json",
          "X-ACL-User-Agent": "ANR/1.0",
        },
        data: savedViz,
      });
    },

    shareMetadata: function(controlTest, metadata) {
      var GRCApiUrl = apisUrl + "/control_tests/" + controlTest.id + "/exception_attributes";
      return $http({
        method: "POST",
        url: GRCApiUrl,
        headers: {
          Authorization: "Bearer " + accessToken,
          Accept: "application/vnd.acl.v1+json",
          "X-ACL-User-Agent": "ANR/1.0",
        },
        data: metadata,
      });
    },

    uploadData: function(savedViz, controlTest, jobId) {
      var ApiUrl;
      if (AppConfig.baseUrl.api === "nodejs") {
        ApiUrl = apisUrl + "/control_tests/" + controlTest.id + "/exception_uploads";
        return $http({
          method: "POST",
          url: AppConfig.baseUrl.api + "/exception_uploads",
          params: { api: ApiUrl, accessToken: accessToken },
        });
      } else {
        ApiUrl = ApiPath.table.getShareDataToGrcUrl(DataModel.table.id());
        var regionId;
        if (regionCode !== "US") {
          regionId = "@" + regionCode.toLowerCase();
        } else {
          regionId = "";
        }
        return $http({
          method: "POST",
          url: ApiUrl,
          params: { testId: controlTest.id + regionId, accessToken: accessToken, jobId: jobId },
        });
      }
    },

    updateInterpretation: function(controlTestId, interpretationId, savedViz) {
      var GRCApiUrl = apisUrl + "/control_tests/" + controlTestId + "/interpretations/" + interpretationId;
      return $http({
        method: "PUT",
        url: GRCApiUrl,
        headers: {
          Authorization: "Bearer " + accessToken,
          Accept: "application/vnd.acl.v1+json",
          "X-ACL-User-Agent": "ANR/1.0",
        },
        data: savedViz,
      });
    },
  };

  return shareToGRC;
});
