(() => {
  class RenameMetricPrompter {
    constructor($modal) {
      this.$modal = $modal;
    }

    openModal(metricNameArg, { displayDuplicateError = false, mustRename = true, duplicateMode = false }) {
      var modalInstance;
      var ModalController = function($scope, $modalInstance, metricName) {
        $scope.model = {
          oldMetricName: metricName,
          newMetricName: metricName,
          metricNameExists: displayDuplicateError,
          mustRename: mustRename,
          duplicateMode: duplicateMode,
        };

        $scope.isSaveButtonEnabled = function() {
          var model = $scope.model;
          if (duplicateMode) {
            mustRename = true;
          }
          return (
            model.newMetricName &&
            model.newMetricName.length > 0 &&
            (!mustRename || model.oldMetricName !== model.newMetricName)
          );
        };

        $scope.cancel = function() {
          $modalInstance.dismiss("cancel");
        };

        $scope.rename = function() {
          $modalInstance.close($scope.model);
        };
      };
      ModalController.$inject = ["$scope", "$modalInstance", "metricName"];

      modalInstance = this.$modal.open({
        templateUrl: "visualizer/js/modules/core/renameMetricPrompt/renameMetricPrompter.tpl.html",
        controller: ModalController,
        windowClass: "rename_metric_dialog",
        resolve: {
          metricName: function() {
            return metricNameArg;
          },
        },
      });

      return modalInstance.result;
    }
  }

  angular.module("acl.metrics.renameMetricPrompt").service("RenameMetricPrompter", RenameMetricPrompter);
})();
