/**
 * This module exposes angular dependency injection into non-angular code, to be used in es6 module environments.
 */
let services = {};

angular
  .module("highbond.visualizer.glue", ["acl.visualizer.dataModel", "acl.common.config", "acl.common.localize"])
  .run(function(DataModel, AppConfig, Localize) {
    services.Localize = Localize;
    services.AppConfig = AppConfig;
    services.DataModel = DataModel;
    services.window = window;
  });

module.exports = services;
