angular.module("acl.common.eventLogger", ["acl.common.event"]).run(function(EventService, AppConfig) {
  if (AppConfig.logEvents) {
    return EventService.registerListener({
      onReceive: function(receiver, event, data) {
        return console.log("receive -> " + receiver, event, data);
      },
      onPublish: function(publisher, event, data) {
        return console.log("publish -> " + publisher, event, data);
      },
      onRequest: function(requester, event, data) {
        return console.log("request -> " + requester, event, data);
      },
      onRequestTimeout: function(requester, event, data) {
        return console.log("request timeout -> " + requester, event, data);
      },
    });
  }
});
