function getScrollbarOffset() {
  var $outer = $("<div>")
    .css({ visibility: "hidden", width: 100, overflow: "scroll" })
    .appendTo("body");
  var widthWithScroll = $("<div>")
    .css({ width: "100%" })
    .appendTo($outer)
    .outerWidth();
  $outer.remove();
  return 100 - widthWithScroll;
}

export default function getContainerDimensions(containerSize, contentSize) {
  var scrollbarSize = getScrollbarOffset();
  var scrollbarOffsets = { x: 0, y: 0 };
  var cssVal = {};

  if (containerSize.y < contentSize.y) scrollbarOffsets.x = scrollbarSize;
  if (containerSize.x < contentSize.x) scrollbarOffsets.y = scrollbarSize;

  cssVal.width =
    containerSize.x < contentSize.x + scrollbarOffsets.x
      ? containerSize.x - 2 // 2px for storyboard's border
      : contentSize.x + scrollbarSize;
  cssVal.height =
    containerSize.y < contentSize.y + scrollbarOffsets.y ? containerSize.y : contentSize.y + scrollbarSize + 1; // 1px for handsontable's quirkiness
  return cssVal;
}
