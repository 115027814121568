import { reactAngularModule } from "react-angular";
import User from "@storyboards/modules/services/user.service";
import { UsageTrackerHelper } from "@visualizer/common/services/usageTracker/usageTracker";
import AppConfig from "@storyboards/appConfig";
import ApiPath from "@viz-ui/services/apiPath/apiPathService";
ApiPath.initialize(AppConfig);

angular
  .module("acl.storyboard", [
    "acl.visualizer.charts",
    "acl.visualizer.dataFilter",
    "acl.visualizer.heatmap",
    "acl.common.userAgent",
    "acl.common.config",
    "acl.common.resizeListener",
    "acl.storyboard.storyboard",
    "acl.storyboard.dataLoader",
    "acl.storyboard.presenter",
    "acl.storyboard.interpretation",
    "acl.storyboard.visualization",
    "acl.visualizer.charts.backend",
    "acl.visualizer.dataTable.backend",
    "acl.visualizer.formatters",
    "acl.visualizer.model.interpretation",
    "acl.visualizer.model.metric",
    "acl.visualizer.model.table",
    "acl.visualizer.metric.backend",
    "acl.visualizer.accessControl.backend",
    "acl.visualizer.treemap",
    "acl.visualizer.combinationChart",
    "acl.visualizer.mapChart",
    "highbond.storyboard.glue",
    "react",
    "ui.sortable",
    reactAngularModule(true).name,
  ])
  .run(() => {
    UsageTrackerHelper.setupStoryboardEventListener(User.role());
    vizHtmlPrefilter(AppConfig.features.notRestoreJQueryLegacyHtmlPrefilter, AppConfig.environment);
  })
  .config(function(tmhDynamicLocaleProvider) {
    // Set up Angular-dynamic-locale by telling it where to find the AngularJS locale files
    tmhDynamicLocaleProvider.localeLocationPattern(AppConfig.angularLocaleLocation);
  })
  .config($locationProvider => {
    //TODO: Remove these Angular 1.6 reverts
    $locationProvider.hashPrefix("");
  })
  .value("AclNotification", window.AclNotification);
