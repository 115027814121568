class QueryErrorInterpreter {
  errorCode(response) {
    switch (response.status) {
      case 400:
        return getErrorCode(response);
    }
  }
}

function getErrorCode(response) {
  const errorJson = response.responseJSON.errors;
  if (errorJson.length > 0) {
    return errorJson[0].code;
  }
}

export default new QueryErrorInterpreter();
