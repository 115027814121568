(() => {
  class UnsavedChangesConfirmer {
    constructor($modal) {
      this.$modal = $modal;
    }

    openModal(metricNew, name) {
      var modalInstance;
      var ModalController = function($scope, $modalInstance) {
        $scope.model = {
          newName: name,
        };
        $scope.isNew = metricNew;
        $scope.cancel = function() {
          $modalInstance.dismiss("cancel");
        };
        $scope.save = function() {
          $modalInstance.close({ model: $scope.model, save: true });
        };
        $scope.skipSave = function() {
          $modalInstance.close({ skip: true });
        };
      };
      ModalController.$inject = ["$scope", "$modalInstance"];

      modalInstance = this.$modal.open({
        templateUrl: "visualizer/js/modules/core/metricApp/unsavedChangesConfirmer.tpl.html",
        controller: ModalController,
        windowClass: "metric-app__dialog--unsaved-metric",
      });

      return modalInstance.result;
    }
  }

  angular.module("acl.visualizer.metricApp").service("UnsavedChangesConfirmer", UnsavedChangesConfirmer);
})();
