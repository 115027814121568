import AggregatorArgs from "./aggregatorArgs";
import ConditionalFormat from "../conditionalFormatting/conditionalFormat";
import Model from "../model";
import FieldFormat from "../field/fieldFormat";
import FilterConfig from "../filter/filterConfig";
import MetricConfig from "./metricConfig";
import Threshold from "../filter/threshold";

export default class Metric extends Model {
  static modelType = "Metric";

  constructor(data) {
    super({
      metricConfig: new MetricConfig(),
      conditionalFormatsMap: new Map(),
      fieldFormatMap: new Map(),
      filterConfig: new FilterConfig(),
      ...data,
    });
  }

  id() {
    return this._data.id;
  }

  name(...args) {
    return this.accessor("name", args, "string");
  }

  metricConfig(...args) {
    return this.accessor("metricConfig", args, MetricConfig);
  }

  // An object where keys are field names and elements are arrays of ConditionalFormat models.
  conditionalFormatsMap(...args) {
    return this.accessor("conditionalFormatsMap", args, Map);
  }

  conditionalFormatsByFieldName(fieldName, x) {
    const formatsMap = this._data.conditionalFormatsMap;
    let formats = formatsMap.get(fieldName);
    if (!formats) {
      formats = [];
      formatsMap.set(fieldName, formats);
    }

    if (arguments.length < 2) {
      return formats.map(format => format.clone());
    }

    formatsMap.set(
      fieldName,
      x.map(format => format.clone())
    );

    return this;
  }

  filterConfig(...args) {
    return this.accessor("filterConfig", args, FilterConfig);
  }

  fieldFormatMap(...args) {
    return this.accessor("fieldFormatMap", args, Map);
  }

  isProjectArchived(...args) {
    return this.accessor("isProjectArchived", args, "boolean");
  }

  fieldFormatByFieldName(fieldName, x) {
    const formatMap = this._data.fieldFormatMap;
    if (arguments.length < 2) {
      if (formatMap.get(fieldName)) {
        return formatMap.get(fieldName).clone();
      }
      return null;
    }

    formatMap.set(fieldName, x.clone());
    return this;
  }

  error(...args) {
    return this.accessor("error", args, "string");
  }

  isNew() {
    return typeof this._data.id === "undefined";
  }

  clone() {
    return new Metric(this._data);
  }

  cloneExceptId() {
    const newMetric = new Metric(this._data);
    newMetric._data.id = undefined;
    return newMetric;
  }

  static fromJson(json) {
    const filterConfig = json.filter_config;
    return new Metric({
      id: typeof json.id === "undefined" ? json.id : Number(json.id),
    })
      .name(json.name)
      .isProjectArchived(json.project && json.project.archived)
      .metricConfig(deserializeMetricConfig(json))
      .filterConfig(filterConfig ? FilterConfig.fromJson(filterConfig) : new FilterConfig())
      .conditionalFormatsMap(metricDataToConditionalFormatsMap(json))
      .fieldFormatMap(metricDataToFieldFormatMap(json))
      .error(json.error && json.error.code);
  }

  toJson() {
    const metricConfig = this.metricConfig();
    const aggregatorArgs = metricConfig.aggregatorArgs();
    return this._deleteUndefinedProperties({
      id: this.id(),
      name: this.name(),
      func: backendMetricFunctionMap(metricConfig.func()),
      func_argument: aggregatorArgs ? aggregatorArgs.toJson() : null,
      interval: metricConfig.interval(),
      field_name: metricConfig.fieldName(),
      time_field_name: metricConfig.timeFieldName(),
      filter_config: this.filterConfig().toJson(),
      base_color: metricConfig.baseColor(),
      thresholds: metricConfigToThresholds(metricConfig),
      config: metricToConfig(this),
      metric_type: metricConfig.type(),
      view_thresholds: metricConfig.viewThresholds(),
      show_sparkline: metricConfig.showSparkline(),
    });
  }
}

function deserializeMetricConfig(metricData) {
  const funcArgument = metricData.func_argument;
  return new MetricConfig()
    .func(metricData.func)
    .aggregatorArgs(funcArgument ? AggregatorArgs.fromJson(funcArgument) : null)
    .interval(metricData.interval)
    .fieldName(metricData.field_name)
    .timeFieldName(metricData.time_field_name)
    .baseColor(metricData.base_color)
    .thresholds(thresholdsToThresholdModels(metricData.thresholds))
    .type(metricData.metric_type)
    .viewThresholds(metricData.view_thresholds)
    .showSparkline(metricData.show_sparkline);
}

function thresholdsToThresholdModels(thresholds) {
  if (thresholds && thresholds.length > 0) {
    return thresholds.map(Threshold.fromJson);
  }
  return undefined;
}

function metricDataToConditionalFormatsMap(metricData) {
  if (metricData.config && metricData.config.conditional_formats_map) {
    return ConditionalFormat.jsonToConditionalFormatMap(metricData.config.conditional_formats_map);
  }
  return new Map();
}

function metricDataToFieldFormatMap(metricData) {
  if (metricData.config && metricData.config.field_format_map) {
    return deserializeFieldFormatMap(metricData.config.field_format_map);
  }
  return new Map();
}

function deserializeFieldFormatMap(fieldFormatMapData) {
  const result = new Map();
  Object.keys(fieldFormatMapData).forEach(fieldName => {
    result.set(fieldName, FieldFormat.fromJson(fieldFormatMapData[fieldName]));
  });
  return result;
}

function backendMetricFunctionMap(functionName) {
  const METRIC_FUNCTION_MAP = {
    standardDeviation: "standard_deviation",
  };
  const backendFunctionName = METRIC_FUNCTION_MAP[functionName];
  return backendFunctionName || functionName;
}

function metricToConfig(metric) {
  const fieldFormatMap = metric.fieldFormatMap();
  const conditionalFormatsMap = metric.conditionalFormatsMap();
  if (fieldFormatMap || conditionalFormatsMap) {
    return {
      field_format_map: fieldFormatMap ? serializeFieldFormatMap(fieldFormatMap) : undefined,
      conditional_formats_map: conditionalFormatsMap
        ? ConditionalFormat.conditionalFormatMapToJson(conditionalFormatsMap)
        : undefined,
    };
  }
  return undefined;
}

function serializeFieldFormatMap(fieldFormatMap) {
  const result = {};
  fieldFormatMap.forEach((fieldFormat, fieldName) => {
    result[fieldName] = fieldFormat.toJson();
  });
  return result;
}

function metricConfigToThresholds(metricConfig) {
  const thresholds = metricConfig.thresholds();
  if (thresholds) {
    return thresholds.map(threshold => threshold.toJson());
  }

  return undefined;
}
