import backendApi from "@results/services/apiCall/backendApi";
import User from "@storyboards/modules/services/user.service";
import Glue from "@storyboards/services/glue/appGlue";
import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";
import MultiTableValueStore from "@viz-ui/services/query/multiTableValueStore/multiTableValueStore";

class ValuesRequester {
  constructor(useQueryService, useDynamicFilters, forceRerender) {
    this.useQueryService = useQueryService;
    this.useDynamicFilters = useDynamicFilters;
    this.forceRerender = forceRerender;
  }

  static getInstance(useQueryService, useDynamicFilters, forceRerender) {
    return new ValuesRequester(useQueryService, useDynamicFilters, forceRerender);
  }

  getCacheKey(data) {
    return JSON.stringify({
      id: data.filterModel.id(),
      fields: data.filterModel.filterFields().map(d => d.name()),
      search: data.filterString,
    });
  }

  loadValues(data, position = 0) {
    if (this.useQueryService) {
      return MultiTableValueStore.loadValues(
        data.filterModel,
        data.filterString,
        this.useDynamicFilters,
        this.forceRerender
      );
    }

    const requestUrl = getUrl(data);
    const requestData = getRequestData(data, position);
    return backendApi.post(requestUrl, {}, requestData).then(deserializeResponse);
  }
}

function deserializeResponse(response) {
  return {
    values: response.data.result.data,
    count: response.data.result.count,
  };
}

function getRequestData(data, currentPosition) {
  const { filterModel } = data;
  const columns = filterModel.filterFields().map(field => ({ name: field.name(), control_test_id: field.tableId() }));
  const filterId = filterModel.id();

  const requestData = {
    columns: columns,
    position: currentPosition,
    storyboard_id: getStoryboardId(),
    filter_id: filterId,
  };

  if (data.filterString) {
    requestData.search_text = data.filterString;
    if (filterModel.fieldType() === "datetime") {
      const timezoneOffset = GlobalValueFormatter.getTimezoneOffset();
      requestData.timezone_offset = timezoneOffset.utcOffsetString();
    }
  }
  return requestData;
}

function getStoryboardId() {
  return User.isAnonymous() ? Glue.AppConfig.storyboard.getUid() : Glue.AppConfig.storyboard.getId();
}

function getUrl(data) {
  return data.filterString ? getSearchUrl() : getValuesUrl();
}

function getValuesUrl() {
  return User.isAnonymous()
    ? Glue.AppConfig.coneService.publicStoryboardLinkGlobalFilterValuesPath({
        public_storyboard_link_id: Glue.AppConfig.storyboard.getUid(),
      })
    : Glue.AppConfig.coneService.globalFilterValueStoryboardsPath();
}

function getSearchUrl() {
  return User.isAnonymous()
    ? Glue.AppConfig.coneService.publicStoryboardLinkGlobalFilterSearchPath({
        public_storyboard_link_id: Glue.AppConfig.storyboard.getUid(),
      })
    : Glue.AppConfig.coneService.globalFilterSearchStoryboardsPath();
}

export default ValuesRequester;
