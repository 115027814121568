import FilterField from "./filterField";

function serializeFilterField(filterFieldModel) {
  return {
    name: filterFieldModel.name(),
    table_id: filterFieldModel.tableId(),
  };
}

function serializeFilterFieldMap(filterFieldModelArray) {
  return filterFieldModelArray.map(filterFieldModel => serializeFilterField(filterFieldModel));
}

function deserializeFilterField(filterFieldObject) {
  return new FilterField().name(filterFieldObject.name).tableId(filterFieldObject.table_id);
}

function deserializeFilterFieldMap(filterFieldObjectArray) {
  return filterFieldObjectArray.map(filterFieldObject => deserializeFilterField(filterFieldObject));
}

export default {
  serializeFilterField,
  serializeFilterFieldMap,
  deserializeFilterField,
  deserializeFilterFieldMap,
};
