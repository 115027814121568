import i18n from "@viz-ui/i18n/i18n";
import ChartService from "../chartService";
import { reorderValues } from "../../../components/charts/services/highChartDataSorter";

class BarChartService extends ChartService {
  /**TODO: Need to remove following parameters in order to make this efficient
   * !Need to find alternative to $filter (angular) which is right now used as filter parameter.
   */
  defaultDisplayConfig = () => ({
    horizontal: false,
    showLegend: true,
    displayDataLabels: true,
    showControls: true,
    enableBoost: true,
    showPercentageTicks: false,
    xAxis: {
      showAxis: true,
      showLabel: true,
    },
    yAxis: {
      showAxis: true,
      showLabel: true,
      minimum: null,
      maximum: null,
    },
    chartStyle: "group",
  });

  getChartDirectiveConfig = (interpretationId, chartConfig) => {
    const getColorByField = () =>
      (chartConfig &&
        chartConfig.dataConfig &&
        chartConfig.dataConfig.chartSeries &&
        chartConfig.dataConfig.chartSeries.fieldName) ||
      "";
    const categoryLabelTemplate = i18n.t("_Chart.SingleNestedAxisLabel.Template_");
    const hasMultipleSeries = !!(chartConfig.dataConfig.chartSeries && chartConfig.dataConfig.chartSeries.fieldName);

    return {
      order: chartConfig.displayConfig.order,
      valuesOrder: chartConfig.displayConfig.valuesOrder,
      horizontal: chartConfig.displayConfig.horizontal,
      hasMultipleSeries: hasMultipleSeries,
      showLegend: chartConfig.displayConfig.showLegend,
      enableBoost: chartConfig.displayConfig.enableBoost,
      displayDataLabels: chartConfig.displayConfig.displayDataLabels,
      showControls: chartConfig.displayConfig.showControls,
      showPercentageTicks: chartConfig.displayConfig.showPercentageTicks,
      xAxis: {
        label: this.getXAxisLabel(
          chartConfig.dataConfig,
          chartConfig.displayConfig,
          categoryLabelTemplate,
          hasMultipleSeries
        ),
        tickFormatter: this.getValueFormatter(
          interpretationId,
          chartConfig.dataConfig.chartXAxis.fieldName,
          chartConfig.dataConfig.chartXAxis.type
        ),
        title: chartConfig.displayConfig.xAxis.title,
        showAxis: chartConfig.displayConfig.xAxis.showAxis,
        fieldType: chartConfig.dataConfig.chartXAxis.type,
      },
      yAxis: {
        label: this.getYAxisLabel(chartConfig.dataConfig, chartConfig.displayConfig),
        tickFormatter: this.getYAxisFormatter(interpretationId, chartConfig),
        minimum: chartConfig.displayConfig.yAxis.minimum,
        maximum: chartConfig.displayConfig.yAxis.maximum,
        title: chartConfig.displayConfig.yAxis.title,
        showAxis: chartConfig.displayConfig.yAxis.showAxis,
      },
      chartSeries: chartConfig.dataConfig.chartSeries,
      DATA_ITEM_LIMIT: this.getDataItemLimit(),
      colorByField: getColorByField(),
      labelFormatter: this.getLabelFormatter(chartConfig, interpretationId),
      chartStyle: chartConfig.displayConfig.chartStyle,
    };
  };

  populateChartConfigColumnDefs = chartConfigColumnDefs => {
    let tempColumnDef;
    const fields = this.DataModel.table.fields();
    if (fields) {
      for (const fieldName in fields) {
        tempColumnDef = {};
        tempColumnDef.displayName = fields[fieldName].displayName;
        tempColumnDef.fieldName = fieldName;
        tempColumnDef.type = fields[fieldName].type;

        switch (tempColumnDef.type) {
          case "character":
          case "logical":
            if (this.AppConfig.features.logicalFieldsInCharts || tempColumnDef.type === "character") {
              chartConfigColumnDefs.chartSeries.push(tempColumnDef);
              chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
            }
            break;
          case "numeric":
            chartConfigColumnDefs.chartValue.push(tempColumnDef);
            chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
            break;
          case "date":
          case "datetime":
          case "time":
            chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
            break;
        }
      }
    }
    return chartConfigColumnDefs;
  };

  chartConfigColumnDefs = () => ({
    chartSeries: [],
    chartXAxis: [],
    chartValue: [],
  });

  isValidDataConfig = dataConfig =>
    !!(
      dataConfig &&
      dataConfig.chartXAxis &&
      dataConfig.chartValue &&
      ((dataConfig.chartValue.fieldName && dataConfig.chartValue.aggregationType) ||
        dataConfig.chartValue.aggregationType === "count")
    );

  reorderValues = (chartData, sortConfig) => reorderValues(chartData, sortConfig);

  getPercentage = (data, categories, multipleSeries) => {
    const keysTotal = [];
    if (multipleSeries) {
      categories.forEach(category => {
        let total = 0;
        data.forEach(d => {
          d.values.forEach(i => {
            if (i.key === category) {
              total += Math.abs(parseFloat(i.values === null ? 0 : i.values));
            }
          });
        });
        keysTotal.push({ key: category, total: total.toFixed(2) });
      });
      keysTotal.forEach(item => {
        data = data.map(d => ({
          ...d,
          values: d.values.map(i => {
            if (i.key === item.key) {
              let value = i.values === null ? 0 : i.values;
              value = value < 0 ? -1 * value : value;
              let percentage = item.total !== "0.00" ? (100 * value) / item.total : 0;
              percentage = percentage.toFixed(2);
              return { ...i, percentage };
            }
            return i;
          }),
        }));
      });
    } else {
      let total = 0;
      data[0].values.forEach(d => {
        total += Math.abs(parseFloat(d.values === null ? 0 : d.values));
      });
      data = data.map(d => ({
        ...d,
        values: d.values.map(i => {
          let value = i.values === null ? 0 : i.values;
          value = value < 0 ? -1 * value : value;
          let percentage = total != "0.00" ? (100 * value) / total : 0;
          percentage = percentage.toFixed(2);
          return { ...i, percentage };
        }),
      }));
    }
    return data;
  };

  getXAxisLabel = (dataConfig, displayConfig, labelTemplate, hasMultipleSeries) => {
    if (displayConfig.xAxis.showLabel) {
      if (displayConfig.xAxis.title) {
        return displayConfig.xAxis.title;
      }
      if (hasMultipleSeries) {
        return labelTemplate
          .replace("$0", dataConfig.chartXAxis.displayName)
          .replace("$1", dataConfig.chartSeries.displayName);
      }
      return dataConfig.chartXAxis.displayName;
    }
    return "";
  };

  getYAxisLabel = (dataConfig, displayConfig) => {
    if (displayConfig.yAxis.showLabel) {
      if (displayConfig.yAxis.title) {
        return displayConfig.yAxis.title;
      }
      return this.getFieldLabel(dataConfig.chartValue);
    }
    return "";
  };

  getYAxisFormatter = (interpretationId, chartConfig) => {
    const { vizId, dataConfig, displayConfig } = chartConfig;
    if (displayConfig.valueFormattingOptions && dataConfig.chartValue.aggregationType === "average") {
      const chartValueFieldName = `${vizId}-chart-value`;
      const chartValueFieldType = "numeric";
      return this.getValueFormatter(interpretationId, chartValueFieldName, chartValueFieldType);
    }
    return this.getValueFormatter(interpretationId, dataConfig.chartValue.fieldName, dataConfig.chartValue.type);
  };

  onDisplayConfigChanged = (displayConfig, chartConfig) => {
    const temp = {};
    if (displayConfig.horizontal !== chartConfig.displayConfig.horizontal) {
      if (displayConfig.horizontal) {
        temp["displayConfig.xAxis.showLabel"] = displayConfig.xAxis.showLabel;
        displayConfig.xAxis.showLabel = false;
      } else if (temp["displayConfig.xAxis.showLabel"]) {
        displayConfig.xAxis.showLabel = true;
      }
    }
  };

  getFilters = (keyArray, chartConfig) => {
    const xFormatter = this.getFormatterForType(chartConfig.dataConfig.chartXAxis.type);
    const filters = [];
    if (keyArray.length > 1) {
      const seriesFormatter = this.getFormatterForType(chartConfig.dataConfig.chartSeries.type);
      filters.push({ field: chartConfig.dataConfig.chartSeries, value: seriesFormatter(keyArray[0]) });
    }
    filters.push({
      field: chartConfig.dataConfig.chartXAxis,
      value: xFormatter(keyArray[keyArray.length - 1]),
    });
    return filters;
  };
}
export default BarChartService;
