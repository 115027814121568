import Utils from "@viz-ui/services/common/utilsService";

angular
  .module("acl.visualizer.csv", ["acl.common.event", "acl.common.localize"])
  .factory("Csv", function(EventService) {
    var eventService = EventService.register("acl.visualizer.exportToCsv");

    var csv = {
      export: exportCsv,
    };

    function exportCsv() {
      return eventService
        .request("exportToCsv", null, Utils.generateFilename("csv"))
        .catch(error => Utils.handleError(error, "Csv"));
    }
    return csv;
  });
