import FilterConfig from "../filter/filterConfig";
import FieldFormatAdapter from "../field/fieldFormatAdapter";
import Interpretation from "./interpretation";
import ColumnConfigAdapter from "../tableConfig/columnConfigAdapter";
import FieldFormat from "../field/fieldFormat";
import GlobalFieldFormatMap from "@viz-ui/services/formatters/globalFieldFormatMap";

function deserializeInterpretation(interpretationData, interpretationId) {
  const { filterConfig } = interpretationData;
  return new Interpretation()
    .tableId(interpretationData.control_test_id)
    .columnConfigs(interpretationDataToColumnConfigs(interpretationData))
    .fieldFormatMap(interpretationDataToFieldFormatMap(interpretationData, interpretationId))
    .filterConfig(filterConfig ? FilterConfig.fromJson(filterConfig) : null);
}

function interpretationDataToColumnConfigs(interpretationData) {
  if (interpretationData.tableConfig && interpretationData.tableConfig.columns) {
    return interpretationData.tableConfig.columns.map(ColumnConfigAdapter.deserializeColumnConfig);
  }
  return [];
}

function interpretationDataToFieldFormatMap(interpretationData, interpretationId) {
  if (interpretationId) {
    interpretationData.visualizations.forEach(visualization => {
      const { id, config = {} } = visualization;
      if (config.displayConfig && config.displayConfig.valueFormattingOptions) {
        const fieldFormat = FieldFormat.fromJson(visualization.config.displayConfig.valueFormattingOptions);
        const valueFormatFieldName = `${id}-chart-value`;
        GlobalFieldFormatMap.setFieldFormat(interpretationId, valueFormatFieldName, fieldFormat);
        GlobalFieldFormatMap.setFieldType(interpretationId, valueFormatFieldName, "numeric");
      }
      if (config.displayConfig && config.displayConfig.valueFormattingOptionsFirstYAxis) {
        const fieldFormat = FieldFormat.fromJson(visualization.config.displayConfig.valueFormattingOptionsFirstYAxis);
        const valueFormatFieldName = `${id}-chart-value-FirstYAxis`;
        GlobalFieldFormatMap.setFieldFormat(interpretationId, valueFormatFieldName, fieldFormat);
        GlobalFieldFormatMap.setFieldType(interpretationId, valueFormatFieldName, "numeric");
      }
      if (config.displayConfig && config.displayConfig.valueFormattingOptionsSecondYAxis) {
        const fieldFormat = FieldFormat.fromJson(visualization.config.displayConfig.valueFormattingOptionsSecondYAxis);
        const valueFormatFieldName = `${id}-chart-value-SecondYAxis`;
        GlobalFieldFormatMap.setFieldFormat(interpretationId, valueFormatFieldName, fieldFormat);
        GlobalFieldFormatMap.setFieldType(interpretationId, valueFormatFieldName, "numeric");
      }
    });
  }
  if (interpretationData.tableConfig && interpretationData.tableConfig.formatting) {
    return FieldFormatAdapter.deserializeFieldFormatMap(interpretationData.tableConfig.formatting);
  }
  return new Map();
}

export default {
  deserializeInterpretation,
};
