angular
  .module("acl.visualizer.charts.controllers")
  .controller("BubbleChartController", function(
    $scope,
    $timeout,
    BubbleChart,
    ChartService,
    DataModel,
    DataDownsamplerService,
    InheritController,
    Localize
  ) {
    var base = InheritController("ChartBaseController", $scope)
      .override("chartType", "BubbleChart")
      .override("defaultDisplayConfig", BubbleChart.defaultDisplayConfig)
      .override("getChartDirectiveConfig", BubbleChart.getChartDirectiveConfig)
      .override("populateChartConfigColumnDefs", BubbleChart.populateChartConfigColumnDefs)
      .override("chartConfigColumnDefs", BubbleChart.chartConfigColumnDefs)
      .override("isValidDataConfig", BubbleChart.isValidDataConfig)
      // FIXME: Move filterResult implementation into BubbleChart service.
      .override("filterResult", function(representation) {
        //FIXME: dry this with other downsampled charts
        var cohortSize = DataDownsamplerService.reduceBubbleData(
          representation,
          ChartService.getDataItemLimit(),
          $scope.chartConfig.dataConfig.chartValue.aggregationType
        );
        if (cohortSize > 1) {
          $timeout(function() {
            $scope.setMessage({
              type: "notice",
              content: Localize.getLocalizedString("_Chart.DrawableDataLimitReached.Template_").replace(
                "$0",
                cohortSize
              ),
            });
          }, 1000);
        }
        return representation;
      })
      .override("chartZoomIn", function(data) {
        var dataConfig = $scope.chartConfig.dataConfig;

        var xFormatter = ChartService.getFormatterForType(dataConfig.chartXAxis.type);
        var yFormatter = ChartService.getFormatterForType(dataConfig.chartYAxis.type);
        var filters = [];
        if (dataConfig.chartSeries && dataConfig.chartSeries.fieldName) {
          var seriesFormatter = ChartService.getFormatterForType(dataConfig.chartSeries.type);
          filters.push({ field: dataConfig.chartSeries, value: seriesFormatter(data.series.key) });
        }

        if (data.point.rangeX !== undefined && data.point.rangeY !== undefined) {
          // If these range values are here, then DataDownsampler added them
          // We'll need to create ranges to view drill down in
          var xFieldName = dataConfig.chartXAxis.fieldName;
          var xField = DataModel.table.field(xFieldName);
          var xFilter = xField.criteriaFilter.newFilter;
          xFilter.operator = "between";
          xFilter.active = true;
          xFilter.value = xFormatter(data.point.rangeX.min);
          xFilter.value2 = xFormatter(data.point.rangeX.max);
          DataModel.table.field(xFieldName, xField);

          var yFieldName = dataConfig.chartYAxis.fieldName;
          var yField = DataModel.table.field(yFieldName);
          var yFilter = yField.criteriaFilter.newFilter;
          yFilter.operator = "between";
          yFilter.active = true;
          yFilter.value = yFormatter(data.point.rangeY.min);
          yFilter.value2 = yFormatter(data.point.rangeY.max);
          DataModel.table.field(yFieldName, yField);

          $scope.resetMessage();
        } else {
          filters.push({ field: dataConfig.chartXAxis, value: xFormatter(data.point.x) });
          filters.push({ field: dataConfig.chartYAxis, value: yFormatter(data.point.y) });
        }

        $scope.tab.callbackInterface.onZoomIn(filters);
      })
      .override("getKeyColor", config => config && ChartService.getKeyColor(config.colorByField, config.colorMapping));

    base.initChart();
  });
