import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";
import uniq from "lodash/uniq";

angular.module("acl.visualizer.charts").factory("ChartDataSorter", function(ChartService, Sorter) {
  return {
    sortChartDataByPropertyName,
    sortChartDataByTotals,
    sortChartDataByCustomOrder,
    reorderValues,
    getXAxisDataPointsInAscOrder,
    getXAxisDataPointsInCustomOrder,
  };

  function sortChartDataByPropertyName(data, propertyName, dataType, order = "asc", isUnicode = true) {
    return Sorter.sort(data, {
      dataType: dataType,
      order: order,
      unicode: isUnicode,
      valueParser: item => item[propertyName],
    });
  }

  function getValueTotalsByKey(data, valueTotalsByKey) {
    data.map(value => {
      const { key } = value;
      valueTotalsByKey[key] = valueTotalsByKey[key] || 0;
      valueTotalsByKey[key] += value.values;
      return valueTotalsByKey[key];
    });
  }

  function sortChartDataByTotals(data, order) {
    var valueTotalsByKey = {};

    // If values is array then its these values that should be sorted.
    if (data.length && Array.isArray(data[0].values)) {
      data.forEach(row => getValueTotalsByKey(row.values, valueTotalsByKey));
      return data.map(row => {
        const sortedValues = Sorter.sort(row.values, {
          valueParser: item => valueTotalsByKey[item.key],
          order,
          dataType: "numeric",
        });
        return { ...row, values: sortedValues };
      });
    }
    getValueTotalsByKey(data, valueTotalsByKey);
    return Sorter.sort(data, {
      valueParser: item => valueTotalsByKey[item.key],
      order: order,
      dataType: "numeric",
    });
  }

  function sortChartDataByCustomOrder(data, valuesOrder, dataType) {
    if (data.length && Array.isArray(data[0].values)) {
      return data.map(row => {
        const sortedValues = Sorter.sort(row.values, {
          valueParser: value => {
            const parsedValue = ChartService.parseValue(value.key, dataType);
            return GlobalValueFormatter.normalizeValue(parsedValue, dataType);
          },
          valuesOrder,
          dataType,
        });
        return { ...row, values: sortedValues };
      });
    }
  }

  function reorderValues(chartData, sortConfig) {
    const {
      order,
      valuesOrder,
      xAxis: { fieldType },
    } = sortConfig;
    switch (order) {
      case "asc":
      case "desc":
        return this.sortChartDataByTotals(chartData, order);
      case "custom":
        return this.sortChartDataByCustomOrder(chartData, valuesOrder, fieldType);
      default:
        return chartData.slice(0);
    }
  }

  function getXAxisDataPointsInAscOrder(chartData, dataType) {
    if (chartData && chartData.length > 0) {
      const allXAxisDataPoints = chartData.reduce(
        (allXAxisDataPoints, series) => allXAxisDataPoints.concat(series.values),
        []
      );
      return Sorter.sort(uniq(allXAxisDataPoints.map(d => d.key)), { dataType: dataType });
    }
  }

  function getXAxisDataPointsInCustomOrder(chartData, valuesOrder, dataType) {
    if (chartData && chartData.length > 0) {
      const allXAxisDataPoints = this.getXAxisDataPointsInAscOrder(chartData, dataType);
      return Sorter.sort(allXAxisDataPoints, {
        valueParser: value => {
          const parsedValue = ChartService.parseValue(value, dataType);
          return GlobalValueFormatter.normalizeValue(parsedValue, dataType);
        },
        valuesOrder: valuesOrder,
        dataType: dataType,
      });
    }
  }
});
