import SessionStorageHelper from "@viz-ui/services/sessionStorage/sessionStorageHelper";
import StoryboardFilter from "@viz-ui/models/storyboardFilter/storyboardFilter";
import FilterValue from "@viz-ui/models/storyboardFilter/filterValue";
import FilterField from "@viz-ui/models/storyboardFilter/filterField";
import FieldFilterSet from "@viz-ui/models/filter/fieldFilterSet";
import { coneService } from "@results/services/apiPath/coneService";
import StoryboardDrilldownFiltersTransformer from "./storyboardDrilldownFiltersTransformer";
import checkboxState from "@viz-ui/models/checkboxState";

export default class StoryBoardDrilldownService {
  static async processDrilldownAsync(board, drilldownFilters) {
    const that = this;
    return new Promise(function(resolve, reject) {
      try {
        Promise.all([
          that.getDrilldownURLAsync(board),
          that.getApplicableFiltersForBoardAsync(board.tableId, drilldownFilters),
        ]).then(values => {
          const drilldownURL = values[0];
          const drilldownFiltersForBoard = values[1];
          resolve({
            url: drilldownURL,
            data: {
              storyboardId: board.storyboardId,
              storyboardName: board.storyboardName,
              drilldownFilters: drilldownFiltersForBoard,
            },
          });
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  static async getApplicableFiltersForBoardAsync(tableId, drilldownFilters) {
    return this.getApplicableFiltersForBoard(tableId, drilldownFilters);
  }

  static getApplicableFiltersForBoard(tableId, drilldownFilters) {
    const resultSet =
      drilldownFilters &&
      drilldownFilters.reduce((drillDownFilters, response) => {
        let drilldownFilter = response;
        if (drilldownFilter && drilldownFilter.isActive() && drilldownFilter.filterFields()) {
          const drillDownField = drilldownFilter.filterFields().find(filterField => filterField.tableId() === tableId);
          const filterValue =
            drilldownFilter.value() != null
              ? new FilterValue().value(drilldownFilter.value().value()).count(drilldownFilter.value().count())
              : null;
          const filterValues =
            drilldownFilter.values() != null && drilldownFilter.values().length > 0
              ? drilldownFilter
                  .values()
                  .map(filterValueParam =>
                    new FilterValue().value(filterValueParam.value()).count(filterValueParam.count())
                  )
              : null;

          const filterUnSelectedValues =
            drilldownFilter.unSelectedValues() != null && drilldownFilter.unSelectedValues().length > 0
              ? drilldownFilter
                  .unSelectedValues()
                  .map(filterValueParam =>
                    new FilterValue().value(filterValueParam.value()).count(filterValueParam.count())
                  )
              : undefined;

          const valuesFlag =
            drilldownFilter.selectAllState() === checkboxState.CHECKED ||
            filterValue ||
            filterValues ||
            filterUnSelectedValues ||
            drilldownFilter.operator() === "is_blank" ||
            drilldownFilter.operator() === "is_not_blank"; //For following operators 'is_blank', 'is_not_blank' no values will be provided

          if (drillDownField && valuesFlag) {
            drilldownFilter = new StoryboardFilter()
              .id(drilldownFilter.id())
              .displayName(drilldownFilter.displayName())
              .isActive(drilldownFilter.isActive())
              .type(drilldownFilter.type())
              .operator(drilldownFilter.operator())
              .value(filterValue)
              .values(filterValues)
              .filterFields([new FilterField().name(drillDownField.name()).tableId(drillDownField.tableId())])
              .fieldType(drilldownFilter.fieldType())
              .excludeToday(drilldownFilter.excludeToday())
              .selectAllState(drilldownFilter.selectAllState())
              .unSelectedValues(filterUnSelectedValues);

            drillDownFilters.push(drilldownFilter);
          }
        }
        return drillDownFilters;
      }, []);

    return resultSet || [];
  }

  static async getDrilldownURLAsync(board) {
    return this.getDrilldownURL(board);
  }

  static getDrilldownURL(board) {
    switch (board.type) {
      case "visualization":
        return this.getVizURL(board);
      case "metric":
        return this.getMetricURL(board);
      case "trigger":
        return this.getTriggerURL(board);
      case "table":
        return this.getTableURL(board);
      default:
        return undefined;
    }
  }

  static getVizURL(board) {
    const interpretationId = board.interpretation_id;
    const visualizationId = board.visualization_id;

    const baseUrl = coneService.shortInterpretationPath({ id: interpretationId });
    const vizUrl = `/viz/${visualizationId}?storyboard_id=${board.storyboardId}`;

    return `${baseUrl}${vizUrl}`;
  }

  static getTableURL(board) {
    const interpretationId = board.interpretation_id;

    const baseUrl = coneService.shortInterpretationPath({ id: interpretationId });
    const vizUrl = `/table?storyboard_id=${board.storyboardId}`;

    return `${baseUrl}${vizUrl}`;
  }

  static getMetricURL(board) {
    const { projectId, controlId, controlTestId, metricId } = board;

    const baseUrl = coneService.projectControlControlTestMetricsPath({
      project_id: projectId,
      control_id: controlId,
      control_test_id: controlTestId,
    });
    const metricUrl = `/#/${metricId}?storyboard_id=${board.storyboardId}`;

    return baseUrl + metricUrl;
  }

  static getTriggerURL(board) {
    const { projectId, controlId, controlTestId, metricId, triggerId } = board;
    const baseUrl = coneService.projectControlControlTestMetricsPath({
      project_id: projectId,
      control_id: controlId,
      control_test_id: controlTestId,
    });
    const metricUrl = `/#/${metricId}/triggers/${triggerId}?storyboard_id=${board.storyboardId}`;

    return baseUrl + metricUrl;
  }

  static async getSessionStorageKeyAsync(board) {
    return this.getSessionStorageKey(board);
  }

  static getSessionStorageKey(board) {
    const key = `${board.type}_drilldownfilters_${board.storyboardId}_`;
    switch (board.type) {
      case "visualization":
        return key + board.interpretation_id + "_" + board.visualization_id;
      case "metric":
        return key + board.metricId;
      case "trigger":
        return key + board.metricId + "_" + board.triggerId;
      case "table":
        return key + board.interpretation_id;
      default:
        return undefined;
    }
  }

  static processDrilldownFiltersAsync(vizFilterSets, sessionStorageKey) {
    const that = this;
    const promise = new Promise(function(resolve, reject) {
      let updatedVizFilters = vizFilterSets;

      try {
        const storyboardFilters = JSON.parse(SessionStorageHelper.get(sessionStorageKey));

        if (!storyboardFilters || storyboardFilters.length === 0) {
          resolve({ storyboardFiltersExists: false, updatedVizFilters: updatedVizFilters });
          return;
        }

        const vizTransformedFilterSets = StoryboardDrilldownFiltersTransformer.transformToVizFilterSets(
          storyboardFilters.map(storyboardFilter => that.toStoryboardFilter(storyboardFilter))
        );

        if (vizTransformedFilterSets) {
          updatedVizFilters = StoryboardDrilldownFiltersTransformer.mergeFilterSets(
            vizFilterSets ? vizFilterSets.map(vizFilterSet => FieldFilterSet.fromJson(vizFilterSet)) : [],
            vizTransformedFilterSets
          );

          updatedVizFilters =
            updatedVizFilters && updatedVizFilters.length > 0
              ? updatedVizFilters.map(filterSet => filterSet.toJson())
              : vizFilterSets;
        }

        resolve({
          storyboardFiltersExists: vizTransformedFilterSets && vizTransformedFilterSets.length > 0,
          updatedVizFilters: updatedVizFilters,
        });
      } catch (e) {
        reject();
        console.log(e);
      }
    });

    return promise;
  }

  static toStoryboardFilter = storyboardFilterJSON => {
    const storyboardFilter = new StoryboardFilter(storyboardFilterJSON._data);
    storyboardFilter.value(storyboardFilter.value() ? new FilterValue(storyboardFilter.value()._data) : null);
    storyboardFilter.values(
      storyboardFilter.values()
        ? storyboardFilter.values().map(filterValue => new FilterValue(filterValue._data))
        : null
    );
    storyboardFilter.unSelectedValues(
      storyboardFilter.unSelectedValues()
        ? storyboardFilter.unSelectedValues().map(filterValue => new FilterValue(filterValue._data))
        : null
    );
    storyboardFilter.filterFields(
      storyboardFilter.filterFields()
        ? storyboardFilter.filterFields().map(filterField => new FilterField(filterField._data))
        : null
    );

    return storyboardFilter;
  };
}
