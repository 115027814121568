/* eslint-disable */

// src: https://github.com/d3/d3-scale/blob/43ca71c0950e15c4e2f3cecb8086a03d399f608c/src/ordinal.js
// Note: modified to remove dependency on initRange.

export const implicit = Symbol("implicit");

export default function ordinal() {
  var index = new Map(),
    domain = [],
    range = [],
    unknown = implicit;

  function scale(d) {
    var key = d + "",
      i = index.get(key);
    if (!i) {
      if (unknown !== implicit) return unknown;
      index.set(key, (i = domain.push(d)));
    }
    return range[(i - 1) % range.length];
  }

  scale.domain = function(_) {
    if (!arguments.length) return domain.slice();
    (domain = []), (index = new Map());
    for (const value of _) {
      const key = value + "";
      if (index.has(key)) continue;
      index.set(key, domain.push(value));
    }
    return scale;
  };

  scale.range = function(_) {
    return arguments.length ? ((range = Array.from(_)), scale) : range.slice();
  };

  scale.unknown = function(_) {
    return arguments.length ? ((unknown = _), scale) : unknown;
  };

  scale.copy = function() {
    return ordinal(domain, range).unknown(unknown);
  };

  initRange.apply(scale, arguments);

  return scale;
}

function initRange(domain, range) {
  switch (arguments.length) {
    case 0:
      break;
    case 1:
      this.range(domain);
      break;
    default:
      this.range(range).domain(domain);
      break;
  }
  return this;
}
