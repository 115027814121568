angular.module("template/modal/backdrop.html", []).run([
  "$templateCache",
  function($templateCache) {
    $templateCache.put(
      "template/modal/backdrop.html",
      '<div class="reveal-modal-bg fade" ng-class="{in: animate}" ng-click="close($event)" style="display: block"></div>\n' +
        ""
    );
  },
]);

angular.module("template/modal/window.html", []).run([
  "$templateCache",
  function($templateCache) {
    $templateCache.put(
      "template/modal/window.html",
      '<div tabindex="-1" class="reveal-modal fade {{ windowClass }}"\n' +
        '  ng-class="{in: animate}" ng-click="close($event)"\n' +
        '  style="display: block; position: fixed; visibility: visible">\n' +
        "  <div ng-transclude></div>\n" +
        "</div>\n" +
        ""
    );
  },
]);

angular.module("template/tabs/tab.html", []).run([
  "$templateCache",
  function($templateCache) {
    $templateCache.put(
      "template/tabs/tab.html",
      '<dd ng-class="{active: active, disabled}">\n' +
        '  <a ng-click="select()" tab-heading-transclude>{{heading}}</a>\n' +
        "</dd>\n" +
        ""
    );
  },
]);

angular.module("template/tabs/tabset.html", []).run([
  "$templateCache",
  function($templateCache) {
    $templateCache.put(
      "template/tabs/tabset.html",
      '<div class="tabbable">\n' +
        '  <dl class="tabs" ng-class="{\'vertical\': vertical}" ng-transclude></dl>\n' +
        '  <div class="tabs-content" ng-class="{\'vertical\': vertical}">\n' +
        '    <div class="content" \n' +
        '      ng-repeat="tab in tabs" \n' +
        '      ng-class="{active: tab.active}">\n' +
        '      <div tab-content-transclude="tab"></div>\n' +
        "    </div>\n" +
        "  </div>\n" +
        "</div>\n" +
        ""
    );
  },
]);

angular.module("acl.angular.ui", [
  "mm.foundation.modal",
  "template/modal/backdrop.html",
  "template/modal/window.html",
  "mm.foundation.tabs",
  "template/tabs/tab.html",
  "template/tabs/tabset.html",
]);
