import PropTypes from "prop-types";
import React from "react";
import services from "../../../services/glue/appGlue";
import Radio from "@paprika/radio";
import Button from "@paprika/button";
import reject from "lodash/reject";
import GroupsSelector from "./groupsSelector.es6";
import { StoryboardBackend } from "../../storyboard/storyboard.backend.es6";
import backendApi from "@results/services/apiCall/backendApi";
import User from "@storyboards/modules/services/user.service";
class LinkPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      linkType: props.linkType,
      workflowGroups: props.selectedGroups || [],
      launchpadGroups: props.selectedLaunchpadGroups || [], //all the selected groups
      isDirty: false,
      groups: null,
      allAvailableLaunchpadGroups: null, //this refers to all the available launchpadgroups
      isLoading: false,
    };
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleRegenerate = this.handleRegenerate.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.isGroupSelected = this.isGroupSelected.bind(this);
    this.handleDeleteLink = this.handleDeleteLink.bind(this);
  }

  componentDidMount() {
    // Code to execute after the component has been added to the DOM
    this.getGroupsAndWorkflows();
  }
  //call groups and workflows api for linkpane
  async getGroupsAndWorkflows() {
    this.setState({ isLoading: true });
    if (
      (backendApi.isStoryboardSPA() || services.AppConfig.environment === services.AppConfig.environments.DEV) &&
      !User.isAnonymous()
    ) {
      await Promise.all([
        StoryboardBackend.getGroups(services.AppConfig.highbondNavBarProps.appSwitcherProps.initialOrganizationId),
        StoryboardBackend.getLaunchpadGroups(
          services.AppConfig.highbondNavBarProps.appSwitcherProps.initialOrganizationId
        ),
      ]).then(([groups, launchpadGroups]) => {
        this.setState({ groups: groups, allAvailableLaunchpadGroups: launchpadGroups, isLoading: false });
      });
    }
  }

  handleRowClick(groupId, groupType) {
    if (groupType === "workflow") {
      if (this.state.workflowGroups.indexOf(groupId) > -1) {
        this.setState({
          workflowGroups: reject(this.state.workflowGroups, function(id) {
            return id === groupId;
          }),
          isDirty: true,
        });
      } else {
        this.setState({
          workflowGroups: this.state.workflowGroups.concat([groupId]),
          isDirty: true,
        });
      }
    }
    if (groupType === "launchpad") {
      if (this.state.launchpadGroups.indexOf(groupId) > -1) {
        this.setState({
          launchpadGroups: reject(this.state.launchpadGroups, function(id) {
            return id === groupId;
          }),
          isDirty: true,
        });
      } else {
        this.setState({
          launchpadGroups: this.state.launchpadGroups.concat([groupId]),
          isDirty: true,
        });
      }
    }
  }

  isGroupSelected(groupId, groupType) {
    if (groupType === "workflow") return this.state.workflowGroups.indexOf(groupId) > -1;
    if (groupType === "launchpad") return this.state.launchpadGroups.indexOf(groupId) > -1;
  }

  getGenerateLink() {
    let generateLink;
    if (!this.props.publicLink) {
      generateLink = (
        <Button kind={Button.types.kind.PRIMARY} onClick={this.handleConfirm} className="acl-btn primary">
          {services.Localize.getLocalizedString("_Storyboards.SharePanel.Link.GenerateBtn_")}
        </Button>
      );
    }
    return generateLink;
  }

  getSaveChangesBtn(isDisabled) {
    let saveChangesBtn;
    if (this.props.publicLink) {
      saveChangesBtn = (
        <>
          <Button kind={Button.types.kind.PRIMARY} onClick={this.handleConfirm} isDisabled={isDisabled}>
            {services.Localize.getLocalizedString("_Storyboards.SharePanel.Link.SaveChangesBtn_")}
          </Button>
          &nbsp;
          <Button kind={Button.types.kind.MINOR} className="cancel" onClick={this.handleCancel} isDisabled={isDisabled}>
            {services.Localize.getLocalizedString("_Storyboards.SharePanel.Link.CancelBtn_")}
          </Button>
        </>
      );
    }
    return saveChangesBtn;
  }

  getShowLinkSection() {
    let showLinkSection;
    if (this.props.publicLink) {
      showLinkSection = (
        <div className="storyboard-link__wrapper">
          <PublicLink link={this.props.publicLink} />
          <LinkActionsSection
            onRegenerate={this.handleRegenerate}
            onDelete={this.handleDeleteLink}
            accessType={this.props.linkType}
          />
        </div>
      );
    }
    return showLinkSection;
  }

  getLinkTypes() {
    return (
      <Radio.Group
        onChange={Index => {
          const newLinkType = Index === 1 ? "group" : "anyone";
          const oldLinkType = this.props.linkType;
          this.setState({ isDirty: oldLinkType === newLinkType ? false : true, linkType: newLinkType });
        }}
      >
        <Radio
          value="anyone"
          size={Radio.types.size.SMALL}
          className="storyboard-link__share_link_type"
          isChecked={this.state.linkType === "anyone"}
        >
          {services.Localize.getLocalizedString("_Storyboards.SharePanel.Link.LinkTypeAnyone_")}
        </Radio>
        <Radio
          value="group"
          size={Radio.types.size.SMALL}
          className="storyboard-link__share_link_type"
          isChecked={this.state.linkType === "group"}
        >
          {services.Localize.getLocalizedString("_Storyboards.SharePanel.Link.LinkTypeUserGroups_")}
        </Radio>
      </Radio.Group>
    );
  }

  handleConfirm() {
    this.setState({ isDirty: false });
    const { linkType, launchpadGroups, workflowGroups } = this.state;
    if (linkType === "anyone") {
      this.props.onGenerate(linkType, [], []);
      this.setState({ launchpadGroups: [], workflowGroups: [] });
    } else {
      this.props.onGenerate(linkType, launchpadGroups, workflowGroups);
    }
  }

  /*
   Function to delete existing link and to regenerate new link   
  */
  handleRegenerate() {
    const { linkType, selectedLaunchpadGroups, selectedGroups } = this.props;
    if (linkType) {
      if (linkType === "anyone") {
        this.props.onRegenerate(linkType, [], []);
      } else {
        this.props.onRegenerate(linkType, selectedLaunchpadGroups, selectedGroups);
      }
    } else {
      this.props.onRegenerate();
    }
  }

  handleCancel() {
    const oldLinkType = this.props.linkType;
    this.setState({
      linkType: oldLinkType,
      isDirty: false,
      launchpadGroups: this.props.selectedLaunchpadGroups,
      workflowGroups: this.props.selectedGroups,
    });
  }

  handleDeleteLink() {
    this.setState({
      isDirty: false,
      linkType: "anyone",
      launchpadGroups: [],
      workflowGroups: [],
    });
    this.props.onDelete();
  }

  render() {
    if (services.AppConfig.features.userGroupsSharePanel) {
      const isDisabled = this.state.linkType === this.props.linkType && !this.state.isDirty;
      return (
        <div className="storyboard-link">
          <div className="storyboard-link__reminder">
            <label className="storyboards-share__reminder_label">
              {services.Localize.getLocalizedString("_Storyboards.SharePanel.Link.header_")}
            </label>
            {this.getLinkTypes()}
            {this.state.linkType === "group" ? (
              <div className="user-groups-section">
                <GroupsSelector
                  groups={this.state.groups}
                  launchpadGroups={this.state.allAvailableLaunchpadGroups}
                  onUsersSelected={this.handleConfirm}
                  handleRowClick={this.handleRowClick}
                  isGroupSelected={this.isGroupSelected}
                  isLoading={this.state.isLoading}
                />
              </div>
            ) : null}
          </div>
          <div className="storyboard-link__footer">
            {this.state.isDirty ? this.getSaveChangesBtn(isDisabled) : null}
            <label className="storyboards-share__reminder_label">
              {services.Localize.getLocalizedString("_Storyboards.SharePanel.Link.shareableLink_")}
            </label>
            {this.getGenerateLink()}
            {this.getShowLinkSection()}
          </div>
        </div>
      );
    }
    return (
      <div className="storyboard-link">
        <div className="storyboard-link__reminder">
          {services.Localize.getLocalizedString("_Storyboards.SharePanel.Link.Reminder_")}
        </div>
        {this.getGenerateLink()}
        {this.getShowLinkSection()}
      </div>
    );
  }
}

LinkPane.propTypes = {
  publicLink: PropTypes.string,
  onGenerate: PropTypes.func,
  onRegenerate: PropTypes.func,
  onDelete: PropTypes.func,
  selectedGroups: PropTypes.array,
  selectedLaunchpadGroups: PropTypes.array,
  linkType: PropTypes.string,
};

window.LinkPane = LinkPane;
