import isEqual from "lodash/isEqual";

angular
  .module("acl.visualizer.shareToGRC")
  .controller("ShareToGRCController", function(
    $scope,
    $modalInstance,
    $stateParams,
    $modal,
    AppConfig,
    Localize,
    SaveViz,
    DataModel,
    EventService,
    shareToGRC,
    state,
    controlTest,
    error,
    dataRecords
  ) {
    "use strict";
    var eventService = EventService.register("ShareToGRCController", $scope);
    $scope.controlTest = controlTest;
    $scope.state = state;
    $scope.error = error;
    $scope.dataRecords = dataRecords;

    if ($scope.state === "init") {
      $scope.dataRecords = DataModel.table.recordCount();
      $scope.fieldsCount = Object.keys(DataModel.table.fields()).length;
      if (
        $scope.dataRecords > AppConfig.share_grc_limit.Max_Record &&
        $scope.fieldsCount > AppConfig.share_grc_limit.Max_Column
      ) {
        $scope.state = "dataRecords&fieldsExceedLimit";
      } else if ($scope.dataRecords > AppConfig.share_grc_limit.Max_Record) {
        $scope.state = "dataRecordsExceedLimit";
      } else if ($scope.fieldsCount > AppConfig.share_grc_limit.Max_Column) {
        $scope.state = "fieldsExceedLimit";
      } else {
        getAllRegions();
      }
    }

    function getAllRegions() {
      $scope.regions = [];
      shareToGRC.getOrgs().then(
        function(response) {
          getSupportedRegions(response.data);
          setModalState();
        },
        function(response) {
          $scope.state = "connectToGRCFailed";
          handleErrorMessages(response);
        }
      );
    }

    function getSupportedRegions(orgs) {
      var regionExists;
      for (var orgIndex in orgs) {
        regionExists = false;
        for (var regionIndex in $scope.regions) {
          if ($scope.regions[regionIndex].code === orgs[orgIndex].region.code) {
            regionExists = true;
          }
        }
        if (!regionExists) {
          var resultsUrl = null;
          for (var appIndex in orgs[orgIndex].apps) {
            if (orgs[orgIndex].apps[appIndex].key.indexOf("result") !== -1) {
              resultsUrl = orgs[orgIndex].apps[appIndex].url;
            }
          }
          if (resultsUrl !== null) {
            var region = {
              id: orgs[orgIndex].region.id,
              code: orgs[orgIndex].region.code,
              name: orgs[orgIndex].region.name,
              resultsUrl: resultsUrl,
              accountsLoaded: false,
            };
            $scope.regions.push(region);
          }
        }
      }
    }

    function setModalState() {
      if ($scope.regions.length === 1) {
        getAccounts($scope.regions[0]);
      } else {
        $scope.state = "multipleRegions";
      }
    }

    function getAccounts(region) {
      shareToGRC.getAccounts(region).then(
        function(response) {
          for (var i in response.data) {
            response.data[i].projects = [];
          }
          $scope.regions[0].accountsLoaded = true;
          $scope.regions[0].accounts = response.data;
          $scope.state = "singleRegion";
        },
        function(response) {
          $scope.regions[0].accounts = [];
        }
      );
    }

    $scope.chooseControlTest = function(controlTest) {
      $scope.controlTest = controlTest;
      if ($scope.sharedToSameControlTest(controlTest)) {
        $scope.getInterpretation();
      } else {
        $scope.shareMetadataToControlTest();
      }
    };

    $scope.getInterpretation = function() {
      var interpretationId = DataModel.interpretation.grcInfo().interpretationId;
      shareToGRC.getInterpretation($scope.controlTest.id, interpretationId).then(
        function(response) {
          $modalInstance.close();
          $scope.state = "interpretationAlreadyExists";
          openConfirmationModal();
        },
        function() {
          $scope.shareMetadataToControlTest();
        }
      );
    };

    $scope.sharedToSameControlTest = function(controlTest) {
      return DataModel.interpretation.grcInfo() && DataModel.interpretation.grcInfo().controlTestId === controlTest.id;
    };

    function getMetadataAttributes() {
      var metadata = { attributes: [] };
      for (var field in DataModel.table.fields()) {
        var attribute = {
          field_name: field,
          display_name: DataModel.table.fields()[field].displayName,
          data_type: DataModel.table.fields()[field].type,
        };
        metadata.attributes.push(attribute);
      }
      return metadata;
    }

    $scope.shareMetadataToControlTest = function() {
      var metadata = getMetadataAttributes();
      shareToGRC.shareMetadata($scope.controlTest, metadata).then(
        function(response) {
          $scope.shareInterpretationToControlTest();
        },
        function(response) {
          $modalInstance.close();
          $scope.state = "shareInterpretationFailed";
          handleErrorMessages(response);
          openConfirmationModal();
        }
      );
    };

    $scope.shareInterpretationToControlTest = function() {
      shareToGRC.shareInterpretation($scope.controlTest, DataModel.toSaveViz()).then(
        function(response) {
          $modalInstance.close();
          $scope.state = "promptToUploadData";
          var grcInfo = {
            interpretationId: response.data.id,
            controlTestId: $scope.controlTest.id,
          };
          eventService.publish("shareToGRC.interpretationShared", grcInfo);
          openConfirmationModal();
        },
        function(response) {
          $modalInstance.close();
          $scope.state = "shareInterpretationFailed";
          handleErrorMessages(response);
          openConfirmationModal();
        }
      );
    };

    function handleErrorMessages(response) {
      let message;
      let detailMessage;
      if (response.status === 401) {
        message = "_UploadToGRC.Modal.ShareInterpretation.401.Failed.Message_";
      } else if (response.status === 422) {
        if (isProjectArchived(response.data)) {
          message = "_UploadToGRC.Modal.Failed.ProjectArchived.Message_";
        } else if (response.data.message.title && response.data.message.title[0].indexOf("taken") > -1) {
          message = "_UploadToGRC.Modal.ShareInterpretation.Title.Taken.Message_";
        } else {
          message = "_UploadToGRC.Modal.ShareInterpretation.422.Failed.Message_";
        }
        detailMessage = getErrorDetailMessage(response.data);
      }

      $scope.error = {
        message: message,
        detail: detailMessage,
      };
    }

    function getErrorDetailMessage(responseData) {
      if (responseData && responseData.error) {
        return JSON.stringify(responseData.error);
      }
      return JSON.stringify(responseData);
    }

    function openConfirmationModal() {
      $modal.open({
        templateUrl: "visualizer/views/uploadDataToGRCModal.html",
        controller: "ShareToGRCController",
        windowClass: "share-data-to-grc-modal",
        resolve: {
          state: function() {
            return $scope.state;
          },
          controlTest: function() {
            return $scope.controlTest;
          },
          error: function() {
            return $scope.error;
          },
          dataRecords: function() {
            return $scope.dataRecords;
          },
        },
      });
    }

    $scope.doNotUploadData = function() {
      $modalInstance.close();
    };

    $scope.uploadData = function() {
      $scope.state = "uploadingData";
      var jobId = DataModel.getFilterConfigDeprecated().jobId;
      shareToGRC.uploadData(DataModel.toSaveViz(), controlTest, jobId).then(
        function(response) {
          $scope.state = "uploadDataSuccess";
        },
        function(response) {
          handleDataUploadErrors(response);
          $scope.state = "uploadDataFailed";
        }
      );
    };

    function handleDataUploadErrors(response) {
      $scope.error = {
        message: "",
      };
      if (response.status === 422) {
        if (isProjectArchived(response.data)) {
          $scope.error.message = "_UploadToGRC.Modal.UploadData.Failed.ProjectArchived.Message_";
        } else if (response.data && response.data.error && response.data.error.test_exception_count) {
          $scope.error.message = "_UploadToGRC.Modal.UploadData.Failed.ExceedLimit.Message_";
        }
      }
    }

    function isProjectArchived(responseData = {}) {
      const responseErrorMessage = responseData.message || responseData.error;
      const projectArchivedErrorMessage = { project: ["archived"] };
      return isEqual(responseErrorMessage, projectArchivedErrorMessage);
    }

    $scope.close = function() {
      $modalInstance.close();
    };

    $scope.close = function() {
      $modalInstance.dismiss();
    };

    $scope.replaceInterpretation = function() {
      var metadata = getMetadataAttributes();
      shareToGRC.shareMetadata($scope.controlTest, metadata).then(
        function(response) {
          updateInterpretation();
        },
        function(response) {
          $scope.state = "shareInterpretationFailed";
          handleErrorMessages(response);
        }
      );
    };

    function updateInterpretation() {
      var interpretationId = DataModel.interpretation.grcInfo().interpretationId;
      shareToGRC.updateInterpretation($scope.controlTest.id, interpretationId, DataModel.toSaveViz()).then(
        function(response) {
          $scope.state = "promptToUploadData";
        },
        function(response) {
          $scope.state = "shareInterpretationFailed";
          handleErrorMessages(response);
        }
      );
    }
  });
