import moment from "moment";
import numeral from "numeral";
import Sorter from "../services/sorters/sorter";

const defaultLocale = "en";

const localeObservers = [];

const i18n = {
  set locale(locale) {
    let resource;
    try {
      resource = require("./locales/" + locale + ".json");
    } catch (e) {
      resource = require("./locales/" + defaultLocale + ".json");
    }
    this.currentLocale = locale;
    this.translations = transformListToMap(resource);

    initMomentJs(locale);
    initNumeralJs(locale);
    initSorter(locale);

    notifyObservers(locale);
  },

  get locale() {
    return this.currentLocale;
  },

  t(key, variables) {
    if (!this.translations) {
      this.locale = defaultLocale;
    }
    if (this.translations[key]) {
      if (!variables) {
        return this.translations[key];
      } else {
        let translatedString = this.translations[key];
        Object.keys(variables).forEach(key => {
          translatedString = translatedString.replace("${" + key + "}", variables[key]);
        });
        return translatedString;
      }
    }
    return key;
  },

  addObserver(func) {
    localeObservers.push(func);
  },

  removeObserver(func) {
    var index = localeObservers.indexOf(func);

    if (index >= 0) {
      localeObservers.splice(index, 1);
    }
  },
};

//TODO: rationalize with visualizer localize.service.js. no subCode supported.
function initMomentJs(twoLetterLanguage) {
  moment.locale(twoLetterLanguage);
}

function initNumeralJs(twoLetterLanguage) {
  try {
    let userLocale = twoLetterLanguage;
    if (twoLetterLanguage === "pt") {
      userLocale = "pt-br";
    }
    numeral.locale(userLocale, require("numeral/locales/" + userLocale));
    numeral.locale(userLocale);
  } catch (e) {
    numeral.locale(defaultLocale);
  }
  var abbreviations_override = {
    thousand: i18n.t("_Numeric.Abbreviations.Thousand_"),
    million: i18n.t("_Numeric.Abbreviations.Million_"),
    billion: i18n.t("_Numeric.Abbreviations.Billion_"),
    trillion: i18n.t("_Numeric.Abbreviations.Trillion_"),
  };
  for (var key in numeral.localeData().abbreviations) {
    numeral.localeData().abbreviations[key] = abbreviations_override[key];
  }
}

function initSorter(twoLetterLanguage) {
  Sorter.locale(twoLetterLanguage);
}

function notifyObservers(twoLetterLanguage) {
  localeObservers.forEach(func => {
    func(twoLetterLanguage);
  });
}

function transformListToMap(keyValuePairJson) {
  return keyValuePairJson.reduce(function(accumulator, entry) {
    accumulator[entry["key"]] = entry["value"];
    return accumulator;
  }, {});
}

export default i18n;
