import React from "react";
import i18n from "@viz-ui/i18n/i18n";
import PropTypes from "prop-types";
import GlobalValueFormatter from "../../../services/formatters/globalValueFormatter";
import { formatFieldValueShortNumber, numeralFormatter } from "../../../services/formatters/numericFormatter";
import ToolTip from "@viz-ui/components/ToolTip";

class NumericStatistics extends React.Component {
  static propTypes = {
    statistic: PropTypes.object,
    config: PropTypes.object,
    index: PropTypes.number,
  };

  static defaultProps = {
    statistic: {},
    config: {},
    index: 0,
  };

  render() {
    return (
      <div id={`statistic-${this.props.statistic.fieldName}-${this.props.statistic.index}`} className="numeric">
        <div className="statistic_info">
          <h2 className="statistic_title">{this.props.statistic.displayName}</h2>
          <div className="highest">
            <p className="title">
              <a
                id={`statistic-${this.props.index}-highest-link`}
                onClick={() => this.props.setDisplayForPopup(this.props.statistic.fieldName, "highest", true)}
              >
                <span>{i18n.t("_Chart.StatisticsViz.Highest.Label_")}</span>
                {` ${GlobalValueFormatter.formatValue(
                  this.props.statistic.highest[0].value,
                  this.props.config.interpretationId,
                  this.props.config.fieldNames[this.props.index]
                )}`}
                <i className="icon-chevron-up title-icon"></i>
              </a>
            </p>
            {this.props.showPopup(this.props.statistic, "highest") ? (
              <div className="popup">
                <h3>
                  {i18n.t("_Chart.StatisticsViz.Next.Label_").replace("$0", this.props.statistic.highest.length - 1)}
                </h3>
                <ul>
                  {this.props.statistic.highest.map((highestValues, index) => {
                    return (
                      index !== 0 && (
                        <li key={`highest-${index}`}>
                          <span id={`highest-next-${index + 1}`} className="value">
                            {` ${GlobalValueFormatter.formatValue(
                              highestValues.value,
                              this.props.config.interpretationId,
                              this.props.config.fieldNames[this.props.index]
                            )}`}
                          </span>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="lowest">
            <p className="title">
              <a
                id={`statistic-${this.props.index}-lowest-link`}
                onClick={() => this.props.setDisplayForPopup(this.props.statistic.fieldName, "lowest", true)}
              >
                {i18n.t("_Chart.StatisticsViz.Lowest.Label_")}
                {` ${GlobalValueFormatter.formatValue(
                  this.props.statistic.lowest[0].value,
                  this.props.config.interpretationId,
                  this.props.config.fieldNames[this.props.index]
                )}`}
                <i className="icon-chevron-down title-icon"></i>
              </a>
            </p>
            {this.props.showPopup(this.props.statistic, "lowest") ? (
              <div className="popup">
                <h3>
                  {i18n.t("_Chart.StatisticsViz.Next.Label_").replace("$0", this.props.statistic.lowest.length - 1)}
                </h3>
                <ul>
                  {this.props.statistic.lowest.map((lowestValues, index) => {
                    return (
                      index !== 0 && (
                        <li key={`highest-${index}`}>
                          <span id={`highest-next-${index + 1}`} className="value">
                            {` ${GlobalValueFormatter.formatValue(
                              lowestValues.value,
                              this.props.config.interpretationId,
                              this.props.config.fieldNames[this.props.index]
                            )}`}
                          </span>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="totals">
            <div className="total">
              <div>
                <ToolTip
                  align="top"
                  content={GlobalValueFormatter.formatValue(
                    this.props.statistic.total,
                    this.props.config.interpretationId,
                    this.props.config.fieldNames[this.props.index]
                  )}
                >
                  <strong className="title">{i18n.t("_Chart.StatisticsViz.Total.Label_")}</strong>
                  <span className="value">
                    {` ${formatFieldValueShortNumber(
                      this.props.statistic.total,
                      this.props.config.interpretationId,
                      this.props.config.fieldNames[this.props.index]
                    )}`}
                  </span>
                </ToolTip>
              </div>
            </div>
            <div className="average">
              <div>
                <ToolTip
                  align="top"
                  content={GlobalValueFormatter.formatValue(
                    this.props.statistic.average,
                    this.props.config.interpretationId,
                    this.props.config.fieldNames[this.props.index]
                  )}
                >
                  <strong className="title">{i18n.t("_Chart.StatisticsViz.Average.Label_")}</strong>
                  <span className="value">
                    {` ${formatFieldValueShortNumber(
                      this.props.statistic.average,
                      this.props.config.interpretationId,
                      this.props.config.fieldNames[this.props.index]
                    )}`}
                  </span>
                </ToolTip>
              </div>
            </div>
            <div className="range">
              <div>
                <ToolTip
                  align="top"
                  content={GlobalValueFormatter.formatValue(
                    this.props.statistic.range,
                    this.props.config.interpretationId,
                    this.props.config.fieldNames[this.props.index]
                  )}
                >
                  <strong className="title">{i18n.t("_Chart.StatisticsViz.Range.Label_")}</strong>
                  <span className="value">
                    {` ${formatFieldValueShortNumber(
                      this.props.statistic.range,
                      this.props.config.interpretationId,
                      this.props.config.fieldNames[this.props.index]
                    )}`}
                  </span>
                </ToolTip>
              </div>
            </div>
            <div className="std-deviation">
              <div>
                <ToolTip
                  align="top"
                  content={GlobalValueFormatter.formatValue(
                    this.props.statistic.stdDeviation,
                    this.props.config.interpretationId,
                    this.props.config.fieldNames[this.props.index]
                  )}
                >
                  <strong className="title">{i18n.t("_Chart.StatisticsViz.StdDeviation.Label_")}</strong>
                  <span className="value">{` ${numeralFormatter(this.props.statistic.stdDeviation, 2)}`}</span>
                </ToolTip>
              </div>
            </div>
          </div>
        </div>
        <div className="statistic_stats">
          <div className="count stat">
            <h3 className="value">{Number(this.props.statistic.count).toLocaleString()}</h3>
            <p className="title">{i18n.t("_Chart.StatisticsViz.Count.Label_")}</p>
          </div>
          <div className="positives stat">
            <h3 className="value">{Number(this.props.statistic.positives).toLocaleString()}</h3>
            <p className="title">{i18n.t("_Chart.StatisticsViz.Positives.Label_")}</p>
          </div>
          <div className="negatives stat">
            <h3 className="value">{Number(this.props.statistic.negatives).toLocaleString()}</h3>
            <p className="title">{i18n.t("_Chart.StatisticsViz.Negatives.Label_")}</p>
          </div>
          <div className="zeros stat">
            <h3 className="value">{Number(this.props.statistic.zeros).toLocaleString()}</h3>
            <p className="title">{i18n.t("_Chart.StatisticsViz.Zeros.Label_")}</p>
          </div>
        </div>
        <div className="clear"></div>
      </div>
    );
  }
}

export default NumericStatistics;
