const REGION_TAB = "Region";
const COORDINATES_TAB = "Coordinates";
const aclLocationSelector = {
  templateUrl: "visualizer/js/modules/core/locationSelector/locationSelector.tpl.html",
  bindings: {
    locationType: "<",
    locationFieldName: "<",
    locationFields: "<",
    latLongFields: "<",
    latitudeFieldName: "<",
    longitudeFieldName: "<",
    displayNameFieldName: "<",
    dataConfigField: "<",
    fieldNameToFieldObj: "<",
    onChange: "&",
    placeholder: "<",
    position: "<",
  },
  controllerAs: "locationSelector",
  controller: class LocationSelectorController {
    constructor(Localize) {
      "ngInject";
      this._Localize = Localize;
      this.locationPlaceholder = this._Localize.getLocalizedString("_ChartConfig.Region.placeholder_");
      this.latitudePlaceholder = this._Localize.getLocalizedString("_ChartConfig.Latitude.placeholder_");
      this.longitudePlaceholder = this._Localize.getLocalizedString("_ChartConfig.Longitude.placeholder_");
      this.displayNamePlaceholder = this._Localize.getLocalizedString("_ChartConfig.displayName.placeholder_");
      this.viewModel = {
        locationFieldName: null,
        latitudeFieldName: null,
        longitudeFieldName: null,
        displayNameFieldName: null,
      };
      let currentSelectedTab = REGION_TAB;
      this.selectTab = selectedTab => {
        currentSelectedTab = selectedTab;
      };
      this.isRegionTabSelected = () => currentSelectedTab === REGION_TAB;
      this.isCoordinatesTabSelected = () => currentSelectedTab === COORDINATES_TAB;

      this.tabProps = {
        selectedItem: this.isRegionTabSelected() ? REGION_TAB : COORDINATES_TAB,
        handleTabChange: selectedTab => {
          this.tabProps.selectedItem = selectedTab;
          this.selectTab(selectedTab);
        },
      };
    }

    $onChanges(changesObj) {
      const locationType = changesObj.locationType,
        locationFieldName = changesObj.locationFieldName,
        latitudeFieldName = changesObj.latitudeFieldName,
        longitudeFieldName = changesObj.longitudeFieldName,
        displayNameFieldName = changesObj.displayNameFieldName;
      if (locationType) {
        this.tabProps.handleTabChange(locationType.currentValue);
      }
      if (locationFieldName) {
        if (!locationFieldName.currentValue) {
          this.viewModel.locationFieldName = "";
        } else {
          this.viewModel.locationFieldName = locationFieldName.currentValue.fieldName || locationFieldName.currentValue;
        }
      }
      if (latitudeFieldName) {
        if (!latitudeFieldName.currentValue) {
          this.viewModel.latitudeFieldName = "";
        } else {
          this.viewModel.latitudeFieldName = latitudeFieldName.currentValue.fieldName || latitudeFieldName.currentValue;
        }
      }
      if (longitudeFieldName) {
        if (!longitudeFieldName.currentValue) {
          this.viewModel.longitudeFieldName = "";
        } else {
          this.viewModel.longitudeFieldName =
            longitudeFieldName.currentValue.fieldName || longitudeFieldName.currentValue;
        }
      }
      if (displayNameFieldName) {
        if (!displayNameFieldName.currentValue) {
          this.viewModel.displayNameFieldName = "";
        } else {
          this.viewModel.displayNameFieldName =
            displayNameFieldName.currentValue.fieldName || displayNameFieldName.currentValue;
        }
      }
    }

    onLocationFieldNameChange(type, selectedField) {
      this.viewModel.locationFieldName = selectedField;
      this.onChange({ type: type, fieldName: this.viewModel.locationFieldName });
    }

    onLatitudeFieldNameChange(type, selectedField) {
      this.viewModel.latitudeFieldName = selectedField;
      this.onChange({ type: type, fieldName: this.viewModel.latitudeFieldName });
    }

    onLongitudeFieldNameChange(type, selectedField) {
      this.viewModel.longitudeFieldName = selectedField;
      this.onChange({ type: type, fieldName: this.viewModel.longitudeFieldName });
    }

    onDisplayNameFieldNameChange(type, selectedField) {
      this.viewModel.displayNameFieldName = selectedField;
      this.onChange({ type: type, fieldName: this.viewModel.displayNameFieldName });
    }
  },
};

export default aclLocationSelector;
