import React, { Component } from "react";
import PropTypes from "prop-types";
import Heading from "@paprika/heading";
import Icon from "acl-ui/components/Icon";
import StringFormatter from "@viz-ui/services/formatters/stringFormatter";
import Routes from "./Utils/Routes";
import "./DataTab.scss";

export default class DataTab extends Component {
  static propTypes = {
    a11yText: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        formattedValue: PropTypes.string,
        isHtml: PropTypes.bool.isRequired,
      })
    ),
    projectId: PropTypes.number.isRequired,
    controlId: PropTypes.number.isRequired,
    controlTestId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    data: [],
  };

  getAttachmentLinks(value) {
    const fileNames = value.split(",");
    const files = fileNames.map((name, index) => {
      const filename = name.replace(/^[0-9]+-/, "");

      const { projectId, controlId, controlTestId } = this.props;
      const params = {
        projectId,
        controlId,
        controlTestId,
        id: parseInt(name, 10),
      };
      const url = Routes.questionnaireAttachmentPath(params);
      const filenameIndex = index;
      return (
        <div key={`attachment_${filenameIndex}-${filename}`}>
          <Icon type="attachment" />
          <a href={url}>{filename}</a>
        </div>
      );
    });
    return files;
  }

  getDisplayValue(dataType, isHtml, value) {
    if (dataType === "character") {
      if (isHtml) {
        return <span dangerouslySetInnerHTML={{ __html: StringFormatter.sanitizeHtml(value) }} />;
      }
      return <span>{value}</span>;
    }
    if (this.isFileOrSignature(dataType, value)) {
      return this.getAttachmentLinks(value);
    }
    return value;
  }

  getDataHtml(data) {
    return (
      <dl>
        {data.reduce((html, item, idx) => {
          return html.concat([
            <dt key={`term-${idx}`}>{this.normalizeNonBreakingSpace(item.displayName)}</dt>,
            <dd key={`desc-${idx}`}>{this.getDisplayValue(item.type, item.isHtml, item.formattedValue)}</dd>,
          ]);
        }, [])}
      </dl>
    );
  }

  isFileOrSignature(dataType, value) {
    return value && (dataType === "file" || dataType === "digital_signature");
  }

  normalizeNonBreakingSpace(content) {
    let div = document.createElement("div");
    div.innerHTML = content;
    return div.textContent;
  }

  renderData() {
    const html = this.getDataHtml(this.props.data);
    return (
      <div className="record-processing__data-tab">
        <Heading isHidden level={4}>
          {this.props.a11yText}
        </Heading>
        {html}
      </div>
    );
  }

  render() {
    const { isActive } = this.props;
    if (isActive) {
      return this.renderData();
    }
    return null;
  }
}
