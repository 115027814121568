import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import L10n from "acl-ui/components/L10n";
import i18n from "@viz-ui/i18n/i18n";
import { Tabs, Tab } from "acl-ui/components/Tabs";
import Services from "./Utils/Services";
import Header from "./Header";
import DataTab from "./DataTab";
import ActivityTab from "./ActivityTab";
import Footer from "./Footer";
import ProcessSubPanel from "./ProcessSubPanel";
import ReviewSubPanel from "./ReviewSubPanel";
import { assigneeIsInActiveGroup, focusProcessPanelTitle } from "./Utils/RecordProcessingHelpers";
import makeTrashable from "trashable";

import "./RecordProcessPanel.scss";

export default class RecordProcessingPanel extends Component {
  static propTypes = {
    isOpen: PropTypes.bool, // if the container that holds this component is open
    activeRecordId: PropTypes.number, // the active `test exception` id (the one to show the data of)
    activeRowId: PropTypes.number.isRequired, // the row # that is currently being viewed
    selectedRecords: PropTypes.arrayOf(PropTypes.number).isRequired, // which records are selected
    numSelectedRecords: PropTypes.number.isRequired, // how many records are selected
    onCheck: PropTypes.func.isRequired, // callback for when click checkbox so hands-on-table checks it off too

    projectId: PropTypes.number.isRequired,
    controlId: PropTypes.number.isRequired,
    controlTestId: PropTypes.number.isRequired,

    isFirstRecord: PropTypes.bool.isRequired, // disables the 'prev' arrow
    isLastRecord: PropTypes.bool.isRequired, // disables the 'next' arrow
    onClickPrevArrow: PropTypes.func.isRequired, // tells Visualizer to switch to the previous te_id
    onClickNextArrow: PropTypes.func.isRequired, // tells Visualizer to switch to the next te_id

    data: PropTypes.array.isRequired, // an array containing the pre-formatted data to show on the 'Data' tab
    characterFieldsData: PropTypes.arrayOf(PropTypes.object), // an array containing the pre-formatted data to show on the 'Email From Fields' Option in Send Questionnaire component.

    onProcessedRecord: PropTypes.func.isRequired, // Callback that is fired after a single record is processed.
    onDeletedRecord: PropTypes.func.isRequired, // Callback that is fired after a single record is deleted.  If null, the 'Delete' option is not visible.
    onAddedComment: PropTypes.func.isRequired,
    isAddCommentVisible: PropTypes.bool.isRequired, // Hide the form input for adding comments
    isGroupSelectorEnabled: PropTypes.bool.isRequired, // Disabled for some types of users
    isFooterVisible: PropTypes.bool.isRequired,
    isRecordCheckboxVisible: PropTypes.bool,
    isActivityTabVisible: PropTypes.bool,
    isDeleteVisible: PropTypes.bool,

    // The following are used specifically in bulk processing
    onClickReviewRecords: PropTypes.func.isRequired, // filters the hands-on-table
    onCancelReviewRecords: PropTypes.func.isRequired, // un-filters the hands-on-table
    onProcessedRecords: PropTypes.func.isRequired, // Callback that is fired after multiple records are processed
    onDeletedRecords: PropTypes.func.isRequired, // Callback that is fired after records are deleted.  If null, the 'Delete' option is not visible.
    isSelectionInversed: PropTypes.bool,
    filters: PropTypes.object, // Any filters that are active (needed when they "select all", i.e. `isSelectionInversed` is true)
  };

  static defaultProps = {
    isOpen: true,
    activeRecordId: 0,
    isSelectionInversed: false,
    isDeleteVisible: true,
    isRecordCheckboxVisible: true,
    isActivityTabVisible: true,
    filters: {},
    characterFieldsData: [],
  };

  static ME = "me";

  constructor(props) {
    super(props);

    this.statusQuestionnairePanelRef = React.createRef();

    // Used to track which tab is active
    this.ACTIVE_TAB_DATA = "data";
    this.ACTIVE_TAB_RESPONSES = "responses";
    this.ACTIVE_TAB_ACTIVITY = "activity";
    this.TABS = [this.ACTIVE_TAB_DATA, this.ACTIVE_TAB_RESPONSES, this.ACTIVE_TAB_ACTIVITY];

    this.MODE_PROCESS_REGULAR = "processOne";
    this.MODE_PROCESS_INVERSE = "processAll";

    this.MODE_DELETE_REGULAR = "deleteOne";
    this.MODE_DELETE_INVERSE = "deleteAll";

    this.state = {
      // Layout
      isLoadingSubPanel: false,
      isLoadingActivities: false,
      activeTab: this.ACTIVE_TAB_DATA,
      activities: [],
      showSendQuestionnairePanel: false,
      showQuestionnaireLimitErrorModal: false,
      errorText: "",
      processRecordSubPanelBottom: -333,
      // Bottom (process) panel
      showProcessRecordSubPanel: false,
      // Bottom (process) panel and side (review) panel
      statuses: [],
      priorities: [],
      groups: [],
      activeGroups: [],
      assignees: [],
      statusQuestionnairesStatuses: [-1],
      active: {
        statusId: "",
        priorityId: "",
        groupId: "",
        assigneeId: "",
      },
      isProcessing: false,
      // Side (review) panel
      showReviewRecordsSubPanel: false,
      statusWarningMessage: "",
      bulkComment: "",
      bulkAttachment: null,
      // Side (questionnaire) panel
      questionnaireResponse: null,
      questionnaireResponseForActiveRecord: null,
      isQuestionnairePanelOpen: true,
      bulkProcessResult: null,
      singleProcessResult: null,
      attributesForRecord: null,
      answeredTeIds: [],
      testExceptionData: {},
      // Record the original values so can tell if they've changed
      original: {
        statusId: "",
        priorityId: "",
        groupId: "",
        assigneeId: "",
      },
      // The last successfully processed data
      lastProcessed: {
        recordId: null,
        statusId: null,
        priorityId: null,
        groupId: null,
        assigneeId: null,
        comment: null,
        attachment: null,
      },
    };
  }

  componentDidUpdate() {
    this.renderQuestionnaireLimitErrorModal();
    this.renderSendQuestionnairePanel();
  }

  trashablePromises = [];

  callService(serviceFn, stateString) {
    const trashablePromise = makeTrashable(serviceFn(this.props.controlTestId));
    this.trashablePromises.push(trashablePromise);
    trashablePromise.then(response => {
      this.setState({
        [stateString]: response,
      });
    });
  }

  async setStatusQuestionnairesStatuses() {
    if (window.getStatusQuestionnaires) {
      const statusQuestionnaires = await window.getStatusQuestionnaires(this.props.projectId);
      const statusIds = statusQuestionnaires.map(q => q["status_id"]);
      this.setState({
        statusQuestionnairesStatuses: statusIds,
      });
    } else {
      console.log("window.getStatusQuestionnaires function is undefined in acl-exception ");
    }
  }

  componentWillUnmount() {
    this.trashablePromises.forEach(promise => promise.trash());
  }

  componentDidMount() {
    this.getTestException(this.props.activeRecordId);
    this.callService(Services.GetUsers, "assignees");
    this.callService(Services.GetStatuses, "statuses");
    this.callService(Services.GetPriorities, "priorities");
    this.callService(Services.GetGroups, "groups");
    this.setStatusQuestionnairesStatuses();
  }

  resetState = () => {
    this.setState({
      activeTab: this.ACTIVE_TAB_DATA,
      errorText: "",
      showProcessRecordSubPanel: false,
      showReviewRecordsSubPanel: false,
      isProcessing: false,
      statusWarningMessage: "",
      bulkComment: "",
      bulkAttachment: null,
      bulkProcessResult: null,
      singleProcessResult: null,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeRecordId !== this.props.activeRecordId) {
      this.getTestException(nextProps.activeRecordId);
    }
    if (nextProps.isOpen !== this.props.isOpen) {
      this.resetState();
      this.setState({
        isQuestionnairePanelOpen: this.state.isQuestionnairePanelOpen && nextProps.isOpen,
      });
    }
    if (
      nextProps.activeRecordId !== this.props.activeRecordId ||
      nextProps.numSelectedRecords !== this.props.numSelectedRecords
    ) {
      this.hideProcessRecordSubPanelHandler();
    }
  }

  changeTabHandler = newTab => {
    if (this.TABS.indexOf(newTab) > -1) {
      this.setState({ activeTab: newTab });
      if (this.state.activeTab !== this.ACTIVE_TAB_ACTIVITY && newTab === this.ACTIVE_TAB_ACTIVITY) {
        this.loadActivities();
      }
    }
  };

  getIsSelected() {
    return this.props.isSelectionInversed
      ? !this.props.selectedRecords.includes(this.props.activeRecordId)
      : this.props.selectedRecords.includes(this.props.activeRecordId);
  }

  getActiveAssignee() {
    const activeAssignee = this.state.assignees.filter(assignee => {
      return assignee.id === AclExceptionCurrentUser.id;
    });

    return activeAssignee;
  }

  getProjectAssignment(activeAssignee) {
    if (activeAssignee.length === 0) {
      return null;
    }

    const project_assignment = activeAssignee[0].project_assignments.find(project_assignment => {
      return project_assignment.project_id === this.props.projectId;
    });

    return project_assignment;
  }

  isNonWorkflow() {
    const singleProcess = !this.state.showReviewRecordsSubPanel;
    const nonWorkflowGroup =
      singleProcess &&
      +this.state.active.groupId &&
      this.state.groups.find(group => group.id === this.state.active.groupId) == undefined;
    const allNonWorkflow =
      !singleProcess &&
      this.state.activeGroups.some(group => +group) &&
      !this.state.groups.some(group => {
        return this.state.activeGroups.indexOf(group.id) > -1;
      });

    return nonWorkflowGroup || allNonWorkflow;
  }

  getValidAssignees() {
    const activeAssignee = this.getActiveAssignee();
    const project_assignment = this.getProjectAssignment(activeAssignee);
    const isClient = project_assignment && project_assignment.role === "client";
    const isExecutive = project_assignment && project_assignment.role === "executive";
    const singleProcess = !this.state.showReviewRecordsSubPanel;
    const allUnassignedGroups = this.state.activeGroups.every(group => !+group);
    const noUnassignedGroups = this.state.activeGroups.every(group => +group);

    if (
      // client is bulk processing with all records having an unassigned group
      (!singleProcess && allUnassignedGroups && isClient) ||
      // client is single processing and a record has unassigned group
      (singleProcess && !+this.state.active.groupId && isClient) ||
      // a non-admin user is processing a group(s) not in the workflow
      (this.isNonWorkflow() && (isClient || (isExecutive && noUnassignedGroups)))
    ) {
      return activeAssignee;
    } else {
      return this.state.assignees;
    }
  }

  loadTestExceptionData(teId) {
    const activeRecordId = teId || this.props.activeRecordId;
    if (this.state.testExceptionData.activeRecordId === activeRecordId) {
      return;
    } else {
      this.setState({ isLoadingSubPanel: true });
    }
    const { projectId, controlId, controlTestId } = this.props;
    if (this.props.isFooterVisible) {
      Services.GetTestException(projectId, controlId, controlTestId, activeRecordId)
        .then(json => {
          const newState = {
            isLoadingSubPanel: false,
            original: {
              statusId: json[0].status_id,
              priorityId: json[0].priority_id,
              groupId: this.castToInitialValue(json[0].group_id),
              assigneeId: this.castToInitialValue(json[0].user_id),
            },
          };
          newState.testExceptionData = {
            activeRecordId: activeRecordId,
            data: {}, // we dont care what record data the server returns, as it is passed in as a prop already formatted
            responses: json[0].responses,
            availableStatuses: json[0].available_statuses,
          };
          newState.active = JSON.parse(JSON.stringify(newState.original));
          this.setState(newState);

          if (this.shouldCheckQuestionnaireResponse(json[0].status_id)) {
            const options = {
              projectId,
              controlId,
              controlTestId,
              statusId: json[0].status_id,
              testExceptionIds: [activeRecordId],
            };
            this.getQuestionnaireResponse(options);
          } else {
            this.unmountStatusQuestionnairePanel();
          }
        })
        .catch(error => {
          this.setState({ isLoadingSubPanel: false });
          console.log(`getTestException error: ${error}`);
        });
    }
  }

  shouldCheckQuestionnaireResponse(statusId) {
    const statusIds = this.state.statusQuestionnairesStatuses;
    return statusIds.length > 0 && (statusIds.indexOf(statusId) !== -1 || statusIds[0] === -1);
  }

  loadActivities(teId) {
    const { projectId, controlId, controlTestId } = this.props;
    const activeRecordId = teId || this.props.activeRecordId;
    Services.GetTestExceptionComments(projectId, controlId, controlTestId, activeRecordId)
      .then(json => {
        this.setState({
          isLoadingActivities: false,
          activities: json,
        });
      })
      .catch(error => {
        this.setState({
          isLoadingActivities: false,
        });
        console.log(`getTestExceptionComments error: ${error}`);
      });
  }
  castToInitialValue(value) {
    if (value === null) {
      return "null";
    } else if (value) {
      return value;
    }
    return "";
  }

  isForActiveRecords = testExceptionIds =>
    testExceptionIds.length > 1 || testExceptionIds.indexOf(this.props.activeRecordId) > -1;

  isForInverseSelectedRecords = () => this.props.isSelectionInversed && this.state.showReviewRecordsSubPanel;

  singleOrAllForBulkMode = testExceptionIds =>
    !this.state.showReviewRecordsSubPanel ||
    testExceptionIds.sort().toString() === this.props.selectedRecords.sort().toString();

  getTestException(teId) {
    this.setState({
      isLoadingActivities: true,
    });
    if (this.state.activeTab === this.ACTIVE_TAB_ACTIVITY) {
      this.loadActivities(teId);
    }
    if (this.state.statusQuestionnairesStatuses.length > 0) {
      this.loadTestExceptionData(teId);
    }
  }

  getQuestionnaireResponse = options => {
    const { statusId, testExceptionIds } = options;
    if (this.singleOrAllForBulkMode(testExceptionIds)) {
      this.unmountStatusQuestionnairePanel();
    }

    if (statusId && testExceptionIds && this.singleOrAllForBulkMode(testExceptionIds)) {
      const requestOptions = { ...options };
      const inverse = this.isForInverseSelectedRecords();
      if (this.state.showReviewRecordsSubPanel) {
        requestOptions.filters = this.clonedFilters();
        requestOptions.inverse = inverse;
      }

      if (window.getStatusQuestionnaireResponse) {
        return window
          .getStatusQuestionnaireResponse(requestOptions)
          .then(questionnaireResponse => {
            if (this.isForActiveRecords(testExceptionIds) || inverse) {
              this.setState({
                questionnaireResponse,
                isQuestionnairePanelOpen: true,
              });
            }
          })
          .catch(error => {
            this.setState({ questionnaireResponse: null });
            console.log(`GetStatusQuestionnaireResponse error: ${error}`);
          });
      } else {
        console.log("window.getStatusQuestionnaireResponse function is undefined in acl-exception");
      }
    }
    return Promise.resolve();
  };

  getAvailableStatuses() {
    return this.state.testExceptionData.availableStatuses || [];
  }

  filterAndGetName(array, activeId) {
    if (activeId === null || activeId === "null" || activeId === "") {
      return "";
    }
    return array.filter(obj => obj.id === activeId)[0].name;
  }

  getChangedFieldsValues(result) {
    const changedFields = result.changed_fields;
    const changedFieldsValues = {};
    if ("status_id" in changedFields) {
      changedFieldsValues.status = this.filterAndGetName(this.state.statuses, changedFields.status_id);
    }
    if ("priority_id" in changedFields) {
      changedFieldsValues.priority = this.filterAndGetName(this.state.priorities, changedFields.priority_id);
    }
    if ("group_id" in changedFields) {
      changedFieldsValues.group = this.filterAndGetName(this.state.groups, changedFields.group_id);
    }
    if ("user_id" in changedFields) {
      changedFieldsValues.assignee = this.filterAndGetName(this.state.assignees, changedFields.user_id);
    }
    if (result.updated_at) {
      changedFieldsValues.updated_at = result.updated_at;
    }
    if (result.closed_at) {
      changedFieldsValues.closed_at = result.closed_at;
    }
    return changedFieldsValues;
  }

  getChangedFields() {
    const changedFields = {};
    if (this.state.active.statusId !== this.state.original.statusId) {
      changedFields.statusId = this.state.active.statusId;
    }
    if (this.state.active.priorityId !== this.state.original.priorityId) {
      changedFields.priorityId = this.state.active.priorityId;
    }
    if (this.state.active.groupId !== this.state.original.groupId) {
      changedFields.groupId = this.state.active.groupId;
    }
    if (this.state.active.assigneeId !== this.state.original.assigneeId) {
      changedFields.assigneeId = this.state.active.assigneeId;
    }
    return changedFields;
  }

  dataHasChangedSinceLastProcessed = () => {
    return (
      this.props.activeRecordId !== this.state.lastProcessed.recordId ||
      this.state.active.statusId !== this.state.lastProcessed.statusId ||
      this.state.active.priorityId !== this.state.lastProcessed.priorityId ||
      this.state.active.groupId !== this.state.lastProcessed.groupId ||
      this.state.active.assigneeId !== this.state.lastProcessed.assigneeId
    );
  };

  bulkAttachmentHasChangedSinceLastProcessed = () => {
    if (
      this.state.bulkAttachment &&
      this.state.bulkAttachment.lastModified &&
      this.state.lastProcessed.attachment &&
      this.state.lastProcessed.attachment.lastModified
    ) {
      return this.state.bulkAttachment.lastModified !== this.state.lastProcessed.attachment.lastModified;
    }
    return false;
  };

  bulkCommentHasChangedSinceLastProcessed = () => {
    return this.state.bulkComment !== this.state.lastProcessed.comment;
  };

  shouldShowProcessingCheckmark() {
    // Single record processing panel
    if (this.state.showProcessRecordSubPanel) {
      return !this.dataHasChangedSinceLastProcessed();
    }

    // Bulk processing panel
    return (
      !this.dataHasChangedSinceLastProcessed() &&
      !this.bulkCommentHasChangedSinceLastProcessed() &&
      !this.bulkAttachmentHasChangedSinceLastProcessed()
    );
  }

  updateOriginalValuesInState() {
    const changedFields = this.getChangedFields();
    const newValues = {
      ...this.state.active,
    };

    if (changedFields.statusId) {
      newValues.statusId = this.state.active.statusId;
    }

    if (changedFields.priorityId) {
      newValues.priorityId = this.state.active.priorityId;
    }

    if (changedFields.groupId) {
      newValues.groupId = this.state.active.groupId;
    }

    if (changedFields.assigneeId) {
      newValues.assigneeId = this.state.active.assigneeId;
    }
    this.setState({ original: newValues });
  }

  getQuestionnaireResponseAfterProcess = () => {
    const { projectId, controlId, controlTestId, activeRecordId, selectedRecords } = this.props;
    const testExceptionIds = this.state.showReviewRecordsSubPanel ? selectedRecords : [activeRecordId];

    const options = {
      projectId,
      controlId,
      controlTestId,
      statusId: this.state.active.statusId,
      testExceptionIds,
    };
    //TODO: Check if this request can be eliminated
    return this.getQuestionnaireResponse(options);
  };

  updateLastProcessedValuesInState() {
    const lastProcessed = {
      recordId: this.props.activeRecordId,
      statusId: this.state.active.statusId,
      priorityId: this.state.active.priorityId,
      groupId: this.state.active.groupId,
      assigneeId: this.state.active.assigneeId,
    };
    this.setState({ lastProcessed });

    if (this.state.showReviewRecordsSubPanel) {
      this.setState({
        lastProcessed: {
          ...lastProcessed,
          comment: this.state.bulkComment,
          attachment: this.state.bulkAttachment,
        },
      });
    }
  }

  userHasChangedSomething = () => {
    return (
      this.state.active.statusId !== "" ||
      this.state.active.priorityId !== "" ||
      this.state.active.groupId !== "" ||
      this.state.active.assigneeId !== ""
    );
  };

  getIsProcessingDisabled() {
    // Single record processing panel
    if (this.state.showProcessRecordSubPanel) {
      return Object.keys(this.getChangedFields()).length === 0;
    }

    // Bulk processing panel (cannot simply check 'changedFields' as it was blanked out when entering bulk mode)
    return !this.userHasChangedSomething() && this.state.bulkComment.length === 0;
  }

  getFields(bulkMode = false) {
    let fields = {};

    if (this.state.active.statusId !== "") {
      fields.statusId = this.state.active.statusId;
    }
    if (this.state.active.priorityId !== "") {
      fields.priorityId = this.state.active.priorityId;
    }

    switch (this.state.active.groupId) {
      case "null":
        fields.groupId = null;
        break;
      case "":
        //do nothing;
        break;
      default:
        fields.groupId = this.state.active.groupId;
        break;
    }

    switch (this.state.active.assigneeId) {
      case "null":
        fields.assigneeId = null;
        break;
      case "":
        //do nothing;
        break;
      default:
        fields.assigneeId = this.state.active.assigneeId;
        break;
    }

    if (bulkMode) {
      if (this.state.bulkComment.length > 0) {
        fields.comment = this.state.bulkComment;
      }
      if (this.state.bulkAttachment) {
        fields.attachment = this.state.bulkAttachment;
      }
    }

    return fields;
  }

  getUrlParams() {
    return {
      projectId: this.props.projectId,
      controlId: this.props.controlId,
      controlTestId: this.props.controlTestId,
    };
  }

  clickProcessRecordHandler = () => {
    this.setState({ errorText: "", isProcessing: true });

    return Services.ProcessTestExceptions(
      this.getUrlParams(),
      [this.props.activeRecordId],
      this.getFields(),
      this.MODE_PROCESS_REGULAR
    )
      .then(result => {
        if (result.processed.length) {
          this.getQuestionnaireResponseAfterProcess()
            .then(() => {
              const { questionnaireResponse, isQuestionnairePanelOpen } = this.state;
              if (questionnaireResponse && isQuestionnairePanelOpen) {
                result.changedValues = this.getChangedFieldsValues(result);
                this.setState({ singleProcessResult: result });
              } else {
                this.props.onProcessedRecord(
                  result.processed,
                  result.unprocessed,
                  this.getChangedFieldsValues(result),
                  result.removed_from_view,
                  null
                );
              }

              this.setState({ isProcessing: false });
              this.updateOriginalValuesInState();
              this.updateLastProcessedValuesInState();
            })
            .catch(err => console.log("getQuestionnaireResponseAfterProcess error: ", err));

          return true;
        }
        this.setState({
          errorText: i18n.t("_RecordProcessing.ProcessRecordError_"),
          isProcessing: false,
        });
        return false;
      })
      .catch(err => console.log(err));
  };

  clickDeleteRecordHandler = () => {
    return Services.DeleteTestExceptions(
      this.getUrlParams(),
      [this.props.activeRecordId],
      this.MODE_DELETE_REGULAR
    ).then(() => {
      this.props.onDeletedRecord();
    });
  };

  clickDeleteRecordsHandler = () => {
    this.props.onDeleteInProgress();
    return Services.DeleteTestExceptions(
      this.getUrlParams(),
      this.props.selectedRecords,
      this.getDeleteMode(),
      this.clonedFilters()
    ).then(result => {
      this.props.onDeletedRecords(result.processed, result.unprocessed);
    });
  };

  clickPrevArrowHandler = () => {
    this.hideProcessRecordSubPanelHandler();
    this.props.onClickPrevArrow();
  };

  clickNextArrowHandler = () => {
    this.hideProcessRecordSubPanelHandler();
    this.props.onClickNextArrow();
  };

  showProcessRecordSubPanelHandler = () => {
    // restore the active data (in case coming back from bulk mode)
    const active = {
      ...this.state.original,
    };
    // Add element first (out of viewport), then remove the bottom (which slides it into view)
    this.setState({ showProcessRecordSubPanel: true, active }, () => {
      setTimeout(() => {
        this.setState({ processRecordSubPanelBottom: null });
        document.querySelector("#common-subpanel-header-title").focus();
      }, 0);
    });
    this.loadTestExceptionData();
  };

  hideProcessRecordSubPanelHandler = () => {
    const subPanel = document.querySelector(".record-processing__process-panel");

    if (subPanel) {
      //Slide it out of viewport first (by setting bottom), then remove element after animation done
      this.setState({ processRecordSubPanelBottom: subPanel.offsetHeight * -1 }, () => {
        this.setState({ showProcessRecordSubPanel: false });
        setTimeout(() => {
          document.querySelector("#record-processing-process-records-button").focus();
        }, 300);
      });
    }
  };

  showReviewRecordsSubPanelHandler = () => {
    const project_assignment = this.getProjectAssignment(this.getActiveAssignee());

    if (project_assignment && (project_assignment.role === "client" || project_assignment.role === "executive")) {
      Services.GetGroupsForSelectedRecord(
        this.getUrlParams(),
        this.props.selectedRecords,
        this.getMode(),
        this.clonedFilters()
      ).then(result => {
        this.setState({ activeGroups: result.groups_for_records });
      });
    }

    const active = {
      statusId: "",
      priorityId: "",
      groupId: "",
      assigneeId: "",
    };

    this.unmountStatusQuestionnairePanel();
    this.setState({
      showReviewRecordsSubPanel: true,
      statusWarningMessage: "",
      questionnaireResponseForActiveRecord: this.state.questionnaireResponse,
      active,
    });

    this.props.onClickReviewRecords();

    setTimeout(() => {
      document.querySelector("#common-subpanel-header-title").focus();
    }, 0);
  };

  getMode = () => (this.props.isSelectionInversed ? this.MODE_PROCESS_INVERSE : this.MODE_PROCESS_REGULAR);

  getDeleteMode = () => (this.props.isSelectionInversed ? this.MODE_DELETE_INVERSE : this.MODE_DELETE_REGULAR);

  clickProcessRecordsHandler = () => {
    this.setState({ errorText: "", isProcessing: true });

    return Services.ProcessTestExceptions(
      this.getUrlParams(),
      this.props.selectedRecords,
      this.getFields(true),
      this.getMode(),
      this.clonedFilters()
    ).then(result => {
      if (!!result.delayed) {
        // TODO: inine questionnaire for delayed processing

        this.setState({ isProcessing: false });
        this.props.onProcessedRecords(true);
        this.updateOriginalValuesInState();
        this.updateLastProcessedValuesInState();
        return true;
      }
      if (result.processed.length) {
        this.getQuestionnaireResponseAfterProcess().then(() => {
          const { questionnaireResponse, isQuestionnairePanelOpen } = this.state;
          if (questionnaireResponse && isQuestionnairePanelOpen) {
            this.setState({ bulkProcessResult: result });
          } else {
            this.props.onProcessedRecords(false, result.processed, result.unprocessed, result.removed_from_view, false);
          }

          this.setState({ isProcessing: false });
          this.updateOriginalValuesInState();
          this.updateLastProcessedValuesInState();
        });
        return result.unprocessed.length === 0;
      }
      this.setState({
        errorText: i18n.t("_RecordProcessing.ProcessRecordError_"),
        isProcessing: false,
      });
      return false;
    });
  };

  hideReviewRecordsSubPanelHandler = () => {
    this.setState({
      showReviewRecordsSubPanel: false,
      isQuestionnairePanelOpen: true,
      questionnaireResponse: this.state.questionnaireResponseForActiveRecord,
      bulkComment: "",
      bulkAttachment: null,
    });
    this.props.onCancelReviewRecords();
    setTimeout(() => {
      document.querySelector("#record-processing-review-records-button").focus();
    }, 300);
  };

  showSendQuestionnairePanel = () => {
    this.setState({ showSendQuestionnairePanel: true });
  };

  hideSendQuestionnairePanel = () => {
    this.setState({ showSendQuestionnairePanel: false });
  };

  castToIntIfNecessary(value) {
    switch (value) {
      case "":
      case "null":
        return value;
      default:
        return +value;
    }
  }

  changeStatusHandler = e => {
    const newStatusId = this.castToIntIfNecessary(e.target.value);
    const active = {
      ...this.state.active,
      statusId: newStatusId,
    };
    this.setState({ active });

    if (this.state.showReviewRecordsSubPanel) {
      Services.GetStatusChangeCounts(
        this.getUrlParams(),
        this.props.selectedRecords,
        newStatusId,
        this.getMode(),
        this.clonedFilters()
      ).then(result => {
        if (result.processable_count < result.total_count) {
          this.setState({
            statusWarningMessage: i18n.t("_RecordProcessing.ChangeStatusWarning_", {
              processable: result.processable_count,
              total: result.total_count,
            }),
          });
        } else {
          this.setState({ statusWarningMessage: "" });
        }
      });
    }
  };

  changePriorityHandler = e => {
    const newPriorityId = this.castToIntIfNecessary(e.target.value);
    const active = {
      ...this.state.active,
      priorityId: newPriorityId,
    };
    this.setState({ active });
  };

  changeGroupHandler = e => {
    const newActiveGroupId = this.castToIntIfNecessary(e.target.value);
    const active = {
      ...this.state.active,
      groupId: newActiveGroupId,
    };
    if (
      this.state.active.assigneeId &&
      !assigneeIsInActiveGroup(this.state.groups, this.state.active.assigneeId, newActiveGroupId)
    ) {
      active.assigneeId = "";
    }
    this.setState({ active });
  };

  changeAssigneeHandler = e => {
    const newAssigneeId = this.castToIntIfNecessary(e.target.value);
    const active = {
      ...this.state.active,
      assigneeId: newAssigneeId,
    };

    this.setState({ active });
  };

  changeBulkCommentHandler = e => {
    this.setState({ bulkComment: e.target.value });
  };

  changeBulkAttachmentHandler = e => {
    this.setState({ bulkAttachment: e.target.files[0] });
  };

  removeBulkAttachmentHandler = () => {
    this.setState({ bulkAttachment: null });
  };

  sendQuestionnaireHandler = ({
    questionnaireId,
    assignType,
    assignValue,
    aggregateResponse,
    tempSubject,
    tempBody,
    supportingFiles,
  }) => {
    const questionnaire = {
      aggregate_response: aggregateResponse,
      questionnaire_id: questionnaireId,
      assign_type: assignType,
      temp_subject: tempSubject,
      temp_body: tempBody,
      supporting_files_attributes: supportingFiles,
    };

    if (assignType !== RecordProcessingPanel.ME) {
      questionnaire.assign_value = assignValue;
    }
    const selectedRecords = this.state.showProcessRecordSubPanel
      ? [this.props.activeRecordId]
      : this.props.selectedRecords;

    if (assignType === RecordProcessingPanel.ME && (selectedRecords.length === 1 || aggregateResponse)) {
      var questionnaireWindow = window.open();
    }

    const filters = this.state.showReviewRecordsSubPanel ? this.clonedFilters() : {};

    if (window.sendQuestionnaire) {
      return window
        .sendQuestionnaire({
          urlParams: this.getUrlParams(),
          selectedRecords,
          mode: this.getMode(),
          questionnaire,
          filters,
        })
        .then(response => {
          const questionnaireResponses = response.questionnaire_responses;
          if (questionnaireWindow && questionnaireResponses.length === 1) {
            const qr = questionnaireResponses[0];
            const response_url = `/questionnaire_responses/${qr.uid}/edit`;
            questionnaireWindow.location = response_url;
          } else if (questionnaireWindow) {
            questionnaireWindow.close();
          }
          this.hideSendQuestionnairePanel();
        })
        .catch(errorResponse => {
          console.log(errorResponse);
          if (questionnaireWindow) {
            questionnaireWindow.close();
          }
          if (errorResponse.errors && errorResponse.errors.control_tests_ids) {
            this.hideSendQuestionnairePanel();
            this.setState({ showQuestionnaireLimitErrorModal: true });
          }
          return new Promise((resolve, reject) => {
            reject(errorResponse);
          });
        });
    } else {
      console.log("window.sendQuestionnaire function is undefined in acl-exception");
    }
  };

  clonedFilters = () => JSON.parse(JSON.stringify(this.props.filters));

  addCommentHandler = (comment, attachment) => {
    const promise = Services.CreateTestExceptionComment(
      this.props.projectId,
      this.props.controlId,
      this.props.controlTestId,
      this.props.activeRecordId,
      AclExceptionActiveTestExceptionsCount,
      comment,
      attachment
    ).then(() => {
      this.loadActivities();
      this.props.onAddedComment(comment, attachment);
    });
    return promise;
  };

  renderFooter() {
    if (this.props.isFooterVisible) {
      return (
        <Footer
          activeRowId={this.props.activeRowId}
          numSelectedRecords={this.props.numSelectedRecords}
          onShowProcessRecordSubPanel={this.showProcessRecordSubPanelHandler}
          onShowReviewRecordsSubPanel={this.showReviewRecordsSubPanelHandler}
        />
      );
    }
    return null;
  }

  setAttributesFromQuestionnaire = (attributesForRecord, answeredTeIds) => {
    this.setState({ attributesForRecord, answeredTeIds });
  };

  unmountStatusQuestionnairePanel = () => {
    this.setState({ isQuestionnairePanelOpen: false });
    ReactDOM.unmountComponentAtNode(this.statusQuestionnairePanelRef.current);
    setTimeout(function() {
      focusProcessPanelTitle();
    }, 300);
  };

  closeInlineQuestionnaire = () => {
    this.unmountStatusQuestionnairePanel();

    const { bulkProcessResult, singleProcessResult, attributesForRecord, answeredTeIds } = this.state;

    if (bulkProcessResult || (attributesForRecord && answeredTeIds.length > 1)) {
      this.handleBulkProcessResult(bulkProcessResult);
      this.setState({ bulkProcessResult: null, answeredTeIds: [] });
    }
    if (singleProcessResult || (attributesForRecord && answeredTeIds.length == 1)) {
      this.handleSingleProcessResult(singleProcessResult, attributesForRecord, answeredTeIds);
      this.setState({ singleProcessResult: null, answeredTeIds: [] });
    }
  };

  handleBulkProcessResult = bulkProcessResult => {
    if (bulkProcessResult) {
      const { processed, unprocessed, removed_from_view } = bulkProcessResult;
      this.props.onProcessedRecords(false, processed, unprocessed, removed_from_view, true);
    } else {
      this.props.onProcessedRecords(false, [], [], [], true);
    }
  };

  handleSingleProcessResult = (singleProcessResult, attributesForRecord, answeredTeIds) => {
    if (singleProcessResult) {
      const { processed, unprocessed, changedValues, removed_from_view } = singleProcessResult;
      this.props.onProcessedRecord(processed, unprocessed, changedValues, removed_from_view, attributesForRecord);
    } else {
      this.props.onProcessedRecord(answeredTeIds, [], {}, [], attributesForRecord);
    }
  };

  isProcessingMultipleRecords = () =>
    this.state.showReviewRecordsSubPanel && (this.props.selectedRecords.length > 1 || this.props.isSelectionInversed);

  renderInlineQuestionnaire = () => {
    const { questionnaireResponse, isQuestionnairePanelOpen } = this.state;

    if (questionnaireResponse && isQuestionnairePanelOpen) {
      const props = {
        uid: questionnaireResponse.uid,
        questionnaireResponse,
        closeInlineQuestionnaire: this.closeInlineQuestionnaire,
        setAttributesFromQuestionnaire: this.setAttributesFromQuestionnaire,
        bulkProcessMode: this.isProcessingMultipleRecords(),
      };

      return window.renderStatusQuestionnairePanel(props, this.statusQuestionnairePanelRef.current);
    }
  };

  getSelectedRecordsForSendQuestionnairePanel() {
    return this.state.showReviewRecordsSubPanel ? this.props.selectedRecords : [this.props.activeRecordId];
  }

  hideQuestionnaireLinkageErrorModal = () => this.setState({ showQuestionnaireLimitErrorModal: false });

  renderTabs() {
    if (!this.props.isActivityTabVisible) return <div className="record-processing__tabs-divider" />;

    return (
      <div className="record-processing__tabs">
        <Tabs>
          <Tab
            label={i18n.t("_RecordProcessing.Tabs.Data_")}
            onClick={() => {
              this.changeTabHandler(this.ACTIVE_TAB_DATA);
            }}
            isActive={this.state.activeTab === this.ACTIVE_TAB_DATA}
          />
          <Tab
            label={i18n.t("_RecordProcessing.Tabs.Activity_")}
            onClick={() => {
              this.changeTabHandler(this.ACTIVE_TAB_ACTIVITY);
            }}
            isActive={this.state.activeTab === this.ACTIVE_TAB_ACTIVITY}
          />
        </Tabs>
      </div>
    );
  }

  renderReviewSubPanel() {
    if (!this.state.showReviewRecordsSubPanel) {
      return null;
    }

    return (
      <ReviewSubPanel
        isLoading={false}
        isProcessingDisabled={this.getIsProcessingDisabled()}
        onHide={this.hideReviewRecordsSubPanelHandler}
        onSubmit={this.clickProcessRecordsHandler}
        onDeleted={this.clickDeleteRecordsHandler}
        isProcessing={this.state.isProcessing}
        isProcessingCheckmarkVisible={this.shouldShowProcessingCheckmark()}
        statuses={this.state.statuses}
        activeStatusId={this.state.active.statusId}
        availableStatuses={this.getAvailableStatuses()}
        priorities={this.state.priorities}
        activePriorityId={this.state.active.priorityId}
        groups={this.state.groups}
        activeGroupId={this.state.active.groupId}
        activeGroups={this.state.activeGroups}
        isGroupSelectorEnabled={this.props.isGroupSelectorEnabled}
        isDeleteVisible={this.props.isDeleteVisible}
        assignees={this.getValidAssignees()}
        activeAssigneeId={this.state.active.assigneeId}
        projectAssignment={this.getProjectAssignment(this.getActiveAssignee())}
        onChangeStatus={this.changeStatusHandler}
        onChangePriority={this.changePriorityHandler}
        onChangeGroup={this.changeGroupHandler}
        onChangeAssignee={this.changeAssigneeHandler}
        onShowSendQuestionnairePanel={this.showSendQuestionnairePanel}
        errorText={this.state.errorText}
        // Extended props
        numSelectedRecords={this.props.numSelectedRecords}
        statusWarningMessage={this.state.statusWarningMessage}
        onChangeComment={this.changeBulkCommentHandler}
        comment={this.state.bulkComment}
        onChangeAttachment={this.changeBulkAttachmentHandler}
        attachment={this.state.bulkAttachment}
        onRemoveAttachment={this.removeBulkAttachmentHandler}
      />
    );
  }

  renderProcessSubPanel() {
    if (!this.state.showProcessRecordSubPanel) {
      return null;
    }

    return (
      <ProcessSubPanel
        isLoading={this.state.isLoadingSubPanel}
        isProcessingDisabled={this.getIsProcessingDisabled()}
        onHide={this.hideProcessRecordSubPanelHandler}
        onSubmit={this.clickProcessRecordHandler}
        onDeleted={this.clickDeleteRecordHandler}
        isProcessing={this.state.isProcessing}
        isProcessingCheckmarkVisible={this.shouldShowProcessingCheckmark()}
        statuses={this.state.statuses}
        activeStatusId={this.state.active.statusId}
        availableStatuses={this.getAvailableStatuses()}
        priorities={this.state.priorities}
        activePriorityId={this.state.active.priorityId}
        groups={this.state.groups}
        activeGroupId={this.state.active.groupId}
        isGroupSelectorEnabled={this.props.isGroupSelectorEnabled}
        isDeleteVisible={this.props.isDeleteVisible}
        assignees={this.getValidAssignees()}
        activeAssigneeId={this.state.active.assigneeId}
        onChangeStatus={this.changeStatusHandler}
        onChangePriority={this.changePriorityHandler}
        onChangeGroup={this.changeGroupHandler}
        onChangeAssignee={this.changeAssigneeHandler}
        onShowSendQuestionnairePanel={this.showSendQuestionnairePanel}
        errorText={this.state.errorText}
        // Extended props
        activeRowId={this.props.activeRowId}
        height={this.state.processRecordSubPanelBottom}
        statusIdFromDb={this.state.original.statusId}
      />
    );
  }

  renderSendQuestionnairePanel() {
    const element = document.getElementsByClassName("send-questionnaire-panel")[0];
    const sendQuestionnairePanelProps = {
      isVisible: this.state.showSendQuestionnairePanel,
      onHide: this.hideSendQuestionnairePanel,
      projectId: this.props.projectId,
      controlId: this.props.controlId,
      controlTestId: this.props.controlTestId,
      data: this.props.data,
      characterFieldsData: this.props.characterFieldsData,
      onSend: this.sendQuestionnaireHandler,
      assignees: this.state.assignees,
      selectedRecords: this.getSelectedRecordsForSendQuestionnairePanel(),
      isSelectionInversed: this.props.isSelectionInversed,
    };
    return window.renderSendQuestionnairePanel(sendQuestionnairePanelProps, element);
  }

  renderQuestionnaireLimitErrorModal() {
    const element = document.getElementsByClassName("questionnaire-limit-error-moda")[0];
    const props = {
      isOpen: this.state.showQuestionnaireLimitErrorModal,
      onClose: this.hideQuestionnaireLinkageErrorModal,
    };

    return window.renderQuestionnaireLimitErrorModal(props, element);
  }

  render() {
    return (
      <L10n locale={i18n.locale}>
        <div className="record-processing-wrapper">
          <div ref={this.statusQuestionnairePanelRef}>{this.renderInlineQuestionnaire()}</div>
          <div className="record-processing-panel">
            {!this.state.showReviewRecordsSubPanel ? (
              <>
                <div className="record-processing__body">
                  <Header
                    isChecked={this.getIsSelected()}
                    isCheckboxVisible={this.props.isRecordCheckboxVisible}
                    onCheck={() => {
                      this.props.onCheck(this.props.activeRecordId);
                    }}
                    activeRowId={this.props.activeRowId}
                    isFirstRecord={this.props.isFirstRecord}
                    isLastRecord={this.props.isLastRecord}
                    onClickPrevArrow={this.clickPrevArrowHandler}
                    onClickNextArrow={this.clickNextArrowHandler}
                  />

                  {this.renderTabs()}

                  <div className="record-processing__tab-content__scroll-wrapper" tabindex="0">
                    <div className="record-processing__tab-content">
                      <DataTab
                        a11yText={i18n.t("_RecordProcessing.Tabs.Content.Data_")}
                        isActive={this.state.activeTab === "data"}
                        data={this.props.data}
                        projectId={this.props.projectId}
                        controlId={this.props.controlId}
                        controlTestId={this.props.controlTestId}
                      />
                      <ActivityTab
                        a11yText={i18n.t("_RecordProcessing.Tabs.Content.Activity_")}
                        isActive={this.state.activeTab === this.ACTIVE_TAB_ACTIVITY}
                        isAddCommentVisible={this.props.isAddCommentVisible}
                        isLoading={this.state.isLoadingActivities}
                        activities={this.state.activities}
                        onAddedComment={this.addCommentHandler}
                      />
                    </div>
                  </div>
                </div>
                {this.renderFooter()}
                {this.renderProcessSubPanel()}
              </>
            ) : null}
            {this.renderReviewSubPanel()}
          </div>
        </div>
      </L10n>
    );
  }
}
