/**
 * (Note that this acl-checkbox-input directive is separate from the acl-checklist-input /acl-checklist-checkbox-input pair of directives)
 */
angular.module("acl.common.input").directive("aclCheckboxInput", function() {
  return {
    restrict: "E",
    replace: true,
    scope: {
      value: "@value",
      label: "@label",
      cssClass: "@cssClass",
      onClick: "&onClick",
    },
    template:
      '<div class="aclviz-checkbox {{cssClass}}" ng-click="onClick()">' +
      '<i class="icon-check-empty" ng-hide="value===\'true\'"></i>' +
      '<i class="icon-check" ng-show="value===\'true\'"></i>' +
      '<span class="checkbox-label" value-checked="{{value}}">{{label}}</span>' +
      "</div>",
  };
});
