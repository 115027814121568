import PropTypes from "prop-types";
import React from "react";
import SidePanel from "@paprika/sidepanel";
import Button from "acl-ui/components/Button";
import Spinner from "acl-ui/components/Spinner";
import i18n from "@viz-ui/i18n/i18n";
import ListBox from "@paprika/list-box";
import Checkbox from "@paprika/checkbox";
import NotepadExclamation from "@acl-services/wasabicons/lib/NotepadExclamation";
import Clipboard from "@acl-services/wasabicons/lib/Clipboard";
import Table from "@acl-services/wasabicons/lib/Table";
import "./copyInterpretation.scss";

const { CHECKED, UNCHECKED } = Checkbox.types.state;

const TableType = {
  DataAnalytic: "DataTables::DataAnalytic",
  Survey: "DataTables::Survey",
  EventReport: "DataTables::EventReport",
};

export default class CopyInterpretation extends React.Component {
  static propTypes = {
    isCopyInterpretationOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onCopyInterpretation: PropTypes.func,
    collectionList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        type: PropTypes.string,
        childItems: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            controlId: PropTypes.number,
            projectId: PropTypes.number,
            type: PropTypes.string,
          })
        ),
      })
    ),
  };

  static defaultProps = {
    onClose: () => {},
    onCopyInterpretation: () => {},
    collectionList: [],
    isCopyInterpretationOpen: false,
  };

  constructor(props) {
    super(props);
    this.sidePanelWidth = "100%";
    this.state = {
      enableCopyButton: false,
      selectedConfig: [],
    };
    this.handleListBoxChange = this.handleListBoxChange.bind(this);
    this.refHeading = React.createRef();
  }

  handlePanelAfterOpen = () => {
    if (this.refHeading.current) this.refHeading.current.focus();
  };

  handleCopyInterpretation = () => {
    this.props.onCopyInterpretation({
      selectedConfig: this.state.selectedConfig,
    });
  };

  handleClose = () => {
    this.props.onClose();
    this.setState({ enableCopyButton: false });
  };

  handleListBoxChange = (selectedIndex, options) => {
    this.setState({
      enableCopyButton: selectedIndex.length > 0,
      selectedConfig: selectedIndex.map(index => {
        const option = options[index].content.props;
        return {
          projectId: option.projectId,
          controlId: option.controlId,
          controlTestId: option.controlTestId,
          name: option.label,
          type: option.type,
        };
      }),
    });
  };

  renderFooter() {
    return (
      <span>
        <Button
          kind="primary"
          type="primary"
          onClick={this.handleCopyInterpretation}
          data-testid="copy_interpretation_copy_button"
          isDisabled={!this.state.enableCopyButton}
        >
          {i18n.t("_CopyInterpretation.Copy.Button.Label_")}
        </Button>
        <Button kind="default" type="minor" onClick={this.handleClose}>
          {i18n.t("_General.Cancel.Label_")}
        </Button>
      </span>
    );
  }

  renderItems(items) {
    return (
      <ListBox.Option
        projectId={items.projectId}
        controlId={items.controlId}
        controlTestId={items.id}
        type={items.type}
        label={items.name}
        key={items.id}
        data-testid={items.id}
      >
        {({ isSelected }) => (
          <span className="copy-interpretation__panel-items">
            <Checkbox
              checkedState={isSelected ? CHECKED : UNCHECKED}
              style={{ paddingRight: "8px", pointerEvents: "none" }}
              size={Checkbox.types.size.MEDIUM}
              a11yText={items.name}
            />
            {this.renderTableIcon(items.type)}
            <span className="copy-interpretation__panel-items-title">{items.name}</span>
          </span>
        )}
      </ListBox.Option>
    );
  }

  renderTableIcon = tableType => {
    switch (tableType) {
      case TableType.DataAnalytic:
        return <Table height={22} width={22} />;
      case TableType.Survey:
        return <Clipboard height={22} width={22} />;
      case TableType.EventReport:
        return <NotepadExclamation height={22} width={22} />;
    }
  };

  renderContent() {
    return this.props.collectionList.length === 0 ? (
      <div className="copy-interpretation__loading">
        <Spinner />
      </div>
    ) : (
      <ListBox
        isMulti
        isInline
        height={434}
        size="small"
        style={{ border: "none" }}
        onChange={this.handleListBoxChange}
      >
        <ListBox.Trigger isHidden></ListBox.Trigger>
        <ListBox.Filter
          hasClearButton={false}
          placeholder={i18n.t("_CopyInterpretation.Filter.Placeholder_")}
          noResultsMessage={i18n.t("_General.NoMatches.Label_")}
        />
        {this.props.collectionList.map(collection => (
          <React.Fragment key={collection.id}>
            <ListBox.Divider>{collection.name}</ListBox.Divider>
            {collection.childItems.map(childItem => this.renderItems(childItem))}
          </React.Fragment>
        ))}
      </ListBox>
    );
  }

  render() {
    return (
      <SidePanel
        isInline
        isOpen={this.props.isCopyInterpretationOpen}
        onAfterOpen={this.handlePanelAfterOpen}
        onClose={this.handleClose}
        width={this.sidePanelWidth}
        className="copy-interpretation__panel"
      >
        <SidePanel.Header isSticky className="copy-interpretation__panel-header" refHeading={this.refHeading}>
          {i18n.t("_ActionButtons.CopyInterpretation.Label_")}
        </SidePanel.Header>
        <SidePanel.Content className="copy-interpretation__panel-Content">{this.renderContent()}</SidePanel.Content>
        <SidePanel.Footer isSticky>{this.renderFooter()}</SidePanel.Footer>
      </SidePanel>
    );
  }
}
