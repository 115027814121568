import { StoryboardBackend } from "../storyboard/storyboard.backend";

class StoryboardDataStore {
  constructor() {
    this._id = undefined;
    this._uid = undefined;
    this._name = "";
    this._config = { positions: [], panels: [] };

    this._metricsMap = null;
    this._interpretationsMap = null;
  }

  setData({ id, uid, name, config }) {
    this._id = id;
    this._uid = uid;
    this._name = name;
    this._config = config;
  }

  async fetchBoardSelectorData() {
    if (!this._metricsMap && !this._interpretationsMap) {
      const boardSelectorData = await StoryboardBackend.getBoardSelectorMaps();
      const { project_metrics: metricsMap, project_interpretations_map: interpretationsMap } = boardSelectorData;
      this._metricsMap = metricsMap;
      this._interpretationsMap = interpretationsMap;
    }
  }

  getId() {
    return this._id;
  }

  getName() {
    return this._name;
  }

  getConfig() {
    return this._config;
  }

  getUid() {
    return this._uid;
  }

  getMetricsMap() {
    return this._metricsMap;
  }

  getInterpretationsMap() {
    return this._interpretationsMap;
  }
}

export default new StoryboardDataStore();
