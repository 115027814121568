const localizedLanguages = ["de", "en", "es", "fr", "ja", "ko", "pl", "pt", "zh", "it", "nl"];

export default {
  loadLanguages: supportedLangs => {
    //Require must to be explict so webpack figures out exactly what part of the module to bundle
    require("../../../../node_modules/angular-i18n/angular-locale_de");
    require("../../../../node_modules/angular-i18n/angular-locale_en");
    require("../../../../node_modules/angular-i18n/angular-locale_es");
    require("../../../../node_modules/angular-i18n/angular-locale_fr");
    require("../../../../node_modules/angular-i18n/angular-locale_ja");
    require("../../../../node_modules/angular-i18n/angular-locale_ko");
    require("../../../../node_modules/angular-i18n/angular-locale_pl");
    require("../../../../node_modules/angular-i18n/angular-locale_pt");
    require("../../../../node_modules/angular-i18n/angular-locale_zh");
    require("../../../../node_modules/angular-i18n/angular-locale_it");
    require("../../../../node_modules/angular-i18n/angular-locale_nl");

    const allSupportedLanguagesLocalized = supportedLangs.every(lang => localizedLanguages.includes(lang));

    if (!allSupportedLanguagesLocalized) {
      throw "webpack hints need to change with supported language";
    }
  },
};
