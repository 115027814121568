angular
  .module("acl.visualizer.dataMessage", ["acl.common.localize", "acl.visualizer.charts", "acl.visualizer.dataModel"])
  .factory("DataMessage", (ChartService, Localize) => {
    const dataMessage = {
      getNoDataMessage: getNoDataMessage,
    };

    function chartDataOverlimit(dataRecordsSize) {
      return dataRecordsSize > ChartService.getDataItemLimit();
    }

    function getNoDataMessage(tableRecordCount, dataRecordsSize) {
      if (!tableRecordCount) {
        return Localize.getLocalizedString("_Table.NoDataAvailable.Label_");
      } else if (dataRecordsSize && chartDataOverlimit(dataRecordsSize)) {
        return Localize.getLocalizedString("_Chart.DataSetTooLarge.Label_");
      } else if (dataRecordsSize === 0) {
        return Localize.getLocalizedString("_Table.NoFilterDataAvailable.Label_");
      }
      return null;
    }

    return dataMessage;
  });
