/* eslint no-undef: 0*/
import { visualizerAndMetricsFlippers } from "@results/flippers";
import { coneService } from "@results/services/apiPath/coneService";
import { ENV, Environments } from "../../config/environments";
import integrations from "../../config/integrations";

const appConfig = {
  environment: ENV,
  environments: Environments,
  get project_id() {
    return window.AclExceptionProjectId;
  },
  get control_id() {
    return window.AclExceptionControlId;
  },
  get control_test_id() {
    return window.AclExceptionControlTestId;
  },
  get currentUser() {
    return window.AclExceptionCurrentUser;
  },
  get timezoneOffsetSeconds() {
    return window.AclExceptionTimezoneSecOffset;
  },
  get aclExceptionControlTestInterpretationId() {
    return window.AclExceptionControlTestInterpretationId;
  },
  get pageHeaderProps() {
    return window.AclExceptionPageHeaderProps;
  },

  baseUrl: {
    get api() {
      return window.cone.projectControlControlTestInterpretationsPath({
        project_id: window.AclExceptionProjectId,
        control_id: window.AclExceptionControlId,
        control_test_id: window.AclExceptionControlTestId,
      });
    },
    static: "/",
  },
  get exportToCsvUrl() {
    return (
      window.cone.projectControlControlTestInterpretationsPath({
        project_id: window.AclExceptionProjectId,
        control_id: window.AclExceptionControlId,
        control_test_id: window.AclExceptionControlTestId,
      }) + "/export_csv"
    );
  },
  get exportToXlsUrl() {
    return (
      window.cone.projectControlControlTestInterpretationsPath({
        project_id: window.AclExceptionProjectId,
        control_id: window.AclExceptionControlId,
        control_test_id: window.AclExceptionControlTestId,
      }) + "/export_xls"
    );
  },
  getAttachmentUrl(id) {
    return window.cone.downloadProjectControlControlTestExceptionAttachmentPath({
      project_id: window.AclExceptionProjectId,
      control_id: window.AclExceptionControlId,
      control_test_id: window.AclExceptionControlTestId,
      id: id,
    });
  },

  application: {
    name: "HighBond",
    title: " - HighBond",
  },

  features: visualizerAndMetricsFlippers(),
  airbrake: integrations.airbrake[ENV],
  //FIXME: This isn't being used. Should come from environment.
  queryUrl: "https://query-us2.aclgrc-s1.com/graphql",
  querySlug() {
    return undefined;
  },

  get aclVisualizerFeatureToggles() {
    return window.AclVisualizerFeatureToggles;
  },

  supportedQueryParams: ["saved", "returnUrl", "storyboard_id"],

  // TODO: supportedLanguages required?
  supportedLanguages: ["de", "es", "en", "fr", "pt", "zh", "ja", "it", "nl"],

  systemFields: ["metadata.priority", "metadata.status", "metadata.assignee"],

  // TODO: dictionary_** required?
  dictionary_de: ["cmn_msg_de", "dv_msg_de"],
  dictionary_en: ["cmn_msg_en", "dv_msg_en"],
  dictionary_fr: ["cmn_msg_fr", "dv_msg_fr"],
  dictionary_es: ["cmn_msg_es", "dv_msg_es"],
  dictionary_pt: ["cmn_msg_pt", "dv_msg_pt"],
  dictionary_zh: ["cmn_msg_zh", "dv_msg_zh"],
  dictionary_ja: ["cmn_msg_ja", "dv_msg_ja"],
  dictionary_it: ["cmn_msg_it", "dv_msg_it"],
  dictionary_nl: ["cmn_msg_nl", "dv_msg_nl"],

  data_item_limits: {
    Chrome: 30000,
    Safari: 30000,
    default: 5000,
  },

  language: window.currentLocale,

  timeout: 30000,
};

appConfig.coneService = coneService;
appConfig.projectUrl = appConfig.coneService.projectPath({ id: appConfig.project_id });
appConfig.features = { ...appConfig.features, ...appConfig.aclVisualizerFeatureToggles };

setTimeout(() => {
  if (window.AclException) appConfig.ability = window.AclException.current_ability;
}, 0);

export default appConfig;
