import cloneDeep from "lodash/cloneDeep";

export default class MetricDataStore {
  static _metrics = new Map();
  static _metricErrors = new Map();
  static _sparklineData = new Map();

  static addMetric(metricId, metric) {
    this._metrics.set(metricId, metric);
  }

  static getMetric(metricId) {
    if (this._metricErrors.has(metricId)) return this._metricErrors.get(metricId);
    return this._metrics.get(metricId);
  }

  static addMetricError(metricId, error) {
    this._metricErrors.set(metricId, error);
  }

  static addSparklineData(metricId, data) {
    this._sparklineData.set(metricId, data);
  }

  static getSparklineData(metricId) {
    return this._sparklineData.get(metricId);
  }

  static resetData() {
    this._sparklineData = new Map();
  }
}
