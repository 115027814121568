import chartService from "@viz-ui/services/charts/chartService";
import {
  getXAxisDataPointsInAscOrder,
  getXAxisDataPointsInCustomOrder,
  sortChartDataByCustomOrder,
} from "../../../components/charts/services/highChartDataSorter";

class LineChartService extends chartService {
  defaultDisplayConfig = () => ({
    showLegend: true,
    displayDataLabels: true,
    smoothEdged: false,
    enableBoost: true,
    interpolate: false,
    xAxis: {
      showLabel: true,
    },
    yAxis: {
      showLabel: true,
      minimum: null,
      maximum: null,
    },
  });

  getChartDirectiveConfig = (interpretationId, chartConfig) => {
    const getColorByField = () =>
      (chartConfig &&
        chartConfig.dataConfig &&
        chartConfig.dataConfig.chartSeries &&
        chartConfig.dataConfig.chartSeries.fieldName) ||
      "";

    const valueFormatter = (valueFormatterInterpretationId, valueFormatterChartConfig) => {
      const { vizId, dataConfig, displayConfig } = valueFormatterChartConfig;
      if (displayConfig.valueFormattingOptions && dataConfig.chartValue.aggregationType === "average") {
        const chartValueFieldName = `${vizId}-chart-value`;
        const chartValueFieldType = "numeric";
        return this.getValueFormatter(valueFormatterInterpretationId, chartValueFieldName, chartValueFieldType);
      }
      return this.getValueFormatter(
        valueFormatterInterpretationId,
        dataConfig.chartValue.fieldName,
        dataConfig.chartValue.type
      );
    };
    /* eslint-disable */
    return {
      hasMultipleSeries: !!(chartConfig.dataConfig.chartSeries && chartConfig.dataConfig.chartSeries.fieldName),
      showLegend: chartConfig.displayConfig.showLegend,
      displayDataLabels: chartConfig.displayConfig.displayDataLabels,
      smoothEdged: chartConfig.displayConfig.smoothEdged,
      enableBoost: chartConfig.displayConfig.enableBoost,
      interpolate: chartConfig.displayConfig.interpolate,
      order: chartConfig.displayConfig.order,
      valuesOrder: chartConfig.displayConfig.valuesOrder,
      xAxis: {
        label: chartConfig.displayConfig.xAxis.showLabel
          ? chartConfig.displayConfig.xAxis.title
            ? chartConfig.displayConfig.xAxis.title
            : chartConfig.dataConfig.chartXAxis.displayName
          : "",
        tickFormatter: this.getValueFormatter(
          interpretationId,
          chartConfig.dataConfig.chartXAxis.fieldName,
          chartConfig.dataConfig.chartXAxis.type
        ),
        title: chartConfig.displayConfig.xAxis.title,
        axisType: chartConfig.dataConfig.chartXAxis.type,
        fieldType: chartConfig.dataConfig.chartXAxis.type,
      },
      yAxis: {
        label: chartConfig.displayConfig.yAxis.showLabel
          ? chartConfig.displayConfig.yAxis.title
            ? chartConfig.displayConfig.yAxis.title
            : this.getFieldLabel(chartConfig.dataConfig.chartValue)
          : "",
        tickFormatter: valueFormatter(interpretationId, chartConfig),
        title: chartConfig.displayConfig.yAxis.title,
        minimum: chartConfig.displayConfig.yAxis.minimum,
        maximum: chartConfig.displayConfig.yAxis.maximum,
      },
      DATA_ITEM_LIMIT: this.getDataItemLimit(),
      colorByField: getColorByField(),
      labelFormatter: this.getLabelFormatter(chartConfig, interpretationId),
    };
    /* eslint-enable */
  };

  populateChartConfigColumnDefs = chartConfigColumnDefs => {
    let tempColumnDef;
    const fields = this.DataModel.table.fields();
    if (fields) {
      for (const fieldName in fields) {
        tempColumnDef = {};
        tempColumnDef.displayName = fields[fieldName].displayName;
        tempColumnDef.fieldId = fields[fieldName].colId;
        tempColumnDef.fieldName = fieldName;
        tempColumnDef.type = fields[fieldName].type;

        switch (tempColumnDef.type) {
          case "character":
          case "logical":
            if (this.AppConfig.features.logicalFieldsInCharts || tempColumnDef.type === "character") {
              chartConfigColumnDefs.chartSeries.push(tempColumnDef);
              chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
            }
            break;

          case "numeric":
            chartConfigColumnDefs.chartValue.push(tempColumnDef);
            chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
            break;

          case "date":
          /* Falls through */
          case "datetime":
          /* Falls through */
          case "time":
            chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
            break;
        }
      }
    }
    return chartConfigColumnDefs;
  };

  chartConfigColumnDefs = () => ({
    chartSeries: [],
    chartValue: [],
    chartXAxis: [],
  });

  isValidDataConfig = dataConfig =>
    !!(
      dataConfig &&
      dataConfig.chartXAxis &&
      dataConfig.chartValue &&
      ((dataConfig.chartValue.aggregationType && dataConfig.chartValue.fieldName) ||
        dataConfig.chartValue.aggregationType === "count")
    );

  reorderXAxisDataPoints = (chartData, chartConfig) => {
    const {
      order,
      valuesOrder,
      xAxis: { fieldType },
    } = chartConfig;

    if (!order || order === "default") {
      return getXAxisDataPointsInAscOrder(chartData, fieldType);
    }
    if (order === "custom") {
      return getXAxisDataPointsInCustomOrder(chartData, valuesOrder, fieldType);
    }
  };

  reorderValues = (chartData, chartConfig) => {
    const {
      order,
      valuesOrder,
      xAxis: { fieldType },
    } = chartConfig;
    switch (order) {
      case "custom":
        return sortChartDataByCustomOrder(chartData, valuesOrder, fieldType);
      default:
        return chartData.slice(0);
    }
  };

  getFilters = (keyArray, chartConfig) => {
    const xFormatter = this.getFormatterForType(chartConfig.dataConfig.chartXAxis.type);
    const filters = [];
    if (keyArray.length > 1) {
      const seriesFormatter = this.getFormatterForType(chartConfig.dataConfig.chartSeries.type);
      filters.push({ field: chartConfig.dataConfig.chartSeries, value: seriesFormatter(keyArray[0]) });
    }
    filters.push({
      field: chartConfig.dataConfig.chartXAxis,
      value: xFormatter(keyArray[keyArray.length - 1]),
    });
    return filters;
  };
}

export default LineChartService;
