import React, { Component } from "react";
import i18n from "@viz-ui/i18n/i18n";
import PropTypes from "prop-types";
import Button from "acl-ui/components/Button";
import Icon from "acl-ui/components/Icon";
import Toast from "acl-ui/components/Toast";
import Tokens from "acl-ui/components/helpers/tokens.json";
import Popover from "acl-ui/components/Popover";
import RawButton from "@paprika/raw-button";
import Heading from "@paprika/heading";
import TrashBin from "@acl-services/wasabicons/lib/Trashbin";
import DeleteButton from "./DeleteButton";
import { assigneeIsInActiveGroup, assigneeIsInActiveGroups } from "./Utils/RecordProcessingHelpers";
import "./CommonSubPanel.scss";

export default class CommonSubPanel extends Component {
  static propTypes = {
    a11yText: PropTypes.string,
    activeRowId: PropTypes.number,
    statuses: PropTypes.arrayOf(PropTypes.object).isRequired,
    activeStatusId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChangeStatus: PropTypes.func.isRequired,
    availableStatuses: PropTypes.arrayOf(PropTypes.number).isRequired,
    statusWarningMessage: PropTypes.string,
    statusIdFromDb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    priorities: PropTypes.arrayOf(PropTypes.object).isRequired,
    activePriorityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChangePriority: PropTypes.func.isRequired,

    groups: PropTypes.arrayOf(PropTypes.object).isRequired,
    activeGroupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    activeGroups: PropTypes.arrayOf(PropTypes.number),
    onChangeGroup: PropTypes.func.isRequired,
    isGroupSelectorEnabled: PropTypes.bool,

    assignees: PropTypes.arrayOf(PropTypes.object).isRequired,
    activeAssigneeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    projectAssignment: PropTypes.object,
    onChangeAssignee: PropTypes.func.isRequired,

    isProcessingDisabled: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDeleted: PropTypes.func.isRequired,
    isProcessing: PropTypes.bool,
    isProcessingCheckmarkVisible: PropTypes.bool,
    onShowSendQuestionnairePanel: PropTypes.func.isRequired,
    errorText: PropTypes.string,

    isDeleteVisible: PropTypes.bool,
    deleteButtonMenuAlign: PropTypes.string,

    minimizeIcon: PropTypes.string,
    minimizeIconSize: PropTypes.number,
    minimizeText: PropTypes.string,

    numSelectedRecords: PropTypes.number,
    comment: PropTypes.string,
    onChangeComment: PropTypes.func,
    attachment: PropTypes.object,
    onChangeAttachment: PropTypes.func,
    onRemoveAttachment: PropTypes.func,

    isReviewMode: PropTypes.bool,
  };

  static defaultProps = {
    a11yText: null,
    activeRowId: 0,
    activeStatusId: "",
    availableStatuses: [],
    statusIdFromDb: "",
    activePriorityId: "",
    activeGroupId: "",
    activeGroups: [],
    activeAssigneeId: "",
    isGroupSelectorEnabled: true,
    isDeleteVisible: true,

    deleteButtonMenuAlign: "top",
    minimizeIcon: "arrow-down",
    minimizeIconSize: 12,
    minimizeText: "",

    numSelectedRecords: null,
    statusWarningMessage: "",
    comment: "",
    attachment: null,
    errorText: "",
    isProcessingDisabled: false,
    isProcessing: false,
    isProcessingCheckmarkVisible: false,

    isReviewMode: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
      isProcessingComplete: false,
      showStatusPopover: false,
    };
  }

  handleClickProcess = () => {
    this.props.onSubmit();
  };

  handleKeyDownHeader = event => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      this.props.onHide();
      const { isReviewMode } = this.props;
      setTimeout(() => {
        if (isReviewMode) document.querySelector("#record-processing-review-records-button").focus();
        else document.querySelector("#record-processing-process-records-button").focus();
      }, 100);
    }
  };

  setFileUploadComponent(component) {
    this.fileUploadComponent = component;
  }

  openFileDialog() {
    this.fileUploadComponent.click();
  }

  renderBlankOption = (override = false) => {
    return this.props.isReviewMode || override ? <option value="" /> : null;
  };

  renderUnassignedOption() {
    return <option value="null">— {i18n.t("_RecordProcessing.unassigned_")} —</option>;
  }

  shouldIncludeStatus = status =>
    this.props.availableStatuses.indexOf(status.id) > -1 || this.props.statusIdFromDb === status.id;

  renderStatusSelector() {
    let statusFound = false;
    const options = [];
    this.props.statuses.forEach(status => {
      if (status.id == this.props.activeStatusId) {
        statusFound = true;
      }
      if (this.props.isReviewMode || this.shouldIncludeStatus(status)) {
        options.push(
          <option key={`status-${status.id}`} value={status.id}>
            {status.name}
          </option>
        );
      }
    });

    return (
      <select
        disabled={this.props.isLoading || this.props.isProcessing}
        id="status"
        value={this.props.activeStatusId || ""}
        onChange={this.props.onChangeStatus}
        ref={this.selectInput}
      >
        {this.renderBlankOption(!statusFound)}
        {options}
      </select>
    );
  }

  renderStatusWarning() {
    if (this.props.statusWarningMessage.length > 0) {
      return (
        <div className="record-processing__subpanel__row record-processing__status-warning">
          <div>
            <Icon type="caution" color={Tokens.color.yellow} />
            <span>{this.props.statusWarningMessage}</span>
          </div>
        </div>
      );
    }
    return null;
  }

  renderPrioritiesSelector() {
    const options = this.props.priorities.map(priority => (
      <option key={`priority-${priority.id}`} value={priority.id}>
        {priority.name}
      </option>
    ));

    return (
      <select
        disabled={this.props.isLoading || this.props.isProcessing}
        id="priority"
        value={this.props.activePriorityId}
        onChange={this.props.onChangePriority}
      >
        {this.renderBlankOption()}
        {options}
      </select>
    );
  }

  renderGroupsSelector() {
    let groupFound = false;
    const options = [];

    this.props.groups.forEach(group => {
      if (this.props.activeGroupId === "null" || group.id == this.props.activeGroupId) {
        groupFound = true;
      }
      options.push(
        <option key={`group-${group.id}`} value={group.id}>
          {group.name}
        </option>
      );
    });

    return (
      <select
        disabled={
          this.props.isLoading ||
          this.props.isProcessing ||
          !this.props.isGroupSelectorEnabled ||
          this.props.groups.length === 0
        }
        id="group"
        value={this.props.activeGroupId}
        onChange={this.props.onChangeGroup}
      >
        {this.renderBlankOption(!groupFound)}
        {this.renderUnassignedOption()}
        {options}
      </select>
    );
  }

  isValidAssignee(assigneeId) {
    if (this.props.activeGroups.length > 0) {
      return assigneeIsInActiveGroups(
        this.props.groups,
        assigneeId,
        this.props.activeGroups,
        this.props.projectAssignment.role
      );
    } else {
      return assigneeIsInActiveGroup(this.props.groups, assigneeId, this.props.activeGroupId);
    }
  }

  renderAssigneesSelector() {
    const options = this.props.assignees
      .filter(assignee => this.isValidAssignee(assignee.id))
      .map(assignee => (
        <option key={`assignee-${assignee.id}`} value={assignee.id}>
          {assignee.name}
        </option>
      ));

    return (
      <select
        disabled={this.props.isLoading || this.props.isProcessing}
        id="assignee"
        value={this.props.activeAssigneeId}
        onChange={this.props.onChangeAssignee}
      >
        {this.renderBlankOption()}
        {this.renderUnassignedOption()}
        {options}
      </select>
    );
  }

  renderSubmitButtonText() {
    const text = i18n.t("_RecordProcessing.save_");
    if (this.props.isProcessingCheckmarkVisible) {
      return (
        <span>
          <Icon type="check" color={Tokens.color.blackLighten40} />
          {text}
        </span>
      );
    }

    return text;
  }

  renderRecordCount() {
    if (this.props.numSelectedRecords) {
      return <h3>{i18n.t("_RecordProcessing.RecordCounts_", { count: this.props.numSelectedRecords })}</h3>;
    }
    return null;
  }

  renderAttachmentName() {
    return this.props.attachment ? this.props.attachment.name : i18n.t("_RecordProcessing.AddAttachment_");
  }

  renderRemoveAttachment() {
    if (this.props.attachment) {
      return (
        <RawButton
          className="record-processing__remove-attachment"
          a11yText={i18n.t("_RecordProcessing.RemoveAttachment_")}
          onClick={this.props.onRemoveAttachment}
        >
          <TrashBin />
        </RawButton>
      );
    }
    return null;
  }

  handleKeyboardAddAttachment = e => {
    if (e.key === "Enter" || e.key === " ") {
      this.openFileDialog();
    }
  };

  handleClickAddAttachment = e => {
    e.preventDefault();
    this.openFileDialog();
  };

  renderCommentSection() {
    if (this.props.onChangeComment) {
      return (
        <div className="record-processing__subpanel__row">
          <div className="record-processing-subpanel__label">
            <label htmlFor="comment">{i18n.t("_RecordProcessing.Comment_")}:</label>
          </div>
          <div className="record-processing__add-comment is-focused">
            <textarea
              id="comment"
              disabled={this.props.isLoading || this.props.isProcessing}
              onChange={this.props.onChangeComment}
              value={this.props.comment}
            />
            <footer>
              <Icon type="attachment" />
              <label
                htmlFor="attachment"
                tabIndex="0"
                onKeyUp={this.handleKeyboardAddAttachment}
                className="file-wrapper"
                onClick={this.handleClickAddAttachment}
              >
                {this.renderAttachmentName()}
              </label>
              {this.renderRemoveAttachment()}
              <input
                id="attachment"
                type="file"
                ref={component => this.setFileUploadComponent(component)}
                onChange={this.props.onChangeAttachment}
              />
            </footer>
          </div>
        </div>
      );
    }
    return null;
  }

  renderError() {
    if (this.props.errorText.length > 0) {
      return (
        <Toast hasCloseButton={false} type="error">
          {this.props.errorText}
        </Toast>
      );
    }
    return null;
  }

  closePopover = () => {
    this.setState({ showStatusPopover: false });
  };

  openPopover = () => {
    this.setState({ showStatusPopover: true });
  };

  renderDeleteButton() {
    if (this.props.isDeleteVisible) {
      return (
        <DeleteButton
          align={this.props.deleteButtonMenuAlign}
          isDisabled={this.props.isLoading}
          onDeleted={this.props.onDeleted}
        />
      );
    }
  }

  render() {
    return (
      <div className="record-processing__subpanel">
        <div className="record-processing-header">
          {this.props.a11yText ? (
            <Heading tabIndex="-1" id="common-subpanel-header-title" level={4} isHidden>
              {this.props.a11yText}
            </Heading>
          ) : null}
          <RawButton onKeyDown={this.handleKeyDownHeader} onClick={this.props.onHide}>
            <Icon type={this.props.minimizeIcon} size={this.props.minimizeIconSize} />
            {this.props.minimizeText}
          </RawButton>
        </div>
        {this.renderRecordCount()}

        <div className="record-processing__subpanel__table">
          <div className="record-processing__subpanel__row">
            <div className="record-processing-subpanel__label">
              <label htmlFor="status">{i18n.t("_RecordProcessing.Status_")}</label>
              <Popover
                content={i18n.t("_RecordProcessing.StatusHelp_")}
                show={this.state.showStatusPopover}
                onOutsideClick={this.closePopover}
                align="bottom"
              >
                <RawButton a11yText={i18n.t("_RecordProcessing.StatusHelp_")} onClick={this.openPopover}>
                  <Icon type="info-circle" color={Tokens.color.blackLighten20} />
                </RawButton>
              </Popover>
            </div>
            {this.renderStatusSelector()}
          </div>

          {this.renderStatusWarning()}

          <div className="record-processing__subpanel__row">
            <div className="record-processing-subpanel__label">
              <label htmlFor="priority">{i18n.t("_RecordProcessing.Priority_")}</label>
            </div>
            {this.renderPrioritiesSelector()}
          </div>

          <div className="record-processing__subpanel__row">
            <div className="record-processing-subpanel__label">
              <label htmlFor="group">{i18n.t("_RecordProcessing.Group_")}</label>
            </div>
            {this.renderGroupsSelector()}
          </div>

          <div className="record-processing__subpanel__row">
            <div className="record-processing-subpanel__label">
              <label htmlFor="assignee">{i18n.t("_RecordProcessing.Assignee_")}</label>
            </div>
            {this.renderAssigneesSelector()}
          </div>

          {this.renderCommentSection()}
        </div>

        <footer>
          {this.renderError()}
          <div className="record-processing-subpanel__buttons">
            <Button
              isDisabled={this.props.isProcessingDisabled || this.props.isLoading || this.props.isProcessing}
              isPending={this.props.isProcessing}
              key="process"
              onClick={this.handleClickProcess}
              type="primary"
            >
              {this.renderSubmitButtonText()}
            </Button>

            <Button
              className="record_processing__send-a-questionnaire"
              key="questionnaire"
              onClick={this.props.onShowSendQuestionnairePanel}
            >
              {i18n.t("_RecordProcessing.SendQuestionnaire_")}
            </Button>

            {this.renderDeleteButton()}
          </div>
        </footer>
      </div>
    );
  }
}
