import backendApi from "@results/services/apiCall/backendApi";
import { storyboardUidFinder } from "@viz-ui/services/storyboardUrlDetection/storyboardUrlDetection";
import globalFiltersStore from "../globalFilters/services/GlobalFiltersStore";

angular
  .module("acl.storyboard.visualization")
  .factory("VisualizationBackend", function(
    $timeout,
    AppConfig,
    ChartData,
    ChartDataManager,
    BarChartBackendService,
    BubbleChartBackendService,
    LineChartBackendService,
    StatisticsVizBackendService,
    PieChartBackendService,
    StackedAreaChartBackendService,
    SummaryTableBackendService,
    TreemapBackendService,
    CombinationChartBackendService,
    MapChartBackendService,
    Table
  ) {
    var backendServices = {
      BarChart: BarChartBackendService,
      BubbleChart: BubbleChartBackendService,
      Heatmap: BubbleChartBackendService,
      LineChart: LineChartBackendService,
      StatisticsViz: StatisticsVizBackendService,
      PieChart: PieChartBackendService,
      StackedAreaChart: StackedAreaChartBackendService,
      SummaryTable: SummaryTableBackendService,
      Treemap: TreemapBackendService,
      CombinationChart: CombinationChartBackendService,
      MapChart: MapChartBackendService,
    };

    var cache = new Map();

    return {
      loadVisualizationData: async function(tableId, interpretation, visualization, queryScope) {
        if (AppConfig.features.queryService) {
          return loadVisualizationDataFromQueryService(tableId, interpretation, visualization, queryScope);
        }
        return loadVisualizationData(tableId, interpretation, visualization);
      },
    };

    async function loadVisualizationDataFromQueryService(tableId, interpretation, visualization, queryScope) {
      const globalFilters = globalFiltersStore.forQuery(tableId);
      const {
        config: { dataConfig, displayConfig = {} },
      } = visualization;
      const chartType = visualization.vizType || visualization.type;
      let filterConfig = interpretation.filterConfig;
      let formatting = interpretation.tableConfig.formatting;
      let table = new Table({ id: tableId });
      return ChartDataManager.loadChartData(
        table,
        chartType,
        { filterConfig, formatting },
        dataConfig,
        globalFilters,
        queryScope,
        displayConfig
      );
    }

    function loadVisualizationData(tableId, interpretation, visualization) {
      const globalFilters = globalFiltersStore.forQuery(tableId);
      const {
        id: visualizationId,
        config: { dataConfig },
      } = visualization;
      const chartType = visualization.vizType || visualization.type;
      const requestUrl = url(interpretation.id, visualizationId);
      const data = { globalFilters };

      let cacheKey = JSON.stringify({
        requestUrl,
        data,
      });
      if (cache.has(cacheKey)) {
        return cache.get(cacheKey);
      }

      var promise = backendApi
        .post(requestUrl, {}, data)
        .then(response => {
          let backendService = getBackendService(chartType);
          let representation = backendService.getRepresentation(response.data.result.data, dataConfig);
          return chartType === "SummaryTable" || chartType === "Treemap"
            ? representation
            : new ChartData()
                .data(response.data.result.data)
                .dataConfig(dataConfig)
                .type(chartType)
                .representation(representation);
        })
        .catch(error => {
          $timeout(() => {
            cache.delete(cacheKey);
          }, 5000);
          return Promise.reject(error);
        });

      cache.set(cacheKey, promise);
      return promise;
    }

    function getBackendService(chartType) {
      return backendServices[chartType];
    }

    function url(id, visualization_id) {
      const isAnonymous = AppConfig.isAnonymous;
      if (!isAnonymous) {
        return AppConfig.coneService.visualizationDataPath({ id, visualization_id }) + AppConfig.apiQueryString;
      } else {
        return AppConfig.coneService.publicStoryboardLinkVisualizationDataPath({
          public_storyboard_link_id: storyboardUidFinder(window.location.href),
          id,
          visualization_id,
        });
      }
    }
  });
