angular
  .module("acl.visualizer.model.accessControl")
  .factory("AccessControlManager", function($q, AppConfig, DataRequester, PromiseCache) {
    return {
      loadAccessControl,
    };

    function loadAccessControl(entity, id) {
      return PromiseCache.getPromise(requestAccessControl(entity, id), {
        requestType: "accessControlRequest",
        entity,
        id,
      });
    }

    function requestAccessControl(entity, id) {
      return function() {
        return DataRequester.getInstance("accessControlRequest").request(AppConfig.timeout, entity, id);
      };
    }
  });
