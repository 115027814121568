import React from "react";
import PropTypes from "prop-types";
import i18n from "@viz-ui/i18n/i18n";

class ResultsRecordCount extends React.Component {
  static propTypes = {
    filteredCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      recordCountString: "",
    };
  }
  componentDidMount() {
    if (this.props.totalCount) this.setState({ recordCountString: this.updateRecordCountString() });
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) this.setState({ recordCountString: this.updateRecordCountString() });
  }

  updateRecordCountString = () => {
    let displayString = "";
    // eslint-disable-next-line no-restricted-globals
    if (
      this.props.filteredCount === null ||
      isNaN(this.props.filteredCount) ||
      this.props.filteredCount === this.props.totalCount
    ) {
      displayString = i18n.t("_Record.All.Count.Label_", { total: this.props.totalCount });
    } else {
      displayString = i18n.t("_Record.Filtered.Count.Label_", {
        filtered: this.props.filteredCount,
        total: this.props.totalCount,
      });
    }
    return displayString;
  };

  render() {
    return (
      <span id="filteredRecordCount" title={this.state.recordCountString} data-testid="filtered-record-count-span-test">
        {this.state.recordCountString}
      </span>
    );
  }
}

export default ResultsRecordCount;
