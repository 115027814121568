import Field from "../field/field";
import Recordset from "./recordset";

const RecordsetAdapter = {
  deserializeRecordset: deserializeRecordset,
};

function deserializeRecordset(table, filterConfig, data) {
  table = table.clone();
  filterConfig = filterConfig.clone();

  extendWithFieldTypes(table, data.columns);

  return new Recordset()
    .table(table)
    .filterConfig(filterConfig)
    .records(data.data)
    .recordCount(data.totalRecordCount)
    .lastRecordIndex(data.lastRecNo);
}

function extendWithFieldTypes(table, columns) {
  var name;
  var type;
  var field;

  for (name in columns) {
    if (columns.hasOwnProperty(name)) {
      type = columns[name];
      field = table.fieldByName(name);
      if (field) {
        field.type(type);
      } else {
        field = new Field().name(name).type(type);
      }
      table.setField(field);
    }
  }
}
export default RecordsetAdapter;
