angular.module("acl.common.userAgent", ["acl.common.config"]).factory("UserAgent", function($window) {
  "use strict";
  var userAgentString = (function() {
    var userAgent = navigator.userAgent,
      tem,
      M = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*([\d\.]+)/i) || [];

    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+(\.\d+)?)/g.exec(userAgent) || [];
      return "IE " + (tem[1] || "");
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = userAgent.match(/version\/([\.\d]+)/i)) !== null) {
      M[2] = tem[1];
    }

    return M.join(" ");
  })();

  var userAgentParts = userAgentString.split(" ");

  var userAgent = {
    browser: userAgentParts[0],
    version: userAgentParts[1],
    isIE: () => userAgentParts[0] === "IE",
  };

  return userAgent;
});
