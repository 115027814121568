const timeRegexWithoutSurroundingForwardSlashes = "^([01]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$";

class InputValidation {
  date = {
    isBeforeMin: function(dateToCheck, minDate) {
      if (!minDate) {
        var january = 0,
          minValidAclDate = new Date(1900, january, 2);
        minDate = minValidAclDate;
      }
      var dateIsBeforeMin = dateToCheck < minDate;
      return dateIsBeforeMin;
    },
  };

  time = {
    regexString: {
      withoutSurroundingForwardSlashes: timeRegexWithoutSurroundingForwardSlashes,
      wrappedWithForwardSlashes: "/" + timeRegexWithoutSurroundingForwardSlashes + "/",
    },
  };
}

export default new InputValidation();
