import Model from "../model";

class AggregatorArgs extends Model {
  static modelType = "AggregatorArgs";

  operator(...args) {
    return this.accessor("operator", args, "string");
  }

  values(...args) {
    return this.accessor("values", args, ["*"]);
  }

  static fromJson(json) {
    return new AggregatorArgs().operator(json.operator).values(json.values);
  }

  toJson() {
    return this._deleteUndefinedProperties({
      operator: this.operator(),
      values: this.values(),
    });
  }
}
export default AggregatorArgs;
