angular
  .module("acl.visualizer.shareToGRC")
  .controller("RegionController", function($scope, shareToGRC, $timeout) {
    "use strict";
    $scope.sectionOpen = false;

    $scope.$watch("$parent.sectionOpen", function(newValue) {
      if (newValue) {
        var selectedRegion = $scope.$parent.region;
        $scope.$parent.region.accountsLoaded = false;
        shareToGRC.getAccounts(selectedRegion).then(
          function(response) {
            $scope.$parent.region.accountsLoaded = true;
            for (var i in response.data) {
              response.data[i].projects = [];
            }
            $scope.$parent.region.accounts = response.data;
          },
          function(response) {
            $scope.$parent.region.accountsLoaded = true;
            $scope.$parent.region.accounts = [];
          }
        );
      } else {
        // remove child accounts
        $timeout(function() {
          $scope.$parent.region.accounts = [];
        }, 500);
      }
    });
  })
  .controller("AccountController", function($scope, shareToGRC, $timeout) {
    "use strict";
    $scope.sectionOpen = false;

    $scope.$watch("$parent.sectionOpen", function(newValue) {
      if (newValue) {
        var selectedAccountId = $scope.$parent.account.id;
        $scope.$parent.account.projects = [];
        $scope.$parent.account.projectsLoaded = false;
        shareToGRC.getProjects(selectedAccountId).then(
          function(response) {
            $scope.$parent.account.projectsLoaded = true;
            $scope.$parent.account.projects = response.data;
          },
          function(response) {
            $scope.$parent.account.projectsLoaded = true;
            $scope.$parent.account.projects = [];
          }
        );
      } else {
        // remove child projects
        $timeout(function() {
          $scope.$parent.account.projects = [];
        }, 500);
      }
    });
  })
  .controller("ProjectController", function($scope, shareToGRC, $timeout) {
    "use strict";
    $scope.sectionOpen = false;

    $scope.$watch("$parent.sectionOpen", function(newValue) {
      if (newValue) {
        var selectedProjectId = $scope.$parent.project.id;
        $scope.$parent.project.controls = [];
        $scope.$parent.project.controlsLoaded = false;
        shareToGRC.getControls(selectedProjectId).then(
          function(response) {
            $scope.$parent.project.controlsLoaded = true;
            $scope.$parent.project.controls = response.data;
          },
          function(response) {
            $scope.$parent.project.controlsLoaded = true;
            $scope.$parent.project.controls = [];
          }
        );
      } else {
        // remove child controls
        $timeout(function() {
          $scope.$parent.project.controls = [];
        }, 500);
      }
    });
  })
  .controller("ControlController", function($scope, shareToGRC, $timeout) {
    "use strict";
    $scope.sectionOpen = false;

    $scope.$watch("$parent.sectionOpen", function(newValue) {
      if (newValue) {
        var selectedControlId = $scope.$parent.control.id;
        $scope.$parent.control.control_tests = [];
        $scope.$parent.control.controlTestsLoaded = false;
        shareToGRC.getControlTests(selectedControlId).then(
          function(response) {
            $scope.$parent.control.controlTestsLoaded = true;
            $scope.$parent.control.control_tests = response.data;
          },
          function(response) {
            $scope.$parent.control.control_tests = [];
          }
        );
      } else {
        // remove child control tests
        $timeout(function() {
          $scope.$parent.control.control_tests = [];
        }, 500);
      }
    });
  });
