import React, { Component } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import StoryboardFilter from "@viz-ui/models/storyboardFilter/storyboardFilter";
import VizUiSingleselect from "@viz-ui/components/stbGlobalFilter/singleselect/singleselect";
import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";
import SelectLoader from "../services/SelectLoader";
import wasFilterEdited from "../services/wasFilterEdited";
import dynamicFilter from "../services/dynamicFilter";

export default class Singleselect extends Component {
  static propTypes = {
    fieldFormatIdentifier: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    filter: PropTypes.instanceOf(StoryboardFilter).isRequired,
    flipQueryService: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    isMandatory: PropTypes.bool.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    forceRerender: PropTypes.bool.isRequired,
    flipDynamicFilter: PropTypes.bool.isRequired,
    resetForceReRenderFlag: PropTypes.func.isRequired,
    clearAllFilter: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      allItemsLoaded: true,
      filterString: "",
      isLoading: false,
      items: [],
      isApiCalled: false,
    };
  }

  componentDidMount() {
    this.setState({ isApiCalled: true });
    this.updateSelectLoader(this.props.filter, this.props.flipQueryService, this.props.flipDynamicFilter);
  }

  componentWillReceiveProps(nextProps) {
    //if on apply, we have a parent filter, then hide unselected
    if (dynamicFilter.hideUnselectedValueForFrozenFilter(nextProps.filter, nextProps.flipDynamicFilter)) {
      this.setState({ items: [nextProps.filter.value()] });
    }
    //forceRerender will be true inorder to make api calls when we are clearing filter
    if (nextProps.filter) {
      if (!this.props.filter || wasFilterEdited(this.props.filter, nextProps.filter) || nextProps.forceRerender) {
        if (
          this.props.fieldName !== nextProps.fieldName ||
          (!nextProps.filter.isFrozenFilter() &&
            !nextProps.filter.isCurrentDynamicFilter() &&
            !this.state.isApiCalled) ||
          nextProps.clearAllFilter
        ) {
          this.setState({ isApiCalled: true });
          this.updateSelectLoader(
            nextProps.filter,
            nextProps.flipQueryService,
            nextProps.flipDynamicFilter,
            nextProps.forceRerender
          );
        }
      }
    }
  }

  //reset the render flag so filter will not be unnecessarily rendered
  //after clear filter process is done
  handleResetRerendering = () => {
    this.props.resetForceReRenderFlag();
  };

  updateSelectLoader(filter, flipQueryService, flipDynamicFilter, forceRerender) {
    this.setState({ filterString: "", isApiCalled: false });
    this.selectLoader = new SelectLoader(filter, flipQueryService, flipDynamicFilter, forceRerender);
    this.selectLoader.load().then(this.updateItemsState);
  }

  handleClear = () => {
    this.props.filter.value(null);
    this.setState({ selectedItem: null });
    this.props.onFilterChange("value");
  };

  handleLoadMore = () => {
    this.setState({ isLoading: true });
    this.selectLoader.loadMore().then(this.updateItemsState);
  };

  handleChangeFilterString = filterString => {
    this.setState({ filterString });
    this.debounceFilterStringChange(filterString);
  };

  debounceFilterStringChange = debounce(
    filterString => {
      this.setState({ isLoading: true });
      this.selectLoader.filterStringChange(filterString).then(this.updateItemsState);
    },
    500,
    { leading: true }
  );

  updateItemsState = () => {
    this.setState({
      allItemsLoaded: this.selectLoader.allItemsLoaded(),
      isLoading: false,
      items: this.selectLoader.valuesRef(),
    });
    //Reset the rerender flag inorder to prevent rerendering when not required
    //after clearing the filter
    this.handleResetRerendering();
  };

  handleClickItem = filterValueModel => {
    this.props.onFilterChange("value", filterValueModel);
    UsageTracker.createEvent(`.filterpanel.${this.props.filter.type()}.selectionchanged`);
  };

  render() {
    return (
      <VizUiSingleselect
        allItemsLoaded={this.state.allItemsLoaded}
        fieldFormatIdentifier={this.props.fieldFormatIdentifier}
        fieldName={this.props.fieldName}
        filterString={this.state.filterString}
        isDisabled={this.props.isDisabled}
        isLoading={this.state.isLoading}
        isMandatory={this.props.isMandatory}
        items={this.state.items}
        onClear={this.handleClear}
        onClickItem={this.handleClickItem}
        onChangeFilterString={this.handleChangeFilterString}
        onLoadMore={this.handleLoadMore}
        selectedItem={this.props.filter && this.props.filter.value()}
      />
    );
  }
}
