const CHART_LABEL_PX = {
  large: 16,
};

export default function() {
  return {
    updateLabelFontSize: (config, labelFontSize, fontSizeConfigPaths, fontZoomRatio = 1) => {
      if (!CHART_LABEL_PX[labelFontSize]) return config;

      let labelFontSizeInPx = CHART_LABEL_PX[labelFontSize] * fontZoomRatio + "px";
      let configToUpdate = Object.assign({}, config);
      fontSizeConfigPaths.forEach(path => {
        configToUpdate = setConfigValue(configToUpdate, path, labelFontSizeInPx);
      });
      return configToUpdate;
    },
  };
}

function setConfigValue(config, path, value) {
  let clonedConfig = Object.assign({}, config);
  let pathToValue = path.split(".");

  let parentConfigObj = clonedConfig;
  pathToValue.forEach((key, index) => {
    if (parentConfigObj[key] === undefined) parentConfigObj[key] = {};
    if (index === pathToValue.length - 1) {
      parentConfigObj[key] = value;
    } else {
      parentConfigObj = parentConfigObj[key];
    }
  });

  return clonedConfig;
}
