/* eslint-disable no-bitwise */

import i18n from "@viz-ui/i18n/i18n";
import GlobalValueFormatter from "../formatters/globalValueFormatter";

class FilterValueService {
  static hashCode(str) {
    let hash = 0;
    let i;
    let chr;

    if (!str) return hash;
    if (typeof str !== "string") return null;

    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash &= hash; // Convert to 32-bit integer
    }

    return hash;
  }

  static getItemDisplayValue(filterValue, fieldFormatIdentifier, fieldName) {
    return filterValue.isBlank()
      ? i18n.t("_Filter.BlankValue.Label_")
      : GlobalValueFormatter.formatValue(filterValue.value(), fieldFormatIdentifier, fieldName);
  }
}

export default FilterValueService;
