import React from "react";
import PropTypes from "prop-types";
import HighChart from "@acl-services/sriracha-high-chart";
import { patternFillPalettes } from "@visualizer/modules/visualization/highCharts/helpers/patternFillPalettes";

let highChartProps = {};
class ReactHighChart extends React.Component {
  static propTypes = {
    HighChartLabelSizer: PropTypes.any,
    onClick: PropTypes.func,
    config: PropTypes.object,
    redrawIndex: PropTypes.number,
    translations: PropTypes.object,
  };

  static defaultProps = {
    HighChartLabelSizer: {},
    onClick: () => {},
    config: {},
    redrawIndex: 0,
    translations: {},
  };

  constructor(props) {
    super(props);
    highChartProps = {
      ...props,
      HighChartLabelSizer: props.HighChartLabelSizer,
      isClickable: props.onClick,
      translations: props.translations,
      hcConfig: props.config,
      patternFillPalettes: patternFillPalettes,
      redrawIndex: props.redrawIndex,
      chartReDraw: false,
    };
    this.chartRef = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    highChartProps = {
      ...nextProps,
      HighChartLabelSizer: nextProps.HighChartLabelSizer,
      isClickable: nextProps.onClick,
      translations: nextProps.translations,
      hcConfig: nextProps.config,
      patternFillPalettes: patternFillPalettes,
      redrawIndex: nextProps.redrawIndex,
      chartReDraw: true,
    };
  }

  shouldComponentUpdate(nextProps) {
    const { config } = this.props;
    if (config.series) delete config.series[0]._symbolIndex;
    if (highChartProps.chartReDraw && JSON.stringify(nextProps.config) === JSON.stringify(config)) {
      this.chartRef.current.chart.reflow();
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    if (highChartProps.chartReDraw) highChartProps.chartReDraw = false;
  }

  render() {
    return (
      <div className="sriracha react-highChart" style={{ height: "100%" }}>
        <HighChart
          id={`svg-chartid-high-chart-${highChartProps.index}`}
          className={`redraw-index-${highChartProps.redrawIndex}`}
          config={highChartProps.hcConfig}
          translations={highChartProps.translations}
          ref={this.chartRef}
        />
      </div>
    );
  }
}

export default ReactHighChart;
