import React from "react";
import PropTypes from "prop-types";
import Select from "@paprika/select";
import "./dropdown.scss";

const propTypes = {
  value: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  onValueChange: PropTypes.func,
};

const defaultProps = {
  value: "",
  onValueChange: () => {},
};

function Dropdown(props) {
  const [value, setValue] = React.useState(props.value || "");

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function renderOptions() {
    if (props.values && props.values.length > 0) {
      return props.values.map(optionValue => (
        <option data-testid={optionValue} value={optionValue}>
          {optionValue}
        </option>
      ));
    }
    return "";
  }

  function onChange(selectedValue) {
    setValue(selectedValue);
    props.onValueChange(selectedValue);
  }

  return (
    <div class="drop-down__container">
      <Select
        data-testid="_drop-down__select"
        {...props}
        value={value}
        onChange={e => onChange(e.target.value)}
        className="drop-down__select"
      >
        {renderOptions()}
      </Select>
    </div>
  );
}

Dropdown.displayName = "Dropdown";
Dropdown.propTypes = propTypes;
Dropdown.defaultProps = defaultProps;

export default Dropdown;
