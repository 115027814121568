import QueryScope from "@viz-ui/services/query/queryScope/queryScope";
import FieldTypeChecker from "@visualizer/modules/core/fieldTypeChecker/fieldTypeChecker.service";
import GlobalFieldFormatMap from "@viz-ui/services/formatters/globalFieldFormatMap";
import BaseDataLoader from "./baseDataLoader";
import BoardError from "../models/boardError";
import TableBoard from "../models/tableBoard";
import ErrorReporter from "@viz-ui/services/common/errorReporterService";

export default class InterpretationTableDataLoader extends BaseDataLoader {
  constructor(
    AppConfig,
    InterpretationAdapter,
    InterpretationBackend,
    InterpretationDataStore,
    InterpretationTableDataStore,
    TableManager
  ) {
    "ngInject";
    super();

    this._exceptionHandler = ErrorReporter.instance("Storyboards");
    this._AppConfig = AppConfig;
    this._InterpretationAdapter = InterpretationAdapter;
    this._InterpretationBackend = InterpretationBackend;
    this._InterpretationDataStore = InterpretationDataStore;
    this._InterpretationTableDataStore = InterpretationTableDataStore;
    this._TableManager = TableManager;
  }

  async load(board) {
    let tableBoard = this._newTableBoard(board);
    if (!tableBoard.boardError()) {
      tableBoard = await this._addTableToBoard(board, tableBoard);
      tableBoard = this._checkFieldTypes(tableBoard);
      tableBoard = await this._addRecordsetToBoard(board, tableBoard);

      this._setGlobalFieldFormat(tableBoard);
    }
    return tableBoard;
  }

  async loadMoreTableData(board, tableBoard) {
    const { interpretation_id: interpretationId } = board;
    if (!tableBoard.boardError()) {
      const moreRecordset = await this._loadTableData(board, tableBoard);
      if (moreRecordset) {
        this._InterpretationTableDataStore.addRecordset(interpretationId, moreRecordset);
        tableBoard.recordset(this._InterpretationTableDataStore.getRecordset(interpretationId));
        tableBoard = tableBoard.clone();
      }
    }
    return tableBoard;
  }

  _newTableBoard(board) {
    const { interpretation_id: interpretationId } = board;
    let tableBoard = new TableBoard(board);
    tableBoard = this._addInterpretationToBoard(tableBoard, interpretationId);
    tableBoard = this._checkAndAddInterpretationError(tableBoard, interpretationId);

    return tableBoard;
  }

  _addInterpretationToBoard(tableBoard, interpretationId) {
    const interpretation = this._InterpretationDataStore.getInterpretation(interpretationId);
    const canDrilldown = this._InterpretationDataStore.canDrilldown(interpretationId);
    tableBoard.interpretation(interpretation);
    tableBoard.canDrilldown(canDrilldown);
    return tableBoard;
  }

  _checkAndAddInterpretationError(tableBoard, interpretationId) {
    const interpretationError = this._InterpretationDataStore.getInterpretationError(interpretationId);
    if (interpretationError) {
      const boardError = this._getBoardError(interpretationError);
      tableBoard.boardError(boardError);
    }
    return tableBoard;
  }

  async _addTableToBoard(board, tableBoard) {
    const table = await this._loadTable(board);
    tableBoard.table(table);
    this._InterpretationTableDataStore.addTable(board.id, table);

    return tableBoard;
  }

  _checkFieldTypes(tableBoard) {
    const interpretation = tableBoard.interpretation();
    const table = tableBoard.table();
    const { filterList } = interpretation.filterConfig;
    const fieldTypeChecker = FieldTypeChecker.create(table.fields());
    const filterFieldTypeChanged = fieldTypeChecker.filterListTypeMismatch(filterList);
    if (filterFieldTypeChanged) {
      const boardError = this._getBoardError("FilterTypeChanged");
      tableBoard.boardError(boardError);
    }
    return tableBoard;
  }

  async _addRecordsetToBoard(board, tableBoard) {
    if (tableBoard.boardError()) return tableBoard;
    const { interpretation_id: interpretationId } = board;

    let recordset = this._InterpretationTableDataStore.getRecordset(interpretationId);
    if (!recordset) {
      try {
        recordset = await this._loadRecordset(board, tableBoard);
      } catch (error) {
        const boardError = this._getBoardError(error);
        boardError.log(this.retrieveBoardLogInfo(board));
        tableBoard.boardError(boardError);
      }
      this._InterpretationTableDataStore.setRecordset(interpretationId, recordset);
    }
    tableBoard.recordset(recordset);

    return tableBoard;
  }

  _setGlobalFieldFormat(tableBoard) {
    const table = tableBoard.table();
    const interpretation = tableBoard.interpretation();
    const interpretationModel = this._InterpretationAdapter.deserializeInterpretation(interpretation);
    GlobalFieldFormatMap.setFieldFormats(interpretation.id, interpretationModel.fieldFormatMap());
    GlobalFieldFormatMap.setFieldTypes(interpretation.id, table.fields());
  }

  async _loadTable(board) {
    const { interpretation_id: interpretationId, tableId } = board;
    let table = this._InterpretationTableDataStore.getTable(board.id);
    if (!table) {
      if (this._AppConfig.features.queryService) {
        const queryScope = QueryScope.getScope(board);
        table = await this._TableManager.loadTable(tableId, queryScope);
      } else {
        table = await this._InterpretationBackend.loadTableMetadata(interpretationId);
      }
    }
    return Promise.resolve(table);
  }

  _loadRecordset(board, tableBoard) {
    const { interpretation_id: interpretationId } = board;
    let recordset = this._InterpretationTableDataStore.getRecordset(interpretationId);
    if (!recordset) {
      return this._loadTableData(board, tableBoard);
    }
    return Promise.resolve(recordset);
  }

  _loadTableData(board, tableBoard) {
    const { interpretation_id: interpretationId, tableId } = board;
    const interpretation = tableBoard.interpretation();
    const filterConfig = interpretation.filterConfig;
    const queryScope = QueryScope.getScope(board);
    const table = tableBoard.table();
    const nextRecordIndex = this._InterpretationTableDataStore.getNextRecordIndex(interpretation.id);
    if (nextRecordIndex) {
      return this._InterpretationBackend.loadTableData({
        tableId,
        interpretationId,
        table,
        filterConfig,
        queryScope,
        nextRecordIndex,
      });
    }
  }

  _getBoardError(error) {
    const exceptionHandler = this._exceptionHandler;
    const boardType = "table";
    return new BoardError({ boardType }).setExceptionHandler(exceptionHandler).setErrorFromCode(error);
  }
}
