import EventService from "@viz-ui/services/eventService/eventService";

export default class DataRequest {
  constructor(eventName, destroyCallbackFn) {
    this._eventName = eventName;
    this._destroyCallbackFn = destroyCallbackFn;

    this._eventServiceInstance = EventService.register("acl.visualizer.dataRequester::" + eventName);
    this._requestMap = {};

    this._eventServiceInstance.subscribe("resolve." + this._eventName, (_, token, responseData) => {
      this._resolveToken(token, responseData);
    });

    this._eventServiceInstance.subscribe("reject." + this._eventName, (_, token, error) => {
      this._rejectToken(token, error);
    });
  }

  request(timeout, ...requestData) {
    const deferred = {};

    deferred.promise = new Promise((resolve, reject) => {
      deferred.resolve = resolve;
      deferred.reject = reject;
    });

    const token = Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);

    const request = {
      token: token,
      timeout: timeout,
      deferred: deferred,
      requestData: requestData,
    };

    this._cancelAfterTimeout(request, timeout);

    this._requestMap[token] = request;
    this._eventServiceInstance.publish("request." + this._eventName, token, ...request.requestData);

    return deferred.promise;
  }

  destroy() {
    if (this._eventServiceInstance) {
      this._eventServiceInstance.unregister();
      this._eventServiceInstance = undefined;

      if (this._destroyCallbackFn) this._destroyCallbackFn();
    }
  }

  _cancelAfterTimeout(request) {
    const { token, timeout } = request;

    if (timeout > 0) {
      const timeoutPromise = setTimeout(() => {
        this._eventServiceInstance.publish(
          "reject." + this._eventName,
          token,
          "DataRequester got a timeout while waiting for a request."
        );
      }, timeout);

      const cancelTimeout = () => {
        clearTimeout(timeoutPromise);
      };

      request.deferred.promise.then(cancelTimeout, cancelTimeout);
    }
  }

  _resolveToken(token, responseData) {
    if (!this._requestMap[token]) {
      throw Error("Attempted to resolve unexpected token: " + token);
    }

    this._requestMap[token].deferred.resolve(responseData);
  }

  _rejectToken(token, error) {
    if (!this._requestMap[token]) {
      throw Error("Attempted to reject unexpected token: " + token);
    }

    this._requestMap[token].deferred.reject(error);
  }
}
