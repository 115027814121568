import { isEqual } from "lodash";
import _min from "lodash/min";
import _max from "lodash/max";
import chartService from "@viz-ui/services/charts/chartService";
import ColorPalette from "@acl-services/sriracha-color-palette/dist/ColorPalette";
import ChartColorConfigHelper from "@viz-ui/services/charts/chartColorConfigHelper";
import TreemapQueryDataConverter from "./treemapQueryDataConverter";

class TreemapChartService extends chartService {
  defaultDisplayConfig = () => {
    const paletteColors = new ColorPalette().treemapColors();
    return {
      displayDataLabels: true,
      binMode: false,
      colorAxis: {
        colorStops: [{ color: paletteColors[0] }, { color: paletteColors[1] }, { color: paletteColors[2] }],
        numBins: 5,
      },
      reverseColors: false,
      showFirstGroupLabels: true,
      showSecondGroupLabels: true,
      showLegend: true,
      enableBoost: true,
      xAxis: {
        showLabel: true,
      },
      yAxis: {
        showLabel: true,
      },
    };
  };

  valueFormatter = (interpretationId, chartConfig, chartRow) => {
    const { vizId, dataConfig, displayConfig } = chartConfig;
    if (
      displayConfig.valueFormattingOptions &&
      dataConfig.chartValues &&
      dataConfig.chartValues[0].aggregationType === "average"
    ) {
      const chartValueFieldName = `${vizId}-chart-value`;
      const chartValueFieldType = "numeric";
      return this.getValueFormatter(interpretationId, chartValueFieldName, chartValueFieldType);
    }
    return this.getValueFormatter(interpretationId, chartRow.fieldName, chartRow.type);
  };

  getChartDirectiveConfig = (interpretationId, chartConfig) => {
    const { dataConfig } = chartConfig;
    const { displayConfig } = chartConfig;
    const hasColorValue = dataConfig.chartValues.length > 1;

    const result = {
      valueLabels: this.getConfigChartValueLabels(dataConfig.chartValues),
      showLegend: displayConfig.showLegend,
      showFirstGroupLabels: displayConfig.showFirstGroupLabels,
      showSecondGroupLabels: displayConfig.showSecondGroupLabels,
      enableBoost: displayConfig.enableBoost,
      chartRows: dataConfig.chartRows.map(chartRow => ({
        displayName: chartRow.displayName,
        fieldName: chartRow.fieldName,
        type: chartRow.type,
        valueFormatter: this.getValueFormatter(interpretationId, chartRow.fieldName, chartRow.type),
      })),
      chartValues: this.getConfigChartValues(interpretationId, dataConfig.chartValues, chartConfig),
      binMode: (hasColorValue && chartConfig.displayConfig.binMode) || false,
      colorAxis: hasColorValue
        ? this.getConfigColorAxis(interpretationId, displayConfig.colorAxis, dataConfig.chartValues[1])
        : undefined,
    };

    return result;
  };

  getConfigChartValues = (interpretationId, chartValues, chartConfig) => {
    const result = [];
    if (chartValues.length > 0) {
      const sizeByValue = chartValues[0];
      result.push({
        aggregationType: sizeByValue.aggregationType,
        fieldName: sizeByValue.fieldName,
        displayName: sizeByValue.displayName,
        valueFormatter: this.valueFormatter(interpretationId, chartConfig, sizeByValue),
      });
    }

    const hasColorValue = chartValues.length > 1;
    if (hasColorValue) {
      const colorByValue = chartValues[1];
      result.push({
        aggregationType: colorByValue.aggregationType,
        fieldName: colorByValue.fieldName,
        displayName: colorByValue.displayName,
        valueFormatter: this.getValueFormatter(interpretationId, colorByValue.fieldName, colorByValue.type),
      });
    }

    return result;
  };

  getConfigChartValueLabels = chartValues => {
    const result = [];
    if (chartValues.length > 0) {
      const sizeByValue = chartValues[0];
      result.push(this.getFieldLabel(sizeByValue));
    }

    const hasColorValue = chartValues.length > 1;
    if (hasColorValue) {
      const colorByValue = chartValues[1];
      result.push(this.getFieldLabel(colorByValue));
    }

    return result;
  };

  getConfigColorAxis = (interpretationId, colorAxis, colorByValue) => ({
    colorStops: colorAxis ? colorAxis.colorStops : this.defaultDisplayConfig().colorAxis.colorStops,
    minimum: colorAxis && colorAxis.minimum,
    maximum: colorAxis && colorAxis.maximum,
    numBins: colorAxis && colorAxis.numBins,
    valueFormatter: this.getValueFormatter(interpretationId, colorByValue.fieldName, colorByValue.type),
  });

  populateChartConfigColumnDefs = chartConfigColumnDefsObj => {
    const fields = this.DataModel.table.fields();
    if (fields) {
      let tempColumnDef = {};
      for (const fieldName in fields) {
        tempColumnDef = {};
        tempColumnDef.displayName = fields[fieldName].displayName;
        tempColumnDef.fieldId = fields[fieldName].colId;
        tempColumnDef.fieldName = fieldName;
        tempColumnDef.type = fields[fieldName].type;

        switch (tempColumnDef.type) {
          case "character":
          case "logical":
            if (this.AppConfig.features.logicalFieldsInCharts || tempColumnDef.type === "character") {
              chartConfigColumnDefsObj.chartRows.push(tempColumnDef);
            }
            break;

          case "numeric":
            chartConfigColumnDefsObj.chartRows.push(tempColumnDef);
            chartConfigColumnDefsObj.chartValues.push(tempColumnDef);
            break;

          case "date":
          case "datetime":
          case "time":
            chartConfigColumnDefsObj.chartRows.push(tempColumnDef);
            break;
        }
      }
    }
    return chartConfigColumnDefsObj;
  };

  chartConfigColumnDefs = () => ({
    chartRows: [],
    chartValues: [],
  });

  isValidDataConfig = dataConfig =>
    !!(
      dataConfig &&
      dataConfig.chartRows &&
      dataConfig.chartRows.length > 0 &&
      dataConfig.chartRows.length < 3 &&
      dataConfig.chartValues &&
      dataConfig.chartValues.length > 0 &&
      dataConfig.chartValues.length < 3 &&
      ((dataConfig.chartValues[0].aggregationType && dataConfig.chartValues[0].fieldName) ||
        dataConfig.chartValues[0].aggregationType === "count")
    );

  tooltipPointFormatter = (config, series, node, value, colorValue) => {
    const groupNodes = this.getNodeAndParentsDescending(node, series);
    let result = "";
    groupNodes.forEach((groupNode, i) => {
      const chartRow = config.chartRows[i];
      const lineBreak = i > 0 ? "<br/>" : "";
      result += `${lineBreak}<b>${chartRow.displayName}:</b> <span>${chartRow.valueFormatter(groupNode.name)}</span>`;
    });

    if (config.chartValues.length > 0 && node.isLeaf) {
      const sizeByValue = config.chartValues[0];
      const colorByValue = config.chartValues[1];
      result += `<br/><b>${config.valueLabels[0]}:</b> <span>${sizeByValue.valueFormatter(value)}</span>`;
      if (
        config.chartValues.length > 1 &&
        colorValue &&
        (sizeByValue.fieldName !== colorByValue.fieldName ||
          sizeByValue.aggregationType !== colorByValue.aggregationType)
      ) {
        result += `<br/><b>${config.valueLabels[1]}:</b> <span>${colorByValue.valueFormatter(colorValue)}</span>`;
      }
    }

    return result;
  };

  nodeById = (id, series) => series.data.find(d => d.node.id === id);

  getNodeAndParentsDescending = (rootNode, series) => {
    const result = [rootNode];
    const maxIterations = 100;
    let i;
    for (i = 0; i < maxIterations; i++) {
      const parentId = result[0].parent;
      if (!parentId) break;
      const parentNode = this.nodeById(parentId, series);
      if (!parentNode) break;
      result.unshift(parentNode);
    }
    if (i >= maxIterations) {
      throw Error("Malformed treemap series data.");
    }
    return result;
  };

  convertToHighChartData = (data, vizConfig) => {
    if (this.AppConfig.features.queryService) {
      const converter = new TreemapQueryDataConverter(data, vizConfig, this);
      return converter.convertToHighChartData();
    }
    return this.convertSummaryTableDataToHighChartData(data);
  };

  getParentValues = (valueObj, rowFieldNames, rowIndex) => {
    const result = [];
    for (let i = 0; i <= rowIndex; i++) {
      const value = valueObj.rows[i][rowFieldNames[i]];
      result.push(value);
    }
    return result;
  };

  convertSummaryTableDataToHighChartData = data => {
    const result = [];

    const valueFieldName = data.config.values[0].field_name;
    const hasColorValue = data.config.values.length > 1;
    const colorValueFieldName = hasColorValue ? data.config.values[1].field_name : undefined;
    const rowFieldNames = data.config.rows.map(row => row.field_name);
    const lastRowIndex = rowFieldNames.length - 1;
    const discreteColorByFieldName = data.config.rows[0].field_name;
    const getDiscreteColor = this.getKeyColor(discreteColorByFieldName, data.config.colorMapping);

    rowFieldNames.forEach((rowFieldName, rowIndex) => {
      let prevValuesChain;
      data.values.forEach(valueObj => {
        const valuesChain = this.getParentValues(valueObj, rowFieldNames, rowIndex);
        const value = valueObj.rows[rowIndex][rowFieldName];
        if (typeof prevValuesChain === "undefined" || !isEqual(valuesChain, prevValuesChain)) {
          prevValuesChain = valuesChain;

          const row = { id: JSON.stringify(valuesChain), name: value };
          if (rowIndex > 0) {
            const parentValues = valuesChain.slice(0, rowIndex);
            row.parent = JSON.stringify(parentValues);
          }

          if (rowIndex === 0 && !hasColorValue) {
            // If no colour by field is selected, top-level nodes are assigned colours.
            row.color = getDiscreteColor({ key: value });
          }

          if (rowIndex === lastRowIndex) {
            row.value = Number(valueObj.values[valueFieldName]);
            if (hasColorValue) {
              // If a colour by field is selected, leaf nodes are assigned colour values which
              // are used by HighCharts to calculate actual colours.
              row.colorValue = Number(valueObj.values[colorValueFieldName]);
            }
          }

          result.push(row);
        }
      });
    });

    return result;
  };

  getHighChartsColorAxis = (config, data) => {
    const hasColorByField = config.chartValues.length > 1;

    let result;
    if (hasColorByField) {
      const values = this.getFlattenedColorValues(data);
      const stops = ChartColorConfigHelper.getColorStops(config.colorAxis.colorStops, _min(values), _max(values));
      const min = stops[0].value;
      const max = stops[2].value;

      if (config.binMode) {
        result = {
          dataClasses: ChartColorConfigHelper.getColorBins(stops, config.colorAxis.numBins),
        };
      } else {
        result = {
          stops: stops.map((stop, i) => [(stops[i].value - min) / (max - min), stop.color]),
          formatter: function() {
            return config.colorAxis.valueFormatter(this.value);
          },
          min: min,
          max: max,
          startOnTick: !Number.isFinite(stops[0].value),
          endOnTick: !Number.isFinite(stops[2].value),
        };
      }
      result.labels = {
        formatter: function() {
          return config.colorAxis.valueFormatter(this.value);
        },
      };
    } else {
      result = undefined;
    }

    return result;
  };

  getFlattenedColorValues = data => {
    if (this.AppConfig.features.queryService) {
      const converter = new TreemapQueryDataConverter(data, undefined, this);
      return converter.getFlattenedColorValues();
    }
    const valueFieldName = data.config.values[1].field_name;
    return data.values.map(d => Number(d.values[valueFieldName]));
  };

  getFilters = (data, chartConfig) => {
    const formatters = chartConfig.dataConfig.chartRows.map(chartRow => this.getFormatterForType(chartRow.type));
    const filters = data.map((fieldValue, index) => ({
      field: chartConfig.dataConfig.chartRows[index],
      value: formatters[index](data[index]),
    }));
    return filters;
  };
}

export default TreemapChartService;
