(() => {
  var fieldFormatOptions = {
    restrict: "E",
    bindings: {
      field: "<",
      fieldFormat: "<",
      linkLabel: "<",
      onConditionalFormattingClick: "&",
      onFieldFormatChange: "&",
      hideConditionalFormatting: "<",
    },
    controllerAs: "formatOptions",
    controller: FieldFormatOptionsController,
    templateUrl: "visualizer/js/modules/core/fieldFormatOptions/fieldFormatOptions.tpl.html",
  };

  function FieldFormatOptionsController(DataFilter) {
    "ngInject";
    var formatOptions = this;

    formatOptions.$onInit = function() {
      if (formatOptions.field) {
        formatOptions.fieldName = formatOptions.field.name();
        formatOptions.fieldType = formatOptions.field.type();
      }
    };

    formatOptions.$onChanges = function(changes) {
      if (changes.field && changes.field.currentValue) {
        formatOptions.fieldName = changes.field.currentValue.name();
        formatOptions.fieldType = changes.field.currentValue.type();
      }
    };

    formatOptions.isFormattableType = function() {
      return formatOptions.field && DataFilter.isFormattableType(formatOptions.field.type());
    };

    formatOptions.handleConditionalFormattingClick = function() {
      formatOptions.onConditionalFormattingClick({ field: formatOptions.field });
    };

    formatOptions.handleChangeFieldFormat = function(fieldFormat) {
      formatOptions.onFieldFormatChange({ field: formatOptions.field, fieldFormat: fieldFormat });
    };
  }

  angular.module("acl.visualizer.fieldFormatOptions").component("fieldFormatOptions", fieldFormatOptions);
})();
