import React from "react";
import delay from "lodash/delay";
import humps from "humps";
import Spinner from "@paprika/spinner";
import Toast from "@paprika/toast";
import services from "@visualizer/common/services/appGlue";
import Utils from "@viz-ui/services/common/utilsService";

const DEFAULT_TIMEOUT = 5000;
const DONE_STATE = "done";

export default class DownloadXlsBody extends React.Component {
  state = {
    isRunning: true,
    submitHref: null,
  };

  componentDidMount() {
    this._isMounted = true;
    this.xlsUrl = services.AppConfig.exportToXlsUrl;
    this.docParams = {
      projectId: services.AppConfig.project_id,
      controlId: services.AppConfig.control_id,
      controlTestId: services.AppConfig.control_test_id,
    };
    this.checkDownloadSource();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUrl(attachment, type) {
    const { id, attachable_id: attachableId, attachable_type: attachableType } = attachment;
    return `/download_attachments/${id}.${type}?download_attachment[attachable_id]=${attachableId}&download_attachment[attachable_type]=${attachableType}`;
  }

  getHeaders() {
    let headers = {
      "Content-type": "application/json",
      "X-Requested-By": "visualizer",
    };

    if (services.window._token) {
      headers = Object.assign(headers, { "X-CSRF-TOKEN": services.window._token });
    }

    return headers;
  }

  checkDownloadSource() {
    this.startAttachmentGenerator(this.docParams).then(attachment => this.checkIfAttachmentReady(attachment));
  }

  async startAttachmentGenerator(params) {
    const { projectId, controlId, controlTestId } = humps.camelizeKeys(params);

    var data = {
      file_name: Utils.generateFilename("xls"),
      export_data: JSON.stringify(services.DataModel.toSaveViz()),
    };

    const url = `/projects/${projectId}/controls/${controlId}/control_tests/${controlTestId}/interpretations/export_xls`;

    try {
      const response = await fetch(url, {
        credentials: "include", // required to include cookies
        body: JSON.stringify(data), // must match 'Content-Type' header
        headers: this.getHeaders(),
        method: "POST",
      });
      return await response.json();
    } catch (err) {
      Utils.handleError(err, "Xls");
    }
  }

  checkIfAttachmentReady(attachment) {
    this.pollForAttachmentReadiness(attachment).then(response => {
      if (this._isMounted) {
        const { state } = response;
        if (state === DONE_STATE) {
          this.updateDownloadLink(this.getUrl(attachment, "xls"));
        } else {
          delay(() => {
            this.checkIfAttachmentReady(response);
          }, DEFAULT_TIMEOUT);
        }
      }
    });
  }

  async pollForAttachmentReadiness(attachment) {
    try {
      const response = await fetch(this.getUrl(attachment, "json"), {
        credentials: "include", // required to include cookies
        headers: this.getHeaders(),
        method: "GET",
      });
      return await response.json();
    } catch (err) {
      Utils.handleError(err, "Xls");
    }
  }

  updateDownloadLink(url) {
    this.setState({ isRunning: false, submitHref: url });
  }

  render() {
    return (
      <div className="download-xls-modal__content">
        {this.state.isRunning ? (
          <Spinner caption={services.Localize.getLocalizedString("_Export.Xls.processing_")} />
        ) : (
          <>
            <Toast hasCloseButton={false} canAutoClose autoCloseDelay={1500} kind="visually-hidden">
              {services.Localize.getLocalizedString("_Export.Xls.readyMessage_")}
            </Toast>
            <p className="processing-message">
              <a href={this.state.submitHref} className="download-message">
                {services.Localize.getLocalizedString("_Export.Xls.ready_")}
              </a>
            </p>
          </>
        )}
      </div>
    );
  }
}
