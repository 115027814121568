/**
 * @name acl-checklist-input directive
 *
 * @description
 * aclChecklistInput lets us show a checklist,
 * check if it's valid (will be false when acl-required evaluates to true and nothing has been selected),
 * and stick the chosen values (the text of them — taken from the data referenced by aclRepeatOn) into
 * the location specified by aclSelectedModel.
 *
 * (Really this could be improved by replacing 'aclSelectedModel' with ng-model,
 * and 'aclIsValidModel' with form.element.$valid. But I didn't know how to do it quickly with isolated
 * scope, and I didn't know the consequences of making it non-isolated scope; and it works as implemented
 * with isolated scope, so... as long as it works, I won't rock the boat at this time. —Daryn Mitchell, Mar 2014)
 *
 * @param aclRepeatOn Takes the model value (array or collection) that will be repeated on
 * @param aclSelectedModel takes the a model representing the selected values. This model is an array of objects,
 *        one for each of the options to choose from, with the following properties (this is needed to handle
 *        case where there are duplicate options):
 *          value {String} The option value being displayed for selection
 *          isChecked {Boolean} [optional] set to true when the option is checked
 * @param aclIsValidModel takes the model value that gets set true when the input is valid, false when not.
 *        That all hinges on aclRequired...
 * @param aclRequired takes the model value that when true, will cause the input to be not valid when nothing is selected
 * @param aclChecklistFocusModel takes a model value, and sets it to set true when one of the list items is
 *        focused, and false when none of the checklist itmes are focused.
 */
angular.module("acl.common.input").directive("aclChecklistInput", function() {
  return {
    restrict: "E",
    replace: true,
    scope: {
      aclRepeatOn: "=",
      aclSelectedModel: "=",
      aclIsValidModel: "=",
      aclRequired: "=",
      aclChecklistFocusModel: "=",
    },
    template:
      "" +
      "<div>" +
      '<div class="inputParams"' +
      '     ng-repeat="aclChecklistInputInternalItem in aclRepeatOn track by $index"' +
      '     ng-init="checkboxIndex = $index">' + // alias the index so we can reference it in ng-model
      '<acl-checklist-checkbox-input ng-model="aclChecklistInputInternalValuesModel[checkboxIndex]" ' +
      'acl-focus-model="oneOfTheCheckboxes.isFocused">' +
      "{{aclChecklistInputInternalItem}}" +
      "</acl-checklist-checkbox-input>" +
      "</div>" +
      "</div>",
    link: function(scope, element, attr) {
      var iValueModel;

      enforceRequiredAttributes();

      scope.aclChecklistInputInternalValuesModel = [];
      if (scope.aclRepeatOn) {
        for (iValueModel = 0; iValueModel < scope.aclRepeatOn.length; ++iValueModel) {
          scope.aclChecklistInputInternalValuesModel[iValueModel] = false;
        }
      }

      // Setup the 'checklist is focused' plumbing
      if (attr.aclChecklistFocusModel) {
        scope.oneOfTheCheckboxes = {};

        scope.$watch("oneOfTheCheckboxes.isFocused", function(newValue) {
          scope.aclChecklistFocusModel = newValue;
        });
      }

      // Model (acl-selected-model) --> UI Checklist
      scope.$watch("aclSelectedModel", function(newSelectedModel) {
        var remainingNewSelections;
        if (scope.aclRepeatOn) {
          // We need to jump through some hoops to handle
          // 1) A checklist that can have duplicate values (multiple options have same text), and
          // 2) A model that can be minimal, i.e. only those values that are true are present (this
          //      happens when we retrieve the parameter set)
          // Therefore, make a copy of the model and remove an item each time we process it
          remainingNewSelections = angular.copy(newSelectedModel);

          scope.aclRepeatOn.forEach(function(checklistOption, checklistIndex) {
            var firstMatchingNewSelection;
            var selectionsThatMatchCurrentChecklistOption;
            var currentChecklistItemShouldBeSelected = false;
            var indexOfSelectionToRemoveNowThatWeSelectedIt;

            if (remainingNewSelections) {
              selectionsThatMatchCurrentChecklistOption = remainingNewSelections.filter(function(d) {
                return d.value === checklistOption;
              });
              currentChecklistItemShouldBeSelected = selectionsThatMatchCurrentChecklistOption.length > 0;
            }

            if (currentChecklistItemShouldBeSelected) {
              firstMatchingNewSelection = selectionsThatMatchCurrentChecklistOption[0];
              scope.aclChecklistInputInternalValuesModel[checklistIndex] = firstMatchingNewSelection.isChecked;

              // Now, remove the new selection value we just used.
              // (This is the fancy move to handle duplicate and missing values okay...)
              indexOfSelectionToRemoveNowThatWeSelectedIt = remainingNewSelections.indexOf(firstMatchingNewSelection);
              remainingNewSelections.splice(indexOfSelectionToRemoveNowThatWeSelectedIt, 1);
            } else {
              scope.aclChecklistInputInternalValuesModel[checklistIndex] = false;
            }
          });

          updateValidity();
        }
      });

      // UI Checklist --> Model (acl-selected-model)
      // This gets updated when any of the checkboxes changes a value in aclChecklistInputInternalValuesModel[checkboxIndex]
      scope.$watch(
        "aclChecklistInputInternalValuesModel",
        function(newChecklistFlags) {
          var checkboxIndex;
          var newCheckedObject;
          var selectedValues;
          if (newChecklistFlags) {
            selectedValues = [];

            for (checkboxIndex = 0; checkboxIndex < newChecklistFlags.length; ++checkboxIndex) {
              newCheckedObject = {
                value: scope.aclRepeatOn[checkboxIndex],
                isChecked: scope.aclChecklistInputInternalValuesModel[checkboxIndex],
              };
              selectedValues.push(newCheckedObject);
            }

            scope.aclSelectedModel = selectedValues;

            updateValidity();
          }
        },
        true
      );

      // This gets updated when the scope aclRequired value changes
      scope.$watch("aclRequired", function() {
        updateValidity();
      });

      function updateValidity() {
        var isValid;

        if (!scope.aclRequired) {
          isValid = true;
        } else {
          isValid =
            scope.aclSelectedModel &&
            scope.aclSelectedModel.some(function(checklistObject) {
              return checklistObject.isChecked;
            });
        }

        setIsValid(isValid);
      }

      function setIsValid(isValid) {
        var modelAttributeWasProvided = attr.aclIsValidModel;
        if (modelAttributeWasProvided) {
          scope.aclIsValidModel = isValid;
        }
      }

      function enforceRequiredAttributes() {
        if (!attr.aclRepeatOn) {
          throw new Error("<acl-checklist-input> element requires acl-repeat-on attribute");
        }

        if (!isAclRepeatSyntax(attr.aclRepeatOn)) {
          throw new Error(
            "<acl-checklist-input> expected acl-repeat-on attribute with name of the scope property collection to repeat on"
          );
        }

        if (!attr.aclSelectedModel) {
          throw new Error("<acl-checklist-input> element requires acl-selected-model attribute");
        }

        if (scope.aclSelectedModel && !angular.isArray(scope.aclSelectedModel)) {
          throw new Error(
            "<acl-checklist-input> element requires acl-selected-model to be an array (or not to exist yet), instead got a: " +
              typeof scope.aclSelectedModel
          );
        }

        function isAclRepeatSyntax(aclRepeatValue) {
          return aclRepeatValue.match(/^\s*[^\s]+\s*$/);
        }
      }
    },
  };
});
