import some from "lodash/some";
import sortBy from "lodash/sortBy";
import PropTypes from "prop-types";
import React from "react";
import Spinner from "@paprika/spinner";
import services from "../../../services/glue/appGlue";

class GroupsSelector extends React.Component {
  constructor(props) {
    super(props);

    // function binding
    this.handleFilterChange = this.handleFilterChange.bind(this);

    this.state = {
      filterString: "",
    };
  }

  getGroupRows() {
    let groups = sortBy(this.props.groups, group => group.name.toLowerCase());
    return groups.map(group => {
      let groupRow;
      if (this.state.filterString === "" || this.filterMatches(group.name)) {
        groupRow = (
          <UserSelectorRow
            key={group.id}
            isSelected={this.props.isGroupSelected(group.id, "workflow")}
            groupId={group.id}
            onClick={() => {
              this.props.handleRowClick(group.id, "workflow");
            }}
            name={group.name}
          />
        );
      }
      return groupRow;
    });
  }

  getLaunchpadGroupRows() {
    let groups = sortBy(this.props.launchpadGroups, group => group.name.toLowerCase());
    return groups.map(group => {
      let groupRow;
      if (this.state.filterString === "" || this.filterMatches(group.name)) {
        groupRow = (
          <UserSelectorRow
            key={group.id}
            isSelected={this.props.isGroupSelected(group.id, "launchpad")}
            groupId={group.id}
            onClick={() => {
              this.props.handleRowClick(group.id, "launchpad");
            }}
            name={group.name}
          />
        );
      }

      return groupRow;
    });
  }

  filterMatches(...attributes) {
    return some(
      attributes,
      attribute => attribute && attribute.toLowerCase().indexOf(this.state.filterString.toLowerCase()) > -1
    );
  }

  handleFilterChange(e) {
    this.setState({ filterString: e.target.value });
  }
  showSpinner() {
    return (
      <div className="selection__spinner">
        <Spinner
          caption={services.Localize.getLocalizedString("_Storyboards.SharePanel.UserSelector.spinnerMessage")}
        />
      </div>
    );
  }

  showSelectorPanel() {
    return (
      <>
        <div className="user-selector-wrapper">
          <div className="search-panel">
            <i className="acl-i-search" />
            <input
              type="text"
              value={this.state.filterString}
              onChange={this.handleFilterChange}
              placeholder={services.Localize.getLocalizedString(
                "_Storyboards.SharePanel.GroupSelector.FilterGroupPlaceholder_"
              )}
            />
          </div>

          <div ref="userGroupList" className="selection">
            {this.props.launchpadGroups && this.props.launchpadGroups.length > 0 ? (
              <>
                <div className="selection__sub-header">
                  <span className="selection__sub-header-text">
                    {services.Localize.getLocalizedString(
                      "_Storyboards.SharePanel.UserSelector.LaunchpadGroupSubheader_"
                    )}
                  </span>
                </div>
                <div>{this.getLaunchpadGroupRows()}</div>
              </>
            ) : null}

            {this.props.groups && this.props.groups.length > 0 ? (
              <>
                <div className="selection__sub-header">
                  <span className="selection__sub-header-text">
                    {services.Localize.getLocalizedString("_Storyboards.SharePanel.UserSelector.GroupSubheader_")}
                  </span>
                </div>
                <div> {this.getGroupRows()}</div>
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  }

  render() {
    return this.props.isLoading ? this.showSpinner() : this.showSelectorPanel();
  }
}

GroupsSelector.propTypes = {
  groups: PropTypes.array.isRequired,
  launchpadGroups: PropTypes.array.isRequired,
  isGroupSelected: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default GroupsSelector;
