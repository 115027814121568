import Model from "../model";

class ConditionalFilter extends Model {
  static modelType = "ConditionalFilter";

  operator(...args) {
    return this.accessor("operator", args, "string");
  }

  value1(...args) {
    return this.accessor("value1", args, "*");
  }

  value2(...args) {
    return this.accessor("value2", args, "*");
  }

  value3(...args) {
    return this.accessor("value3", args, "*");
  }

  excludeToday(...args) {
    return this.accessor("excludeToday", args, "boolean");
  }

  clone() {
    return new ConditionalFilter(this._data);
  }
}

export default ConditionalFilter;
