import UrlFlipper from "@viz-ui/services/urlFlipper/urlFlipper";
import logger from "@viz-ui/services/logger/logger";
import LocalStorageHelper from "@viz-ui/services/localStorage/localStorageHelper";
import { patternFillPalettes } from "./helpers/patternFillPalettes";

export class HighChartDirective {
  restrict = "E";
  replace = true;
  scope = {
    config: "<",
    data: "<",
    fontZoomRatio: "<",
    labelFontSize: "<",
    onClick: "&?",
    zoomInHandler: "<?",
  };
  templateUrl = "visualizer/js/modules/visualization/highCharts/highChart.tpl.html";
  controllerAs = "highChart";
}

export class HighChartController {
  constructor($scope, $timeout, HighChartLabelSizer, AppConfig) {
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.HighChartLabelSizer = HighChartLabelSizer;
    this.isClickable = angular.isFunction(this.$scope.onClick);
    this.hcConfig = angular.copy(this.baseConfig);
    this.translations = {};
    const numberOfPatterns = patternFillPalettes.length;
    this.patternFillPalettes = index =>
      Math.floor(index / numberOfPatterns) >= 1
        ? patternFillPalettes[index - Math.floor(index / numberOfPatterns) * numberOfPatterns]
        : patternFillPalettes[index];
    this.isNotResultPage = !location.host.includes("results");
    this.AppConfig = AppConfig;
    this.handleRef = ref => {
      this.chart = ref && ref.chart;
      return this.chart;
    };

    $scope.$watch("data", this.update);
    $scope.$watch("config", this.update);
    $scope.$watch("fontZoomRatio", this.update);
    $scope.$watch("labelFontSize", this.update);

    $scope.$on("chartRedraw", () => {
      if (this.chart) this.chart.reflow();
    });

    this.redrawIndex = 0;
  }

  get baseConfig() {
    return {
      title: {
        text: "",
      },
      boost: this.getBoostConfig(),
      credits: {
        enabled: false,
      },
      chart: {
        backgroundColor: "transparent",
        events: {
          click: this.handleClick,
          render: this.handleRender,
        },
      },
    };
  }

  update = () => {
    if (!this.updatePending) {
      this.$scope.$evalAsync(() => {
        this.$scope.isPatternFill =
          (UrlFlipper.isEnabled("isPatternFill") || LocalStorageHelper.get("applyPatternFill")) && this.isNotResultPage;
        logger.log(`getConfig - Start`);

        const config =
          !this.isReactChart(this.$scope.$parent.chartViz) && this.$scope.config && this.$scope.data
            ? this.getConfig(this.$scope.config, this.$scope.data)
            : {};
        logger.log(`getConfig - End`);

        this.hcConfig = angular.extend(angular.copy(this.baseConfig), config);
        this.updatePending = false;
      });
      this.updatePending = true;
    }
  };
  isReactChart = chartViz => {
    if (this.AppConfig && chartViz)
      return (
        (this.AppConfig.features.migrateLineChart && chartViz.vizType === "LineChart") ||
        (this.AppConfig.features.migrateBarChart && chartViz.vizType === "BarChart") ||
        (this.AppConfig.features.migratePieChart && chartViz.vizType === "PieChart") ||
        (this.AppConfig.features.migrateStackedAreaChart && chartViz.vizType === "StackedAreaChart") ||
        (this.AppConfig.features.migrateHeatMapChart && chartViz.vizType === "Heatmap") ||
        (this.AppConfig.features.migrateStatisticsChart && chartViz.vizType === "StatisticsViz") ||
        (this.AppConfig.features.migrateSummaryTableChart && chartViz.vizType === "SummaryTable")
      );
  };
  updateTranslations = translations => {
    this.translations = translations;
  };

  updateHcConfig = config => {
    this.hcConfig = { ...this.hcConfig, ...config };
  };

  canAnimate = () => {
    return UrlFlipper.isRenderMode("export") ? false : true;
  };

  updateLabelFontSize = (config, fontSizeConfigPaths) =>
    this.HighChartLabelSizer.updateLabelFontSize(
      config,
      this.$scope.labelFontSize,
      fontSizeConfigPaths,
      this.$scope.fontZoomRatio
    );

  clickWrapper = (onClick, onDblClick) => {
    let $timeout = this.$timeout;
    let target = null;
    let timeout = null;
    let data = null;
    return function(arg) {
      let e = arg instanceof MouseEvent ? arg : arg.browserEvent;
      $timeout.cancel(timeout);
      if (onDblClick && e.target === target) {
        $timeout(() => {
          onDblClick.apply(null, [this]);
        });
      } else {
        target = e.target;
        data = angular.copy(this);
        timeout = $timeout(() => {
          if (onClick) {
            onClick.apply(null, [data]);
          }
          target = null;
        }, 300);
      }
      return false;
    };
  };

  handleClick = e => {
    if (angular.isFunction(this.$scope.onClick)) {
      this.$scope.onClick(e);
    }
  };

  handleRender = () => {
    this.redrawIndex++;
  };

  getBoostConfig = (config, conditionalEnable) => {
    config = config || {};
    const boostFlipper = UrlFlipper.isRenderMode("boost");
    const isEnabled = config.enableBoost !== false && conditionalEnable !== false;
    return {
      enabled: boostFlipper && isEnabled,
      allowForce: true,
      seriesThreshold: 150,
    };
  };
}
