import i18n from "@viz-ui/i18n/i18n";

const types = [
  {
    displayName: i18n.t("_Chart.Metrics.KeyPerformanceIndicator.Label_"),
    fieldName: "kpi",
  },
  {
    displayName: i18n.t("_Chart.Metrics.KeyRiskIndicator.Label_"),
    fieldName: "kri",
  },
  {
    displayName: i18n.t("_Chart.Metrics.KeyControlIndicator.Label_"),
    fieldName: "kci",
  },
  {
    displayName: i18n.t("_Chart.Metrics.OtherMetric.Label_"),
    fieldName: "other",
  },
];

class MetricTypes {
  getMetricTypes() {
    return types;
  }
}

export default new MetricTypes();
