import backendApi from "@results/services/apiCall/backendApi";
import $ from "jquery";
import ApiPath from "@viz-ui/services/apiPath/apiPathService";

angular.module("highbond.visualizer.navigation").factory("HighbondNavigationService", function(AppConfig, $window) {
  const service = {
    getGlobalNavProps: async () => {
      if (AppConfig.highbondNavBarProps) {
        return Promise.resolve(AppConfig.highbondNavBarProps);
      }

      const redirectParamForLoginUrl = `?redirect_uri=${$window.location.href}`;
      const req = {
        method: "GET",
        url: ApiPath.highbond.getGlobalNavBarUrl() + redirectParamForLoginUrl,
        headers: {
          Accept: "application/json",
        },
      };

      return backendApi.http(req).then(response => {
        // Setting AppConfig.highbondNavBarProps to prevent multiple API calls
        const highbondNavBarProps = response.data.data;
        AppConfig.highbondNavBarProps = highbondNavBarProps;
        return highbondNavBarProps;
      });
    },

    getGlobalNavPropsSync: () => {
      if (AppConfig.highbondNavBarProps) {
        return AppConfig.highbondNavBarProps;
      }

      let response = {};
      const redirectParamForLoginUrl = `?redirect_uri=${$window.location.href}`;
      const url = backendApi.getLoganUrl() + ApiPath.highbond.getGlobalNavBarUrl() + redirectParamForLoginUrl;

      $.ajax({
        url: url,
        type: "GET",
        async: false,
        headers: {
          Accept: "application/json",
        },
        xhrFields: {
          withCredentials: true,
        },
        success: res => {
          response = res.data;
        },
      });

      return response;
    },
  };

  return service;
});
