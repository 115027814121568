import Model from "../model";

class CriteriaFilter extends Model {
  static modelType = "CriteriaFilter";

  filterType() {
    return "criteria";
  }

  operator(...args) {
    return this.accessor("operator", args, "string");
  }

  name(...args) {
    return this.accessor("name", args, "string");
  }

  connector(...args) {
    return this.accessor("connector", args, "string");
  }

  values(...args) {
    return this.accessor("values", args, ["*"]);
  }

  showStoryboardIndicator(...args) {
    return this.accessor("showStoryboardIndicator", args, "boolean");
  }

  static fromJson(json) {
    return new CriteriaFilter()
      .operator(json.operator)
      .connector(json.connector)
      .values(json.values)
      .name(json.name)
      .showStoryboardIndicator(json.showStoryboardIndicator);
  }

  toJson() {
    return this._deleteUndefinedProperties({
      operator: this.operator(),
      connector: this.connector(),
      values: this.values(),
      name: this.name(),
      showStoryboardIndicator: this.showStoryboardIndicator(),
    });
  }
}

export default CriteriaFilter;
