import React from "react";
import PropTypes from "prop-types";
import ChartArrow from "@acl-services/wasabicons/lib/ChartArrow";
import "./visualIndicator.scss";

class VisualIndicator extends React.Component {
  static propTypes = {
    size: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
  };

  static defaultProps = {
    height: "15px",
    width: "15px",
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="indicator-container">
        <ChartArrow height={this.props.height} width={this.props.width} style={{ color: "white" }}></ChartArrow>
      </div>
    );
  }
}

export default VisualIndicator;
