import InputValidation from "@viz-ui/services/common/InputValidationService";

angular.module("acl.common.input").directive("aclTimeInput", function($filter, Localize) {
  return {
    restrict: "E",
    replace: true,
    require: "ngModel",
    scope: true,
    template: function(tElem, attrs) {
      // validate time with format HH:mm:ss or HH:mm
      var timeFormatRegex = InputValidation.time.regexString.wrappedWithForwardSlashes;
      var template = '<input type="text" ng-pattern="' + timeFormatRegex + '" $0>';
      var defaultPlaceholder = Localize.getLocalizedString("_Input.Time.24hrPatternToShowUser_");
      var placeholderAttr = angular.isDefined(attrs.placeholder) ? "" : ' placeholder="' + defaultPlaceholder + '"';
      return $filter("format")(template, placeholderAttr);
    },
    link: {
      pre: function preLink(scope, element, attrs, ngModelCtrl) {
        // Setting the parent model from a child scope (as we do in the blur handler) requires the model
        // attribute to not be a primitive. https://github.com/angular/angular.js/wiki/Understanding-Scopes
        if (attrs.ngModel.indexOf(".") === -1) {
          throw new Error(
            "<acl-time-input> ng-model value (" +
              attrs.ngModel +
              ") is not valid. It must *not* be a scope primitive — i.e. it must have a dot."
          );
        }

        function padWithZeroesIfNeeded() {
          // We want to let the user
          //  - Enter the hour with just 1 digit ("2:00:00" OK, not just "02:00:00"
          //  - Omit the seconds and assume it's zero ("12:00" OK, assume they mean "12:00:00")
          // But the the time value passed to backend REQUIRES both 2 digit hours and seconds.
          // So we fill in the missing zeroes if needed:

          var hourComponent;
          var startsWithSingleHourDigit;
          var secondsWereOmitted;

          var value = element.val();
          var timeComponents = value.split(":");

          if (timeComponents.length >= 2) {
            hourComponent = timeComponents[0];

            startsWithSingleHourDigit = hourComponent.length === 1;
            secondsWereOmitted = timeComponents.length === 2;

            if (startsWithSingleHourDigit) {
              value = "0" + value; // Prefix with leading zero to make it 2-digit hour
            }

            if (secondsWereOmitted) {
              value += ":00";
            }

            if (startsWithSingleHourDigit || secondsWereOmitted) {
              scope.$apply(function() {
                element.val(value);
                ngModelCtrl.$setViewValue(value);
              });
            }
          }
        }

        element.bind("keypress", function(e) {
          var enter = 13;
          if (e.which === enter) {
            padWithZeroesIfNeeded();
          }
        });

        element.bind("blur", function() {
          padWithZeroesIfNeeded();
        });
      },
    },
  };
});
