import filterConditions from "@viz-ui/models/storyboardFilter/storyboardFilterConditions";
import RelativeDateTranslator from "@viz-ui/models/relativeDate/relativeDateTranslator";
import filterTypes from "@viz-ui/models/storyboardFilter/storyboardFilterTypes";
import checkboxState from "@viz-ui/models/checkboxState";
import { DateFormatter, DatetimeFormatter, TimeFormatter } from "@acl-services/sriracha-formatters/dist/Formatters";
import globalFiltersStore from "./GlobalFiltersStore";

class DynamicFilter {
  //set initial dynamic filter on clikcing the filter panel controls

  setInitialDynamicFilter = filter => {
    const filterFields = filter._data.fields;
    this.removeCurrentDynamicFilter(filterFields);
    if (!filter.isFrozenFilter()) {
      filter.isSelectedDynamicFilter(true);
      filter.isSelectedFrozenFilter(true);
      filter.isCurrentSessionFilter(false); // setting current session filter to false on init
      // globalFiltersStore.update(filter); //update global filter store before removing other dynamic filters
    }
  };

  removeCurrentDynamicFilter = filterFields => {
    //remove current dynamic filter for other filter panels
    const dynamicFilters = globalFiltersStore.get();
    let isTableIDExist = false;
    dynamicFilters.forEach(item => {
      const currentFileds = item._data.fields;
      filterFields.forEach(filterField => {
        if (currentFileds.some(currentFiled => currentFiled.tableId() === filterField.tableId())) {
          isTableIDExist = true;
        }
      });
      if (isTableIDExist) {
        let newFilter = item.clone();
        newFilter.isCurrentDynamicFilter(false);
        newFilter.isSelectedDynamicFilter(false);
        globalFiltersStore.update(newFilter);
      }
    });
  };

  //update current dynamic filter on applyng filter
  setCurrentDynamicFilter = dynamicFilters => {
    dynamicFilters.forEach(item => {
      let newFilter = item.clone();
      //dont set frezeen filter and dynamic filter if filter has no values selected
      if (newFilter.values() && newFilter.values().length > 0 && newFilter.isSelectedDynamicFilter()) {
        newFilter.isCurrentDynamicFilter(true);
      }
      if (newFilter.values() && newFilter.values().length > 0 && newFilter.isSelectedFrozenFilter()) {
        newFilter.isFrozenFilter(true);
      } else {
        newFilter.isSelectedFrozenFilter(false);
      }
      globalFiltersStore.update(newFilter);
    });
  };

  //validate if the conditional filter value is valid
  isValidForConditionalFilterQuery = filter => {
    let realValues = [];
    if (filter.operator()) {
      switch (filter.operator()) {
        case filterConditions.BLANK:
        case filterConditions.NOT_BLANK:
          return true;
        case filterConditions.BETWEEN_OR_EQUAL_TO:
          if (!filter.values()) return false;
          realValues = filter.values().filter(v => !v.isBlank());
          return (
            realValues.length === 2 &&
            realValues.every(v => this.isValidConditionalValue(v.value(), filter.fieldType(), filter.fieldFormat()))
          );
        case filterConditions.RELATIVE:
          if (!filter.values()) return false;
          realValues = filter
            .values()
            .filter(v => !v.isBlank())
            .map(v => v.value());
          if (realValues[1] === "1") return false;
          return RelativeDateTranslator.isRelativeValueValid(realValues);
        default:
          if (!filter.values()) return false;
          realValues = filter.values().filter(v => !v.isBlank());
          return (
            realValues.length > 0 &&
            realValues.every(v => this.isValidConditionalValue(v.value(), filter.fieldType(), filter.fieldFormat()))
          );
      }
    }

    return false;
  };

  isValidConditionalValue = (value, fieldType, fieldFormat) => {
    switch (fieldType) {
      case "numeric":
        return !Number.isNaN(value);
      case "datetime":
        return fieldFormat && fieldFormat.displayTime() !== false
          ? DatetimeFormatter.isUnformatted(value)
          : DateFormatter.isUnformatted(value);
      case "date":
        return DateFormatter.isUnformatted(value);
      case "time":
        return TimeFormatter.isValid(value);
      default:
        return true;
    }
  };

  //set filter as frozen filter on load
  setFrozenFilteronLoad = filter => {
    const newFilter = filter.clone();
    switch (newFilter.type()) {
      case filterTypes.OPTIONAL_SINGLESELECT:
      case filterTypes.MANDATORY_SINGLESELECT:
        if (newFilter.value()) {
          newFilter.isFrozenFilter(true);
        }
        break;
      case filterTypes.MULTISELECT:
        if (
          (newFilter.values() && newFilter.values().length > 0) ||
          newFilter.selectAllState() === checkboxState.CHECKED
        ) {
          newFilter.isFrozenFilter(true);
        }
        break;
      case filterTypes.CONDITIONAL:
        if (this.isValidForConditionalFilterQuery(newFilter)) {
          newFilter.isFrozenFilter(true);
        }
        break;
      default:
        break;
    }
    globalFiltersStore.update(newFilter);
  };

  //logic to handle hiding unselected filters
  hideUnselectedValueForFrozenFilter(filter, flipDynamicFilter) {
    const newFilter = filter.clone();
    if (!flipDynamicFilter) {
      return false;
    }

    switch (newFilter.type()) {
      case filterTypes.OPTIONAL_SINGLESELECT:
      case filterTypes.MANDATORY_SINGLESELECT:
        return newFilter.value() && !newFilter.isCurrentDynamicFilter() && newFilter.isFrozenFilter();

      case filterTypes.MULTISELECT:
        return (
          newFilter.values() &&
          newFilter.values().length !== 0 &&
          !newFilter.isCurrentDynamicFilter() &&
          newFilter.isFrozenFilter()
        );

      default:
        break;
    }
  }
}
export default new DynamicFilter();
