angular.module("acl.visualizer.summaryTable.dataConfig").component("aclSummaryTableDataConfig", {
  restrict: "E",
  templateUrl: "visualizer/js/modules/visualization/summaryTable/summaryTableDataConfig.tpl.html",
  controller: function SummaryTableDataConfig($scope, Localize, DataModel, AppConfig) {
    var summaryTableConfig = this;
    summaryTableConfig.isApplyButtonEnabled = false;
    summaryTableConfig.showFreezeRowHeaders = AppConfig.features.summaryTableFreezeRowHeaders;
    summaryTableConfig.freezeRowHeadersText = Localize.getLocalizedString("_ChartConfig.Rows.FreezeRowHeaders.Text_");
    summaryTableConfig.freezeRowHeadersTooltip = Localize.getLocalizedString(
      "_ChartConfig.Rows.FreezeRowHeaders.InfoIcon.Tooltip_"
    );
    initDataConfig();

    $scope.$watch("summaryTableConfig.aclDataConfig", function(dataConfig) {
      if (dataConfig) {
        if (dataConfig.chartRows && dataConfig.chartColumns && dataConfig.chartValues) {
          summaryTableConfig.dataConfig = angular.copy(dataConfig);
          summaryTableConfig.dataConfig.chartValues = convertCountValueField(summaryTableConfig.dataConfig.chartValues);
        } else {
          initDataConfig();
        }
        updateApplyButtonEnable();
        summaryTableConfig.selectedColumn = summaryTableConfig.dataConfig.chartColumns[0];
        summaryTableConfig.showToastMessage = true;
      }
    });

    summaryTableConfig.addRow = function(selectedRows) {
      summaryTableConfig.dataConfig.chartRows = angular.copy(selectedRows);
      updateApplyButtonEnable();
    };

    summaryTableConfig.freezeRowHeadersOnChange = function(isChecked) {
      summaryTableConfig.dataConfig.freezeRowHeaders = isChecked;
      handleDataConfigChange();
    };

    summaryTableConfig.addColumn = function() {
      if (summaryTableConfig.selectedColumn) {
        summaryTableConfig.dataConfig.chartColumns = [summaryTableConfig.selectedColumn];
      } else {
        summaryTableConfig.dataConfig.chartColumns = [];
      }
      updateApplyButtonEnable();
    };

    summaryTableConfig.addValue = function(selectedValues) {
      summaryTableConfig.dataConfig.chartValues = angular.copy(selectedValues);
    };

    summaryTableConfig.isValidField = fieldName => !fieldName || DataModel.table.fieldExists(fieldName);

    summaryTableConfig.fieldDisplayName = selectedField => {
      if (selectedField === undefined) return "";
      if (!summaryTableConfig.isValidField(selectedField.fieldName)) {
        return Localize.getLocalizedString("_ChartConfig.CloseButton.DeletedOption_");
      }

      return selectedField.displayName;
    };

    summaryTableConfig.handleDataConfigChange = function() {
      handleDataConfigChange();
    };

    function convertCountValueField() {
      return summaryTableConfig.dataConfig.chartValues.map(function(value) {
        if (value.aggregationType === "count") {
          return {
            fieldName: "Count",
            displayName: Localize.getLocalizedString("_SummaryTable.Count.Label_"),
            type: "count",
          };
        }
        if (value.aggregationType) delete value.aggregationType;
        return value;
      });
    }

    function initDataConfig() {
      summaryTableConfig.dataConfig = {
        chartRows: [],
        chartColumns: [],
        chartValues: [],
        freezeRowHeaders: false,
      };
    }

    function parseConfig() {
      var config = angular.copy(summaryTableConfig.dataConfig);
      angular.forEach(config, function(fields, key) {
        if (key === "chartValues") {
          config[key] = fields.map(function(opt) {
            if (opt.type === "count") {
              return {
                aggregationType: opt.type,
                type: "numeric",
                fieldName: "",
              };
            }
            opt.aggregationType = "sum";
            return opt;
          });
        }
      });
      return config;
    }

    function handleDataConfigChange() {
      var config = parseConfig();
      summaryTableConfig.onDataConfigChange({ dataConfig: config });
    }

    function updateApplyButtonEnable() {
      summaryTableConfig.isApplyButtonEnabled = isValidDataConfig(summaryTableConfig.dataConfig);
    }

    function isValidDataConfig(dataConfig) {
      if (dataConfig.chartRows.length < 1 && dataConfig.chartColumns.length < 1) return false;
      return true;
    }
  },
  controllerAs: "summaryTableConfig",
  bindings: {
    aclDataConfig: "<",
    columnDefs: "<",
    onDataConfigChange: "&",
    removeTab: "&",
  },
});
