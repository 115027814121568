import Field from "@viz-ui/models/field/field";
import FieldAdapter from "@viz-ui/models/field/fieldAdapter";
import FieldFormat from "@viz-ui/models/field/fieldFormat";
import FieldFormatAdapter from "@viz-ui/models/field/fieldFormatAdapter";

angular
  .module("acl.visualizer.model.field", ["acl.visualizer.model.model"])
  .factory("Field", () => Field)
  .factory("FieldAdapter", () => FieldAdapter)
  .factory("FieldFormat", () => FieldFormat)
  .factory("FieldFormatAdapter", () => FieldFormatAdapter);
