angular.module("acl.visualizer.charts").factory("BubbleChart", function(ChartService, DataModel, AppConfig) {
  return {
    defaultDisplayConfig: function() {
      return {
        showLegend: true,
        displayDataLabels: true,
        enableBoost: true,
        bubbleScale: "1",
        xAxis: {
          showLabel: true,
        },
        yAxis: {
          showLabel: true,
          minimum: null,
          maximum: null,
        },
      };
    },

    getChartDirectiveConfig: function(interpretationId, chartConfig) {
      var getColorByField = function() {
        return (
          (chartConfig &&
            chartConfig.dataConfig &&
            chartConfig.dataConfig.chartSeries &&
            chartConfig.dataConfig.chartSeries.fieldName) ||
          ""
        );
      };

      function valueFormatter(interpretationId, chartConfig) {
        const { vizId, dataConfig, displayConfig } = chartConfig;
        if (displayConfig.valueFormattingOptions && dataConfig.chartValue.aggregationType === "average") {
          const chartValueFieldName = `${vizId}-chart-value`;
          const chartValueFieldType = "numeric";
          return ChartService.getValueFormatter(interpretationId, chartValueFieldName, chartValueFieldType);
        }
        return ChartService.getValueFormatter(
          interpretationId,
          dataConfig.chartValue.fieldName,
          dataConfig.chartValue.type
        );
      }

      return {
        valueLabel: ChartService.getFieldLabel(chartConfig.dataConfig.chartValue),
        valueFormatter: valueFormatter(interpretationId, chartConfig),
        hasMultipleSeries: !!(chartConfig.dataConfig.chartSeries && chartConfig.dataConfig.chartSeries.fieldName),
        showLegend: chartConfig.displayConfig.showLegend,
        enableBoost: chartConfig.displayConfig.enableBoost,
        displayDataLabels: chartConfig.displayConfig.displayDataLabels,
        xAxis: {
          label: chartConfig.displayConfig.xAxis.showLabel
            ? chartConfig.displayConfig.xAxis.title
              ? chartConfig.displayConfig.xAxis.title
              : chartConfig.dataConfig.chartXAxis.displayName
            : "",
          tickFormatter: ChartService.getValueFormatter(
            interpretationId,
            chartConfig.dataConfig.chartXAxis.fieldName,
            chartConfig.dataConfig.chartXAxis.type
          ),
          title: chartConfig.displayConfig.xAxis.title,
          axisType: chartConfig.dataConfig.chartXAxis.type,
        },
        yAxis: {
          label: chartConfig.displayConfig.yAxis.showLabel
            ? chartConfig.displayConfig.yAxis.title
              ? chartConfig.displayConfig.yAxis.title
              : chartConfig.dataConfig.chartYAxis.displayName
            : "",
          tickFormatter: ChartService.getValueFormatter(
            interpretationId,
            chartConfig.dataConfig.chartYAxis.fieldName,
            chartConfig.dataConfig.chartYAxis.type
          ),
          title: chartConfig.displayConfig.yAxis.title,
          axisType: chartConfig.dataConfig.chartYAxis.type,
          minimum: chartConfig.displayConfig.yAxis.minimum,
          maximum: chartConfig.displayConfig.yAxis.maximum,
        },
        DATA_ITEM_LIMIT: ChartService.getDataItemLimit(),
        colorByField: getColorByField(),
        labelFormatter: ChartService.getLabelFormatter(chartConfig, interpretationId),
        bubbleScaleMinSize: ChartService.BubbleScaleInfo.minSize(
          chartConfig.displayConfig.bubbleScale || this.defaultDisplayConfig().bubbleScale
        ),
        bubbleScaleMaxSize: ChartService.BubbleScaleInfo.maxSize(
          chartConfig.displayConfig.bubbleScale || this.defaultDisplayConfig().bubbleScale
        ),
      };
    },

    populateChartConfigColumnDefs: function(chartConfigColumnDefs) {
      var tempColumnDef;
      var fields = DataModel.table.fields();
      if (fields) {
        for (var fieldName in fields) {
          tempColumnDef = {};
          tempColumnDef.displayName = fields[fieldName].displayName;
          tempColumnDef.fieldId = fields[fieldName].colId;
          tempColumnDef.fieldName = fieldName;
          tempColumnDef.type = fields[fieldName].type;

          switch (tempColumnDef.type) {
            case "character":
            case "logical":
              if (AppConfig.features.logicalFieldsInCharts || tempColumnDef.type === "character") {
                chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
                chartConfigColumnDefs.chartSeries.push(tempColumnDef);
              }
              break;

            case "numeric":
              chartConfigColumnDefs.chartValue.push(tempColumnDef);
              chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
              break;

            case "date":
            /* Falls through */
            case "datetime":
            /* Falls through */
            case "time":
              chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
              break;
          }
        }
        chartConfigColumnDefs.chartYAxis = chartConfigColumnDefs.chartXAxis;
      }
      return chartConfigColumnDefs;
    },

    chartConfigColumnDefs: function() {
      return {
        chartSeries: [],
        chartValue: [],
        chartXAxis: [],
        chartYAxis: [],
      };
    },

    isValidDataConfig: function(dataConfig) {
      return !!(
        dataConfig &&
        dataConfig.chartXAxis &&
        dataConfig.chartYAxis &&
        dataConfig.chartValue &&
        ((dataConfig.chartValue.aggregationType && dataConfig.chartValue.fieldName) ||
          dataConfig.chartValue.aggregationType === "count")
      );
    },
  };
});
