import ReactDirective from "acl-ui/angular/reactDirective.umd";
import StoryBoardDrilldownButton from "@viz-ui/components/drillDownButton/storyboardDrilldownButton";
import PageHeader from "@viz-ui/components/PageHeader/PageHeader";
import aclDrilldownButton from "./aclDrilldownButton.component";
import aclTileLoading from "./tileLoading.component";

angular
  .module("acl.storyboard.board", [
    "acl.storyboard.visualization",
    "acl.visualizer.dataTable",
    "acl.visualizer.highCharts",
    "acl.visualizer.model.accessControl",
  ])
  .component("aclDrilldownButton", aclDrilldownButton)
  .component("aclTileLoading", aclTileLoading)
  .directive("storyBoardDrilldownButton", ReactDirective.getFactory(StoryBoardDrilldownButton))
  .directive("highbondPageheader", ReactDirective.getFactory(PageHeader));
