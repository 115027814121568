angular
  .module("acl.visualizer.charts")
  .factory("BarChart", function($filter, ChartDataSorter, ChartService, DataModel, Localize, AppConfig) {
    function getXAxisLabel(dataConfig, displayConfig, labelTemplate, hasMultipleSeries) {
      if (displayConfig.xAxis.showLabel) {
        if (displayConfig.xAxis.title) {
          return displayConfig.xAxis.title;
        }
        if (hasMultipleSeries) {
          return $filter("format")(labelTemplate, [
            dataConfig.chartXAxis.displayName,
            dataConfig.chartSeries.displayName,
          ]);
        }
        return dataConfig.chartXAxis.displayName;
      }
      return "";
    }

    function getYAxisLabel(dataConfig, displayConfig) {
      if (displayConfig.yAxis.showLabel) {
        if (displayConfig.yAxis.title) {
          return displayConfig.yAxis.title;
        }
        return ChartService.getFieldLabel(dataConfig.chartValue);
      }
      return "";
    }

    function getYAxisFormatter(interpretationId, chartConfig) {
      const { vizId, dataConfig, displayConfig } = chartConfig;
      if (displayConfig.valueFormattingOptions && dataConfig.chartValue.aggregationType === "average") {
        const chartValueFieldName = `${vizId}-chart-value`;
        const chartValueFieldType = "numeric";
        return ChartService.getValueFormatter(interpretationId, chartValueFieldName, chartValueFieldType);
      }
      return ChartService.getValueFormatter(
        interpretationId,
        dataConfig.chartValue.fieldName,
        dataConfig.chartValue.type
      );
    }

    return {
      defaultDisplayConfig: function() {
        return {
          horizontal: false,
          showLegend: true,
          displayDataLabels: true,
          showControls: true,
          enableBoost: true,
          showPercentageTicks: false,
          xAxis: {
            showAxis: true,
            showLabel: true,
          },
          yAxis: {
            showAxis: true,
            showLabel: true,
            minimum: null,
            maximum: null,
          },
          chartStyle: "group",
        };
      },

      getChartDirectiveConfig: function(interpretationId, chartConfig) {
        var getColorByField = function() {
          return (
            (chartConfig &&
              chartConfig.dataConfig &&
              chartConfig.dataConfig.chartSeries &&
              chartConfig.dataConfig.chartSeries.fieldName) ||
            ""
          );
        };
        var categoryLabelTemplate = Localize.getLocalizedString("_Chart.SingleNestedAxisLabel.Template_");
        var hasMultipleSeries = !!(chartConfig.dataConfig.chartSeries && chartConfig.dataConfig.chartSeries.fieldName);

        return {
          order: chartConfig.displayConfig.order,
          valuesOrder: chartConfig.displayConfig.valuesOrder,
          horizontal: chartConfig.displayConfig.horizontal,
          hasMultipleSeries: hasMultipleSeries,
          showLegend: chartConfig.displayConfig.showLegend,
          enableBoost: chartConfig.displayConfig.enableBoost,
          displayDataLabels: chartConfig.displayConfig.displayDataLabels,
          showControls: chartConfig.displayConfig.showControls,
          showPercentageTicks: chartConfig.displayConfig.showPercentageTicks,
          xAxis: {
            label: getXAxisLabel(
              chartConfig.dataConfig,
              chartConfig.displayConfig,
              categoryLabelTemplate,
              hasMultipleSeries
            ),
            tickFormatter: ChartService.getValueFormatter(
              interpretationId,
              chartConfig.dataConfig.chartXAxis.fieldName,
              chartConfig.dataConfig.chartXAxis.type
            ),
            title: chartConfig.displayConfig.xAxis.title,
            showAxis: chartConfig.displayConfig.xAxis.showAxis,
            fieldType: chartConfig.dataConfig.chartXAxis.type,
          },
          yAxis: {
            label: getYAxisLabel(chartConfig.dataConfig, chartConfig.displayConfig),
            tickFormatter: getYAxisFormatter(interpretationId, chartConfig),
            minimum: chartConfig.displayConfig.yAxis.minimum,
            maximum: chartConfig.displayConfig.yAxis.maximum,
            title: chartConfig.displayConfig.yAxis.title,
            showAxis: chartConfig.displayConfig.yAxis.showAxis,
          },
          chartSeries: chartConfig.dataConfig.chartSeries,
          DATA_ITEM_LIMIT: ChartService.getDataItemLimit(),
          colorByField: getColorByField(),
          labelFormatter: ChartService.getLabelFormatter(chartConfig, interpretationId),

          chartStyle: chartConfig.displayConfig.chartStyle,
        };
      },

      populateChartConfigColumnDefs: function(chartConfigColumnDefs) {
        var tempColumnDef;
        var fields = DataModel.table.fields();
        if (fields) {
          for (var fieldName in fields) {
            tempColumnDef = {};
            tempColumnDef.displayName = fields[fieldName].displayName;
            tempColumnDef.fieldName = fieldName;
            tempColumnDef.type = fields[fieldName].type;

            switch (tempColumnDef.type) {
              case "character":
              case "logical":
                if (AppConfig.features.logicalFieldsInCharts || tempColumnDef.type === "character") {
                  chartConfigColumnDefs.chartSeries.push(tempColumnDef);
                  chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
                }
                break;
              case "numeric":
                chartConfigColumnDefs.chartValue.push(tempColumnDef);
                chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
                break;
              case "date":
              case "datetime":
              case "time":
                chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
                break;
            }
          }
        }
        return chartConfigColumnDefs;
      },

      chartConfigColumnDefs: function() {
        return {
          chartSeries: [],
          chartXAxis: [],
          chartValue: [],
        };
      },

      isValidDataConfig: function(dataConfig) {
        return !!(
          dataConfig &&
          dataConfig.chartXAxis &&
          dataConfig.chartValue &&
          ((dataConfig.chartValue.fieldName && dataConfig.chartValue.aggregationType) ||
            dataConfig.chartValue.aggregationType === "count")
        );
      },

      reorderValues: function(chartData, sortConfig) {
        return ChartDataSorter.reorderValues(chartData, sortConfig);
      },

      getPercentage(data, categories, multipleSeries) {
        let keysTotal = [];
        if (multipleSeries) {
          categories.forEach(category => {
            let total = 0;
            data.forEach(d => {
              d.values.forEach(i => {
                if (i.key === category) {
                  total += Math.abs(parseFloat(i.values === null ? 0 : i.values));
                }
              });
            });
            keysTotal.push({ key: category, total: total.toFixed(2) });
          });
          keysTotal.forEach(item => {
            data = data.map(d => ({
              ...d,
              values: d.values.map(i => {
                if (i.key === item.key) {
                  let value = i.values === null ? 0 : i.values;
                  value = value < 0 ? -1 * value : value;
                  let percentage = item.total !== "0.00" ? (100 * value) / item.total : 0;
                  percentage = percentage.toFixed(2);
                  return { ...i, percentage };
                }
                return i;
              }),
            }));
          });
        } else {
          let total = 0;
          data[0].values.forEach(d => {
            total += Math.abs(parseFloat(d.values === null ? 0 : d.values));
          });
          data = data.map(d => ({
            ...d,
            values: d.values.map(i => {
              let value = i.values === null ? 0 : i.values;
              value = value < 0 ? -1 * value : value;
              let percentage = total !== "0.00" ? (100 * value) / total : 0;
              percentage = percentage.toFixed(2);
              return { ...i, percentage };
            }),
          }));
        }
        return data;
      },
    };
  });
