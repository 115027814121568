import chartService from "@viz-ui/services/charts/chartService";
import DataDownSamplerService from "@viz-ui/services/charts/dataDownSamplerService";
import i18n from "@viz-ui/i18n/i18n";

class BubbleChartService extends chartService {
  defaultDisplayConfig = () => ({
    showLegend: true,
    displayDataLabels: true,
    enableBoost: true,
    bubbleScale: "1",
    xAxis: {
      showLabel: true,
    },
    yAxis: {
      showLabel: true,
      minimum: null,
      maximum: null,
    },
  });

  getChartDirectiveConfig = (interpretationId, chartConfig) => {
    const getColorByField = () =>
      (chartConfig &&
        chartConfig.dataConfig &&
        chartConfig.dataConfig.chartSeries &&
        chartConfig.dataConfig.chartSeries.fieldName) ||
      "";

    const valueFormatter = (interpretationId, chartConfig) => {
      const { vizId, dataConfig, displayConfig } = chartConfig;
      if (displayConfig.valueFormattingOptions && dataConfig.chartValue.aggregationType === "average") {
        const chartValueFieldName = `${vizId}-chart-value`;
        const chartValueFieldType = "numeric";
        return this.getValueFormatter(interpretationId, chartValueFieldName, chartValueFieldType);
      }
      return this.getValueFormatter(interpretationId, dataConfig.chartValue.fieldName, dataConfig.chartValue.type);
    };

    return {
      valueLabel: this.getFieldLabel(chartConfig.dataConfig.chartValue),
      valueFormatter: valueFormatter(interpretationId, chartConfig),
      hasMultipleSeries: !!(chartConfig.dataConfig.chartSeries && chartConfig.dataConfig.chartSeries.fieldName),
      showLegend: chartConfig.displayConfig.showLegend,
      enableBoost: chartConfig.displayConfig.enableBoost,
      displayDataLabels: chartConfig.displayConfig.displayDataLabels,
      xAxis: {
        label: chartConfig.displayConfig.xAxis.showLabel
          ? chartConfig.displayConfig.xAxis.title
            ? chartConfig.displayConfig.xAxis.title
            : chartConfig.dataConfig.chartXAxis.displayName
          : "",
        tickFormatter: this.getValueFormatter(
          interpretationId,
          chartConfig.dataConfig.chartXAxis.fieldName,
          chartConfig.dataConfig.chartXAxis.type
        ),
        title: chartConfig.displayConfig.xAxis.title,
        axisType: chartConfig.dataConfig.chartXAxis.type,
      },
      yAxis: {
        label: chartConfig.displayConfig.yAxis.showLabel
          ? chartConfig.displayConfig.yAxis.title
            ? chartConfig.displayConfig.yAxis.title
            : chartConfig.dataConfig.chartYAxis.displayName
          : "",
        tickFormatter: this.getValueFormatter(
          interpretationId,
          chartConfig.dataConfig.chartYAxis.fieldName,
          chartConfig.dataConfig.chartYAxis.type
        ),
        title: chartConfig.displayConfig.yAxis.title,
        axisType: chartConfig.dataConfig.chartYAxis.type,
        minimum: chartConfig.displayConfig.yAxis.minimum,
        maximum: chartConfig.displayConfig.yAxis.maximum,
      },
      DATA_ITEM_LIMIT: this.getDataItemLimit(),
      colorByField: getColorByField(),
      labelFormatter: this.getLabelFormatter(chartConfig, interpretationId),
      bubbleScaleMinSize: this.BubbleScaleInfo.minSize(
        chartConfig.displayConfig.bubbleScale || this.defaultDisplayConfig().bubbleScale
      ),
      bubbleScaleMaxSize: this.BubbleScaleInfo.maxSize(
        chartConfig.displayConfig.bubbleScale || this.defaultDisplayConfig().bubbleScale
      ),
    };
  };

  populateChartConfigColumnDefs = chartConfigColumnDefs => {
    let tempColumnDef;
    const fields = this.DataModel.table.fields();
    if (fields) {
      for (const fieldName in fields) {
        tempColumnDef = {};
        tempColumnDef.displayName = fields[fieldName].displayName;
        tempColumnDef.fieldId = fields[fieldName].colId;
        tempColumnDef.fieldName = fieldName;
        tempColumnDef.type = fields[fieldName].type;

        switch (tempColumnDef.type) {
          case "character":
          case "logical":
            if (this.AppConfig.features.logicalFieldsInCharts || tempColumnDef.type === "character") {
              chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
              chartConfigColumnDefs.chartSeries.push(tempColumnDef);
            }
            break;

          case "numeric":
            chartConfigColumnDefs.chartValue.push(tempColumnDef);
            chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
            break;

          case "date":
          /* Falls through */
          case "datetime":
          /* Falls through */
          case "time":
            chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
            break;
        }
      }
      chartConfigColumnDefs.chartYAxis = chartConfigColumnDefs.chartXAxis;
    }
    return chartConfigColumnDefs;
  };

  chartConfigColumnDefs = () => ({
    chartSeries: [],
    chartValue: [],
    chartXAxis: [],
    chartYAxis: [],
  });

  isValidDataConfig = dataConfig =>
    !!(
      dataConfig &&
      dataConfig.chartXAxis &&
      dataConfig.chartYAxis &&
      dataConfig.chartValue &&
      ((dataConfig.chartValue.aggregationType && dataConfig.chartValue.fieldName) ||
        dataConfig.chartValue.aggregationType === "count")
    );

  filterResult = (representation, aggregationType) => {
    const dataDownSampler = new DataDownSamplerService();
    var cohortSize = dataDownSampler.reduceBubbleData(representation, this.getDataItemLimit(), aggregationType);
    const msg = cohortSize > 1 ? i18n.t("_Chart.DrawableDataLimitReached.Template_").replace("$0", cohortSize) : "";
    return { representation, msg };
  };
  getFilters = (data, chartConfig) => {
    const dataConfig = chartConfig.dataConfig;
    const xFormatter = this.getFormatterForType(dataConfig.chartXAxis.type);
    const yFormatter = this.getFormatterForType(dataConfig.chartYAxis.type);
    const filters = [];
    if (dataConfig.chartSeries && dataConfig.chartSeries.fieldName) {
      const seriesFormatter = this.getFormatterForType(dataConfig.chartSeries.type);
      filters.push({ field: dataConfig.chartSeries, value: seriesFormatter(data.series.key) });
    }

    if (data.point.rangeX !== undefined && data.point.rangeY !== undefined) {
      // If these range values are here, then DataDownsampler added them
      // We'll need to create ranges to view drill down in
      const xFieldName = dataConfig.chartXAxis.fieldName;
      const xField = this.DataModel.table.field(xFieldName);
      const xFilter = xField.criteriaFilter.newFilter;
      xFilter.operator = "between";
      xFilter.active = true;
      xFilter.value = xFormatter(data.point.rangeX.min);
      xFilter.value2 = xFormatter(data.point.rangeX.max);
      this.DataModel.table.field(xFieldName, xField);

      const yFieldName = dataConfig.chartYAxis.fieldName;
      const yField = this.DataModel.table.field(yFieldName);
      const yFilter = yField.criteriaFilter.newFilter;
      yFilter.operator = "between";
      yFilter.active = true;
      yFilter.value = yFormatter(data.point.rangeY.min);
      yFilter.value2 = yFormatter(data.point.rangeY.max);
      this.DataModel.table.field(yFieldName, yField);
    } else {
      filters.push({ field: dataConfig.chartXAxis, value: xFormatter(data.point.x) });
      filters.push({ field: dataConfig.chartYAxis, value: yFormatter(data.point.y) });
    }
    return filters;
  };
}
export default BubbleChartService;
