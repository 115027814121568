import backendApi from "@results/services/apiCall/backendApi";
import { LinkId, StoryboardId, accessType, workflow_group_ids, launchpad_group_ids } from "@storyboards/types";

/**
 * A service class for managing storyboard anonymous links, by communicating
 * with the backend server.
 */
class StoryboardLinkService {
  private readonly storyboardLinksPath = (storyboardId: StoryboardId) => `/storyboards/${storyboardId}/links`;

  private readonly storyboardUpdateLinkPath = (storyboardId: StoryboardId, linkId: LinkId) => `/storyboards/${storyboardId}/links/${linkId}`;

  private readonly storyboardLinkPath = (storyboardId: StoryboardId, linkId: LinkId) =>
    `/storyboards/${storyboardId}/links/${linkId}`;

  private readonly storyboardRegenerateLinkPath = (storyboardId: StoryboardId, linkId: LinkId) =>
    `/storyboards/${storyboardId}/links/${linkId}/regenerate`;

  private readonly publicStoryboardLinkPath = (linkId: LinkId) => `/storyboard_links/${linkId}`;

  /**
   * Generate a new public link for this storyboard, returning the link ID.
   * TODO: making accessType, linkId as optional and launchpadGroups, workflowGroups, with default empty array
   */
  public async generateLink(storyboardId: StoryboardId, accessType?: accessType, launchpadGroups: launchpad_group_ids = [], workflowGroups: workflow_group_ids = [], linkId?: LinkId): Promise<LinkId> {

    // Checking if accessType is provided or not for flipper purpose
    // Should be removed once fully implemented
    if (accessType) {
      let body;
      const options = {};
      // Passing LinkId for updating the endpoint
      if (linkId) {
        body = {
          "storyboard_link": {
            "access_type": accessType,
            "launchpad_group_ids": launchpadGroups,
            "group_ids": workflowGroups
          }
        }
        const result = await backendApi.put(this.storyboardUpdateLinkPath(storyboardId, linkId), options, body);
        return result.data.storyboard_link;
      }
      // Else generate new link
      body = {
        "storyboard_id": storyboardId,
        "storyboard_link": {
          "access_type": accessType,
          "launchpad_group_ids": launchpadGroups,
          "group_ids": workflowGroups
        }
      }
      const result = await backendApi.post(this.storyboardLinksPath(storyboardId), options, body);
      return result.data.storyboard_link;
    }
    const result = await backendApi.post(this.storyboardLinksPath(storyboardId));
    return result.data.uid;
  }

  /**
   * Regenerate a public link for this storyboard, returning the link ID.
   */
  public async regenerateLink(storyboardId: StoryboardId, linkId: LinkId, accessType?: accessType, launchpadGroups: launchpad_group_ids = [], workflowGroups: workflow_group_ids = []): Promise<LinkId> {
    // TODO: Need to add regenerate changes once the backend is ready
    if (accessType) {
      const body = {
        "storyboard_id": storyboardId,
        "storyboard_link": {
          "access_type": accessType,
          "launchpad_group_ids": launchpadGroups,
          "group_ids": workflowGroups
        }
      }
      const options = {}
      const result = await backendApi.put(this.storyboardRegenerateLinkPath(storyboardId, linkId), options, body);
      return result.data.storyboard_link;
    }
    const result = await backendApi.put(this.storyboardLinkPath(storyboardId, linkId));
    return result.data.uid;
  }

  /**
   * Delete the public link for this storyboard.
   */
  public async deleteLink(storyboardId: StoryboardId, linkId: LinkId): Promise<void> {
    await backendApi.delete(this.storyboardLinkPath(storyboardId, linkId));
  }

  /**
   * Given a storyboard link ID, return the full URL to the storyboard.
   */
  public storyboardFullPublicUrl(linkId: LinkId): string {
    if (linkId) {
      return `${window.location.protocol}//${window.location.hostname}${this.publicStoryboardLinkPath(linkId)}`;
    } else {
      return "";
    }
  }
}

/*
 * The main thing exported from this module is a singleton instance
 * of the service. This object has no state, so can be used anywhere.
 */
const singleton = new StoryboardLinkService();
export default singleton as StoryboardLinkService;
