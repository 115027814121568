import Button from "acl-ui/components/Button";
import ReactDirective from "acl-ui/angular/reactDirective.umd";
import Toggle from "acl-ui/components/Toggle";
import ToolTip from "@viz-ui/components/ToolTip";
import BoostToolTip from "@viz-ui/components/ToolTip/BoostToolTip";

angular
  .module("acl.visualizer.aclui", [])
  .directive("acluiButton", ReactDirective.getFactory(Button))
  .directive("acluiToggle", ReactDirective.getFactory(Toggle))
  .directive("pkaToolTip", ReactDirective.getFactory(ToolTip))
  .directive("boostToolTip", ReactDirective.getFactory(BoostToolTip));
