import backendApi from "@results/services/apiCall/backendApi";
import { AssignmentId, Group, GroupAssignment, Role, StoryboardId, User, UserAssignment } from "@storyboards/types";

/**
 * Definition of the object that's passed to/from the backend.
 * This uses snake case because it's Rails-generated.
 */
interface ApiAssignment {
  id?: number;
  user_id?: string;
  group_id?: number;
  role: Role;
}

/**
 * Backend-access service for fetching, updating, and deleting user and group
 * permissions for storyboards.
 */
class StoryboardAssignmentService {
  private readonly storyboardAssignmentsPath = (storyboardId: StoryboardId) =>
    `/storyboards/${storyboardId}/assignments`;

  private readonly storyboardAssignmentPath = (storyboardId: StoryboardId, assignmentId: AssignmentId) =>
    `/storyboards/${storyboardId}/assignments/${assignmentId}`;

  /**
   * Call the backend to fetch permissions assignments for this storyboard.
   */
  public async getAssignments(
    storyboardId: StoryboardId,
    users: User[],
    groups: Group[]
  ): Promise<{ userAssignments: UserAssignment[]; groupAssignments: GroupAssignment[] }> {
    const assignments = await backendApi.get(this.storyboardAssignmentsPath(storyboardId));
    this.addAssignmentsToWindow(assignments.data);
    return this.handleAssignmentsCallback(users, groups, assignments.data);
  }

  /**
   * Add assignments to window object. This is done to handle going to manage users from pagination page
   */
  private addAssignmentsToWindow(assignments: any) {
    const userAssignments: UserAssignment[] = [];
    const groupAssignments: GroupAssignment[] = [];
    assignments.forEach((assignment: any) => {
      if (assignment.user_id) {
        userAssignments.push(this.formattedUserAssignment(assignment, { name: "", email: "", id: "" }));
      } else if (assignment.group_id) {
        groupAssignments.push(this.formattedGroupAssignment(assignment, { name: "", id: 0 }));
      }
    });
    (window as any).assignments = { userAssignments, groupAssignments };
  }

  /**
   * Call the backend to add new permission assignments for this storyboard.
   */
  public async addAssignments(
    storyboardId: StoryboardId,
    users: User[],
    groups: Group[],
    newUsers: User[],
    newGroups: Group[]
  ): Promise<{ userAssignments: UserAssignment[]; groupAssignments: GroupAssignment[] }> {
    let newAssignments = this.getNewAssignments(newUsers, newGroups);
    if (newAssignments.length) {
      const assignments = await backendApi.post(
        this.storyboardAssignmentsPath(storyboardId),
        {},
        { storyboard_assignments: JSON.stringify(newAssignments) }
      );
      return this.handleAssignmentsCallback(users, groups, assignments.data);
    } else {
      return { userAssignments: [], groupAssignments: [] };
    }
  }

  /**
   * Call the backend to update the Role associated with a specific permission assignment.
   */
  public async updateAssignment(
    storyboardId: StoryboardId,
    assignmentId: AssignmentId,
    role: Role
  ): Promise<ApiAssignment> {
    const assignment = await backendApi.put(
      this.storyboardAssignmentPath(storyboardId, assignmentId),
      {},
      { storyboard_assignment: { role: role } }
    );
    return assignment.data;
  }

  /**
   * Call the backend API to remove a permission assignment from this storyboard.
   */
  public async removeAssignment(storyboardId: StoryboardId, assignmentId: AssignmentId): Promise<AssignmentId> {
    const assignment = await backendApi.delete(this.storyboardAssignmentPath(storyboardId, assignmentId));
    return assignment.data.id;
  }

  /**
   * Helper method to translate the user/group assignments from the higher-layer code
   * into the format required by the backend API. Note that all new user/group assignments
   * will be "viewer" role when they're first created.
   */
  private getNewAssignments(newUsers: User[], newGroups: Group[]): ApiAssignment[] {
    let newAssignments: ApiAssignment[] = [];
    newUsers.forEach((user: User) => newAssignments.push({ user_id: user.id, role: "viewer" }));
    newGroups.forEach((group: Group) => newAssignments.push({ group_id: group.id, role: "viewer" }));
    return newAssignments;
  }

  /**
   * Helper method, used by getAssignments() and addAssignments() to convert the backend
   * response into separate UserAssignment[] and GroupAssignment[] lists that can be passed
   * to the front-end code.
   */
  private handleAssignmentsCallback(
    users: User[],
    groups: Group[],
    assignments: ApiAssignment[]
  ): { userAssignments: UserAssignment[]; groupAssignments: GroupAssignment[] } {
    const userAssignments: UserAssignment[] = [];
    const groupAssignments: GroupAssignment[] = [];

    assignments.forEach(assignment => {
      if (assignment.user_id) {
        let user = users.find(user => user.id === assignment.user_id);
        if (user) {
          userAssignments.push(this.formattedUserAssignment(assignment, user));
        }
      } else if (assignment.group_id) {
        let group = groups.find(group => group.id === assignment.group_id);
        if (group) {
          groupAssignments.push(this.formattedGroupAssignment(assignment, group));
        }
      }
    });
    return { userAssignments, groupAssignments };
  }

  /**
   * Helper method to translate the API format for user permission assignments to that
   * expected by the upper-layer code.
   */
  private formattedUserAssignment(assignment: ApiAssignment, user: User): UserAssignment {
    return {
      assignmentId: assignment.id,
      userId: assignment.user_id,
      name: user.name,
      email: user.email,
      role: assignment.role,
    };
  }

  /**
   * Helper method to translate the API format for group permission assignments to that
   * expected by the upper-layer code.
   */
  private formattedGroupAssignment(assignment: ApiAssignment, group: Group): GroupAssignment {
    return {
      assignmentId: assignment.id,
      groupId: assignment.group_id,
      name: group.name,
      role: assignment.role,
    };
  }
}

/*
 * The main thing exported from this module is a singleton instance
 * of the service. This object has no state, so can be used anywhere.
 */
const singleton = new StoryboardAssignmentService();
export default singleton as StoryboardAssignmentService;
