/*
	Copyright ©2014 ACL Services Ltd.
    Use of this code is governed by the ACL Software License Agreement that applies to the Software of which this code is a part:
    http://www.acl.com/pdfs/ACL_License_Agreement.pdf
*/

angular
  .module("acl.common.locationManager", ["acl.common.config"])
  .factory("LocationManager", function($location, $rootScope, $state) {
    "use strict";
    var parser = document.createElement("a");

    var locationChanged = false,
      settingLocation = false,
      setLocationCallbacks = [],
      reloadOnStateChanges = false;

    $rootScope.$on("$stateChangeStart", function(event, toState, toParams, fromState, fromParams) {
      if (reloadOnStateChanges || settingLocation) event.preventDefault();
    });

    $rootScope.$on("$locationChangeStart", function(event, to, from) {
      if (locationChanged && (reloadOnStateChanges || settingLocation)) {
        event.preventDefault();
        if (settingLocation) {
          settingLocation = false;
          setLocationCallbacks.shift()(from);
        }
      }
      locationChanged = !locationChanged;
    });

    $rootScope.$on("$locationChangeSuccess", function(event, to, from) {
      if (reloadOnStateChanges && !settingLocation) {
        location.reload();
      }
    });

    var locationManagerService = {
      goToState: function(stateName, options) {
        var params = angular.extend($state.params, options);
        var url = $state.href(stateName, params);
        document.location.assign(url);
      },

      setLocation: function(callback) {
        setLocationCallbacks.push(callback || function() {});
        settingLocation = true;
        locationChanged = false;
        return $location;
      },

      reloadOnStateChanges: function(enabled) {
        if (arguments.length < 1) return reloadOnStateChanges;
        reloadOnStateChanges = enabled;
        locationChanged = false;
        return locationManagerService;
      },
    };

    return locationManagerService;
  });
