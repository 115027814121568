import PropTypes from "prop-types";
import React from "react";

class UserSelectorRow extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  toggleRowIcon(iconClass, deleteMode) {
    return function(e) {
      let row = $(e.target).closest(".selection__row.selected");
      let icon = row.find("i");
      icon.removeClass("acl-i-check acl-i-times");
      icon.addClass(iconClass);
      deleteMode ? row.addClass("delete-mode") : row.removeClass("delete-mode");
    };
  }

  getRowClass() {
    return this.props.isSelected ? "selection__row selected" : "selection__row";
  }

  getRowIcon() {
    let icon = this.props.isSelected ? <i className="acl-i-check" /> : <i className="acl-i-add" />;
    return <div className="row-icon">{icon}</div>;
  }

  getUserValues() {
    return this.props.users ? this.props.users.split(",").map(item => item.trim()) : [];
  }

  handleClick(e) {
    let row = $(e.target).closest(".selection__row");
    let userId = row.data("userId");
    let groupId = row.data("groupId");
    let userDetails;
    if (groupId) {
      userDetails = {
        id: this.props.groupId,
        name: this.props.name,
        users: this.getUserValues(),
      };
    } else {
      userDetails = {
        id: this.props.userId,
        email: this.props.email,
        name: this.props.name,
      };
    }
    this.props.onClick(userId, groupId, userDetails);
  }

  render() {
    return (
      <div
        className={this.getRowClass()}
        data-user-id={this.props.userId}
        data-group-id={this.props.groupId}
        onClick={this.handleClick}
        onMouseEnter={this.toggleRowIcon("acl-i-times", true)}
        onMouseLeave={this.toggleRowIcon("acl-i-check", false)}
      >
        {this.getRowIcon()}
        {this.props.name}
        {this.props.children}
      </div>
    );
  }
}

UserSelectorRow.propTypes = {
  isSelected: PropTypes.bool,
  userId: PropTypes.string,
  groupId: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  email: PropTypes.string,
  users: PropTypes.array,
};

window.UserSelectorRow = UserSelectorRow;
