import i18n from "@viz-ui/i18n/i18n";
import chartService from "@viz-ui/services/charts/chartService";
import { reorderValues } from "../../../components/charts/services/highChartDataSorter";

class CombinationChartService extends chartService {
  /**TODO: Need to remove following parameters in order to make this efficient
   * !Need to find alternative to $filter (angular) which is right now used as filter parameter.
   */
  defaultDisplayConfig = () => ({
    smoothEdged: false,
    showLegend: true,
    displayDataLabels: true,
    showControls: true,
    enableBoost: true,
    showDualYAxis: false,
    xAxis: {
      showAxis: true,
      showLabel: true,
    },
    yAxis: [
      {
        showAxis: true,
        showLabel: true,
        minimum: null,
        maximum: null,
      },
      {
        showAxis: true,
        showLabel: true,
        minimum: null,
        maximum: null,
      },
    ],
    chartStyle: "group",
  });

  getXAxisLabel = (dataConfig, displayConfig, labelTemplate, hasMultipleSeries) => {
    if (displayConfig.xAxis.showLabel) {
      if (displayConfig.xAxis.title) {
        return displayConfig.xAxis.title;
      }
      if (hasMultipleSeries) {
        return labelTemplate
          .replace("$0", dataConfig.chartXAxis.displayName)
          .replace("$1", dataConfig.chartSeries.displayName);
      }
      return dataConfig.chartXAxis.displayName;
    }
    return "";
  };

  getYAxisLabel = (dataConfig, displayConfig, index) => {
    if (displayConfig.yAxis[index].showLabel) {
      if (displayConfig.yAxis[index].title) {
        return displayConfig.yAxis[index].title;
      }
      return this.getFieldLabel(dataConfig.chartValues[index]);
    }
    return "";
  };

  getFirstYAxisFormatter = (interpretationId, chartConfig) => {
    const { vizId, displayConfig } = chartConfig;
    if (
      displayConfig.valueFormattingOptionsFirstYAxis &&
      chartConfig.dataConfig.chartValues[0].aggregationType === "average"
    ) {
      const chartValueFieldName = `${vizId}-chart-value-FirstYAxis`;
      const chartValueFieldType = "numeric";
      return this.getValueFormatter(interpretationId, chartValueFieldName, chartValueFieldType);
    }
    return this.getValueFormatter(
      interpretationId,
      chartConfig.dataConfig.chartValues[0].fieldName,
      chartConfig.dataConfig.chartValues[0].type
    );
  };

  getSecondYAxisFormatter = (interpretationId, chartConfig) => {
    const { vizId, displayConfig } = chartConfig;
    if (
      displayConfig.valueFormattingOptionsSecondYAxis &&
      chartConfig.dataConfig.chartValues[1].aggregationType === "average"
    ) {
      const chartValueFieldName = `${vizId}-chart-value-SecondYAxis`;
      const chartValueFieldType = "numeric";
      return this.getValueFormatter(interpretationId, chartValueFieldName, chartValueFieldType);
    }
    return this.getValueFormatter(
      interpretationId,
      chartConfig.dataConfig.chartValues[1].fieldName,
      chartConfig.dataConfig.chartValues[1].type
    );
  };

  getChartDirectiveConfig = (interpretationId, chartConfig) => {
    const getColorByField = () =>
      (chartConfig &&
        chartConfig.dataConfig &&
        chartConfig.dataConfig.chartSeries &&
        chartConfig.dataConfig.chartSeries.fieldName) ||
      "";
    const categoryLabelTemplate = i18n.t("_Chart.SingleNestedAxisLabel.Template_");
    const hasMultipleSeries = !!(chartConfig.dataConfig.chartSeries && chartConfig.dataConfig.chartSeries.fieldName);

    return {
      order: chartConfig.displayConfig.order,
      valuesOrder: chartConfig.displayConfig.valuesOrder,
      hasMultipleSeries: hasMultipleSeries,
      showLegend: chartConfig.displayConfig.showLegend,
      displayDataLabels: chartConfig.displayConfig.displayDataLabels,
      showControls: chartConfig.displayConfig.showControls,
      smoothEdged: chartConfig.displayConfig.smoothEdged,
      enableBoost: chartConfig.displayConfig.enableBoost,
      showDualYAxis: chartConfig.displayConfig.showDualYAxis,
      xAxis: {
        label: this.getXAxisLabel(
          chartConfig.dataConfig,
          chartConfig.displayConfig,
          categoryLabelTemplate,
          hasMultipleSeries
        ),
        tickFormatter: this.getValueFormatter(
          interpretationId,
          chartConfig.dataConfig.chartXAxis.fieldName,
          chartConfig.dataConfig.chartXAxis.type
        ),
        title: chartConfig.displayConfig.xAxis.title,
        showAxis: chartConfig.displayConfig.xAxis.showAxis,
        fieldType: chartConfig.dataConfig.chartXAxis.type,
      },
      yAxis: [
        {
          label: this.getYAxisLabel(chartConfig.dataConfig, chartConfig.displayConfig, 0),
          tickFormatter: this.getFirstYAxisFormatter(interpretationId, chartConfig),
          minimum: chartConfig.displayConfig.yAxis[0].minimum,
          maximum: chartConfig.displayConfig.yAxis[0].maximum,
          title: chartConfig.displayConfig.yAxis[0].title,
          showAxis: chartConfig.displayConfig.yAxis[0].showAxis,
        },
        {
          label: this.getYAxisLabel(chartConfig.dataConfig, chartConfig.displayConfig, 1),
          tickFormatter: this.getSecondYAxisFormatter(interpretationId, chartConfig),
          minimum: chartConfig.displayConfig.yAxis[1].minimum,
          maximum: chartConfig.displayConfig.yAxis[1].maximum,
          title: chartConfig.displayConfig.yAxis[1].title,
          showAxis: chartConfig.displayConfig.yAxis[1].showAxis,
        },
      ],
      chartSeries: chartConfig.dataConfig.chartSeries,
      DATA_ITEM_LIMIT: this.getDataItemLimit(),
      colorByField: getColorByField(),
      labelFormatter: this.getLabelFormatter(chartConfig, interpretationId),
      chartStyle: chartConfig.displayConfig.chartStyle,
    };
  };

  populateChartConfigColumnDefs = chartConfigColumnDefsObj => {
    let tempColumnDef;
    const fields = this.DataModel.table.fields();
    if (fields) {
      for (const fieldName in fields) {
        tempColumnDef = {};
        tempColumnDef.displayName = fields[fieldName].displayName;
        tempColumnDef.fieldName = fieldName;
        tempColumnDef.type = fields[fieldName].type;

        switch (tempColumnDef.type) {
          case "character":
          case "logical":
            if (this.AppConfig.features.logicalFieldsInCharts || tempColumnDef.type === "character") {
              chartConfigColumnDefsObj.chartSeries.push(tempColumnDef);
              chartConfigColumnDefsObj.chartXAxis.push(tempColumnDef);
            }
            break;
          case "numeric":
            chartConfigColumnDefsObj.chartValues.push(tempColumnDef);
            chartConfigColumnDefsObj.chartXAxis.push(tempColumnDef);
            break;
          case "date":
          case "datetime":
          case "time":
            chartConfigColumnDefsObj.chartXAxis.push(tempColumnDef);
            break;
        }
      }
    }
    return chartConfigColumnDefsObj;
  };

  chartConfigColumnDefs = () => ({
    chartSeries: [],
    chartXAxis: [],
    chartValues: [],
  });

  isValidDataConfig = dataConfig =>
    !!(
      dataConfig &&
      dataConfig.chartXAxis &&
      dataConfig.chartValues &&
      dataConfig.chartValues.length === 2 &&
      (dataConfig.chartValues[0].aggregationType === "count" ||
        (dataConfig.chartValues[0].aggregationType && dataConfig.chartValues[0].fieldName)) &&
      (dataConfig.chartValues[1].aggregationType === "count" ||
        (dataConfig.chartValues[1].aggregationType && dataConfig.chartValues[1].fieldName))
    );

  reorderValues = (chartData, sortConfig) => reorderValues(chartData, sortConfig);

  getFilters = (keyArray, chartConfig) => {
    const xFormatter = this.getFormatterForType(chartConfig.dataConfig.chartXAxis.type);
    const filters = [];
    if (keyArray.length > 1) {
      const seriesFormatter = this.getFormatterForType(chartConfig.dataConfig.chartSeries.type);
      filters.push({ field: chartConfig.dataConfig.chartSeries, value: seriesFormatter(keyArray[0]) });
    }
    filters.push({
      field: chartConfig.dataConfig.chartXAxis,
      value: xFormatter(keyArray[keyArray.length - 1]),
    });
    return filters;
  };
}

export default CombinationChartService;
