import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";
import GlobalFieldFormatMap from "@viz-ui/services/formatters/globalFieldFormatMap";

angular
  .module("acl.common.filters", ["acl.common.localize", "acl.visualizer.formatters", "acl.visualizer.model.field"])
  .filter("aclFilterLocalized", function($filter, TableDataFormatter, FieldFormat) {
    return function(array, filterValue, fieldFormatIdentifier, fieldName) {
      var predicate;
      const type = GlobalFieldFormatMap.getFieldType(fieldFormatIdentifier, fieldName);

      switch (type) {
        case "numeric": {
          let fieldFormat = GlobalFieldFormatMap.getFieldFormat(fieldFormatIdentifier, fieldName);
          fieldFormat = fieldFormat ? fieldFormat.clone() : new FieldFormat();

          predicate = (item, text) => {
            const formatVariations = [];
            const searchValue = text.toLowerCase();

            fieldFormat.thousandsDelimiter(false);
            formatVariations.push(TableDataFormatter.formatValue(item, "numeric", fieldFormat.toJson()));

            fieldFormat.thousandsDelimiter(true);
            formatVariations.push(TableDataFormatter.formatValue(item, "numeric", fieldFormat.toJson()));

            return formatVariations.some(formattedItem => formattedItem.toLowerCase().indexOf(searchValue) > -1);
          };
          break;
        }

        case "datetime":
        case "date":
          predicate = (item, text) => item.toLowerCase().indexOf(text.toLowerCase()) > -1;
          break;

        default:
          predicate = (item, text) =>
            TableDataFormatter.formatValue(item, type)
              .toLowerCase()
              .indexOf(text.toLowerCase()) > -1;
      }

      if (filterValue === null) {
        filterValue = undefined;
      }
      return $filter("filter")(array, { value: filterValue }, predicate);
    };
  })
  .filter("aclNewlines", function() {
    return function(text) {
      text = text || "";
      return text.replace(/\n/g, "<br/>");
    };
  })
  .filter("aclEscapeHtml", function() {
    return function(text) {
      text = text || "";
      return text
        .replace(/&/g, "&amp;")
        .replace(/>/g, "&gt;")
        .replace(/</g, "&lt;")
        .replace(/'/g, "&apos;");
    };
  })
  .filter("aclEncodeURI", function() {
    return function(text) {
      return encodeURI(text);
    };
  })
  .filter("aclEncodeURIComponent", function() {
    return function(text) {
      return encodeURIComponent(text);
    };
  })
  // simple translation filter
  // usage {{ TOKEN | i18n }}
  .filter("aclLocalize", function(Localize) {
    return function(input) {
      return Localize.getLocalizedString(input);
    };
  })
  .filter("aclLocalizeFieldValue", function() {
    return function(value, fieldFormatIdentifier, fieldName) {
      return GlobalValueFormatter.formatValue(value, fieldFormatIdentifier, fieldName);
    };
  })
  .filter("aclLocalizeFieldValueShortNumber", function(TableDataFormatter, FieldFormat) {
    return function(value, fieldFormatIdentifier, fieldName) {
      let fieldFormat = GlobalFieldFormatMap.getFieldFormat(fieldFormatIdentifier, fieldName);
      const fieldType = GlobalFieldFormatMap.getFieldType(fieldFormatIdentifier, fieldName);

      fieldFormat = fieldFormat ? fieldFormat.clone() : new FieldFormat();
      fieldFormat
        .isRaw(false)
        .precision(2)
        .keepTrailingZeros(false)
        .abbreviate(true);
      const fieldFormatJson = fieldFormat.toJson();
      return TableDataFormatter.formatValue(value, fieldType, fieldFormatJson);
    };
  })
  .filter("aclLocalizeNumeric", function(NumberFormatter) {
    return function(value, fieldFormatJson) {
      return NumberFormatter.format(value, fieldFormatJson);
    };
  })
  .filter("aclLocalizeDatetime", function(DatetimeFormatter) {
    return function(value, fieldFormatJson) {
      return DatetimeFormatter.format(value, fieldFormatJson);
    };
  })
  .filter("aclLocalizeDate", function(DateFormatter) {
    return function(value, fieldFormatJson) {
      return DateFormatter.format(value, fieldFormatJson);
    };
  })
  .filter("aclLocalizeTime", function(TimeFormatter) {
    return function(value, fieldFormatJson) {
      return TimeFormatter.format(value, fieldFormatJson);
    };
  })
  .filter("aclShortNumber", function() {
    return function(value, decimalSpace) {
      if (isNaN(value)) return value;

      var formatter = "0";
      if (decimalSpace > 0) {
        formatter += ".[";
        for (var i = 0; i < decimalSpace; i++) {
          formatter += "0";
        }
        formatter += "]";
      }

      return numeral(value).format(formatter + "a");
    };
  })
  .filter("aclLocalizedTokenReplace", function(Localize, $filter) {
    return function(templateKey, otherKeyTokens) {
      var templateString = Localize.getLocalizedString(templateKey);

      if (angular.isString(otherKeyTokens)) {
        otherKeyTokens = Localize.getLocalizedString(otherKeyTokens);
      } else {
        for (var iToken in otherKeyTokens) {
          if (otherKeyTokens.hasOwnProperty(iToken)) {
            var tokenKey = otherKeyTokens[iToken];
            var localizedToken = Localize.getLocalizedString(tokenKey);
            otherKeyTokens[iToken] = localizedToken;
          }
        }
      }

      var localizedAndReplacedString = $filter("format")(templateString, otherKeyTokens);
      return localizedAndReplacedString;
    };
  })
  .filter("aclExcerpt", function() {
    return function(text, length) {
      var word_count = 15;
      var end = "...";
      var new_text = "";
      if (isNaN(length)) {
        length = 10;
      }
      if (text.length <= length || text.length - end.length <= length) {
        return text;
      } else {
        var words = text.split(" ");
        for (var x = 0; x < word_count; x++) {
          var this_word = words[x];
          new_text = new_text + " " + this_word;
        }
        return String(new_text) + end;
      }
    };
  })
  .filter("aclDisplaySize", function($log) {
    function buildZeroString(length) {
      // http://stackoverflow.com/a/21371641/135114 claims—and jsperf confirms—some string-repeat algorithms
      // can be slow. So let's be less elegant but make it way fast (and kind of clever when you think about it)
      if (length > 6) {
        throw new Exception("Maximum supported precision for aclDisplaySize is 6 digits");
      } else if (length < 0) {
        length = 0;
      }

      var giveMeThisManyZeroes = ["", "0", "00", "000", "0000", "00000", "000000"];
      return giveMeThisManyZeroes[length];
    }

    return function(sizeInByte, decimalDigits) {
      // Default to 0 digits if not specified
      if (!angular.isNumber(decimalDigits) || decimalDigits < 0) {
        decimalDigits = 0;
      }

      var decimalDigitsFormatString;
      if (decimalDigits) {
        decimalDigitsFormatString = ".[" + buildZeroString(decimalDigits) + "]";
      }
      return numeral(sizeInByte).format("0" + decimalDigitsFormatString + " b");
    };
  })
  .filter("aclTrustAsHtml", function($sce) {
    return function(text) {
      return $sce.trustAsHtml(text);
    };
  })
  .filter("aclCheckboxFilterLimitTo", function($filter) {
    return function(array, limit, checkedValues) {
      var returnArray = $filter("limitTo")(array, limit);

      for (var valueIndex in checkedValues) {
        var itemIndexInArray = findIndexByValue(array, checkedValues[valueIndex]);
        var itemInArray = array[itemIndexInArray];
        if (itemInArray && returnArray.indexOf(itemInArray) < 0) {
          returnArray.push(itemInArray);
        }
      }

      function findIndexByValue(array, value) {
        for (var itemIndex in array) {
          if (array[itemIndex] && array[itemIndex].value === value) {
            return itemIndex;
          }
        }
        return undefined;
      }

      return returnArray;
    };
  });
