import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import FieldFormatMultiselect from "./fieldFormatMultiselect/fieldFormatMultiselect";
import NumericOptions from "./fieldFormatMultiselect/sectionOptions/numericOptions";
import DateOptions from "./fieldFormatMultiselect/sectionOptions/dateOptions";
import DatetimeOptions from "./fieldFormatMultiselect/sectionOptions/datetimeOptions";
import TimeOptions from "./fieldFormatMultiselect/sectionOptions/timeOptions";

FormatSection.propTypes = {
  className: PropTypes.string,
  fieldFormatObject: PropTypes.shape({
    abbreviate: PropTypes.bool,
    dateFormat: PropTypes.string,
    datetimeFormat: PropTypes.string,
    displayTime: PropTypes.bool,
    isHtml: PropTypes.bool,
    isRaw: PropTypes.bool,
    keepTrailingZeros: PropTypes.bool,
    postfix: PropTypes.string,
    precision: PropTypes.number,
    prefix: PropTypes.string,
    sign: PropTypes.string,
    thousandsDelimiter: PropTypes.string,
  }),
  fieldType: PropTypes.string,
  flipUnformattedValues: PropTypes.bool,
  onChange: PropTypes.func,
  title: PropTypes.string,
};

function FormatSection({ className, fieldFormatObject, fieldType, onChange, title, flipUnformattedValues = false }) {
  const classes = classNames("filter-section__format", className);

  let sections;
  switch (fieldType) {
    case "numeric":
      sections = NumericOptions(fieldFormatObject);
      break;
    case "date":
      sections = DateOptions(fieldFormatObject, flipUnformattedValues);
      break;
    case "datetime":
      sections = DatetimeOptions(fieldFormatObject, flipUnformattedValues);
      break;
    case "time":
      sections = TimeOptions(fieldFormatObject, flipUnformattedValues);
      break;
    case "character":
    case "logical":
    default:
      sections = [];
  }

  const isDisabled = sections.length === 0;

  const handleOnChange = format => {
    const selectedFormat = format;

    //Auto selecting 'No Decimals' rounding option when user selects 'None' formatting option.
    if (format.hasOnlyRadixSeparator && typeof format.precision === "undefined") {
      selectedFormat.precision = 0;
      selectedFormat.abbreviate = false;
      selectedFormat.keepTrailingZeros = false;
    }

    onChange(selectedFormat);
  };

  return (
    <div className={classes}>
      <label htmlFor="format-dropdown">{title}</label>
      <FieldFormatMultiselect
        id="format-dropdown"
        onChange={handleOnChange}
        sections={sections}
        isDisabled={isDisabled}
      />
    </div>
  );
}

export default FormatSection;
