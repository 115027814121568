import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Popover from "@paprika/popover";

import "./ToolTip.scss";

function ToolTip(props) {
  const { content, children, align, offset, className, ...moreProps } = props;
  const rootClasses = classNames("visualizer-tooltip", className);

  return (
    <Popover
      align={align}
      className={rootClasses}
      isDark
      isEager
      offset={offset}
      shouldKeepFocus
      zIndex={1000}
      {...moreProps}
    >
      <Popover.Trigger tabIndex="-1">{children}</Popover.Trigger>
      {content && (
        <>
          <Popover.Content key={content}>
            <Popover.Card>{content}</Popover.Card>
          </Popover.Content>
          <Popover.Tip />
        </>
      )}
    </Popover>
  );
}

ToolTip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  content: PropTypes.node,
  align: PropTypes.string,
  offset: PropTypes.number,
};

ToolTip.defaultProps = {
  className: null,
  content: null,
  align: "top",
  offset: 8,
};

export default ToolTip;
