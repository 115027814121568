import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import i18n from "@viz-ui/i18n/i18n";

import DatePicker from "@paprika/date-picker";
import PaprikaL10n from "@paprika/l10n";
import ErrorMessage from "acl-ui/components/__beta__/ErrorMessage/ErrorMessage";
import L10n from "acl-ui/components/L10n/L10n";

import ConditionalFilter from "../../../models/storyboardFilter/conditionalFilter";

import FieldFormatAttributes from "./fieldFormatAttributes";
import InputFormatInterpreter from "./inputFormatInterpreter";
import InputValidator from "./inputValidator";

import "./conditional.scss";

import RelativeDateFilter from "../../relativeDate/relativeDateFilter";

const getValueKey = filterInputIndex => `value${filterInputIndex}`;
const getDisplayValueKey = filterInputIndex => `displayValue${filterInputIndex}`;
const getClassValueKey = filterInputIndex => `viz-conditional__input-${filterInputIndex}`;

export default class Conditional extends Component {
  static propTypes = {
    canValidate: PropTypes.bool,
    fieldFormatIdentifier: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    filter: PropTypes.instanceOf(ConditionalFilter),
    flipHasBlankFilter: PropTypes.bool,
    flipRelativeFilters: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onFilterChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    canValidate: false,
    fieldFormatIdentifier: "",
    filter: new ConditionalFilter(),
    flipHasBlankFilter: true,
    flipRelativeFilters: false,
    isDisabled: false,
  };

  constructor(props) {
    super(props);

    this.fieldFormatAttributes = new FieldFormatAttributes(props.fieldFormatIdentifier, props.fieldName);
    this.fieldFormatAttributes.subscribeToFieldFormatChange(this.formatUpdated);

    this.inputFormatInterpreter = new InputFormatInterpreter(
      this.fieldFormatAttributes,
      props.flipHasBlankFilter,
      props.flipRelativeFilters
    );
    this.inputValidator = new InputValidator(this.fieldFormatAttributes);

    this.state = {
      displayValue1: this.formatValue(props.filter.value1()),
      displayValue2: this.formatValue(props.filter.value2()),
      displayValue3: this.formatValue(props.filter.value3()),
      activeInput: null,
    };

    this.operatorList = this.inputFormatInterpreter.getOperators();
  }

  componentWillReceiveProps(nextProps) {
    this.fieldFormatAttributes.updateIdentifiers(nextProps.fieldFormatIdentifier, nextProps.fieldName);

    if (this.state.activeInput !== 1) {
      this.setState({
        displayValue1: this.formatValue(nextProps.filter.value1()),
      });
    }
    if (this.state.activeInput !== 2) {
      this.setState({
        displayValue2: this.formatValue(nextProps.filter.value2()),
      });
    }
    if (this.state.activeInput !== 3) {
      this.setState({
        displayValue3: this.formatValue(nextProps.filter.value3()),
      });
    }
  }

  componentWillUpdate() {
    this.operatorList = this.inputFormatInterpreter.getOperators();
  }

  formatUpdated = () => {
    this.setState({
      displayValue1: this.formatValue(this.props.filter.value1()),
      displayValue2: this.formatValue(this.props.filter.value2()),
      displayValue3: this.formatValue(this.props.filter.value3()),
    });
  };

  getRootClasses = () => {
    const { filter, isDisabled } = this.props;
    let count = this.getInputCount();
    if (this.inputFormatInterpreter.isFileOrSignatureInputType()) {
      count = 0;
    }

    return classNames(
      "viz-conditional",
      `viz-conditional--${count}-inputs`,
      { "viz-conditional--placeholder": !filter.operator() },
      { "viz-conditional--is-disabled": isDisabled }
    );
  };

  getInputCount = () => {
    const operator = this.operatorList.get(this.props.filter.operator());
    return operator ? operator.argumentCount : 1;
  };

  getOptions = () => {
    const optionsArray = [
      <option key="placeholder" value="">
        {i18n.t("_Conditional.Placeholder_")}
      </option>,
    ];
    return optionsArray.concat(
      Array.from(this.operatorList.entries()).map(item => (
        <option value={item[0]} key={item[0]}>
          {item[1].name}
        </option>
      ))
    );
  };

  formatValue = value => (this.inputValidator.isValid(value) ? this.inputFormatInterpreter.formatValue(value) : value);

  isItemSelected = item => {
    if (!this.props.filter.operator()) return false;
    return item.operator === this.props.filter.operator().operator;
  };

  handleSelectChange = event => {
    this.props.onFilterChange("operator", event.target.value);
  };

  handleDateInputChange = (filterInputIndex, userInput) => {
    let interpretedUserInput = userInput;
    if (userInput && !this.inputValidator.isValidDateOrDatetime(userInput)) {
      interpretedUserInput = this.inputFormatInterpreter.interpretDate(userInput);
    }

    this.handleInputChange(filterInputIndex, interpretedUserInput);
  };

  handleInputChange = (filterInputIndex, userInput) => {
    const newState = {};
    const displayValueKey = getDisplayValueKey(filterInputIndex);
    newState[displayValueKey] = userInput;

    const valueKey = getValueKey(filterInputIndex);
    const userInputOffset = this.inputFormatInterpreter.handleInputChangeDatetimeOffset(userInput);
    this.onFilterChange(valueKey, userInputOffset);

    this.setState(newState);
  };

  handleRelativeDateChange = (name, value, idx) => {
    this.onFilterChange(name, value);
  };

  relativeDateValues() {
    return this.props.filter;
  }

  handleTextInputBlur = filterInputIndex => {
    const newState = {};
    const valueKey = getValueKey(filterInputIndex);
    const displayValueKey = getDisplayValueKey(filterInputIndex);

    const value = this.props.filter[valueKey]();

    if (this.inputValidator.isValid(value)) {
      const interpretedValue = this.inputFormatInterpreter.interpretTime(value);
      this.onFilterChange(valueKey, interpretedValue);
      newState[displayValueKey] = this.inputFormatInterpreter.formatValue(interpretedValue);
    } else {
      newState[displayValueKey] = value;
    }

    this.handleInputBlur(newState);
  };

  handleInputBlur = (textInputNewState = {}) => {
    const newState = { ...textInputNewState };
    newState.activeInput = null;

    this.setState(newState);
  };

  onFilterChange(valueKey, newValue) {
    if (this.props.filter[valueKey]() !== newValue) {
      this.props.onFilterChange(valueKey, newValue);
    }
  }

  handleFocus = filterInputIndex => {
    const newState = { activeInput: filterInputIndex };
    const valueKey = getValueKey(filterInputIndex);
    const displayValueKey = getDisplayValueKey(filterInputIndex);
    const value = this.props.filter[valueKey]();

    newState[displayValueKey] = this.inputFormatInterpreter.getRawDisplayValue(value);
    this.setState(newState);
  };

  renderConjunction = () => {
    if (!this.props.filter.operator() || this.getInputCount() !== 2) return null;
    return (
      <div className="viz-conditional__conjunction">
        <span>{i18n.t("_Conditional.Conjunction.Label_")}</span>
      </div>
    );
  };

  renderError = () => {
    const messages = this.props.isDisabled
      ? []
      : this.inputValidator.getErrorMessages(
          this.props.filter.value1(),
          this.props.filter.value2(),
          this.props.filter.value3(),
          this.props.filter.operator(),
          this.props.canValidate
        );
    const messageElements = messages.map((message, index) => <div key={"invalid-inputs-" + index}>{message}</div>);

    return messageElements.length ? <ErrorMessage>{messageElements}</ErrorMessage> : null;
  };

  renderRelativeDateInput = () => {
    const isSelectionValid =
      !this.props.isDisabled &&
      this.inputValidator.isRelativeDateValid(
        this.props.filter.value1() || undefined,
        this.props.filter.value3() || undefined,
        this.props.canValidate
      );
    if (this.inputFormatInterpreter.isDateInputType() && this.props.filter.operator() === "relative") {
      const inputClasses = classNames("viz-conditional__input-1", {
        "viz-conditional__input--invalid": this.inputValidator.isValidRelativeUnit(this.props.filter.value2()),
      });
      return (
        <div className={inputClasses}>
          <RelativeDateFilter
            isDisabled={this.props.isDisabled}
            filter={this.relativeDateValues()}
            onChange={this.handleRelativeDateChange}
            isSelectionValid={isSelectionValid}
          />
        </div>
      );
    }
  };

  renderInput = filterInputIndex => {
    if (this.inputFormatInterpreter.isFileOrSignatureInputType() || this.props.filter.operator() === "relative")
      return null;
    if (filterInputIndex === 1 && this.props.filter.operator() && this.getInputCount() === 0) return null;
    if (filterInputIndex === 2 && (!this.props.filter.operator() || this.getInputCount() !== 2)) return null;

    const valueKey = getValueKey(filterInputIndex);
    const displayValueKey = getDisplayValueKey(filterInputIndex);
    const classValueKey = getClassValueKey(filterInputIndex);

    const filterValue = this.props.filter[valueKey]();
    const displayValue = this.state[displayValueKey];
    const inputInvalid =
      !this.props.isDisabled &&
      !this.inputValidator.isValidInput(filterValue, this.props.filter.operator(), this.props.canValidate);
    const inputClasses = classNames(classValueKey, {
      "viz-conditional__input--invalid": inputInvalid,
    });

    if (this.inputFormatInterpreter.isDateInputType()) {
      const formatToken = this.inputFormatInterpreter.displayDateFormatToken(
        this.state.activeInput === filterInputIndex
      );

      return (
        <div className={inputClasses}>
          <PaprikaL10n locale={i18n.locale}>
            <DatePicker
              isDisabled={this.props.isDisabled}
              dateFormat={this.inputFormatInterpreter.getInputPlaceholder()}
              humanFormat={formatToken}
              date={displayValue ? moment(displayValue) : undefined}
              onChange={arg => this.handleDateInputChange(filterInputIndex, arg)}
            >
              <DatePicker.Input
                placeholder={this.inputFormatInterpreter.getInputPlaceholder()}
                a11yText={i18n.t("_Conditional.DateInput.Label_")}
              />
              <DatePicker.Popover zIndex={9999}></DatePicker.Popover>
            </DatePicker>
          </PaprikaL10n>
        </div>
      );
    }

    return (
      <div className={inputClasses}>
        <input
          disabled={this.props.isDisabled}
          onBlur={() => this.handleTextInputBlur(filterInputIndex)}
          onChange={arg => this.handleInputChange(filterInputIndex, arg.target.value)}
          onFocus={() => this.handleFocus(filterInputIndex)}
          placeholder={this.inputFormatInterpreter.getInputPlaceholder()}
          type="text"
          value={displayValue || ""}
        />
      </div>
    );
  };

  render() {
    const selectInvalid =
      !this.inputValidator.isValidSelect(
        this.props.filter.value1(),
        this.props.filter.value2(),
        this.props.filter.value3(),
        this.props.filter.operator(),
        this.props.canValidate
      ) && !this.props.isDisabled;

    const selectClasses = classNames("viz-conditional__select", "viz-select", {
      "viz-conditional__select--invalid": selectInvalid,
    });

    return (
      <div className={this.getRootClasses()}>
        <L10n locale={i18n.locale}>
          <select
            className={selectClasses}
            disabled={this.props.isDisabled}
            onChange={this.handleSelectChange}
            value={this.props.filter.operator() || i18n.t("_Conditional.Placeholder_")}
          >
            {this.getOptions()}
          </select>
          <div className="viz-conditional__inputs">
            {this.renderRelativeDateInput()}
            {this.renderInput(1)}
            {this.renderConjunction()}
            {this.renderInput(2)}
          </div>
          {this.renderError()}
        </L10n>
      </div>
    );
  }
}
