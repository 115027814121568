export const coneService = {
  projectControlControlTestInterpretationsPath: (params: any) => {
    return `/projects/${params.project_id}/controls/${params.control_id}/control_tests/${params.control_test_id}/interpretations`;
  },
  projectControlControlTestInterpretationPath: (params: any) => {
    return `/projects/${params.project_id}/controls/${params.control_id}/control_tests/${params.control_test_id}/interpretations/${params.id}`;
  },
  projectControlControlTestMetricsPath: (params: any) => {
    return `/projects/${params.project_id}/controls/${params.control_id}/control_tests/${params.control_test_id}/metrics`;
  },
  summaryProjectControlControlTestMetricsPath: (params: any) => {
    return `/projects/${params.project_id}/controls/${params.control_id}/control_tests/${params.control_test_id}/metrics/summary`;
  },
  timelineProjectControlControlTestMetricsPath: (params: any) => {
    return `/projects/${params.project_id}/controls/${params.control_id}/control_tests/${params.control_test_id}/metrics/timeline`;
  },
  projectControlControlTestDefaultInterpretationPath: (params: any) => {
    return `/projects/${params.project_id}/controls/${params.control_id}/control_tests/${params.control_test_id}/default_interpretation`;
  },
  downloadProjectControlControlTestExceptionAttachmentPath: (params: any) => {
    return `/projects/${params.project_id}/controls/${params.control_id}/control_tests/${params.control_test_id}/exception_attachments/${params.id}/download`;
  },
  commentCountProjectControlControlTestInterpretationsPath: (params: any) => {
    return `/projects/${params.project_id}/controls/${params.control_id}/control_tests/${params.control_test_id}/interpretations/comment_count`;
  },
  projectPath: (params: any) => {
    return `/projects/${params.id}`;
  },
  shortInterpretationPath: (params: any) => {
    return `/interpretations/${params.id}`;
  },
  visualizationDataPath: (params: any) => {
    return `/interpretations/${params.id}/visualization_data/${params.visualization_id}`;
  },
  storyboardsPath: () => {
    return `/storyboards`;
  },
  storyboardPath: (params: any) => {
    return `/storyboards/${params.id}`;
  },
  storyboardMetadataPath: () => {
    return `/storyboards/meta_data`;
  },
  shortMetricPath: (params: any) => {
    return `/metrics/${params.id}`;
  },
  shortMetricTimelinePath: (params: any) => {
    return `/metrics/${params.id}/timeline`;
  },
  shortMetricMetaDataPath: (params: any) => {
    return `/metrics/${params.id}/meta_data`;
  },
  shortMetaDataPath: (params: any) => {
    return `/interpretations/${params.id}/meta_data`;
  },
  shortTableDataPath: (params: any) => {
    return `/interpretations/${params.id}/table_data`;
  },
  interpretationsListPath: () => {
    return `/interpretations_list`;
  },
  downloadExceptionAttachmentPath: (params: any) => {
    return `/control_tests/${params.control_test_id}/exception_attachments/${params.id}/download`;
  },
  publicStoryboardLinkInterpretationsListPath: (params: any) => {
    return `/storyboard_links/${params.public_storyboard_link_id}/interpretations_list`;
  },
  publicStoryboardLinkGlobalFilterValuesPath: (params: any) => {
    return `/storyboard_links/${params.public_storyboard_link_id}/global_filters/values`;
  },
  globalFilterValueStoryboardsPath: () => {
    return `/storyboards/global_filter/values`;
  },
  publicStoryboardLinkGlobalFilterSearchPath: (params: any) => {
    return `/storyboard_links/${params.public_storyboard_link_id}/global_filters/search`;
  },
  globalFilterSearchStoryboardsPath: () => {
    return `/storyboards/global_filter/search`;
  },
  publicStoryboardLinkVisualizationDataPath: (params: any) => {
    return `/storyboard_links/${params.public_storyboard_link_id}/interpretations/${params.id}/visualization_data/${params.visualization_id}`;
  },
  publicStoryboardLinkMetaDataPath: (params: any) => {
    return `/storyboard_links/${params.public_storyboard_link_id}/interpretations/${params.id}/meta_data`;
  },
  publicStoryboardLinkTableDataPath: (params: any) => {
    return `/storyboard_links/${params.public_storyboard_link_id}/interpretations/${params.id}/table_data`;
  },
  publicStoryboardLinkMetricPath: (params: any) => {
    return `/storyboard_links/${params.public_storyboard_link_id}/metrics/${params.id}`;
  },
  publicStoryboardLinkMetricTimelinePath: (params: any) => {
    return `/storyboard_links/${params.public_storyboard_link_id}/metrics/${params.id}/timeline`;
  },
  publicStoryboardLinkMetricMetaDataPath: (params: any) => {
    return `/storyboard_links/${params.public_storyboard_link_id}/metrics/${params.id}/meta_data`;
  },
};
