import he from "he";
import sanitizeHtml from "sanitize-html";

const StringFormatter = {
  truncate(text, maxLength = 80, end = "...") {
    if (text.length <= maxLength || text.length - end.length <= maxLength) {
      return text;
    }

    return String(text).substring(0, maxLength - end.length) + end;
  },

  sanitizeHtml(value) {
    const allowedAttributes = {
      div: ["style"],
      span: ["style", "class"],
      p: ["style", "class"],
      b: ["style", "class"],
      s: ["style", "class"],
      i: ["style", "class"],
      u: ["style", "class"],
      ul: ["style", "class"],
      ol: ["style", "class"],
      li: ["style", "class"],
      strong: ["style", "class"],
      a: ["target", "href", "title", "name", "style", "rel", "class"],
    };
    return sanitizeHtml(value, {
      allowedAttributes: { ...sanitizeHtml.defaults.allowedAttributes, ...allowedAttributes },
    });
  },

  stripHtml(value) {
    return sanitizeHtml(value, {
      allowedTags: [],
      allowedAttributes: {},
    });
  },

  encode(value) {
    if (typeof value === "string") return he.encode(value);
    if (typeof value === "number") return value;
    return "";
  },

  convertUrlLink(value) {
    const url = sanitizeHtml(value);
    return `<a target="_blank" href="${url}">${url}</a>`;
  },
};

export default StringFormatter;
