import chartService from "@viz-ui/services/charts/chartService";
import DataDownSamplerService from "@viz-ui/services/charts/dataDownSamplerService";
import i18n from "@viz-ui/i18n/i18n";

class StackedAreaChartService extends chartService {
  defaultDisplayConfig = () => ({
    showLegend: true,
    displayDataLabels: true,
    showControls: true,
    interpolate: false,
    enableBoost: true,
    xAxis: {
      showLabel: true,
    },
    yAxis: {
      showLabel: true,
      minimum: null,
      maximum: null,
    },
    chartStyle: "overlap",
  });

  getYAxisFormatter = (interpretationId, chartConfig) => {
    const { vizId, dataConfig, displayConfig } = chartConfig;
    if (displayConfig.valueFormattingOptions && dataConfig.chartValue.aggregationType === "average") {
      const chartValueFieldName = `${vizId}-chart-value`;
      const chartValueFieldType = "numeric";
      return this.getValueFormatter(interpretationId, chartValueFieldName, chartValueFieldType);
    }
    return this.getValueFormatter(interpretationId, dataConfig.chartValue.fieldName, dataConfig.chartValue.type);
  };

  getChartDirectiveConfig = (interpretationId, chartConfig) => {
    const getColorByField = function() {
      return (
        (chartConfig &&
          chartConfig.dataConfig &&
          chartConfig.dataConfig.chartSeries &&
          chartConfig.dataConfig.chartSeries.fieldName) ||
        ""
      );
    };

    return {
      isInterpolated: !!chartConfig.dataConfig.isInterpolated,
      hasMultipleSeries: !!(chartConfig.dataConfig.chartSeries && chartConfig.dataConfig.chartSeries.fieldName),
      showLegend: chartConfig.displayConfig.showLegend,
      displayDataLabels: chartConfig.displayConfig.displayDataLabels,
      interpolate: chartConfig.displayConfig.interpolate,
      enableBoost: chartConfig.displayConfig.enableBoost,
      showControls: chartConfig.displayConfig.showControls,
      xAxis: {
        label: chartConfig.displayConfig.xAxis.showLabel
          ? chartConfig.displayConfig.xAxis.title
            ? chartConfig.displayConfig.xAxis.title
            : chartConfig.dataConfig.chartXAxis && chartConfig.dataConfig.chartXAxis.fieldName
            ? chartConfig.dataConfig.chartXAxis.displayName
            : chartConfig.dataConfig.chartSeries.displayName
          : "",
        tickFormatter:
          chartConfig.dataConfig.chartXAxis && chartConfig.dataConfig.chartXAxis.fieldName
            ? this.getValueFormatter(
                interpretationId,
                chartConfig.dataConfig.chartXAxis.fieldName,
                chartConfig.dataConfig.chartXAxis.type
              )
            : this.getValueFormatter(
                interpretationId,
                chartConfig.dataConfig.chartSeries.fieldName,
                chartConfig.dataConfig.chartSeries.type
              ),
        title: chartConfig.displayConfig.xAxis.title,
        axisType: chartConfig.dataConfig.chartXAxis.type,
      },
      yAxis: {
        label: chartConfig.displayConfig.yAxis.showLabel
          ? chartConfig.displayConfig.yAxis.title
            ? chartConfig.displayConfig.yAxis.title
            : this.getFieldLabel(chartConfig.dataConfig.chartValue)
          : "",
        tickFormatter: this.getYAxisFormatter(interpretationId, chartConfig),
        title: chartConfig.displayConfig.yAxis.title,
        minimum: chartConfig.displayConfig.yAxis.minimum,
        maximum: chartConfig.displayConfig.yAxis.maximum,
      },
      DATA_ITEM_LIMIT: this.getDataItemLimit(),
      colorByField: getColorByField(),
      labelFormatter: this.getLabelFormatter(chartConfig, interpretationId),
      tooLargeMessage: i18n.t("_Chart.DataSetTooLarge.Label_"),
      chartStyle: chartConfig.displayConfig.chartStyle,
      controlLabels: {
        stacked: i18n.t("_Chart.ControlLabel.Stacked"),
        stream: i18n.t("_Chart.ControlLabel.Stream"),
        expanded: i18n.t("_Chart.ControlLabel.Expanded"),
      },
    };
    /* eslint-enable */
  };

  populateChartConfigColumnDefs = chartConfigColumnDefs => {
    let tempColumnDef;
    const fields = this.DataModel.table.fields();
    if (fields) {
      for (const fieldName in fields) {
        tempColumnDef = {};
        tempColumnDef.displayName = fields[fieldName].displayName;
        tempColumnDef.fieldId = fields[fieldName].colId;
        tempColumnDef.fieldName = fieldName;
        tempColumnDef.type = fields[fieldName].type;

        switch (tempColumnDef.type) {
          case "character":
          case "logical":
            if (this.AppConfig.features.logicalFieldsInCharts || tempColumnDef.type === "character") {
              chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
              chartConfigColumnDefs.chartSeries.push(tempColumnDef);
            }
            break;

          case "numeric":
            chartConfigColumnDefs.chartValue.push(tempColumnDef);
            chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
            break;

          case "date":
          /* Falls through */
          case "datetime":
          /* Falls through */
          case "time":
            chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
            break;
        }
      }
    }
    return chartConfigColumnDefs;
  };

  chartConfigColumnDefs = () => ({
    chartSeries: [],
    chartXAxis: [],
    chartValue: [],
  });

  isValidDataConfig = dataConfig =>
    !!(
      dataConfig &&
      dataConfig.chartXAxis &&
      dataConfig.chartValue &&
      ((dataConfig.chartValue.aggregationType && dataConfig.chartValue.fieldName) ||
        dataConfig.chartValue.aggregationType === "count")
    );

  filterResult = (representation, aggregationType) => {
    const dataDownSampler = new DataDownSamplerService();
    var cohortSize = dataDownSampler.reduceStackedAreaData(representation, this.getDataItemLimit(), aggregationType);
    const msg = cohortSize > 1 ? i18n.t("_Chart.DrawableDataLimitReached.Template_").replace("$0", cohortSize) : "";
    return { representation, msg };
  };

  getFilters = (keyArray, chartConfig) => {
    const xFormatter = this.getFormatterForType(chartConfig.dataConfig.chartXAxis.type);
    const filters = [];
    if (keyArray.length > 1) {
      const seriesFormatter = this.getFormatterForType(chartConfig.dataConfig.chartSeries.type);
      filters.push({ field: chartConfig.dataConfig.chartSeries, value: seriesFormatter(keyArray[0]) });
    }
    filters.push({
      field: chartConfig.dataConfig.chartXAxis,
      value: xFormatter(keyArray[keyArray.length - 1]),
    });
    return filters;
  };
}

export default StackedAreaChartService;
