import FieldFormatter from "@viz-ui/services/formatters/fieldFormatter";

(() => {
  var datetimeFormatOptions = {
    restrict: "E",
    bindings: {
      fieldFormat: "<",
      fieldName: "<",
      onFieldFormatChange: "&",
    },
    controllerAs: "datetimeOptions",
    controller: DatetimeFormatOptionsController,
    templateUrl: "visualizer/js/modules/core/fieldFormatOptions/datetimeFormatOptions.tpl.html",
  };

  function DatetimeFormatOptionsController(FieldFormat) {
    "ngInject";
    var datetimeOptions = this;

    datetimeOptions.timeDisplayOptionsEnabled = true;

    datetimeOptions.$onInit = function() {
      setupOptions();
    };

    datetimeOptions.$onChanges = function(changes) {
      if (changes.fieldFormat) {
        setupOptions();
      }
    };

    datetimeOptions.handleFormatClick = function(format) {
      let fieldFormat;
      if (datetimeOptions.fieldFormat) {
        fieldFormat = datetimeOptions.fieldFormat.clone().assign(format);
      } else {
        fieldFormat = new FieldFormat(format);
      }
      datetimeOptions.onFieldFormatChange({ fieldFormat: fieldFormat });
    };

    datetimeOptions.handleTimeDisplayClick = function(format) {
      let fieldFormat;
      if (datetimeOptions.fieldFormat) {
        fieldFormat = datetimeOptions.fieldFormat.clone().assign(format);
      } else {
        fieldFormat = new FieldFormat(format);
      }
      datetimeOptions.onFieldFormatChange({ fieldFormat: fieldFormat });
    };

    function setupOptions() {
      datetimeOptions.formatOptions = getDatetimeFormattingOptions();
      datetimeOptions.timeDisplayOptions = getTimeDisplayOptions();
      datetimeOptions.timeDisplayOptionsEnabled = !selectedFormattingOption().isRaw;
    }

    function getDatetimeFormattingOptions() {
      var selectedFieldFormat = selectedFormattingOption();
      var heading = FieldFormatter.getDatetimeHeading();
      var options = FieldFormatter.getDatetimeFormattingOptions(selectedFieldFormat);
      return [heading].concat(options);
    }

    function getTimeDisplayOptions() {
      var selectedFieldFormat = selectedFormattingOption();
      var heading = FieldFormatter.getTimeDisplayHeading();
      var options = FieldFormatter.getTimeDisplayOptions(selectedFieldFormat);
      return [heading].concat(options);
    }

    function selectedFormattingOption() {
      return datetimeOptions.fieldFormat ? datetimeOptions.fieldFormat.toJson() : { displayTime: true };
    }
  }

  angular.module("acl.visualizer.fieldFormatOptions").component("datetimeFormatOptions", datetimeFormatOptions);
})();
