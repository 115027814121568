import Model from "../model";
import Field from "../field/field";

class SummaryTable extends Model {
  static modelType = "SummaryTable";

  constructor(data) {
    super({ fields: [], fieldFormatMap: new Map(), ...data });
  }

  fields(...args) {
    return this.accessor("fields", args, [Field]);
  }

  fieldFormatMap(...args) {
    return this.accessor("fieldFormatMap", args, Map);
  }

  columnDefs(...args) {
    return this.accessor("columnDefs", args, [Object]);
  }

  headers(...args) {
    return this.accessor("headers", args, [Field]);
  }

  nestedHeaders(...args) {
    return this.accessor("nestedHeaders", args, [[Object]]);
  }

  valueColumnMap(...args) {
    return this.accessor("valueColumnMap", args, Object);
  }

  tableData(...args) {
    return this.accessor("tableData", args, [Object]);
  }

  totalRow(...args) {
    return this.accessor("totalRow", args, Object);
  }

  rawData(...args) {
    return this.accessor("rawData", args, Object);
  }

  numRows() {
    const rawData = this.rawData() || {};
    const summarizedData = rawData.values ? rawData.values.summarizedData : undefined;
    if (summarizedData) {
      return summarizedData.length;
    }
  }

  clone() {
    return new SummaryTable(this._data);
  }
}

export default SummaryTable;
