(() => {
  class PromptSaveChangesConfirmer {
    constructor($modal) {
      this.$modal = $modal;
    }

    openModal(metricNew, metricName) {
      var modalInstance;
      var ModalController = function($scope, $modalInstance) {
        $scope.model = {
          newName: metricName,
        };
        $scope.isNew = metricNew;
        $scope.cancel = function() {
          $modalInstance.dismiss("cancel");
        };
        $scope.save = function() {
          $modalInstance.close({ model: $scope.model, save: true });
        };
      };
      ModalController.$inject = ["$scope", "$modalInstance"];

      modalInstance = this.$modal.open({
        templateUrl: "visualizer/js/modules/core/metricApp/promptSaveChangesConfirmer.tpl.html",
        controller: ModalController,
        windowClass: "metric-app__dialog--unsaved-metric",
      });

      return modalInstance.result;
    }
  }

  angular.module("acl.visualizer.metricApp").service("PromptSaveChangesConfirmer", PromptSaveChangesConfirmer);
})();
