import { aclLocalizeFieldValue, numeralFormatter } from "@viz-ui/services/common/filter";
import DataModel from "@viz-ui/services/charts/DataModel/dataModelService";
import EventService from "@viz-ui/services/eventService/eventService";
import cloneDeep from "lodash/cloneDeep";

class StatisticsService {
  constructor() {
    this.eventService = EventService.register("acl.visualizer.statistics.StatisticsService");
  }

  createStatisticsViewConfig = fieldData => ({
    chartCategory: [
      {
        displayName: fieldData.displayName,
        fieldId: fieldData.colId,
        fieldName: fieldData.fieldName,
        type: fieldData.type,
      },
    ],
  });

  loadData = config => {
    const filterConfig = DataModel.getFilterConfigDeprecated();
    this.eventService.publish("biView.configChanged", "StatisticsViz", DataModel.table.id(), filterConfig, config);
  };

  formatData = (rawData, fieldFormatIdentifier, fieldName) => {
    const newRawData = this.rebuildLowestHighestValues(rawData);
    return this.extractValues(newRawData, fieldFormatIdentifier, fieldName);
  };

  extractValues = (data, fieldFormatIdentifier, fieldName) => {
    const columns = availableStatisticsByType[data.type];
    const formattedData = [];

    for (const i in columns) {
      const col = columns[i];
      formattedData.push({
        id: col,
        label: this.localizeLabel(col),
        value: data[col],
        displayValue: this.applyFilters(data[col], col, fieldFormatIdentifier, fieldName),
      });
    }
    return formattedData;
  };

  getFiltersMap = (value, fieldFormatIdentifier, fieldName) => {
    const decimal = numeralFormatter(value, 2);
    const number = numeralFormatter(value);
    const filterFieldFormat = aclLocalizeFieldValue(value, fieldFormatIdentifier, fieldName);

    const filtersMap = {
      uniqueValues: number,
      blanks: number,
      count: number,
      positives: number,
      negatives: number,
      zeros: number,
      total: filterFieldFormat,
      average: filterFieldFormat,
      lowest: filterFieldFormat,
      highest: filterFieldFormat,
      range: decimal,
      stdDeviation: decimal,
    };
    return filtersMap;
  };

  applyFilters = (value, col, fieldFormatIdentifier, fieldName) => {
    const filters = this.getFiltersMap(value, fieldFormatIdentifier, fieldName);
    const val = filters[col] || value;
    return val;
  };

  rebuildLowestHighestValues = data => {
    const formattedData = cloneDeep(data);
    if (formattedData.lowest && Array.isArray(formattedData.lowest) && formattedData.lowest.length > 0) {
      formattedData.lowest = formattedData.lowest.sort(function(a, b) {
        const aNumber = Number(a.value);
        const bNumber = Number(b.value);
        if (aNumber < bNumber) {
          return -1;
        }
        if (aNumber > bNumber) {
          return 1;
        }
        return 0;
      })[0].value;
    } else {
      formattedData.lowest = 0;
    }
    if (formattedData.highest && Array.isArray(formattedData.highest) && formattedData.highest.length > 0) {
      formattedData.highest = formattedData.highest.sort(function(a, b) {
        const aNumber = Number(a.value);
        const bNumber = Number(b.value);
        if (aNumber > bNumber) {
          return -1;
        }
        if (aNumber < bNumber) {
          return 1;
        }
        return 0;
      })[0].value;
    } else {
      formattedData.highest = 0;
    }
    return formattedData;
  };

  localizeLabel = key => {
    const translations = {
      count: "_Chart.StatisticsViz.Count.Label_",
      blanks: "_Chart.StatisticsViz.Blanks.Label_",
      uniqueValues: "_Chart.StatisticsViz.UniqueValues.Label_",
      total: "_Chart.StatisticsViz.Total.Label_",
      average: "_Chart.StatisticsViz.Average.Label_",
      range: "_Chart.StatisticsViz.Range.Label_",
      stdDeviation: "_Chart.StatisticsViz.StdDeviation.Label_",
      positives: "_Chart.StatisticsViz.Positives.Label_",
      negatives: "_Chart.StatisticsViz.Negatives.Label_",
      zeros: "_Chart.StatisticsViz.Zeros.Label_",
      highest: "_Chart.StatisticsViz.Highest.Label_",
      lowest: "_Chart.StatisticsViz.Lowest.Label_",
    };
    return translations[key];
  };
}

const availableStatisticsByType = {
  character: ["uniqueValues", "blanks"],
  numeric: ["highest", "lowest", "average", "stdDeviation", "total", "zeros", "count", "positives", "negatives"],
  date: ["highest", "lowest", "blanks", "count"],
  time: ["highest", "lowest", "blanks", "count"],
  datetime: ["highest", "lowest", "blanks", "count"],
};
export default new StatisticsService();
