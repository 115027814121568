angular
  .module("acl.visualizer.heatmap")
  .factory("HeatmapService", function(AppConfig, ChartService, ColorPalette, DataModel) {
    function defaultDisplayConfig() {
      const heatMapColors5 = new ColorPalette().heatMapColors().reverse();
      return {
        showLegend: true,
        displayDataLabels: true,
        binMode: false,
        reverseColors: false,
        xAxis: {
          showLabel: true,
        },
        yAxis: {
          showLabel: true,
        },
        colorAxis: {
          colorStops: [{ color: heatMapColors5[0] }, { color: heatMapColors5[2] }, { color: heatMapColors5[4] }],
          numBins: 5,
        },
      };
    }

    function valueFormatter(interpretationId, chartConfig) {
      const { vizId, dataConfig, displayConfig } = chartConfig;
      if (displayConfig.valueFormattingOptions && dataConfig.chartValue.aggregationType === "average") {
        const chartValueFieldName = `${vizId}-chart-value`;
        const chartValueFieldType = "numeric";
        return ChartService.getValueFormatter(interpretationId, chartValueFieldName, chartValueFieldType);
      }
      return ChartService.getValueFormatter(
        interpretationId,
        dataConfig.chartValue.fieldName,
        dataConfig.chartValue.type
      );
    }

    return {
      defaultDisplayConfig: defaultDisplayConfig,

      getChartDirectiveConfig: function(interpretationId, chartConfig) {
        return {
          valueLabel: ChartService.getFieldLabel(chartConfig.dataConfig.chartValue),
          hasMultipleSeries: !!(chartConfig.dataConfig.chartSeries && chartConfig.dataConfig.chartSeries.fieldName),
          showLegend: chartConfig.displayConfig.showLegend,
          displayDataLabels: chartConfig.displayConfig.displayDataLabels,
          xAxis: {
            displayName: chartConfig.dataConfig.chartXAxis.displayName,
            label: chartConfig.displayConfig.xAxis.showLabel
              ? chartConfig.displayConfig.xAxis.title || chartConfig.dataConfig.chartXAxis.displayName
              : "",
            valueFormatter: ChartService.getValueFormatter(
              interpretationId,
              chartConfig.dataConfig.chartXAxis.fieldName,
              chartConfig.dataConfig.chartXAxis.type
            ),
            title: chartConfig.displayConfig.xAxis.title,
            axisType: chartConfig.dataConfig.chartXAxis.type,
          },
          yAxis: {
            displayName: chartConfig.dataConfig.chartYAxis.displayName,
            label: chartConfig.displayConfig.yAxis.showLabel
              ? chartConfig.displayConfig.yAxis.title || chartConfig.dataConfig.chartYAxis.displayName
              : "",
            valueFormatter: ChartService.getValueFormatter(
              interpretationId,
              chartConfig.dataConfig.chartYAxis.fieldName,
              chartConfig.dataConfig.chartYAxis.type
            ),
            title: chartConfig.displayConfig.yAxis.title,
            axisType: chartConfig.dataConfig.chartYAxis.type,
          },
          colorAxis: {
            colorStops: chartConfig.displayConfig.colorAxis
              ? chartConfig.displayConfig.colorAxis.colorStops
              : defaultDisplayConfig().colorAxis.colorStops,
            minimum: chartConfig.displayConfig.colorAxis && chartConfig.displayConfig.colorAxis.minimum,
            maximum: chartConfig.displayConfig.colorAxis && chartConfig.displayConfig.colorAxis.maximum,
            numBins: chartConfig.displayConfig.colorAxis && chartConfig.displayConfig.colorAxis.numBins,
            valueFormatter: valueFormatter(interpretationId, chartConfig),
          },
          DATA_ITEM_LIMIT: ChartService.getDataItemLimit(),
          binMode: chartConfig.displayConfig.binMode || false,
        };
      },

      populateChartConfigColumnDefs: function(chartConfigColumnDefs) {
        var fields = DataModel.table.fields();
        if (fields) {
          var tempColumnDef = {};
          for (var fieldName in fields) {
            tempColumnDef = {};
            tempColumnDef.displayName = fields[fieldName].displayName;
            tempColumnDef.fieldId = fields[fieldName].colId;
            tempColumnDef.fieldName = fieldName;
            tempColumnDef.type = fields[fieldName].type;

            switch (tempColumnDef.type) {
              case "character":
              case "logical":
                if (AppConfig.features.logicalFieldsInCharts || tempColumnDef.type === "character") {
                  chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
                }
                break;

              case "numeric":
                chartConfigColumnDefs.chartValue.push(tempColumnDef);
                chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
                break;

              case "date":
              case "datetime":
              case "time":
                chartConfigColumnDefs.chartXAxis.push(tempColumnDef);
                break;
            }
          }
          chartConfigColumnDefs.chartYAxis = angular.copy(chartConfigColumnDefs.chartXAxis);
        }
        return chartConfigColumnDefs;
      },

      chartConfigColumnDefs: function() {
        return {
          chartValue: [],
          chartXAxis: [],
          chartYAxis: [],
        };
      },

      isValidDataConfig: function(dataConfig) {
        return !!(
          dataConfig &&
          dataConfig.chartXAxis &&
          dataConfig.chartYAxis &&
          dataConfig.chartValue &&
          ((dataConfig.chartValue.aggregationType && dataConfig.chartValue.fieldName) ||
            dataConfig.chartValue.aggregationType === "count")
        );
      },
    };
  });
