import React from "react";
import PropTypes from "prop-types";
import Button from "@paprika/button";
import Modal from "@paprika/modal";
import Input from "@paprika/input";
import Switch from "@paprika/switch";
import Toast from "@paprika/toast";
import FormElement from "@paprika/form-element";
import i18n from "@viz-ui/i18n/i18n";
import "./storyboard.saveAs.component.scss";

const propTypes = {
  openModal: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  storyboardName: PropTypes.string.isRequired,
};

const defaultProps = {
  openModal: false,
  onConfirm: () => {},
  onCancel: () => {},
  storyboardName: "",
};

export default function StoryboardSaveAsModal(props) {
  let isStoryboardNameLengthy = false;
  let errMsg = "";
  let storyboardName = i18n.t("_Storyboard.Popup.Prefix.Label_", {storyboardName: props.storyboardName});
  if (storyboardName.length > 80) {
    isStoryboardNameLengthy = true;
    errMsg = i18n.t("_Storyboard.Popup.Lengthy.Name.Error_");
  }
  const { onConfirm } = props;
  const [isChecked, setIsChecked] = React.useState(true);
  const [errorText, setErrorText] = React.useState(errMsg);
  const [inputValue, setInputValue] = React.useState(storyboardName);
  const [isStoryboardNameEmptyOrGreater, setStoryboardName] = React.useState(isStoryboardNameLengthy);

  React.useEffect(() => {
    setInputValue(storyboardName);
    setErrorText(errMsg)
  }, [storyboardName, errMsg]);

  function handleClose() {
    props.onCancel();
    setInputValue(storyboardName);
    checkTextboxValue(storyboardName);
    setIsChecked(true);
  }

  function handleToggleChange() {
    setIsChecked(!isChecked);
  }

  function handleEmptyOrLengthyStoryboardName(bool) {
    setStoryboardName(bool);
  }

  function handleInputChange(evt) {
    let storyboardName = evt.target.value;
    checkTextboxValue(storyboardName);
    setInputValue(storyboardName);
  }

  function checkTextboxValue(storyboardName) {
    if (storyboardName === "") {
      handleEmptyOrLengthyStoryboardName(true);
      setErrorText(i18n.t("_Storyboard.Popup.No.Name.Error_"));
    } else if (storyboardName.length > 80) {
      handleEmptyOrLengthyStoryboardName(true);
      setErrorText(i18n.t("_Storyboard.Popup.Lengthy.Name.Error_"));
    } else {
      handleEmptyOrLengthyStoryboardName(false);
      setErrorText("");
    }
  }

  function renderInputElement() {
    return (
      <FormElement label={i18n.t("_Storyboard.Popup.Input.Label_")}>
        <FormElement.Content>
          {({ idForLabel }) => (
            <Input
              type="text"
              value={inputValue}
              size="large"
              id={idForLabel}
              pattern='/^[^\\/:*?"<>|]+$/'
              placeholder={i18n.t("_Storyboard.Popup.Input.Placeholder_")}
              onChange={handleInputChange}
              hasError={Boolean(errorText.length)}
              data-autofocus
            />
          )}
        </FormElement.Content>
        <FormElement.Error>{errorText}</FormElement.Error>
      </FormElement>
    );
  }

  function renderSwitchToggle() {
    let status = isChecked ? i18n.t("_Storyboard.Popup.Switch_Checked_") : i18n.t("_Storyboard.Popup.Switch_Unchecked_");

    return (
      <div className="copy_filters_switch">
        <Switch id="switch-item__toggle" aria-label={i18n.t("_Storyboard.Popup.Filters.Label_") + status} onChange={handleToggleChange} isChecked={isChecked} size="small" />
        <label htmlFor="switch-item__toggle" aria-hidden="true" className="switch-item__label" onClick={handleToggleChange}>
          <b>{i18n.t("_Storyboard.Popup.Filters.Label_")}</b>
        </label>
      </div>
    );
  }

  function renderToastMessage() {
    return (
      <Toast kind="info" className="save_as_storyboard__toast" hasCloseButton={false}>
        {i18n.t("_Storyboard.Popup.Permissions.Label_")}
      </Toast>
    );
  }

  return (
    <Modal className="save-as-storyboard-modal" isOpen={props.openModal} onClose={handleClose} onEscKey={handleClose}>
      <Modal.Header onClose={handleClose}>{i18n.t("_Storyboard.Popup.Name.Label_")}</Modal.Header>
      <Modal.Content>
        {renderInputElement()}
        {renderSwitchToggle()}
        {renderToastMessage()}
      </Modal.Content>
      <Modal.Footer className="modal-footer">
        <Button
          className="footer-button"
          data-testid="button-save"
          onClick={() => onConfirm(inputValue, isChecked)}
          isDisabled={isStoryboardNameEmptyOrGreater}
          kind="primary"
        >
          {i18n.t("_SaveButton.Save.Label_")}
        </Button>
        <Button className="footer-button footer-button-cancel" onClick={handleClose} kind="minor">
          {i18n.t("_General.Cancel.Label_")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

StoryboardSaveAsModal.propTypes = propTypes;
StoryboardSaveAsModal.defaultProps = defaultProps;
