// Method to add script tag dynamically to the application
// Used for globalNavBar
import backendApi from "@results/services/apiCall/backendApi";

export default function addScript(url) {
  //checking if the development is localhost, if yes then no need to add script tag
  if (backendApi.isHostLocal()) {
    return;
  }
  const scriptTag = document.createElement("script");
  scriptTag.type = "text/javascript";
  scriptTag.crossOrigin = "use-credentials";
  scriptTag.src = url;
  document.head.appendChild(scriptTag);
}
