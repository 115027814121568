import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Heading from "@paprika/heading";
import Button from "acl-ui/components/Button";
import Comment from "acl-ui/components/__beta__/Comment";
import Icon from "acl-ui/components/Icon";
import OutsideClickWrapper from "acl-ui/components/OutsideClickWrapper";
import Spinner from "acl-ui/components/Spinner";
import RawButton from "@paprika/raw-button";
import TrashBin from "@acl-services/wasabicons/lib/Trashbin";

import i18n from "@viz-ui/i18n/i18n";
import LocalStorageHelper from "@viz-ui/services/localStorage/localStorageHelper";
import { DatetimeFormatter } from "@acl-services/sriracha-formatters/dist/Formatters";
import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";

import "./ActivityTab.scss";
import "./CommentInput.scss";

export default class ActivityTab extends Component {
  static propTypes = {
    a11yText: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isAddCommentVisible: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    activities: PropTypes.arrayOf(PropTypes.object).isRequired,
    onAddedComment: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.fileUploadComponent = null;
    this.state = {
      showActivityLog: LocalStorageHelper.get("showActivityLog") || null,
      newComment: "",
      attachment: null,
      isCommentSubmitting: false,
      isCommentExpanded: false,
    };
  }

  onClickShowActivityLog = () => {
    const { showActivityLog } = this.state;
    if (showActivityLog) {
      this.setState({ showActivityLog: false });
      LocalStorageHelper.set("showActivityLog", false);
    } else {
      this.setState({ showActivityLog: true });
      LocalStorageHelper.set("showActivityLog", true);
    }
  };

  onChangeNewComment = e => {
    this.setState({ newComment: e.target.value });
  };

  onAddAttachment = e => {
    this.setState({ attachment: e.target.files[0] });
  };

  onSubmitComment = () => {
    this.setState({
      isCommentSubmitting: true,
    });

    const { newComment, attachment } = this.state;
    const { onAddedComment } = this.props;
    onAddedComment(newComment, attachment).then(() => {
      this.setState({
        newComment: "",
        attachment: null,
        isCommentSubmitting: false,
        isCommentExpanded: false,
      });
    });
  };

  onMinimizeCommentSection = () => {
    this.setState({ isCommentExpanded: false });
  };

  onMaximizeCommentSection = () => {
    this.setState({ isCommentExpanded: true });
  };

  onCancelComment = () => {
    this.setState({
      newComment: "",
      attachment: null,
      isCommentExpanded: false,
    });
  };

  onRemoveAttachment = () => {
    this.setState({
      attachment: null,
    });
  };

  openFileDialog = e => {
    e.preventDefault();
    this.fileUploadComponent.click();
  };

  hashString(str) {
    let sum = 0;
    for (let i = 0; i < str.length; i++) {
      sum += +str.charCodeAt(i).toString(2);
    }
    return (sum % 12) + 1;
  }

  formattedTimestamp(timestamp) {
    const offsetMinutes = GlobalValueFormatter.getTimezoneOffset().utcOffsetMinutes();
    return DatetimeFormatter.format(timestamp, null, offsetMinutes);
  }

  renderComments() {
    const { activities } = this.props;
    return activities.map(activity => {
      if (activity.audit) {
        const { showActivityLog } = this.state;
        if (showActivityLog) {
          return (
            <li key={`comment-${activity.id}`} className="activity-tab__item activity-tab__item--log">
              <div className="activity-tab__body" dangerouslySetInnerHTML={{ __html: activity.body }} />
              <span> - {this.formattedTimestamp(activity.created_at)}</span>
            </li>
          );
        }
        return null;
      }

      const userNameArray = activity.author.split(" ");
      const userInitials =
        userNameArray[0].substr(0, 1).toUpperCase() +
        (userNameArray[1] ? userNameArray[1].substr(0, 1).toUpperCase() : "");
      const userClassName = `commenter${this.hashString(activity.author)}`;
      const attachments = activity.download_url
        ? [
            {
              id: 1,
              name: activity.attachment_name,
              url: activity.download_url,
            },
          ]
        : [];

      return (
        <li key={`comment-${activity.id}`} className={`activity-tab__item ${userClassName}`}>
          <Comment
            userName={activity.author}
            userInitials={userInitials}
            timestamp={this.formattedTimestamp(activity.created_at)}
            message={activity.body}
            attachments={attachments}
          />
        </li>
      );
    });
  }

  renderCommentsSection() {
    const { showActivityLog } = this.state;

    return (
      <div className="activity-tab__items">
        <div className="activity-tab__show-checkbox">
          <input
            id="show-activity-log-checkbox"
            type="checkbox"
            checked={showActivityLog}
            onClick={this.onClickShowActivityLog}
            readOnly
          />
          <label htmlFor="show-activity-log-checkbox">{i18n.t("_RecordProcessing.ShowActivityLog_")}</label>
        </div>
        <ul>{this.renderComments()}</ul>
      </div>
    );
  }

  renderAttachmentName() {
    const { attachment } = this.state;
    return attachment ? attachment.name : i18n.t("_RecordProcessing.AddAttachment_");
  }

  renderRemoveAttachment() {
    const { attachment } = this.state;
    if (attachment) {
      return (
        <RawButton
          className="record-processing__remove-attachment"
          a11yText={i18n.t("_RecordProcessing.RemoveAttachment_")}
          onClick={this.onRemoveAttachment}
        >
          <TrashBin data-qa-anchor="record-processing__remove-attachment" />
        </RawButton>
      );
    }
    return null;
  }

  setFileUploadComponent(component) {
    this.fileUploadComponent = component;
  }

  onKeyDownAttachment = event => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      this.openFileDialog(event);
    }
  };

  renderAddComment() {
    const { isAddCommentVisible } = this.props;
    const { isCommentSubmitting, newComment, isCommentExpanded } = this.state;

    if (!isAddCommentVisible) return null;

    const cn = classNames("record-processing__add-comment", { "is-focused": isCommentExpanded });

    return (
      <div className={cn}>
        <OutsideClickWrapper onOutsideClick={this.onMinimizeCommentSection}>
          <label htmlFor="add-comment-textarea" className="visually-hidden">
            {i18n.t("_RecordProcessing.AddComment_")}
          </label>
          <textarea
            id="add-comment-textarea"
            name="comment"
            rows="1"
            placeholder={i18n.t("_RecordProcessing.AddComment_")}
            onFocus={this.onMaximizeCommentSection}
            onChange={this.onChangeNewComment}
            disabled={isCommentSubmitting}
            value={newComment}
          />
          <div className="action-bar" aria-hidden={!isCommentExpanded}>
            <Button
              size="small"
              type="primary"
              isDisabled={isCommentSubmitting || newComment === ""}
              isPending={isCommentSubmitting}
              onClick={this.onSubmitComment}
            >
              {i18n.t("add")}
            </Button>
            <Button size="small" type="link" isDisabled={isCommentSubmitting} onClick={this.onCancelComment}>
              {i18n.t("cancel")}
            </Button>
          </div>
          <footer aria-hidden={!isCommentExpanded}>
            <Icon type="attachment" />
            <label
              className="file-wrapper"
              htmlFor="addAttachment"
              tabIndex="0"
              onKeyDown={this.onKeyDownAttachment}
              onClick={this.openFileDialog}
            >
              {this.renderAttachmentName()}
            </label>
            {this.renderRemoveAttachment()}
            <input
              id="addAttachment"
              type="file"
              ref={component => this.setFileUploadComponent(component)}
              onChange={this.onAddAttachment}
            />
          </footer>
        </OutsideClickWrapper>
      </div>
    );
  }

  renderActivity() {
    return (
      <div className="record-processing__activity-tab">
        <Heading isHidden level={4}>
          {this.props.a11yText}
        </Heading>
        {this.renderAddComment()}
        {this.renderCommentsSection()}
      </div>
    );
  }

  render() {
    const { isActive, isLoading } = this.props;
    if (isActive) {
      return isLoading ? <Spinner size="small" /> : this.renderActivity();
    }
    return null;
  }
}
