angular.module("acl.visualizer.biView").directive("biView", function() {
  return {
    restrict: "E",
    replace: true,
    scope: {
      fieldName: "<",
      isClickable: "<",
      fieldFormatIdentifier: "<",
      onClose: "&",
    },
    templateUrl: "visualizer/js/modules/core/biView/biView.tpl.html",
    controller: function(
      $scope,
      AppConfig,
      BiViewService,
      DataModel,
      EventService,
      StatisticsService,
      HistogramService,
      ChartService
    ) {
      var eventService = EventService.register("acl.biView");

      eventService.subscribe("biView.loaded", function(_, type, dataConfig, data) {
        if (type === "BarChart") {
          $scope.histogram = {};

          const sortedData = BiViewService.sortChartData(data, dataConfig.chartXAxis.type);

          $scope.histogram.data = sortedData;

          $scope.histogram.config = {
            xAxis: {
              hide: true,
              tickFormatter: ChartService.getValueFormatter(
                $scope.fieldFormatIdentifier,
                dataConfig.chartXAxis.fieldName,
                dataConfig.chartXAxis.type
              ),
            },
            yAxis: {
              hide: true,
              tickFormatter: ChartService.getValueFormatter(
                $scope.fieldFormatIdentifier,
                dataConfig.chartValue.fieldName,
                dataConfig.chartValue.type
              ),
              minimum: 0,
              maximum: sortedData.reduce(
                (max, series) =>
                  Math.max(max, series.values.reduce((seriesMax, point) => Math.max(point.values, seriesMax), 0)),
                0
              ),
            },
          };
        } else if (type === "StatisticsViz") {
          $scope.statisticsData = data[0];
        }
        $scope.isLoading = false;
        $scope.$apply();
      });

      $scope.onChartClick = () => {
        eventService.publish("biView.chartClick", $scope.fieldName);
      };

      $scope.onStatisticsClick = () => {
        eventService.publish("biView.statisticsClick", $scope.fieldName);
      };

      function load(fieldName) {
        var fieldData;
        if (fieldName) {
          fieldData = DataModel.table.field(fieldName);
          $scope.isLoading = true;

          $scope.statisticsData = null;
          $scope.histogram = null;

          $scope.isSupportableType = BiViewService.isSupportableType(fieldData.type);

          if ($scope.isSupportableType) {
            loadHistogram(fieldData);
            loadStatistics(fieldData);
          }
        }
      }

      function loadHistogram(fieldData) {
        var histogramConfig = HistogramService.createHistogramViewConfig(fieldData);
        HistogramService.loadData(histogramConfig);
      }

      function loadStatistics(fieldData) {
        var statisticsConfig = StatisticsService.createStatisticsViewConfig(fieldData);
        StatisticsService.loadData(statisticsConfig);
      }

      $(document).on("click", ".ngViewport", function() {
        eventService.publish("quickMenu.close");
      });

      $scope.$on("$destroy", () => {
        eventService.unregister();
      });

      load($scope.fieldName);
    },
  };
});
