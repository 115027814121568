import uniq from "lodash/uniq";
import GlobalFieldFormatMap from "@viz-ui/services/formatters/globalFieldFormatMap";
import { ERROR_CODES, getErrorCodeForInterpretation } from "./errorCode.service";
import ErrorReporter from "@viz-ui/services/common/errorReporterService";

export default class InterpretationDataLoader {
  constructor(AppConfig, InterpretationAdapter, InterpretationBackend, InterpretationDataStore, Localize) {
    "ngInject";

    this._exceptionHandler = ErrorReporter.instance("Storyboards");
    this._AppConfig = AppConfig;
    this._InterpretationAdapter = InterpretationAdapter;
    this._InterpretationBackend = InterpretationBackend;
    this._InterpretationDataStore = InterpretationDataStore;
    this._Localize = Localize;
  }

  async loadInterpretations(boards) {
    const interpretationIds = this._getInterpretationIdsToLoad(boards);
    if (interpretationIds.length) {
      try {
        const interpretationsResponse = await this._InterpretationBackend.loadInterpretations(interpretationIds);
        this._responseHandler(interpretationIds, interpretationsResponse);
      } catch (error) {
        this._errorHandler(interpretationIds);
        this._exceptionHandler(error);
      }
    }
  }

  _getInterpretationIdsToLoad(boards) {
    return uniq(boards.map(board => board.interpretation_id)).filter(
      interpretationId => !this._isInterpretationIdLoaded(interpretationId)
    );
  }

  _isInterpretationIdLoaded(interpretationId) {
    const interpretation = this._InterpretationDataStore.getInterpretation(interpretationId);
    const interpretationError = this._InterpretationDataStore.getInterpretationError(interpretationId);
    return interpretation || interpretationError;
  }

  _responseHandler(interpretationIds, response) {
    const interpretations = response.data.interpretations || [];
    const accessControls = response.data.access_controls || {};
    interpretationIds.forEach(interpretationId => {
      const interpretation = interpretations.find(interpretation => interpretation.id === interpretationId);
      if (interpretation) {
        const interpretationError = getErrorCodeForInterpretation(interpretation.raw);
        if (interpretationError) {
          this._addErrorToDataStore(interpretationId, interpretationError);
        } else {
          this._addToDataStore(interpretation);
        }
        this._setDrilldown(interpretation, accessControls[interpretation.id]);
        this._addGlobalFilterFormat(interpretation);
      } else {
        this._addErrorToDataStore(interpretationId, ERROR_CODES.INTERPRETATION_DELETED);
      }
    });
  }

  _errorHandler(interpretationIds) {
    interpretationIds.forEach(interpretationId => {
      this._addErrorToDataStore(interpretationId, ERROR_CODES.INTERPRETATION_ERROR);
    });
  }

  _addToDataStore(interpretation) {
    interpretation = this._formatDataConfig(interpretation);
    this._InterpretationDataStore.addInterpretation(interpretation.id, interpretation.raw);
  }

  _formatDataConfig(interpretation) {
    const visualizations = interpretation.raw.visualizations;
    if (!visualizations) return interpretation;

    visualizations.forEach(({ vizType, config }, index) => {
      if (config && vizType === "CombinationChart") {
        const { dataConfig } = config;
        if (dataConfig.chartColumns && dataConfig.chartColumns.length) {
          interpretation.raw.visualizations[index].config.dataConfig.chartSeries = dataConfig.chartColumns[0];
        }

        delete interpretation.raw.visualizations[index].config.dataConfig.chartColumns;
      }
    });
    return interpretation;
  }
  _setDrilldown(interpretation, accessControl) {
    const isAnonymous = this._AppConfig.isAnonymous;
    const canDrilldown = accessControl ? accessControl.drilldown : isAnonymous;
    this._InterpretationDataStore.setCanDrilldown(interpretation.id, canDrilldown);
  }

  _addGlobalFilterFormat(interpretation) {
    let interpretationModel = this._InterpretationAdapter.deserializeInterpretation(
      interpretation.raw,
      interpretation.id
    );
    GlobalFieldFormatMap.setFieldFormats(interpretation.id, interpretationModel.fieldFormatMap());
  }

  _addErrorToDataStore(interpretationId, error) {
    this._InterpretationDataStore.addInterpretationError(interpretationId, error);
  }
}
