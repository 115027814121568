import moment from "moment";
import { map, isObject, isArray, each } from "lodash";
/* eslint-disable */
const currentFormatVersion = "1.17";
const DataModelFormat = {
  convertSaveViz: (saveViz, inVersion, outVersion) => {
    saveViz = Object.assign({}, saveViz);
    getConverters().forEach(c => {
      if (compareVersion(inVersion, c.version) < 0 && compareVersion(outVersion, c.version) >= 0) {
        c.convert(saveViz);
      }
    });
    saveViz.formatVersion = compareVersion(outVersion, currentFormatVersion) <= 0 ? outVersion : currentFormatVersion;

    return saveViz;
  },

  compareVersion: (a, b) => compareVersion(a, b),

  formatVersion: () => currentFormatVersion,

  generateVisualizationId: () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1),
};

const convertSaveVizFilterValue = (value, type) => {
  if (type === "numeric") return value.replace(",", ".");
  return value;
};

const isDataTableTab = tabIndex => tabIndex === 0;

const addConditionalFormattingFlags = condition => {
  if (condition.textColorEnabled === undefined) condition.textColorEnabled = true;
  if (condition.backgroundColorEnabled === undefined) condition.backgroundColorEnabled = true;
};

const addConditionalIconFlags = condition => {
  if (condition.iconEnabled === undefined) condition.iconEnabled = false;
};

const compareVersion = (a, b) => {
  a = `${a}`.split(".");
  b = `${b}`.split(".");
  for (let i = 0; i < Math.max(a.length, b.length); i++) {
    a[i] = typeof a[i] === "undefined" ? -1 : Number(a[i]);
    b[i] = typeof b[i] === "undefined" ? -1 : Number(b[i]);
    if (a[i] < b[i]) return -1;
    if (a[i] > b[i]) return 1;
  }
  return 0;
};

const getConverters = () => [
  {
    version: "1.0",
    convert: saveViz => {
      // Initial version.
    },
  },
  {
    version: "1.1",
    convert: saveViz => {
      if (isObject(saveViz.filterConfig) && isArray(saveViz.filterConfig.filterList)) {
        saveViz.filterConfig.filterList.forEach(filterListItem => {
          if (!isArray(filterListItem.filters)) return;
          filterListItem.filters.forEach(filter => {
            if (!isObject(filter) || !isArray(filter.values)) return;
            filter.values = filter.values.map(value => convertSaveVizFilterValue(value, filterListItem.type));
          });
        });
      }

      if (isObject(saveViz.tableConfig) && isObject(saveViz.tableConfig.formatting)) {
        for (const key in saveViz.tableConfig.formatting) {
          const formatting = saveViz.tableConfig.formatting[key];
          if (!isObject(formatting) || !isArray(formatting.conditions)) continue;
          formatting.conditions.forEach(addConditionalFormattingFlags);
        }
      }
    },
  },
  {
    version: "1.2",
    convert: saveViz => {
      // Nothing to convert.
    },
  },
  {
    version: "1.3",
    convert: saveViz => {
      if (isObject(saveViz.tableConfig) && isObject(saveViz.tableConfig.formatting)) {
        for (const key in saveViz.tableConfig.formatting) {
          const formatting = saveViz.tableConfig.formatting[key];
          if (!isObject(formatting) || !isArray(formatting.conditions)) continue;
          formatting.conditions.forEach(addConditionalIconFlags);
        }
      }
    },
  },
  {
    version: "1.4",
    convert: saveViz => {
      if (isArray(saveViz.visualizations)) {
        const oldDefaultTitles = [
          "Visualisierung ohne Titel",
          "Untitled Visualization",
          "Visualización sin título",
          "Visualisation sans titre",
          "無題の視覚化",
          "제목 없는 시각화",
          "Wizualizacja bez nazwy",
          "Visualização sem título",
          "无标题可视化效果",
        ];
        const newDefaultTitle = "";

        saveViz.visualizations.forEach(visualization => {
          if (oldDefaultTitles.indexOf(visualization.title) >= 0) {
            visualization.title = newDefaultTitle;
          }
        });
      }
    },
  },
  {
    version: "1.5",
    convert: saveViz => {
      if (isArray(saveViz.visualizations)) {
        saveViz.visualizations.forEach(visualization => {
          if (!isObject(visualization.config)) {
            visualization.config = {};
          }
          if (!isObject(visualization.config.dataConfig)) {
            visualization.config.dataConfig = {};
          }
          if (!isObject(visualization.config.displayConfig)) {
            visualization.config.displayConfig = {};
          }
        });
      }
    },
  },
  {
    version: "1.6",
    convert: saveViz => {
      if (isArray(saveViz.visualizations)) {
        saveViz.visualizations.forEach(visualization => {
          if (visualization.id === undefined) {
            visualization.id = DataModelFormat.generateVisualizationId();
          }
        });
      }
    },
  },
  {
    version: "1.7",
    convert: saveViz => {
      if (isArray(saveViz.visualizations)) {
        saveViz.visualizations.forEach(visualization => {
          if (
            visualization &&
            visualization.config &&
            visualization.config.displayConfig &&
            typeof visualization.config.displayConfig.showTicks !== "undefined"
          ) {
            visualization.config.displayConfig.showPercentageTicks = visualization.config.displayConfig.showTicks;
            delete visualization.config.displayConfig.showTicks;
          }
        });
      }
    },
  },
  {
    version: "1.8",
    convert: saveViz => {
      if (isArray(saveViz.visualizations)) {
        saveViz.visualizations.forEach(visualization => {
          if (
            visualization &&
            visualization.config &&
            visualization.config.displayConfig &&
            typeof visualization.config.displayConfig.showControls === "undefined" &&
            visualization.type === "BarChart"
          ) {
            visualization.config.displayConfig.showControls = true;
          }
        });
      }
    },
  },
  {
    version: "1.9",
    convert: saveViz => {
      if (isArray(saveViz.visualizations)) {
        saveViz.visualizations.forEach(visualization => {
          if (
            visualization &&
            visualization.config &&
            visualization.config.displayConfig &&
            typeof visualization.config.displayConfig.showControls === "undefined" &&
            visualization.type === "StackedAreaChart"
          ) {
            visualization.config.displayConfig.showControls = true;
          }
        });
      }
      if (!saveViz.tableConfig) {
        saveViz.tableConfig = {
          columns: [],
          formatting: {},
        };
      }
    },
  },
  {
    version: "1.10",
    convert: saveViz => {
      if (isArray(saveViz.visualizations)) {
        saveViz.visualizations.forEach(visualization => {
          if (visualization && visualization.type === "MetricsViz") {
            visualization.type = "StatisticsViz";
          }
        });
      }
    },
  },
  {
    version: "1.11",
    convert: saveViz => {
      const rgbMap = {
        "rgb(222, 77, 51)": "rgb(205, 60, 68)", // red
        "rgb(66, 153, 109)": "rgb(41, 154, 122)", // green
        "rgb(38, 102, 171)": "rgb(43, 130, 191)", // blue

        "rgb(241, 193, 185)": "rgb(246, 194, 199)", // pink
        "rgb(249, 240, 219)": "rgb(255, 248, 234)", // light yellow
        "rgb(196, 227, 211)": "rgb(214, 240, 233)", // light green
        "rgb(176, 205, 237)": "rgb(221, 240, 253)", // light blue
        "rgb(214, 214, 231)": "rgb(231, 231, 248)", // light purple

        "rgb(220, 76, 60)": "rgb(205, 60, 68)",
        "rgb(233, 185, 90)": "rgb(234, 186, 81)",
        "rgb(88, 137, 73)": "rgb(121, 165, 81)",
        "rgb(16, 85, 162)": "rgb(43, 130, 191)",
        "rgb(77, 71, 128)": "rgb(83, 82, 159)",

        "rgb(245, 201, 196)": "rgb(246, 194, 199)",
        "rgb(249, 235, 205)": "rgb(255, 248, 234)",
        "rgb(204, 220, 201)": "rgb(188, 210, 168)",
        "rgb(182, 204, 227)": "rgb(221, 240, 253)",
        "rgb(202, 199, 217)": "rgb(231, 231, 248)",
      };

      const oldColors = Object.keys(rgbMap);
      const visualizationConfig = saveViz.visualizationConfig;
      const tableConfig = saveViz.tableConfig;

      if (tableConfig && tableConfig.formatting) {
        each(tableConfig.formatting, (column, key) => {
          const conditions = column.conditions.map(condition => {
            if (oldColors.includes(condition.iconColor)) condition.iconColor = rgbMap[condition.iconColor];
            if (oldColors.includes(condition.textColor)) condition.textColor = rgbMap[condition.textColor];
            if (oldColors.includes(condition.backgroundColor))
              condition.backgroundColor = rgbMap[condition.backgroundColor];
            return condition;
          });
          tableConfig.formatting[key].conditions = conditions;
        });
      }

      if (visualizationConfig && visualizationConfig.colorMapping) {
        each(visualizationConfig.colorMapping, (color, key) => {
          if (oldColors.includes(color)) {
            visualizationConfig.colorMapping[key] = rgbMap[color];
          }
        });
      }
    },
  },
  {
    version: "1.12",
    convert: saveViz => {
      if (saveViz.filterConfig && saveViz.filterConfig.filterList) {
        saveViz.filterConfig.filterList.forEach(fieldFilter => {
          if (fieldFilter.type === "datetime") {
            const filters = []
              .concat(fieldFilter.quickFilter)
              .concat(fieldFilter.filters)
              .filter(it => it);
            filters.forEach(filter => {
              filter.values = map(filter.values, value => moment.utc(value).format("YYYY-MM-DD HH:mm:ss"));
            });
          }
        });
      }
    },
  },
  {
    version: "1.13",
    convert: saveViz => {
      if (!saveViz.tableConfig.columns) {
        saveViz.tableConfig.columns = [];
      }
      if (!saveViz.tableConfig.formatting) {
        saveViz.tableConfig.formatting = {};
      }
    },
  },
  {
    version: "1.14",
    convert: saveViz => {
      const currentTabIndex = saveViz.visualizationInfo.currentTabIndex;

      if (isDataTableTab(currentTabIndex)) {
        saveViz.visualizationInfo.selectedVizId = null;
      } else {
        const vizIdIndex = currentTabIndex - 1;
        saveViz.visualizationInfo.selectedVizId = saveViz.visualizations[vizIdIndex].id;
      }

      delete saveViz.visualizationInfo.currentTabIndex;
    },
  },
  {
    version: "1.15",
    convert: saveViz => {
      if (!saveViz.tableConfig.formatting) {
        saveViz.tableConfig.formatting = {};
      }
      if (saveViz.filterConfig.sortField === null) {
        delete saveViz.filterConfig.sortField;
      }
    },
  },
  {
    version: "1.16",
    convert: saveViz => {
      delete saveViz.visualizationInfo.interpretationId;
    },
  },
  {
    version: "1.17",
    convert: saveViz => {
      saveViz.visualizations.forEach(viz => {
        viz.vizType = viz.type;
        if (["CombinationChart", "Treemap"].includes(viz.type)) {
          viz.type = "SummaryTable";
        }
      });
    },
  },
];

export default DataModelFormat;
/* eslint-enable */
