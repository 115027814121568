import DataModelFormat from "@viz-ui/services/charts/DataModel/dataModelFormatService";
import {
  sortChartDataByPropertyName,
  sortChartDataByTotals,
} from "@viz-ui/components/charts/services/highChartDataSorter";
import ColorPalette from "@acl-services/sriracha-color-palette/dist/ColorPalette";
import DataModel from "@viz-ui/services/charts/DataModel/dataModelService";
import i18n from "@viz-ui/i18n/i18n";
import EventService from "@viz-ui/services/eventService/eventService";
import BarChartService from "../barChart/barChartService";

class BiViewService {
  isSupportableType(fieldType) {
    const unSupportableTypes = ["logical", "file", "digital_signature"];
    return unSupportableTypes.indexOf(fieldType) === -1;
  }

  prepareVizConfig(chartType, dataConfig) {
    const config = {
      dataConfig: dataConfig,
    };
    const appConfig = {
      features: {
        logicalFieldsInCharts: true,
      },
    };
    let title;

    switch (chartType) {
      case "BarChart": {
        title = i18n.t("_Chart.DataDistribution.Title_") + " - " + dataConfig.chartXAxis.displayName;
        const barChart = new BarChartService(ColorPalette, DataModel, appConfig, EventService);
        config.displayConfig = barChart.defaultDisplayConfig();

        // Strings should default to descending order in the bar chart.
        if (dataConfig.chartXAxis.type === "character") {
          config.displayConfig.order = "desc";
        }
        break;
      }
      case "StatisticsViz": {
        title = i18n.t("_Chart.StatisticsViz.Title_") + " - " + dataConfig.chartCategory[0].displayName;
        break;
      }
      default:
        throw new Error("Unexpected chart type: " + chartType);
    }

    return {
      type: chartType,
      vizType: chartType,
      title: title,
      id: DataModelFormat.generateVisualizationId(),
      config: config,
    };
  }

  sortChartData(data, type) {
    // If character it should sort by descending count by default.

    if (type === "character") {
      data = sortChartDataByTotals(data, "desc");
    } else {
      data.forEach(function(row) {
        row.values = sortChartDataByPropertyName(row.values, "key", type);
      });
    }
    return data;
  }
}

export default new BiViewService();
