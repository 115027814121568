angular.module("acl.visualizer.multiSelect").directive("popoverTrigger", function($compile, $document) {
  return {
    restrict: "A",
    link: function(scope, element) {
      element.bind("click", function(e) {
        var target = e.currentTarget;
        var bounds = target.getBoundingClientRect();
        var x = bounds.left;
        var y = bounds.top + target.offsetHeight + 15;
        var body = $document.find("body");
        var popover = body.find(".multi-select__popover-content");

        if (popover.length) {
          popover.remove();
        } else {
          body.append(
            $compile(
              `<acl-popover-content
                  style='top: ${y}px; left: ${x}px;'>
              </acl-popover-content>`
            )(scope)
          );
        }
      });
    },
  };
});
