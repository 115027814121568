angular
  .module("acl.visualizer.model.table")
  .factory("RecordsetManager", function($q, AppConfig, DataRequester, PromiseCache) {
    return {
      loadRecordset,
    };

    function loadRecordset(table, filterConfig, globalFilters, scope) {
      return PromiseCache.getPromise(requestRecords(table, filterConfig, globalFilters, scope), {
        requestType: "recordsetRequest",
        table,
        filterConfig,
      });
    }

    function requestRecords(table, filterConfig, globalFilters, scope) {
      return function() {
        return DataRequester.getInstance("recordsetRequest").request(
          AppConfig.timeout,
          table,
          filterConfig,
          globalFilters,
          scope
        );
      };
    }
  });
