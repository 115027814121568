import chartService from "@viz-ui/services/charts/chartService";
import Sorter from "@viz-ui/services/sorters/sorter";
import isArray from "lodash/isArray";
import i18n from "@viz-ui/i18n/i18n";

class SummaryTableChartService extends chartService {
  pendingSummaryTableData = null;

  defaultDisplayConfig = () => ({ showTotals: true });

  isDataConfigParsed = true;

  getSummaryTableData = chartModel => ({
    table: this.DataModel.table.model(),
    formattingOptions: this.DataModel.tableConfig.formattingOptions(),
    rawData: chartModel.data,
    columns: this.DataModel.tableConfig.columns(),
  });

  getFixedColumnsLeftCount = dataConfig => {
    const { freezeRowHeaders, chartRows } = dataConfig;
    return freezeRowHeaders && chartRows ? chartRows.length : 0;
  };

  getChartDirectiveConfig = (interpretationId, chartConfig) => chartConfig;

  sortFields = chartConfigColumnDefs => ({
    chartRows: Sorter.sort(chartConfigColumnDefs.chartRows, { valueParser: item => item.displayName }),
    chartColumns: Sorter.sort(chartConfigColumnDefs.chartColumns, { valueParser: item => item.displayName }),
    chartValues: Sorter.sort(chartConfigColumnDefs.chartValues, { valueParser: item => item.displayName }),
  });

  populateChartConfigColumnDefs = fields => {
    const chartConfigColumnDefs = this.chartConfigColumnDefs();

    if (fields) {
      for (const fieldName in fields) {
        const tempColumnDef = {};
        tempColumnDef.displayName = fields[fieldName].displayName;
        tempColumnDef.fieldId = fields[fieldName].colId;
        tempColumnDef.fieldName = fieldName;
        tempColumnDef.type = fields[fieldName].type;
        tempColumnDef.class = "";

        switch (tempColumnDef.type) {
          case "character":
            tempColumnDef.class = "acl-i-character";
            break;
          case "logical":
            if (this.AppConfig.features.logicalFieldsInCharts || tempColumnDef.type !== "logical") {
              tempColumnDef.class = "acl-i-boolean";
            }
            break;
          case "date":
            tempColumnDef.class = "acl-i-calendar";
            break;
          case "datetime":
            tempColumnDef.class = "acl-i-time-and-date";
            break;
          case "time":
            tempColumnDef.class = "acl-i-clock";
            break;
          case "numeric":
            tempColumnDef.class = "acl-i-number-sign";
            break;
        }

        switch (tempColumnDef.type) {
          case "character":
          case "logical":
          case "date":
          case "datetime":
          case "time":
            if (this.AppConfig.features.logicalFieldsInCharts || tempColumnDef.type !== "logical") {
              chartConfigColumnDefs.chartColumns.push(tempColumnDef);
              chartConfigColumnDefs.chartRows.push(tempColumnDef);
            }
            break;
          case "numeric":
            chartConfigColumnDefs.chartColumns.push(tempColumnDef);
            chartConfigColumnDefs.chartRows.push(tempColumnDef);
            chartConfigColumnDefs.chartValues.push(tempColumnDef);
            break;
        }
      }
    }

    // Add count option to the values list
    if (isArray(chartConfigColumnDefs.chartValues)) {
      chartConfigColumnDefs.chartValues.unshift({
        displayName: i18n.t("_SummaryTable.Count.Label_"),
        fieldName: "Count",
        type: "count",
      });
    }

    return this.sortFields(chartConfigColumnDefs);
  };

  chartConfigColumnDefs = () => ({
    chartRows: [],
    chartColumns: [],
    chartValues: [],
  });

  setPendingSummaryTableData = newProps => {
    this.pendingSummaryTableData = newProps;
  };

  isValidDataConfig = dataConfig => {
    const rowContainsField =
      dataConfig.chartRows &&
      dataConfig.chartRows.length &&
      dataConfig.chartRows[0] &&
      dataConfig.chartRows[0].fieldName;
    const columnContainsField =
      dataConfig.chartColumns &&
      dataConfig.chartColumns.length &&
      dataConfig.chartColumns[0] &&
      dataConfig.chartColumns[0].fieldName;
    return dataConfig && (rowContainsField || columnContainsField);
  };
}

export default SummaryTableChartService;
