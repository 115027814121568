import uniq from "lodash/uniq";
import isNumber from "lodash/isNumber";

import { HighChartDirective, HighChartController } from "../highChartDirective";
import { BAR_CHART } from "../boost/boostThresholds";

class BarChartDirective extends HighChartDirective {
  controller = Controller;
  static factory = () => new BarChartDirective();
}

class Controller extends HighChartController {
  static stacking = {
    stack: "normal",
    expand: "percent",
    group: undefined,
  };

  static configPath = {
    labelFontSize: [
      "legend.itemStyle.fontSize",
      "plotOptions.series.dataLabels.style.fontSize",
      "tooltip.style.fontSize",
      "xAxis.labels.style.fontSize",
      "xAxis.title.style.fontSize",
      "yAxis.labels.style.fontSize",
      "yAxis.title.style.fontSize",
    ],
  };

  constructor($scope, $timeout, HighChartLabelSizer, BarChart, AppConfig, Localize) {
    "ngInject";
    super($scope, $timeout, HighChartLabelSizer);
    this.BarChartService = BarChart;
    this.AppConfig = AppConfig;
    this.Localize = Localize;
  }

  findPercentage = (xValue, data) => {
    const barSlice = data.find(d => d.xValue === xValue);
    return barSlice.yPercent;
  };

  onLegendItemClick = item => {
    const showAll = !this.hcConfig.series.some(entry => entry.visible && entry.name !== item.name);
    const series = this.hcConfig.series.map(entry =>
      angular.extend({}, entry, {
        visible: showAll || (entry.name === item.name ? !item.visible : entry.visible),
      })
    );
    this.updateHcConfig({ series });
  };

  onLegendItemDoubleClick = item => {
    const series = this.hcConfig.series.map(entry => angular.extend({}, entry, { visible: entry.name === item.name }));
    this.updateHcConfig({ series });
  };

  getConfig = (config, rawData) => {
    const chartData = rawData;
    let data = this.BarChartService.reorderValues(chartData, config);
    let { findPercentage } = this;
    if (Controller.stacking[config.chartStyle]) {
      data = data.reverse();
    }
    let categories = uniq(data[0].values.map(d => d.key));
    //calculating percentage
    if (config.showPercentageTicks) {
      data = this.BarChartService.getPercentage(data, categories, config.chartSeries);
    }

    let hcConfig = {
      legend: {
        enabled: config.showLegend && config.hasMultipleSeries,
        align: "right",
        verticalAlign: "top",
        reversed: !!Controller.stacking[config.chartStyle],
        labelFormatter: function() {
          return config.labelFormatter && this.options.name !== "(blank)"
            ? config.labelFormatter(this.options.legendDisplayName)
            : this.options.legendDisplayName;
        },
      },
      boost: this.getBoostConfig(config, categories.length >= BAR_CHART),
      //tooltip: { shared: true }, //This breaks tooltip when multiple series
      xAxis: {
        categories: categories,
        labels: {
          formatter: function() {
            return config.xAxis.tickFormatter(this.value);
          },
        },
        title: {
          text: config.xAxis.label,
        },
      },
      yAxis: {
        title: {
          text: config.yAxis.label,
        },
        labels: {
          formatter: function() {
            return config.chartStyle === "expand" ? `${this.value}%` : config.yAxis.tickFormatter(this.value);
          },
        },
        min: isNumber(config.yAxis.minimum) ? config.yAxis.minimum : undefined,
        max: isNumber(config.yAxis.maximum) ? config.yAxis.maximum : undefined,
        startOnTick: !isNumber(config.yAxis.minimum),
        endOnTick: !isNumber(config.yAxis.maximum),
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: config.displayDataLabels || config.showPercentageTicks,
            crop: false,
            formatter: function() {
              let showPercentage = config.showPercentageTicks;
              let percentage = "";
              let value = "";
              if (config.displayDataLabels) {
                value = config.yAxis.tickFormatter(this.y);
              }
              if (showPercentage) {
                percentage = `<br/>(${findPercentage(this.x, this.series.data)}%)`;
              }
              return `${value} ${percentage}`;
            },
          },
          stacking: Controller.stacking[config.chartStyle],
          tooltip: {
            headerFormat: "",
            pointFormatter: function() {
              let header = config.hasMultipleSeries
                ? `
              <span style="text-decoration: underline grey;">${
                config.labelFormatter && this.series.options.name !== "(blank)"
                  ? config.labelFormatter(this.series.options.legendDisplayName)
                  : this.series.options.legendDisplayName
              }</span>
              <br/>
              `
                : "";
              let body = `
              <span>${config.xAxis.tickFormatter(this.xValue)}</span>
              <br/>
              <b>${config.yAxis.tickFormatter(this.y)}</b>
              `;
              if (config.showPercentageTicks) {
                body = `${body} 
                 <br/>
                <b>(${this.yPercent}%)</b>
                `;
              }

              return header + body;
            },
          },
          point: {
            events: {
              click: this.clickWrapper(null, point => {
                this.zoomIn(config.hasMultipleSeries ? [point.series.name, point.xValue] : [point.xValue]);
              }),
            },
          },
          events: {
            legendItemClick: this.clickWrapper(this.onLegendItemClick, this.onLegendItemDoubleClick),
          },
          turboThreshold: 0,
          animation: this.canAnimate(),
        },
      },

      // Adding legendDisplayName property to handle (blank) value  & to localize the string (issue GRCPRD-4135)
      series: data.map((series, index) => ({
        type: config.horizontal ? "bar" : "column",
        name: series.key,
        legendDisplayName:
          series.key === "(blank)" ? this.Localize.getLocalizedString("_Filter.BlankValue.Label_") : series.key,
        color:
          !this.getBoostConfig(config, categories.length >= BAR_CHART).enabled && this.$scope.isPatternFill
            ? this.patternFillPalettes(index)
            : series.color,
        boostThreshold: BAR_CHART,
        visible: series.visible !== false,
        data: series.values.map(value => ({
          x: categories.indexOf(value.key),
          y: value.values,
          xValue: value.key,
          yPercent: value.percentage,
        })),
      })),
    };
    hcConfig = this.updateLabelFontSize(hcConfig, Controller.configPath.labelFontSize);
    return hcConfig;
  };

  zoomIn = keyArray => {
    if (angular.isFunction(this.$scope.zoomInHandler)) {
      this.$scope.zoomInHandler(keyArray);
    }
  };
}

export default BarChartDirective;
