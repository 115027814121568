import cloneDeep from "lodash/cloneDeep";

export default class ProcessedDataHandler {
  constructor(DataModel, DataTableService) {
    "ngInject";
    this.DataModel = DataModel;
    this.DataTableService = DataTableService;

    this.processedRecords = [];
  }

  isProcessedEmpty = () => this.processedRecords.length === 0;

  resetProcessed = () => {
    this.processedRecords = [];
  };

  isRecordProcessed = record => this.processedRecords.includes(getId(record));

  correctTableDataAfterProcess = (processedRecordId, lastRecNo) => {
    // This helps to fix the loaded record set in the cases it gets offset by
    // a process record displaying the data. This is needed to resume
    // lazyloading. If all records loaded not necessary.
    const reprocessing = this.processedRecords.includes(processedRecordId);

    if (!reprocessing) {
      this.processedRecords.push(processedRecordId);
    }

    const numberProcessed = this.processedRecords.length;

    const originalParams = this.DataModel.getFilterConfig().params;

    let start = lastRecNo - numberProcessed;
    start = start > 0 ? start : 1;

    const limit = lastRecNo - start + 1;

    const newParams = {
      start,
      limit,
    };

    this.DataModel.filterConfig.setParams(newParams);

    const withMetaData = false;
    return this.DataTableService.loadTableData(withMetaData).then(responseData => {
      // Don't care about processed records since they:
      //  A) Are already in dataTable so no need to add
      //  B) They will stay in place always so don't need to remove
      //  C) We want to ensure that non processed records that are within
      //  the tableData loaded in accordance with the data on backend.
      const lastNonProcessedRecord = this._getLastNonProcessed(responseData.result);
      const lastLoadedNonProcessedRecord = this._getLastNonProcessed(this.DataModel.table.data());
      const secondLastLoadedNonProcessedRecord = this._getLastNonProcessed(this.DataModel.table.data(), 2);

      if (!this._isIdEqualOrBothUndefined(lastNonProcessedRecord, lastLoadedNonProcessedRecord)) {
        // If record is reprocessed it could potentially re-enter the table
        // data set. We determine this by comparing the
        // lastNonProcessedRecord from backend with the last TWO
        // loadedNonProcessedRecords. In the case the lastNonProcessedRecord
        // is the same as the secondLastLoaded one then we must remove the
        // lastLoaded one.
        if (
          reprocessing &&
          this._isIdEqualOrBothUndefined(lastNonProcessedRecord, secondLastLoadedNonProcessedRecord)
        ) {
          this._removeRecordFromDataModel(lastLoadedNonProcessedRecord);
        } else {
          this._addRecordToDataModel(lastNonProcessedRecord);
        }
      }

      this.DataModel.table.filteredRecordCount(responseData.filteredRecordCount);
      this.DataModel.filterConfig.setParams(originalParams);

      return Promise.resolve();
    });
  };

  _addRecordToDataModel(record) {
    const data = this.DataModel.table.data();
    data.push(record);
    this.DataModel.table.data(data);
  }

  _removeRecordFromDataModel(record) {
    this.DataModel.deleteRows([record]);
  }

  _getLastNonProcessed(records, nthLast = 1) {
    let numberFound = 0;
    return cloneDeep(records)
      .reverse()
      .find(record => {
        if (!this.isRecordProcessed(record)) {
          numberFound += 1;
        }
        return numberFound === nthLast;
      });
  }

  _isIdEqualOrBothUndefined(recordA, recordB) {
    if (typeof recordA === "undefined" && typeof recordB === "undefined") {
      return true;
    } else if (typeof recordA === "undefined" || typeof recordB === "undefined") {
      return false;
    }
    return getId(recordA) === getId(recordB);
  }
}

function getId(record) {
  return record["metadata.exception_id"];
}
