import chartService from "@viz-ui/services/charts/chartService";
import Sorter from "@viz-ui/services/sorters/sorter";

class PieChartService extends chartService {
  defaultDisplayConfig = () => ({
    showLegend: true,
    donut: false,
    donutRatio: 0.35,
    showPercentageTicks: true,
    showCountTicks: true,
    enableBoost: true,
  });

  getChartDirectiveConfig = (interpretationId, chartConfig) => {
    const getColorByField = () =>
      (chartConfig &&
        chartConfig.dataConfig &&
        chartConfig.dataConfig.chartCategory &&
        chartConfig.dataConfig.chartCategory.fieldName) ||
      "";

    const valueFormatter = (interpretationId, chartConfig) => {
      const { vizId, dataConfig, displayConfig } = chartConfig;
      if (displayConfig.valueFormattingOptions && dataConfig.chartValue.aggregationType === "average") {
        const chartValueFieldName = `${vizId}-chart-value`;
        const chartValueFieldType = "numeric";
        return this.getValueFormatter(interpretationId, chartValueFieldName, chartValueFieldType);
      }
      return this.getValueFormatter(interpretationId, dataConfig.chartValue.fieldName, dataConfig.chartValue.type);
    };
    /* eslint-disable */
    return {
      showLegend: chartConfig.displayConfig.showLegend,
      donut: chartConfig.displayConfig.donut,
      donutRatio: chartConfig.displayConfig.donutRatio,
      showPercentageTicks: chartConfig.displayConfig.showPercentageTicks,
      displayDataLabels: chartConfig.displayConfig.showCountTicks,
      enableBoost: chartConfig.displayConfig.enableBoost,
      showTotals: chartConfig.displayConfig.showTotals,
      DATA_ITEM_LIMIT: this.getDataItemLimit(),
      colorByField: getColorByField(),
      labelFormatter: this.getValueFormatter(
        interpretationId,
        chartConfig.dataConfig.chartCategory.fieldName,
        chartConfig.dataConfig.chartCategory.type
      ),
      valueFormatter: valueFormatter(interpretationId, chartConfig),
    };

    /* eslint-enable */
  };

  populateChartConfigColumnDefs = chartConfigColumnDefs => {
    let tempColumnDef;
    const fields = this.DataModel.table.fields();
    if (fields) {
      for (const fieldName in fields) {
        tempColumnDef = {};
        tempColumnDef.displayName = fields[fieldName].displayName;
        tempColumnDef.fieldName = fieldName;
        tempColumnDef.type = fields[fieldName].type;

        switch (tempColumnDef.type) {
          case "character":
          case "logical":
            if (this.AppConfig.features.logicalFieldsInCharts || tempColumnDef.type === "character") {
              chartConfigColumnDefs.chartCategory.push(tempColumnDef);
            }
            break;
          case "numeric":
            chartConfigColumnDefs.chartValue.push(tempColumnDef);
            chartConfigColumnDefs.chartCategory.push(tempColumnDef);
            break;
          case "date":
          case "datetime":
          case "time":
            chartConfigColumnDefs.chartCategory.push(tempColumnDef);
            break;
        }
      }
      chartConfigColumnDefs.chartCategory = Sorter.sort(chartConfigColumnDefs.chartCategory, {
        valueParser: item => item.displayName,
      });
      chartConfigColumnDefs.chartValue = Sorter.sort(chartConfigColumnDefs.chartValue, {
        valueParser: item => item.displayName,
      });
    }

    return chartConfigColumnDefs;
  };

  chartConfigColumnDefs = () => ({
    chartCategory: [],
    chartValue: [],
  });

  isValidDataConfig = dataConfig =>
    !!(
      dataConfig &&
      dataConfig.chartCategory &&
      dataConfig.chartValue &&
      ((dataConfig.chartValue.aggregationType && dataConfig.chartValue.fieldName) ||
        dataConfig.chartValue.aggregationType === "count")
    );

  getFilters = (keyArray, config) => {
    const categoryFormatter = this.getFormatterForType(config.dataConfig.chartCategory.type);
    var filters = [{ field: config.dataConfig.chartCategory, value: categoryFormatter(keyArray[0]) }];
    return filters;
  };
}

export default PieChartService;
