import moment from "moment";
import isArray from "lodash/isArray";
import map from "lodash/map";
import some from "lodash/some";
import checkboxState from "@viz-ui/models/checkboxState";
import MultiSelectFilterStore from "@viz-ui/services/quickMenu/MultiSelectFilterStoreService";

//FIXME this should be moved to filters module.
angular
  .module("acl.visualizer.dataFilter", [
    "acl.visualizer.dataModel",
    "acl.common.localize",
    "acl.common.config",
    "acl.visualizer.checkboxFilter",
    "acl.visualizer.sorters",
  ])
  .factory("DataFilter", function($filter, AppConfig, DataModel, Localize, Sorter) {
    var dataFilter = {
      hasInvalidFilters: function(filterConfig) {
        var filterNames = map(filterConfig.filterList, "name");
        return some(filterNames, filterName => {
          return (
            this.isFilterActive(filterConfig, filterName) &&
            !this.areChangedFieldCriteriaFiltersValid(filterConfig, filterName)
          );
        });
      },

      areChangedFieldCriteriaFiltersValid: function(filterConfig, fieldName) {
        var fieldFilters = this.filtersForField(filterConfig, fieldName);
        var valid = !some(fieldFilters, filter => {
          return filter.changed && !this.isCriteriaFilterValid(filter);
        });

        return valid;
      },

      isCriteriaFilterValid: function(criteriaFilter) {
        switch (criteriaFilter.operator) {
          case "is_blank":
          case "is_not_blank":
            return true;
          case "between":
            return !!(criteriaFilter.values[0] && criteriaFilter.values[1]);
          default:
            return !!criteriaFilter.values[0];
        }
      },

      clearNewCriteriaFilter: function(field) {
        field.criteriaFilter.newFilter = {};
      },

      filtersForField: function(filterConfig, fieldName) {
        var filters = [];
        var fieldFilterIndex = this.findFilterIndexByName(filterConfig, fieldName);

        // FIXME: Make this easier to read by using functional array methods.
        if (fieldFilterIndex >= 0 && filterConfig.filterList[fieldFilterIndex].filters) {
          for (var iFilter = 0; iFilter < filterConfig.filterList[fieldFilterIndex].filters.length; iFilter++) {
            var filter = filterConfig.filterList[fieldFilterIndex].filters[iFilter];
            filters.push(filter);
          }
        }
        return filters;
      },

      setOrToggleSort: function(newSortField, newSortOrder) {
        var resetSort = false;
        var oldSortField = DataModel.filterConfig.sortFieldId();
        var oldSortOrder = DataModel.filterConfig.sortOrder();
        if (oldSortField && oldSortOrder) {
          var isExistingSort = oldSortField === newSortField && oldSortOrder === newSortOrder;
          if (isExistingSort) {
            DataModel.filterConfig.sortFieldId(null).sortOrder(null);
            resetSort = true;
          }
        }
        if (!resetSort) {
          DataModel.filterConfig.sortFieldId(newSortField).sortOrder(newSortOrder);
        }
      },

      addFilter: function(filterConfig, colName, type, operator, values, connector) {
        if (!filterConfig.filterList) {
          filterConfig.filterList = [];
        }
        var filterList = filterConfig.filterList;

        var filter = {};
        filter.operator = operator;

        switch (type) {
          case "datetime":
            filter.values = getMomentParsedValues(values, "YYYY-MM-DD HH:mm:ss");
            break;
          case "date":
            filter.values = getMomentParsedValues(values, "YYYY-MM-DD");
            break;
          default:
            filter.values = values.slice(0);
        }

        if (connector) {
          filter.connector = connector;
        }

        var filterObj = this.findFilterByName(filterConfig, colName);
        if (!filterObj) {
          filterList.push({
            name: colName,
            type: type,
            filters: [filter],
            active: true,
          });
        } else {
          if (!filterObj.filters) {
            filterObj.filters = [];
          }
          filterObj.filters.push(filter);
        }

        return true;
      },

      updateFilter: function(filterConfig, colName) {
        if (!filterConfig.filterList) return;

        var filterObj = this.findFilterByName(filterConfig, colName);
        if (filterObj && filterObj.filters) {
          filterObj.filters.forEach(function(filter) {
            if (filter.operator !== "between") {
              filter.values.splice(1);
            }
          });
        }
      },

      removeFilter: function(filterConfig, colName, filter) {
        var updated = false;
        if (filterConfig.filterList) {
          var fieldFilterIndex = this.findFilterIndexByName(filterConfig, colName);
          if (fieldFilterIndex >= 0 && filterConfig.filterList[fieldFilterIndex].filters) {
            var filterIndex = filterConfig.filterList[fieldFilterIndex].filters.indexOf(filter);
            if (filterIndex >= 0) {
              filterConfig.filterList[fieldFilterIndex].filters.splice(filterIndex, 1);
              updated = true;
              if (!filterConfig.filterList[fieldFilterIndex].filters.length) {
                delete filterConfig.filterList[fieldFilterIndex].filters;
              }
              this.cleanFilterListByIndex(filterConfig, fieldFilterIndex);
            }
          }
        }
        return updated;
      },

      removeAllCriteriaFilter: function(filterConfig, colName) {
        var updated = false;
        if (filterConfig.filterList) {
          var fieldFilterIndex = this.findFilterIndexByName(filterConfig, colName);
          if (fieldFilterIndex >= 0 && filterConfig.filterList[fieldFilterIndex].filters) {
            delete filterConfig.filterList[fieldFilterIndex].filters;
            updated = true;
            this.cleanFilterListByIndex(filterConfig, fieldFilterIndex);
          }
        }
        return updated;
      },

      isNewCriteriaFilterValid: function(filter) {
        var valid = false;

        // FIXME: Should be valid for falsy values.
        if (filter.operator === "between" && filter.value && filter.value2) {
          valid = true;
        }

        // FIXME: Should be valid for falsy values.
        if (filter.operator !== "between" && filter.operator && filter.value) {
          valid = true;
        }

        if (filter.operator === "is_blank" || filter.operator === "is_not_blank") {
          valid = true;
        }
        return valid;
      },

      bindQuickFilterCheckedValues: function(filterConfig) {
        if (filterConfig && filterConfig.filterList) {
          for (var iFilter = 0; iFilter < filterConfig.filterList.length; iFilter++) {
            var fieldName = filterConfig.filterList[iFilter].name;
            if (filterConfig.filterList[iFilter].quickFilter) {
              MultiSelectFilterStore.bindCheckedValuesOnLoad(
                fieldName,
                filterConfig.filterList[iFilter].quickFilter.values,
                filterConfig.filterList[iFilter].quickFilter.unCheckedValues,
                filterConfig.filterList[iFilter].quickFilter.selectAllState
              );
            }
          }
        }
      },

      resetFilterConfigQuickFilterValues: function(
        filterConfig,
        colName,
        type,
        values,
        unCheckedValues,
        selectAllState
      ) {
        var updated = false;

        if (!values.length) {
          //if values is empty, remove the quickFilter from the filterList
          var filterIndex = this.findFilterIndexByName(filterConfig, colName);
          if (filterIndex >= 0) {
            if (filterConfig.filterList[filterIndex].quickFilter) {
              delete filterConfig.filterList[filterIndex].quickFilter;
              updated = true;
              this.cleanFilterListByIndex(filterConfig, filterIndex);
            }
          }
        } else {
          if (!filterConfig.filterList) {
            filterConfig.filterList = [];
          }

          var quickFilter = {
            operator: "=",
            values:
              selectAllState == checkboxState.CHECKED || (unCheckedValues && unCheckedValues.length > 0)
                ? []
                : values.slice(0),
            unCheckedValues: unCheckedValues,
            selectAllState: selectAllState,
          };

          var filterObj = this.findFilterByName(filterConfig, colName);
          if (filterObj) {
            if (filterObj.quickFilter) {
              quickFilter.storyboardIndicatorValues = filterObj.quickFilter.storyboardIndicatorValues;
              quickFilter.indeterminateValues = filterObj.quickFilter.indeterminateValues;
            }
            filterObj.quickFilter = quickFilter;
            updated = true;
          } else {
            filterConfig.filterList.push({
              name: colName,
              type: type,
              quickFilter: quickFilter,
              active: true,
            });
            updated = true;
          }
        }
        return updated;
      },

      cleanFilterListByIndex: function(filterConfig, fieldIndex) {
        if (!filterConfig.filterList[fieldIndex].quickFilter && !filterConfig.filterList[fieldIndex].filters) {
          filterConfig.filterList.splice(fieldIndex, 1);
        }
        if (!filterConfig.filterList.length) {
          delete filterConfig.filterList;
        }
      },

      findFilterIndexByName: function(filterConfig, colName) {
        if (filterConfig.filterList) {
          for (var i = 0; i < filterConfig.filterList.length; i++) {
            if (filterConfig.filterList[i].name === colName) {
              return i;
            }
          }
        }
        return -1;
      },

      findFilterByName: function(filterConfig, colName) {
        if (filterConfig.filterList) {
          return filterConfig.filterList[this.findFilterIndexByName(filterConfig, colName)];
        }
      },

      getFilterFieldNames: function(filterConfig) {
        if (filterConfig.filterList) {
          return filterConfig.filterList.map(function(d) {
            return d.name;
          });
        }
        return [];
      },

      isFilterActive: function(filterConfig, fieldName) {
        var filterActive = true;
        var filterIndex = this.findFilterIndexByName(filterConfig, fieldName);
        if (filterIndex >= 0) {
          filterActive = filterConfig.filterList[filterIndex].active;
        }
        return filterActive;
      },

      getFilterRequestBody: function(filterConfig) {
        var requestBody = {};
        if (filterConfig.jobId) {
          requestBody.jobId = filterConfig.jobId;
        }
        if (filterConfig.params) {
          requestBody.params = filterConfig.params;
        }
        if (filterConfig.sortField) {
          requestBody.sortField = filterConfig.sortField;
        }

        if (AppConfig.features.remediationToggles) {
          requestBody.myRecords = filterConfig.myRecords;
          requestBody.openStatuses = filterConfig.openStatuses;
        }

        if (filterConfig.filterList) {
          requestBody.filterList = [];

          for (var iField = 0; iField < filterConfig.filterList.length; iField++) {
            if (filterConfig.filterList[iField].active) {
              var filter = {
                name: filterConfig.filterList[iField].name,
                type: filterConfig.filterList[iField].type,
              };
              // initialize filters if either quickFilter or filters exist
              if (filterConfig.filterList[iField].quickFilter || filterConfig.filterList[iField].filters) {
                filter.filters = [];
              }
              if (filterConfig.filterList[iField].filters) {
                for (var iFilter = 0; iFilter < filterConfig.filterList[iField].filters.length; iFilter++) {
                  var values = [];
                  var currFilter = filterConfig.filterList[iField].filters[iFilter];
                  currFilter.values = currFilter.values || [];
                  // make sure we are taking 2 values for between and 1 value for other operators
                  switch (filterConfig.filterList[iField].type) {
                    case "date":
                      values = getMomentParsedValues(currFilter.values, "YYYY-MM-DD");
                      break;
                    case "datetime":
                      const datetimeFormat = AppConfig.systemDatetimeFormat || "YYYY-MM-DD HH:mm:ss";
                      values = getMomentParsedValues(currFilter.values, datetimeFormat);
                      break;
                    case "numeric":
                      values.push(currFilter.values[0]);
                      if (currFilter.operator === "between") {
                        values.push(currFilter.values[1]);
                      }
                      break;
                    default:
                      if (currFilter.operator === "between") {
                        values = currFilter.values.slice(0, 2);
                      } else if (currFilter.operator === "is_blank" || currFilter.operator === "is_not_blank") {
                        // FIXME: What happens if date, datetime, or numeric types above are using blank operators.
                        values = [];
                      } else {
                        values = currFilter.values.slice(0, 1);
                      }
                  }
                  filter.filters.push({
                    operator: currFilter.operator,
                    values: values,
                    connector: currFilter.connector,
                  });
                }
              }

              const quickFilter = filterConfig.filterList[iField].quickFilter;
              if (quickFilter) {
                if (quickFilter.unCheckedValues && quickFilter.unCheckedValues.length > 0) {
                  quickFilter.unCheckedValues.forEach(unCheckedValue => {
                    const sanitizeQuickFilter = {
                      ...quickFilter,
                      operator: unCheckedValue == "" ? "is_not_blank" : "!=",
                      values: unCheckedValue == "" ? [] : [unCheckedValue],
                      connector: "and",
                    };
                    delete sanitizeQuickFilter.selectAllState;
                    delete sanitizeQuickFilter.unCheckedValues;
                    filter.filters.push(sanitizeQuickFilter);
                  });
                } else if (quickFilter.values && quickFilter.values.length != 0) {
                  const sanitizeQuickFilter = { ...quickFilter };
                  delete sanitizeQuickFilter.selectAllState;
                  delete sanitizeQuickFilter.unCheckedValues;
                  filter.filters.push(quickFilter);
                }
              }
              if (filter.filters.length > 0) requestBody.filterList.push(filter);
            }
          }
          if (!requestBody.filterList.length) {
            delete requestBody.filterList;
          }
        }
        return requestBody;
      },

      valueExistInQuickFilter: function(filterConfig, colName, value) {
        var filter = this.findFilterByName(filterConfig, colName);
        return filter && filter.quickFilter && filter.quickFilter.values.indexOf(value) !== -1;
      },

      isAlphaSortType: function(fieldType) {
        switch (fieldType) {
          case "character":
          case "logical":
            return true;

          default:
            return false;
        }
      },

      isFormattableType: function(fieldType) {
        return fieldType !== "logical";
      },

      isDigitalSortType: function(fieldType) {
        switch (fieldType) {
          case "date":
          case "datetime":
          case "time":
          case "numeric":
            return true;

          default:
            return false;
        }
      },
    };

    function getMomentParsedValues(values, format) {
      if (!isArray(values)) return [];

      var formattedValues = [];
      values.forEach(function(elem, index) {
        formattedValues.push(moment(elem).format(format));
      });
      return formattedValues;
    }

    return dataFilter;
  });
