import backendApi from "@results/services/apiCall/backendApi";
import { stripData } from "./dataTableBackendHelper";
import ApiPath from "@viz-ui/services/apiPath/apiPathService";

angular
  .module("acl.visualizer.dataTable.backend")
  .service("DataTableBackendService", function($q, AppConfig, DataFilter, queuedHttp, RecordsetAdapter, TableAdapter) {
    var service = {
      getTableMetadata: function(tableIdentifier) {
        var url = ApiPath.table.getMetaDataUrl(tableIdentifier);
        return queuedHttp.get(url).then(function(response) {
          return response.data;
        });
      },

      getTableData: function(tableIdentifier, filterConfig, includeMetadata) {
        var url = ApiPath.table.getDataUrl(tableIdentifier);
        var requestBody = DataFilter.getFilterRequestBody(filterConfig);

        if (includeMetadata) {
          return $q
            .all([service.getTableMetadata(tableIdentifier), queuedHttp.post(url, requestBody)])
            .then(responses => ({ ...responses[1].data, ...{ metaData: responses[0] } }));
        }
        return queuedHttp.post(url, requestBody).then(response => response.data);
      },

      getFilteredRecordCount: function(tableIdentifier, filterConfig) {
        var url = ApiPath.table.getRecordCountUrl(tableIdentifier);
        var requestBody = DataFilter.getFilterRequestBody(filterConfig);
        return queuedHttp.post(url, requestBody).then(response => response.data);
      },

      getCommentCounts: function() {
        var url = AppConfig.coneService.commentCountProjectControlControlTestInterpretationsPath({
          project_id: AppConfig.project_id,
          control_id: AppConfig.control_id,
          control_test_id: AppConfig.control_test_id,
        });

        return backendApi.post(url).then(response => {
          var data = response.data;
          return data;
        });
      },

      fieldValuesRequested: function(tableIdentifier, columnName, fieldType, search, timezoneOffset, position) {
        let url = ApiPath.table.getColumnValuesUrl(tableIdentifier);
        let params = {
          column_name: columnName,
          position,
          limit: 200,
        };

        if (search) {
          url = ApiPath.table.getColumnSearchUrl(tableIdentifier);
          params.search_text = search;
          if (fieldType === "datetime") params.timezone_offset = timezoneOffset;
        } else {
          url = ApiPath.table.getColumnValuesUrl(tableIdentifier);
        }
        return queuedHttp.get(url, { params }).then(response => response.data);
      },

      tableRequested: function(tableId) {
        var controlTestId = tableId;
        var controlId = AppConfig.control_id;
        var projectId = AppConfig.project_id;
        var url = ApiPath.metric.getTableUrl(projectId, controlId, controlTestId);
        return queuedHttp.get(url).then(function(response) {
          var controlTestId = response.data.id;
          stripData(response.data);
          return TableAdapter.deserializeTable(controlTestId, response.data);
        });
      },

      tableRequestedForMetric: function(tableId) {
        var url = ApiPath.metric.getTableForMetricUrl(tableId);
        return queuedHttp.get(url).then(function(response) {
          var controlTestId = response.data.id;
          return TableAdapter.deserializeTable(controlTestId, response.data);
        });
      },

      recordsetRequested: function(table, filterConfig) {
        return service.getTableData(table.id(), filterConfig.toJson()).then(function(data) {
          return RecordsetAdapter.deserializeRecordset(table, filterConfig, data.result);
        });
      },

      deleteField: function(fieldName) {
        const attributeBaseUrl = ({ projectId, controlId, controlTestId }) =>
          `/projects/${projectId}/controls/${controlId}/control_tests/${controlTestId}/exception_attributes`;

        const ids = {
          projectId: AppConfig.project_id,
          controlId: AppConfig.control_id,
          controlTestId: AppConfig.control_test_id,
        };

        const url = `${attributeBaseUrl(ids)}?field_name=${encodeURIComponent(fieldName)}`;
        return queuedHttp.delete(url);
      },
    };
    return service;
  });
