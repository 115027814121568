import React, { Component } from "react";
import PropTypes from "prop-types";
import tokens from "acl-ui/components/helpers/tokens.json";
import Divider from "acl-ui/components/Multiselect/Divider/Divider";
import OutsideClickWrapper from "acl-ui/components/OutsideClickWrapper";
import Icon from "acl-ui/components/Icon";
import RawButton from "acl-ui/components/RawButton";
import TriggerSelect from "./triggerSelect/triggerSelect";
import i18n from "@viz-ui/i18n/i18n";
import "./fieldFormatMultiselect.scss";

const itemPropType = {
  content: PropTypes.object,
  id: PropTypes.number,
  isSelected: PropTypes.bool,
  title: PropTypes.string,
};

const sectionPropType = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(itemPropType)),
};

export default class FieldFormatMultiselect extends Component {
  static propTypes = {
    id: PropTypes.string,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    sections: PropTypes.arrayOf(PropTypes.shape(sectionPropType)),
    value: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  setTriggerRef = node => {
    this.$triggerSelect = node;
  };

  minimizeSelect() {
    this.setState({ isExpanded: false });
  }

  maximizeSelect = () => {
    this.setState({ isExpanded: true });
  };

  toggleSelect = () => {
    if (this.state.isExpanded) {
      this.minimizeSelect();
    } else {
      this.maximizeSelect();
    }
  };

  handleOutsideClick = () => {
    this.minimizeSelect();
  };

  handleItemClick = format => {
    this.props.onChange(format);
  };

  renderItemsAndDividers() {
    const formatSections = this.props.sections;

    const html = formatSections.reduce((sections, section, i) => {
      const items = section.items.map((item, index) => {
        const { example, format, label, selected, isDisabled } = item;
        return (
          <div className="format-select__item" role="listitem" key={"filter-format-" + index}>
            <RawButton
              className="format-select__button"
              disabled={isDisabled}
              onClick={() => {
                this.handleItemClick(format);
              }}
            >
              <span className="format-select__item-row" aria-checked={selected} role="menuitemcheckbox">
                <Icon type="check" size={12} color={tokens.textColorIcon} />
                <span className="format-select__example">{example}</span>
                <span className="format-select__label">{label}</span>
              </span>
            </RawButton>
          </div>
        );
      });

      if (items.length > 0) {
        if (section.heading) {
          sections.push(<Divider key={"divider-" + i} text={section.heading} />);
        }
        sections.push(
          <div key={"section-" + i} className="format-select__list">
            {items}
          </div>
        );
      }
      return sections;
    }, []);

    return html;
  }

  renderBody() {
    if (this.state.isExpanded) {
      return (
        <div className="aclui-multiselect__body">
          <div className="aclui-multiselect__scrolling-area" role="listbox">
            {this.renderItemsAndDividers()}
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <OutsideClickWrapper className="outside-click-wrapper" onOutsideClick={this.handleOutsideClick}>
        <div id={this.props.id} className="aclui-multiselect aclui-multiselect--field-format">
          <TriggerSelect
            isDisabled={this.props.isDisabled}
            isExpanded={this.state.isExpanded}
            onClick={this.toggleSelect}
            statusTitle={i18n.t("_GlobalFilter.FilterFormat.Placeholder_")}
            triggerRef={this.setTriggerRef}
          />
          {this.renderBody()}
        </div>
      </OutsideClickWrapper>
    );
  }
}
