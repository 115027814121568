import ColumnConfig from "../tableConfig/columnConfig";
import FilterConfig from "../filter/filterConfig";
import Model from "../model";

class Interpretation extends Model {
  static modelType = "Interpretation";

  constructor(data) {
    super({ fieldFormatMap: new Map(), ...data });
  }

  tableId(...args) {
    return this.accessor("tableId", args, "string");
  }

  columnConfigs(...args) {
    return this.accessor("columnConfigs", args, [ColumnConfig]);
  }

  fieldFormatMap(...args) {
    return this.accessor("fieldFormatMap", args, Map);
  }

  fieldFormatByFieldName(fieldName, fieldFormat) {
    var formats = this._data.fieldFormatMap;
    if (arguments.length < 2) {
      if (formats[fieldName]) {
        return formats[fieldName].clone();
      }
      return null;
    }

    formats[fieldName] = fieldFormat.clone();
    return this;
  }

  filterConfig(...args) {
    return this.accessor("filterConfig", args, FilterConfig);
  }
}
export default Interpretation;
