import FilterField from "@viz-ui/models/storyboardFilter/filterField";

const FilterFieldService = {
  findField: (filterFieldModel, tableModels) => {
    if (!filterFieldModel || !tableModels) return null;
    const tableId = filterFieldModel.tableId();
    const tableModel = tableModels.find(table => table.id() === tableId);

    if (tableModel) {
      const fieldModel = tableModel.fieldByName(filterFieldModel.name());
      return fieldModel || null;
    }
    return null;
  },

  findFields: (filterFieldModels, tableModels) => {
    if (!filterFieldModels || !tableModels) return [];

    return filterFieldModels
      .map(filterFieldModel => FilterFieldService.findField(filterFieldModel, tableModels))
      .filter(fieldModel => fieldModel !== null);
  },

  convertFields: fieldModels =>
    fieldModels.map(fieldModel => new FilterField().name(fieldModel.name()).tableId(fieldModel.tableId())),
};

export default FilterFieldService;
