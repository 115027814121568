import i18n from "@viz-ui/i18n/i18n";
import storyboardFilterConditions from "@viz-ui/models/storyboardFilter/storyboardFilterConditions";

const operatorDefs = () => [
  { operator: ">", name: i18n.t("_Conditional.GreaterThan.Label_"), argumentCount: 1 },
  { operator: "<", name: i18n.t("_Conditional.LessThan.Label_"), argumentCount: 1 },
  {
    operator: ">=",
    name: i18n.t("_Conditional.GreaterThanAndEqual.Label_"),
    argumentCount: 1,
  },
  {
    operator: "<=",
    name: i18n.t("_Conditional.LessThanAndEqual.Label_"),
    argumentCount: 1,
  },
  { operator: "=", name: i18n.t("_Conditional.Equals.Label_"), argumentCount: 1 },
  { operator: "!=", name: i18n.t("_Conditional.NotEqual.Label_"), argumentCount: 1 },
  { operator: "between", name: i18n.t("_Conditional.Between.Label_"), argumentCount: 2 },
  { operator: "^=", name: i18n.t("_Conditional.BeginsWith.Label_"), argumentCount: 1 },
  { operator: "contain", name: i18n.t("_Conditional.Contains.Label_"), argumentCount: 1 },
  {
    operator: "!contain",
    name: i18n.t("_Conditional.NotContain.Label_"),
    argumentCount: 1,
  },
  { operator: "is_blank", name: i18n.t("_Conditional.IsBlank.Label_"), argumentCount: 0 },
  {
    operator: "is_not_blank",
    name: i18n.t("_Conditional.IsNotBlank.Label_"),
    argumentCount: 0,
  },
  {
    operator: "relative",
    name: i18n.t("_Conditional.Relative.Label_"),
    argumentCount: 3,
  },
];

const mapping = {
  character: ["^=", "contain", "!contain", "is_blank", "is_not_blank"],
  numeric: [">", "<", ">=", "<=", "=", "!=", "between", "is_blank", "is_not_blank"],
  date: [">", "<", ">=", "<=", "=", "!=", "between", "is_blank", "is_not_blank", "relative"],
  time: [">", "<", ">=", "<=", "=", "!=", "between", "is_blank", "is_not_blank"],
  datetime: [">", "<", ">=", "<=", "=", "!=", "between", "is_blank", "is_not_blank", "relative"],
  logical: ["="],
  file: ["is_blank", "is_not_blank"],
  digital_signature: ["is_blank", "is_not_blank"],
};

const isBlankFilterOperators = ["is_blank", "is_not_blank"];

const ConditionalFilterService = {
  getConditionalOperators: (type, flipHasBlankFilter = true, flipRelativeFilters = true) => {
    if (type && !mapping[type]) {
      throw new Error(`Unknown type. type=${type}`);
    }

    const operatorMap = new Map();
    operatorDefs().forEach(item => {
      const { operator, ...operatorProps } = item;
      operatorMap.set(operator, { ...operatorProps });
    });

    if (type === undefined) return operatorMap;

    const filteredOperatorMap = new Map();
    mapping[type]
      .filter(operator => flipHasBlankFilter || !isBlankFilterOperator(operator))
      .filter(operator => flipRelativeFilters || !isRelativeDateOperator(operator))
      .forEach(item => {
        filteredOperatorMap.set(item, operatorMap.get(item));
      });

    return filteredOperatorMap;
  },

  getSingleOperandOperators: () =>
    operatorDefs()
      .filter(item => item.argumentCount === 1)
      .reduce((operatorArray, item) => operatorArray.concat(item.operator), []),

  getDoubleOperandOperators: () =>
    operatorDefs()
      .filter(item => item.argumentCount === 2)
      .reduce((operatorArray, item) => operatorArray.concat(item.operator), []),

  getTripleOperandOperators: () =>
    operatorDefs()
      .filter(item => item.argumentCount === 3)
      .reduce((operatorArray, item) => operatorArray.concat(item.operator), []),

  getOperatorName: operator => {
    const operatorDef = operatorDefs().find(item => item.operator === operator);
    return operatorDef ? operatorDef.name : "";
  },
};

function isBlankFilterOperator(operator) {
  return isBlankFilterOperators.includes(operator);
}

function isRelativeDateOperator(operator) {
  return operator === storyboardFilterConditions.RELATIVE;
}

export default ConditionalFilterService;
