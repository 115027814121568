const statisticsDataConfig = {
  bindings: {
    columnDefs: "<",
    dataConfig: "<",
    onDataConfigChange: "&",
  },
  restrict: "E",
  templateUrl: "visualizer/js/modules/visualization/statistics/statisticsDataConfig.tpl.html",
  controllerAs: "statisticsConfig",
  controller: function StatisticsDataConfig() {
    const statisticsConfig = this;

    statisticsConfig.viewModel = defaultViewModel();

    statisticsConfig.updateFields = selectedFields => {
      statisticsConfig.viewModel.chartCategory = selectedFields;
      onDataConfigChange();
    };

    statisticsConfig.$onChanges = changeObj => {
      if (
        (changeObj.columnDefs || changeObj.dataConfig) &&
        statisticsConfig.columnDefs &&
        statisticsConfig.dataConfig
      ) {
        statisticsConfig.viewModel = dataConfigToViewModel(statisticsConfig.dataConfig);
      }
    };

    function dataConfigToViewModel(dataConfig) {
      const chartCategory = dataConfig.chartCategory ? dataConfig.chartCategory.map(getFieldObj) : [];
      const viewModel = defaultViewModel();
      viewModel.chartCategory = chartCategory;
      return viewModel;
    }

    function getFieldObj(fieldName) {
      return (
        statisticsConfig.columnDefs.chartCategory.find(fieldObj => fieldObj.fieldName === fieldName) || { fieldName }
      );
    }

    function viewModelToDataConfig(viewModel) {
      return {
        chartCategory: viewModel.chartCategory.map(field => field.fieldName),
      };
    }

    function onDataConfigChange() {
      statisticsConfig.onDataConfigChange({ dataConfig: viewModelToDataConfig(statisticsConfig.viewModel) });
    }

    function defaultViewModel() {
      return {
        chartCategory: [],
      };
    }
  },
};

export default statisticsDataConfig;
