import React from "react";
import i18n from "@viz-ui/i18n/i18n";
import PropTypes from "prop-types";
import GlobalValueFormatter from "../../../services/formatters/globalValueFormatter";

class TemporalStatistics extends React.Component {
  static propTypes = {
    statistic: PropTypes.objectOf(PropTypes.any),
    config: PropTypes.objectOf(PropTypes.any),
    index: PropTypes.number,
    setDisplayForPopup: PropTypes.func,
    showPopup: PropTypes.func,
  };

  static defaultProps = {
    statistic: {},
    config: {},
    index: 0,
    setDisplayForPopup: () => {},
    showPopup: () => {},
  };

  replaceNextLabel = value => i18n.t("_Chart.StatisticsViz.Next.Label_").replace("$0", value);

  render() {
    return (
      <div id={`statistic-${this.props.statistic.fieldName}-${this.props.index}`} className="datetime">
        <div className="statistic_info">
          <h2 className="statistic_title">{this.props.statistic.displayName}</h2>
          {this.props.statistic.highest.length > 0 && (
            <div className="highest">
              <p className="title">
                <a
                  id={`statistic-${this.props.index}-highest-link`}
                  role="presentation"
                  onClick={() => this.props.setDisplayForPopup(this.props.statistic.fieldName, "highest", true)}
                >
                  {`${i18n.t("_Chart.StatisticsViz.MostRecent.Label_")} `}
                  {GlobalValueFormatter.formatValue(
                    this.props.statistic.highest[0].value,
                    this.props.config.interpretationId,
                    this.props.config.fieldNames[this.props.index]
                  )}
                  <i className="icon-chevron-up title-icon" />
                </a>
              </p>
              {this.props.showPopup(this.props.statistic, "highest") && (
                <div className="popup">
                  <h3>{this.replaceNextLabel(this.props.statistic.highest.length - 1)}</h3>
                  <ul>
                    {this.props.statistic.highest.map(
                      (highestValues, index) =>
                        index !== 0 && (
                          <li>
                            <span id={`highest-next-${index + 1}`} className="value">
                              {GlobalValueFormatter.formatValue(
                                highestValues.value,
                                this.props.config.interpretationId,
                                this.props.config.fieldNames[this.props.index]
                              )}
                            </span>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              )}
            </div>
          )}
          {this.props.statistic.blanks <= 0 && (
            <>
              <div className="lowest">
                <p className="title">
                  <a
                    id={`statistic-${this.props.index}-lowest-link`}
                    role="presentation"
                    onClick={() => this.props.setDisplayForPopup(this.props.statistic.fieldName, "lowest", true)}
                  >
                    {`${i18n.t("_Chart.StatisticsViz.Earliest.Label_")} `}
                    {GlobalValueFormatter.formatValue(
                      this.props.statistic.lowest[0].value,
                      this.props.config.interpretationId,
                      this.props.config.fieldNames[this.props.index]
                    )}
                    <i className="icon-chevron-down title-icon" />
                  </a>
                </p>

                {this.props.showPopup(this.props.statistic, "lowest") && (
                  <div className="popup">
                    <h3>{this.replaceNextLabel(this.props.statistic.lowest.length - 1)}</h3>
                    <ul>
                      {this.props.statistic.lowest.map(
                        (lowestValues, index) =>
                          index !== 0 && (
                            <li>
                              <span id={`highest-next-${index + 1}`} className="value">
                                {GlobalValueFormatter.formatValue(
                                  lowestValues.value,
                                  this.props.config.interpretationId,
                                  this.props.config.fieldNames[this.props.index]
                                )}
                              </span>
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                )}
              </div>
              <div className="totals">
                <div className="range">
                  <p>
                    <strong className="title">{i18n.t("_Chart.StatisticsViz.RangeInDays.Label_")}</strong>
                    <span className="value">
                      {` ${Number(this.props.statistic.range)} ~ ${this.props.statistic.formattedRange}`}
                    </span>
                  </p>
                </div>
                <div className="std-deviation">
                  <p>
                    <strong className="title">{`${i18n.t("_Chart.StatisticsViz.StdDeviationInDays.Label_")} `}</strong>
                    <span className="value">{Number(this.props.statistic.stdDeviation)}</span>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="statistic_stats">
          <div className="count stat">
            <h3 className="value">{this.props.statistic.count}</h3>
            <p className="title">{i18n.t("_Chart.StatisticsViz.Count.Label_")}</p>
          </div>
          <div className="blanks stat">
            <h3 className="value">{this.props.statistic.blanks}</h3>
            <p className="title">{i18n.t("_Chart.StatisticsViz.Blanks.Label_")}</p>
          </div>
        </div>
        <div className="clear" />
      </div>
    );
  }
}

export default TemporalStatistics;
