import aclCriteriaFilter from "./criteriaFilter.component";

angular
  .module("acl.visualizer.criteriaFilter", [
    "acl.common.asyncCallManager",
    "acl.visualizer.charts",
    "acl.visualizer.checkboxFilter",
    "acl.visualizer.fieldValue",
  ])
  .component("aclCriteriaFilter", aclCriteriaFilter);
