import React from "react";
import PropTypes from "prop-types";
import tokens from "acl-ui/components/helpers/tokens.json";
import Icon from "acl-ui/components/Icon";
import i18n from "@viz-ui/i18n/i18n";

import InterpretationSummaryPopover from "../InterpretationSummaryPopover/InterpretationSummaryPopover";
import "./breadcrumb.scss";

class Breadcrumb extends React.Component {
  static propTypes = {
    org: PropTypes.shape({ name: PropTypes.string.isRequired, url: PropTypes.string.isRequired }).isRequired,

    collection: PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      isArchived: PropTypes.bool,
    }).isRequired,

    currentInstance: PropTypes.shape({ name: PropTypes.string.isRequired, url: PropTypes.string }).isRequired,

    interpretationSummaryProps: PropTypes.shape({
      summary: PropTypes.string.isRequired,
      canUpdateSummary: PropTypes.bool.isRequired,
      onSaveSummary: PropTypes.func,
    }),
  };

  static defaultProps = {
    interpretationSummaryProps: null,
  };

  renderCurrentInstance() {
    const { currentInstance } = this.props;
    if (currentInstance.url) {
      return <a href={currentInstance.url}>{currentInstance.name}</a>;
    }
    return <span>{currentInstance.name}</span>;
  }

  renderInterpretationSummary() {
    if (this.props.interpretationSummaryProps) {
      return (
        <li className="breadcrumb__summary-popover">
          <InterpretationSummaryPopover {...this.props.interpretationSummaryProps} />
        </li>
      );
    }
  }

  renderArchiveIcon() {
    const { collection } = this.props;
    if (collection.isArchived) return <Icon type="archive" color={tokens.textColor.link} />;
  }

  render() {
    const { org, collection } = this.props;

    return (
      <nav aria-label={i18n.t("_Breadcrumbs_")} className="breadcrumb__nav-container">
        <ul className="breadcrumb__links">
          <li>
            <a href={org.url}>
              <Icon ariaText={i18n.t("_Breadcrumbs.HomePage_")} type="arrow-left" color={tokens.textColor.Icon} />
            </a>
          </li>
          <li className="breadcrumb__organization">
            <a href={org.url}>{org.name}</a>
          </li>
          <li className="breadcrumb__collection">
            <a href={collection.url}>
              {this.renderArchiveIcon()} {collection.name}
            </a>
          </li>
          <li className="current">{this.renderCurrentInstance()}</li>
          {this.renderInterpretationSummary()}
        </ul>
      </nav>
    );
  }
}

export default Breadcrumb;
