import Model from "../model";
import Field from "../field/field";
import TableCapability from "./tableCapability";

class Table extends Model {
  static modelType = "Table";

  constructor(data) {
    super({ fields: [], capabilities: new TableCapability(), ...data });
  }

  id() {
    return this._data.id;
  }

  name(...args) {
    return this.accessor("name", args, "string");
  }

  recordCount(...args) {
    return this.accessor("recordCount", args, "number");
  }

  fields(...args) {
    return this.accessor("fields", args, [Field]);
  }

  systemFields(...args) {
    return this.accessor("systemFields", args, [Field]);
  }

  capabilities(...args) {
    return this.accessor("capabilities", args, TableCapability);
  }

  setField(field) {
    const index = fieldIndexByName(this._data.fields, field.name());
    if (index >= 0) {
      this._data.fields[index] = field;
    } else {
      this._data.fields.push(field);
    }
    return this;
  }

  typeByFieldName(fieldName) {
    const { fields } = this._data;
    let i;

    for (i = 0; i < fields.length; i++) {
      if (fields[i].name() === fieldName) {
        return fields[i].type();
      }
    }
    return null;
  }

  typeMap() {
    const result = {};
    this._data.fields.forEach(field => {
      result[field.name()] = field.type();
    });
    return result;
  }

  fieldByName(name) {
    const index = fieldIndexByName(this._data.fields, name);
    return this._data.fields[index];
  }

  systemFieldByName(name) {
    const index = fieldIndexByName(this._data.systemFields, name);
    return this._data.systemFields[index];
  }

  fieldsByType(type) {
    return this._data.fields.filter(d => d.type() === type);
  }

  fieldsByTypes(types) {
    return this._data.fields.filter(d => types.includes(d.type()));
  }

  clone() {
    return new Table(this._data);
  }
}

function fieldIndexByName(fields, name) {
  return fields.findIndex(field => field.name() === name);
}

export default Table;
