import PropTypes from "prop-types";
import React from "react";
import services from "../../../services/glue/appGlue";

class StoryboardShareNav extends React.Component {
  constructor(props) {
    super(props);

    this.handleNavClick = this.handleNavClick.bind(this);

    this.state = this.getDefaultState();
  }

  handleNavClick(nav) {
    return e => {
      this.setState({ selectedNav: nav });
      this.props.onNavSelected(nav);
    };
  }

  getDefaultState() {
    return {
      selectedNav: this.props.paneMode,
    };
  }
  getMemberNav() {
    if (!this.props.showMemberNav) return "";
    return (
      <a className={this.getNavCssClass("users")} data-qa-anchor="users-tab" onClick={this.handleNavClick("users")}>
        {services.Localize.getLocalizedString("_Storyboards.SharePanel.MembersNav_")}
      </a>
    );
  }

  getLinkNav() {
    if (!this.props.showLinkNav) return "";
    return (
      <a className={this.getNavCssClass("link")} data-qa-anchor="link-tab" onClick={this.handleNavClick("link")}>
        {services.Localize.getLocalizedString("_Storyboards.SharePanel.LinkNav_")}
      </a>
    );
  }

  getExportNav() {
    if (!this.props.exportStoryboardFlipper) return "";
    return (
      <a className={this.getNavCssClass("export")} data-qa-anchor="export-tab" onClick={this.handleNavClick("export")}>
        {services.Localize.getLocalizedString("_Storyboards.SharePanel.ExportNav_")}
      </a>
    );
  }

  getNavCssClass(nav) {
    let navCssClass = "share-storyboard-nav__link";
    if (nav === this.state.selectedNav) {
      navCssClass = navCssClass + " selected";
    }
    return navCssClass;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.paneMode !== this.state.paneMode) {
      this.setState({ selectedNav: nextProps.paneMode });
    }
  }

  render() {
    return (
      <div className="share-storyboard-nav">
        {this.getMemberNav()}
        {this.getLinkNav()}
        {this.getExportNav()}
      </div>
    );
  }
}

StoryboardShareNav.propTypes = {
  paneMode: PropTypes.string,
  onNavSelected: PropTypes.func,
  showLinkNav: PropTypes.bool,
  showMemberNav: PropTypes.bool,
};

StoryboardShareNav.defaultProps = {
  showLinkNav: true,
  showMemberNav: true,
  onNavSelected: nav => {},
};

window.StoryboardShareNav = StoryboardShareNav;
