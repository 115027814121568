import Field from "../field/field";
import Table from "./table";
import TableCapability from "./tableCapability";

const TableAdapter = {
  deserializeTable: deserializeTable,
  deserializeField: deserializeField,
};

function deserializeTable(tableId, data) {
  const { capabilities, name, recordCount, fields, systemFields } = data;
  const tableCapability = deserializeTableCapabilities(capabilities);
  return new Table({
    id: tableId,
  })
    .name(name)
    .recordCount(recordCount)
    .fields(deserializeFields(fields, tableId))
    .systemFields(deserializeFields(systemFields, tableId))
    .capabilities(tableCapability);
}

function deserializeFields(fields, tableId) {
  if (fields && fields.length) {
    return fields.map(field => TableAdapter.deserializeField(field, tableId));
  }
  return [];
}

function deserializeField(field, tableId) {
  if (field) {
    return new Field()
      .name(field.name)
      .displayName(field.columnTitle)
      .type(field.type)
      .isPrimaryKey(field.isPrimaryKey)
      .tableId(tableId);
  }
  return undefined;
}

function deserializeTableCapabilities(capabilities) {
  return capabilities
    ? new TableCapability({
        canManageTriggers: capabilities.manage_triggers,
        canDeleteColumns: capabilities.delete_columns,
        canRemediateRecords: capabilities.remediation,
      })
    : new TableCapability();
}

export default TableAdapter;
