export default  ConditionalOperators => {
  "ngInject";

  class ConditionValidator {
    isValid(conditionObj, fieldType) {
      return this.hasValidOperator(conditionObj, fieldType) && this.hasValidValues(conditionObj, fieldType);
    }

    hasValidOperator(conditionObj, fieldType) {
      return ConditionalOperators.isValid(conditionObj.operator, fieldType);
    }

    hasValidValues(conditionObj, fieldType) {
      const operatorName = conditionObj.operator;

      if (!operatorName) {
        return true;
      }

      const argumentCount = ConditionalOperators.getArgumentCount(operatorName);

      if (argumentCount === 0) {
        return true;
      }

      if (argumentCount === 1) {
        return (
          conditionObj.values && conditionObj.values.length === 1 && isValidValue(conditionObj.values[0], fieldType)
        );
      }

      if (argumentCount === 2) {
        return (
          conditionObj.values &&
          conditionObj.values.length === 2 &&
          isValidValue(conditionObj.values[0], fieldType) &&
          isValidValue(conditionObj.values[1], fieldType)
        );
      }

      return false;
    }

    hasValidValue(conditionObj, fieldType) {
      return conditionObj.values && isValidValue(conditionObj.values[0], fieldType);
    }

    hasValidFromValue(conditionObj, fieldType) {
      return conditionObj.values && isValidValue(conditionObj.values[0], fieldType);
    }

    hasValidToValue(conditionObj, fieldType) {
      return conditionObj.values && isValidValue(conditionObj.values[1], fieldType);
    }
  }

  function isValidValue(value, fieldType) {
    switch (fieldType) {
      case "character":
        return isValidCharacter(value);
      case "date":
        return isValidDate(value);
      case "datetime":
        return isValidDatetime(value);
      case "time":
        return isValidTime(value);
      case "logical":
        return isValidLogical(value);
      case "numeric":
        return isValidNumeric(value);
      default:
        throw new Error("Unexpected field type. type=" + fieldType);
    }
  }

  function isValidDate(value) {
    return /\d\d\d\d-\d\d-\d\d/g.test(value);
  }

  function isValidDatetime(value) {
    return /\d\d\d\d-\d\d-\d\d \d\d:\d\d(:\d\d)?/g.test(value);
  }

  function isValidTime(value) {
    return /\d\d:\d\d(:\d\d)?/g.test(value);
  }

  function isValidLogical(value) {
    let lower = value.toLowerCase();
    return lower === "t" || lower === "f";
  }

  function isValidNumeric(value) {
    return isDefined(value) && !isNaN(value);
  }

  function isValidCharacter(value) {
    return isDefined(value);
  }

  function isDefined(value) {
    return typeof value !== "undefined" && value !== null;
  }

  return new ConditionValidator();
};
