import FieldFormat from "@viz-ui/models/field/fieldFormat";
import Observer from "../observer/observer";

const fieldFormatMap = new Map();
const fieldTypeMap = new Map();
const fieldNameToObserver = new Map();

class GlobalFieldFormatMap {
  clear() {
    fieldFormatMap.clear();
    fieldTypeMap.clear();
    fieldNameToObserver.clear();
    return this;
  }

  getFieldFormat(fieldFormatIdentifier, fieldName) {
    return fieldFormatMap.get(getKey(fieldFormatIdentifier, fieldName));
  }

  setFieldFormat(fieldFormatIdentifier, fieldName, fieldFormat) {
    this._setFieldFormat(fieldFormatIdentifier, fieldName, fieldFormat);
    this._broadcastField(fieldFormatIdentifier, fieldName);
    this._broadcastIdentifier(fieldFormatIdentifier);
    return this;
  }

  setFieldFormats(fieldFormatIdentifier, fieldFormatMapArg) {
    fieldFormatMapArg.forEach((fieldFormat, fieldName) => {
      this._setFieldFormat(fieldFormatIdentifier, fieldName, fieldFormat);
      this._broadcastField(fieldFormatIdentifier, fieldName);
    });
    this._broadcastIdentifier(fieldFormatIdentifier);
    return this;
  }

  _setFieldFormat(fieldFormatIdentifier, fieldName, fieldFormat) {
    if (!fieldFormat.isInstanceOf(FieldFormat)) {
      throw Error("GlobalFieldFormatMap.setFieldFormat expected third argument to be an instance of FieldFormat");
    }
    const key = getKey(fieldFormatIdentifier, fieldName);
    fieldFormatMap.set(key, fieldFormat);
  }

  getFieldType(fieldFormatIdentifier, fieldName) {
    return fieldTypeMap.get(getKey(fieldFormatIdentifier, fieldName));
  }

  getFieldTypeMap(fieldFormatIdentifier) {
    return Array.from(fieldTypeMap.entries())
      .map(([key, value]) => ({ key: JSON.parse(key), value }))
      .filter(({ key: [identifier] }) => identifier === fieldFormatIdentifier)
      .reduce((accumulator, { key: [, fieldName], value }) => {
        accumulator[fieldName] = value;
        return accumulator;
      }, {});
  }

  setFieldType(fieldFormatIdentifier, fieldName, fieldType) {
    this._setFieldType(fieldFormatIdentifier, fieldName, fieldType);
    this._broadcastField(fieldFormatIdentifier, fieldName);
    this._broadcastIdentifier(fieldFormatIdentifier);
    return this;
  }

  setFieldTypes(fieldFormatIdentifier, fieldModels) {
    fieldModels.forEach(fieldModel => {
      this._setFieldType(fieldFormatIdentifier, fieldModel.name(), fieldModel.type());
      this._broadcastField(fieldFormatIdentifier, fieldModel.name());
    });
    this._broadcastIdentifier(fieldFormatIdentifier);
    return this;
  }

  _setFieldType(fieldFormatIdentifier, fieldName, fieldType) {
    const fieldKey = getKey(fieldFormatIdentifier, fieldName);
    fieldTypeMap.set(fieldKey, fieldType);
  }

  _broadcastField(fieldFormatIdentifier, fieldName) {
    const key = getKey(fieldFormatIdentifier, fieldName);
    this._broadcast(key);
  }

  _broadcastIdentifier(fieldFormatIdentifier) {
    const key = getIdentifierKey(fieldFormatIdentifier);
    this._broadcast(key);
  }

  _broadcast(key) {
    if (fieldNameToObserver.has(key)) {
      const observer = fieldNameToObserver.get(key);
      observer.broadcast();
    }
  }

  subscribeToFieldObserver(fieldFormatIdentifier, fieldName, callback) {
    const fieldKey = getKey(fieldFormatIdentifier, fieldName);
    return this._subscribeToObserver(fieldKey, callback);
  }

  subscribeToIdentifierObserver(fieldFormatIdentifier, callback) {
    const identifierKey = getIdentifierKey(fieldFormatIdentifier);
    return this._subscribeToObserver(identifierKey, callback);
  }

  _subscribeToObserver(fieldKey, callback) {
    let observer;
    if (fieldNameToObserver.has(fieldKey)) {
      observer = fieldNameToObserver.get(fieldKey);
    } else {
      observer = new Observer();
      fieldNameToObserver.set(fieldKey, observer);
    }
    return observer.subscribe(callback);
  }
}

function getIdentifierKey(fieldFormatIdentifier) {
  const isIdNotSet = typeof fieldFormatIdentifier === "undefined" || fieldFormatIdentifier === "";
  return isIdNotSet ? "new" : fieldFormatIdentifier;
}

function getKey(fieldFormatIdentifier, fieldName) {
  const identifierKey = getIdentifierKey(fieldFormatIdentifier);
  return JSON.stringify([identifierKey, fieldName]);
}

export default new GlobalFieldFormatMap();
