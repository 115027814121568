import Model from "../model";
import AggregatorArgs from "./aggregatorArgs";
import Threshold from "../filter/threshold";

class MetricConfig extends Model {
  static modelType = "MetricConfig";

  constructor(data) {
    super({ aggregatorArgs: new AggregatorArgs(), thresholds: [new Threshold()], ...data });
  }

  func(...args) {
    return this.accessor("func", args, "string");
  }

  aggregatorArgs(...args) {
    return this.accessor("aggregatorArgs", args, AggregatorArgs);
  }

  interval(...args) {
    return this.accessor("interval", args, "string");
  }

  fieldName(...args) {
    return this.accessor("fieldName", args, "string");
  }

  timeFieldName(...args) {
    return this.accessor("timeFieldName", args, "string");
  }

  baseColor(...args) {
    return this.accessor("baseColor", args, "string");
  }

  thresholds(...args) {
    return this.accessor("thresholds", args, [Threshold]);
  }

  viewThresholds(...args) {
    return this.accessor("viewThresholds", args, "boolean");
  }

  showSparkline(...args) {
    return this.accessor("showSparkline", args, "boolean");
  }

  type(...args) {
    return this.accessor("type", args, "string");
  }

  clone() {
    return new MetricConfig(this._data);
  }
}
export default MetricConfig;
