import backendApi from "@results/services/apiCall/backendApi";
import TableAdapter from "@viz-ui/models/table/tableAdapter";
import { storyboardUidFinder } from "@viz-ui/services/storyboardUrlDetection/storyboardUrlDetection";
import globalFiltersStore from "../globalFilters/services/GlobalFiltersStore";

angular
  .module("acl.storyboard.interpretation")
  .factory("InterpretationBackend", function($q, AppConfig, FilterConfig, Table, RecordsetAdapter, RecordsetManager) {
    var urlPromiseMap = {};

    return {
      loadInterpretations: function(ids) {
        const url = interpretationListUrl();
        const params = { ids: ids.toString() };
        return loadOnceByUrl(url, params);
      },

      loadTableMetadata: function(interpretationId) {
        let url = interpretationMetaDataUrl(interpretationId);
        return loadOnceByUrl(url).then(response => {
          return TableAdapter.deserializeTable(response.data.id, response.data);
        });
      },

      loadTableData: async function({ tableId, interpretationId, table, filterConfig, queryScope, nextRecordIndex }) {
        if (AppConfig.features.queryService) {
          return loadTableDataFromQueryService(tableId, table, filterConfig, nextRecordIndex, queryScope);
        }
        return loadTableData(tableId, interpretationId, table, filterConfig, nextRecordIndex);
      },
    };

    function loadTableDataFromQueryService(tableId, table, filterConfig, nextRecordIndex, queryScope) {
      const globalFilters = globalFiltersStore.forQuery(tableId);
      const tableModel = table || new Table({ id: tableId });
      const filterConfigModel = FilterConfig.fromJson(filterConfig);
      if (nextRecordIndex) filterConfigModel.startRecord(nextRecordIndex);
      return RecordsetManager.loadRecordset(tableModel, filterConfigModel, globalFilters, queryScope);
    }

    function loadTableData(tableId, interpretationId, table, filterConfig, nextRecordIndex) {
      const globalFilters = globalFiltersStore.forQuery(tableId);
      const filterConfigModel = FilterConfig.fromJson(filterConfig);
      const metadataUrl = interpretationMetaDataUrl(interpretationId);
      const tableDataurl = interpretationTableDataUrl(interpretationId);
      const data = { globalFilters };

      if (table) {
        return loadOnceByUrl(tableDataurl, { start: nextRecordIndex }, "POST", data).then(response => {
          return RecordsetAdapter.deserializeRecordset(table, filterConfigModel, response.data.result);
        });
      } else {
        return $q
          .all([loadOnceByUrl(metadataUrl), loadOnceByUrl(tableDataurl, { start: nextRecordIndex }, "POST", data)])
          .then(responses => {
            table = TableAdapter.deserializeTable(responses[0].data.id, responses[0].data);
            return RecordsetAdapter.deserializeRecordset(table, filterConfigModel, responses[1].data.result);
          });
      }
    }

    function loadOnceByUrl(url, params, method = "GET", data = {}) {
      if (urlPromiseMap[url] !== undefined) {
        return urlPromiseMap[url];
      }

      var promise = backendApi.http({
        url,
        method,
        headers: {
          Accept: "application/json",
        },
        params,
        body: data,
      });

      var deletePromise = () => delete urlPromiseMap[url];
      promise.then(deletePromise, deletePromise);

      urlPromiseMap[url] = promise;
      return promise;
    }

    function interpretationListUrl() {
      const isAnonymous = AppConfig.isAnonymous;
      if (!isAnonymous) {
        return AppConfig.coneService.interpretationsListPath() + AppConfig.apiQueryString;
      } else {
        return AppConfig.coneService.publicStoryboardLinkInterpretationsListPath({
          public_storyboard_link_id: storyboardUidFinder(window.location.href),
        });
      }
    }

    function interpretationMetaDataUrl(id) {
      const isAnonymous = AppConfig.isAnonymous;
      if (!isAnonymous) {
        return AppConfig.coneService.shortMetaDataPath({ id }) + AppConfig.apiQueryString;
      } else {
        return AppConfig.coneService.publicStoryboardLinkMetaDataPath({
          public_storyboard_link_id: storyboardUidFinder(window.location.href),
          id,
        });
      }
    }

    function interpretationTableDataUrl(id) {
      const isAnonymous = AppConfig.isAnonymous;
      if (!isAnonymous) {
        return AppConfig.coneService.shortTableDataPath({ id }) + AppConfig.apiQueryString;
      } else {
        return AppConfig.coneService.publicStoryboardLinkTableDataPath({
          public_storyboard_link_id: storyboardUidFinder(window.location.href),
          id,
        });
      }
    }
  });
