angular.module("acl.common.resizeListener").directive("resizeListener", function($interval) {
  const defaultPollInterval = 1000;

  return {
    restrict: "A",
    scope: {
      pollInterval: "<",
      resizeCallback: "&",
    },
    link: function(scope, element) {
      let oldWidth = element.width();
      let oldHeight = element.height();

      let timer = $interval(() => {
        if (oldWidth !== element.width() || oldHeight !== element.height()) {
          oldWidth = element.width();
          oldHeight = element.height();
          scope.resizeCallback();
        }
      }, scope.pollInterval || defaultPollInterval);

      scope.$on("$destroy", function() {
        $interval.cancel(timer);
        timer = undefined;
      });
    },
  };
});
