import React from "react";
import Button from "@paprika/button";
import KeyboardIcon from "@acl-services/wasabicons/lib/Keyboard";
import PropTypes from "prop-types";
import Popover from "@paprika/popover";
import i18n from "@viz-ui/i18n/i18n";

const propTypes = {
  onClick: PropTypes.func,
};

const defaultProps = {
  onClick: () => {},
};

function KeyBoardShortcut(props) {
  const { onClick } = props;
  return (
    <Button.Icon onClick={onClick} kind="flat">
      <Popover align="bottom" maxWidth={150} isDark isEager offset={8} shouldKeepFocus>
        <Popover.Trigger>
          <KeyboardIcon aria-label="Keyboard shortcut" />
        </Popover.Trigger>
        <Popover.Content>
          <Popover.Card>{i18n.t("_Presentation.HelpButton.Tooltip_")}</Popover.Card>
        </Popover.Content>
        <Popover.Tip />
      </Popover>
    </Button.Icon>
  );
}

KeyBoardShortcut.propTypes = propTypes;
KeyBoardShortcut.defaultProps = defaultProps;

export default KeyBoardShortcut;
