import Model from "../model";

class ColumnConfig extends Model {
  static modelType = "ColumnConfig";

  name(...args) {
    return this.accessor("name", args, "string");
  }

  visible(...args) {
    return this.accessor("visible", args, "boolean");
  }

  width(...args) {
    return this.accessor("width", args, "number");
  }

  wordWrap(...args) {
    return this.accessor("wordWrap", args, "boolean");
  }
}

export default ColumnConfig;
