import difference from "lodash/difference";
import find from "lodash/find";
import reject from "lodash/reject";
import some from "lodash/some";
import sortBy from "lodash/sortBy";

import Button from "acl-ui/components/Button";
import PropTypes from "prop-types";
import React from "react";
import services from "../../../services/glue/appGlue";
import Pagination from "@paprika/pagination";
import { StoryboardBackend } from "../../storyboard/storyboard.backend.es6";
import Spinner from "@paprika/spinner";
let timeout = null;
class UserSelector extends React.Component {
  constructor(props) {
    super(props);

    // function binding
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.state = {
      selectedUsers: props.selectedUsers,
      selectedGroups: props.selectedGroups,
      filterString: "",
      currentPage: 1,
      totalPages: null,
      userData: [],
      workFlowData: [],
      from: null,
      to: null,
      count: null,
      isLoading: false,
      selectedManageUsers: [], //used to store temporary data from selected values of manageUsers,
    };
  }

  filterMatches(...attributes) {
    return some(attributes, attribute => {
      return attribute && attribute.toLowerCase().indexOf(this.state.filterString.toLowerCase()) > -1;
    });
  }
  componentDidMount() {
    if (services.AppConfig.features.enablePaginationOnManageUsers) {
      this.getStoryboardUserData(1, this.state.filterString);
      clearTimeout(timeout);
    }
  }

  async getStoryboardUserData(pageNo, filterString) {
    this.setState({ isLoading: true });
    const { data, meta } = await StoryboardBackend.getStoryboardAssignees(
      services.AppConfig.highbondNavBarProps.appSwitcherProps.initialOrganizationId,
      pageNo,
      filterString
    );
    //modifying the api data to get groups and users as per the requirement in the code
    const workflowGroupData = data.filter(item => item.attributes.assignee_type === "workflow_group");
    const usersDataList = data.filter(item => item.attributes.assignee_type === "user");
    let structuredworkFlowData = [];
    workflowGroupData.map(item => {
      structuredworkFlowData.push({
        id: item.attributes.assignee_id,
        name: item.attributes.title,
        users: item.attributes.users_info,
      });
    });

    let structuredUserData = [];
    usersDataList.map(item => {
      structuredUserData.push({
        id: item.attributes.assignee_id,
        name: item.attributes.title,
        email: item.attributes.users_info,
      });
    });

    this.setState({
      workFlowData: structuredworkFlowData,
      userData: structuredUserData,
      isLoading: false,
      totalPages: meta.page.pages,
      from: meta.page.from,
      to: meta.page.to,
      count: meta.page.count,
    });
  }

  getUserRows() {
    //if flipper turned off, resort to prop data and normal search methods
    if (services.AppConfig.features.enablePaginationOnManageUsers) {
      let users = sortBy(this.state.userData, user => {
        return user.name.toLowerCase();
      });
      return users.map(user => {
        const userRow = (
          <UserSelectorRow
            isSelected={this.isUserSelected(user.id)}
            userId={user.id}
            email={user.email}
            onClick={this.handleRowClick}
            name={user.name}
          >
            <span className="email">({user.email})</span>
          </UserSelectorRow>
        );

        return userRow;
      });
    } else {
      let users = sortBy(this.props.user, user => {
        return user.name.toLowerCase();
      });
      return users.map(user => {
        let userRow;
        if (this.state.filterString === "" || this.filterMatches(user.name, user.email)) {
          userRow = (
            <UserSelectorRow
              isSelected={this.isUserSelected(user.id)}
              userId={user.id}
              onClick={this.handleRowClick}
              email={user.email}
              name={user.name}
            >
              <span className="email">({user.email})</span>
            </UserSelectorRow>
          );
        }
        return userRow;
      });
    }
  }

  getGroupRows() {
    //if flipper turned off, resort to prop data and normal search methods
    if (services.AppConfig.features.enablePaginationOnManageUsers) {
      let groups = sortBy(this.state.workFlowData, group => {
        return group.name.toLowerCase();
      });
      return groups.map(group => {
        let groupRow;
        let usersList = group.users;
        groupRow = (
          <UserSelectorRow
            isSelected={this.isGroupSelected(group.id)}
            groupId={group.id}
            onClick={this.handleRowClick}
            users={group.users}
            name={group.name}
          >
            <span className="user-list">{usersList}</span>
          </UserSelectorRow>
        );

        return groupRow;
      });
    } else {
      let groups = sortBy(this.props.groups, group => {
        return group.name.toLowerCase();
      });
      return groups.map(group => {
        let usersList = this.getGroupUsersList(group.users);
        let usersEmails = group.users
          .map(function(user) {
            return user.email;
          })
          .toString();

        let groupRow;
        if (this.state.filterString === "" || this.filterMatches(group.name, usersList, usersEmails)) {
          groupRow = (
            <UserSelectorRow
              isSelected={this.isGroupSelected(group.id)}
              groupId={group.id}
              onClick={this.handleRowClick}
              users={group.users}
              name={group.name}
            >
              <span className="user-list">{usersList}</span>
            </UserSelectorRow>
          );
        }

        return groupRow;
      });
    }
  }

  getGroupUsersList(users) {
    let usersList = "(";
    users.forEach(function(user, index) {
      if (index > 0) usersList += ", ";
      if (index === users.length - 1) usersList += "and ";
      usersList += user.name;
    });
    usersList += ")";

    return usersList;
  }

  handleConfirm(e) {
    this.clearFilter();

    let usersAdded = difference(this.state.selectedUsers, this.props.selectedUsers).map(userId => {
      return find(this.state.selectedManageUsers, function(user) {
        return user.id === userId;
      });
    });
    let usersRemoved = difference(this.props.selectedUsers, this.state.selectedUsers).map(userId => {
      return find(this.state.selectedManageUsers, function(user) {
        return user.id === userId;
      });
    });
    let groupsAdded = difference(this.state.selectedGroups, this.props.selectedGroups).map(groupId => {
      return find(this.state.selectedManageUsers, function(group) {
        return group.id === groupId;
      });
    });
    let groupsRemoved = difference(this.props.selectedGroups, this.state.selectedGroups).map(groupId => {
      return find(this.state.selectedManageUsers, function(group) {
        return group.id === groupId;
      });
    });
    this.props.onUsersSelected(
      { added: usersAdded, removed: usersRemoved },
      { added: groupsAdded, removed: groupsRemoved }
    );
  }

  handleCancel(e) {
    this.clearFilter();
    this.props.onCancel(e);
  }

  handleRowClick(userId, groupId, userDetails) {
    //use the userdetails coming from row and store it into temporary array
    if (userDetails) {
      this.setState({ selectedManageUsers: this.state.selectedManageUsers.concat([userDetails]) });
    }
    if (userId) {
      let userIndex = this.state.selectedUsers.indexOf(userId);
      if (userIndex > -1) {
        this.setState({
          selectedUsers: reject(this.state.selectedUsers, function(id) {
            return id === userId;
          }),
        });
      } else {
        this.setState({
          selectedUsers: this.state.selectedUsers.concat([userId]),
        });
      }
    } else if (groupId) {
      if (this.state.selectedGroups.indexOf(groupId) > -1) {
        this.setState({
          selectedGroups: reject(this.state.selectedGroups, function(id) {
            return id === groupId;
          }),
        });
      } else {
        this.setState({
          selectedGroups: this.state.selectedGroups.concat([groupId]),
        });
      }
    }
  }

  handleFilterChange(e) {
    //if flipper turned off, resort to prop data and normal search methods
    if (!services.AppConfig.features.enablePaginationOnManageUsers) {
      this.setState({ filterString: e.target.value });
    } else {
      this.setState({ filterString: e.target.value });
      if (timeout) {
        clearTimeout(timeout);
      }
      // Set a new timeout to log the input after a pause of 500 milliseconds
      timeout = setTimeout(() => {
        if (this.state.filterString.length >= 2 || this.state.filterString.length === 0) {
          this.getStoryboardUserData(1, this.state.filterString);
        }
      }, 500);
    }
  }

  clearFilter() {
    this.setState({ filterString: "" });
  }

  isUserSelected(userId) {
    return this.state.selectedUsers.indexOf(userId) > -1;
  }

  isGroupSelected(groupId) {
    return this.state.selectedGroups.indexOf(groupId) > -1;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedUsers: nextProps.selectedUsers,
      selectedGroups: nextProps.selectedGroups,
    });
  }
  showManageUserPanel() {
    return (
      <>
        <div className="selection__sub-header">
          <span className="selection__sub-header-text">
            {services.Localize.getLocalizedString("_Storyboards.SharePanel.UserSelector.GroupSubheader_")}
          </span>
        </div>
        <div>{this.getGroupRows()}</div>
        <div className="selection__sub-header">
          <span className="selection__sub-header-text">
            {services.Localize.getLocalizedString("_Storyboards.SharePanel.UserSelector.UserSubheader_")}
          </span>
        </div>
        <div>{this.getUserRows()}</div>
      </>
    );
  }

  showSpinner() {
    return (
      <div className="user-selector__spinner">
        <Spinner
          caption={services.Localize.getLocalizedString("_Storyboards.SharePanel.UserSelector.spinnerMessage")}
        />
      </div>
    );
  }
  render() {
    return (
      <>
        <div className="user-selector__count">
          <p>
            {services.AppConfig.features.enablePaginationOnManageUsers && !this.state.isLoading && (
              <>
                {services.Localize.getLocalizedString("_Storyboards.SharePanel.UserSelector.countMessage")}{" "}
                {this.state.from}-{this.state.to}/{this.state.count}
              </>
            )}
          </p>
        </div>

        <div className="user-selector-wrapper">
          <div className="search-panel">
            <i className="acl-i-search" />
            <input
              type="text"
              value={this.state.filterString}
              onChange={this.handleFilterChange}
              placeholder={services.Localize.getLocalizedString(
                "_Storyboards.SharePanel.UserSelector.FilterUserPlaceholder_"
              )}
            />
          </div>

          <div ref="userGroupList" className="selection manageUsers">
            {this.state.isLoading ? this.showSpinner() : this.showManageUserPanel()}
          </div>
        </div>

        <>
          <div className="selection_actions">
            <div className="selection_pagination">
              {services.AppConfig.features.enablePaginationOnManageUsers && !this.state.isLoading && (
                <Pagination
                  totalPages={this.state.totalPages}
                  currentPage={this.state.currentPage}
                  onChange={e => {
                    this.getStoryboardUserData(e);
                    this.setState({ currentPage: e });
                  }}
                />
              )}
            </div>

            <Button onClick={this.handleConfirm} type="primary" isDisabled={this.state.isLoading}>
              {services.Localize.getLocalizedString("_Storyboards.SharePanel.UserSelector.ConfirmBtn_")}
            </Button>
            <Button onClick={this.handleCancel} type="minor" isDisabled={this.state.isLoading}>
              {services.Localize.getLocalizedString("_Storyboards.SharePanel.UserSelector.CancelBtn_")}
            </Button>
          </div>
        </>
      </>
    );
  }
}

UserSelector.propTypes = {
  users: PropTypes.array,
  groups: PropTypes.array,
  onUsersSelected: PropTypes.func,
  onCancel: PropTypes.func,
};

window.UserSelector = UserSelector;
