import ApiPath from "@viz-ui/services/apiPath/apiPathService";

angular
  .module("acl.visualizer.charts.backend")
  .service("TreemapBackendService", (queuedHttp, ChartBackendService, DataModel) => {
    return {
      fetchJson: (tableId, filter, config) => {
        const myRecords = DataModel.filterConfig.myRecords();
        const openStatuses = DataModel.filterConfig.openStatuses();
        const raw = DataModel.toSaveViz();
        const requestParams = { raw, myRecords, openStatuses };
        const chartIndex = requestParams.raw.visualizations.findIndex(d => {
          return angular.equals(d.config.dataConfig, config);
        });

        requestParams.raw.visualizations[chartIndex] = convertToSummaryTableVisualization(
          requestParams.raw.visualizations[chartIndex]
        );

        return queuedHttp.post(url(tableId, chartIndex), requestParams);
      },

      getRepresentation: (resultData, config) => {
        const rowFieldNames = resultData.config.rows.map(d => d.field_name);
        const valueFieldNames = resultData.config.values.map(d => d.field_name);
        const rowParsers = resultData.config.rows.map(d => ChartBackendService.getParserForType(d.data_type));
        const valueParsers = resultData.config.values.map(d => ChartBackendService.getParserForType(d.data_type));
        return {
          config: angular.copy(resultData.config),
          values: resultData.values.map(valueObj => ({
            columns: {},
            rows: valueObj.rows.map((d, i) => {
              const rowFieldName = rowFieldNames[i];
              return { [rowFieldName]: rowParsers[i](valueObj.rows[i][rowFieldName]) };
            }),
            values: valueFieldNames.reduce((accumulator, valueFieldName, index) => {
              accumulator[valueFieldName] = valueParsers[index](valueObj.values[valueFieldName]);
              return accumulator;
            }, {}),
          })),
        };
      },
    };

    function url(tableId, chartIndex) {
      return ApiPath.table.getSummaryTableDataUrl(tableId) + "?chart_index=" + chartIndex;
    }

    function convertToSummaryTableVisualization(treemapVisualization) {
      return {
        id: treemapVisualization.id,
        title: treemapVisualization.title,
        type: "SummaryTable",
        config: {
          displayConfig: {},
          dataConfig: treemapVisualization.config.dataConfig,
        },
      };
    }
  });
