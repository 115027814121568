import CombinationChartController from "./combinationChart.controller";
import CombinationChartService from "./combinationChart.service";

angular
  .module("acl.visualizer.combinationChart", [
    "acl.visualizer.charts.controllers",
    "acl.visualizer.charts",
    "acl.visualizer.color",
  ])
  .factory("CombinationChart", CombinationChartService)
  .controller("CombinationChartController", CombinationChartController);
