import backendApi from "@results/services/apiCall/backendApi";
import TableDataAdapter from "@viz-ui/services/query/queryAdapters/tableDataAdapter";
import QueryClient from "@viz-ui/services/query/queryClient/queryClient";
import QueryGenerator from "@viz-ui/services/query/queryGenerator/queryGenerator";

export default class MetadataManager {
  constructor(useQueryService) {
    this.useQueryService = useQueryService;

    this.loadedControlTestIds = [];
    this.loadedMetadata = [];
    this.resetCurrent();
  }

  static getInstance(useQueryService) {
    return new MetadataManager(useQueryService);
  }

  get(controlTestIds) {
    const newControlTestIds = this.newLoadedIds(controlTestIds);
    this.requestedIds = controlTestIds;
    if (this.currentPromise) {
      if (this.newCurrentPromiseIds(newControlTestIds).length === 0) {
        return this.currentPromise;
      }
      return this.requestMetadata(this.newCurrentPromiseIds(newControlTestIds));
    }

    if (newControlTestIds.length) {
      return this.requestMetadata(newControlTestIds);
    }
    return Promise.resolve(this.requestedMetadata());
  }

  newLoadedIds(controlTestIds) {
    let loadedControlTestIds = new Set(this.loadedControlTestIds);
    return controlTestIds.filter(controlTestId => !loadedControlTestIds.has(controlTestId));
  }

  newCurrentPromiseIds(newControlTestIds) {
    let currentPromiseIds = new Set(this.currentPromiseIds);
    return newControlTestIds.filter(controlTestId => !currentPromiseIds.has(controlTestId));
  }

  requestMetadata(controlTestIds) {
    this.currentPromiseIds = controlTestIds;

    let metadataLoadedHandler = metadatas => {
      this.resetCurrent();
      this.loadedControlTestIds = this.loadedControlTestIds.concat(controlTestIds);
      this.loadedMetadata = this.loadedMetadata.concat(metadatas);
      return this.requestedMetadata();
    };

    if (!this.useQueryService) {
      this.currentPromise = backendApi
        .get(this.url(controlTestIds))
        .then(response => metadataLoadedHandler(response.data.meta_data));
    } else {
      this.currentPromise = Promise.all(
        controlTestIds.map(controlTestId => {
          const queryObj = QueryGenerator.tableMetadata(controlTestId);
          return new Promise(resolve => {
            QueryClient.query(queryObj)
              .then(response => {
                resolve(TableDataAdapter.formatMetadata(controlTestId, response.data));
              })
              .catch(() => {
                resolve("error");
              });
          });
        })
      ).then(results => {
        const metadatas = results.filter(result => result !== "error");
        return metadataLoadedHandler(metadatas);
      });
    }

    return this.currentPromise;
  }

  requestedMetadata() {
    return this.loadedMetadata.filter(metaData => this.requestedIds.includes(metaData.id));
  }

  url(ids) {
    return `/storyboards/meta_data?ids=${ids.join(",")}`;
  }

  resetCurrent() {
    this.currentPromise = undefined;
    this.currentPromiseIds = [];
  }
}
