import { JwtHighbondClient } from "@acl-services/jwt-highbond-client";
import { isPublicStoryboard } from "@viz-ui/services/storyboardUrlDetection/storyboardUrlDetection";

/**
 * This is a generic class for calling APIs. It provides a central location for
 * handling authorization, routing to specific backends, as well as JWT token
 * refresh. All Visualizer/Storyboards code should start using this class for
 * HTTP communication.
 */
export default class ApiCall {
  /* private members, caching values that take time to compute */
  private storyboardsUrl: string = "";
  private picassoApiUrl: string = "";
  private loganUrl: string = "";
  private loginUrl: string = "";
  private publicApiUrl: string = "";
  private csrfToken: string = "";
  private reportDispatcher: string = "";
  private forceAuthorization: boolean = true;
  // webComponentsUrl is to get micro-frontend url based on the window.location.href
  private webComponentsUrl: string = "";

  //flag to check if the url is local or not
  private isLocal: boolean = false;

  /** set to true if we're running with Picasso as the backend */
  private isRunningOnPicasso: boolean = false;

  /** The JWT client library, which refreshes stale tokens */
  private jwtClient: JwtHighbondClient;

  /**
   * It takes time to fetch the CSRF token, so do it in background. If we
   * need to do a POST, yet `csrfToken` is still the empty string,
   * then we must await() on this promise.
   */
  private csrfPromise: Promise<string>;

  /**
   * Perform a GET operation on the Picasso API. Refreshes
   * the JWT token if it expires.
   */
  public async get(path: string, options: any = {}) {
    return this.http({
      ...options,
      url: path,
      method: "GET",
    });
  }

  /**
   * Perform a GET operation on the Public API. Refreshes
   * the JWT token if it expires.
   */
  public async publicApiGet(path: string, options: any = {}) {
    return this.http(
      {
        ...options,
        url: `${this.publicApiUrl}${path}`,
        method: "GET",
      },
      false
    );
  }

  /**
   * Perform a POST operation on the Public API. Refreshes
   * the JWT token if it expires.
   */
  public async publicApiPost(path: string, options: any = {}) {
    return this.http(
      {
        ...options,
        url: `${this.publicApiUrl}${path}`,
        method: "POST",
      },
      false
    );
  }

  /**
   * Perform a POST operation on the Picasso API.
   */
  public async post(path: string, options: any = {}, body: any = {}) {
    return this.http({
      ...options,
      url: path,
      method: "POST",
      body,
    });
  }

  /**
   * Perform a PUT operation on the Picasso API.
   */
  public async put(path: string, options: any = {}, body: any = {}) {
    return this.http({
      ...options,
      url: path,
      method: "PUT",
      body,
    });
  }

  /**
   * Perform a DELETE operation on the Picasso API.
   */
  public async delete(path: string, options: any = {}) {
    return this.http({
      ...options,
      url: path,
      method: "DELETE",
    });
  }

  /**
   * Perform an HTTP operation on the Picasso API. The `method` field in the options
   * provides the method. If there is a body, it must be in the `body` field. The
   * response payload must be JSON.
   */
  public async http(options: any, isLoganCall: boolean = true) {
    const newOptions: RequestInit = {
      ...options,
      credentials: "include",
    };

    const method = options.method.toUpperCase();
    if (["PUT", "POST"].includes(method)) {
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      delete newOptions.body;
    }

    if (!this.isStoryboardSPA() && ["PUT", "POST", "DELETE"].includes(method)) {
      /* POSTs etc. will fail if they're sent to Logan without the CSRF token */
      await this.setCsrfHeader(newOptions);
    }

    const queryParams = this.computeQueryParams(options.url, options.params);

    /* we only send and accept JSON */
    if (!newOptions.headers) {
      newOptions.headers = {};
    }
    (newOptions.headers as any)["accept"] = (newOptions.headers as any)["content-type"] = "application/json";

    /*
     * Send the fetch, and throw an exception if not a 2xx response. This makes
     * `fetch` behave more like `$http()` in Angular.
     */
    return this.jwtClient
      .fetchWithTokenRefresh(isLoganCall ? `${this.loganUrl}${options.url}${queryParams}` : options.url, newOptions)
      .then(async response => {
        if (response.status === 419 && this.forceAuthorization) {
          window.location.href = `${this.loginUrl}?redirect_uri=${window.location.href}`;
        }
        if (response.status < 200 || (response.status > 299 && response.status != 422)) {
          throw {
            status: response.status,
            statusText: response.statusText,
          };
        }

        let data: any = {};
        try {
          data = await response.json();
        } catch (ex) {
          /* it's OK if a DELETE call doesn't return JSON */
          if (method !== "DELETE") {
            throw ex;
          }
        }
        return {
          data,
          status: response.status,
          statusText: response.statusText,
        };
      });
  }

  /**
   * Returns true if storyboards are accessed through storyboard spa, else false if through logan or results.
   *  storyboards spa url something like:
   *   https://<subdomain>.storyboards.highbond.com
   */
  public isStoryboardSPA(): boolean {
    return this.isRunningOnPicasso;
  }

  /**
   * Returns the base URL for the Storyboard static pages, derived from
   * our base HTML page. This is typically something like:
   *   https://<subdomain>.storyboards.highbond.com
   */
  public getStoryboardsUrl(): string {
    return this.storyboardsUrl;
  }

  /**
   * Returns the base URL for the Picasso API endpoints, derived from
   * our base HTML page. This is typically something like:
   *   https://<subdomain>.visualizer-api.highbond.com
   */
  public getPicassoApiUrl(): string {
    return this.picassoApiUrl;
  }

  /**
   * Returns the base URL for the Logan (Results Rails) application, derived
   * from our base HTML page. This is typically something like:
   *   https://<subdomain>.results.highbond.com
   */
  public getLoganUrl(): string {
    return this.loganUrl;
  }

  /**
   * Returns the login url for application, derived
   * from our base HTML page. This is typically something like:
   *   https://accounts<-domain>..highbond.com
   */
  public getLoginUrl(): string {
    return this.loginUrl;
  }

  /**
   * Returns the public api url for application, derived
   * from our base HTML page. This is typically something like:
   *   https://apis<-regioncode>.highbond.com
   */
  public getPublicApiUrl(): string {
    return this.publicApiUrl;
  }

  /**
   * Returns the websocket url for application, derived
   * from our base HTML page. This is typically something like:
   *   wss://impact-report-dispatcher<-regioncode>.highbond.com
   */
  public getReportDispatcherUrl(): string {
    return this.reportDispatcher;
  }

  /**
   * Returns the global nav bar url for application, derived
   * from our base HTML page. This is typically something like:
   *  https://web-components<-regioncode>.highbond.com
   */

  public getWebComponentsUrl(): string {
    return this.webComponentsUrl;
  }

  /**
   * Returns the isLocal flag for application, derived
   * from our base HTML page. This is typically something like:
   *  true or false
   */
  public isHostLocal(): boolean {
    return this.isLocal;
  }

  /**
   * Extract the CSRF token from the main Results page's meta fields. This is
   * necessary because invoking a POST operation on a Results endpoint will
   * be rejected unless we set the `X-CSRF-TOKEN` header to this token value.
   * In the old days, our HTML page was loaded from Results, so this was just
   * embedded into our HTML anyway - we didn't need to do anything extra.
   *
   * Note: due to CORS restrictions, we actually fetch this page via Picasso's
   * equivalent endpoint, rather than reaching out to Results (which will reject
   * our fetch).
   */
  private async fetchCsrfToken(): Promise<string> {
    if (this.csrfToken) {
      return this.csrfToken;
    }
    const response = await this.jwtClient.fetchWithTokenRefresh(this.loganUrl, {
      headers: {
        Accept: "text/html",
      },
      credentials: "include",
    });
    const text = await response.text();
    let csrfValueMatch = text.match(/<meta.*name="csrf-token".*content="(.*)".*\/>/);
    if (csrfValueMatch) {
      this.csrfToken = csrfValueMatch[1];
      return this.csrfToken;
    } else {
      throw "Unable to find CSRF token";
    }
  }

  /**
   * Return the CSRF token for this page. This is an async method because
   * we might not yet have received the token, so we'll need to wait for it.
   * If we're not running with Picasso as the backend, then use the legacy
   * window._token instead.
   */
  public async getCsrfToken(): Promise<string> {
    /* We use window._token for CSRF, unless we're running on Picasso */
    if (!this.isRunningOnPicasso) {
      return (window as any)._token;
    }
    if (this.csrfToken === "") {
      await this.csrfPromise;
    }
    return this.csrfToken;
  }

  /**
   * Modify the provided options to include the necessary CSRF header.
   * This is an async method, because we might not know what the CSRF
   * token is, and will therefore need to wait.
   *
   * @param options The HTTP options to be passed to fetch()
   */
  private async setCsrfHeader(options: any) {
    if (!options.headers) {
      options.headers = {};
    }
    options.headers["x-csrf-token"] = await this.getCsrfToken();
  }

  /**
   * Constructor for ApiCall. There'll typically be only one object of this
   * class, shared across the whole front-end application.
   */
  constructor(baseUrl: string = window.location.href, fetchCsrf: boolean = true) {
    /* create special "fetch" client for refreshing JWT tokens if they expire */
    this.jwtClient = new JwtHighbondClient({
      autoRedirect: true, // When true, automatically go to loginUrl when token refresh fails.
    });

    this.forceAuthorization = !isPublicStoryboard(window.location.href);

    /* pre-compute URLs to related services */
    this.computeUrls(baseUrl);

    /*
     * Fetch the CSRF token by copying it from the Results home page. This is
     * asynchronous, but will hopefully complete before we need it (if not,
     * we'll just await the promise).
     */
    if (fetchCsrf && this.isRunningOnPicasso) {
      this.csrfPromise = this.fetchCsrfToken();
    } else {
      /* for testing - mock the token */
      this.csrfPromise = Promise.resolve("");
      this.csrfToken = "test_csrf_token";
    }
  }

  /**
   * Helper function for computing the URLs to related services, based on the
   * URL of the base HTML page. This code pre-compute the values returned by
   *   getPicassoUrl()
   *   getPicassoApiUrl()
   *   getLoganUrl()
   *
   * @param baseUrl The URL of the HTML page (normally window.location.href)
   */
  //wss://impact-reports-report-dispatcher.highbond-s1.com
  private computeUrls(baseUrl: string) {
    /*
     * Handle local development case
     */
    const matchLocal = baseUrl.match(/^(http:\/\/localhost)([^/]*)/);
    const matchNgrok = baseUrl.match(/^https:\/\/(.*)\.ngrok.io\/(.*)$/);
    if (matchLocal) {
      this.storyboardsUrl = this.picassoApiUrl = this.loganUrl = this.publicApiUrl = this.webComponentsUrl = `${matchLocal[1]}${matchLocal[2]}`;
      this.loginUrl = `${matchLocal[1]}${matchLocal[2]}/login`;
      this.reportDispatcher = `wss://localhost${matchLocal[2]}`;
      this.isLocal = true;
    } else if (matchNgrok) {
      this.storyboardsUrl = this.picassoApiUrl = this.loganUrl = this.publicApiUrl = `https://${matchNgrok[1]}.ngrok.io/`;
      this.loginUrl = `https://${matchNgrok[1]}.ngrok.io/login`;
      this.reportDispatcher = `wss://impact-reports-report-dispatcher.ngrok.io`;
      this.webComponentsUrl = `https://web-components.ngrok.io`;
    } else {
      /*
       * Else, handle general case with realistic URLs
       */
      const match = baseUrl.match(/^(https?:[^\.]+)\.([^\.]+)\.([^/]+)/);
      if (!match) {
        throw `Unable to parse window.location.href URL: ${location}`;
      }
      const urlPrefix = match[1];
      const urlSuffix = match[3];
      const isViaLogan = match[2].match(/^results(-..)?$/);
      const isLocalDev = match[2] === "picasso";
      const region = match[2].match(/^(results|storyboards|picasso)(-..)?$/);
      const regionSuffix = region && region[2] ? region[2] : "";
      /*
       * When loaded via Results/Logan, continue to use relative URLs for backward
       * compatibility, else split out to three separate URLs, also allowing for
       * local development case.
       */
      if (isViaLogan) {
        this.storyboardsUrl = this.picassoApiUrl = this.loganUrl = "";
      } else {
        this.isRunningOnPicasso = true;
        this.storyboardsUrl = `${urlPrefix}.${isLocalDev ? "picasso" : `storyboards${regionSuffix}`}.${urlSuffix}`;
        this.picassoApiUrl = `${urlPrefix}.${isLocalDev ? "picasso" : `visualizer-api${regionSuffix}`}.${urlSuffix}`;
        this.loganUrl = `${urlPrefix}.results${regionSuffix}.${urlSuffix}`;
        this.reportDispatcher = `wss://impact-reports-report-dispatcher${regionSuffix}.${urlSuffix}`;
      }

      const domain = baseUrl.match(/\.(highbond|diligentoneplatform)((?:-\w+)+)?\.(local|com|mil)/)?.shift();
      const protocol = baseUrl.match(/^(https?)/)?.shift();

      // Adding the fix in order to use public api url for 'US' region with '-us'
      const publicApiWithUsRegionSuffix = regionSuffix
        ? regionSuffix
        : urlSuffix.indexOf("-") > -1 || urlSuffix === "highbond.mil" || urlSuffix === "diligentoneplatform.mil"
        ? ""
        : "-us";
      // Setting global nav bar url
      this.webComponentsUrl = `https://web-components${regionSuffix}${domain}`;
      this.loginUrl = `${protocol}://accounts${domain}/login`;
      this.publicApiUrl = `${protocol}://apis${publicApiWithUsRegionSuffix}.${urlSuffix}`;
      this.reportDispatcher = `wss://impact-reports-report-dispatcher${regionSuffix}.${urlSuffix}`;
    }
  }

  /**
   * Compute the query string, given a number of query parameters in an object. Be
   * careful when forming the URL, since it might already have query parameters on it.
   */
  private computeQueryParams(url: string, params: Record<string, string>): string {
    if (params) {
      const searchParams = new URLSearchParams(params);
      searchParams.sort(); /* easier testing/mocking */
      const queryStart = url.indexOf("?") === -1 ? "?" : "&";
      return `${queryStart}${searchParams.toString()}`;
    } else {
      return "";
    }
  }
}
