angular.module("acl.visualizer.model.accessControl").factory("AccessControl", function(Model) {
  class AccessControl extends Model {
    static modelType = "AccessControl";

    drilldown(...args) {
      return this.accessor("drilldown", args, "boolean");
    }
  }
  return AccessControl;
});
