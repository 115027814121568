import EventServiceRegistrant from "./eventServiceRegistrant";

class EventService {
  constructor() {
    this.listeners = [];
  }

  register(registrant, registrantScope) {
    const that = this;
    return new EventServiceRegistrant(registrant, registrantScope, (method, subscriber, event) => {
      const args = Array.prototype.slice.call(arguments, 3);
      that.listeners
        .filter(function(listener) {
          return !!listener[method];
        })
        .forEach(function(listener) {
          listener[method](subscriber, event, ...args);
        });
    });
  }

  registerListener(listener) {
    this.listeners.push(listener);
  }
}

export default new EventService();
