/*
    Copyright ©2014 ACL Services Ltd.
    Use of this code is governed by the ACL Software License Agreement that applies to the Software of which this code is a part:
    http://www.acl.com/pdfs/ACL_License_Agreement.pdf
*/

/**
 * Simple service providing dependency injection for any controller
 * that needs to use a Date "now" or "today"
 *
 * (Originally created for analyticParamInputCtrl, which needs "now"
 * for intializing date/datetime picker values.)
 */
angular.module("acl.common.simpleDate", []).factory("simpleDate", function() {
  "use strict";
  var simpleDateService = {
    now: function() {
      return new Date();
    },
  };

  return simpleDateService;
});
