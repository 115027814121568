export default  (AppConfig, DataRequester) => {
  "ngInject";

  class FieldValueManager {
    getCacheKey(requestData) {
      let keyData = {
        fieldName: requestData.fieldName,
      };

      if (isLazyLoadingEnabled()) {
        keyData.search = requestData.search;
      }
      return JSON.stringify(keyData);
    }

    loadValues({ tableId, fieldName, fieldType, search, timezoneOffset }, position = 0) {
      let positionParameter = AppConfig.features.valueLazyLoading ? position : undefined;
      return DataRequester.getInstance("fieldValuesRequest")
        .request(AppConfig.timeout, tableId, fieldName, fieldType, search, timezoneOffset, positionParameter)
        .then(rawData => {
          let values = rawData.result.data;
          return {
            values: values,
            count: parseCount(rawData.result),
          };
        });
    }
  }

  function parseCount(result) {
    if (isLazyLoadingEnabled()) {
      return isNaN(result.count) ? null : result.count;
    }
    return result.data.length;
  }

  function isLazyLoadingEnabled() {
    return AppConfig.features.valueLazyLoading;
  }

  return new FieldValueManager();
};
