import LoadingAnimationState from "@viz-ui/services/common/loadingAnimationStateService";

angular
  .module("acl.visualizer.dataVisualizer")
  .factory("Visualizer", function($state, $q, AppConfig, DataModel, EventService, SaveViz, TabStateService) {
    var eventService = EventService.register("dataVisualizer.Visualizer");

    var dataVisualizer = {
      initColumnConfigs,
      initDataVisualizer,
      initLoadingAnimationState,
      getFields,
      getSortFields,
      canLinkMostRecentTable,
      canShareToGrc,
    };

    return dataVisualizer;

    function initDataVisualizer(params, storyboardDrilldownStorageKey) {
      const { identifyByAnalyticName } = AppConfig;
      let deferred = $q.defer();
      let analyticName;

      if (params.savedVizId) {
        if (identifyByAnalyticName) {
          analyticName = params.analyticName;
        }
        SaveViz.loadSavedViz(params.savedVizId, analyticName, storyboardDrilldownStorageKey).then(
          function(message) {
            deferred.resolve(message);
          },
          function(message) {
            deferred.reject(message);
          }
        );
      } else if (params.remediation || params.public) {
        SaveViz.loadDefaultViz().then(
          function(message) {
            deferred.resolve(message);
          },
          function(message) {
            if (message.errorCode === 404) {
              if (message.hide_metadata) {
                DataModel.setHideMetaData(true);
              }
              _setTableIdAndTab(params.tableId, identifyByAnalyticName, params.analyticName);
              // Make sure the "tabState.reloaded" event is triggered after
              // the the tab id get set to 0 in _setTableIdAndTab
              TabStateService.reload(0);
              deferred.resolve("Load Table Id");
            } else {
              deferred.reject(message);
            }
          }
        );
      } else {
        _setTableIdAndTab(params.tableId, identifyByAnalyticName, params.analyticName);
        deferred.resolve("Load Table Id");
      }

      return deferred.promise;
    }

    function _setTableIdAndTab(tableId, identifyByAnalyticName, analyticName) {
      // AX needs only table uuid
      DataModel.table.tableId(tableId);
      if (identifyByAnalyticName) {
        // ANR result tables need analytic name in addition to the table uuid
        DataModel.table.analyticName(analyticName);
      }
      DataModel.interpretation.currentTabIndex(0).linkLatestTable(false);
    }

    function initLoadingAnimationState() {
      LoadingAnimationState.isDebug(AppConfig.features.debugLoadingAnimation);
      LoadingAnimationState.start("visualizerInit");
      var unsubscribeDataChange = eventService.subscribe("tabState.reloaded", function(event, tabIndex) {
        if (
          ($state.current.name === "openViz" && tabIndex !== 0) ||
          ($state.current.name === "openTable" && tabIndex === 0) ||
          tabIndex === DataModel.interpretation.currentTabIndex()
        ) {
          unsubscribeDataChange();
          LoadingAnimationState.stop("visualizerInit");
        }
      });
    }

    //TODO: this changes the datamodel -> should go into datamodel.
    //TODO: this is duplicated but slightly different in dataTableService.
    function getFields(rawFields, initialValue) {
      var fields = initialValue || {};
      for (var iField = 0; iField < rawFields.length; iField++) {
        var fieldName = rawFields[iField].name;
        var colId = "col" + (iField + 1);
        if (fields[fieldName] === undefined) {
          fields[fieldName] = {};
        }
        fields[fieldName].colId = colId;
        var displayValue = "";
        if (rawFields[iField].columnTitle) {
          displayValue = rawFields[iField].columnTitle;
        } else {
          displayValue = fieldName;
        }
        fields[fieldName].displayName = displayValue;
        fields[fieldName].criteriaFilter = {
          newFilter: {},
          filters: [],
        };
      }
      return fields;
    }

    function getSortFields(rawFields, initialValue) {
      var sortFields = initialValue || [];
      for (var iField = 0; iField < rawFields.length; iField++) {
        var fieldName = rawFields[iField].name;
        var displayValue = "";
        if (rawFields[iField].columnTitle) {
          displayValue = rawFields[iField].columnTitle;
        } else {
          displayValue = fieldName;
        }
        sortFields.push({ fieldName: fieldName, displayName: displayValue });
      }
      return sortFields;
    }

    function canShareToGrc() {
      return (
        AppConfig.features.saveViz &&
        AppConfig.features.saveViz.shareToGRC &&
        ($state.current.name === "saveViz" || $state.current.name === "openViz")
      );
    }

    function canLinkMostRecentTable() {
      return (
        AppConfig.features.saveViz &&
        AppConfig.features.saveViz.linkMostRecentTable &&
        $state.params.analyticName &&
        ($state.current.name === "saveViz" || $state.current.name === "openViz")
      );
    }

    function initColumnConfigs(isNew, isRemediation) {
      const columnConfigObjs = getColumnConfigObjs(
        DataModel.tableConfig.columns(),
        DataModel.table.fields(),
        isNew,
        isRemediation
      );

      DataModel.tableConfig.columns(columnConfigObjs);
    }

    function getColumnConfigObjs(tableConfigColumnObjs, fieldObjs, isNew, isRemediation) {
      let newColumnConfigObjs = [];
      let columnConfigKeysDefined = {};

      tableConfigColumnObjs.forEach(configColumnObj => {
        const { fieldName } = configColumnObj;
        const columnFieldExists = Object.hasOwnProperty.call(fieldObjs, fieldName);
        if (columnFieldExists) {
          columnConfigKeysDefined[fieldName] = true;
          newColumnConfigObjs.push({ ...configColumnObj });
        }
      });

      Object.values(fieldObjs).forEach(({ fieldName }) => {
        if (!columnConfigKeysDefined[fieldName]) {
          const isInterpretation = !isRemediation;
          const isMetadataField = DataModel.table.isMetadataField(fieldName);

          const shouldHideField =
            (isNew && isInterpretation && isMetadataField) ||
            (AppConfig.features.hideMeteData && isMetadataField && DataModel.isHideMetaData());

          newColumnConfigObjs.push({
            fieldName,
            visible: !shouldHideField,
          });
        }
      });

      return newColumnConfigObjs;
    }
  });
