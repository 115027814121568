import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Icon from "acl-ui/components/Icon";
import Button from "acl-ui/components/Button";
import RawButton from "acl-ui/components/RawButton";
import Switch from "acl-ui/components/Switch";
import Popover from "acl-ui/components/Popover";
import i18n from "@viz-ui/i18n/i18n";
import UsageTracker from "@viz-ui/services/tracking/usageTracker";

export default class FilterWidgetTitleBar extends PureComponent {
  static propTypes = {
    displayName: PropTypes.string,
    hasOverflow: PropTypes.bool,
    isActive: PropTypes.bool,
    isEditDisabled: PropTypes.bool,
    onActiveClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onEditClick: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      showOverflow: false,
      confirmDelete: false,
    };
  }

  toggleOverflow = () => {
    this.setState({
      confirmDelete: false,
      showOverflow: !this.state.showOverflow,
    });
  };

  closeOverflow = () => {
    this.setState({
      confirmDelete: false,
      showOverflow: false,
    });
  };

  handleEdit = () => {
    this.closeOverflow();
    this.props.onEditClick();
  };

  handleDelete = () => {
    this.closeOverflow();
    this.props.onDeleteClick();
  };

  handleDeleteRequest = () => {
    this.setState({ confirmDelete: true });

    // specifies a FilterPanel action, but that will not be certain if this component is reused
    UsageTracker.createEvent(".filterpanel.delete.clicked");
  };

  handleDeleteCancel = () => {
    this.closeOverflow();
    this.setState({ confirmDelete: false });
  };

  handleSwitch = () => {
    this.props.onActiveClick();
  };

  renderDelete = () => {
    const requestClasses = classNames("filter-widget__overflow-delete-request", {
      "request-hidden": this.state.confirmDelete,
    });
    const confirmClasses = classNames("filter-widget__overflow-delete-confirm", {
      "confirm-hidden": !this.state.confirmDelete,
    });

    return (
      <div>
        <div className={requestClasses}>
          <RawButton
            ariaLabel={i18n.t("_FilterWidget.Delete.Label_")}
            disabled={this.state.confirmDelete}
            onClick={this.handleDeleteRequest}
          >
            {i18n.t("_FilterWidget.Delete.Label_")}
          </RawButton>
        </div>
        <div className={confirmClasses}>
          <div className="filter-widget__overflow-delete-ask">{i18n.t("_FilterWidget.DeleteAsk.Label_")}</div>
          <div className="filter-widget__overflow-delete-buttons">
            <Button onClick={this.handleDelete} type="destructive" size="small" isDisabled={!this.state.confirmDelete}>
              {i18n.t("_FilterWidget.DeleteConfirm.Label_")}
            </Button>
            <Button onClick={this.handleDeleteCancel} type="minor" size="small" isDisabled={!this.state.confirmDelete}>
              {i18n.t("_FilterWidget.DeleteCancel.Label_")}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  getPopBody = () => (
    <div className="filter-widget__overflow-menu">
      <ul>
        <li className="filter-widget__overflow-edit">
          <RawButton
            disabled={this.props.isEditDisabled}
            onClick={this.handleEdit}
            ariaLabel={i18n.t("_FilterWidget.Edit.Label_")}
          >
            {i18n.t("_FilterWidget.Edit.Label_")}
          </RawButton>
        </li>
        <li className="filter-widget__overflow-delete">{this.renderDelete()}</li>
      </ul>
    </div>
  );

  renderOverflow = () => {
    if (!this.props.hasOverflow) return null;
    return (
      <div className="filter-widget__titlebar-overflow">
        <Popover
          className="aclui-popover--overflow"
          show={this.state.showOverflow}
          content={this.getPopBody()}
          align="bottomRight"
          onOutsideClick={this.closeOverflow}
          canPropagateOutsideClick={false}
        >
          <RawButton
            onClick={this.toggleOverflow}
            className="filter-widget__overflow-button"
            ariaLabel={i18n.t("_FilterWidget.Overflow.Label_")}
          >
            <Icon type="ellipsis" />
          </RawButton>
        </Popover>
      </div>
    );
  };

  render() {
    const titlebarClasses = classNames("filter-widget__titlebar", {
      "filter-widget__titlebar--has-overflow": this.props.hasOverflow,
    });

    return (
      <div className={titlebarClasses}>
        <div className="filter-widget__titlebar-label" title={this.props.displayName}>
          {this.props.displayName}
        </div>
        <div className="filter-widget__titlebar-switch">
          <Switch
            size="small"
            value={this.props.isActive}
            onChange={this.handleSwitch}
            ariaLabel={i18n.t("_FilterWidget.Active.Label_")}
          />
        </div>
        {this.renderOverflow()}
      </div>
    );
  }
}
