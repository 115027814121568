import isFunction from "lodash/isFunction";
import UrlFlipper from "@viz-ui/services/urlFlipper/urlFlipper";
import HighChartLabelSizer from "@visualizer/modules/visualization/highCharts/highChartLabelSizer";

export const configPath = {
  fontSizeConfigPaths: [
    "legend.itemStyle.fontSize",
    "plotOptions.series.dataLabels.style.fontSize",
    "tooltip.style.fontSize",
    "xAxis.labels.style.fontSize",
    "xAxis.title.style.fontSize",
    "yAxis.labels.style.fontSize",
    "yAxis.title.style.fontSize",
  ],
};

//Need to update this method while doing the chart integration.
export const updateTranslations = highChartsProps => {
  return highChartsProps.translations;
};

export const updateHcConfig = (hcConfig, config) => {
  return { ...hcConfig, ...config };
};

export const canAnimate = () => {
  return UrlFlipper.isRenderMode("export") ? false : true;
};

export const updateLabelFontSize = (config, highChartsProps) =>
  HighChartLabelSizer().updateLabelFontSize(
    config,
    highChartsProps.labelFontSize,
    highChartsProps.fontSizeConfigPaths,
    highChartsProps.fontZoomRatio
  );

export const handleClick = (e, highChartsProps) => {
  if (highChartsProps && isFunction(highChartsProps.onClick)) {
    highChartsProps.onClick(e);
  }
};
const handleRender = highChartsProps => {
  highChartsProps.redrawIndex++;
};
//Need to update this method while implementing charts
export const clickWrapper = (onClick, onDblClick) => {
  let target = null;
  let timeout = null;
  let data = null;
  return function(arg) {
    const e = arg instanceof MouseEvent ? arg : arg.browserEvent;
    clearTimeout(timeout);
    if (onDblClick && e.target === target) {
      setTimeout(() => {
        onDblClick.apply(null, [this]);
      });
    } else {
      target = e.target;
      data = { ...this };
      timeout = setTimeout(() => {
        if (onClick) {
          onClick.apply(null, [data]);
        }
        target = null;
      }, 300);
    }
    return false;
  };
};

export const baseConfig = () => {
  return {
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: "transparent",
      events: {
        click: handleClick,
        render: handleRender,
      },
    },
  };
};

export const getBoostConfig = (config, conditionalEnable) => {
  config = config || {};
  const boostFlipper = UrlFlipper.isRenderMode("boost");
  const isEnabled = config.enableBoost !== false && conditionalEnable !== false;
  return {
    enabled: boostFlipper && isEnabled,
    allowForce: true,
    seriesThreshold: 150,
  };
};
