import UsageTracker from "./usageTracker";

class UsageTiming {
  constructor() {
    this._trackingSessionId = generateId();
    this._activeEventList = [];
    this._pendingMeasureEvents = {
      endMarkerName: undefined,
      eventList: [],
      timeout: undefined,
    };
    this._eventList = [];
    this._pendingMeasureTimeoutBuffer = 10000;
  }

  setEventList(eventList) {
    this._eventList = eventList;
  }

  mark(markerName) {
    if (!window.performance) return;

    const markerStartEvents = this._getEventsStartedByMarker(markerName);
    const markerEndEvents = this._getEventsEndedByMarker(markerName);

    this._checkForPendingMeasureSubmission(markerStartEvents, markerEndEvents);

    performance.mark(markerName);

    this._checkForPendingMeasureExtension(markerName);
    this._markerEventsHandler(markerName, markerStartEvents, markerEndEvents);
    return this;
  }

  _checkForPendingMeasureSubmission(startEvents, endEvents) {
    const newActiveEventsStarted = startEvents.length > 0;
    const activeEventsEnded = endEvents.length > 0;
    if (newActiveEventsStarted || activeEventsEnded) {
      this._submitPendingMeasureEvents();
    }
  }

  _checkForPendingMeasureExtension(markerName) {
    if (markerName === this._pendingMeasureEvents.endMarkerName) {
      clearTimeout(this._pendingMeasureEvents.timeout);
      const { endMarkerName, eventList } = this._pendingMeasureEvents;
      this._pendingMeasure(endMarkerName, eventList);
    }
  }

  _markerEventsHandler(markerName, startEvents, endEvents) {
    const newActiveEventsStarted = startEvents.length > 0;
    const activeEventsEnded = endEvents.length > 0;
    if (newActiveEventsStarted) {
      this._activeEventList = this._activeEventList.concat(startEvents);
    }
    if (activeEventsEnded) {
      this._pendingMeasure(markerName, endEvents);
    }
  }

  _pendingMeasure(endMarkerName, eventList) {
    eventList.forEach(event => this._removeEventFromActiveList(event.name));
    this._pendingMeasureEvents = {
      endMarkerName,
      eventList,
      timeout: setTimeout(() => this._measureEvents(eventList), this._pendingMeasureTimeoutBuffer),
    };
  }

  _submitPendingMeasureEvents() {
    if (this._pendingMeasureEvents.timeout) {
      clearTimeout(this._pendingMeasureEvents.timeout);
      this._measureEvents(this._pendingMeasureEvents.eventList);
    }
  }

  _resetPendingMeasure() {
    this._pendingMeasureEvents = {
      endMarkerName: undefined,
      eventList: [],
      timeout: undefined,
    };
  }

  _measureEvents(eventList) {
    eventList.forEach(event => this._measure(event.name));
    this._resetPendingMeasure();
  }

  _measure(eventName) {
    const currentEvent = this._eventList.find(event => event.name === eventName);
    if (!currentEvent) return;

    const startMarkerName = currentEvent.startMarker;
    const endMarkerName = currentEvent.endMarker;

    if (hasMark(startMarkerName) && hasMark(endMarkerName)) {
      performance.measure(eventName, startMarkerName, endMarkerName);
    }

    const measure = getMeasure(eventName);
    if (measure) {
      const markers = getMarksInMeasure(measure);
      this._createUsageTimingEvent(eventName, measure, markers);
      this._clearMarkers();
    }

    return measure;
  }

  _getEventsStartedByMarker(markerName) {
    return this._eventList.filter(event => event.startMarker === markerName);
  }

  _getEventsEndedByMarker(markerName) {
    return this._activeEventList.filter(event => event.endMarker === markerName);
  }

  _removeEventFromActiveList(eventName) {
    const newActiveEventList = [];
    this._activeEventList.forEach(event => {
      if (event.name !== eventName) {
        newActiveEventList.push(event);
      }
    });
    this._activeEventList = newActiveEventList;
  }

  _createUsageTimingEvent(eventName, measure, markers) {
    const eventDetails = { trackingSessionId: this._trackingSessionId, measure, markers };
    const { duration: eventDuration } = measure;
    const name = `.usageTiming.${eventName}`;

    UsageTracker.createEvent(name, eventDetails, eventDuration);
  }

  _clearMarkers() {
    if (this._activeEventList.length === 0) {
      performance.clearMarks();
    }
  }
}

function hasMark(markerName) {
  return !!performance.getEntriesByType("mark").find(marker => marker.name === markerName);
}

function getMeasure(eventName) {
  const measures = performance.getEntriesByType("measure");
  return measures.reverse().find(measure => measure.name === eventName);
}

function getMarksInMeasure(measure) {
  const markers = performance.getEntriesByType("mark");
  const { startTime, duration } = measure;

  return markers.filter(marker => marker.startTime >= startTime && marker.startTime <= startTime + duration);
}

function generateId() {
  return Math.floor((1 + Math.random()) * 0x10000000000)
    .toString(16)
    .substring(1);
}

const usageTiming = new UsageTiming();

export default usageTiming;
