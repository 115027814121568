import ReactDirective from "acl-ui/angular/reactDirective.umd";
import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";
import tableCapability from "@visualizer/modules/core/tableCapability/tableCapability.service";
import ConditionalFormattingToast from "@viz-ui/components/stbGlobalFilter/conditional/conditionalFormattingToast";
import ValueFilter from "@viz-ui/components/vizFilter/quickFilter/valueFilter";
import MultiSelectFilterStore from "@viz-ui/services/quickMenu/MultiSelectFilterStoreService";
import AppConfig from "@visualizer/appConfig";
import VisualizerAbilities from "@viz-ui/services/abilities/visualizerAbilitiesService";

(() => {
  var aclQuickMenu = {
    restrict: "E",
    replace: true,
    bindings: {
      isBiViewClickable: "<",
      fieldFormatIdentifier: "<",
      showDeleteFieldButton: "<",
      showWordWrapToggle: "<",
    },
    templateUrl: "visualizer/js/modules/core/quickMenu/quickMenu.tpl.html",
    controller: QuickMenuController,
    controllerAs: "quickMenu",
  };

  function QuickMenuController(
    $scope,
    DataFilter,
    DataModel,
    EventService,
    Field,
    FieldFormat,
    Localize,
    $timeout,
    $modal
  ) {
    "ngInject";
    var quickMenu = this;
    let hasUserEditedCriteriaValue;

    quickMenu.menu = {
      position: {
        top: "0px",
        left: "0px",
      },
      show: false,
    };

    quickMenu.showDeleteButton = false;

    quickMenu.showBiViewChart = false;

    quickMenu.showFieldName = AppConfig.features.table && AppConfig.features.table.showFieldName;
    quickMenu.migrateVizQuickFilter = AppConfig.features.migrateVizQuickFilter;

    quickMenu.showConditionalFormattingToast = false;

    quickMenu.isLoadingChanged = newValue => {
      quickMenu.showLoadingAnimation = newValue;
    };

    quickMenu.toggleBiViewChart = () => {
      quickMenu.showBiViewChart = !quickMenu.showBiViewChart;
    };

    quickMenu.onToggleWrapText = () => {
      quickMenu.wordWrap = !quickMenu.wordWrap;
      const columnConfigs = DataModel.tableConfig.columns();
      const columnConfig = columnConfigs.find(config => config.fieldName === quickMenu.fieldName);
      columnConfig.wordWrap = quickMenu.wordWrap;
      DataModel.tableConfig.columns(columnConfigs);
      eventService.publish("dataTable.columnResized");
    };

    quickMenu.criteriaFilterProps = {
      canValidate: () => hasUserEditedCriteriaValue,
      fieldName: () => (quickMenu.fieldData ? quickMenu.fieldData.fieldName : null),

      hasOperatorError: () => {
        if (!quickMenu.fieldData) return false;
        let { error } = quickMenu.fieldData.criteriaFilter.newFilter;
        return error && error.operator;
      },
      hasValueError: () => {
        if (!quickMenu.fieldData) return false;
        let { error } = quickMenu.fieldData.criteriaFilter.newFilter;
        return error && (error.value || error.value2);
      },

      isActive: () => quickMenu.isFilterActive(),

      operator: () => {
        if (!quickMenu.fieldData) return null;
        return quickMenu.fieldData.criteriaFilter.newFilter.operator;
      },
      value1: () => {
        if (!quickMenu.fieldData) return false;
        return quickMenu.fieldData.criteriaFilter.newFilter.value;
      },
      value2: () => {
        if (!quickMenu.fieldData) return false;
        return quickMenu.fieldData.criteriaFilter.newFilter.value2;
      },

      operatorChanged: operator => {
        if (quickMenu.fieldData) {
          quickMenu.fieldData.criteriaFilter.newFilter.operator = operator;
        }
      },
      value1Changed: value => {
        if (quickMenu.fieldData) {
          quickMenu.fieldData.criteriaFilter.newFilter.value = value;
          hasUserEditedCriteriaValue = true;
        }
      },
      value2Changed: value => {
        if (quickMenu.fieldData) {
          quickMenu.fieldData.criteriaFilter.newFilter.value2 = value;
          hasUserEditedCriteriaValue = true;
        }
      },
    };

    quickMenu.valueFilterProps = {
      checkedValues: () => MultiSelectFilterStore.getCheckedValuesForWatch(quickMenu.fieldName),
      prevCheckedValues: () => {
        const filter = quickMenu.filter();
        if (!filter) return undefined;
        const { quickFilter } = filter;
        if (!quickFilter) return undefined;
        return quickFilter.values;
      },
      field: () => quickMenu.fieldData,
      isActive: () => !quickMenu.hasFilter() || quickMenu.isFilterActive(),
      isLoadingChanged: newValue => {
        if (!newValue) UsageTracker.mark("quickMenu.loaded");
        quickMenu.showLoadingAnimation = newValue;
      },
      onToggle: (field, value, totalLoadedValuesLength, filterApplied) => {
        if (quickMenu.fieldName && quickMenu.fieldData) {
          if (DataFilter.isFilterActive(DataModel.getFilterConfigDeprecated(), quickMenu.fieldName)) {
            MultiSelectFilterStore.toggleValue(quickMenu.fieldName, value, totalLoadedValuesLength, filterApplied);
          }
        }
      },
      tableId: () => DataModel.table.id(),
      selectAllState: () => {
        return MultiSelectFilterStore.getSelectAllState(quickMenu.fieldName);
      },
      onSelectAllClick: (
        field,
        action,
        visibleValues,
        totalLoadedValuesLength,
        filterApplied,
        selectAllStatePriorSearch
      ) => {
        if (quickMenu.fieldName && quickMenu.fieldData) {
          if (DataFilter.isFilterActive(DataModel.getFilterConfigDeprecated(), quickMenu.fieldName)) {
            MultiSelectFilterStore.handleSelectAllClick(
              quickMenu.fieldName,
              action,
              visibleValues,
              totalLoadedValuesLength,
              filterApplied,
              selectAllStatePriorSearch
            );
          }
        }
      },
    };

    var eventService = EventService.register("QuickMenuController", $scope);
    $scope.$on("$destroy", function() {
      eventService.unregister();
    });

    eventService.subscribe("dataGrid.toggleQuickMenu", function(event, menuPosition, fieldName, isApplyFilter) {
      toggleQuickMenu(menuPosition, fieldName, isApplyFilter);
    });

    eventService.subscribe("filterPanel.toggleQuickMenu", function(event, menuPosition, fieldName) {
      toggleQuickMenu(menuPosition, fieldName);
    });

    eventService.subscribe("quickMenu.close", function() {
      if (quickMenu.fieldName !== undefined && quickMenu.menu.show) {
        quickMenu.closeQuickMenu();
      }
    });

    DataModel.subscribe("table.field", () => {
      quickMenu.fieldData = DataModel.table.field(quickMenu.fieldName);
    });
    DataModel.subscribe("table.fields", () => {
      quickMenu.fieldData = DataModel.table.field(quickMenu.fieldName);
    });

    quickMenu.addFilter = function() {
      var fromQuickMenu = true;
      if (quickMenu.applyFilter) {
        fromQuickMenu = false;
      }
      const addNewFilter = true;
      DataModel.table.field(quickMenu.fieldName, quickMenu.fieldData);
      eventService.publish("applyFilters", fromQuickMenu, quickMenu.applyFilter, addNewFilter);
    };

    quickMenu.applyFilterReady = function() {
      if (quickMenu.fieldData) {
        return (
          MultiSelectFilterStore.hasChanges(quickMenu.fieldName) ||
          DataFilter.isNewCriteriaFilterValid(quickMenu.fieldData.criteriaFilter.newFilter)
        );
      }
      return false;
    };

    quickMenu.deleteField = function() {
      const ModalController = function($scope, $modalInstance) {
        $scope.model = {
          fieldName: quickMenu.fieldName,
        };
        $scope.cancel = function() {
          $modalInstance.dismiss("cancel");
        };
        $scope.delete = function() {
          $modalInstance.close(true);
        };
      };
      ModalController.$inject = ["$scope", "$modalInstance"];

      $modal
        .open({
          templateUrl: "visualizer/js/modules/core/quickMenu/deleteFieldModal.tpl.html",
          controller: ModalController,
          windowClass: "quick-menu__delete-field-modal",
        })
        .result.then(deleteField => {
          if (deleteField) {
            eventService.publish("biView.deleteField", quickMenu.fieldName);
          }
        });
    };

    quickMenu.setFocusToFilterHeader = function(event) {
      if (!quickMenu.showDeleteButton) {
        quickMenu.setFocusToFilterHeaderBtn(event);
      }
    };

    quickMenu.setFocusToFilterHeaderBtn = function(event) {
      if (event.keyCode === 9) {
        angular.element("#filter-closeButton").focus();
      }
      event.preventDefault();
    };

    quickMenu.filter = () => DataFilter.findFilterByName(DataModel.getFilterConfigDeprecated(), quickMenu.fieldName);

    quickMenu.hasFilter = () => !!quickMenu.filter();

    quickMenu.isFilterActive = () =>
      quickMenu.fieldName && DataFilter.isFilterActive(DataModel.getFilterConfigDeprecated(), quickMenu.fieldName);

    quickMenu.clearFilters = () => {
      if (quickMenu.fieldName) {
        DataFilter.clearNewCriteriaFilter(quickMenu.fieldData);

        var filterConfig = DataModel.getFilterConfigDeprecated();
        if (DataFilter.isFilterActive(filterConfig, quickMenu.fieldName)) {
          MultiSelectFilterStore.clearValues(quickMenu.fieldName);
        }
      }
    };

    quickMenu.sort = function(order) {
      if (quickMenu.fieldName) {
        eventService.publish("sortUpdated", quickMenu.fieldName, order);
      }
    };

    quickMenu.isFormattableType = function() {
      return quickMenu.fieldData && DataFilter.isFormattableType(quickMenu.fieldData.type);
    };

    quickMenu.isDigitalSortType = function() {
      return quickMenu.fieldData && DataFilter.isDigitalSortType(quickMenu.fieldData.type);
    };

    quickMenu.hasStaticField = function() {
      return DataModel.table.hasStaticField();
    };

    quickMenu.closeQuickMenu = function() {
      quickMenu.menu.show = false;
      quickMenu.menu.position = {
        top: "0px",
        left: "0px",
      };
      quickMenu.showBiViewChart = false;

      MultiSelectFilterStore.revertAll();
      resetProps();

      eventService.publish("biView.columnSelected", null);
      eventService.publish("clearQuickMenuSearch");
      if (quickMenu.showConditionalFormattingToast) quickMenu.conditionalFormattingToastProps.closeHandler();

      // Remove highlight from the selected column header using selector as re-rendering the grid is costly.
      $(".grid-header--is-active").removeClass("grid-header--is-active");
    };

    quickMenu.onKeyUpToggleBiViewChartPanel = event => {
      if (event.keyCode === 13 || event.keyCode === 32) quickMenu.toggleBiViewChart();
    };

    function toggleQuickMenu(menuPosition, fieldName, isApplyFilter) {
      var { menu } = quickMenu;
      quickMenu.buttonText = "_FilterPanel.QuickMenu.AddFilter.Button_";
      quickMenu.applyFilter = false;
      // reset the dragged position before toggling
      $scope.$broadcast("quickMenu.resetPosition");
      if (!menu.col || quickMenu.fieldName === fieldName) {
        menu.show = true;
      }
      if (isApplyFilter) {
        quickMenu.buttonText = "_FilterPanel.QuickMenu.ApplyFilter.Button_";
        quickMenu.applyFilter = true;
      }
      MultiSelectFilterStore.revertAll();

      quickMenu.isCertificationsTable = function() {
        return DataModel.table.certificationsTable();
      };

      quickMenu.sortDirection = null;

      if (menu.show) {
        menu.position.top = menuPosition.top;
        menu.position.left = menuPosition.left;

        quickMenu.fieldName = fieldName;

        var filterConfig = DataModel.getFilterConfigDeprecated();
        for (var i in filterConfig) {
          if (filterConfig[i] && filterConfig[i].field === fieldName) {
            quickMenu.sortDirection = filterConfig[i].order;
          }
        }

        quickMenu.showBiViewChart = false;
        quickMenu.fieldData = DataModel.table.field(fieldName);
        quickMenu.colId = quickMenu.fieldData ? quickMenu.fieldData.colId : null;
        hasUserEditedCriteriaValue = false;
        initDeleteButton();

        var field = new Field();
        if (quickMenu.fieldData) {
          field
            .name(quickMenu.fieldName)
            .displayName(quickMenu.fieldData.displayName)
            .type(quickMenu.fieldData.type);
        }
        var activeFormattingOption = DataModel.tableConfig.formattingOptions(quickMenu.fieldName) || {};
        quickMenu.fieldFormatProps = {
          field: field,
          fieldFormat: FieldFormat.fromJson(activeFormattingOption),
          linkLabel: Localize.getLocalizedString("_Format.Options.Label_"),
          onChangeFieldFormat: handleChangeFieldFormat,
          onConditionalFormattingClick: openConditionalFormatting,
        };

        updateWordWrapConfig();
        focusTitle();

        eventService.publish("biView.columnSelected", fieldName);

        // Highlight the selected column header using selector as re-rendering the grid is costly.
        $(".grid-header--is-active").removeClass("grid-header--is-active");
        $(".ht_clone_top .ht__active_highlight")
          .find(".grid-header__body")
          .addClass("grid-header--is-active");
      } else {
        resetProps();
      }
    }

    function initDeleteButton() {
      VisualizerAbilities.fetchAbilities().then(abilities => {
        const tableCanDeleteColumns = tableCapability.canDeleteColumns();
        if (abilities) {
          quickMenu.showDeleteButton =
            tableCanDeleteColumns &&
            abilities.can_delete_field &&
            quickMenu.showDeleteFieldButton &&
            quickMenu.fieldData.removable;
        }
      });
    }

    function updateWordWrapConfig() {
      const columnConfigs = DataModel.tableConfig.columns();
      const columnConfig = columnConfigs.find(config => config.fieldName === quickMenu.fieldName);
      quickMenu.wordWrap = columnConfig ? !!columnConfig.wordWrap : false;
    }

    function focusTitle() {
      $timeout(function() {
        angular.element("#quick-menu-title").focus();
      }, 0);
    }

    function resetProps() {
      quickMenu.fieldName = undefined;
      quickMenu.fieldData = null;
      quickMenu.colId = null;
      hasUserEditedCriteriaValue = false;
      quickMenu.wordWrap = false;
    }

    function openConditionalFormatting(field) {
      $modal
        .open({
          templateUrl: "visualizer/views/conditionalFormattingModal.html",
          controller: "ConditionalFormattingController as conditionalFormatting",
          windowClass: "conditional-formatting-modal",
          resolve: {
            fieldFormatIdentifier: function() {
              return quickMenu.fieldFormatIdentifier;
            },
            fieldName: function() {
              return field.name();
            },
          },
        })
        .result.catch(() => {});
    }

    function handleChangeFieldFormat(field, fieldFormat) {
      var fieldName = field.name();
      if (fieldFormat) {
        DataModel.tableConfig.abbreviate(fieldName, fieldFormat.abbreviate());
        DataModel.tableConfig.datetimeFormat(fieldName, fieldFormat.datetimeFormat());
        DataModel.tableConfig.dateFormat(fieldName, fieldFormat.dateFormat());
        DataModel.tableConfig.displayTime(fieldName, fieldFormat.displayTime());
        DataModel.tableConfig.keepTrailingZeros(fieldName, fieldFormat.keepTrailingZeros());
        DataModel.tableConfig.isHtml(fieldName, fieldFormat.isHtml());
        DataModel.tableConfig.isRaw(fieldName, fieldFormat.isRaw());
        DataModel.tableConfig.prefix(fieldName, fieldFormat.prefix());
        DataModel.tableConfig.precision(fieldName, fieldFormat.precision());
        DataModel.tableConfig.postfix(fieldName, fieldFormat.postfix());
        DataModel.tableConfig.sign(fieldName, fieldFormat.sign());
        DataModel.tableConfig.isURL(fieldName, fieldFormat.isURL());
        DataModel.tableConfig.hasOnlyRadixSeparator(fieldName, fieldFormat.hasOnlyRadixSeparator());
        DataModel.tableConfig.thousandsDelimiter(fieldName, fieldFormat.thousandsDelimiter());

        formatterChanged();
      }
    }

    function formatterChanged() {
      quickMenu.closeQuickMenu();
      eventService.publish("dataTable.formattingChange");
    }

    eventService.subscribe("conditionalFormatting.save.toast", () => {
      quickMenu.showConditionalFormattingToast = true;
    });

    quickMenu.publishDataTableConfigChange = tableViewRedirect => {
      quickMenu.showConditionalFormattingToast = false;
      eventService.publish("dataTable.configChange", tableViewRedirect);
    };

    quickMenu.conditionalFormattingToastProps = {
      closeHandler: () => {
        quickMenu.publishDataTableConfigChange(false);
      },
      redirectHandler: () => {
        quickMenu.publishDataTableConfigChange(true);
      },
    };
  }

  angular
    .module("acl.visualizer.quickMenu")
    .component("aclQuickMenu", aclQuickMenu)
    .directive("conditionalFormattingToast", ReactDirective.getFactory(ConditionalFormattingToast))
    .directive("valueFilter", ReactDirective.getFactory(ValueFilter));
})();
