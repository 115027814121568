import i18n from "@viz-ui/i18n/i18n";
import ChartService from "../chartService";

class DataMessage {
  constructor(ColorPallette, DataModel, AppConfig, EventService) {
    this.chartService = new ChartService(ColorPallette, DataModel, AppConfig, EventService);
  }

  getNoDataMessage = () => this.getNoDataMessage;

  chartDataOverLimit = dataRecordsSize => dataRecordsSize > this.chartService.getDataItemLimit();

  getNoDataMessage = (tableRecordCount, dataRecordsSize) => {
    if (!tableRecordCount) {
      return i18n.t("_Table.NoDataAvailable.Label_");
    }
    if (dataRecordsSize && this.chartDataOverLimit(dataRecordsSize)) {
      return i18n.t("_Chart.DataSetTooLarge.Label_");
    }
    if (dataRecordsSize === 0) {
      return i18n.t("_Table.NoFilterDataAvailable.Label_");
    }
    return null;
  };
}
export default DataMessage;
