import React from "react";
import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import uniq from "lodash/uniq";
import isNumber from "lodash/isNumber";
import i18n from "@viz-ui/i18n/i18n";
import { BUBBLE_CHART } from "@visualizer/modules/visualization/highCharts/boost/boostThresholds";
import pubsub from "pubsub-js";

import ReactHighChart from "../highChart/reactHighChart";
import Sorter from "../../../services/sorters/sorter";
import {
  canAnimate,
  updateHcConfig,
  updateLabelFontSize,
  getBoostConfig,
  clickWrapper,
  baseConfig,
  configPath,
} from "../services/highChartService";

class BubbleChart extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    config: PropTypes.object,
    rawData: PropTypes.array,
    redrawIndex: PropTypes.number,
    zoomInHandler: PropTypes.func,
  };

  static defaultProps = {
    index: 0,
    config: {},
    rawData: [],
    redrawIndex: 0,
    zoomInHandler: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      hcConfig: {},
      chartReDraw: false,
    };
  }

  componentDidMount() {
    pubsub.subscribe("chartRedraw", () => {
      this.setState({ chartReDraw: !this.state.chartReDraw });
    });

    this.setState({ hcConfig: this.getConfig(this.props.config, this.props.rawData) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.config !== nextProps.config)
      this.setState({ hcConfig: this.getConfig(nextProps.config, nextProps.rawData) });
  }

  onLegendItemClick = item => {
    const showAll = !this.state.hcConfig.series.some(entry => entry.visible && entry.name !== item.name);
    const series = this.state.hcConfig.series.map(entry =>
      Object.assign({}, entry, {
        visible: showAll || (entry.name === item.name ? !item.visible : entry.visible),
      })
    );
    this.setState({
      hcConfig: updateHcConfig(this.state.hcConfig, { series }),
    });
  };

  onLegendItemDoubleClick = item => {
    const series = this.state.hcConfig.series.map(entry =>
      Object.assign({}, entry, { visible: entry.name === item.name })
    );
    this.setState({
      hcConfig: updateHcConfig(this.state.hcConfig, { series }),
    });
  };

  getConfig = (config, data) => {
    const allDataPoints = data.reduce((all, series) => all.concat(series.values), []);
    const xAxis = {};
    const yAxis = {};
    if (config.xAxis.axisType === "character" || config.xAxis.axisType === "logical") {
      xAxis.categories = Sorter.sort(uniq(allDataPoints.map(d => d.x)), {
        dataType: config.xAxis.axisType,
      });
    }
    if (config.yAxis.axisType === "character" || config.yAxis.axisType === "logical") {
      yAxis.categories = Sorter.sort(uniq(allDataPoints.map(d => d.y)), {
        dataType: config.yAxis.axisType,
      });
    }

    let hcConfig = {
      legend: {
        enabled: config.showLegend && config.hasMultipleSeries,
        align: "right",
        verticalAlign: "top",
        labelFormatter: function() {
          return config.labelFormatter && this.options.name !== "(blank)"
            ? config.labelFormatter(this.options.legendDisplayName)
            : this.options.legendDisplayName;
        },
      },
      boost: getBoostConfig(config),
      plotOptions: {
        series: {
          dataLabels: {
            enabled: config.displayDataLabels,
            formatter: function() {
              return config.valueFormatter(this.point.z);
            },
          },
          point: {
            events: {
              click: clickWrapper(null, point => {
                this.zoomIn(
                  config.hasMultipleSeries
                    ? [point.series.name, point.xValue, point.yValue]
                    : [point.xValue, point.yValue]
                );
              }),
            },
          },
          events: {
            legendItemClick: clickWrapper(this.onLegendItemClick, this.onLegendItemDoubleClick),
          },
          tooltip: {
            headerFormat: "",
            pointFormatter: function() {
              const header = config.hasMultipleSeries
                ? `
                  <span style="text-decoration: underline grey;">${
                    config.labelFormatter && this.series.options.name !== "(blank)"
                      ? config.labelFormatter(this.series.options.legendDisplayName)
                      : this.series.options.legendDisplayName
                  }</span>
                  <br/>
                `
                : "";
              const body = `
                <span>${config.xAxis.tickFormatter(this.xValue)}</span>
                <br/>
                <span>${config.yAxis.tickFormatter(this.yValue)}</span>
                <br/>
                <b>${config.valueFormatter(this.z)}</b>
              `;
              return header + body;
            },
          },
          turboThreshold: 0,
          animation: canAnimate(),
        },
        bubble: {
          minSize: config.bubbleScaleMinSize,
          maxSize: config.bubbleScaleMaxSize,
        },
      },
      xAxis: {
        categories: xAxis.categories,
        labels: {
          formatter: function() {
            // When yaxis is character and value is a number, highchart is trying to fill the axis tick so larger bubble would fit.  Returning empty string so we don't show the number on the axis
            if ((config.xAxis.axisType === "character" || config.xAxis.axisType === "logical") && isNumber(this.value))
              return "";
            return config.xAxis.tickFormatter(this.value);
          },
        },
        tickmarkPlacement: "on",
        title: {
          text: config.xAxis.label,
        },
      },
      yAxis: {
        categories: yAxis.categories,
        title: {
          text: config.yAxis.label,
        },
        labels: {
          formatter: function() {
            // When yaxis is character and value is a number, highchart is trying to fill the axis tick so larger bubble would fit.  Returning empty string so we don't show the number on the axis
            if ((config.yAxis.axisType === "character" || config.yAxis.axisType === "logical") && isNumber(this.value))
              return "";
            return config.yAxis.tickFormatter(this.value);
          },
        },
        min: isNumber(config.yAxis.minimum) ? config.yAxis.minimum : undefined,
        max: isNumber(config.yAxis.maximum) ? config.yAxis.maximum : undefined,
        startOnTick: config.yAxis.axisType !== "character" && !isNumber(config.yAxis.minimum),
        endOnTick: config.yAxis.axisType !== "character" && !isNumber(config.yAxis.maximum),
      },
      series: data.map((series, index) => ({
        type: "bubble",
        name: series.key,
        legendDisplayName: series.key === "(blank)" ? i18n.t("_Filter.BlankValue.Label_") : series.key,
        boostThreshold: BUBBLE_CHART,
        color:
          !getBoostConfig(config, data.length >= BUBBLE_CHART).enabled && this.isPatternFill
            ? this.patternFillPalettes(index)
            : series.color,
        visible: series.visible !== false,
        data: series.values.map(point => ({
          x: xAxis.categories ? xAxis.categories.indexOf(point.x) : point.x,
          y: yAxis.categories ? yAxis.categories.indexOf(point.y) : point.y,
          xValue: point.x,
          yValue: point.y,
          z: point.size,
        })),
      })),
    };
    hcConfig = updateLabelFontSize(hcConfig, configPath);
    return Object.assign(baseConfig(), hcConfig);
  };

  zoomIn = keyArray => {
    if (isFunction(this.props.zoomInHandler)) {
      const data = {
        point: {
          x: keyArray[keyArray.length - 2],
          y: keyArray[keyArray.length - 1],
        },
      };
      if (keyArray.length > 2) {
        data.series = { key: keyArray[0] };
      }
      this.props.zoomInHandler(data);
    }
  };

  render() {
    return (
      <ReactHighChart
        className={`redraw-index-${this.props.redrawIndex}`}
        config={this.state.hcConfig}
        redrawIndex={this.props.redrawIndex}
        chartReDraw={this.state.chartReDraw}
      />
    );
  }
}
export default BubbleChart;
