import { DateFormatter, DatetimeFormatter, TimeFormatter } from "@acl-services/sriracha-formatters/dist/Formatters";
import i18n from "@viz-ui/i18n/i18n";
import ConditionalFilterService from "@viz-ui/services/storyboardFilter/conditionalFilterService";

class InputValidator {
  constructor(fieldFormatAttributes) {
    this.fieldFormatAttributes = fieldFormatAttributes;
  }

  get _fieldFormat() {
    return this.fieldFormatAttributes.fieldFormat;
  }

  get _fieldType() {
    return this.fieldFormatAttributes.fieldType;
  }

  isValid(value) {
    if (!value) return true;
    switch (this._fieldType) {
      case "numeric":
        return !Number.isNaN(Number(value));
      case "datetime":
        return this._fieldFormat && this._fieldFormat.displayTime() !== false
          ? DatetimeFormatter.isUnformatted(value)
          : DateFormatter.isUnformatted(value);
      case "date":
        return DateFormatter.isUnformatted(value);
      case "time":
        return TimeFormatter.isValid(value);
      default:
        return true;
    }
  }

  isValidSelect(value1, value2, value3, operator, canValidate) {
    return !!(!canValidate || operator || (!value1 && !value2 && !value3));
  }

  isRelativeDateValid(value1, value3, canValidate) {
    if (canValidate) {
      switch (value1) {
        case "current":
        case "last":
        case "next":
          if (value3) return true;
          return false;
        case undefined:
          return false;
        default:
          break;
      }
    } else {
      return true;
    }
  }

  isValidInput(filterValue, operator, canValidate) {
    return !!(this.isValid(filterValue) && (!canValidate || !operator || filterValue));
  }

  isValidDateOrDatetime(value) {
    switch (this._fieldType) {
      case "datetime":
        return DatetimeFormatter.isUnformatted(value);
      case "date":
        return DateFormatter.isUnformatted(value);
      default:
        return false;
    }
  }

  isValidRelativeUnit = value => {
    const unit = parseInt(value);
    return unit < 2;
  };

  getErrorMessages(value1, value2, value3, operator, canValidate) {
    const messages = [];
    if (operator === "relative") {
      if (!this.isRelativeDateValid(value1, value3, canValidate) && value2 != "1") {
        messages.push(i18n.t("_Conditional.NoValue.Error_"));
      } else if (this.isValidRelativeUnit(value2)) {
        messages.push(i18n.t("_Conditional.InvalidType.unitValue.Error_"));
      }
      return messages;
    }
    const isFilterInput1Invalid = !this.isValid(value1);
    const isFilterInput2Invalid = !this.isValid(value2);
    const isFilterInput3Invalid = !this.isValid(value3);

    if (isFilterInput1Invalid || isFilterInput2Invalid || isFilterInput3Invalid) {
      messages.push(getFieldTypeError(this._fieldType));
    }
    if (canValidate) {
      if (!operator && (value1 || value2 || value3)) {
        messages.push(i18n.t("_Conditional.NoCondition.Error_"));
      } else if (isSingleOperatorError(operator, value1)) {
        messages.push(i18n.t("_Conditional.NoValue.Error_"));
      } else if (isDoubleOperatorError(operator, value1, value2)) {
        messages.push(i18n.t("_Conditional.NoValues.Error_"));
      }
    }
    return messages;
  }
}

function isSingleOperatorError(operator, value) {
  const singleOperandOperators = ConditionalFilterService.getSingleOperandOperators();
  return singleOperandOperators.includes(operator) && !value;
}

function isDoubleOperatorError(operator, value1, value2) {
  const doubleOperandOperators = ConditionalFilterService.getDoubleOperandOperators();
  return doubleOperandOperators.includes(operator) && (!value1 || !value2);
}

function getFieldTypeError(fieldType) {
  switch (fieldType) {
    case "numeric":
      return i18n.t("_Conditional.InvalidType.numeric.Error_");
    case "date":
      return i18n.t("_Conditional.InvalidType.date.Error_");
    case "time":
      return i18n.t("_Conditional.InvalidType.time.Error_");
    case "datetime":
      return i18n.t("_Conditional.InvalidType.datetime.Error_");
    default:
      return "";
  }
}

export default InputValidator;
