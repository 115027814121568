import FilterValue from "./filterValue";

function serializeFilterValueString(filterValueString) {
  return filterValueString;
}

function serializeFilterValueStrings(filterValueStrings) {
  return filterValueStrings.map(serializeFilterValueString);
}

function serializeFilterValueModelToString(filterValueModel) {
  return filterValueModel.value();
}

function serializeFilterValueModelsToStrings(filterValueModels) {
  return filterValueModels.map(serializeFilterValueModelToString);
}

function serializeFilterValue(filterValueModel) {
  return { value: serializeFilterValueString(filterValueModel.value()), COUNT: filterValueModel.count() };
}

function serializeFilterValues(filterValueModelArray) {
  return filterValueModelArray.map(filterValueModel => serializeFilterValue(filterValueModel));
}

function deserializeFilterValueString(filterValueString) {
  if (filterValueString === undefined) return null;
  return filterValueString === null ? "" : filterValueString;
}

function deserializeFilterValueStrings(filterValueStringArray) {
  if (filterValueStringArray === undefined || filterValueStringArray === null) return null;
  return filterValueStringArray
    .map(filterValueString => deserializeFilterValueString(filterValueString))
    .filter(item => item !== null);
}

function deserializeFilterValueStringToModel(filterValueString) {
  if (filterValueString === undefined) return null;
  return new FilterValue().value(deserializeFilterValueString(filterValueString));
}

function deserializeFilterValueStringsToModels(filterValueStrings) {
  if (filterValueStrings === undefined || filterValueStrings === null) return null;
  return filterValueStrings
    .map(filterValueString => deserializeFilterValueStringToModel(filterValueString))
    .filter(item => item !== null);
}

function deserializeFilterValue(filterValueJson) {
  if (!filterValueJson || filterValueJson.value === undefined) return null;
  let filterValueString = filterValueJson.value;
  return new FilterValue().value(deserializeFilterValueString(filterValueString)).count(filterValueJson.COUNT);
}

function deserializeFilterValues(filterValueJsonArray) {
  if (filterValueJsonArray === undefined || filterValueJsonArray === null) return null;
  return filterValueJsonArray
    .map(filterValueJson => deserializeFilterValue(filterValueJson))
    .filter(item => item !== null);
}

export default {
  serializeFilterValueString,
  serializeFilterValueStrings,
  serializeFilterValueModelToString,
  serializeFilterValueModelsToStrings,
  serializeFilterValue,
  serializeFilterValues,
  deserializeFilterValueString,
  deserializeFilterValueStrings,
  deserializeFilterValueStringToModel,
  deserializeFilterValueStringsToModels,
  deserializeFilterValue,
  deserializeFilterValues,
};
