import i18n from "@viz-ui/i18n/i18n";
import isNil from "lodash/isNil";
import difference from "lodash/difference";
import d3 from "@viz-ui/vendor/d3";

import Field from "../field/field";
import SummaryTable from "./summaryTable";
import { TableDataFormatter } from "@acl-services/sriracha-formatters/dist/Formatters";
import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";

export default class SummaryTableAdapter {
  static deserializeSummaryTable(dataConfig, fieldFormatMap, json, showTotals) {
    const summaryTable = new SummaryTable().rawData({ config: dataConfig, values: json });

    setupHeaders(summaryTable, fieldFormatMap);
    setupFieldFormatMap(summaryTable, fieldFormatMap);
    transformSummarizedData(summaryTable);

    if (showTotals) {
      setupTotalRow(summaryTable);
    }

    return summaryTable;
  }

  static processTableData(summaryTable, numRows) {
    const tableData = summaryTable.tableData() || [];
    const summarizedData = getSummarizedData(summaryTable);
    let rowIndex = tableData.length;
    const endIndex = Math.min(tableData.length + numRows, summarizedData.length);
    const countAggColumnIds = getCountColumnIds(summaryTable);
    for (rowIndex; rowIndex < endIndex; rowIndex++) {
      let dataRow = Object.assign(JSON.parse(summarizedData[rowIndex].key), summarizedData[rowIndex].values);
      dataRow = fillZerosOnCountColumn(dataRow, countAggColumnIds);
      tableData.push(dataRow);
    }
    summaryTable.tableData(tableData);
    return summaryTable;
  }
}

function fillZerosOnCountColumn(dataRow, countAggColumnIds) {
  const columnIdsToFillZero = difference(countAggColumnIds, Object.keys(dataRow));
  columnIdsToFillZero.forEach(columnId => {
    dataRow[columnId] = { value: 0 };
  });
  return dataRow;
}

function transformSummarizedData(summaryTable) {
  const rawData = summaryTable.rawData();
  const config = getConfig(summaryTable);
  rawData.values.summarizedData = d3
    .nest()
    .key(d => transformDataKey(d, config))
    .rollup(values => {
      const columnField = getColumnField(config);
      const valueResult = {};

      if (columnField) {
        values.forEach(value => {
          const columnValue = value[columnField.fieldName];
          Object.assign(
            valueResult,
            rollupValuesForColumnValue(config, summaryTable.valueColumnMap(), columnValue, value)
          );
        });
        return valueResult;
      }

      return rollupValuesForColumnValue(config, summaryTable.valueColumnMap(), undefined, values[0]);
    })
    .entries(rawData.values.summarizedData);
  summaryTable.rawData(rawData);
  return summaryTable;
}

function transformDataKey(data, config) {
  const key = {};
  config.chartRows.forEach((rowField, index) => {
    if (isNil(data[rowField.fieldName])) {
      key["col" + (index + 1)] = {
        value: i18n.t("_Filter.BlankValue.Label_"),
        formattedValue: i18n.t("_Filter.BlankValue.Label_"),
      };
    } else {
      key["col" + (index + 1)] = { value: data[rowField.fieldName] };
    }
  });
  return JSON.stringify(key);
}

function rollupValuesForColumnValue(config, valueColumnMap, columnValue, valueObj) {
  const valueResult = {};
  config.chartValues.forEach((valueField, valueFieldIndex) => {
    const columnId = getColumnId(valueColumnMap, columnValue, valueFieldIndex);
    if (valueField.aggregationType === "count") {
      valueResult[columnId] = { value: valueObj.count };
    } else {
      valueResult[columnId] = { value: valueObj["__valueField" + valueFieldIndex].sum };
    }
  });
  return valueResult;
}

function setupHeaders(summaryTable, fieldFormatMap) {
  const config = getConfig(summaryTable);
  const headers = [];
  const nestedHeaders = getInitNestedHeaders(config);
  summaryTable.headers(headers);
  summaryTable.nestedHeaders(nestedHeaders);

  addFieldsToHeaders(summaryTable, fieldFormatMap);
  addFieldsToNestedHeaders(summaryTable, fieldFormatMap);
}

function setupFieldFormatMap(summaryTable, fieldFormatMap) {
  const config = getConfig(summaryTable);
  const fieldFormatMapForColumns = new Map();

  mapAssign(fieldFormatMapForColumns, getFieldFormatMapForRowFields(config, fieldFormatMap));
  mapAssign(fieldFormatMapForColumns, getFieldFormatMapForValueFields(summaryTable, config, fieldFormatMap));

  summaryTable.fieldFormatMap(fieldFormatMapForColumns);
}

function mapAssign(dest, source) {
  source.forEach((value, key) => {
    dest.set(key, value);
  });
}

function getFieldFormatMapForRowFields(config, fieldFormatMap) {
  const fieldFormatMapForRowFields = new Map();
  config.chartRows.forEach((rowField, rowIndex) => {
    if (fieldFormatMap.get(rowField.fieldName)) {
      fieldFormatMapForRowFields.set("col" + (rowIndex + 1), fieldFormatMap.get(rowField.fieldName));
    }
  });
  return fieldFormatMapForRowFields;
}

function getFieldFormatMapForValueFields(summaryTable, config, fieldFormatMap) {
  const subtotalData = getSubtotalData(summaryTable);
  const valueFields = config.chartValues;
  let columnIndex = config.chartRows.length + 1;
  const fieldFormatMapForValueFields = new Map();
  subtotalData.forEach((columnValue, columnValueIndex) => {
    valueFields.forEach((valueField, valueFieldIndex) => {
      if (fieldFormatMap.get(valueField.fieldName)) {
        fieldFormatMapForValueFields.set("col" + columnIndex, fieldFormatMap.get(valueField.fieldName));
      }
      columnIndex++;
    });
  });
  return fieldFormatMapForValueFields;
}

function getInitNestedHeaders(config) {
  const headerRowspan = getHeaderRowspan(config);
  return new Array(headerRowspan - 1).fill([]);
}

function setupTotalRow(summaryTable) {
  if (summaryTable.numRows() > 1) {
    summaryTable.totalRow(getTotalRow(summaryTable));
  }
}

function getTotalRow(summaryTable) {
  const rawData = summaryTable.rawData();
  const config = rawData.config;
  const subtotalData = rawData.values.subtotalData;
  let subtotalRow;
  let columnIndex = config.chartRows.length + 1;
  if (config.chartRows.length && config.chartValues.length) {
    subtotalRow = {};
    subtotalRow.col1 = {
      value: i18n.t("_SummaryTable.Totals.Label_"),
      formattedValue: i18n.t("_SummaryTable.Totals.Label_"),
    };
    subtotalData.forEach(subtotal => {
      config.chartValues.forEach((valueField, valueIndex) => {
        subtotalRow["col" + columnIndex] = {
          value:
            valueField.aggregationType === "count"
              ? subtotal.count
              : subtotal["__valueField" + valueIndex][valueField.aggregationType],
        };
        columnIndex++;
      });
    });
  }
  return subtotalRow;
}

function addFieldsToHeaders(summaryTable, fieldFormatMap) {
  const config = getConfig(summaryTable);
  summaryTable.headers(summaryTable.headers().concat(rowFieldsHeaders(config)));
  const { headers, valueColumnMap } = valueFieldsHeaders(config, getSubtotalData(summaryTable), fieldFormatMap);
  summaryTable.headers(summaryTable.headers().concat(headers));
  summaryTable.valueColumnMap(valueColumnMap);
}

function addFieldsToNestedHeaders(summaryTable, fieldFormatMap) {
  if (summaryTable.nestedHeaders().length) {
    let nestedHeaders = summaryTable.nestedHeaders();
    const config = getConfig(summaryTable);

    nestedHeaders = appendNestedHeaders(nestedHeaders, getRowFieldsNestedHeaders(config));
    nestedHeaders = appendNestedHeaders(
      nestedHeaders,
      getColumnFieldsNestedHeader(config, getSubtotalData(summaryTable), fieldFormatMap)
    );
    summaryTable.nestedHeaders(nestedHeaders);
  }
}

function getRowFieldsNestedHeaders(config) {
  const nestedHeaders = getInitNestedHeaders(config);
  const rowFields = rowFieldsHeaders(config);
  const rowspan = nestedHeaders.length + 1;

  return nestedHeaders.map((row, index) => {
    const rowHeaders = rowFields.map(rowField => ({ field: rowField }));
    if (index === 0) rowHeaders.forEach(header => (header.rowspan = rowspan));
    return rowHeaders;
  });
}

function getColumnFieldsNestedHeader(config, subtotalData, fieldFormatMap) {
  const columnField = getColumnField(config);
  const columnValues = getColumnValues(columnField, subtotalData);
  let colspan = config.chartValues.length ? columnValues.length * config.chartValues.length : columnValues.length;
  const fieldFormat = fieldFormatMap.get(columnField.fieldName);
  const nestedHeaders = getInitNestedHeaders(config);

  nestedHeaders[0] = nestedHeaders[0].concat([getColumnFieldNestedHeader(columnField, colspan)]);

  if (config.chartValues.length) {
    colspan = config.chartValues.length;
    nestedHeaders[1] = nestedHeaders[1].concat(
      columnValues.map(columnValue => getColumnValueNestedHeader(columnField, columnValue, colspan, fieldFormat))
    );
  }

  return nestedHeaders;
}

function getColumnFieldNestedHeader(columnField, colspan) {
  return {
    field: getNewField(columnField.fieldName, columnField.displayName, columnField.type),
    colspan: colspan,
  };
}

function getColumnValueNestedHeader(columnField, columnValue, colspan, fieldFormat) {
  const formattedColumnValue = getFormattedValue(columnValue, columnField.type, fieldFormat);
  return {
    field: getNewField(columnValue, formattedColumnValue, columnField.type),
    colspan: colspan,
  };
}

function getCountColumnIds(summaryTable) {
  const headers = summaryTable.headers();
  const countLabel = i18n.t("_SummaryTable.Count.Label_");
  const filteredHeaders = headers.filter(header => header.displayName() === countLabel);

  return filteredHeaders.map(header => header.name());
}

function appendNestedHeaders(nestedHeaders, newNestedHeaders) {
  return nestedHeaders.map((headerRow, index) => headerRow.concat(newNestedHeaders[index]));
}

function rowFieldsHeaders(config) {
  const headers = config.chartRows.map((rowField, index) => {
    const displayName = fieldDisplayName(rowField.fieldName, rowField.displayName) || rowField.fieldName;
    return getNewField("col" + (index + 1), displayName, rowField.type);
  });
  return headers;
}

function valueFieldsHeaders(config, subtotalData, fieldFormatMap) {
  const columnField = getColumnField(config);
  let headers = [];
  let valueColumnMap = {};
  let fieldIndex = config.chartRows.length + 1;
  if (columnField) {
    subtotalData.forEach(subtotal => {
      const columnValue = subtotal[columnField.fieldName];
      if (config.chartValues.length) {
        const valueHeaders = getValueHeaders(config, fieldIndex);
        valueColumnMap = { ...valueColumnMap, ...getValueColumnMap(valueHeaders, columnValue) };
        headers = headers.concat(valueHeaders);
        fieldIndex += valueHeaders.length;
      } else {
        const fieldFormat = fieldFormatMap.get(columnField.fieldName);
        const formattedColumnValue = getFormattedValue(subtotal[columnField.fieldName], columnField.type, fieldFormat);
        headers.push(getNewField("col" + fieldIndex, formattedColumnValue, columnField.type));
        fieldIndex++;
      }
    });
  } else {
    const valueHeaders = getValueHeaders(config, fieldIndex);
    valueColumnMap = { ...valueColumnMap, ...getValueColumnMap(valueHeaders) };
    headers = headers.concat(valueHeaders);
    fieldIndex += valueHeaders.length;
  }
  return { headers, valueColumnMap };
}

function getValueHeaders(config, fieldIndex) {
  return config.chartValues.map((valueField, index) => {
    const fieldName = "col" + (fieldIndex + index);
    let displayName;
    if (valueField.aggregationType === "count") {
      displayName = i18n.t("_SummaryTable.Count.Label_");
      return getNewField(fieldName, i18n.t("_SummaryTable.Count.Label_"), "numeric");
    }
    displayName = i18n.t("_SummaryTable.SumOf.Label_", { fieldName: getFieldDisplayName(valueField) });
    return getNewField(fieldName, displayName, "numeric");
  });
}

function getValueColumnMap(valueHeaders, columnValue) {
  const valueMap = {};
  valueHeaders.forEach((valueHeader, index) => {
    const accessorKey = {};
    if (columnValue !== undefined) accessorKey.columnValue = columnValue;
    accessorKey.value = "__valueField" + index;
    valueMap[JSON.stringify(accessorKey)] = valueHeader.name();
  });
  return valueMap;
}

function getConfig(summaryTable) {
  const rawData = summaryTable.rawData();
  return rawData.config;
}

function getNewField(name, displayName, type) {
  return new Field()
    .name(name)
    .displayName(fieldDisplayName(name, displayName))
    .type(type);
}

function fieldDisplayName(fieldName, displayName) {
  const metadataFieldNames = [
    "metadata.assignee",
    "metadata.closed_at",
    "metadata.group",
    "metadata.priority",
    "metadata.publish_date",
    "metadata.publisher",
    "metadata.status",
    "metadata.updated_at",
  ];
  if (metadataFieldNames.includes(fieldName)) {
    return i18n.t(`_Table.${fieldName}.DisplayName_`);
  }
  return displayName;
}

function getColumnField(config) {
  return config.chartColumns.length ? config.chartColumns[0] : undefined;
}

function getFormattedValue(value, type, fieldFormat) {
  const fieldFormatJson = fieldFormat && fieldFormat.toJson();

  let utcOffsetMinutes = 0;
  if (type == "dateTime" || type == "datetime" || type == "DT") {
    utcOffsetMinutes = GlobalValueFormatter.getTimezoneOffset().utcOffsetMinutes();
  }

  return isNil(value)
    ? i18n.t("_Filter.BlankValue.Label_")
    : TableDataFormatter.formatValue(value, type, fieldFormatJson, false, utcOffsetMinutes);
}

function getSummarizedData(summaryTable) {
  const rawData = summaryTable.rawData();
  return rawData.values.summarizedData;
}

function getSubtotalData(summaryTable) {
  const rawData = summaryTable.rawData();
  return rawData.values.subtotalData;
}

function getColumnId(valueColumnMap, columnValue, valueFieldIndex) {
  const key = { columnValue: columnValue, value: "__valueField" + valueFieldIndex };
  return valueColumnMap[JSON.stringify(key)];
}

function getColumnValues(columnField, subtotalData) {
  return subtotalData.map(subtotal => (columnField ? subtotal[columnField.fieldName] : undefined));
}

function getHeaderRowspan(config) {
  let rowspan = 1;
  if (config.chartColumns.length) {
    rowspan += 1;
    if (config.chartValues.length) {
      rowspan += 1;
    }
  }
  return rowspan;
}

function getFieldDisplayName(field) {
  return field.displayName || field.fieldName;
}
