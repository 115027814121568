import find from "lodash/find";
import i18n from "@viz-ui/i18n/i18n";
import MetricTimePeriods from "./metricTimePeriodsService";
import DataTypeFunctions from "./dataTypeFunctions";

class MetricNamer {
  getName(metric, table) {
    if (metric.isNew()) {
      return this.getDefaultMetricName(metric.metricConfig(), table);
    }

    return metric.name();
  }

  getDefaultMetricName(metricConfig, table) {
    const field = table.fieldByName(metricConfig.fieldName());
    const fieldStr = this.getFieldString(field);
    const funcStr = this.getFunctionString(metricConfig.func(), field);
    const periodStr = this.getPeriodString(metricConfig.interval());

    if (fieldStr.length && funcStr.length && periodStr.length) {
      return i18n.t("_MetricsConfig.DefaultName.Value_", {
        field: fieldStr,
        function: funcStr,
        period: periodStr,
      });
    }

    return "";
  }

  getFieldString(field) {
    if (field) {
      return field.displayName();
    }
    return "";
  }

  getFunctionString(funcName, field) {
    if (field) {
      const functions = DataTypeFunctions.getFunctionsByDataType(field.type());
      const func = find(functions, item => item.fieldName === funcName);
      if (func) {
        return func.displayName;
      }
    }
    return "";
  }

  getPeriodString(periodName) {
    const periods = MetricTimePeriods.getMetricTimePeriods();
    const period = find(periods, item => item.fieldName === periodName);
    if (period) {
      return period.displayName;
    }
    return "";
  }
}

export default new MetricNamer();
