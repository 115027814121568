import ColumnConfig from "./columnConfig";

function deserializeColumnConfig(columnConfigData) {
  return new ColumnConfig()
    .name(columnConfigData.fieldName)
    .visible(columnConfigData.visible)
    .wordWrap(columnConfigData.wordWrap);
}

export default {
  deserializeColumnConfig,
};
