import UrlFlipper from "@viz-ui/services/urlFlipper/urlFlipper";

const EXPORT_PADDING_HEIGHT = 12;
const MAX_ROW_HEIGHT_A4 = 690;
const DEFAULT_ROW_HEIGHT = 350;
const isExportMode = UrlFlipper.isRenderMode("export");
const isChartFitToPage = UrlFlipper.isEnabled("chartFitToPage");

export default class ExportAdapter {
  static getExportRowConfigs(rowConfigs, rows = 0) {
    if (isExportMode) {
      const exportRowConfigs = [];
      for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
        const rowConfig = rowConfigs[rowIndex] || { height: DEFAULT_ROW_HEIGHT };
        rowConfig.height = isChartFitToPage
          ? MAX_ROW_HEIGHT_A4 - EXPORT_PADDING_HEIGHT
          : rowConfig.height - EXPORT_PADDING_HEIGHT;
        exportRowConfigs.push(rowConfig);
      }
      return exportRowConfigs;
    }
    return rowConfigs;
  }

  static getExportPositions(positions) {
    return isExportMode && isChartFitToPage
      ? positions.map((p, idx) => {
          return { id: p.id, row: idx, col: 0 };
        })
      : positions;
  }
}
