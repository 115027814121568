angular
  .module("acl.visualizer.charts.controllers")
  .controller("LineChartController", function($filter, $scope, InheritController, LineChart, ChartService) {
    var base = InheritController("ChartBaseController", $scope)
      .override("chartType", "LineChart")
      .override("defaultDisplayConfig", LineChart.defaultDisplayConfig)
      .override("getChartDirectiveConfig", LineChart.getChartDirectiveConfig)
      .override("populateChartConfigColumnDefs", LineChart.populateChartConfigColumnDefs)
      .override("chartConfigColumnDefs", LineChart.chartConfigColumnDefs)
      .override("isValidDataConfig", LineChart.isValidDataConfig)
      .override("chartZoomIn", function(keyArray) {
        var xFormatter = ChartService.getFormatterForType($scope.chartConfig.dataConfig.chartXAxis.type);
        var filters = [];
        if (keyArray.length > 1) {
          var seriesFormatter = ChartService.getFormatterForType($scope.chartConfig.dataConfig.chartSeries.type);
          filters.push({ field: $scope.chartConfig.dataConfig.chartSeries, value: seriesFormatter(keyArray[0]) });
        }
        filters.push({
          field: $scope.chartConfig.dataConfig.chartXAxis,
          value: xFormatter(keyArray[keyArray.length - 1]),
        });
        $scope.tab.callbackInterface.onZoomIn(filters);
      })
      .override("getKeyColor", config => config && ChartService.getKeyColor(config.colorByField, config.colorMapping));

    base.initChart();
  });
