import services from "../../../services/glue/appGlue";

class StoryboardShareHeader extends React.Component {
  render() {
    return (
      <div className="panel__header">
        <h2 className="panel__title">{services.Localize.getLocalizedString("_Storyboards.SharePanel.Header_")}</h2>
      </div>
    );
  }
}

window.StoryboardShareHeader = StoryboardShareHeader;
