class Observer {
  constructor() {
    this._observers = [];
  }

  subscribe(callback) {
    this._observers.push(callback);
    // return callback to unsubscribe
    return () => this.unsubscribe(callback);
  }

  unsubscribe(callback) {
    const index = this._observers.findIndex(observerCallback => observerCallback === callback);
    if (index >= 0) {
      this._observers.splice(index, 1);
      return true;
    }
    return false;
  }

  broadcast(...data) {
    this._observers.forEach(callback => callback(...data));
  }
}

export default Observer;
