import React, { Component } from "react";
import PropTypes from "prop-types";
import Spinner from "acl-ui/components/Spinner";
import FilterConfigPanel from "./filterConfigPanel";
import FieldSelectSection from "./fieldSelectSection/fieldSelectSection";
import NameSection from "./nameSection/nameSection";
import FormatSection from "./formatSection/formatSection";
import FilterTypeSection from "./filterTypeSection/filterTypeSection";
import i18n from "@viz-ui/i18n/i18n";
import FieldFormat from "@viz-ui/models/field/fieldFormat";
import FieldFormatAdapter from "@viz-ui/models/field/fieldFormatAdapter";
import StoryboardFilter from "@viz-ui/models/storyboardFilter/storyboardFilter";
import Table from "@viz-ui/models/table/table";
import FilterFieldService from "@viz-ui/services/storyboardFilter/filterFieldService";
import UsageTracker from "@viz-ui/services/tracking/usageTracker";

export default class FilterConfigPanelContainer extends Component {
  static propTypes = {
    filterModel: PropTypes.instanceOf(StoryboardFilter).isRequired,
    flipConditional: PropTypes.bool.isRequired,
    flipMultiselect: PropTypes.bool.isRequired,
    flipUnformattedValues: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    saveButtonLabel: PropTypes.string.isRequired,
    tableModels: PropTypes.arrayOf(PropTypes.instanceOf(Table)).isRequired,
    title: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const fieldFormatModel = this.props.filterModel.fieldFormat();
    const fieldFormatObject = fieldFormatModel ? fieldFormatModel.toJson() : null;
    const fields = FilterFieldService.findFields(this.props.filterModel.filterFields(), this.props.tableModels);

    this.state = {
      fieldModels: fields,
      filterName: this.props.filterModel.displayName(),
      nameOptions: fields ? this.getNameOptions(fields) : null,
      fieldFormatObject: fieldFormatObject,
      type: this.props.filterModel.type(),
      fieldType: fields && fields.length > 0 ? fields[0].type() : null,
      submitDisabled: !fields || fields.length === 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    const fieldFormatModel = nextProps.filterModel.fieldFormat();
    const fieldFormatObject = fieldFormatModel ? fieldFormatModel.toJson() : null;
    const fields = FilterFieldService.findFields(nextProps.filterModel.filterFields(), nextProps.tableModels);

    this.setState({
      fieldModels: fields,
      filterName: nextProps.filterModel.displayName(),
      nameOptions: fields ? this.getNameOptions(fields) : null,
      fieldFormatObject: fieldFormatObject,
      type: nextProps.filterModel.type(),
      fieldType: fields && fields.length > 0 ? fields[0].type() : null,
      submitDisabled: !fields || fields.length === 0,
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    UsageTracker.createEvent(`.filterpanel.configpanel${this.props.filterModel.isNew() ? ".addfilter" : ".saved"}`);

    const saveFilterModel = this.props.filterModel
      .clone()
      .displayName(this.state.filterName)
      .fieldType(this.state.fieldType)
      .filterFields(FilterFieldService.convertFields(this.state.fieldModels))
      .type(this.state.type)
      .value(null)
      .values(null);

    if (this.state.fieldFormatObject) {
      saveFilterModel.fieldFormat(FieldFormat.fromJson(this.state.fieldFormatObject));
    } else {
      saveFilterModel.fieldFormat(new FieldFormat());
    }
    this.props.onSubmit(saveFilterModel);
  };

  handleClose = e => {
    e.preventDefault();
    this.props.onClose();
  };

  handleFilterNameChange = e => {
    this.setState({ filterName: e.target.value });
    UsageTracker.createEvent(".filterpanel.configpanel.namechanged");
  };

  handleFieldFormatChange = newFieldFormatObject => {
    this.setState({ fieldFormatObject: newFieldFormatObject });
    UsageTracker.createEvent(".filterpanel.configpanel.formatchanged", { format: newFieldFormatObject });
  };

  handleFieldsChange = newFields => {
    const newState = {
      fieldModels: newFields,
      nameOptions: this.getNameOptions(newFields),
      submitDisabled: !newFields || newFields.length === 0,
    };

    const fieldType = newFields.length > 0 ? newFields[0].type() : null;

    // Since some properties are dependent on the fields need to reset them if changed.
    if (fieldType !== this.state.fieldType) {
      newState.fieldType = fieldType;
      newState.fieldFormatObject = null;
    }
    if (!newFields || newFields.length === 0) {
      newState.filterName = null;
    } else if (newFields.length > 0 && !newFields.some(item => item.displayName() === this.state.filterName)) {
      newState.filterName = newFields[0].displayName();
    }

    this.setState(newState);
  };

  handleFilterTypeChange = newType => {
    this.setState({ type: newType });
  };

  getNameOptions = fields => {
    const flatFields = fields.map(field => field.displayName());
    const uniqueFields = [];
    flatFields.forEach(item => {
      if (!uniqueFields.includes(item)) uniqueFields.push(item);
    });
    return uniqueFields;
  };

  renderSpinner = () => {
    if (!(this.props.tableModels.length > 0))
      return (
        <div className="filter-config__spinner">
          <Spinner />
        </div>
      );
    return null;
  };

  render() {
    return (
      <FilterConfigPanel
        disabled={this.state.submitDisabled}
        onClose={this.handleClose}
        onSubmit={this.handleSubmit}
        saveButtonLabel={this.props.saveButtonLabel}
        title={this.props.title}
      >
        {this.renderSpinner()}
        <FieldSelectSection
          className="filter-config__section"
          onChange={this.handleFieldsChange}
          selectedList={this.state.fieldModels}
          tables={this.props.tableModels}
        />
        <NameSection
          className="filter-config__section"
          name={"filterName"}
          onChange={this.handleFilterNameChange}
          options={this.state.nameOptions}
          title={i18n.t("_GlobalFilter.FilterName.Label_")}
          value={this.state.filterName}
        />
        <FormatSection
          className="filter-config__section"
          fieldFormatObject={this.state.fieldFormatObject}
          fieldType={this.state.fieldType}
          flipUnformattedValues={this.props.flipUnformattedValues}
          name={"fieldFormat"}
          onChange={this.handleFieldFormatChange}
          title={i18n.t("_GlobalFilter.FilterFormat.Label_")}
        />
        <FilterTypeSection
          className="filter-config__section"
          flipConditional={this.props.flipConditional}
          flipMultiselect={this.props.flipMultiselect}
          isConditionalDisabled={this.state.fieldType === "logical"}
          name={"filterType"}
          onChange={this.handleFilterTypeChange}
          title={i18n.t("_GlobalFilter.ChooseFilterType.Label_")}
          value={this.state.type}
        />
      </FilterConfigPanel>
    );
  }
}
