import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "element-closest";
import Collapsible from "acl-ui/components/Collapsible/Collapsible";
import FilterWidgetTitleBar from "./filterWidgetTitleBar";
import "./filterWidget.scss";
import L10n from "acl-ui/components/L10n/L10n";
import i18n from "@viz-ui/i18n/i18n";

export default class FilterWidget extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    displayName: PropTypes.string.isRequired,
    hasOverflow: PropTypes.bool,
    isActive: PropTypes.bool,
    isEditDisabled: PropTypes.bool,
    isHighlighted: PropTypes.bool,
    onActiveClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onEditClick: PropTypes.func,
  };

  static defaultProps = {
    isActive: true,
    isEditDisabled: false,
    onClickItem: () => {},
    onDeleteClick: () => {},
    onEditClick: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: false,
    };
  }

  onCollapsibleClicked = event => {
    if (
      !event.target.closest(".filter-widget__titlebar-overflow") &&
      !event.target.closest(".filter-widget__titlebar-switch")
    ) {
      this.setState({ isCollapsed: !this.state.isCollapsed });
    }
  };

  getLabel = () => {
    const titleBarProps = {
      displayName: this.props.displayName,
      hasOverflow: this.props.hasOverflow,
      isActive: this.props.isActive,
      onActiveClick: this.props.onActiveClick,
      onDeleteClick: this.props.onDeleteClick,
      onEditClick: this.props.onEditClick,
      isEditDisabled: this.props.isEditDisabled,
    };
    return <FilterWidgetTitleBar {...titleBarProps} />;
  };

  render() {
    const rootClasses = classNames("filter-widget", this.props.className, {
      "is-highlighted": this.props.isHighlighted,
    });

    return (
      <div className={rootClasses}>
        <L10n locale={i18n.locale}>
          <Collapsible label={this.getLabel()} isCollapsed={this.state.isCollapsed} onClick={this.onCollapsibleClicked}>
            {this.props.children}
          </Collapsible>
        </L10n>
      </div>
    );
  }
}
