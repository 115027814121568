import PropTypes from "prop-types";
import React from "react";
import services from "../../../services/glue/appGlue";

class UsersListRow extends React.Component {
  constructor(props) {
    super(props);

    // binding handler functions
    this.handleRemoveUser = this.handleRemoveUser.bind(this);
    this.handleOpenDropdown = this.handleOpenDropdown.bind(this);
    this.handleCloseDropdown = this.handleCloseDropdown.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);

    this.mouseLeft = false;
    this.state = {
      editMode: false,
    };
  }

  handleRemoveUser(assignmentId) {
    return () => {
      if (assignmentId) {
        //flag set as true as we need to refresh the list after removing the user
        this.props.onRemoveUser(assignmentId, true);
      }
    };
  }

  handleOpenDropdown() {
    this.props.onOpenDropdown();
  }

  handleCloseDropdown(newRole) {
    let assignmentId = this.props.user.assignmentId;
    let currentRole = this.props.user.role;
    if (assignmentId && newRole !== currentRole) {
      this.props.onPermissionChange(assignmentId, newRole);
    }
    this.props.onCloseDropdown();
    if (this.mouseLeft) {
      this.toggleEditMode(false)();
    }
  }

  handleMouseEnter() {
    this.mouseLeft = false;
    this.toggleEditMode(true)();
  }

  handleMouseLeave() {
    this.mouseLeft = true;
    this.toggleEditMode(false)();
  }

  shouldShowRemoveBtn() {
    let user = this.props.user;
    return this.state.editMode && user.role !== "admin" && user.role !== "creator";
  }

  toggleEditMode(editMode) {
    return e => {
      if (this.props.shouldToggleEditMode) {
        $(ReactDOM.findDOMNode(this.refs.row)).toggleClass("edit-mode", editMode);
        this.setState({ editMode: editMode });
      }
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.shouldToggleEditMode;
  }

  getPermissionField() {
    let permissionField;
    let user = this.props.user;
    switch (user.role) {
      case "admin":
        permissionField = (
          <span>{services.Localize.getLocalizedString("_Storyboards.SharePanel.MembersTable.AccountAdmin_")}</span>
        );
        break;
      case "creator":
        permissionField = (
          <span>{services.Localize.getLocalizedString("_Storyboards.SharePanel.MembersTable.Creator_")}</span>
        );
        break;
      default:
        if (this.state.editMode) {
          permissionField = (
            <span className="update">
              <RoleSelect
                assignmentId={user.assignmentId}
                selectedRole={user.role}
                onOpenDropdown={this.handleOpenDropdown}
                onCloseDropdown={this.handleCloseDropdown}
              />
            </span>
          );
        } else {
          let roleKey = user.role.charAt(0).toUpperCase() + user.role.slice(1);
          permissionField = (
            <span className="view">
              {services.Localize.getLocalizedString(
                "_Storyboards.SharePanel.MembersTable.Permissions." + roleKey + "_"
              )}
            </span>
          );
        }
    }
    return permissionField;
  }

  getRemoveUserField() {
    let removeUserField;
    let user = this.props.user;
    if (this.shouldShowRemoveBtn()) {
      removeUserField = <i className="acl-i-times-circle" onClick={this.handleRemoveUser(user.assignmentId)} />;
    }
    return removeUserField;
  }

  render() {
    return (
      <tr
        ref="row"
        key={this.props.user.assignmentId}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <td className="share-storyboard-table__primary-info">{this.props.user.name}</td>
        <td className="share-storyboard-table__secondary-info">{this.props.user.email}</td>
        <td className="share-storyboard-table__secondary-info permission">{this.getPermissionField()}</td>
        <td className="share-storyboard-table__secondary-info remove-user">{this.getRemoveUserField()}</td>
      </tr>
    );
  }
}

UsersListRow.propTypes = {
  user: PropTypes.shape({
    assignmentId: PropTypes.number,
    userId: PropTypes.number,
    groupId: PropTypes.number,
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  shouldToggleEditMode: PropTypes.bool,
  onOpenDropdown: PropTypes.func,
  onCloseDropdown: PropTypes.func,
  onPermissionChange: PropTypes.func,
  onRemoveUser: PropTypes.func,
};

window.UsersListRow = UsersListRow;
