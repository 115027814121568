import cloneDeep from "lodash/cloneDeep";

export default class InterpretationDataStore {
  static _canDrilldown = new Map();
  static _interpretations = new Map();
  static _interpretationErrors = new Map();

  static addInterpretation(interpretationId, interpretation) {
    this._interpretations.set(interpretationId, { ...interpretation, ...{ id: interpretationId } });
  }

  static getInterpretation(interpretationId) {
    if (this.hasInterpretationError(interpretationId)) return;
    return this._interpretations.get(interpretationId);
  }

  static getInterpretations() {
    return this._interpretations;
  }

  static addInterpretationError(interpretationId, error) {
    this._interpretationErrors.set(interpretationId, error);
  }

  static getInterpretationError(interpretationId) {
    return this._interpretationErrors.get(interpretationId);
  }

  static hasInterpretationError(interpretationId) {
    return this._interpretationErrors.has(interpretationId);
  }

  static setCanDrilldown(interpretationId, canDrilldown) {
    this._canDrilldown.set(interpretationId, canDrilldown);
  }

  static canDrilldown(interpretationId) {
    return this._canDrilldown.get(interpretationId);
  }

  static getDrilldownMap() {
    return this._canDrilldown;
  }
}
