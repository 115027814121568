import moment from "moment";
import i18n from "@viz-ui/i18n/i18n";
import SectionService from "./sectionService";
import { TimeFormatter } from "@acl-services/sriracha-formatters/dist/Formatters";

export default function(fieldFormatObject, unformattedValues) {
  const formattingObject = fieldFormatObject || {};

  if (!unformattedValues) {
    return [];
  }

  return [
    {
      items: getTimeFormattingOptions(formattingObject),
    },
  ];
}

function getTimeFormattingOptions(selectedFormattingObj) {
  var exampleTime = moment(new Date(new Date().getFullYear(), 0, 31, 17, 30, 0)).format("H:mm:ss");
  var formats = [
    { isRaw: true, timeFormat: undefined },
    { isRaw: false, timeFormat: SectionService.getLocalizedTimeFormat() }, // Default format.
  ];

  return formats.map(function(fieldFormatJson) {
    var example = fieldFormatJson.isRaw
      ? i18n.t("_Format.Time.Raw.Example_")
      : TimeFormatter.format(exampleTime, fieldFormatJson);

    return {
      example: example,
      label: i18n.t(getTimeFormatLabelKey(fieldFormatJson)),
      selected: SectionService.compareFormattingOptions(fieldFormatJson, selectedFormattingObj),
      format: fieldFormatJson,
    };
  });
}

function getTimeFormatLabelKey(fieldFormatJson) {
  var template = key => `_Format.Time.${key}.Label_`;
  if (fieldFormatJson) {
    if (fieldFormatJson.isRaw) {
      return template("Raw");
    }
  }
  return template("Default");
}
