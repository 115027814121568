import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import Toast from "@paprika/toast";

window.AclNotification = createReactClass({
  propTypes: {
    index: PropTypes.number,
    onClose: PropTypes.func,
    text: PropTypes.string,
    type: PropTypes.string,
  },

  handleClose() {
    const { onClose, index } = this.props;

    onClose(index);
  },

  renderNotification() {
    const { text } = this.props;

    if (!text) return;

    return (
      <Toast kind={this.props.type} onClose={this.handleClose} hasCloseButton={!!this.props.onClose}>
        {text}
      </Toast>
    );
  },

  render() {
    return <div className={`acl-notification ${this.props.type}`}>{this.renderNotification()}</div>;
  },
});
