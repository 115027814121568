const aclAggregationSelector = {
  templateUrl: "visualizer/js/modules/core/aggregationSelector/aggregationSelector.tpl.html",
  bindings: {
    aggregationType: "<",
    aggregationFieldName: "<",
    aggregationFields: "<",
    dataConfigField: "<",
    fieldNameToFieldObj: "<",
    onChange: "&",
  },
  controllerAs: "aggregationSelector",
  controller: class AggregationSelectorController {
    constructor(DataModel, Localize) {
      "ngInject";

      this._DataModel = DataModel;
      this._Localize = Localize;

      this.countOrFieldAggregation = "fieldAggregation";
      this._previousFieldAggregationType = null;
      this.viewModel = { aggregationType: null, aggregationFieldName: null };
    }

    $onChanges(changesObj) {
      if (changesObj.aggregationType) {
        const aggregationType = changesObj.aggregationType.currentValue;
        this.viewModel.aggregationType = aggregationType;
        this._updateCountOrFieldAggregation(aggregationType);
        this._updatePreviousFieldAggregationType(aggregationType);
      }

      if (changesObj.aggregationFieldName) {
        this.viewModel.aggregationFieldName = changesObj.aggregationFieldName.currentValue || "";
      }
    }

    _updateCountOrFieldAggregation(newAggregationType) {
      if (newAggregationType === "count") {
        this.countOrFieldAggregation = "count";
      } else {
        this.countOrFieldAggregation = "fieldAggregation";
      }
    }

    _updatePreviousFieldAggregationType(newAggregationType) {
      if (newAggregationType !== "count") {
        this._previousFieldAggregationType = newAggregationType;
      }
    }

    onCountRadioButtonClick() {
      this.viewModel.aggregationType = "count";
      this._onChange();
    }

    onFieldAggregationRadioButtonClick() {
      this.viewModel.aggregationType = this._previousFieldAggregationType;
      this._onChange();
    }

    onAggregationTypeButtonClick() {
      const newValue = this.viewModel.aggregationType;
      this._updatePreviousFieldAggregationType(newValue);
      this._onChange();
    }

    onAggregationFieldNameChange(selectedField) {
      this.viewModel.aggregationFieldName = selectedField;
      this._onChange();
    }

    _onChange() {
      if (this.onChange) {
        this.onChange({ type: this.viewModel.aggregationType, fieldName: this.viewModel.aggregationFieldName });
      }
    }

    isValidField(fieldName) {
      return this._DataModel.table.fieldExists(fieldName);
    }

    fieldDisplayName(selectedField) {
      if (!selectedField || !selectedField.fieldName) return "";

      if (!this.isValidField(selectedField.fieldName)) {
        return this._Localize.getLocalizedString("_ChartConfig.CloseButton.DeletedOption_");
      }

      return selectedField.displayName;
    }
  },
};

export default aclAggregationSelector;
