import cloneDeep from "lodash/cloneDeep";
import tokens from "@paprika/tokens";
import { HighChartDirective, HighChartController } from "../highChartDirective";
import { PIE_CHART } from "../boost/boostThresholds";

class PieChartDirective extends HighChartDirective {
  controller = Controller;
  static factory = () => new PieChartDirective();
}

class Controller extends HighChartController {
  static configPath = {
    labelFontSize: [
      "legend.itemStyle.fontSize",
      "plotOptions.series.dataLabels.style.fontSize",
      "tooltip.style.fontSize",
    ],
  };

  constructor($scope, $timeout, HighChartLabelSizer, Localize) {
    "ngInject";
    super($scope, $timeout, HighChartLabelSizer);
    this.Localize = Localize;
  }

  onLegendItemClick = item => {
    const series = cloneDeep(this.hcConfig.series);
    let showAll = !series[0].data.some(entry => entry.visible && entry.name !== item.name);
    series[0].data = series[0].data.map(entry =>
      angular.extend({}, entry, {
        visible: showAll || (entry.name === item.name ? !item.visible : entry.visible),
      })
    );
    this.updateHcConfig({ series });
  };

  onLegendItemDoubleClick = item => {
    const series = cloneDeep(this.hcConfig.series);
    series[0].data = series[0].data.map(seriesData =>
      angular.extend({}, seriesData, { visible: seriesData.name === item.name })
    );
    this.updateHcConfig({ series });
  };

  getConfig = (config, data) => {
    let totalTextToShowInChart = "";
    let localeTotalString = this.Localize.getLocalizedString("_Chart.StatisticsViz.Total.Label_");
    let hcConfig = {
      legend: {
        enabled: config.showLegend,
        align: "right",
        verticalAlign: "top",
        labelFormatter: function() {
          return config.labelFormatter && this.options.name !== "(blank)"
            ? config.labelFormatter(this.options.legendDisplayName)
            : this.options.legendDisplayName;
        },
      },
      boost: this.getBoostConfig(config),
      chart: {
        events: {
          render: function() {
            if (config.showTotals) {
              let total = 0;
              this.series[0].data.forEach(value => {
                total += value.visible ? value.value : 0;
              });
              total = total % 1 === 0 ? total : total.toFixed(2); // if total is integer return integer else return float fixed to decimal.
              const totalString = `<span style="font-size:.95rem;color:#666;font-weight:600;">${localeTotalString.toUpperCase()}: ${total}</span>`;
              const left = this.chartWidth * 0.65; // moving total position 65% of the width of the chart
              const top = this.chartHeight * 0.99; //moving the total to 99% of the total height of the chart to keep it responsive.
              if (totalTextToShowInChart) {
                totalTextToShowInChart.attr({ text: "" });
              }
              totalTextToShowInChart = this.renderer
                .text(totalString, left, top)
                .attr({
                  zIndex: 5,
                })
                .add();
            }
          },
        },
      },
      plotOptions: {
        series: {
          events: {},
          point: {
            events: {
              click: this.clickWrapper(null, point => {
                this.zoomIn([point.name]);
              }),
              legendItemClick: this.clickWrapper(this.onLegendItemClick, this.onLegendItemDoubleClick),
            },
          },
          dataLabels: {
            enabled: config.displayDataLabels || config.showPercentageTicks,
            formatter: function() {
              let percentLabel = config.displayDataLabels
                ? ` (${this.percentage.toFixed(2)}%)`
                : `${this.percentage.toFixed(2)}%`;
              return `${config.displayDataLabels ? config.valueFormatter(this.point.value) : ""}${
                config.showPercentageTicks ? percentLabel : ""
              }`;
            },
            distance: 20,
          },
          tooltip: {
            headerFormat: "",
            pointFormatter: function() {
              let header = `
                <span style="text-decoration: underline grey;">${
                  config.labelFormatter && this.name !== "(blank)"
                    ? config.labelFormatter(this.legendDisplayName)
                    : this.legendDisplayName
                }</span>
                <br/>
              `;
              let body = `<b>${config.valueFormatter(this.value)} (${this.percentage.toFixed(2)}%)</b>`;
              return header + body;
            },
          },
          turboThreshold: 0,
          animation: this.canAnimate(),
        },
      },
      // Adding legendDisplayName property to handle (blank) value  & to localize the string (issue GRCPRD-4135)
      series: [
        data && {
          type: "pie",
          showInLegend: true,
          name: config.colorByField,
          boostThreshold: PIE_CHART,
          dataLabels: {
            connectorColor:
              !this.getBoostConfig(config, data.length >= PIE_CHART).enabled &&
              this.$scope.isPatternFill &&
              tokens.border.color,
          },
          data: data.map((value, index) => ({
            color:
              !this.getBoostConfig(config, data.length >= PIE_CHART).enabled && this.$scope.isPatternFill
                ? this.patternFillPalettes(index)
                : value.color,
            name: value.key,
            legendDisplayName:
              value.key === "(blank)" ? this.Localize.getLocalizedString("_Filter.BlankValue.Label_") : value.key,
            y: Math.abs(value.values),
            value: value.values,
            visible: value.visible !== false,
          })),
          innerSize: config.donut ? `${config.donutRatio * 100}%` : null,
        },
      ],
    };
    hcConfig = this.updateLabelFontSize(hcConfig, Controller.configPath.labelFontSize);
    return hcConfig;
  };

  zoomIn = keyArray => {
    if (angular.isFunction(this.$scope.zoomInHandler)) {
      this.$scope.zoomInHandler(keyArray);
    }
  };
}

export default PieChartDirective;
