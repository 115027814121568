import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";
import numeral from "numeral";

export const aclNewlines = text => {
  const result = text || "";
  return result.replace(/\n/g, "<br/>");
};
export const aclEscapeHtml = text => {
  const result = text || "";
  return result
    .replace(/&/g, "&amp;")
    .replace(/>/g, "&gt;")
    .replace(/</g, "&lt;")
    .replace(/'/g, "&apos;");
};
export const aclEncodeURI = text => encodeURI(text);

export const aclEncodeURIComponent = text => encodeURIComponent(text);

export const aclLocalizeFieldValue = (value, fieldFormatIdentifier, fieldName) =>
  GlobalValueFormatter.formatValue(value, fieldFormatIdentifier, fieldName);

export const numeralFormatter = (value, decimalSpace) => {
  if (isNaN(value)) return value;

  let formatter = "0";
  if (decimalSpace > 0) {
    formatter += ".[";
    for (let i = 0; i < decimalSpace; i++) {
      formatter += "0";
    }
    formatter += "]";
  }

  return numeral(value).format(formatter + "a");
};
