import React, { useRef } from "react";
import PropTypes from "prop-types";
import ListBox from "@paprika/list-box";
import Button from "@paprika/button";
import i18n from "@viz-ui/i18n/i18n";
import AddIcon from "@paprika/icon/lib/Add";
import "./addFilterDropdown.scss";
import DataTypeIcon from "./dataTypeIcon";

const { arrayOf, func, oneOfType, string, bool } = PropTypes;

const addFilterDropdown = props => {
  const { sortFieldOption, onClick } = props;

  const refListBox = useRef(null);

  const handleClick = value => {
    onClick({ field: value });
  };

  const renderTrigger = (state, options, { dispatch, types, refTrigger, propsForTrigger }) => (
    <div className="btn-group dropdown">
      <Button
        {...propsForTrigger()}
        id="filter-panel-quick-menu-toggler"
        onClick={togglePopover(dispatch, types)}
        kind="default"
        isFullWidth
        ref={refTrigger}
        icon={<AddIcon size="large" />}
        className="aclui-button aclui-button--default aclui-button--large"
      >
        {i18n.t("_AddFilterDropdown.addFilter.Button_")}
      </Button>
    </div>
  );

  const renderOptions = () =>
    sortFieldOption.map(field => (
      <ListBox.RawItem key={field.id} label={field.name} onClick={() => handleClick(field.id)}>
        <DataTypeIcon type={field.type} />
        <span className="add-filter-dropdown__menu-item-label">{field.name}</span>
      </ListBox.RawItem>
    ));

  const togglePopover = (dispatch, types) => () => {
    dispatch({ type: types.togglePopover });
  };

  return (
    <div>
      <ListBox ref={refListBox}>
        <ListBox.Trigger>{renderTrigger}</ListBox.Trigger>
        <ListBox.Filter
          key="filter"
          Placeholder={i18n.t("_General.FilterField.Placeholder_")}
          noResultsMessage={i18n.t("_General.NoMatches.Label_")}
        />
        {sortFieldOption.length > 0 ? renderOptions() : <ListBox.Divider />}
        <ListBox.Popover zIndex={1001} />
      </ListBox>
    </div>
  );
};

addFilterDropdown.propTypes = {
  onClick: func,
  sortFieldOption: arrayOf(oneOfType(string, string, string, bool)),
};
addFilterDropdown.defaultProps = {
  onClick: () => {},
  sortFieldOption: [],
};

export default addFilterDropdown;
