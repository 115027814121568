import numeral from "numeral";

const Sorter = {
  /**
   * This sort uses the Schwatzian Transform technique however it does
   * not sort in place. Meaning it will not mutate the array but instead
   * returns the sorted array.
   * This is also a stable sort.
   */
  sort(
    items,
    {
      dataType = "character",
      order = "asc",
      unicode = true,
      valueParser = null,
      valuesOrder = [],
      comparator = null,
    } = {}
  ) {
    if (typeof items === "undefined" || items.length <= 1) {
      return items;
    }

    const compareFunction = valuesOrder.length
      ? sortComparator.valuesOrderCompare(valuesOrder)
      : comparator || getComparatorByType(dataType, unicode);

    // Schwartzian transform (decorate-sort-undecorate)
    return items
      .map(decorate(dataType, valueParser, valuesOrder))
      .sort(compareDecorated(compareFunction, dataType, order))
      .map(undecorate);
  },

  /**
   *  Get a comparator that is specific to a type to be used in a sort outside of this service.
   */
  getTypedComparator({ dataType = "character", order = "asc", unicode = true, valueParser = null }) {
    return (a, b) => {
      const decorateFunction = decorate(dataType, valueParser);
      const compareFunction = compareDecorated(getComparatorByType(dataType, unicode), dataType, order);

      return compareFunction(decorateFunction(a), decorateFunction(b));
    };
  },

  /**
   * Sets the locale that will be used by the sort algorithm.
   */
  locale(newLocale) {
    if (typeof Intl !== "undefined") {
      collator = new Intl.Collator(newLocale, collatorOptions);
    }
  },
};

let collator = null;
const collatorOptions = {
  usage: "sort",
  sensitivity: "variant",
  caseFirst: "lower",
};

const sortComparator = {
  defaultCompare: (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  },

  valuesOrderCompare: valuesOrder => (a, b) => {
    const aIndex = valuesOrder.findIndex(value => value === a);
    const bIndex = valuesOrder.findIndex(value => value === b);

    if (aIndex === -1 && bIndex === -1) return 0;
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;

    if (aIndex < bIndex) return -1;
    if (aIndex > bIndex) return 1;
    return 0;
  },

  numeric: (a, b) => {
    // special handling. NumeralJS 2 always gives NaN when comparing infinite Numeral values.
    if (Number.NEGATIVE_INFINITY === a.value() && Number.NEGATIVE_INFINITY !== b.value()) {
      return -Infinity;
    }
    if (Number.NEGATIVE_INFINITY !== a.value() && Number.NEGATIVE_INFINITY === b.value()) {
      return Infinity;
    }

    return a
      .clone()
      .subtract(b.value())
      .value();
  },

  // When comparing large numbers of strings, such as in sorting large arrays,
  // it is better to use Intl.Collator than it would be to use
  // String.prototype.localeCompare()
  unicodeCollationAlgorithm: newCollator => newCollator.compare,

  // This is a cheap technique to accomplish a bit of what the UCA (Unicode
  // Collation Algorithm) does when comparing the case of letters.
  ucaFallback: (a, b) => {
    const lowerA = a ? a.toLowerCase() : "";
    const lowerB = b ? b.toLowerCase() : "";

    if (lowerA === lowerB && a !== b) return a < b ? 1 : -1;
    if (lowerA < lowerB) return -1;
    if (lowerA > lowerB) return 1;
    return 0;
  },
};

/**
 * Retrieves the comparator function that can be used for the datatype.
 */
function getComparatorByType(dataType, unicode = true) {
  switch (dataType) {
    case "numeric":
      return sortComparator.numeric;
    case "character":
    default:
      // (default) unrecognized type case will be interpreted as character.
      if (unicode) {
        if (collator) {
          return sortComparator.unicodeCollationAlgorithm(collator);
        }
        return sortComparator.ucaFallback;
      }
    // Fall through
    case "date":
    case "datetime":
    case "time":
      // Fall through date can be sorted by default when format is YYYY-MM-DD
      return sortComparator.defaultCompare;
  }
}

function numericDecorator(a) {
  return numeral(!Number.isNaN(parseFloat(a)) ? a : Number.NEGATIVE_INFINITY);
}

function blankDecorator(a) {
  // Empty string sorts to the front as desired.
  switch (a) {
    case "(blank)":
    case "":
    case null:
    case undefined:
      return "";
    default:
      return a;
  }
}

function decorate(dataType, valueParser, valuesOrder = []) {
  const valueDecorators = [];

  if (valueParser !== null) {
    // Must be first decorator to get the value.
    valueDecorators.push(valueParser);
  }

  if (!valuesOrder.length) {
    if (dataType === "numeric") {
      valueDecorators.push(numericDecorator);
    }

    valueDecorators.push(blankDecorator);
  }
  return decorateFormat(valueDecorators);
}

function decorateFormat(decorators) {
  return (item, i = 0) => ({
    sortValue: decorators.reduce((accumulator, currentDecorator) => currentDecorator(accumulator), item),
    index: i, // Needed for stable sorting
    originalValue: item,
  });
}

function compareDecorated(compareFunction, dataType, order) {
  const orderVal = order === "desc" ? -1 : 1;

  return (a, b) => {
    const sorted = compareFunction(a.sortValue, b.sortValue);

    if (sorted === 0) {
      // Compare the index to maintain a stable sort. Been decided that stable
      // sort will not change based on order so will be based just on original
      // order.
      return a.index - b.index;
    }
    return sorted * orderVal;
  };
}

function undecorate(item) {
  return item.originalValue;
}

export default Sorter;
