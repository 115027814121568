import DataModel from "@viz-ui/services/charts/DataModel/dataModelService";
import EventService from "@viz-ui/services/eventService/eventService";

class HistogramChartService {
  constructor() {
    this.eventService = EventService.register("acl.biView");
  }

  createHistogramViewConfig(fieldData) {
    return {
      chartXAxis: {
        displayName: fieldData.displayName,
        fieldName: fieldData.fieldName,
        type: fieldData.type,
      },
      chartValue: {
        fieldName: "",
        type: "numeric",
        aggregationType: "count",
      },
    };
  }

  loadData(config) {
    const filterConfig = DataModel.getFilterConfigDeprecated();
    this.eventService.publish("biView.configChanged", "BarChart", DataModel.table.id(), filterConfig, config);
  }
}

export default new HistogramChartService();
