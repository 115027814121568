import React from "react";
import PropTypes from "prop-types";
import Toast from "@paprika/toast";
import i18n from "@viz-ui/i18n/i18n";
import Button from "@paprika/button";
import "./conditionalFormattingToast.scss";

class ConditionalFormattingToast extends React.Component {
  static propTypes = {
    onRedirect: PropTypes.func,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    onRedirect: () => {},
    onClose: () => {},
  };

  constructor(props) {
    super(props);
  }

  closeHandler = () => {
    this.props.onClose();
  };

  redirectHandler = () => {
    this.props.onRedirect();
  };

  render() {
    return (
      <Toast
        kind="success"
        onClose={this.closeHandler}
        canAutoClose
        autoCloseDelay={5000}
        className="conditional-formatting-toast"
        style={this.toastWidth}
      >
        <span id="conditional-formatting-toast_msg">
          {i18n.t("_ConditionalFormatting.Save.Toast.Message_")}{" "}
          <span className="conditional-formatting-toast_msg_tableview">
            <Button kind="link" className="conditional-formatting-toast_linkbutton" onClick={this.redirectHandler}>
              <b>{i18n.t("_ConditionalFormatting.Save.Toast.Message.TableView_")}</b>
            </Button>
          </span>
        </span>
      </Toast>
    );
  }
}

export default ConditionalFormattingToast;
