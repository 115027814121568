import _min from "lodash/min";
import _max from "lodash/max";
import startCase from "lodash/startCase";
import ChartColorConfigHelper from "../highCharts/chartColorConfigHelper";

angular
  .module("acl.visualizer.charts")
  .factory("MapChart", (DataModel, ChartService, AppConfig, Localize, ColorPalette) => {
    const MAP_TYPES = {
      WorldCountries: "World Countries",
      WorldContinents: "World Continents",
      USAStates: "United States of America",
      Europe: "Europe",
      NorthAmerica: "North America",
      Asia: "Asia",
      Africa: "Africa",
      SouthAmerica: "South America",
      Oceania: "Oceania",
      China: "China",
      Australia: "Australia",
    };

    const MAP_THEME = {
      Light: "Light",
      Dark: "Dark",
      White: "White",
    };

    const COLOR_TYPES = {
      Automatic: "Automatic",
      stops3: "3stops",
      stops5: "5stops",
    };

    const blankKey = "(blank)";

    return {
      defaultDisplayConfig,
      populateChartConfigColumnDefs,
      chartConfigColumnDefs,
      isValidDataConfig,
      getChartDirectiveConfig,
      convertToHighChartData,
      tooltipPointFormatter,
      mapTypes,
      valueFormatter,
      dataLabelFormatter,
      isAdminMapType,
      getHighChartsColorAxis,
      colorStopTypes,
      mapThemeTypes,
      isValidLatLonRange,
      isRegionDrillEnabled,
      isCoordinateDrillEnabled,
      getMapTypeOnCoordinates,
    };

    function defaultDisplayConfig() {
      const mapChartColor = new ColorPalette().mapChartColors();
      return {
        displayDataLabels: true,
        enableDoubleClickZoom: true,
        showZoomButton: true,
        fixedTooltip: false,
        showGradientScale: true,
        mapType: MAP_TYPES.WorldCountries,
        mapThemeType: MAP_THEME.White,
        colorStopType: COLOR_TYPES.Automatic,
        colorAxis: {
          colorStops: {
            colorStops3: [{ color: mapChartColor[0] }, { color: mapChartColor[2] }, { color: mapChartColor[4] }],
            colorStops5: [
              { color: mapChartColor[0] },
              { color: mapChartColor[1] },
              { color: mapChartColor[2] },
              { color: mapChartColor[3] },
              { color: mapChartColor[4] },
            ],
          },
        },
      };
    }

    function valueFormatter(interpretationId, chartConfig, chartRow) {
      const { vizId, dataConfig, displayConfig } = chartConfig;
      if (
        displayConfig.valueFormattingOptions &&
        dataConfig.chartValues &&
        dataConfig.chartValues[0].aggregationType === "average"
      ) {
        const chartValueFieldName = `${vizId}-chart-value`;
        const chartValueFieldType = "numeric";
        return ChartService.getValueFormatter(interpretationId, chartValueFieldName, chartValueFieldType);
      }
      return ChartService.getValueFormatter(interpretationId, chartRow.fieldName, chartRow.type);
    }

    function chartConfigColumnDefs() {
      return {
        mapType: [],
        mapThemeType: [],
        chartRows: [],
        chartValues: [],
        chartCoordinates: [],
        colorStopTypes: [],
      };
    }

    function mapTypes() {
      return MAP_TYPES;
    }

    function mapThemeTypes() {
      return MAP_THEME;
    }

    function colorStopTypes() {
      return COLOR_TYPES;
    }

    function populateChartConfigColumnDefs(chartConfigColumnDefsObj) {
      let fields = DataModel.table.fields();
      if (fields) {
        let tempColumnDef = {};
        for (let fieldName in fields) {
          tempColumnDef = {};
          tempColumnDef.displayName = fields[fieldName].displayName;
          tempColumnDef.fieldId = fields[fieldName].colId;
          tempColumnDef.fieldName = fieldName;
          tempColumnDef.type = fields[fieldName].type;

          switch (tempColumnDef.type) {
            case "character":
              chartConfigColumnDefsObj.chartRows.push(tempColumnDef);
              chartConfigColumnDefsObj.chartCoordinates.push(tempColumnDef);
              break;

            case "numeric":
              chartConfigColumnDefsObj.chartValues.push(tempColumnDef);
              chartConfigColumnDefsObj.chartRows.push(tempColumnDef);
              break;
          }
        }
      }
      setColorStopTypeColumnDefs(chartConfigColumnDefsObj);
      setMapThemeColumnDefs(chartConfigColumnDefsObj);
      return setMapTypeColumnDefs(chartConfigColumnDefsObj);
    }

    function isValidDataConfig(dataConfig) {
      let result = !!(
        dataConfig &&
        dataConfig.chartRows &&
        dataConfig.chartRows.length > 0 &&
        dataConfig.chartRows.length < 4 &&
        dataConfig.chartValues &&
        dataConfig.chartValues.length > 0 &&
        dataConfig.chartValues.length < 2 &&
        ((dataConfig.chartValues[0].aggregationType && dataConfig.chartValues[0].fieldName) ||
          dataConfig.chartValues[0].aggregationType === "count")
      );
      return result;
    }

    function getChartDirectiveConfig(interpretationId, chartConfig) {
      const { dataConfig } = chartConfig;
      const { displayConfig } = chartConfig;

      const result = {
        displayDataLabels: displayConfig.displayDataLabels,
        enableDoubleClickZoom: displayConfig.enableDoubleClickZoom,
        showZoomButton: displayConfig.showZoomButton,
        fixedTooltip: displayConfig.fixedTooltip,
        showGradientScale: displayConfig.showGradientScale,
        mapType: displayConfig.mapType,
        mapThemeType: displayConfig.mapThemeType,
        isCoordinate: dataConfig.chartRows && dataConfig.chartRows.length > 1,
        isAdminMapType: isAdminMapType(displayConfig.mapType),
        chartRows: dataConfig.chartRows.map(chartRow => ({
          displayName: chartRow.displayName,
          fieldName: chartRow.fieldName,
          type: chartRow.type,
          valueFormatter: valueFormatter(interpretationId, chartConfig, chartRow),
        })),
        chartValues: getConfigChartValues(interpretationId, dataConfig.chartValues, chartConfig),
        colorAxis:
          displayConfig.colorStopType && dataConfig.chartValues && dataConfig.chartValues.length > 0
            ? getConfigColorAxis(interpretationId, chartConfig)
            : undefined,
        colorStopType: displayConfig.colorStopType,
      };

      return result;
    }

    function getConfigChartValues(interpretationId, chartValues, chartConfig) {
      const result = [];
      if (chartValues.length > 0) {
        const aggregateByValue = chartValues[0];
        result.push({
          aggregationType: aggregateByValue.aggregationType,
          fieldName: aggregateByValue.fieldName,
          displayName: aggregateByValue.displayName,
          valueFormatter: valueFormatter(interpretationId, chartConfig, aggregateByValue),
        });
      }
      return result;
    }

    function convertToHighChartData(data, vizConfig) {
      return convertTableDataToHighChartData(data, vizConfig);
    }

    function convertTableDataToHighChartData(data, vizConfig) {
      const result = [];

      const valueFieldName = data.config.values[0].field_name;
      const rowFieldNames = data.config.rows.map(row => row.field_name);

      data.values.forEach(valueObj => {
        let row = {};
        const value =
          typeof valueObj.values[valueFieldName] !== "undefined" && valueObj.values[valueFieldName] !== null
            ? Number(valueObj.values[valueFieldName])
            : blankKey;

        if (!vizConfig.isCoordinate) {
          let name = valueObj.rows[0][rowFieldNames[0]];
          if (typeof name === "string") {
            if (vizConfig.mapType === MAP_TYPES.WorldContinents) {
              name = startCase(name.toLowerCase());
            } else {
              name = vizConfig.isAdminMapType ? name.toUpperCase() : name.toLowerCase();
            }
          }
          row = {
            id: name,
            name: name,
            value: value !== blankKey ? value : "",
            drilldown: true,
          };
          result.push(row);
        } else {
          const lat = valueObj.rows[0][rowFieldNames[0]];
          const lon = valueObj.rows[1][rowFieldNames[1]];

          if (lat && lon && Number(lat) && Number(lon) && isValidLatLonRange(vizConfig.mapType, lat, lon)) {
            row = { lat: Number(lat), lon: Number(lon), value: value, drilldown: true };

            if (valueObj.rows[2]) {
              row.name = valueObj.rows[2][rowFieldNames[2]] || blankKey;
            }

            row.dataLabels = {
              overflow: false,
              shadow: false,
              color: "rgba(0,0,0)",
              style: {
                fontWeight: "normal",
              },
              formatter: function() {
                return dataLabelFormatter(vizConfig, this.point.name || "", this.point.value);
              },
            };
            result.push(row);
          }
        }
      });
      return result;
    }

    function getMapTypeOnCoordinates(mapType, lat, lon) {
      let mapTypeValue;
      switch (mapType) {
        case MAP_TYPES.WorldContinents:
          if (isValidLatLon(lat, lon, 7, 83, -169, -17) && !isValidLatLon(lat, lon, 16, 65, -25, -18)) {
            mapTypeValue = MAP_TYPES.NorthAmerica;
          }
          if (isValidLatLon(lat, lon, -55, 11, -85, -28)) {
            mapTypeValue = MAP_TYPES.SouthAmerica;
          }
          if (isValidLatLon(lat, lon, -35, 38, -25, 58) && !isValidLatLon(lat, lon, 15, 36, 33, 54)) {
            mapTypeValue = MAP_TYPES.Africa;
          }
          if (
            isValidLatLon(lat, lon, 35, 72, -24, 58) &&
            !isValidLatLon(lat, lon, 40, 55, 41, 57) &&
            !isValidLatLon(lat, lon, 35, 36, 51, 52)
          ) {
            mapTypeValue = MAP_TYPES.Europe;
          }
          if (
            isValidLatLon(lat, lon, -10, 80, 25, 170) &&
            !isValidLatLon(lat, lon, -7, 13, 29, 55.5) &&
            !isValidLatLon(lat, lon, -10, 15.5, 141, 167)
          ) {
            mapTypeValue = MAP_TYPES.Asia;
          }
          if (isValidLatLon(lat, lon, -46, -2, 114, 180)) {
            mapTypeValue = MAP_TYPES.Oceania;
          }
          break;
        case MAP_TYPES.WorldCountries:
        case MAP_TYPES.NorthAmerica:
        case MAP_TYPES.Asia:
        case MAP_TYPES.Oceania:
          if (isValidLatLon(lat, lon, 19, 72, -169, -67) && !isValidLatLon(lat, lon, 52, 62, -108, -71)) {
            mapTypeValue = MAP_TYPES.USAStates;
          }
          if (
            isValidLatLon(lat, lon, 18, 53, 80, 134) &&
            !isValidLatLon(lat, lon, 44, 51, 88, 112) &&
            !isValidLatLon(lat, lon, 26, 28, 80, 91)
          ) {
            mapTypeValue = MAP_TYPES.China;
          }
          if (isValidLatLon(lat, lon, -44, -10, 112, 154)) {
            mapTypeValue = MAP_TYPES.Australia;
          }
      }
      return mapTypeValue;
    }

    function isValidLatLonRange(mapType, lat, lon) {
      switch (mapType) {
        case MAP_TYPES.WorldCountries:
        case MAP_TYPES.WorldContinents:
          return isValidLatLon(lat, lon, -85, 85, -180, 180);
        case MAP_TYPES.USAStates:
          return isValidLatLon(lat, lon, 19, 72, -169, -67) && !isValidLatLon(lat, lon, 52, 62, -108, -71);
        case MAP_TYPES.Europe:
          return (
            isValidLatLon(lat, lon, 35, 72, -24, 58) &&
            !isValidLatLon(lat, lon, 40, 55, 41, 57) &&
            !isValidLatLon(lat, lon, 35, 36, 51, 52)
          );
        case MAP_TYPES.NorthAmerica:
          return isValidLatLon(lat, lon, 7, 83, -169, -17) && !isValidLatLon(lat, lon, 16, 65, -25, -18);
        case MAP_TYPES.Asia:
          return (
            isValidLatLon(lat, lon, -10, 80, 25, 170) &&
            !isValidLatLon(lat, lon, -7, 13, 29, 55.5) &&
            !isValidLatLon(lat, lon, -10, 15.5, 141, 167)
          );
        case MAP_TYPES.Africa:
          return isValidLatLon(lat, lon, -35, 38, -25, 58) && !isValidLatLon(lat, lon, 15, 36, 33, 54);
        case MAP_TYPES.SouthAmerica:
          return isValidLatLon(lat, lon, -55, 11, -85, -28);
        case MAP_TYPES.Oceania:
          return isValidLatLon(lat, lon, -46, -2, 114, 180);
        case MAP_TYPES.China:
          return (
            isValidLatLon(lat, lon, 18, 53, 80, 134) &&
            !isValidLatLon(lat, lon, 44, 51, 88, 112) &&
            !isValidLatLon(lat, lon, 26, 28, 80, 91)
          );
        case MAP_TYPES.Australia:
          return isValidLatLon(lat, lon, -44, -10, 112, 154);
        default:
          return false;
      }
    }

    function isValidLatLon(lat, lon, latMin, latMax, lonMin, lonMax) {
      return lat >= latMin && lat <= latMax && lon >= lonMin && lon <= lonMax;
    }

    function isAdminMapType(mapType) {
      /* Admin area map type - World Countries,World Continents, Europe, NorthAmerica, supports (Alpha-2, Alpha-3 code)
       All map type supports : `hc-key` (Unique hierarchical identifier) */
      return (
        mapType === MAP_TYPES.Europe ||
        mapType === MAP_TYPES.NorthAmerica ||
        mapType === MAP_TYPES.WorldCountries ||
        mapType === MAP_TYPES.WorldContinents ||
        mapType === MAP_TYPES.Asia ||
        mapType === MAP_TYPES.Africa ||
        mapType === MAP_TYPES.SouthAmerica ||
        mapType === MAP_TYPES.Oceania
      );
    }

    function isRegionDrillEnabled(e) {
      const chart = e.point || e;
      return (
        !chart.lat &&
        (!chart.series.options._levelNumber || chart.series.options._levelNumber <= 1) &&
        (chart.name === MAP_TYPES.Europe ||
          chart.name === MAP_TYPES.NorthAmerica ||
          chart.name === MAP_TYPES.Asia ||
          chart.name === MAP_TYPES.Africa ||
          chart.name === MAP_TYPES.SouthAmerica ||
          chart.name === MAP_TYPES.Oceania ||
          chart.name === MAP_TYPES.USAStates ||
          chart.name === MAP_TYPES.China ||
          chart.name === MAP_TYPES.Australia)
      );
    }

    function isCoordinateDrillEnabled(config, e) {
      const chart = e.point || e;
      return (
        config.isCoordinate && config.isAdminMapType && !!getMapTypeOnCoordinates(config.mapType, chart.lat, chart.lon)
      );
    }

    function setMapTypeColumnDefs(chartConfigColumnDefsObj) {
      chartConfigColumnDefsObj.mapType.push(
        {
          name: Localize.getLocalizedString("_ChartConfig.Display.Style.MapType.World.Label_"),
          option: [
            {
              field: MAP_TYPES.WorldContinents,
              value: Localize.getLocalizedString("_ChartConfig.Display.Style.MapType.WorldContinents.Label_"),
            },
            {
              field: MAP_TYPES.WorldCountries,
              value: Localize.getLocalizedString("_ChartConfig.Display.Style.MapType.WorldCountries.Label_"),
            },
          ],
        },
        {
          name: Localize.getLocalizedString("_ChartConfig.Display.Style.MapType.WorldContinents.Label_"),
          option: [
            {
              field: MAP_TYPES.Africa,
              value: Localize.getLocalizedString("_ChartConfig.Display.Style.MapType.Africa.Label_"),
            },
            {
              field: MAP_TYPES.Asia,
              value: Localize.getLocalizedString("_ChartConfig.Display.Style.MapType.Asia.Label_"),
            },
            {
              field: MAP_TYPES.Europe,
              value: Localize.getLocalizedString("_ChartConfig.Display.Style.MapType.Europe.Label_"),
            },
            {
              field: MAP_TYPES.NorthAmerica,
              value: Localize.getLocalizedString("_ChartConfig.Display.Style.MapType.NorthAmerica.Label_"),
            },
            {
              field: MAP_TYPES.Oceania,
              value: Localize.getLocalizedString("_ChartConfig.Display.Style.MapType.Oceania.Label_"),
            },
            {
              field: MAP_TYPES.SouthAmerica,
              value: Localize.getLocalizedString("_ChartConfig.Display.Style.MapType.SouthAmerica.Label_"),
            },
          ],
        },
        {
          name: Localize.getLocalizedString("_ChartConfig.Display.Style.MapType.WorldCountries.Label_"),
          option: [
            AppConfig.features.australiaMapChart
              ? {
                  field: MAP_TYPES.Australia,
                  value: Localize.getLocalizedString("_ChartConfig.Display.Style.MapType.Australia.Label_"),
                }
              : undefined,
            {
              field: MAP_TYPES.China,
              value: Localize.getLocalizedString("_ChartConfig.Display.Style.MapType.China.Label_"),
            },
            {
              field: MAP_TYPES.USAStates,
              value: Localize.getLocalizedString("_ChartConfig.Display.Style.MapType.USAStates.Label_"),
            },
          ].filter(opt => opt !== undefined),
        }
      );
      return chartConfigColumnDefsObj;
    }

    function setMapThemeColumnDefs(chartConfigColumnDefsObj) {
      let mapThemeDef = {};

      mapThemeDef = {};
      mapThemeDef.fieldName = MAP_THEME.Dark;
      mapThemeDef.displayName = Localize.getLocalizedString("_ChartConfig.Display.Style.MapThemeType.Dark.Label_");
      chartConfigColumnDefsObj.mapThemeType.push(mapThemeDef);

      mapThemeDef = {};
      mapThemeDef.fieldName = MAP_THEME.Light;
      mapThemeDef.displayName = Localize.getLocalizedString("_ChartConfig.Display.Style.MapThemeType.Light.Label_");
      chartConfigColumnDefsObj.mapThemeType.push(mapThemeDef);

      mapThemeDef = {};
      mapThemeDef.fieldName = MAP_THEME.White;
      mapThemeDef.displayName = Localize.getLocalizedString("_ChartConfig.Display.Style.MapThemeType.White.Label_");
      chartConfigColumnDefsObj.mapThemeType.push(mapThemeDef);

      return chartConfigColumnDefsObj;
    }

    function tooltipPointFormatter(config, name, option) {
      let result = "";
      if (config.chartRows.length > 0) {
        const chartRow = config.chartRows[0];
        const lineBreak = " ";
        const optionName = option.name ? option.name : `${lineBreak}`;
        let displayName = config.isCoordinate ? optionName : name;
        displayName = config.displayDataLabels && config.chartRows.length === 2 ? `${lineBreak}` : displayName;

        if (displayName)
          result += `${
            config.isCoordinate ? `${lineBreak}` : `${ChartService.getFieldLabel(chartRow)}:`
          }<span> <b> ${displayName} </b> </span>`;

        if (config.isCoordinate) {
          result += `${lineBreak} ${
            displayName !== `${lineBreak}` ? "<br />" : `${lineBreak}`
          }${Localize.getLocalizedString("_ChartConfig.Display.Style.ToolTip.Lat.Label_")}:<span> <b> ${
            option.lat
          } </b> </span>, ${Localize.getLocalizedString("_ChartConfig.Display.Style.ToolTip.Lon.Label_")}:<span> <b> ${
            option.lon
          } </b> </span>`;
        }
        if (config.chartValues.length > 0) {
          const chartValues = config.chartValues[0];
          result += `<br/>${ChartService.getFieldLabel(chartValues)}: <span> <b>${
            option.value === blankKey
              ? Localize.getLocalizedString("_Filter.BlankValue.Label_")
              : chartValues.valueFormatter(option.value)
          }</b> </span>`;
        }
      }
      result = `<div class="tooltip-mapchart">${result}</div>`;
      return result;
    }

    function dataLabelFormatter(config, name, value) {
      let result = `${name}`;
      if (config.displayDataLabels && config.chartRows.length > 0) {
        result = `${name} <span style="font-weight:bold">${
          value === blankKey ? value : config.chartValues[0].valueFormatter(value)
        } </style>`;
      }
      return result;
    }

    function setColorStopTypeColumnDefs(chartConfigColumnDefsObj) {
      let colorStopTypeDef = {};

      colorStopTypeDef = {};
      colorStopTypeDef.fieldName = COLOR_TYPES.Automatic;
      colorStopTypeDef.displayName = Localize.getLocalizedString("_ChartConfig.Display.Color.Automatic.Label_");
      chartConfigColumnDefsObj.colorStopTypes.push(colorStopTypeDef);

      colorStopTypeDef = {};
      colorStopTypeDef.fieldName = COLOR_TYPES.stops3;
      colorStopTypeDef.displayName = Localize.getLocalizedString("_ChartConfig.Display.Color.3Stop.Label_");
      chartConfigColumnDefsObj.colorStopTypes.push(colorStopTypeDef);

      colorStopTypeDef = {};
      colorStopTypeDef.fieldName = COLOR_TYPES.stops5;
      colorStopTypeDef.displayName = Localize.getLocalizedString("_ChartConfig.Display.Color.5Stop.Label_");
      chartConfigColumnDefsObj.colorStopTypes.push(colorStopTypeDef);
    }

    function getHighChartsColorAxis(config, data) {
      let result;
      const values = getFlattenedColorValues(data);
      const colorStops = getColorStops(config, _min(values), _max(values));
      let min = _min(values);
      let max = _max(values);

      if (config.colorStopType && config.colorStopType !== COLOR_TYPES.Automatic) {
        min = colorStops[0].value;
        max = colorStops[colorStops.length - 1].value;
      }

      result = {
        min: min,
        max: max,
        startOnTick: !Number.isFinite(min),
        endOnTick: !Number.isFinite(max),
        labels: {
          formatter: function() {
            return config.colorAxis ? config.colorAxis.valueFormatter(this.value) : this.value;
          },
        },
      };

      if (config.colorStopType && config.colorStopType !== COLOR_TYPES.Automatic) {
        result.stops = colorStops.map((stop, i) => [(colorStops[i].value - min) / (max - min), stop.color]);
        result.tickPositions = colorStops.map(stop => stop.value);
      }

      return result;
    }

    function getFlattenedColorValues(data) {
      const valueFieldName = data.config.values[0].field_name;
      return data.values.map(d => Number(d.values[valueFieldName]));
    }

    function getColorStops(config, minValue, maxValue) {
      let colorStops;
      if (config.colorStopType === COLOR_TYPES.stops3) {
        colorStops = ChartColorConfigHelper.getColorStops(config.colorAxis.colorStops.colorStops3, minValue, maxValue);
      } else if (config.colorStopType === COLOR_TYPES.stops5) {
        colorStops = ChartColorConfigHelper.getColorStops(config.colorAxis.colorStops.colorStops5, minValue, maxValue);
      }
      return colorStops;
    }

    function getConfigColorAxis(interpretationId, chartConfig) {
      const { dataConfig } = chartConfig;
      const { displayConfig } = chartConfig;
      return {
        colorStops: displayConfig.colorAxis
          ? displayConfig.colorAxis.colorStops
          : defaultDisplayConfig().colorAxis.colorStops,
        valueFormatter: valueFormatter(interpretationId, chartConfig, dataConfig.chartValues[0]),
      };
    }
  });
