import i18n from "@viz-ui/i18n/i18n";
const promptMessage = i18n.t("_ConfirmExit.Message_");

class ConfirmLeave {
  on() {
    window.onbeforeunload = event => {
      event.preventDefault();
      return promptMessage;
    };
  }

  off() {
    window.onbeforeunload = null;
  }
}

export default new ConfirmLeave();
