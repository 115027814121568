angular
  .module("acl.visualizer.treemap")
  .controller("TreemapController", ($scope, ChartService, InheritController, Treemap) => {
    let base = InheritController("ChartBaseController", $scope)
      .override("chartType", "Treemap")
      .override("isDataConfigParsed", false)
      .override("defaultDisplayConfig", Treemap.defaultDisplayConfig)
      .override("getChartDirectiveConfig", Treemap.getChartDirectiveConfig)
      .override("populateChartConfigColumnDefs", Treemap.populateChartConfigColumnDefs)
      .override("chartConfigColumnDefs", Treemap.chartConfigColumnDefs)
      .override("isValidDataConfig", Treemap.isValidDataConfig)
      .override("chartZoomIn", data => {
        let formatters = $scope.chartConfig.dataConfig.chartRows.map(chartRow =>
          ChartService.getFormatterForType(chartRow.type)
        );
        let filters = data.map((fieldValue, index) => ({
          field: $scope.chartConfig.dataConfig.chartRows[index],
          value: formatters[index](data[index]),
        }));

        $scope.tab.callbackInterface.onZoomIn(filters);
      })
      .override(
        "getKeyColor",
        config => config && ChartService.getKeyColor(config.chartRows[0].fieldName, config.colorMapping)
      );

    base.initChart();
  });
