import moment from "moment";
import i18n from "@viz-ui/i18n/i18n";
import SectionService from "./sectionService";
import { DateFormatter } from "@acl-services/sriracha-formatters/dist/Formatters";

export default function(fieldFormatObject, unformattedValues) {
  const formattingObject = fieldFormatObject || {};

  return [
    {
      items: getDateFormattingOptions(formattingObject, unformattedValues),
    },
  ];
}

function getDateFormattingOptions(selectedFormattingObject, unformattedValues) {
  var exampleDate = moment(new Date(new Date().getFullYear(), 0, 31)).format("MM/DD/YYYY");
  const formats = [
    { isRaw: false, dateFormat: undefined }, // Default format.
    { isRaw: false, dateFormat: "YYYY-MM-DD" },
    { isRaw: false, dateFormat: "MM-DD-YYYY" },
    { isRaw: false, dateFormat: "DD-MMM-YYYY" },
    { isRaw: false, dateFormat: "YYYY/MM/DD" },
    { isRaw: false, dateFormat: "MM/DD/YYYY" },
    { isRaw: false, dateFormat: "DD/MMM/YYYY" },
  ];

  if (unformattedValues) {
    formats.unshift({ isRaw: true, dateFormat: undefined });
  }

  return formats.map(function(fieldFormatJson) {
    var example = fieldFormatJson.isRaw
      ? i18n.t("_Format.Date.Raw.Example_")
      : DateFormatter.format(exampleDate, fieldFormatJson);

    return {
      example: example,
      format: fieldFormatJson,
      label: i18n.t(SectionService.getDateFormatLabelKey(fieldFormatJson)),
      selected: SectionService.compareFormattingOptions(fieldFormatJson, selectedFormattingObject),
    };
  });
}
