import { object } from "underscore";

const FieldTypes = Object.freeze({
  CHARACTER: "character",
  NUMERIC: "numeric",
  DATETIME: "datetime",
  DATE: "date",
  TIME: "time",
  LOGICAL: "logical",
  FILE: "file",
  SIGNATURE: "digital_signature",
});

export default FieldTypes;
