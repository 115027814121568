angular
  .module("acl.visualizer.charts.controllers")
  .controller("BarChartController", function($scope, BarChart, ChartService, InheritController) {
    $scope.viewModel = {
      config: null,
      data: null,
    };

    var temp = {};

    var base = InheritController("ChartBaseController", $scope)
      .override("chartType", "BarChart")
      .override("defaultDisplayConfig", BarChart.defaultDisplayConfig)
      .override("getChartDirectiveConfig", BarChart.getChartDirectiveConfig)
      .override("populateChartConfigColumnDefs", BarChart.populateChartConfigColumnDefs)
      .override("chartConfigColumnDefs", BarChart.chartConfigColumnDefs)
      .override("isValidDataConfig", BarChart.isValidDataConfig)
      .override("onDisplayConfigChanged", function(displayConfig) {
        // Needed to remove $scope before being able to move it to service.
        if (displayConfig.horizontal !== $scope.chartConfig.displayConfig.horizontal) {
          if (displayConfig.horizontal) {
            temp["displayConfig.xAxis.showLabel"] = displayConfig.xAxis.showLabel;
            displayConfig.xAxis.showLabel = false;
          } else if (temp["displayConfig.xAxis.showLabel"]) {
            displayConfig.xAxis.showLabel = true;
          }
        }
      })
      .override("chartZoomIn", function(keyArray) {
        var xFormatter = ChartService.getFormatterForType($scope.chartConfig.dataConfig.chartXAxis.type);
        var filters = [];
        if (keyArray.length > 1) {
          var seriesFormatter = ChartService.getFormatterForType($scope.chartConfig.dataConfig.chartSeries.type);
          filters.push({ field: $scope.chartConfig.dataConfig.chartSeries, value: seriesFormatter(keyArray[0]) });
        }
        filters.push({
          field: $scope.chartConfig.dataConfig.chartXAxis,
          value: xFormatter(keyArray[keyArray.length - 1]),
        });
        $scope.tab.callbackInterface.onZoomIn(filters);
      })
      .override("getKeyColor", config => config && ChartService.getKeyColor(config.colorByField, config.colorMapping));

    base.initChart();
  });
