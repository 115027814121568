import React from "react";
import PropTypes from "prop-types";
import i18n from "@viz-ui/i18n/i18n";
import Input from "@paprika/input";
import FormElement from "@paprika/form-element";
import "./sharedChartHeader.scss";

const propTypes = {
  chartTitle: PropTypes.string,
  index: PropTypes.number,
  onChartTitleSet: PropTypes.func,
};

const defaultProps = {
  chartTitle: "",
  index: 0,
  onChartTitleSet: () => {},
};

const SharedChartHeader = props => {
  const { chartTitle: chartTitleInput } = props;
  const chartHeaderPlaceholder = "Untitled";
  const [titleErrorText, setChartHeaderTitleErrorText] = React.useState("");
  const [chartTitle, setChartHeaderText] = React.useState(chartTitleInput);

  React.useEffect(() => {
    setChartHeaderText(chartTitleInput);
  }, [chartTitleInput]);

  const handleTitleChange = e => {
    setChartHeaderTitleErrorText(validateChartHeaderTitle(e.target.value));
    setChartHeaderText(e.target.value);
  };

  const onTitleBlur = e => {
    //set text value to default on error
    let chartTitleText = e.target.value;
    if (titleErrorText.length > 0) {
      chartTitleText = "";
      setChartHeaderTitleErrorText("");
    }
    setChartHeaderText(chartTitleText);
    props.onChartTitleSet(chartTitleText);
  };

  const validateChartHeaderTitle = title => {
    const titlePattern = '^[^\\\\/:*?"<>|]+$';
    const maxTitleLength = 80;
    let errorText = "";
    const titleLength = title.trim().length;
    if (!titleLength) {
      errorText = ""; //No blank validation as we can save blank value for chart title
    } else if (titleLength > maxTitleLength) {
      errorText = i18n.t("_Chart.Title.Error.MaxLength.Template_");
    } else if (!new RegExp(titlePattern).test(title)) {
      errorText = i18n.t("_Chart.Title.Error.Invalid.TemplateError_");
    }
    return errorText;
  };
  return (
    <div className="chart-header">
      <FormElement.Content className="chart-title">
        <Input
          className="edit-when-hover"
          type="text"
          data-testid="chart-header-title-input-test"
          id={`chart-${props.index + 1}-title`}
          value={chartTitle}
          onChange={e => {
            handleTitleChange(e);
          }}
          onKeyPress={e => {
            if (e.key === "Enter") {
              onTitleBlur(e);
            }
          }}
          onBlur={e => {
            onTitleBlur(e);
          }}
          placeholder={chartHeaderPlaceholder}
          hasError={Boolean(titleErrorText.length)}
          data-autofocus
        />
      </FormElement.Content>
      <FormElement.Error>{titleErrorText}</FormElement.Error>
    </div>
  );
};
SharedChartHeader.propTypes = propTypes;
SharedChartHeader.defaultProps = defaultProps;
SharedChartHeader.displayName = "SharedChartHeader";
export default SharedChartHeader;
