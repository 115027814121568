import backendApi from "@results/services/apiCall/backendApi";
import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";

export function angularStoryboardBackend(AppConfig) {
  "ngInject";

  return {
    createStoryboard: function(name, config) {
      return backendApi.post(url(), {}, { name: name, config: config }).then(function(response) {
        UsageTracker.createEvent(".create");
        return response.data;
      });
    },
    saveStoryboard: function(id, name, config) {
      return backendApi.put(url(id), {}, { name: name, config: config }).then(function(response) {
        UsageTracker.createEvent(".update");
        return response.data;
      });
    },

    saveAsStoryboard: function(name, config) {
      return backendApi.post(url(), {}, { name: name, config: config }).then(function(response) {
        UsageTracker.createEvent(".saveAs");
        return response.data;
      });
    },
    deleteStoryboard: function(id) {
      return backendApi.delete(url(id)).then(function(response) {
        UsageTracker.createEvent(".delete");
        return response.data;
      });
    },
  };

  function url(id) {
    return !id ? AppConfig.coneService.storyboardsPath() : AppConfig.coneService.storyboardPath({ id });
  }
}

async function getStoryboard(id) {
  return await backendApi.get(`/storyboards/${id}`).then(response => response.data);
}

async function getStoryboardUser(id) {
  return await backendApi.get(`/storyboards/user?storyboard_id=${id}`).then(response => response.data);
}

async function getPublicStoryboard(uid) {
  return await backendApi
    .get(`/storyboard_links/${uid}`)
    .then(response => response.data)
    .catch(err => {
      // Handling auto redirection in case of user is not authorized as part of share storyboard
      if (!err.status) {
        window.location.href = `${backendApi.getLoginUrl()}?redirect_uri=${window.location.href}`;
      }
      // Throwing error in order to handle existing error handling
      throw err;
    });
}

async function getPublicStoryboardUser(id) {
  return await backendApi
    .get(`/storyboard_links/user_public?public_storyboard_link_id=${id}`)
    .then(response => response.data);
}

async function getStoryboardAssignees(orgId, pageNo, keyword) {
  const url = keyword
    ? `/api/v3/private/storyboard_available_assignees?org_id=${orgId}&page=${pageNo}&items=100&search=${keyword}`
    : `/api/v3/private/storyboard_available_assignees?org_id=${orgId}&page=${pageNo}&items=100`;
  return await backendApi.get(url).then(response => {
    return response.data;
  });
}
const formatAssignedEntities = response => {
  let user = [];
  let admin = [];
  let workflowGroup = [];
  const data = response.data.data;
  const meta = response.data.meta;
  //modifying the api response to a suitable format
  data.forEach(item => {
    if (item.attributes && item.attributes.assignee_type == "user") {
      user.push({
        id: item.attributes.assignee_id,
        name: item.attributes.title,
        email: item.attributes.entity_info,
      });
    } else if (item.attributes && item.attributes.assignee_type == "system_user") {
      admin.push({
        id: item.attributes.assignee_id,
        name: item.attributes.title,
        email: item.attributes.entity_info,
        is_admin: true,
      });
    } else if (item.attributes && item.attributes.assignee_type == "workflow_group") {
      workflowGroup.push({
        id: item.attributes.assignee_id,
        name: item.attributes.title,
        group_assignments: item.relationships.users.data.map(user => {
          return {
            user_id: Number(user.id),
            group_id: Number(item.attributes.assignee_id),
          };
        }),
      });
    }
  });

  return { user, admin, workflowGroup, pagination: meta.page };
};
//pagination api for current Users
async function getStoryboardAssignedEntities(orgId, pageNo, storyboadId, keyword) {
  const url = keyword
    ? `/api/v3/private/storyboard_assigned_entities?org_id=${orgId}&page=${pageNo}&storyboard_id=${storyboadId}&search=${keyword}`
    : `/api/v3/private/storyboard_assigned_entities?org_id=${orgId}&page=${pageNo}&storyboard_id=${storyboadId}`;
  return await backendApi.get(url).then(response => {
    return formatAssignedEntities(response);
  });
}

async function getBoardSelectorMaps() {
  return await backendApi.get("/storyboards/maps").then(response => response.data);
}

async function getUsers() {
  return await backendApi.get("/api/v3/users").then(response => {
    const users = response.data.data;
    if (users && users.length > 0) {
      return users.map(user => {
        return { ...user.attributes };
      });
    }
  });
}

async function getAdminUsers() {
  return await backendApi.get("/api/v3/users?admin_only=true").then(response => {
    const admins = response.data.data;

    if (admins && admins.length > 0) {
      return admins.map(admin => {
        return { ...admin.attributes, is_admin: true };
      });
    }
  });
}

async function getGroups(orgId) {
  return await backendApi.get(`/api/v3/private/workflow_groups?include=users&org_id=${orgId}`).then(response => {
    const groups = response.data.data;
    if (groups && groups.length > 0) {
      return groups.map(group => {
        const groupId = Number(group.id);
        const response = {
          id: groupId,
          name: group.attributes.name,
        };

        if (group && group.relationships && group.relationships.users) {
          const users = group.relationships.users.data;
          if (users && users.length > 0) {
            response.group_assignments = users.map(user => {
              return {
                user_id: Number(user.id),
                group_id: groupId,
              };
            });
          }
        }
        return response;
      });
    }
  });
}

async function getLaunchpadGroups(orgId) {
  return await backendApi.get(`/api/v3/private/launchpad_groups?org_id=${orgId}`).then(response => {
    const groups = response.data.data;
    if (groups && groups.length > 0) {
      return groups.map(group => ({
        id: group.attributes.id,
        name: group.attributes.name,
      }));
    }
    return response;
  });
}

export const StoryboardBackend = {
  getStoryboard,
  getStoryboardUser,
  getPublicStoryboard,
  getPublicStoryboardUser,
  getBoardSelectorMaps,
  getUsers,
  getAdminUsers,
  getGroups,
  getLaunchpadGroups,
  getStoryboardAssignees,
  getStoryboardAssignedEntities,
};
