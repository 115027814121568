/**
 * This module exposes angular dependency injection into non-angular code, to be used in es6 module environments.
 */
let services = {};

angular
  .module("highbond.storyboard.glue", ["acl.common.localize", "acl.common.config"])
  .run(function(Localize, AppConfig) {
    services.Localize = Localize;
    services.AppConfig = AppConfig;
  });

module.exports = services;
