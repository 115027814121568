import _ from "lodash";
import map from "lodash/map";
import some from "lodash/some";
import isMetrics from "../metricApp/metricHelper";
import FieldTypeChecker from "../fieldTypeChecker/fieldTypeChecker.service";
import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";
import MultiSelectFilterStore from "@viz-ui/services/quickMenu/MultiSelectFilterStoreService";
import quickMenuToggler from "@viz-ui/services/quickMenu/quickMenuTogglerService";

const aclFilterPanel = {
  restrict: "E",
  bindings: {
    fieldFormatIdentifier: "<",
    table: "<",
    canRemediateRecords: "<",
  },
  controllerAs: "filterPanel",
  controller: FilterPanelController,
  templateUrl: "visualizer/js/modules/core/filterPanel/filterPanel.tpl.html",
};

function FilterPanelController($scope, $state, DataModel, DataFilter, Localize, EventService, FieldFormat, AppConfig) {
  "ngInject";

  const filterPanel = this;

  filterPanel.migrateVizQuickFilter = AppConfig.features.migrateVizQuickFilter;

  const eventService = EventService.register("filterPanel.FilterPanelController", $scope);
  $scope.$on("$destroy", function() {
    eventService.unregister();
  });

  filterPanel.fieldSort = {
    isSortEnabled: false,
    sortFieldOptions: [],
    sortFieldId: undefined,
    sortOrder: undefined,
    updateSort: updateSort,
    removeSort: removeSort,
  };

  filterPanel.remediationFilters = {
    myRecords: DataModel.filterConfig.myRecords(),
    openStatuses: DataModel.filterConfig.openStatuses(),
    toggleMyRecords,
    toggleOpenStatuses,
    showToggles: showFilterToggles(filterPanel.canRemediateRecords),
  };
  filterPanel.isCertificationsTable = function() {
    return DataModel.table.certificationsTable();
  };

  filterPanel.valueFilterProps = {
    checkedValues: fieldName => MultiSelectFilterStore.getCheckedValuesForWatch(fieldName),
    clear: fieldName => {
      const field = DataModel.table.fieldForWatch(fieldName);
      if (DataFilter.isFilterActive(DataModel.getFilterConfigDeprecated(), field.fieldName)) {
        MultiSelectFilterStore.clearValues(field.fieldName);
      }
    },
    field: fieldName => DataModel.table.fieldForWatch(fieldName),
    hasQuickFilter: filter => !!filter.quickFilter,
    onToggle: (field, value, totalLoadedValuesLength, filterApplied) => {
      if (DataFilter.isFilterActive(DataModel.getFilterConfigDeprecated(), field.fieldName)) {
        MultiSelectFilterStore.toggleValue(field.fieldName, value, totalLoadedValuesLength, filterApplied);
        eventService.publish("filterPanel.filtersChanged");
      }
    },
    tableId: () => DataModel.table.id(),
    selectAllState: fieldName => {
      return MultiSelectFilterStore.getSelectAllState(fieldName);
    },
    onSelectAllClick: (
      field,
      action,
      visibleValues,
      totalLoadedValuesLength,
      filterApplied,
      selectAllStatePriorSearch
    ) => {
      if (DataFilter.isFilterActive(DataModel.getFilterConfigDeprecated(), field.fieldName)) {
        MultiSelectFilterStore.handleSelectAllClick(
          field.fieldName,
          action,
          visibleValues,
          totalLoadedValuesLength,
          filterApplied,
          selectAllStatePriorSearch
        );
        eventService.publish("filterPanel.filtersChanged");
      }
    },
  };

  filterPanel.criteriaFilterPropsHandlers = {
    removeCriteriaFilter: (filter, criteriaFilter) => {
      let fieldName = filter.name;
      let requiredReload = DataFilter.removeFilter(DataModel.getFilterConfigDeprecated(), fieldName, criteriaFilter);
      if (requiredReload) {
        applyFilters(true, true);
        DataModel.filtersChanged = true;
        eventService.publish("filterPanel.filtersChanged");
      }
    },

    setRemoveHighlight: (criteriaFilter, value) => {
      criteriaFilter.removeHighlight = value;
    },

    connectorChanged: criteriaFilter => {
      if (!criteriaFilter.changed) {
        criteriaFilter.changed = {};
      }
      criteriaFilter.changed.connector = true;
    },
    operatorChanged: (criteriaFilter, value) => {
      criteriaFilter.operator = value;
      if (!criteriaFilter.changed) {
        criteriaFilter.changed = {};
      }
      criteriaFilter.changed.operator = true;
    },
    value1Changed: (criteriaFilter, value) => {
      criteriaFilter.values[0] = value;
      addFilterChangedObject(criteriaFilter);
      criteriaFilter.changed.value = true;
    },
    value2Changed: (criteriaFilter, value) => {
      criteriaFilter.values[1] = value;
      addFilterChangedObject(criteriaFilter);
      criteriaFilter.changed.value2 = true;
    },
  };

  filterPanel.criteriaFilterProps = {};
  filterPanel.visualIndicatorProps = {
    height: "12",
    width: "12.5",
  };
  filterPanel.$onChanges = changesObj => {
    if (changesObj.canRemediateRecords) {
      const canRemediateRecords = changesObj.canRemediateRecords.currentValue;
      filterPanel.remediationFilters.showToggles = showFilterToggles(canRemediateRecords);
    }
  };

  let fieldFormatsByName = new Map();
  updateFieldFormats();

  function updateFieldFormats() {
    const formattingOptions = DataModel.tableConfig.formattingOptions();
    fieldFormatsByName = new Map();

    Object.keys(formattingOptions).forEach(fieldName => {
      let fieldFormatObj = formattingOptions[fieldName];
      if (fieldFormatObj) {
        fieldFormatsByName.set(fieldName, FieldFormat.fromJson(fieldFormatObj));
      }
    });
  }

  DataModel.subscribe("tableConfig.formatting", () => {
    updateFieldFormats();
  });

  function isLastFilter(filter, criteriaFilter) {
    return filter.filters.indexOf(criteriaFilter) === filter.filters.length - 1;
  }

  function addFilterChangedObject(criteriaFilter) {
    if (!criteriaFilter.changed) {
      criteriaFilter.changed = {};
    }
  }

  eventService.subscribe("setQuickFilters", setQuickFilters);
  eventService.subscribe("disableQuickFilters", disableQuickFilters);

  eventService.subscribe("applyFilters", applyFilters);

  // If table's fields already exists prepopulate
  if (DataModel.table.sortFields()) {
    updateSortFieldOptions();
  }

  DataModel.subscribe("table.sortFields", updateSortFieldOptions);
  DataModel.subscribe("table.field", updateSortFieldOptions);
  DataModel.subscribe("table.fields", updateSortFieldOptions);

  function updateSortFieldOptions() {
    const sortFields = DataModel.table.sortFields();
    filterPanel.fieldSort.sortFieldOptions = sortFields.map(function(d) {
      return {
        id: d.fieldName,
        name: d.displayName,
        type: DataModel.table.field(d.fieldName).type,
        isDigitalSortType: isDigitalSortType(d.fieldName),
      };
    });
  }

  function isDigitalSortType(fieldId) {
    const type = getFieldType(fieldId);
    return DataFilter.isDigitalSortType(type);
  }

  function getFieldType(fieldId) {
    return DataModel.table.field(fieldId).type;
  }

  eventService.subscribe("dataTable.sortChange", function(event, sortFieldId, sortOrder) {
    filterPanel.fieldSort.sortFieldId = sortFieldId;
    filterPanel.fieldSort.sortOrder = sortOrder;
  });

  // FIXME: Don't set a property of the service in this context.
  DataModel.filterErrors = function() {
    if (filterPanel.filtersForm.$valid) {
      return [];
    }
    const errorFields = _.chain(filterPanel.filtersForm.$error)
      .values()
      .flatten()
      .pluck("$name")
      .value();
    return errorFields.filter(function(fieldName) {
      return (
        !!fieldName &&
        _.chain(filterPanel.filterList)
          .pluck("name")
          .contains(fieldName)
          .value() &&
        DataFilter.isFilterActive(DataModel.getFilterConfigDeprecated(), fieldName)
      );
    });
  };

  filterPanel.filterPanelProps = {
    showFilterSection: {},
  };

  $scope.$watch(
    function() {
      return DataModel.getFilterConfigDeprecated();
    },
    function() {
      filterPanel.fieldSort.sortFieldId = DataModel.filterConfig.sortFieldId();
      filterPanel.fieldSort.sortOrder = DataModel.filterConfig.sortOrder();
      filterPanel.remediationFilters.myRecords = DataModel.filterConfig.myRecords();
      filterPanel.remediationFilters.openStatuses = DataModel.filterConfig.openStatuses();
    },
    true
  );

  DataModel.subscribe("table.hasStaticField", () => {
    filterPanel.fieldSort.isSortEnabled = !DataModel.table.hasStaticField();
  });

  filterPanel.filterTypeMismatch = {};
  filterPanel.filterValid = {};

  $scope.$watch(
    function() {
      return DataModel.getFilterConfigDeprecated().filterList;
    },
    function(filterList) {
      // FIXME: don't directly assign
      filterPanel.filterList = filterList;
      updateFiltersErrorState(filterList);
      setCriteriaFilterProps(filterList, filterPanel.fieldFormatIdentifier);
    },
    true
  );

  DataModel.subscribe("table.fields", () => {
    const filterList = DataModel.getFilterConfigDeprecated().filterList;
    updateFiltersErrorState(filterList);
  });

  function updateFiltersErrorState(filterList) {
    filterPanel.filterTypeMismatch = {};
    filterPanel.filterValid = {};
    if (filterList) {
      filterList.forEach((filter, index) => {
        const fields = DataModel.table.fields();
        const fieldTypeChecker = FieldTypeChecker.create().setFieldsFromMap(fields);
        const filterTypeMismatch = fieldTypeChecker.filterTypeMismatch(filter);
        const fieldExist = filterPanel.fieldExist(filter);
        filterPanel.filterTypeMismatch[index] = fieldExist && filterTypeMismatch;
        filterPanel.filterValid[index] = fieldExist && !filterTypeMismatch;
      });
    }
  }

  function setCriteriaFilterProps(filterList = [], fieldFormatIdentifier) {
    filterList.forEach(filter => {
      const { filters: criteriaFilters } = filter;
      filterPanel.criteriaFilterProps[filter.name] = !criteriaFilters
        ? undefined
        : criteriaFilters.map(criteriaFilter => ({
            connector: criteriaFilter.connector,
            fieldFormatIdentifier: fieldFormatIdentifier,
            fieldName: filter.name,
            filterName: filter.name,
            isActive: filter.active,
            isRemoveHighlighted: criteriaFilter.removeHighlight,
            operator: criteriaFilter.operator,
            showFilterConnector:
              criteriaFilter.connector && (filter.quickFilter || !isLastFilter(filter, criteriaFilter)),
            value1: criteriaFilter.values[0],
            value2: criteriaFilter.values[1],
            showStoryboardIndicator: criteriaFilter.showStoryboardIndicator || false,
          }));
    });
  }

  filterPanel.applyFilters = applyFilters;
  filterPanel.canApplyFilters = canApplyFilters;

  function updateSort(fieldId, sortOrder) {
    eventService.publish("sortUpdated", fieldId, sortOrder);
  }

  function removeSort() {
    eventService.publish("sortUpdated", DataModel.filterConfig.sortFieldId(), DataModel.filterConfig.sortOrder());
  }

  filterPanel.onAddFilterFieldClick = fieldName => {
    const filterPanelDropdownPosition = { top: "109px", left: "9999px" };
    quickMenuToggler.toggle(filterPanelDropdownPosition, fieldName);
  };

  filterPanel.removeAllFilterByFieldName = function(fieldName) {
    let filterWasActive = true;
    if (!DataFilter.isFilterActive(DataModel.getFilterConfigDeprecated(), fieldName)) {
      filterWasActive = false;
      setFilterActive(DataModel.getFilterConfigDeprecated(), fieldName);
    }

    MultiSelectFilterStore.clearValues(fieldName);

    const quickFilterRemoved = applyQuickFilter(DataModel.getFilterConfigDeprecated(), fieldName);
    const criteriaFilterRemoved = DataFilter.removeAllCriteriaFilter(DataModel.getFilterConfigDeprecated(), fieldName);

    const requireReload = quickFilterRemoved || criteriaFilterRemoved;

    MultiSelectFilterStore.clearChanges(fieldName);
    const field = DataModel.table.field(fieldName);
    DataFilter.clearNewCriteriaFilter(field);
    DataModel.table.field(fieldName, field);

    if (requireReload) {
      DataModel.filtersChanged = true;
      canApplyFilters();
      eventService.publish("filterPanel.filtersChanged");
    }
  };

  filterPanel.fieldExist = filter => {
    const fields = DataModel.table.fields();
    return !!fields[filter.name];
  };

  filterPanel.removeFilter = function(fieldName) {
    // remove from save viz
    DataModel.filterConfig.removeFilter(fieldName);
    applyFilters(true);
  };

  function setFilterActive(filterConfig, fieldName, value = true) {
    const filterIndex = DataFilter.findFilterIndexByName(filterConfig, fieldName);
    if (filterIndex >= 0) {
      filterConfig.filterList[filterIndex].active = value;
    }
  }

  filterPanel.toggleFilter = function(filter) {
    if (filter.wasActiveAfterSave == undefined) {
      filter.wasActiveAfterSave = !filter.active;
    }
    filter.isActiveBeforeSave = filter.active;
    filter.active = !filter.active;
    if (!filter.active) filterPanel.filterPanelProps.showFilterSection[filter.name] = false;
    else filterPanel.filterPanelProps.showFilterSection[filter.name] = true;
    if (filterPanel.canApplyFilters()) {
      eventService.publish("filterPanel.filtersChanged");
    }
  };

  function criteriaFilterChanged(filterConfig, fieldName) {
    if (isCurrentCriteriaFilterValid(filterConfig, fieldName)) {
      const filtersForField = DataFilter.filtersForField(filterConfig, fieldName);
      return some(filtersForField, function(filter) {
        return filter.changed;
      });
    }

    return false;
  }

  function valueFilterChanged(fieldName) {
    return MultiSelectFilterStore.hasChanges(fieldName);
  }

  function getDataType(fieldName) {
    const field = DataModel.table.field(fieldName);
    if (field) {
      return field.type;
    }
    return null;
  }
  filterPanel.getDataType = getDataType;

  function applyQuickFilter(filterConfig, fieldName) {
    let quickFilterApplied = false;
    if (valueFilterChanged(fieldName) && DataFilter.isFilterActive(filterConfig, fieldName)) {
      const type = DataModel.table.field(fieldName).type;
      const checkedValues = MultiSelectFilterStore.getCheckedValues(fieldName);
      const unCheckedValues = MultiSelectFilterStore.getUnCheckedValues(fieldName);
      const selectAllState = MultiSelectFilterStore.getSelectAllState(fieldName);
      quickFilterApplied = DataFilter.resetFilterConfigQuickFilterValues(
        filterConfig,
        fieldName,
        type,
        checkedValues,
        unCheckedValues,
        selectAllState
      );

      MultiSelectFilterStore.resetSelectionsPostApplyFilter(fieldName);
    }
    return quickFilterApplied;
  }

  function isCurrentCriteriaFilterValid(filterConfig, fieldName) {
    return DataFilter.areChangedFieldCriteriaFiltersValid(filterConfig, fieldName);
  }

  function applyFilters(forcedChange, isFromQuickMenu, isApplyFilter, addFilter) {
    UsageTracker.mark("filterPanel.applyFilter");
    filterPanel.isApplyFilter = isApplyFilter;
    const tableFields = DataModel.table.fields();
    const filterConfig = DataModel.getFilterConfigDeprecated();
    const applyFieldFiltersFunc = fieldName => applyFieldFilters(filterConfig, fieldName, addFilter);
    const updatedFilterNames = _(tableFields)
      .keys()
      .filter(applyFieldFiltersFunc)
      .each(showFilterSectionOnFilterPanel);
    const isNotEmpty = updatedFilterNames.length === 0;
    if (!isNotEmpty) {
      DataModel.filtersChanged = true;
      eventService.publish("filterPanel.filtersChanged");
    }

    if (!isNotEmpty || forcedChange || configFilterChanged(filterConfig) || DataModel.filtersChanged) {
      eventService.publish("quickMenu.close");
      if (!isFromQuickMenu) {
        eventService.publish("filterPanel.filterChange", filterConfig, undefined, isApplyFilter);
        DataModel.filtersChanged = false;
      }
      updatedFilterNames.forEach(filterName => {
        resetFilter(filterName);
      });
      resetActiveFilter(filterConfig);
      canApplyFilters();
    }
  }

  function resetActiveFilter(filterConfig) {
    if (filterConfig.filterList) {
      filterConfig.filterList.forEach(function(config) {
        config.isActiveBeforeSave = config.active;
        delete config.wasActiveAfterSave;
        delete config.isActiveBeforeSave;
      });
    }
  }

  function resetFilter(fieldName) {
    resetCriteriaFilter(fieldName);
    MultiSelectFilterStore.clearChanges(fieldName);
  }

  function resetCriteriaFilter(fieldName) {
    resetCriteriaFilterChangeFlag(fieldName);

    const field = DataModel.table.field(fieldName);
    DataFilter.clearNewCriteriaFilter(field);
    DataModel.table.field(fieldName, field);
  }

  function resetCriteriaFilterChangeFlag(fieldName) {
    const filterConfig = DataModel.getFilterConfigDeprecated();
    const fieldFilterIndex = DataFilter.findFilterIndexByName(filterConfig, fieldName);
    if (fieldFilterIndex >= 0 && filterConfig.filterList[fieldFilterIndex].filters) {
      for (let iFilter = 0; iFilter < filterConfig.filterList[fieldFilterIndex].filters.length; iFilter++) {
        if (filterConfig.filterList[fieldFilterIndex].filters[iFilter].changed) {
          delete filterConfig.filterList[fieldFilterIndex].filters[iFilter].changed;
        }
      }
    }
  }

  function applyFieldFilters(filterConfig, fieldName, addFilter) {
    const requiredReload =
      applyCriteriaFilter(filterConfig, fieldName, addFilter) || criteriaFilterChanged(filterConfig, fieldName);
    //FIXME: is this the way to check?
    const quickFilterUpdated = applyQuickFilter(filterConfig, fieldName);
    return requiredReload || quickFilterUpdated;
  }

  //FIXME: This check for change checks against real filters. need check with passed in filters
  function canApplyFilters() {
    return (
      DataModel.filtersValid() &&
      !DataFilter.hasInvalidFilters(DataModel.getFilterConfigDeprecated()) &&
      hasChangedFilters()
    );
  }

  function hasChangedFilters() {
    const filterNames = map(filterPanel.filterList, "name");
    const filterConfig = DataModel.getFilterConfigDeprecated();
    if (filterNames.length == 0) {
      return DataModel.filtersChanged;
    } else {
      return some(filterNames, function(filterName) {
        return (
          valueFilterChanged(filterName) ||
          criteriaFilterChanged(filterConfig, filterName) ||
          configFilterChanged(filterConfig) ||
          DataModel.filtersChanged
        );
      });
    }
  }

  function configFilterChanged(filterConfig) {
    return some(filterConfig.filterList, function(filter) {
      if (typeof filter.wasActiveAfterSave === "boolean" && !filter.wasActiveAfterSave === filter.active) {
        return false;
      } else if (typeof filter.isActiveBeforeSave === "boolean" && !filter.isActiveBeforeSave === filter.active) {
        return true;
      }
    });
  }

  function hasNewFilter(fieldName) {
    const newFilter = DataModel.table.field(fieldName).criteriaFilter.newFilter;
    return some(newFilter, function(filterValue) {
      return !!filterValue;
    });
  }

  function applyCriteriaFilter(filterConfig, fieldName, addFilter) {
    let criteriaFilterApplied = false;
    const field = DataModel.table.field(fieldName);
    const type = field.type;
    const newFilter = field.criteriaFilter.newFilter;

    if (DataFilter.isNewCriteriaFilterValid(newFilter)) {
      const operator = newFilter.operator;
      const values = [newFilter.value];
      if (operator === "between") {
        values.push(newFilter.value2);
      }
      const connector = "or";
      criteriaFilterApplied = DataFilter.addFilter(filterConfig, fieldName, type, operator, values, connector);
    } else if (hasNewFilter(fieldName)) {
      newFilter.error = {};
      if (!newFilter.operator) {
        newFilter.error.operator = true;
      }
      if (!newFilter.value) {
        newFilter.error.value = true;
      }
      if (!newFilter.value2 && newFilter.operator === "between") {
        newFilter.error.value2 = true;
      }
    } else {
      DataFilter.updateFilter(filterConfig, fieldName);
    }

    return criteriaFilterApplied;
  }

  filterPanel.closeFilterPanel = function() {
    eventService.publish("filterPanel.close");
  };

  function setQuickFilters(event, columnValues) {
    let pendingReload = false;
    const filterConfig = DataModel.getFilterConfigDeprecated();
    columnValues.forEach(function(columnValue) {
      const field = columnValue.field;
      const fieldName = field.fieldName;

      const filterIndex = DataFilter.findFilterIndexByName(filterConfig, fieldName);
      if (filterIndex >= 0 && !filterConfig.filterList[filterIndex].active) {
        filterConfig.filterList[filterIndex].active = true; //Ensure filter is not toggled off
        pendingReload = true;
      }

      DataFilter.removeAllCriteriaFilter(filterConfig, fieldName);
      MultiSelectFilterStore.clearValues(fieldName);

      if (field.type === "character") {
        MultiSelectFilterStore.toggleValue(fieldName, columnValue.value);
      } else {
        const modelField = DataModel.table.field(fieldName);
        modelField.criteriaFilter.newFilter = {
          operator: "=",
          value: columnValue.value,
        };
        DataModel.table.field(fieldName, modelField);
      }

      const requiresReload =
        applyCriteriaFilter(filterConfig, fieldName) || criteriaFilterChanged(filterConfig, fieldName);
      const quickFilterUpdated = applyQuickFilter(filterConfig, fieldName);
      pendingReload = pendingReload || requiresReload || quickFilterUpdated;
      if (requiresReload || quickFilterUpdated) {
        showFilterSectionOnFilterPanel(fieldName);
        resetFilter(fieldName);
      }
    });

    if (pendingReload) {
      eventService.publish("filterPanel.filterChange", filterConfig);
    }
  }

  function disableQuickFilters(event, columnValues) {
    let pendingReload = false;
    const filterConfig = DataModel.getFilterConfigDeprecated();
    columnValues.forEach(function(columnValue) {
      const fieldName = columnValue.fieldName;
      const filterIndex = DataFilter.findFilterIndexByName(filterConfig, fieldName);

      if (filterIndex >= 0 && filterConfig.filterList[filterIndex].active) {
        filterConfig.filterList[filterIndex].active = false;
        pendingReload = true;
      }
    });

    if (pendingReload) {
      eventService.publish("filterPanel.filterChange", filterConfig);
    }
  }

  function showFilterSectionOnFilterPanel(fieldName) {
    filterPanel.filterPanelProps.showFilterSection[fieldName] = true;
    if (!filterPanel.isApplyFilter) {
      eventService.publish("filterPanel.open");
    }
  }

  filterPanel.filterConnectorProps = {
    items: [
      { label: Localize.getLocalizedString("_FilterPanel.Connector.AND.Label_"), value: "and" },
      { label: Localize.getLocalizedString("_FilterPanel.Connector.OR.Label_"), value: "or" },
    ],
    onSelect: (selectedOperator, criteriaFilter) => {
      criteriaFilter.connector = selectedOperator;
      filterPanel.criteriaFilterPropsHandlers.connectorChanged(criteriaFilter);
    },
  };

  function toggleMyRecords() {
    UsageTracker.mark("filterPanel.toggleMyRecords");
    filterPanel.remediationFilters.myRecords = DataModel.filterConfig.toggleMyRecords();
    const filterConfig = DataModel.getFilterConfigDeprecated();
    eventService.publish("filterPanel.filterChange", filterConfig);
  }

  function toggleOpenStatuses() {
    UsageTracker.mark("filterPanel.toggleOpenStatuses");
    filterPanel.remediationFilters.openStatuses = DataModel.filterConfig.toggleOpenStatuses();
    const filterConfig = DataModel.getFilterConfigDeprecated();
    eventService.publish("filterPanel.filterChange", filterConfig);
  }

  function showFilterToggles(canRemediateRecords) {
    return canRemediateRecords && !isMetrics($state.current.name);
  }
}

export default aclFilterPanel;
