class UrlFlipper {
  static getUrl() {
    return decodeURIComponent(window.location.href);
  }
  static getParams(param) {
    const url = this.getUrl();
    const queryString = url.split("?")[1] || "";
    const params = queryString.split("&");
    for (let i = 0; i < params.length; i++) {
      let paramString = params[i].split("=");
      if (paramString[0] === param) return paramString[1];
    }
    return;
  }
  static getUrlEnabledFlippers() {
    const params = this.getParams("enabledFeatures");
    const enabledFeatures = params && params.split(",");
    if (enabledFeatures) return zipAsFlippers(enabledFeatures);
    else return {};
  }

  static isRenderMode(modeType) {
    switch (modeType) {
      case "boost":
        return true;
      case "export":
        return this.getParams("mode") === modeType;
      default:
        return this.getParams("mode") === modeType;
    }
  }
  static isEnabled(flag) {
    return this.getParams(flag) === "true";
  }
}

const zipAsFlippers = enabledFeatures => {
  const urlEnabledFlippers = {};
  enabledFeatures.forEach(enabledFeature => {
    urlEnabledFlippers[enabledFeature] = true;
  });
  return urlEnabledFlippers;
};

export default UrlFlipper;
