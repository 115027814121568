import FieldFormat from "./fieldFormat";

function serializeFieldFormatMap(fieldFormatMap) {
  const result = {};
  fieldFormatMap.forEach((fieldFormat, fieldName) => {
    result[fieldName] = fieldFormat.toJson();
  });
  return result;
}

function deserializeFieldFormatMap(fieldFormatMapData) {
  const result = new Map();
  Object.keys(fieldFormatMapData).forEach(fieldName => {
    result.set(fieldName, FieldFormat.fromJson(fieldFormatMapData[fieldName]));
  });
  return result;
}

export default {
  serializeFieldFormatMap,
  deserializeFieldFormatMap,
};
