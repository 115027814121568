import Model from "../model";
import Table from "../table/table";

class ChartData extends Model {
  static modelType = "ChartData";

  constructor(data) {
    super({ table: new Table(), ...data });
  }

  type(...args) {
    return this.accessor("type", args, "string");
  }

  table(...args) {
    return this.accessor("table", args, Table);
  }

  dataConfig(...args) {
    return this.accessor("dataConfig", args, Object);
  }

  data(...args) {
    return this.accessor("data", args, Object);
  }

  representation(...args) {
    return this.accessor("representation", args, [Object]);
  }
}

export default ChartData;
