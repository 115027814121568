import React from "react";
import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import merge from "lodash/merge";
import uniq from "lodash/uniq";
import min from "lodash/min";
import max from "lodash/max";
import { HEAT_MAP_CHART } from "@visualizer/modules/visualization/highCharts/boost/boostThresholds";
import pubsub from "pubsub-js";
import ReactHighChart from "../highChart/reactHighChart";
import Sorter from "../../../services/sorters/sorter";
import ChartColorConfigHelper from "@viz-ui/services/charts/chartColorConfigHelper";

import { updateLabelFontSize, baseConfig, configPath, clickWrapper, canAnimate } from "../services/highChartService";

class HeatMap extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    config: PropTypes.object,
    rawData: PropTypes.array,
    redrawIndex: PropTypes.number,
    zoomInHandler: PropTypes.func,
  };

  static defaultProps = {
    index: 0,
    config: {},
    rawData: [],
    redrawIndex: 0,
    zoomInHandler: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      hcConfig: {},
      chartReDraw: false,
    };
  }

  componentDidMount() {
    pubsub.subscribe("chartRedraw", () => {
      this.setState({ chartReDraw: !this.state.chartReDraw });
    });

    this.setState({ hcConfig: this.getConfig(this.props.config, this.props.rawData) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.config !== nextProps.config)
      this.setState({ hcConfig: this.getConfig(nextProps.config, nextProps.rawData) });
  }

  getConfig = (config, data) => {
    let allDataPoints = data.reduce((all, series) => all.concat(series.values), []);
    let xAxis = {
      categories: Sorter.sort(uniq(allDataPoints.map(d => d.x)), {
        dataType: config.xAxis.axisType === "character" ? "character" : "numeric",
      }),
    };
    let yAxis = {
      categories: Sorter.sort(uniq(allDataPoints.map(d => d.y)), {
        dataType: config.yAxis.axisType === "character" ? "character" : "numeric",
      }),
    };
    let colorAxis = {
      colorStops: ChartColorConfigHelper.getColorStops(
        config.colorAxis.colorStops,
        min(allDataPoints.map(p => p.size)),
        max(allDataPoints.map(p => p.size))
      ),
    };
    colorAxis.min = colorAxis.colorStops[0].value;
    colorAxis.max = colorAxis.colorStops[2].value;

    let hcColorAxis = config.binMode
      ? {
          dataClasses: ChartColorConfigHelper.getColorBins(colorAxis.colorStops, config.colorAxis.numBins),
        }
      : {
          stops: colorAxis.colorStops.map((stop, i) => [
            (colorAxis.colorStops[i].value - colorAxis.min) / (colorAxis.max - colorAxis.min),
            stop.color,
          ]),
          min: colorAxis.min,
          max: colorAxis.max,
          endOnTick: !isFinite(config.colorAxis.colorStops[2].value),
          startOnTick: !isFinite(config.colorAxis.colorStops[0].value),
        };
    hcColorAxis.labels = {
      formatter: function() {
        return config.colorAxis.valueFormatter(this.value);
      },
    };

    // pattern-fill does not support gradient axis
    if (this.isPatternFill && hcColorAxis.dataClasses) {
      Object.entries(hcColorAxis.dataClasses).forEach((v, index) => {
        v[1].color = this.patternFillPalettes(index);
      });
    }

    let hcConfig = {
      legend: {
        align: "right",
        enabled: config.showLegend,
        verticalAlign: "top",
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: config.displayDataLabels,
            formatter: function() {
              return config.colorAxis.valueFormatter(this.point.value);
            },
          },
          point: {
            events: {
              click: clickWrapper(null, point => {
                this.zoomIn([point.xValue, point.yValue]);
              }),
            },
          },
          tooltip: {
            headerFormat: "",
            pointFormatter: function() {
              return `
                <b>${config.xAxis.displayName}: </b>
                <span>${config.xAxis.valueFormatter(this.xValue)}</span>
                <br/>
                <b>${config.yAxis.displayName}: </b>
                <span>${config.yAxis.valueFormatter(this.yValue)}</span>
                <br/>
                <b>${config.valueLabel}: </b>
                <span>${config.colorAxis.valueFormatter(this.value)}</span>
              `;
            },
          },
          turboThreshold: 0,
          animation: canAnimate(),
        },
      },
      xAxis: {
        categories: xAxis.categories,
        labels: {
          formatter: function() {
            return config.xAxis.valueFormatter(this.value);
          },
        },
        tickmarkPlacement: "between",
        title: {
          text: config.xAxis.label,
        },
      },
      yAxis: {
        categories: yAxis.categories,
        tickmarkPlacement: "between",
        title: {
          text: config.yAxis.label,
        },
        labels: {
          formatter: function() {
            return config.yAxis.valueFormatter(this.value);
          },
        },
      },
      colorAxis: hcColorAxis,
      series: data.map((series, i) => ({
        type: "heatmap",
        name: series.key,
        color: series.color,
        colorIndex: i,
        boostThreshold: HEAT_MAP_CHART,
        visible: series.visible !== false,
        data: series.values.map(value => ({
          x: xAxis.categories.indexOf(value.x),
          y: yAxis.categories.indexOf(value.y),
          xValue: value.x,
          yValue: value.y,
          value: value.size,
        })),
      })),
    };
    hcConfig = updateLabelFontSize(hcConfig, configPath);

    hcConfig.showValue = config.displayDataLabels;
    return merge({}, baseConfig(), hcConfig);
  };

  zoomIn = keyArray => {
    if (isFunction(this.props.zoomInHandler)) {
      this.props.zoomInHandler({
        xAxisName: keyArray[keyArray.length - 2],
        yAxisName: keyArray[keyArray.length - 1],
      });
    }
  };

  render() {
    return (
      <ReactHighChart
        className={`redraw-index-${this.props.redrawIndex}`}
        config={this.state.hcConfig}
        redrawIndex={this.props.redrawIndex}
        chartReDraw={this.state.chartReDraw}
      />
    );
  }
}

export default HeatMap;
