angular.module("acl.visualizer.fieldSort").directive("aclFieldSort", function(Localize) {
  function FieldSortCtrl($scope) {
    var self = this;

    init();

    function init() {
      self.isExpanded = false;
      self.showSortControls = true;
      self.sortFieldOptions = [];
      self.showRemoveButton = false;
      self.isDigitalSortType = false;
      self.isAscActive = false;
      self.isDescActive = false;
      self.isCertificationsTable = false;

      $scope.$watch("isSortEnabled", function(newValue) {
        self.showSortControls = newValue;
        self.showNotSupportedMessage = !newValue;
      });

      $scope.$watchCollection("sortFieldOptions", function(newValue) {
        self.sortFieldOptions = newValue;
        updateIsDigitalSortType();
      });

      $scope.$watch("certificationsTable", function(newValue) {
        self.isCertificationsTable = newValue;
      });

      $scope.$watch("sortFieldId", function(newValue) {
        if (typeof newValue === "undefined") {
          // Won't initialize properly as undefined remains empty
          self.sortFieldId = null;
        } else {
          self.sortFieldId = newValue;
        }
        updateIsDigitalSortType();
        updateShowRemoveButton();
        updateIsExpanded();
        updateExpandTooltip();
      });

      $scope.$watch("sortOrder", function(newValue) {
        self.sortOrder = newValue;
        updateShowRemoveButton();
        updateIsExpanded();
        updateExpandTooltip();
      });

      self.sortFieldChange = function() {
        updateIsDigitalSortType();
        updateShowRemoveButton();
        updateExpandTooltip();
        sortChanged();
      };

      self.sortAscClick = function() {
        self.isAscActive = true;
        self.isDescActive = false;
        self.sortOrder = "asc";
        updateShowRemoveButton();
        updateExpandTooltip();
        sortChanged();
      };

      self.sortDescClick = function() {
        self.isDescActive = true;
        self.isAscActive = false;
        self.sortOrder = "desc";
        updateShowRemoveButton();
        updateExpandTooltip();
        sortChanged();
      };

      self.removeSort = function($event) {
        self.sortFieldId = undefined;
        self.sortOrder = undefined;
        updateShowRemoveButton();
        updateExpandTooltip();
        $scope.onRemove();
        if ($event) $event.stopPropagation();
      };

      self.getDataTypeByName = function(fieldName, sortFieldOptions) {
        if (!fieldName || !sortFieldOptions) return;
        const field = sortFieldOptions.find(fieldOption => fieldOption.name === fieldName);
        return field && field.type;
      };
    }

    function updateIsExpanded() {
      self.isExpanded = self.sortFieldId || self.sortOrder;
    }

    function updateExpandTooltip() {
      var key;
      if (hasSort()) {
        key = "_FilterPanel.Sort.Expand.WhenSortExists.Tooltip_";
      } else {
        key = "_FilterPanel.Sort.Expand.WhenNoSort.Tooltip_";
      }
      self.expandTooltip = Localize.getLocalizedString(key);
    }

    function updateShowRemoveButton() {
      self.showRemoveButton = hasSort();
    }

    function updateIsDigitalSortType() {
      self.isDigitalSortType = isDigitalSortType(self.sortFieldId);
    }

    function isDigitalSortType(fieldId) {
      var i;
      var field;
      if (!self.sortFieldOptions) return false;
      for (i = 0; i < self.sortFieldOptions.length; i++) {
        field = self.sortFieldOptions[i];
        if (field.id === fieldId) {
          return !!field.isDigitalSortType;
        }
      }
      return false;
    }

    function sortChanged() {
      if (hasSort()) {
        $scope.onChange({
          fieldId: self.sortFieldId,
          sortOrder: self.sortOrder,
        });
      }
    }

    function hasSort() {
      return !!(self.sortFieldId && self.sortOrder);
    }
  }

  return {
    restrict: "E",
    replace: true,
    scope: {
      isSortEnabled: "=",
      sortFieldOptions: "=",
      sortFieldId: "=",
      certificationsTable: "=",
      sortOrder: "=",
      onChange: "&",
      onRemove: "&",
    },
    templateUrl: "visualizer/js/modules/core/fieldSort/fieldSort.tpl.html",
    controller: FieldSortCtrl,
    controllerAs: "vm",
  };
});
