(() => {
  const aclLoadingModal = {
    restrict: "E",
    replace: true,
    template: `<div class="acl-loading">
          <aclui-spinner></aclui-spinner>
      </div>`,
  };

  angular.module("acl.visualizer.loadingAnimation").component("aclLoadingModal", aclLoadingModal);
})();
