import InterpretationDataStore from "./interpretationDataStore.service";
import InterpretationTableDataStore from "./interpretationTableDataStore.service";
import MetricDataStore from "./metricDataStore.service";
import VisualizationDataStore from "./visualizationDataStore.service";

angular
  .module("acl.storyboard.dataStore", [])
  .service("InterpretationDataStore", () => InterpretationDataStore)
  .service("InterpretationTableDataStore", () => InterpretationTableDataStore)
  .service("MetricDataStore", () => MetricDataStore)
  .service("VisualizationDataStore", () => VisualizationDataStore);
