import FieldValueDataSource from "./fieldValueDataSource";

class FieldValueStore {
  constructor() {
    this._dataSourceMap = new Map();
  }

  getDataSource(requester, requestData) {
    const cacheKey = requester.getCacheKey(requestData);
    let ds = this._dataSourceMap.get(cacheKey);
    if (typeof ds === "undefined") {
      ds = new FieldValueDataSource(requester, requestData);
      this._dataSourceMap.set(cacheKey, ds);
    }
    return ds;
  }

  reloadAll() {
    this._dataSourceMap.forEach(ds => {
      ds.reload();
    });
  }
}

export default new FieldValueStore();
