import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import AcluiSingleselect from "acl-ui/components/Multiselect/LazySingleselectWithSections";
import i18n from "@viz-ui/i18n/i18n";
import FilterValue from "@viz-ui/models/storyboardFilter/filterValue";
import FilterValueService from "@viz-ui/services/storyboardFilter/filterValueService";
import "./singleselect.scss";

export default class Singleselect extends PureComponent {
  static propTypes = {
    allItemsLoaded: PropTypes.bool,
    fieldFormatIdentifier: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    filterString: PropTypes.string,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isMandatory: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.instanceOf(FilterValue)).isRequired,
    onChangeFilterString: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    onClickItem: PropTypes.func.isRequired,
    onLoadMore: PropTypes.func.isRequired,
    selectedItem: PropTypes.instanceOf(FilterValue),
  };

  static defaultProps = {
    allItemsLoaded: false,
    fieldFormatIdentifier: "",
    isDisabled: false,
    isLoading: false,
    isMandatory: false,
  };

  static itemPropType = {
    value: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.itemLookup = new Map();
  }

  componentWillMount() {
    this.ensureMandatorySelection(this.props);
    this.buildLookup(this.props.items);
    this.setTransformItemObjectsInState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.ensureMandatorySelection(nextProps);
    this.buildLookup(nextProps.items);
    this.setTransformItemObjectsInState(nextProps);
  }

  buildLookup(items) {
    this.itemLookup = new Map();
    items.forEach(filterValue => {
      const id = FilterValueService.hashCode(filterValue.value());
      if (!this.itemLookup.has(id)) this.itemLookup.set(id, filterValue);
    });
  }

  ensureMandatorySelection(props) {
    if (props.isMandatory && !props.selectedItem && props.items.length) {
      props.onClickItem(props.items[0]);
    }
  }

  setTransformItemObjectsInState(props) {
    this.setState({
      sections: this.transformSections(),
      selectedItemobject: props.selectedItem ? this.createItemObject(props.selectedItem) : null,
    });
  }

  transformSections() {
    const transformedItems = [];
    this.itemLookup.forEach(item => transformedItems.push(this.createItemObject(item)));
    return [{ heading: "", items: transformedItems }];
  }

  createItemObject = filterValue => ({
    id: FilterValueService.hashCode(filterValue.value()),
    title: FilterValueService.getItemDisplayValue(filterValue, this.props.fieldFormatIdentifier, this.props.fieldName),
  });

  handleClickItem = itemObject => {
    this.props.onClickItem(this.itemLookup.get(itemObject.id));
  };

  render() {
    return (
      <AcluiSingleselect
        allItemsLoaded={this.props.allItemsLoaded}
        className="stb-singleselect"
        clearText={i18n.t("_Singleselect.ClearText.Label_")}
        placeholderText={i18n.t("_Singleselect.Placeholder_")}
        isDisabled={this.props.isDisabled}
        filterPlaceholder={i18n.t("_General.FilterField.Placeholder_")}
        filterString={this.props.filterString}
        isCollapsible={false}
        isLoading={this.props.isLoading}
        loadMoreText={i18n.t("_Select.LoadMore.Label_")}
        noResultsText={i18n.t("_General.NoMatches.Label_")}
        onClear={this.props.onClear}
        onClickItem={this.handleClickItem}
        onChangeFilterString={this.props.onChangeFilterString}
        onLoadMore={this.props.onLoadMore}
        sections={this.state.sections}
        showClear={!this.props.isMandatory}
        value={this.state.selectedItemobject}
      />
    );
  }
}
