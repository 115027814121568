import { UsageTracker } from "@visualizer/common/services/usageTracker/usageTracker";

angular.module("acl.visualizer.quickMenu").service("QuickMenuToggler", function(EventService) {
  var eventService = EventService.register("acl.visualizer.quickMenu.QuickMenuToggler");
  const quickMenuWidth = 707;
  const edgePadding = 16;

  const service = {
    toggle: function(menuPosition, fieldName, applyFilter) {
      UsageTracker.mark("quickMenu.toggle");
      eventService.publish("dataGrid.toggleQuickMenu", constrain(menuPosition), fieldName, applyFilter);
    },
  };

  return service;

  function constrain(pos) {
    const windowWidth = $(window).width();
    const result = angular.copy(pos);
    result.left = parseInt(result.left, 10);
    result.top = parseInt(result.top, 10);

    if (result.left < edgePadding) {
      result.left = edgePadding;
    }
    if (result.left > windowWidth - edgePadding - quickMenuWidth) {
      result.left = windowWidth - edgePadding - quickMenuWidth;
    }

    return result;
  }
});
