export default class RowConfigManager {
  static _rowConfigs = [];
  static _DEFAULT_ROW_HEIGHT = 350;

  static initRowConfigs(rowConfigs, rows = 0) {
    for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
      const rowConfig = rowConfigs[rowIndex] || { height: this._DEFAULT_ROW_HEIGHT };
      this._rowConfigs.push(rowConfig);
    }
  }

  static addRowConfig(rowIndex) {
    this._rowConfigs.splice(rowIndex, 0, { height: this._DEFAULT_ROW_HEIGHT });
  }

  static removeRowConfig(rowIndex) {
    this._rowConfigs.splice(rowIndex, 1);
  }

  static updateRowConfig(rowIndex, rowConfig) {
    this._rowConfigs[rowIndex] = rowConfig;
  }

  static getRowConfigs() {
    return this._rowConfigs;
  }

  static toJsonConfig() {
    var rowConfigs = {};
    this._rowConfigs.forEach((rowConfig, index) => {
      rowConfigs[index] = rowConfig;
    });
    return rowConfigs;
  }
}
