import React from "react";
import PropTypes from "prop-types";
import i18n from "@viz-ui/i18n/i18n";
import CommonSubPanel from "./CommonSubPanel";
import "./ProcessSubPanel.scss";

export default class ProcessSubPanel extends CommonSubPanel {
  static propTypes = {
    ...CommonSubPanel.propTypes,
    activeRowId: PropTypes.number.isRequired,
    height: PropTypes.number,
    statusIdFromDb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  render() {
    const bottomStyle = this.props.height ? { bottom: `${this.props.height}px` } : null;
    return (
      <div className="record-processing__process-panel record-processing__process-panel--visible" style={bottomStyle}>
        <CommonSubPanel
          {...this.props}
          minimizeIcon="arrow-down"
          minimizeIconSize={12}
          minimizeText={i18n.t("_RecordProcessing.Cancel_")}
          a11yText={i18n.t("_RecordProcessing.ProcessRecordA11y_")}
        />
      </div>
    );
  }
}
