import extend from "lodash/extend";
import uniq from "lodash/uniq";
import cloneDeep from "lodash/cloneDeep";

const FILTER_CONFIG_CONNECTORS = {
  and: "and",
  or: "or",
};
const QUERY_FILTER_CONNECTORS = {
  and: "#and",
  or: "#or",
};
const QUERY_NEGATE_OPERATOR = "#not";
// [eq, neq, lt, gt, lte, gte, between, isBlank, begins, contains, in]
const FILTER_CONFIG_OPERATORS = {
  "=": "in",
  is_blank: "isBlank",
  is_not_blank: "notBlank",
  "begins-with": "begins",
  contain: "contains",
  "!contain": "notContain",
  ">": "gt",
  "<": "lt",
  ">=": "gte",
  "<=": "lte",
  "^=": "begins",
  "!=": "neq",
  between: "between",
};
const METRIC_PERCENT_OF_OPERATORS = {
  "==": "eq",
  "is-blank": "isBlank",
  "is-not-blank": "notBlank",
  "does-not-contain": "notContain",
  "not-between": "notBetween",
};
const OPERATORS_MAP = { ...FILTER_CONFIG_OPERATORS, ...METRIC_PERCENT_OF_OPERATORS };
const EMPTY_VALUE_OPERATORS = ["isBlank", "notBlank"];

export default class FilterTranslator {
  static parse(filterList) {
    return joinRoot(filterList);
  }

  static parseGlobalFilters(globalFilters) {
    if (globalFilters.length > 1) {
      const translatedFilter = {};
      translatedFilter[QUERY_FILTER_CONNECTORS.and] = globalFilters.map(globalFilter =>
        fieldFilterNode(globalFilter.name, globalFilter.globalFilter)
      );
      return translatedFilter;
    }
    if (globalFilters.length === 1) {
      const globalFilter = globalFilters[0];
      return fieldFilterNode(globalFilter.name, globalFilter.globalFilter);
    }
  }

  static generateNotMalformedFilter(fields) {
    if (fields.length === 1) {
      return getNotMalformedFilter(fields[0]);
    }
    if (fields.length > 1) {
      const combinedNotMalformedFilter = {};
      combinedNotMalformedFilter[QUERY_FILTER_CONNECTORS.and] = fields.map(getNotMalformedFilter);
      return combinedNotMalformedFilter;
    }
  }
}

function joinRoot(filterList) {
  if (filterList === undefined || filterList.length === 0) return null;

  const treeRoot = {};
  if (filterList.length > 1) {
    treeRoot[QUERY_FILTER_CONNECTORS.and] = filterList.map(fieldFilter => fieldFilterToFilterTree(fieldFilter));

    return treeRoot;
  }
  return fieldFilterToFilterTree(filterList[0]);
}

function fieldFilterToFilterTree(fieldFilter) {
  if (fieldFilter.filters.length > 1) {
    return fieldFiltersTree(fieldFilter.name, fieldFilter.filters);
  }
  return fieldFilterNode(fieldFilter.name, fieldFilter.filters[0]);
}

function fieldFiltersTree(fieldName, filters) {
  const splitIndice = fieldFiltersSubtreeIndice(filters);
  if (splitIndice.length > 0) {
    const filterTree = {};
    filterTree[QUERY_FILTER_CONNECTORS.or] = splitSubtree(filters, splitIndice).map(subtree =>
      fieldFilterSubtree(fieldName, subtree)
    );
    return filterTree;
  }
  return fieldFilterSubtree(fieldName, filters);
}

function fieldFilterSubtree(fieldName, filters) {
  const operators = uniq(filters.map(filter => filter.operator));

  if (operators.length === filters.length && !hasBlankQuickFilter(filters)) {
    return combinedSubtree(fieldName, filters);
  }
  return fullSubtree(fieldName, filters);
}

function fieldFilterNode(fieldName, filter) {
  let filterFilterNodeObj = {};
  filterFilterNodeObj[fieldName] = filterNode(filter);
  if (isBlankQuickFilter(filter)) {
    filterFilterNodeObj = removeEmptyQuickFilterValue(filterFilterNodeObj, fieldName);
    return joinIsBlankFilter(filterFilterNodeObj, fieldName);
  }
  return filterFilterNodeObj;
}

function filterNode(filter) {
  const filterObj = {};
  const newOperator = OPERATORS_MAP[filter.operator] || filter.operator;
  if (newOperator) {
    filterObj[newOperator] = EMPTY_VALUE_OPERATORS.includes(newOperator) ? "" : getValues(newOperator, filter.values);
  }
  return filterObj;
}

function removeEmptyQuickFilterValue(filter, fieldName) {
  const filterObj = cloneDeep(filter);
  const quickFilterValues = filterObj[fieldName].in;
  const emptyValueIndex = quickFilterValues.findIndex(value => value === "");
  quickFilterValues.splice(emptyValueIndex, 1);
  return filterObj;
}

function joinIsBlankFilter(filter, fieldName) {
  const isBlankFilter = {};
  isBlankFilter[fieldName] = { isBlank: "" };
  const quickFilterHasNoValue = filter[fieldName].in.length === 0;
  if (quickFilterHasNoValue) {
    return isBlankFilter;
  }
  return {
    "#or": [filter, isBlankFilter],
  };
}

function fieldFiltersSubtreeIndice(filters) {
  const splitIndice = [];
  filters.forEach(function(filter, index) {
    const hasOrConnector = filter.connector === FILTER_CONFIG_CONNECTORS.or;
    const isNotLastFilter = index < filters.length - 1;
    if (hasOrConnector && isNotLastFilter) {
      splitIndice.push(index);
    }
  });
  return splitIndice;
}

function splitSubtree(fieldFilters, splitIndice) {
  let start = 0;
  let end;
  const subtrees = [];
  splitIndice.forEach(function(splitIndex, index) {
    end = splitIndex + 1;
    subtrees.push(fieldFilters.slice(start, end));
    start = end;
    if (index === splitIndice.length - 1) {
      subtrees.push(fieldFilters.slice(start));
    }
  });
  return subtrees;
}

function combinedSubtree(fieldName, filters) {
  const filterObj = {};
  filterObj[fieldName] = {};
  filterObj[fieldName] = filters.reduce(
    (filter, currFilter) => extend(filter, filterNode(currFilter)),
    filterObj[fieldName]
  );
  return filterObj;
}

function fullSubtree(fieldName, filters) {
  const filterObj = {};
  if (filters.length > 1) {
    filterObj[QUERY_FILTER_CONNECTORS.and] = filters.map(filter => fieldFilterNode(fieldName, filter));
    return filterObj;
  }
  return fieldFilterNode(fieldName, filters[0]);
}

function getValues(operator, values = []) {
  if (operator === "in" || values.length > 1) {
    return values;
  }
  if (values.length === 1) {
    return values[0];
  }
  return "";
}

function getNotMalformedFilter(fieldName) {
  const notMalformedFilter = {};
  const malformedFilter = {};
  malformedFilter[fieldName] = { isMalformed: "" };
  notMalformedFilter[QUERY_NEGATE_OPERATOR] = malformedFilter;
  return notMalformedFilter;
}

function hasBlankQuickFilter(filters) {
  return !!filters.find(isBlankQuickFilter);
}

function isBlankQuickFilter(filter) {
  return filter.operator === "=" && filter.values.includes("");
}
