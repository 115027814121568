import LoadingAnimationState from "@viz-ui/services/common/loadingAnimationStateService";

class LoadingAnimationStateInitializer {
  init(eventService) {
    const httpEvents = [
      "metricCreate",
      "metricRequest",
      "metricChange",
      "metricDelete",
      "metricsRequest",
      "metricSummaryRequest",
      "metricSparklineRequest",
      "tableRequest",
      "recordsetRequest",
    ];

    httpEvents.forEach(function(eventName) {
      eventService.subscribe("request." + eventName, function() {
        LoadingAnimationState.start(eventName);
      });

      eventService.subscribe("resolve." + eventName, function() {
        LoadingAnimationState.stop(eventName);
      });

      eventService.subscribe("reject." + eventName, function() {
        LoadingAnimationState.stop(eventName);
      });
    });
  }
}
export default new LoadingAnimationStateInitializer();
