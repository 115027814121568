import React from "react";
import PropTypes from "prop-types";
import DropdownMenu from "@paprika/overflow-menu";
import Styler from "@paprika/stylers";
import Button from "@paprika/button";
import OverflowIcon from "@acl-services/wasabicons/lib/Ellipsis";
import ConfigureIcon from "@acl-services/wasabicons/lib/Configure";
import FiltersIcon from "@acl-services/wasabicons/lib/Filter";
import CopyIcon from "@acl-services/wasabicons/lib/Copy";
import CsvIcon from "@acl-services/wasabicons/lib/Csv";
import DisplayIcon from "@acl-services/wasabicons/lib/ReportsLogo";
import Edit from "@acl-services/wasabicons/lib/Edit";
import ExcelIcon from "@acl-services/wasabicons/lib/DownloadExcel";
import FilterErrorIcon from "@acl-services/wasabicons/lib/ExclamationCircle";
import i18n from "@viz-ui/i18n/i18n";
import SaveButton from "@viz-ui/components/SaveButton";
import ToolTip from "@viz-ui/components/ToolTip";
import "./ActionButtons.scss";

const noop = () => {};

const propTypes = {
  exportToCsvTooltip: PropTypes.string,
  exportToXlsTooltip: PropTypes.string,
  hasDelete: PropTypes.bool,
  hasExportCsv: PropTypes.bool,
  hasExportXls: PropTypes.bool,
  hasFieldError: PropTypes.bool,
  hasNew: PropTypes.bool,
  hasRename: PropTypes.bool,
  hasSave: PropTypes.bool,
  hasSaveAs: PropTypes.bool,
  hasSaveConfirmation: PropTypes.bool,
  hasShareToGrc: PropTypes.bool,
  isConfigDisabled: PropTypes.bool,
  isExportDisabled: PropTypes.bool,
  isFilterDisabled: PropTypes.bool,
  isNew: PropTypes.bool,
  isOverflowDisabled: PropTypes.bool,
  isSaveDisabled: PropTypes.bool,
  isSaveAsDisabled: PropTypes.bool,
  onClickConfigure: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickExportToCsv: PropTypes.func,
  onClickExportToXls: PropTypes.func,
  onClickFilter: PropTypes.func,
  onClickNew: PropTypes.func,
  onClickRename: PropTypes.func,
  onClickSave: PropTypes.func,
  onClickSaveAs: PropTypes.func,
  onClickShareToGrc: PropTypes.func,
  saveTooltip: PropTypes.string,
  saveAsTooltip: PropTypes.string,
  saveButtonTooltip: PropTypes.string,
  hasCopyInterpretation: PropTypes.bool,
  onClickCopyInterpretation: PropTypes.func,
  isCopyInterpretationDisabled: PropTypes.bool,
  copyInterpretationTooltip: PropTypes.string,
  isRenameInterpretationDisabled: PropTypes.bool,
  hasRenameInterpretation: PropTypes.bool,
  renameInterpretationTooltip: PropTypes.string,
  onClickRenameInterpretation: PropTypes.func,
  showReportDownload: PropTypes.bool,
  onClickReportDownload: PropTypes.func,
  isReportDownloadDisabled: PropTypes.bool,
};

const defaultProps = {
  exportToCsvTooltip: null,
  exportToXlsTooltip: null,
  hasDelete: false,
  hasExportCsv: false,
  hasExportXls: false,
  hasFieldError: false,
  hasNew: false,
  hasRename: false,
  hasSave: false,
  hasSaveAs: false,
  hasSaveConfirmation: false,
  hasShareToGrc: false,
  isConfigDisabled: false,
  isExportDisabled: false,
  isFilterDisabled: false,
  isNew: false,
  isOverflowDisabled: false,
  isSaveDisabled: false,
  isSaveAsDisabled: false,
  hasCopyInterpretation: false,
  isCopyInterpretationDisabled: false,
  onClickCopyInterpretation: noop,
  onClickConfigure: noop,
  onClickDelete: noop,
  onClickExportToCsv: noop,
  onClickExportToXls: noop,
  onClickFilter: noop,
  onClickNew: noop,
  onClickRename: noop,
  onClickSave: noop,
  onClickSaveAs: noop,
  onClickShareToGrc: noop,
  saveTooltip: "",
  saveAsTooltip: "",
  saveButtonTooltip: "",
  copyInterpretationTooltip: null,
  isRenameInterpretationDisabled: false,
  hasRenameInterpretation: false,
  renameInterpretationTooltip: null,
  onClickRenameInterpretation: noop,
  showReportDownload: false,
  onClickReportDownload: noop,
  isReportDownloadDisabled: false,
};

function ActionButtons(props) {
  const {
    exportToCsvTooltip,
    exportToXlsTooltip,
    hasDelete,
    hasExportCsv,
    hasExportXls,
    hasFieldError,
    hasNew,
    hasRename,
    hasSave,
    hasSaveAs,
    hasSaveConfirmation,
    hasShareToGrc,
    isConfigDisabled,
    isExportDisabled,
    isFilterDisabled,
    isNew,
    isOverflowDisabled,
    isSaveDisabled,
    isSaveAsDisabled,
    onClickConfigure,
    onClickDelete,
    onClickExportToCsv,
    onClickExportToXls,
    onClickFilter,
    onClickNew,
    onClickRename,
    onClickSave,
    onClickSaveAs,
    onClickShareToGrc,
    saveTooltip,
    saveAsTooltip,
    saveButtonTooltip,
    hasCopyInterpretation,
    onClickCopyInterpretation,
    isCopyInterpretationDisabled,
    copyInterpretationTooltip,
    isRenameInterpretationDisabled,
    hasRenameInterpretation,
    renameInterpretationTooltip,
    onClickRenameInterpretation,
    showReportDownload,
    onClickReportDownload,
    isReportDownloadDisabled,
  } = props;

  const dropdownToolTipProps = {
    align: "left",
    className: "action-buttons__drop-down-tooltip",
    isDark: true,
    maxWidth: 240,
    offset: 0,
  };

  function renderSaveButton() {
    const saveButtonProps = {
      hasSave,
      hasSaveAs,
      hasSaveConfirmation,
      isSaveDisabled,
      isSaveAsDisabled,
      onClickSave,
      onClickSaveAs,
      saveTooltip,
      saveAsTooltip,
      tooltip: saveButtonTooltip,
    };

    return (
      <div className="action-buttons__save-button">
        <SaveButton {...saveButtonProps} />
      </div>
    );
  }

  function renderWithTooltip(node, message, toolTipProps = { align: "top" }) {
    return message ? (
      <ToolTip {...toolTipProps} content={message}>
        {node}
      </ToolTip>
    ) : (
      node
    );
  }

  function renderShareToGrcMenuItem() {
    if (!hasShareToGrc) return null;
    return (
      <DropdownMenu.Item isDisabled={isNew} className="action-buttons__share-to-grc" onClick={onClickShareToGrc}>
        {i18n.t("_ActionButtons.ShareToGRC.Label_")}
      </DropdownMenu.Item>
    );
  }

  function renderNewMenuItem() {
    if (!hasNew) return null;
    return (
      <DropdownMenu.Item isDisabled={isNew} className="action-buttons__new-button" onClick={onClickNew}>
        {i18n.t("_ActionButtons.New.Label_")}
      </DropdownMenu.Item>
    );
  }

  function renderRenameMenuItem() {
    if (!hasRename) return null;
    return (
      <DropdownMenu.Item isDisabled={isNew} className="action-buttons__rename-button" onClick={onClickRename}>
        {i18n.t("_ActionButtons.Rename.Label_")}
      </DropdownMenu.Item>
    );
  }

  function renderDeleteMenuItem() {
    if (!hasDelete) return null;
    return (
      <DropdownMenu.Item
        isDisabled={isNew}
        className="action-buttons__delete-button"
        onClick={onClickDelete}
        isDestructive
      >
        {i18n.t("_ActionButtons.Delete.Label_")}
      </DropdownMenu.Item>
    );
  }

  function renderExportCsvMenuItem() {
    if (!hasExportCsv) return null;
    return (
      <DropdownMenu.Item
        className="action-buttons__export-to-csv action-buttons_menu_item"
        onClick={onClickExportToCsv}
        isDisabled={isExportDisabled}
      >
        <span className="action-buttons__menu-item-icon">
          <CsvIcon size={Styler.spacer(2.3)} />
        </span>
        {renderWithTooltip(i18n.t("_ActionButtons.ExportCsv.Label_"), isExportDisabled && exportToCsvTooltip, {
          ...dropdownToolTipProps,
          "aria-label": i18n.t("_ActionButtons.ExportCsv.Label_"),
        })}
      </DropdownMenu.Item>
    );
  }

  function renderCopyInterpretationMenuItem() {
    if (!hasCopyInterpretation) return null;
    return (
      <DropdownMenu.Item
        className="action-buttons_menu_item"
        onClick={onClickCopyInterpretation}
        isDisabled={isCopyInterpretationDisabled}
      >
        <span className="action-buttons__menu-item-icon">
          <CopyIcon size={Styler.spacer(2.3)} />
        </span>
        {renderWithTooltip(
          i18n.t("_ActionButtons.CopyInterpretation.Label_"),
          isCopyInterpretationDisabled && copyInterpretationTooltip,
          {
            ...dropdownToolTipProps,
            "aria-label": i18n.t("_ActionButtons.CopyInterpretation.Label_"),
          }
        )}
      </DropdownMenu.Item>
    );
  }

  function renderRenameInterpretationMenuItem() {
    if (!hasRenameInterpretation) return null;
    return (
      <DropdownMenu.Item
        className="action-buttons_menu_item"
        onClick={onClickRenameInterpretation}
        isDisabled={isRenameInterpretationDisabled}
      >
        <span className="action-buttons__menu-item-icon">
          <Edit size={Styler.spacer(2.3)} />
        </span>
        {renderWithTooltip(
          i18n.t("_ActionButtons.RenameInterpretation.Label_"),
          isRenameInterpretationDisabled && renameInterpretationTooltip,
          {
            ...dropdownToolTipProps,
            "aria-label": i18n.t("_ActionButtons.RenameInterpretation.Label_"),
          }
        )}
      </DropdownMenu.Item>
    );
  }

  function renderExportXlsMenuItem() {
    if (!hasExportXls) return null;
    return (
      <DropdownMenu.Item
        className="action-buttons__export-to-xls action-buttons_menu_item"
        onClick={onClickExportToXls}
        isDisabled={isExportDisabled}
      >
        <span className="action-buttons__menu-item-icon">
          <ExcelIcon size={Styler.spacer(2.3)} />
        </span>
        {renderWithTooltip(i18n.t("_ActionButtons.ExportXls.Label_"), isExportDisabled && exportToXlsTooltip, {
          ...dropdownToolTipProps,
          "aria-label": i18n.t("_ActionButtons.ExportXls.Label_"),
        })}
      </DropdownMenu.Item>
    );
  }

  function renderReportDownloadMenuItem() {
    if (!showReportDownload) return null;
    return (
      <DropdownMenu.Item
        className="action-buttons__report-download action-buttons_menu_item"
        data-pendo-anchor="impact-report-results-table"
        onClick={onClickReportDownload}
        isDisabled={isReportDownloadDisabled}
      >
        <span className="action-buttons__menu-item-icon">
          <DisplayIcon size={Styler.spacer(2.3)} />
        </span>
        {renderWithTooltip(i18n.t("_ActionButtons.ReportDownload.Label_"), false, {
          ...dropdownToolTipProps,
          "aria-label": i18n.t("_ActionButtons.ReportDownload.Label_"),
        })}
      </DropdownMenu.Item>
    );
  }

  function renderOverflowMenu() {
    const contentText = !isOverflowDisabled
      ? i18n.t("_ActionButtons.Overflow.Tooltip_")
      : i18n.t("_ActionButtons.Overflow.Disable.Tooltip_");
    return (
      <DropdownMenu edge="right" minWidth={220} maxwidth={220} zIndex={350}>
        <DropdownMenu.Trigger
          className="action-buttons__overflow"
          tabIndex="0"
          buttonType="icon"
          kind="flat"
          isDisabled={isOverflowDisabled}
          a11yText={contentText}
        >
          <ToolTip content={contentText} align={!isOverflowDisabled ? "top" : "bottom"}>
            <OverflowIcon />
          </ToolTip>
        </DropdownMenu.Trigger>
        {renderShareToGrcMenuItem()}
        {renderNewMenuItem()}
        {renderRenameMenuItem()}
        {renderCopyInterpretationMenuItem()}
        {renderRenameInterpretationMenuItem()}
        {hasCopyInterpretation ? <DropdownMenu.Divider /> : null}
        {renderExportCsvMenuItem()}
        {renderExportXlsMenuItem()}
        {showReportDownload ? <DropdownMenu.Divider /> : null}
        {renderReportDownloadMenuItem()}
        {hasDelete ? <DropdownMenu.Divider /> : null}
        {renderDeleteMenuItem()}
      </DropdownMenu>
    );
  }

  function renderConfigButton() {
    const contentText = i18n.t("_ActionButtons.Configure.Tooltip_");
    return (
      <Button.Icon
        aria-haspopup
        className="action-buttons__configure-button"
        onClick={onClickConfigure}
        kind="flat"
        isSemantic={false}
        isDisabled={isConfigDisabled}
        a11yText={contentText}
      >
        <ToolTip content={contentText}>
          <ConfigureIcon />
        </ToolTip>
      </Button.Icon>
    );
  }

  function renderFilterButton() {
    const contentText = i18n.t("_ActionButtons.Filter.Tooltip_");
    return (
      <div className="action-buttons__filter-button-wrapper">
        <Button.Icon
          aria-haspopup
          className="action-buttons__filter-config-button"
          onClick={onClickFilter}
          isDisabled={isFilterDisabled}
          kind="flat"
          isSemantic={false}
          a11yText={contentText}
        >
          <ToolTip content={contentText}>
            <FiltersIcon />
          </ToolTip>
        </Button.Icon>

        {hasFieldError && <FilterErrorIcon className="action-buttons__filter-error-indicator" />}
      </div>
    );
  }

  return (
    <div className="action-buttons">
      {renderSaveButton()}
      {renderOverflowMenu()}
      {renderConfigButton()}
      {renderFilterButton()}
    </div>
  );
}

ActionButtons.propTypes = propTypes;
ActionButtons.defaultProps = defaultProps;

export default ActionButtons;
