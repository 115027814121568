import moment from "moment";
import { DateFormatter, DatetimeFormatter, TimeFormatter } from "@acl-services/sriracha-formatters/dist/Formatters";
import i18n from "@viz-ui/i18n/i18n";
import ConditionalFilterService from "@viz-ui/services/storyboardFilter/conditionalFilterService";
import GlobalValueFormatter from "@viz-ui/services/formatters/globalValueFormatter";

class InputFormatInterpreter {
  constructor(fieldFormatAttributes, flipHasBlankFilter, flipRelativeFilter) {
    this.fieldFormatAttributes = fieldFormatAttributes;
    this._flipHasBlankFilter = flipHasBlankFilter;
    this._flipRelativeFilter = flipRelativeFilter;
  }

  get _fieldFormatIdentifier() {
    return this.fieldFormatAttributes.fieldFormatIdentifier;
  }

  get _fieldName() {
    return this.fieldFormatAttributes.fieldName;
  }

  get _fieldFormat() {
    return this.fieldFormatAttributes.fieldFormat;
  }

  get _fieldType() {
    return this.fieldFormatAttributes.fieldType;
  }

  formatValue(value) {
    return GlobalValueFormatter.formatValue(value, this._fieldFormatIdentifier, this._fieldName);
  }

  displayDateFormatToken(useDefault) {
    if (useDefault) return getDateRawFormatToken(this._fieldType);
    return getDateFormatToken(this._fieldType, this._fieldFormat) || getDateRawFormatToken(this._fieldType);
  }

  getRawDisplayValue(rawValue) {
    if (typeof rawValue === "undefined") return "";
    let momentValue;

    if (this._fieldType === "time" || this._fieldType === "datetime") {
      const formatter = this._fieldType === "time" ? TimeFormatter : DatetimeFormatter;
      momentValue = moment(rawValue, formatter.rawFormat);
      if (momentValue.isValid()) {
        const utcOffset = getOffset();
        if (this._fieldType === "datetime" && utcOffset) {
          momentValue.add(utcOffset, "minutes");
        }
        return momentValue.format(formatter.rawFormat);
      }
    }
    return rawValue;
  }

  interpretTime(rawUserValue) {
    if (this._fieldType === "time") {
      const momentValue = moment(rawUserValue, TimeFormatter.rawFormat);
      if (momentValue.isValid()) {
        if (rawUserValue.toLowerCase().endsWith("pm") && momentValue.hours() < 12) {
          momentValue.add(12, "hours");
        }
        return momentValue.format(TimeFormatter.rawFormat);
      }
    }
    return rawUserValue;
  }

  interpretDate(rawUserValue) {
    const momentValue = moment(rawUserValue, getDateFormatToken(this._fieldType, this._fieldFormat), true);
    if (momentValue.isValid()) {
      return momentValue.format(getDateRawFormatToken(this._fieldType));
    }

    return rawUserValue;
  }

  handleInputChangeDatetimeOffset(rawUserValue) {
    let rawValue = rawUserValue;

    if (this._fieldType === "datetime") {
      const utcOffset = getOffset();
      if (utcOffset) {
        const momentValue = moment(rawValue, DatetimeFormatter.rawFormat);
        if (momentValue.isValid()) {
          momentValue.subtract(utcOffset, "minutes");
          rawValue = momentValue.format(DatetimeFormatter.rawFormat);
        }
      }
    }

    return rawValue;
  }

  isDateInputType() {
    return this._fieldType === "date" || this._fieldType === "datetime";
  }

  isFileOrSignatureInputType() {
    return this._fieldType === "file" || this._fieldType === "digital_signature";
  }

  getOperators() {
    return ConditionalFilterService.getConditionalOperators(
      this._fieldType,
      this._flipHasBlankFilter,
      this._flipRelativeFilter
    );
  }

  getInputPlaceholder() {
    switch (this._fieldType) {
      case "date":
        return i18n.t("_Conditional.Date.Placeholder_");
      case "datetime":
        return i18n.t("_Conditional.Datetime.Placeholder_");
      case "time":
        return i18n.t("_Conditional.Time.Placeholder_");
      default:
        return i18n.t("_Conditional.Input.Placeholder_");
    }
  }
}

function getOffset() {
  const timezoneOffset = GlobalValueFormatter.getTimezoneOffset();
  return timezoneOffset.utcOffsetMinutes();
}

function getDateRawFormatToken(fieldType) {
  return fieldType === "datetime" ? DatetimeFormatter.rawFormat : DateFormatter.rawFormat;
}

function getDateFormatToken(fieldType, fieldFormat) {
  const fieldFormatJson = fieldFormat && fieldFormat.toJson();
  const formatter = fieldType === "datetime" ? DatetimeFormatter : DateFormatter;
  return fieldFormat ? formatter.getFormat(fieldFormatJson) : formatter.defaultFormat();
}

export default InputFormatInterpreter;
