import ReactDirective from "acl-ui/angular/reactDirective.umd";
import Switch from "acl-ui/components/Switch";

import ChartConfigTabButtons from "@viz-ui/components/chartConfig/chartConfigTabButtons";
import MapChartToggleButton from "@viz-ui/components/chartConfig/mapChartToggleButton";
import Dropdown from "@viz-ui/components/dropdown/dropdown";
import SortConfig from "@viz-ui/components/sortConfig/sortConfig";
import ListBoxComponent from "@viz-ui/components/listBox/listBoxComponent";

import ChartConfigPanel from "./chartConfigPanel.component";
import ChartConfigSingleSelect from "./chartConfigSingleSelect.component";
import ChartDisplayConfigAdapter from "./chartDisplayConfigAdapter.service";
import SortConfigPanel from "./sortConfigPanel.component";

angular
  .module("acl.visualizer.chartConfigPanel", [
    "acl.visualizer.aclColorPicker",
    "acl.visualizer.aggregationSelector",
    "acl.visualizer.locationSelector",
    "acl.visualizer.charts",
    "acl.visualizer.chartColorPicker",
    "acl.visualizer.multiSelect",
    "acl.visualizer.style",
    "acl.visualizer.summaryTable.dataConfig",
    "acl.visualizer.treemap.dataConfig",
    "acl.visualizer.statistics.dataConfig",
    "acl.visualizer.combinationChart.dataConfig",
    "acl.visualizer.mapChart.dataConfig",
  ])
  .component("aclChartConfigPanel", ChartConfigPanel)
  .component("aclChartConfigSingleSelect", ChartConfigSingleSelect)
  .component("sortConfigPanel", SortConfigPanel)
  .service("ChartDisplayConfigAdapter", ChartDisplayConfigAdapter)
  .directive("chartConfigTabButtons", ReactDirective.getFactory(ChartConfigTabButtons))
  .directive("mapChartToggleButton", ReactDirective.getFactory(MapChartToggleButton))
  .directive("sortConfig", ReactDirective.getFactory(SortConfig))
  .directive("acluiSwitch", ReactDirective.getFactory(Switch))
  .directive("listBoxComponent", ReactDirective.getFactory(ListBoxComponent))
  .directive("dropdown", ReactDirective.getFactory(Dropdown));
