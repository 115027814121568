import moment from "moment";
import ApiPath from "@viz-ui/services/apiPath/apiPathService";

angular
  .module("acl.visualizer.charts.backend")
  .factory("ChartBackendService", function(DataFilter, DataModel, queuedHttp) {
    "use strict";
    var typeFormats = {
      date: "YYYY-MM-DD",
      datetime: {
        default: "YYYY-MM-DDTHH:mm:ss",
        timeZone: "YYYY-MM-DDTHH:mm:ssZ",
      },
      time: "HH:mm:ss",
    };

    var aggregateLabels = {
      sum: "",
      average: "a_",
      min: "m_",
      max: "x_",
      count: "COUNT",
    };

    function url(tableId, chartIndex) {
      return ApiPath.table.getSummarizeDataUrl(tableId) + "?chart_index=" + chartIndex;
    }

    function fetchJson(tableId, filter, config, isBiView) {
      var requestParams;
      if (isBiView) {
        requestParams = { raw: DataModel.toBiView("BarChart", config) };
      } else {
        requestParams = { raw: DataModel.toSaveViz() };
      }
      requestParams.visualizations = requestParams.visualizations || [];
      var chartIndex = requestParams.raw.visualizations
        .map(function(d) {
          return angular.equals(d.config.dataConfig, config);
        })
        .indexOf(true);

      var copy = angular.copy(requestParams);
      copy.raw.filterConfig = DataFilter.getFilterRequestBody(requestParams.raw.filterConfig);
      copy.myRecords = DataModel.filterConfig.myRecords();
      copy.openStatuses = DataModel.filterConfig.openStatuses();
      return queuedHttp.post(url(tableId, chartIndex), copy);
    }

    function getDatetimeFormatMask(value) {
      var timezoneExists = value && value.match(/([+,-])\d{2}:\d{2}/);
      return !timezoneExists ? typeFormats.datetime.default : typeFormats.datetime.timeZone;
    }

    function getParserForType(fieldType) {
      switch (fieldType) {
        case "datetime":
          return value => {
            const unixValue = moment.utc(value, getDatetimeFormatMask(value)).unix();
            return isNaN(unixValue) ? null : unixValue;
          };
        case "date":
          return value => {
            const unixValue = moment.utc(value, typeFormats[fieldType]).unix();
            return isNaN(unixValue) ? null : unixValue;
          };

        case "time":
          return value => {
            const unixValue = moment(value, typeFormats[fieldType]).unix();
            return isNaN(unixValue) ? null : unixValue;
          };

        case "numeric":
          return value => {
            if (value === null) return value;

            const parsedValue = Number(value);
            return isNaN(parsedValue) ? value : parsedValue;
          };

        case "character":
        case "logical":
          return value => {
            const backendBlankKey = "(blank)";
            const valueIsNotBlank = value && value !== backendBlankKey;
            return valueIsNotBlank ? value : backendBlankKey;
            //returning (blank) directly instead of localized string in order to fix the GRCPRD-4135 issue.
          };

        default:
          throw "Unknown data type.";
      }
    }

    function nest() {
      var nest = {};
      var keys = [];
      var skipNullKeys = false;
      var skipNullValues = false;
      var rollup;

      function group(array, depth) {
        if (depth >= keys.length) {
          return rollup ? rollup(array) : array;
        }

        var results = [];
        var uniqueKeyValues = {};
        var keyFunc = keys[depth];

        array.forEach(function(d) {
          var keyValue = keyFunc(d);
          if (keyValue === null && skipNullKeys) return;
          if (!uniqueKeyValues[keyValue]) {
            uniqueKeyValues[keyValue] = { key: keyValue, values: [] };
          }
          uniqueKeyValues[keyValue].values.push(d);
        });

        var sortedKeys = Object.keys(uniqueKeyValues).sort(function(a, b) {
          return uniqueKeyValues[a].key > uniqueKeyValues[b].key ? 1 : -1;
        });
        sortedKeys.forEach(function(key) {
          var result = { key: uniqueKeyValues[key].key, values: group(uniqueKeyValues[key].values, depth + 1) };
          if ((result.values !== null || !skipNullValues) && (depth >= keys.length - 1 || result.values.length > 0)) {
            results.push(result);
          }
        });

        return results;
      }

      nest.key = function(f) {
        keys.push(f);
        return nest;
      };
      nest.rollup = function(f) {
        rollup = f;
        return nest;
      };
      nest.excludeNullKeys = function(b) {
        skipNullKeys = b;
        return nest;
      };
      nest.excludeNullValues = function(b) {
        skipNullValues = b;
        return nest;
      };
      nest.entries = function(array) {
        return group(array, 0);
      };

      return nest;
    }

    return {
      getParserForType: getParserForType,
      fetchJson: fetchJson,
      nest: nest,
    };
  });
