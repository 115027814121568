import Model from "../model";
import FilterConfig from "../filter/filterConfig";
import Table from "./table";

class Recordset extends Model {
  static modelType = "Recordset";

  constructor(data) {
    super({ filterConfig: new FilterConfig(), table: new Table(), ...data });
  }

  table(...args) {
    return this.accessor("table", args, Table);
  }

  filterConfig(...args) {
    return this.accessor("filterConfig", args, FilterConfig);
  }

  records(...args) {
    return this.accessor("records", args, [Object]);
  }

  recordCount(...args) {
    return this.accessor("filteredRecordCount", args, "number");
  }

  lastRecordIndex(...args) {
    return this.accessor("lastRecordIndex", args, "number");
  }

  concat(recordset) {
    if (this.filterConfig().filterEquals(recordset.filterConfig())) {
      this.table(recordset.table());
      this.lastRecordIndex(recordset.lastRecordIndex());
      this.recordCount(recordset.recordCount());
      if (this.records()) {
        this.records(this.records().concat(recordset.records()));
      } else {
        this.records(recordset.records());
      }
    }
  }
}
export default Recordset;
