import React, { Component } from "react";
import PropTypes from "prop-types";
import { cloneDeep, isEqual, extend, isArray, get } from "lodash";
import i18n from "@viz-ui/i18n/i18n";
import Toast from "@paprika/toast";
import MultiSelectFilterStore from "@viz-ui/services/quickMenu/MultiSelectFilterStoreService";
import ColorPalette from "@acl-services/sriracha-color-palette/dist/ColorPalette";
import ChartService from "../../services/charts/chartService";
import DataFilter from "../../services/charts/dataFilter/dataFilterService";
import DataMessage from "../../services/charts/dataFilter/dataMessageService";
import ChartDisplayConfigAdapter from "../../services/charts/chartConfigPanel/chartDisplayConfigAdapterService";
import SharedChartHeader from "./sharedChartHeader/sharedChartHeader";
import LoadingAnimation from "../loadingAnimation/loadingAnimation";
// import individual chart and service
import LineChart from "./lineChart/lineChart";
import LineChartService from "../../services/charts/lineChart/lineChartService";
import BarChart from "./barChart/barChart";
import BarChartService from "../../services/charts/barChart/barChartService";
import MapChart from "./mapChart/mapChart";
import MapChartService from "../../services/charts/mapChart/mapChartService";
import PieChart from "./pieChart/pieChart";
import PieChartService from "../../services/charts/pieChart/pieChartService";
import BubbleChart from "./bubbleChart/bubbleChart";
import BubbleChartService from "../../services/charts/bubbleChart/bubbleChartService";
import AreaChart from "./areaChart/areaChart";
import AreaChartService from "../../services/charts/stackedAreaChart/stackedAreaChartService";
import CombinationChart from "./combinationChart/combinationChart";
import CombinationChartService from "../../services/charts/combinationChart/combinationChartService";
import TreemapChart from "./treemapChart/treemapChart";
import TreemapChartService from "../../services/charts/treemapChart/treemapChartService";
import HeatMap from "./heatMap/heatMap";
import HeatMapService from "../../services/charts/heatMap/heatMapService";
import Statistics from "./statisticsChart/statisticsChart";
import StatisticsService from "../../services/charts/statisticsChart/statisticsVizService";
import SummaryTableChart from "./summaryTableChart/summaryTableChart";
import SummaryTableService from "../../services/charts/summaryTableChart/summaryTableChartService";
import "./ChartBaseContainer.scss";

// TODO: Need to fix upper levels in order to use DataModelService and tabStateService
// import dataModel from "../../services/charts/dataModel/dataModelService";
// import TabStateService from "../../services/charts/tabState/tabStateService";

let isReloading = false;
let hasColorMappingChanged = false;
let previousChartTitleValue;
const chartTypes = {
  lineChart: "LineChart",
  barChart: "BarChart",
  pieChart: "PieChart",
  stackedAreaChart: "StackedAreaChart",
  bubbleChart: "BubbleChart",
  combinationChart: "CombinationChart",
  heatMap: "Heatmap",
  summaryTable: "SummaryTable",
  treemap: "Treemap",
  mapChart: "MapChart",
  statisticsViz: "StatisticsViz",
};

export default class ChartBaseContainer extends Component {
  /* eslint-disable */
  static propTypes = {
    chartType: PropTypes.string.isRequired,
    dataModel: PropTypes.object.isRequired,
    appConfig: PropTypes.object.isRequired,
    eventService: PropTypes.object.isRequired,
    tabStateService: PropTypes.object.isRequired,
    index: PropTypes.number,
    interpretationId: PropTypes.string.isRequired,
  };

  /* eslint-enable */
  static defaultProps = {
    index: 0,
  };

  constructor(props) {
    super(props);
    this.Implementation = this.getChartServiceByType(props.chartType, props);
    this.DataFilter = new DataFilter(props.appConfig, MultiSelectFilterStore, props.dataModel);
    this.DataMessage = new DataMessage(ColorPalette, props.dataModel, props.appConfig, props.eventService);
    this.tabState = this.props.tabStateService.instance(() => this.props.index, this.reload, this.redraw);
    this.eventService = props.eventService.register("acl.visualizer.charts.ChartBaseController");
    this.organizationId = props.appConfig.highbondNavBarProps
      ? props.appConfig.highbondNavBarProps.appSwitcherProps.initialOrganizationId
      : undefined;
    this.chartDisplayConfigAdapter = new ChartDisplayConfigAdapter(
      ColorPalette,
      props.dataModel,
      props.appConfig,
      props.eventService
    );
    this.state = {
      displayConfig: {},
      chartModel: this.defaultChartModel(),
      chartTitle: { value: "" },
      configPanelTabs: {
        data: "data",
        display: "display",
      },
      chartType: chartTypes[props.chartType],
      index: props.index || 0,
      chartViz: {},
      noDataMessage: null,
      initialOrganizationId: this.organizationId, // get orgID for summaryChart
      setConfigPanelTab: function(tab) {
        this.state.selectedChartConfigTab = tab;
      },

      chartConfigColumnDefs: props.chartType ? this.Implementation.chartConfigColumnDefs() : {},
      // TODO: Need to migrate callBack interface from dataVisualizer.controller.js in order to callback interface to work.
      // TODO: we need to migrate toast notification, replace call back interface with toast notification service
      tab: {
        callbackInterface: {
          notify: () => {},
          onZoomIn: () => {},
        },
      },
      filterDataMsg: "",
      ...props,
    };
  }

  componentDidMount() {
    this.eventService.subscribe("chartConfigPanel.configClear", () => {
      this.updateState({
        type: "CHART_CONFIG_PANEL_CLEAR_CONFIG",
        chartModel: this.defaultChartModel(),
      });
    });

    this.eventService.subscribe("chartConfigPanel.colorMappingChanged", () => {
      this.updateState({ type: "CHART_CONFIG_PANEL_COLOR_MAPPING_CHANGED" });
      hasColorMappingChanged = true;
      this.tabState.scheduleRedraw();
    });

    this.eventService.subscribe("dataConfig.aggregationType", aggregationType => {
      if (aggregationType && aggregationType !== "count") {
        this.updateState({ type: "DATA_CONFIG_AGGREGATION_TYPE", prevAggregationType: aggregationType });
      }
    });

    let isTableDataLoaded = false;
    let pendingRepresentation;
    let pendingType;

    this.eventService.subscribe(
      "chartData.loaded",
      (e, eventTabIndex, type, eventDataConfig, representation, error) => {
        if (
          isThisTabsChartDataLoaded(eventTabIndex) &&
          isThisTabSelected() &&
          isChartDataForCurrentDataConfigLoaded(eventDataConfig)
        ) {
          this.updateState({ type: "CHART_DATA_LOADED" });
          if (error) {
            this.handleDataLoadFail(error);
          } else {
            this.handleDataLoad(representation, type);
            pendingRepresentation = representation;
            pendingType = type;
          }
        }

        if (isReloading) {
          this.tabState.reloaded();
          isReloading = false;
        }
      }
    );

    const isThisTabsChartDataLoaded = eventTabIndex => eventTabIndex === this.state.index;

    const isThisTabSelected = () => {
      const selectedTabIndex = parseInt(this.props.dataModel.interpretation.currentTabIndex(), 10);

      return this.state.index === selectedTabIndex;
    };

    const isChartDataForCurrentDataConfigLoaded = eventDataConfig => {
      const visualization = this.getVisualization();
      const currentDataConfig = visualization.config.dataConfig;

      return isEqual(eventDataConfig, currentDataConfig);
    };

    this.eventService.subscribe("tableDataLoaded", () => {
      if (!isTableDataLoaded) {
        isTableDataLoaded = true;

        if (pendingRepresentation) {
          this.handleDataLoad(pendingRepresentation, pendingType);
          pendingRepresentation = null;
          pendingType = null;
        }
      }
    });

    this.eventService.subscribe("chartConfigPanel.dataChange", (msg, dataConfig, tabIndex) => {
      if (tabIndex === this.state.index) {
        this.updateState({
          type: "CHART_CONFIG_PANEL_DATA_CHANGED",
          chartViz: {
            ...this.state.chartViz,
            config: {
              ...this.state.chartViz.config,
              dataConfig,
            },
          },
        });
        this.submitDataConfig();
      }
    });

    this.eventService.subscribe("chartConfigPanel.displayChange", (msg, tabIndex) => {
      if (tabIndex === this.state.index) {
        if (this.isDataConfigValid(this.getVisualization().config.dataConfig, true)) {
          this.updateState({ type: "CHART_CONFIG_PANEL_DISPLAY_CHANGED" });
        }
      }
    });

    this.updateState({ type: "INIT_CHART" });
  }

  shouldComponentUpdate(nextProps, nextState) {
    //re-render only for no data and when loader is disabled
    if (!isEqual(this.state.isChartLoading, nextState.isChartLoading) && !!this.state.noDataMessage) {
      return true;
    }
    if (
      isEqual(this.state.chartModel, nextState.chartModel) &&
      isEqual(this.state.chartViz, nextState.chartViz) &&
      isEqual(this.state.displayConfig, nextState.displayConfig) &&
      isEqual(this.state.noDataMessage, nextState.noDataMessage) &&
      isEqual(this.state.filterDataMsg, nextState.filterDataMsg)
    ) {
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    this.eventService.unregister();
  }

  addColorsToData = (data, config) => {
    if (data && this.Implementation.getKeyColor) {
      const getKeyColor = this.Implementation.getKeyColor(config.colorByField, config.colorMapping);
      if (isArray(data)) {
        return data.map(series =>
          extend({}, series, {
            color: getKeyColor(series),
          })
        );
      }

      return extend({}, data, {
        values: data.values.map(series => extend({}, series, { color: getKeyColor(series) })),
      });
    }
    return data;
  };

  /**
   * ! when adding new chart, include newChartService here.
   * @param {string} chartType
   * @returns chartService of that specific chartType
   */
  getChartServiceByType = (chartType, props) => {
    switch (chartType) {
      case "LineChart":
        return new LineChartService(ColorPalette, props.dataModel, props.appConfig, props.eventService);
      case "BarChart":
        return new BarChartService(ColorPalette, props.dataModel, props.appConfig, props.eventService);
      case "MapChart":
        return new MapChartService(ColorPalette, props.dataModel, props.appConfig, props.eventService);
      case "PieChart":
        return new PieChartService(ColorPalette, props.dataModel, props.appConfig, props.eventService);
      case "BubbleChart":
        return new BubbleChartService(ColorPalette, props.dataModel, props.appConfig, props.eventService);
      case "StackedAreaChart":
        return new AreaChartService(ColorPalette, props.dataModel, props.appConfig, props.eventService);
      case "CombinationChart":
        return new CombinationChartService(ColorPalette, props.dataModel, props.appConfig, props.eventService);
      case "Treemap":
        return new TreemapChartService(ColorPalette, props.dataModel, props.appConfig, props.eventService);
      case "Heatmap":
        return new HeatMapService(ColorPalette, props.dataModel, props.appConfig, props.eventService);
      case "StatisticsViz":
        return new StatisticsService(ColorPalette, props.dataModel, props.appConfig, props.eventService);
      case "SummaryTable":
        return new SummaryTableService(ColorPalette, props.dataModel, props.appConfig, props.eventService);
      default:
        return new ChartService(ColorPalette, props.dataModel, props.appConfig, props.eventService);
    }
  };

  onChartTitleSet = chartTitle => {
    const visualization = this.getVisualization();
    if (visualization.title !== chartTitle) {
      visualization.title = chartTitle;
      this.setVisualization(visualization, this.state.index);
      this.titleChanged();
    }
  };

  getImplementation = () => this.Implementation;

  defaultChartModel = () => ({
    config: undefined,
    data: undefined,
  });

  getItemsValuesCount = (data, searchField) => {
    let counter = 0;
    for (const i in data) {
      if (Object.prototype.hasOwnProperty.call(data[i], searchField)) {
        counter += Array.isArray(data[i][searchField]) ? data[i][searchField].length : 1;
      } else {
        this.getItemsValuesCount(data[i], searchField);
      }
    }
    return counter;
  };

  getDataRecordsSize = (data, chartType) => {
    switch (chartType) {
      case "BarChart":
      case "PieChart":
      case "StackedAreaChart":
      case "LineChart":
      case "BubbleChart":
      case "Heatmap":
      case "CombinationChart":
        return this.getItemsValuesCount(data, "values");
      case "SummaryTable":
      case "Treemap":
      case "MapChart":
        return data.values.length;
      case "StatisticsViz":
        return data.length;
      default:
        return 0;
    }
  };

  handleDataLoad = (data, chartType) => {
    const dataRecordSize = this.getDataRecordsSize(data, chartType);
    let state = {};
    if (dataRecordSize > 0) {
      const { dataConfig } = this.state.chartViz.config;
      const aggregationType = get(dataConfig, "chartValue.aggregationType", dataConfig.aggregationType);
      const { representation, msg } = this.Implementation.filterResult
        ? this.Implementation.filterResult(data, aggregationType)
        : { representation: data, msg: "" };
      state = {
        noDataMessage: null,
      };
      if (msg && msg !== "") {
        state.filterDataMsg = msg;
        /* eslint-disable */
        setTimeout(() => {
          this.updateState({ type: "CLEAR_MSG", filterDataMsg: "" });
        }, 5000);
      }
      state = { ...state, ...this.setChartModelConfig(representation, state) };
      state = {
        ...state,
        ...this.setChartModelDataColors(representation, state.chartModel.config, state),
      };
    } else {
      state = {
        chartModel: this.defaultChartModel(),
        noDataMessage: this.DataMessage.getNoDataMessage(
          this.props.dataModel.table.recordCount(),
          this.getDataRecordsSize(data, chartType)
        ),
      };
    }
    this.updateState({ type: "HANDLE_DATA_LOAD", state });
  };

  setChartModelDataColors = (chartData, config, state) => ({
    chartModel: {
      ...state.chartModel,
      data: this.addColorsToData(chartData, config),
    },
  });

  setChartModelConfig = state => {
    const visualization = this.getVisualization();
    const { interpretationId } = this.state;
    return {
      chartModel: {
        ...state.chartModel,
        config: this.Implementation.getChartDirectiveConfig(interpretationId, {
          vizId: visualization.id,
          dataConfig: visualization.config.dataConfig,
          displayConfig: this.chartDisplayConfigAdapter.configToViewModel(
            this.state.chartType,
            visualization.config.displayConfig
          ),
        }),
      },
    };
  };

  handleDataLoadFail = response => {
    let error;
    if (response.errorMessage) {
      error = i18n.t(response.errorMessage);
    } else {
      error = i18n.t("_Chart.LoadFailed." + response.error, "_Chart.LoadFailed.Error_");
    }
    this.state.tab.callbackInterface.notify("error", error);
  };

  getVisualization = () => this.props.dataModel.visualization(this.state.index - 1);

  setVisualization = (visualization, index) => {
    this.updateState({ type: "SET_VISUALIZATION", chartViz: visualization });
    this.props.dataModel.visualization(index - 1, visualization);
  };

  titleChanged = () => {
    const visualization = this.getVisualization();
    const useDefaultTabTitle = visualization.title === this.Implementation.getDefaultChartTitle();
    const tabTitle = useDefaultTabTitle ? i18n.t("_Chart.Title.Default_") : visualization.title;

    const chartTitleHasChanged = tabTitle !== previousChartTitleValue;
    if (chartTitleHasChanged) {
      // Avoid Chart Title causing unnecessary dirty "leave page?!!" prompt
      previousChartTitleValue = tabTitle;
      this.updateState({ type: "TITLE_CHANGED", chartTitle: { value: tabTitle } });
    }
  };

  reload = () => {
    const visualization = this.getVisualization();
    this.updateState({ type: "RELOAD" });
    if (this.isDataConfigValid(visualization.config.dataConfig, true)) {
      isReloading = this.loadData();
    }

    if (!isReloading) {
      this.tabState.reloaded();
    }
  };

  redraw = () => {
    const visualization = this.getVisualization();
    if (this.isDataConfigValid(visualization.config.dataConfig, true)) {
      if (hasColorMappingChanged) {
        this.updateState({
          type: "REDRAW",
          chartModelDataColors: this.setChartModelDataColors(
            this.state.chartModel.data,
            this.state.chartModel.config,
            this.state
          ),
        });
      } else {
        this.eventService.publish("chartRedraw");
      }
    }
    this.tabState.redrew();
  };

  loadData = () => {
    if (this.filtersValid(this.props.dataModel)) {
      this.updateState({
        type: "LOAD_DATA",
        noDataMessage: this.DataMessage.getNoDataMessage(
          this.props.dataModel.table.recordCount(),
          this.props.dataModel.table.filteredRecordCount()
        ),
        isChartLoading: true,
      });

      const visualization = this.getVisualization();
      const tableId = this.props.dataModel.table.id();
      const filterConfig = this.props.dataModel.getFilterConfig();
      const { dataConfig } = visualization.config;
      this.eventService.publish(
        "chartData.configChanged",
        this.state.index,
        this.state.chartType,
        tableId,
        filterConfig,
        dataConfig
      );
      return true;
    }
    this.state.tab.callbackInterface.notify("warning", i18n.t("_FilterConfig.Invalid.Error_"), false);
    return false;
  };

  isDataConfigValid = (dataConfig, isParsed) => {
    let state = {
      ...this.populateChartConfigColumnDefs(),
    };
    const parsedDataConfig = isParsed
      ? dataConfig
      : this.Implementation.parseDataConfig(dataConfig, state.chartConfigColumnDefs);

    if (!this.Implementation.dataConfigFieldsExist(parsedDataConfig)) {
      // Only a deleted field error if fields have loaded.
      if (!isEqual(this.props.dataModel.table.fields(), {})) {
        state = {
          ...state,
          noDataMessage: i18n.t("_Table.DeletedField.Label_"),
        };
      }
      this.updateState({ type: "IS_DATA_CONFIG_VALID", state });
      return false;
    }
    if (this.Implementation.dataConfigHasFieldTypeMismatch(parsedDataConfig)) {
      if (!isEqual(this.props.dataModel.table.fields(), {})) {
        state = {
          ...state,
          noDataMessage: i18n.t("_Table.FieldTypeChanged.Label_"),
        };
      }
      this.updateState({ type: "IS_DATA_CONFIG_VALID", state });
      return false;
    }

    this.updateState({ type: "IS_DATA_CONFIG_VALID", state });

    return this.Implementation.isValidDataConfig(parsedDataConfig);
  };

  submitDataConfig = () => {
    if (this.isDataConfigValid(this.state.chartViz.config.dataConfig, this.Implementation.isDataConfigParsed)) {
      const visualization = this.getVisualization();
      if (this.Implementation.isDataConfigParsed) {
        visualization.config.dataConfig = this.state.chartViz.config.dataConfig;
      } else {
        visualization.config.dataConfig = this.Implementation.parseDataConfig(
          this.state.chartViz.config.dataConfig,
          this.state.chartConfigColumnDefs
        );
      }
      this.setVisualization(visualization, this.state.index);
      this.loadData();
    }
  };

  initChart = () => {
    const visualization = this.getVisualization() || {};
    const config = this.state.chartViz.config || {};
    return {
      chartViz: {
        config,
        ...visualization,
      },
      chartConfig: config,
      chartConfigColumnDefs: this.props.chartType ? this.Implementation.chartConfigColumnDefs() : {},
      displayConfig: cloneDeep(visualization.config.displayConfig),
      chartZoomIn: this.Implementation.chartZoomIn ? this.Implementation.chartZoomIn : null,
      chartTitle: {
        value: visualization.title,
      },
    };
  };

  zoomInHandler = keyArray => {
    const filters = this.Implementation.getFilters(keyArray, this.state.chartViz.config);
    this.eventService.publish("onHighChartZoomIn", filters, true);
  };

  getChart = (chartType, state) => {
    /* eslint-disable */
    if (!state.chartModel.config || !state.chartModel.data) {
      return <div data-testid="chart-base-container-test-id"></div>;
    }
    /* eslint-enable */
    switch (chartType) {
      case "LineChart":
        return (
          <div data-testid="line-chart-test-id" className="react-chart-container">
            <LineChart
              config={state.chartModel.config}
              rawData={state.chartModel.data}
              zoomInHandler={keyArray => this.zoomInHandler(keyArray)}
              {...state}
            />
          </div>
        );
      case "BarChart":
        return (
          <div data-testid="bar-chart-test-id" className="react-chart-container">
            <BarChart
              config={state.chartModel.config}
              rawData={state.chartModel.data}
              zoomInHandler={keyArray => this.zoomInHandler(keyArray)}
              {...state}
            />
          </div>
        );
      case "MapChart":
        return (
          <div data-testid="map-chart-test-id" className="react-chart-container">
            <MapChart config={state.chartModel.config} rawData={state.chartModel.data} {...state} />
          </div>
        );
      case "PieChart":
        return (
          <div data-testid="pie-chart-test-id" className="react-chart-container">
            <PieChart
              config={state.chartModel.config}
              rawData={state.chartModel.data}
              zoomInHandler={keyArray => this.zoomInHandler(keyArray)}
              {...state}
            />
          </div>
        );
      case "BubbleChart":
        return (
          <div data-testid="map-chart-test-id" className="react-chart-container">
            <BubbleChart
              config={state.chartModel.config}
              rawData={state.chartModel.data}
              zoomInHandler={keyArray => this.zoomInHandler(keyArray)}
              {...state}
            />
          </div>
        );
      case "StackedAreaChart":
        return (
          <div data-testid="map-chart-test-id" className="react-chart-container">
            <AreaChart
              config={state.chartModel.config}
              rawData={state.chartModel.data}
              zoomInHandler={keyArray => this.zoomInHandler(keyArray)}
              {...state}
            />
          </div>
        );
      case "CombinationChart":
        return (
          <div data-testid="map-chart-test-id" className="react-chart-container">
            <CombinationChart
              config={state.chartModel.config}
              rawData={state.chartModel.data}
              zoomInHandler={keyArray => this.zoomInHandler(keyArray)}
              {...state}
            />
          </div>
        );
      case "Treemap":
        return (
          <div data-testid="map-chart-test-id" className="react-chart-container">
            <TreemapChart
              config={state.chartModel.config}
              rawData={state.chartModel.data}
              zoomInHandler={keyArray => this.zoomInHandler(keyArray)}
              {...state}
            />
          </div>
        );
      case "Heatmap":
        return (
          <div data-testid="map-chart-test-id" className="react-chart-container">
            <HeatMap
              config={state.chartModel.config}
              rawData={state.chartModel.data}
              zoomInHandler={keyArray => this.zoomInHandler(keyArray)}
              {...state}
            />
          </div>
        );
      case "StatisticsViz":
        return (
          <div data-testid="statistics-chart-test-id" className="react-chart-container">
            <Statistics
              statistics={state.chartModel.data}
              config={state.chartModel.config}
              zoomInHandler={keyArray => this.zoomInHandler(keyArray)}
            />
          </div>
        );
      case "SummaryTable":
        return (
          <div data-testid="summary-table-chart-test-id" className="react-chart-container">
            <SummaryTableChart
              index={state.index}
              redrawIndex={state.index}
              chartModel={state.chartModel}
              zoomInHandler={keyArray => this.zoomInHandler(keyArray)}
              dataModel={this.props.dataModel}
              eventService={this.props.eventService}
              appConfig={this.props.appConfig}
              chartViz={state.chartViz}
            />
          </div>
        );
      default:
        return null;
    }
  };

  //! created 'updateState' method to handle single place to update state.
  // TODO: Need to replace this with redux / flex
  updateState(action) {
    const state = cloneDeep(this.reducer(action, this.state));
    this.setState({
      ...state,
    });
  }

  // Creating a reducer method to handle state management in a better way
  reducer(action, state) {
    switch (action.type) {
      case "CHART_CONFIG_PANEL_CLEAR_CONFIG":
        return {
          ...state,
          chartModel: action.chartModel,
        };
      case "CHART_CONFIG_PANEL_COLOR_MAPPING_CHANGED":
        return {
          ...state,
          hasColorMappingChanged: true,
        };
      case "DATA_CONFIG_AGGREGATION_TYPE":
        return {
          ...state,
          prevAggregationType: action.prevAggregationType,
        };
      case "CHART_DATA_LOADED":
        return {
          ...state,
          isChartLoading: false,
        };
      case "SET_VISUALIZATION":
      case "CHART_CONFIG_PANEL_DATA_CHANGED":
        return {
          ...state,
          chartViz: action.chartViz,
          isChartLoading: true,
        };
      case "CHART_CONFIG_PANEL_DISPLAY_CHANGED": {
        const chartModelConfig = this.setChartModelConfig(state);
        return {
          ...state,
          ...chartModelConfig,
        };
      }
      case "INIT_CHART": {
        const initialState = {
          ...this.initChart(),
          ...this.populateChartConfigColumnDefs(),
        };
        return {
          ...state,
          ...initialState,
        };
      }
      case "CLEAR_MSG":
        return {
          ...state,
          filterDataMsg: action.filterDataMsg,
        };
      case "IS_DATA_CONFIG_VALID":
      case "HANDLE_DATA_LOAD":
        return {
          ...state,
          ...action.state,
        };
      case "TITLE_CHANGED":
        return {
          ...state,
          chartTitle: action.chartTitle,
        };
      case "RELOAD": {
        const populateChartConfig = this.populateChartConfigColumnDefs();
        return {
          ...state,
          ...populateChartConfig,
        };
      }
      case "REDRAW":
        return {
          ...state,
          ...action.chartModelDataColors,
        };
      case "LOAD_DATA":
        return {
          ...state,
          noDataMessage: action.noDataMessage,
          isChartLoading: action.isChartLoading,
        };
      default:
        return state;
    }
  }

  filtersValid(DataModel) {
    return DataModel.filtersValid() && !this.DataFilter.hasInvalidFilters(DataModel.getFilterConfig());
  }

  populateChartConfigColumnDefs() {
    const chartConfigColumnDefs = this.props.chartType
      ? this.Implementation.populateChartConfigColumnDefs(this.Implementation.chartConfigColumnDefs())
      : {};
    Object.keys(chartConfigColumnDefs).forEach(key => {
      chartConfigColumnDefs[key].forEach((field, i) => {
        const existingField = this.state.chartConfigColumnDefs[key].filter(
          eField => eField.fieldName === field.fieldName
        )[0];
        if (existingField) {
          chartConfigColumnDefs[key][i] = existingField;
        }
      });
    });
    return {
      chartConfigColumnDefs,
      fields: cloneDeep(this.props.dataModel.table.fields()),
    };
  }

  render() {
    const { chartType, isChartLoading, noDataMessage, index, chartTitle } = this.state;
    if (isChartLoading) {
      return <LoadingAnimation />;
    }
    return (
      <React.Fragment>
        <Toast isOpen={this.state.filterDataMsg !== ""} hasCloseButton={false} isFixed kind={Toast.types.kind.WARNING}>
          {this.state.filterDataMsg}
        </Toast>
        <SharedChartHeader onChartTitleSet={this.onChartTitleSet} chartTitle={chartTitle.value} index={index} />
        {errorMessage(noDataMessage, index)}
        {this.getChart(chartType, this.state)}
      </React.Fragment>
    );
  }
}

const errorMessage = (noDataMessage, index) =>
  noDataMessage ? (
    <div className="no-data-available" id={`no-data-available-${index}`} style={{ margin: "185px auto auto" }}>
      <span>{noDataMessage}</span>
    </div>
  ) : null;
