import QueryClient from "@viz-ui/services/query/queryClient/queryClient";
import QueryGenerator from "@viz-ui/services/query/queryGenerator/queryGenerator";
import QueryErrorInterpreter from "@viz-ui/services/query/queryErrorInterpreter/queryErrorInterpreter.service";
import TableDataAdapter from "@viz-ui/services/query/queryAdapters/tableDataAdapter";
import { stripDataForQuery } from "./dataTableBackendHelper";

angular
  .module("acl.visualizer.dataTable.backend")
  .service("DataTableQueryBackendService", function(AppConfig, DataFilter, RecordsetAdapter, TableAdapter) {
    var service = {
      getTableMetadata: (tableId, scope) => tableMetadataQuery(tableId, scope),

      recordsetRequested: (table, filterConfig, globalFilters, scope) => {
        let filterConfigObj = filterConfig.toJson();
        return tableQuery(table, filterConfigObj, globalFilters, scope).then(tableData => {
          let tableModel = TableAdapter.deserializeTable(tableData.metaData.id, tableData.metaData);
          return RecordsetAdapter.deserializeRecordset(tableModel, filterConfig, tableData.result);
        });
      },
    };

    function tableMetadataQuery(tableId, scope) {
      let query = QueryGenerator.tableMetadata(tableId, scope);
      return new Promise((resolve, reject) => {
        QueryClient.query(query)
          .then(response => {
            stripDataForQuery(response.data);
            let metadata = TableDataAdapter.formatMetadata(tableId, response.data);
            resolve(TableAdapter.deserializeTable(tableId, metadata));
          })
          .catch(response => {
            const errorCode = QueryErrorInterpreter.errorCode(response);
            reject(errorCode);
          });
      });
    }

    function tableQuery(table, filterConfig, globalFilters, scope) {
      const filterObj = DataFilter.getFilterRequestBody(filterConfig);
      const clearAllFilter = window.clearAllFilter;

      const { filterList, sortField: sortObj } = filterObj;
      const positionField = table.systemFieldByName("metadata.position");
      const recordIdField = table.systemFieldByName("metadata.record_id");
      const defaultSortField = positionField ? positionField.name() : undefined;
      const defaultSortOrder = defaultSortField ? "asc" : undefined;
      const secondarySortField = recordIdField ? recordIdField.name() : undefined;
      const secondarySortOrder = secondarySortField ? "asc" : undefined;
      const startIndex = filterObj.params.start;
      const query = QueryGenerator.table({
        tableId: table.id(),
        filterList,
        sortField: sortObj ? sortObj.field : defaultSortField,
        sortOrder: sortObj ? sortObj.order : defaultSortOrder,
        secondarySortField,
        secondarySortOrder,
        offset: startIndex - 1,
        globalFilters: clearAllFilter ? [] : globalFilters, //if clearfilterflag set to false, so global filters will be empty
        scope,
      });

      return new Promise((resolve, reject) => {
        QueryClient.query(query)
          .then(response => {
            const tableData = TableDataAdapter.format(table.id(), response.data, startIndex);
            resolve(tableData);
          })
          .catch(response => {
            const errorCode = QueryErrorInterpreter.errorCode(response);
            reject(errorCode);
          });
      });
    }

    return service;
  });
