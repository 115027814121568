import uniq from "lodash/uniq";
import isNumber from "lodash/isNumber";
import { HighChartDirective, HighChartController } from "../highChartDirective";
import { LINE_CHART } from "../boost/boostThresholds";

class LineChartDirective extends HighChartDirective {
  controller = Controller;
  static factory = () => new LineChartDirective();
}

class Controller extends HighChartController {
  static configPath = {
    labelFontSize: [
      "legend.itemStyle.fontSize",
      "plotOptions.series.dataLabels.style.fontSize",
      "tooltip.style.fontSize",
      "xAxis.labels.style.fontSize",
      "xAxis.title.style.fontSize",
      "yAxis.labels.style.fontSize",
      "yAxis.title.style.fontSize",
    ],
  };

  constructor($scope, $timeout, HighChartLabelSizer, Sorter, AppConfig, Localize, LineChart) {
    "ngInject";
    super($scope, $timeout, HighChartLabelSizer);
    this.Sorter = Sorter;
    this.AppConfig = AppConfig;
    this.Localize = Localize;
    this.LineChartService = LineChart;
  }

  onLegendItemClick = item => {
    const showAll = !this.hcConfig.series.some(entry => entry.visible && entry.name !== item.name);
    const series = this.hcConfig.series.map(entry =>
      angular.extend({}, entry, {
        visible: showAll || (entry.name === item.name ? !item.visible : entry.visible),
      })
    );
    this.updateHcConfig({ series });
  };

  onLegendItemDoubleClick = item => {
    const series = this.hcConfig.series.map(entry => angular.extend({}, entry, { visible: entry.name === item.name }));
    this.updateHcConfig({ series });
  };

  getConfig = (config, rawData) => {
    let data = rawData;
    let categories;
    if (!this.AppConfig.features.sortingForLineCharts) {
      if (config.xAxis.axisType === "character" || config.xAxis.axisType === "logical") {
        let allDataPoints = data.reduce((all, series) => all.concat(series.values), []);
        categories = this.Sorter.sort(uniq(allDataPoints.map(d => d.key)), { dataType: config.xAxis.axisType });
      }
    } else {
      data = this.LineChartService.reorderValues(rawData, config);
      categories = this.LineChartService.reorderXAxisDataPoints(rawData, config);
    }

    let hcConfig = {
      legend: {
        enabled: config.showLegend && config.hasMultipleSeries,
        align: "right",
        verticalAlign: "top",
        labelFormatter: function() {
          return config.labelFormatter && this.options.name !== "(blank)"
            ? config.labelFormatter(this.options.legendDisplayName)
            : this.options.legendDisplayName;
        },
      },
      boost: this.getBoostConfig(config),
      plotOptions: {
        series: {
          dataLabels: {
            enabled: config.displayDataLabels,
            formatter: function() {
              return config.yAxis.tickFormatter(this.y);
            },
          },
          marker: {
            enabled: config.displayDataLabels,
          },
          connectNulls: config.interpolate,
          tooltip: {
            headerFormat: "",
            pointFormatter: function() {
              let header = config.hasMultipleSeries
                ? `
                  <span style="text-decoration: underline grey;">${
                    config.labelFormatter && this.series.options.name !== "(blank)"
                      ? config.labelFormatter(this.series.options.legendDisplayName)
                      : this.series.options.legendDisplayName
                  }</span>
                  <br/>
                `
                : "";
              let body = `
                <span>${config.xAxis.tickFormatter(this.xValue)}</span>
                <br/>
                <b>${config.yAxis.tickFormatter(this.yValue)}</b>
              `;
              return header + body;
            },
          },
          point: {
            events: {
              click: this.clickWrapper(null, point => {
                this.zoomIn(config.hasMultipleSeries ? [point.series.name, point.xValue] : [point.xValue]);
              }),
            },
          },
          events: {
            legendItemClick: this.clickWrapper(this.onLegendItemClick, this.onLegendItemDoubleClick),
          },
          turboThreshold: 0,
          animation: this.canAnimate(),
        },
      },
      xAxis: {
        categories: categories,
        labels: {
          formatter: function() {
            return config.xAxis.tickFormatter(this.value);
          },
        },
        tickmarkPlacement: "on",
        title: {
          text: config.xAxis.label,
        },
      },
      yAxis: {
        title: {
          text: config.yAxis.label,
        },
        labels: {
          formatter: function() {
            return config.yAxis.tickFormatter(this.value);
          },
        },
        min: isNumber(config.yAxis.minimum) ? config.yAxis.minimum : undefined,
        max: isNumber(config.yAxis.maximum) ? config.yAxis.maximum : undefined,
        startOnTick: !isNumber(config.yAxis.minimum),
        endOnTick: !isNumber(config.yAxis.maximum),
      },
      series: data.map(series => ({
        type: config.smoothEdged ? "spline" : "line",
        name: series.key,
        legendDisplayName:
          series.key === "(blank)" ? this.Localize.getLocalizedString("_Filter.BlankValue.Label_") : series.key,
        boostThreshold: LINE_CHART,
        color: series.color,
        visible: series.visible !== false,
        data: series.values.map(value => ({
          x: categories ? categories.indexOf(value.key) : value.key,
          y: value.values,
          xValue: value.key,
          yValue: value.values,
        })),
      })),
    };
    hcConfig = this.updateLabelFontSize(hcConfig, Controller.configPath.labelFontSize);
    return hcConfig;
  };

  zoomIn = keyArray => {
    if (angular.isFunction(this.$scope.zoomInHandler)) {
      this.$scope.zoomInHandler(keyArray);
    }
  };
}

export default LineChartDirective;
