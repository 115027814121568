import React from "react";
import SearchIcon from "@acl-services/wasabicons/lib/Search";
import Input from "@paprika/input";
import L10n from "@paprika/l10n";
import i18n from "@viz-ui/i18n/i18n";
import "./searchBox.scss";

export default function SearchBox(props) {
  return (
    <L10n locale={i18n.locale}>
      <Input className="search-box" icon={<SearchIcon />} hasClearButton {...props} />
    </L10n>
  );
}

SearchBox.propTypes = {};
