angular
  .module("acl.visualizer.charts")
  .controller(
    "ChartBaseController",
    (
      $scope,
      AppConfig,
      $stateParams,
      ChartDisplayConfigAdapter,
      ChartService,
      DataMessage,
      DataModel,
      DataFilter,
      EventService,
      Implementation,
      SaveViz,
      Localize,
      TabStateService
    ) => {
      const tabState = TabStateService.instance(() => $scope.$index, reload, redraw);
      const eventService = EventService.register("acl.visualizer.charts.ChartBaseController", $scope);

      $scope.dataConfig = {};
      $scope.displayConfig = {};

      $scope.chartModel = defaultChartModel();

      $scope.chartTitle = { value: "" };

      $scope.configPanelTabs = {
        data: "data",
        display: "display",
      };

      $scope.migrateLineChart = AppConfig.features.migrateLineChart;
      $scope.migrateBarChart = AppConfig.features.migrateBarChart;
      $scope.migratePieChart = AppConfig.features.migratePieChart;

      let isReloading = false;
      let hasColorMappingChanged = false;

      eventService.subscribe("chartConfigPanel.configClear", () => {
        $scope.chartModel = defaultChartModel();
      });

      eventService.subscribe("chartConfigPanel.colorMappingChanged", () => {
        hasColorMappingChanged = true;
        tabState.scheduleRedraw();
      });

      function defaultChartModel() {
        return {
          config: undefined,
          data: undefined,
        };
      }

      function reload() {
        const visualization = getVisualization();
        populateChartConfigColumnDefs();
        if (isDataConfigValid(visualization.config.dataConfig, true)) {
          isReloading = loadData();
        }

        if (!isReloading) {
          tabState.reloaded();
        }
      }

      function redraw() {
        const visualization = getVisualization();
        if (isDataConfigValid(visualization.config.dataConfig, true)) {
          if (hasColorMappingChanged) {
            setChartModelDataColors($scope.chartModel.data);
          } else {
            $scope.$broadcast("chartRedraw");
          }
        }
        tabState.redrew();
      }

      function populateChartConfigColumnDefs() {
        const chartConfigColumnDefs = Implementation.populateChartConfigColumnDefs(
          Implementation.chartConfigColumnDefs()
        );
        //Point to existing where possible (so that select2 keeps the selections)
        angular.forEach(chartConfigColumnDefs, (fields, key) => {
          fields.forEach((field, i) => {
            const existingField = $scope.chartConfigColumnDefs[key].filter(existingField => {
              return existingField.fieldName === field.fieldName;
            })[0];
            if (existingField) {
              chartConfigColumnDefs[key][i] = existingField;
            }
          });
        });
        $scope.chartConfigColumnDefs = chartConfigColumnDefs;
        $scope.fields = angular.copy(DataModel.table.fields());
      }

      function filtersValid() {
        return DataModel.filtersValid() && !DataFilter.hasInvalidFilters(DataModel.getFilterConfig());
      }

      function loadData() {
        if (filtersValid()) {
          $scope.noDataMessage = DataMessage.getNoDataMessage(
            DataModel.table.recordCount(),
            DataModel.table.filteredRecordCount()
          );

          const visualization = getVisualization();
          const chartType = Implementation.chartType;
          const tableId = DataModel.table.id();
          const filterConfig = DataModel.getFilterConfig();
          const dataConfig = visualization.config.dataConfig;
          eventService.publish("chartData.configChanged", $scope.$index, chartType, tableId, filterConfig, dataConfig);

          $scope.tab.callbackInterface.isLoading(true);

          return true;
        }
        $scope.tab.callbackInterface.notify(
          "warning",
          Localize.getLocalizedString("_FilterConfig.Invalid.Error_"),
          false
        );

        return false;
      }

      function getItemsValuesCount(data, searchField) {
        let counter = 0;
        for (let i in data) {
          if (data[i].hasOwnProperty(searchField)) {
            counter += Array.isArray(data[i][searchField]) ? data[i][searchField].length : 1;
          } else {
            getItemsValuesCount(data[i], searchField);
          }
        }
        return counter;
      }

      function getDataRecordsSize(data, chartType) {
        switch (chartType) {
          case "BarChart":
          case "PieChart":
          case "StackedAreaChart":
          case "LineChart":
          case "BubbleChart":
          case "Heatmap":
          case "CombinationChart":
            return getItemsValuesCount(data, "values");
          case "SummaryTable":
          case "Treemap":
          case "MapChart":
            return data.values.length;
          case "StatisticsViz":
            return data.length;
        }
      }

      function handleDataLoad(data, chartType) {
        const dataRecordSize = getDataRecordsSize(data, chartType);
        if (dataRecordSize > 0) {
          const representation = Implementation.filterResult ? Implementation.filterResult(data) : data;
          $scope.noDataMessage = null;
          setChartModelConfig();
          setChartModelDataColors(representation);
        } else {
          $scope.chartModel = defaultChartModel();
          $scope.noDataMessage = DataMessage.getNoDataMessage(
            DataModel.table.recordCount(),
            getDataRecordsSize(data, chartType)
          );
        }
      }

      function setChartModelConfig() {
        const visualization = getVisualization();
        const interpretationId = SaveViz.savedVizId;

        $scope.chartModel.config = Implementation.getChartDirectiveConfig(interpretationId, {
          vizId: visualization.id,
          dataConfig: visualization.config.dataConfig,
          displayConfig: ChartDisplayConfigAdapter.configToViewModel(
            Implementation.chartType,
            visualization.config.displayConfig
          ),
        });
      }

      function setChartModelDataColors(chartData) {
        $scope.chartModel.data = addColorsToData(chartData, $scope.chartModel.config);
      }

      function handleDataLoadFail(response) {
        let error;
        if (response.errorMessage) {
          error = Localize.getLocalizedString(response.errorMessage);
        } else {
          error = Localize.getLocalizedString("_Chart.LoadFailed." + response.error, "_Chart.LoadFailed.Error_");
        }
        $scope.tab.callbackInterface.notify("error", error);
      }

      function getVisualization() {
        return DataModel.visualization($scope.$index - 1);
      }

      function setVisualization(visualization) {
        DataModel.visualization($scope.$index - 1, visualization);
        $scope.chartConfig = visualization.config;
      }

      $scope.$watch("dataConfig.aggregationType", aggregationType => {
        if (aggregationType && aggregationType !== "count") {
          $scope.prevAggregationType = aggregationType;
        }
      });

      function initChartTitle() {
        const visualization = getVisualization();
        $scope.chartTitle.value = visualization.title;
        if (visualization.title && visualization.title.length > 0) {
          $scope.tab.callbackInterface.setTitle($scope.$index, $scope.chartTitle.value);
        }
      }

      let previousChartTitleValue;
      $scope.onChartTitleSet = () => {
        const visualization = getVisualization();
        if (visualization.title !== $scope.chartTitle.value) {
          visualization.title = $scope.chartTitle.value;
          setVisualization(visualization);
          titleChanged();
        }
      };

      function titleChanged() {
        const visualization = getVisualization();
        const useDefaultTabTitle = visualization.title === ChartService.getDefaultChartTitle();
        const tabTitle = useDefaultTabTitle
          ? Localize.getLocalizedString("_Chart.Title.Default_")
          : visualization.title;

        const chartTitleHasChanged = tabTitle !== previousChartTitleValue;
        if (chartTitleHasChanged) {
          // Avoid Chart Title causing unnecessary dirty "leave page?!!" prompt
          previousChartTitleValue = tabTitle;
          $scope.tab.callbackInterface.setTitle($scope.$index, tabTitle);
        }
      }

      $scope.selectedChartConfigTab = $scope.configPanelTabs.data;

      $scope.setConfigPanelTab = tab => {
        $scope.selectedChartConfigTab = tab;
      };

      function isDataConfigValid(dataConfig, isParsed) {
        populateChartConfigColumnDefs();
        const parsedDataConfig = isParsed
          ? dataConfig
          : ChartService.parseDataConfig(dataConfig, $scope.chartConfigColumnDefs);

        if (!ChartService.dataConfigFieldsExist(parsedDataConfig)) {
          // Only a deleted field error if fields have loaded.
          if (!angular.equals(DataModel.table.fields(), {})) {
            $scope.noDataMessage = Localize.getLocalizedString("_Table.DeletedField.Label_");
          }
          return false;
        } else if (ChartService.dataConfigHasFieldTypeMismatch(parsedDataConfig)) {
          if (!angular.equals(DataModel.table.fields(), {})) {
            $scope.noDataMessage = Localize.getLocalizedString("_Table.FieldTypeChanged.Label_");
          }
          return false;
        }

        return Implementation.isValidDataConfig(parsedDataConfig);
      }

      function submitDataConfig() {
        if (isDataConfigValid($scope.dataConfig, Implementation.isDataConfigParsed)) {
          const visualization = getVisualization();
          if (Implementation.isDataConfigParsed) {
            visualization.config.dataConfig = $scope.dataConfig;
          } else {
            visualization.config.dataConfig = ChartService.parseDataConfig(
              $scope.dataConfig,
              $scope.chartConfigColumnDefs
            );
          }
          setVisualization(visualization);

          loadData();
        }
      }

      const initChart = () => {
        $scope.$on("$destroy", () => {
          eventService.unregister();
        });

        let isTableDataLoaded = false;
        let pendingRepresentation;
        let pendingType;

        eventService.subscribe("chartData.loaded", (_, eventTabIndex, type, eventDataConfig, representation, error) => {
          if (
            isThisTabsChartDataLoaded(eventTabIndex) &&
            isThisTabSelected() &&
            isChartDataForCurrentDataConfigLoaded(eventDataConfig)
          ) {
            $scope.tab.callbackInterface.isLoading(false);

            if (error) {
              handleDataLoadFail(error);
            } else {
              handleDataLoad(representation, type);

              pendingRepresentation = representation;
              pendingType = type;
            }
          }

          if (isReloading) {
            tabState.reloaded();
            isReloading = false;
          }
        });

        function isThisTabsChartDataLoaded(eventTabIndex) {
          return eventTabIndex === $scope.$index;
        }

        function isThisTabSelected() {
          const selectedTabIndex = parseInt(DataModel.interpretation.currentTabIndex(), 10);

          return $scope.$index === selectedTabIndex;
        }

        function isChartDataForCurrentDataConfigLoaded(eventDataConfig) {
          const visualization = getVisualization();
          const currentDataConfig = visualization.config.dataConfig;

          return angular.equals(eventDataConfig, currentDataConfig);
        }

        eventService.subscribe("tableDataLoaded", () => {
          if (!isTableDataLoaded) {
            isTableDataLoaded = true;

            if (pendingRepresentation) {
              handleDataLoad(pendingRepresentation, pendingType);
              pendingRepresentation = null;
              pendingType = null;
            }
          }
        });

        eventService.subscribe("chartConfigPanel.dataChange", (_, dataConfig, tabIndex) => {
          if (tabIndex === $scope.$index) {
            $scope.dataConfig = dataConfig;
            submitDataConfig();
          }
        });

        eventService.subscribe("chartConfigPanel.displayChange", (_, tabIndex) => {
          if (tabIndex === $scope.$index) {
            if (isDataConfigValid(getVisualization().config.dataConfig, true)) {
              setChartModelConfig();
            }
          }
        });

        const visualization = getVisualization() || {};
        $scope.chartViz = visualization;
        $scope.chartViz.config = $scope.chartViz.config || {};
        $scope.chartConfig = $scope.chartViz.config;

        initChartTitle();
        $scope.chartConfigColumnDefs = Implementation.chartConfigColumnDefs();
        populateChartConfigColumnDefs();

        $scope.displayConfig = angular.copy(visualization.config.displayConfig);

        $scope.chartZoomIn = Implementation.chartZoomIn ? Implementation.chartZoomIn : null;
      };

      function addColorsToData(data, config) {
        if (data && Implementation.getKeyColor) {
          const getKeyColor = Implementation.getKeyColor(config);
          if (angular.isArray(data)) {
            return data.map(series =>
              angular.extend({}, series, {
                color: getKeyColor(series),
              })
            );
          }

          return angular.extend({}, data, {
            values: data.values.map(series => angular.extend({}, series, { color: getKeyColor(series) })),
          });
        }
        return data;
      }

      const childCtrlApi = {
        initChart: initChart,
      };

      return childCtrlApi;
    }
  );
